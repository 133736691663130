import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgHome = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M21.61 8.21L12.61 1.21C12.25 0.93 11.74 0.93 11.38 1.21L2.39 8.21C2.15 8.4 2 8.69 2 9V20C2 21.65 3.35 23 5 23H19C20.65 23 22 21.65 22 20V9C22 8.69 21.86 8.4 21.61 8.21ZM14 21H10V13H14V21ZM20 20C20 20.55 19.55 21 19 21H16V12C16 11.45 15.55 11 15 11H9C8.45 11 8 11.45 8 12V21H5C4.45 21 4 20.55 4 20V9.49L12 3.27L20 9.49V20Z" })));
};
