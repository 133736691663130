import { ModelPortfolio, ModelPortfolioId } from '@tumelo/shared'
import { APIServiceBaseClass } from '../../../utils/api'
import { ModelPortfolio as ModelPortfolioApi } from '../../../utils/api/gen'
import { ModelPortfolioService } from './ModelPortfolioService'

export class ModelPortfolioServiceAPI extends APIServiceBaseClass implements ModelPortfolioService {
  async listModelPortfolios(): Promise<ModelPortfolio[]> {
    const api = await this.getModelPortfoliosApi()
    const { habitatId } = this
    const { modelPortfolios } = await api.listModelPortfolios({ habitatId })
    return modelPortfolios.map(ModelPortfolioServiceAPI.mapModelPortfolioApiToModelPortfolio)
  }

  async getModelPortfolioById(modelPortfolioId: ModelPortfolioId): Promise<ModelPortfolio | undefined> {
    const portfolios = await this.listModelPortfolios()
    return portfolios.find(({ id }) => id === modelPortfolioId)
  }

  static mapModelPortfolioApiToModelPortfolio({ id, title, externalId }: ModelPortfolioApi): ModelPortfolio {
    return { id: id as ModelPortfolioId, title, externalId }
  }
}
