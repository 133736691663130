import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgEnergy = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 40, height: 40, viewBox: "0 0 40 40", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M28.39 18.91C28.3494 18.7769 28.2745 18.6569 28.1726 18.562C28.0708 18.4672 27.9457 18.401 27.81 18.37L21.55 17L23.26 6.00002C23.2972 5.81201 23.2714 5.61697 23.1864 5.44519C23.1014 5.2734 22.962 5.13449 22.79 5.05002C22.6254 4.9725 22.4377 4.95926 22.2638 5.01291C22.0899 5.06656 21.9423 5.18322 21.85 5.34002L11.85 20.78C11.7775 20.9024 11.732 21.0389 11.7165 21.1803C11.701 21.3217 11.7158 21.4648 11.76 21.6C11.8023 21.7322 11.8779 21.8512 11.9795 21.9458C12.081 22.0403 12.2052 22.1072 12.34 22.14L18.59 23.52L16.74 34C16.6926 34.193 16.7111 34.3963 16.7927 34.5776C16.8742 34.7588 17.0141 34.9075 17.19 35C17.2873 35.0556 17.398 35.0832 17.51 35.08C17.636 35.0793 17.7597 35.0468 17.8698 34.9857C17.9799 34.9245 18.0728 34.8366 18.14 34.73L28.28 19.83C28.3771 19.6997 28.4384 19.5461 28.4577 19.3847C28.477 19.2233 28.4537 19.0596 28.39 18.91ZM18.93 30.6L20.27 23C20.3083 22.7862 20.2662 22.5658 20.1517 22.3812C20.0372 22.1966 19.8585 22.0608 19.65 22L13.83 20.71L21.13 9.45002L19.87 17.51C19.8244 17.7245 19.8635 17.9483 19.979 18.1346C20.0945 18.321 20.2776 18.4555 20.49 18.51L26.28 19.8L18.93 30.6Z" }),
        React.createElement("path", { d: "M33.11 13.16C33.075 12.3876 32.7624 11.6538 32.2296 11.0934C31.6968 10.5331 30.9797 10.1839 30.21 10.11H24.66C24.4519 10.1417 24.2621 10.2468 24.1249 10.4064C23.9877 10.5661 23.9123 10.7696 23.9123 10.98C23.9123 11.1905 23.9877 11.394 24.1249 11.5536C24.2621 11.7132 24.4519 11.8184 24.66 11.85H30.21C30.97 11.85 31.58 12.7 31.58 13.75V14C31.5691 14.2186 31.6449 14.4326 31.791 14.5955C31.937 14.7585 32.1416 14.8571 32.36 14.87H33.06C33.82 14.87 34.43 15.72 34.43 16.77V24.77C34.43 25.82 33.82 26.67 33.06 26.67H32.36C32.1416 26.6829 31.937 26.7816 31.791 26.9445C31.6449 27.1074 31.5691 27.3215 31.58 27.54V27.82C31.58 28.87 30.97 29.72 30.21 29.72H23.59C23.3593 29.72 23.138 29.8117 22.9748 29.9748C22.8117 30.138 22.72 30.3593 22.72 30.59C22.72 30.8208 22.8117 31.042 22.9748 31.2052C23.138 31.3684 23.3593 31.46 23.59 31.46H30.21C30.9797 31.3861 31.6968 31.0369 32.2296 30.4766C32.7624 29.9163 33.075 29.1824 33.11 28.41C34.71 28.41 36 26.76 36 24.77V16.77C36 14.81 34.71 13.2 33.11 13.16Z" }),
        React.createElement("path", { d: "M14.41 29.72H6.94C6.18 29.72 5.57 28.87 5.57 27.82V13.75C5.57 12.7 6.18 11.85 6.94 11.85H15.48C15.7107 11.85 15.932 11.7584 16.0952 11.5952C16.2583 11.432 16.35 11.2108 16.35 10.98C16.35 10.7493 16.2583 10.528 16.0952 10.3648C15.932 10.2017 15.7107 10.11 15.48 10.11H6.94C5.32 10.11 4 11.74 4 13.75V27.82C4 29.82 5.32 31.46 6.94 31.46H14.41C14.6181 31.4284 14.8079 31.3232 14.9451 31.1636C15.0823 31.004 15.1577 30.8005 15.1577 30.59C15.1577 30.3796 15.0823 30.1761 14.9451 30.0164C14.8079 29.8568 14.6181 29.7517 14.41 29.72Z" })));
};
