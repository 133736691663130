/* eslint-disable @typescript-eslint/no-deprecated */

import React, { useState } from 'react'
import {
  SubHeading,
  Body,
  CardGrid,
  CardHeader,
  GridCard,
  CardContent,
  Button,
  BasePageProps,
  Content,
} from '@tumelo/shared'
import { useAppDispatch, useAppSelector } from '../application/store'
import { deleteAccount } from '../application/asyncActions'
import { RootState } from '../application/rootReducer'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { changeTitle } from './changeTitle'

type Props = BasePageProps

const makeTypeFormUrl = (rootUrl: string, hiddenFields?: Record<string, string>): string => {
  const hiddenFieldsString = new URLSearchParams(hiddenFields).toString()
  return hiddenFieldsString ? `${rootUrl}?${hiddenFieldsString}` : rootUrl
}

const DeleteAccountPage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)

  const dispatch = useAppDispatch()
  const [deleteAccountClicked, setDeleteAccountClicked] = useState(false)
  const habitat = useAppSelector((state: RootState) => state.config.config.habitat)

  const deleteUserAccount = () => {
    setDeleteAccountClicked(true)
    dispatch(
      deleteAccount(() => {
        window.location.href = makeTypeFormUrl('https://form.typeform.com/to/TSZbO7F4', { habitat })
      })
    )
  }

  return (
    <ErrorBoundary>
      <Content>
        <CardGrid>
          <GridCard columnSpan={6}>
            <CardHeader>
              <SubHeading>Delete my account</SubHeading>
              <Body mb={0}>
                We&apos;ll be sorry to see you go. Rest assured that deleting your Tumelo account won&apos;t delete or
                affect anything to do with your pension. You&apos;ll always be able to access that through your pension
                portal. <br />
                <br />
                Before you hit delete on the platform:{' '}
                <ul>
                  <li>
                    Account deletion is <b>final</b>. We won&apos;t be able to restore your account and all your data
                    will be removed from Tumelo&apos;s system.
                  </li>
                  <li>
                    Once you&apos;ve deleted your account we&apos;ll ask you to complete a short survey. We&apos;d love
                    some feedback on what went wrong - it will really help us make improvements.
                  </li>
                </ul>
                <br />
                If you&apos;re sure about deleting your Tumelo account, click the button below.
              </Body>
            </CardHeader>
            <CardContent>
              <Button disabled={deleteAccountClicked} onClick={deleteUserAccount}>
                Delete my Tumelo account now
              </Button>
            </CardContent>
          </GridCard>
        </CardGrid>
      </Content>
    </ErrorBoundary>
  )
}

export default DeleteAccountPage
