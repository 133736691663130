import { dateToTimestamp } from '@tumelo/shared'
import { AppNotification } from './AppNotification'

export const SampleNotification: () => AppNotification = () => ({
  id: '1234',
  createTime: dateToTimestamp(new Date()),
  title: 'notificationTitle',
  link: 'www.google.co.uk',
  type: 'openVote',
  resolution: 'unresolved',
  logo: 'www.google.co.uk',
  source: 'static',
})
