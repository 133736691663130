import React from 'react'
import { ModalImage, H1, Body1, Stack, VoteIcon, ModalFooter, ModalBody, ArrowRightIcon } from '@tumelo/designsystem'
import styled from 'styled-components'

interface Props {
  onNavigateToOpenVotes: () => void
}

export const ConfirmedPolicy: React.FC<Props> = ({ onNavigateToOpenVotes }) => (
  <>
    <ModalBody fullScreen>
      <ModalImage src="/images/confirmed_policy.png" />
      <div>
        <H1>All done! You&apos;ve chosen your policy</H1>
        <StyledStack gap="0.8rem" mb="2.4rem" flexDirection="row" alignItems="center">
          <VoteIcon />
          <Body1 mb="0">
            If you would like to vote manually on the issues you care about most, head to Open votes.
          </Body1>
        </StyledStack>
      </div>
    </ModalBody>
    <ModalFooter
      stickyButtons={[
        {
          label: 'Head to Open votes',
          variant: 'primary',
          iconAfter: <ArrowRightIcon />,
          onClick: onNavigateToOpenVotes,
        },
      ]}
    />
  </>
)

const StyledStack = styled(Stack)`
  svg {
    flex-shrink: 0;
  }
`
