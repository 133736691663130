import { createGlobalStyle } from 'styled-components';
/**
 * @deprecated use GlobalStyle from designsystem/_styles instead
 */
export const GlobalStyle = createGlobalStyle `
  html {
    overflow-y: scroll; // prevents layoutshift by always showing scroll bar
    font-family: 'Montserrat', sans-serif;
    font-size: 62.5%; // sets 1px => 0.1rem
    background-color: ${({ theme }) => theme.colors.background.light}
  }
  
  body {
    background-color: ${({ theme }) => theme.colors.background.light};
    font-size: 20px;
    font-size: 2rem;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.colors.dark};
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: ${({ theme }) => theme.colors.black}
  }
  
  div[role='button'] {
    outline: none;
  }
  
  [data-test='authenticator-error'] {
    font-weight: bold;
    color: #ffffff;
    box-shadow: none;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }

  .sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
  }
  
  .icon {
    font-size: 2.4rem !important;
  }
  
  .icon-interior {
    fill: #ffffff;
  }
  
  .icon-exterior {
    fill: none;
  }
`;
