import { add, sub } from 'date-fns';
import { dateToTimestamp } from '../Timestamp';
import { sampleOrganization } from '../Organization/SampleOrganization';
import { sampleGM, sampleGMWithMockDates } from './GMSample';
import { sampleProposal } from './ProposalSample';
export const samplePoll = (providedEndDate) => {
    const endDate = add(new Date(), { days: 6 });
    const closeDate = providedEndDate || endDate;
    const publishDate = sub(new Date(), { days: 10 });
    return {
        id: '77738a21-be2b-48d4-9308-3e2fd54f9d6c',
        title: 'Should TJX report on how CEO pay is set and how employee pay affects it?',
        formattedDescription: `### Shareholders say:\n\nTJX decides what to pay their CEO based on what other, similar companies pay their CEOs. If they match payment levels in large and successful companies, CEO pay can rise quickly.\n\nWe are asking TJX to consider the pay grades and salaries of their employees when deciding how much to pay the CEO. We are also asking them to report to shareholders on how employee pay has influenced CEO pay.  At TJX:\n- The CEOs total compensation was $18,822,770 last year and the average (median) employee was paid $11,791.\n- The pay ratio at TJX is 1,596:1, meaning that the CEO is paid 1,596 times more than the average (median).\n- For comparison, Starbucks has a pay ratio of 1,049:1 and Home Depot have a ratio of 486:1\n\nProductivity is lower in companies with higher pay gaps. \n\n### TJX says:\n\nWe are against this report. This policy would not add value to our decisions about CEO pay. We designed the pay plan to:\n- Attract, motivate, and keep leaders who improve the company’s performance\n- Reflect the skills, abilities and role of associates\n- Minimise risk to TJX\n\nWe already have access to information about pay grades and salary ranges at TJX, including the pay ratio. This policy does not improve the decision-making process.`,
        endDate: dateToTimestamp(closeDate),
        tags: [
            { id: '2', title: 'Equality' },
            { id: '3', title: 'Animal Welfare' },
        ],
        tally: {
            againstCount: 1,
            forCount: 1,
            abstainCount: 0,
            withholdCount: 0,
            noActionCount: 0,
        },
        teaserText: `### Shareholders say:\n\nTJX decides what to pay their CEO based on what other, similar companies pay their CEOs.`,
        teaserImage: {
            url: 'https://res.cloudinary.com/tumelo-dev/image/upload/v1635863620/mdfjenjymyooz6rsb2qx.png',
            altText: 'logo',
        },
        isHotTopic: true,
        publishAt: dateToTimestamp(publishDate),
        isNewToPlatform: true,
        relationships: {
            organization: sampleOrganization(),
            proposal: sampleProposal(),
            generalMeeting: sampleGM(),
        },
    };
};
export const samplePollEndsSoon = () => {
    const tomorrow = add(new Date(), { days: 1 });
    const publishDate = sub(new Date(), { days: 10 });
    return {
        id: '88738a21-be2b-48d4-9308-3e2fd54f9d6b',
        title: 'CorpCom should report on their use of Pangolin parts in their manufacturing process.',
        formattedDescription: 'This is the full text description of the poll in a **user-friendly** form.',
        endDate: dateToTimestamp(tomorrow),
        tags: [
            { id: '3', title: 'Animal Welfare' },
            { id: '6', title: 'Environment' },
        ],
        tally: {
            againstCount: 1,
            forCount: 1,
            abstainCount: 0,
            withholdCount: 0,
            noActionCount: 0,
        },
        isHotTopic: true,
        publishAt: dateToTimestamp(publishDate),
        isNewToPlatform: false,
        relationships: {
            organization: sampleOrganization(),
            proposal: sampleProposal(),
            generalMeeting: sampleGM(),
        },
    };
};
export const samplePollClosed = () => {
    const yesterday = sub(new Date(), { days: 1 });
    const publishDate = sub(new Date(), { days: 10 });
    return {
        id: '99738a21-be2b-48d4-9308-3e2fd54f9d6a',
        title: 'The CEO should get a bonus for reducing our carbon footprint.',
        formattedDescription: 'This is the full text description of the poll in a **user-friendly** form.',
        endDate: dateToTimestamp(yesterday),
        tags: [
            { id: '6', title: 'Environment' },
            { id: '9', title: 'Management' },
        ],
        tally: {
            againstCount: 1,
            forCount: 1,
            abstainCount: 0,
            withholdCount: 0,
            noActionCount: 0,
        },
        isHotTopic: true,
        publishAt: dateToTimestamp(publishDate),
        isNewToPlatform: false,
        relationships: {
            organization: sampleOrganization(),
            proposal: sampleProposal(),
            generalMeeting: sampleGM(),
        },
    };
};
export const samplePollWithMockDates = Object.assign(Object.assign({}, samplePoll(new Date('2021-06-26T14:46:36.000'))), { relationships: Object.assign(Object.assign({}, samplePoll().relationships), { generalMeeting: sampleGMWithMockDates }) });
