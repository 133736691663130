import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Investor } from '@tumelo/shared'
import { Payload } from '../../payload'
import { InvestorExtendedProfile } from '../../types/InvestorExtendedProfile/InvestorExtendedProfile'

interface UpdateInvestor {
  nicknameUpdateState: Payload<'not-updating'>
}

interface InvestorState {
  investor: Payload<Investor>
  extendedProfile: Payload<InvestorExtendedProfile>
  updateInvestor: UpdateInvestor
  termsAndConditionsAccepted: Payload<boolean>
}

const getInitialState = (): InvestorState => {
  return {
    investor: 'not-initialised',
    extendedProfile: 'not-initialised',
    updateInvestor: { nicknameUpdateState: 'not-updating' },
    termsAndConditionsAccepted: 'not-initialised',
  }
}

const investorSlice = createSlice({
  name: 'account',
  initialState: getInitialState(),
  reducers: {
    setInvestor: (state, action: PayloadAction<Payload<Investor>>) => {
      state.investor = action.payload
    },
    setInvestorExtendedProfile: (state, action: PayloadAction<Payload<InvestorExtendedProfile>>) => {
      state.extendedProfile = action.payload
    },
    setNicknameUpdateState: (state, action: PayloadAction<Payload<'not-updating'>>) => {
      state.updateInvestor = { ...state.updateInvestor, nicknameUpdateState: action.payload }
    },
    setTermsAndConditionsAccepted: (state, action: PayloadAction<Payload<boolean>>) => {
      state.termsAndConditionsAccepted = action.payload
    },
  },
})

export default investorSlice.reducer

export const { setInvestor, setInvestorExtendedProfile, setNicknameUpdateState, setTermsAndConditionsAccepted } =
  investorSlice.actions
