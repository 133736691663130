import * as z from 'myzod'
import { SoftConfigThemeSchema } from './SoftConfigTheme'
import { SoftConfigFeatureSwitchesSchema } from './SoftConfigFeatureSwitches'
import { SoftConfigSettingsSchema } from './SoftConfigSettings'
import { SoftConfigBrandingSchema } from './SoftConfigBranding'

/**
 * SoftConfigSchema defines the soft part of the configuration which can be changed on the client. It is a slightly
 * flimsy distinction at the moment.
 *
 * WARNING : any changes to any of these models must be backwards compatible and thus preferably additive only, with sensible
 * defaults for not being provided The app should not ever crash catastrophically due to an old configuration, missing property, etc.
 */
export const SoftConfigSchema = z
  .object(
    {
      theme: SoftConfigThemeSchema,
      branding: SoftConfigBrandingSchema,
      settings: SoftConfigSettingsSchema,
      featureSwitches: SoftConfigFeatureSwitchesSchema,
    },
    { allowUnknown: true }
  )
  .default({
    theme: SoftConfigThemeSchema.parse(),
    branding: SoftConfigBrandingSchema.parse(),
    settings: SoftConfigSettingsSchema.parse(),
    featureSwitches: SoftConfigFeatureSwitchesSchema.parse(),
  })

export type SoftConfig = z.Infer<typeof SoftConfigSchema>
