import { dateToTimestamp, VotingPolicy as DomainVotingPolicy, VotingPolicyId } from '@tumelo/shared'
import { UserPreferences as DomainUserPreferences } from '../../types/UserPreferences/UserPreferences'
import { UserPreferences } from '../../../utils/proto/tumelo/platform/v1/user_preferences'
import { VotingPolicy } from '../../../utils/proto/tumelo/dashboardbff/v1/voting_policy'

export const mapUserPreferencesToDomain = (up: UserPreferences): DomainUserPreferences => {
  return {
    ...up,
  }
}

export const mapVotingPolicyToDomain = ({
  votingPolicyId,
  title,
  structuredDescription,
  policyProvider,
  currentDocument,
  updateTime,
  imageUrl,
}: VotingPolicy): DomainVotingPolicy => {
  return {
    votingPolicyId: votingPolicyId as VotingPolicyId,
    title,
    structuredDescription,
    policyProvider,
    currentDocument: currentDocument && {
      ...currentDocument,
      issueTime: currentDocument?.issueTime && dateToTimestamp(currentDocument.issueTime),
    },
    updateTime: updateTime && dateToTimestamp(updateTime),
    imageUrl: imageUrl || '/images/policy_card.png',
  }
}
