import React from 'react'
import styled from 'styled-components'

const Placeholder: React.FC = () => (
  <>
    <LargePlaceholder />
    <SmallPlaceholder />
  </>
)

const LargePlaceholder = styled.div`
  height: 2.5rem;
  border-radius: 2rem;
  background-image: ${({ theme: { colors } }) =>
    `linear-gradient(to right, ${colors.grey.light}, ${colors.background.light})`};
  width: 90%;
`

const SmallPlaceholder = styled(LargePlaceholder)`
  margin-top: 1.5rem;
  width: 70%;
`

export default Placeholder
