import * as React from 'react'
import { useMatch } from 'react-router-dom'
import { Investor } from '@tumelo/shared'
import { useAppSelector } from '../../../../application/store'
import { selectInvestor } from '../../../../application/features/investor/investorSelector'
import { isErrorStates, isValueState } from '../../../../application/payload'
import { ProfileTab } from './ProfileTab'

interface Props {
  path: string
}

export const ProfileTabContainer: React.FC<Props> = ({ path }) => {
  const match = useMatch({ path })
  const isActive = !!match
  const investor = useAppSelector(selectInvestor)

  if (isErrorStates(investor)) {
    return <ProfileTab path={path} isActive={isActive} />
  }

  if (!isValueState(investor)) {
    return <ProfileTab path={path} investor={placeholder} isActive={isActive} loading />
  }

  return <ProfileTab path={path} investor={investor} isActive={isActive} />
}

const placeholder: Investor = {
  sub: '',
  email: '',
  changeEmail: {
    emailVerified: false,
  },
  signedInWithSSO: false,
  firstName: '',
  lastName: '',
}
