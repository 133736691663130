import { useRadioGroup } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
export const RadioItem = ({ label, value }) => {
    var _a;
    const radioGroup = useRadioGroup();
    if (!radioGroup) {
        return null;
    }
    const checked = radioGroup.value === value;
    const id = ((_a = radioGroup.name) !== null && _a !== void 0 ? _a : '').concat(value).trim();
    return (React.createElement(Wrapper, null,
        React.createElement(StyledRadio, { id: id, name: radioGroup.name, value: value, checked: checked, onChange: (e) => radioGroup.onChange(e, e.target.value) }),
        React.createElement(StyledLabel, { htmlFor: id }, label)));
};
const Wrapper = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 0.2rem 0;

  // offset to make box align with text
  --offset-top: 0.2rem;
`;
const StyledLabel = styled.label `
  display: block;
  flex: 1;
  padding-left: 2.8rem;
  line-height: 2.4rem;
`;
const StyledRadio = styled.input.attrs({ type: 'radio' }) `
  height: 2rem;
  width: 2rem;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;

  :focus + label::before {
    box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.colors.success.dark};
  }

  /* Box */
  + label::before {
    box-sizing: border-box;

    background: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    border: solid 0.1rem ${({ theme }) => theme.colors.grey.medium};
    width: 2rem;
    height: 2rem;
    position: absolute;
    content: '';
    left: 0;
    top: var(--offset-top);
  }
  :checked + label::before {
    border: solid 1px ${({ theme }) => theme.colors.secondary.medium};
  }
  /* Tick */
  + label::after {
    box-sizing: border-box;

    content: '';
    opacity: 0;
    border: 0.1rem solid ${({ theme }) => theme.colors.secondary.medium};
    border-radius: 50%;
    position: absolute;
    left: 0.9rem;
    top: calc(0.9rem + var(--offset-top));
    transition: all 100ms;
  }
  :checked + label::after {
    border: 0.6rem solid ${({ theme }) => theme.colors.secondary.medium};
    left: 0.4rem;
    top: calc(0.4rem + var(--offset-top));
    opacity: 1;
  }
`;
