import { AppThunk } from '../../store'
import { tryExecute } from '../../asyncActions'
import { Payload } from '../../payload'
import { create as createCompositionWithInstruments } from '../../types/CompositionWithInstruments'
import { pipe } from '../../../utils/functional/common'
import { Maybe } from '../../../utils/functional/maybe'
import { setAccountCompositionWithInstrumentsResult } from '.'

export const fetchAccountComposition =
  (): AppThunk =>
  async (dispatch, getState, { services }) => {
    dispatch(setAccountCompositionWithInstrumentsResult('pending'))
    const { accountService, instrumentService, loggerService } = services
    const tryExecuteL = tryExecute(loggerService)
    const compositionWithInstruments = await tryExecuteL(async () => {
      const existingAccount = pipe(
        getState().account.account,
        Payload.toMaybe,
        Maybe.map((x) => (x === 'not-configured' ? null : x))
      )
      const account = existingAccount ?? (await accountService.getAccount())
      if (!account) throw new Error(`no user account exists`)
      const { composition, noReferenceAndNoIsinInstrumentWeight } = await accountService.getAccountComposition(account)
      if (!composition) throw new Error(`no composition exists for ${account.id}`)
      if (noReferenceAndNoIsinInstrumentWeight > 0) {
        loggerService.logError(
          `account '${account.id}' contains holdings without either instrument reference or ISIN with total weight ${noReferenceAndNoIsinInstrumentWeight} and was mapped to others`
        )
      }
      if (composition.components.instruments.length === 0) {
        return createCompositionWithInstruments(new Map(), composition)
      }
      const compositionInstrumentsResult = await instrumentService.createCompositionInstruments(composition)
      if (compositionInstrumentsResult.missingInstrumentReferences.length !== 0) {
        loggerService.logError(
          `Instrument in account composition missing from habitat subscribed instruments ${compositionInstrumentsResult.missingInstrumentReferences}.`
        )
      }
      const updatedComposition = compositionInstrumentsResult.composition
      return createCompositionWithInstruments(compositionInstrumentsResult.instrumentMap, updatedComposition)
    })
    dispatch(setAccountCompositionWithInstrumentsResult(compositionWithInstruments))
  }
