import { countries } from '@tumelo/shared'

export type CountryCodeToBoolean = {
  [K in keyof typeof countries]: boolean
}

export const countryFieldOptions: { label: string; value: string }[] = Object.entries(countries).map(
  ([value, label]) => ({ label, value })
)

export const getInitialCountries = (): CountryCodeToBoolean => ({
  AF: false,
  AL: false,
  DZ: false,
  AS: false,
  AD: false,
  AO: false,
  AI: false,
  AQ: false,
  AG: false,
  AR: false,
  AM: false,
  AW: false,
  AU: false,
  AT: false,
  AZ: false,
  BS: false,
  BH: false,
  BD: false,
  BB: false,
  BY: false,
  BE: false,
  BZ: false,
  BJ: false,
  BM: false,
  BT: false,
  BO: false,
  BA: false,
  BW: false,
  BV: false,
  BR: false,
  IO: false,
  BN: false,
  BG: false,
  BF: false,
  BI: false,
  KH: false,
  CM: false,
  CA: false,
  CV: false,
  KY: false,
  CF: false,
  TD: false,
  CL: false,
  CN: false,
  CX: false,
  CC: false,
  CO: false,
  KM: false,
  CG: false,
  CD: false,
  CK: false,
  CR: false,
  CI: false,
  HR: false,
  CU: false,
  CY: false,
  CZ: false,
  DK: false,
  DJ: false,
  DM: false,
  DO: false,
  EC: false,
  EG: false,
  SV: false,
  GQ: false,
  ER: false,
  EE: false,
  ET: false,
  FK: false,
  FO: false,
  FJ: false,
  FI: false,
  FR: false,
  GF: false,
  PF: false,
  TF: false,
  GA: false,
  GM: false,
  GE: false,
  DE: false,
  GH: false,
  GI: false,
  GR: false,
  GL: false,
  GD: false,
  GP: false,
  GU: false,
  GT: false,
  GN: false,
  GW: false,
  GY: false,
  HT: false,
  HM: false,
  VA: false,
  HN: false,
  HK: false,
  HU: false,
  IS: false,
  IN: false,
  ID: false,
  IR: false,
  IQ: false,
  IE: false,
  IL: false,
  IT: false,
  JM: false,
  JP: false,
  JO: false,
  KZ: false,
  KE: false,
  KI: false,
  KP: false,
  KR: false,
  KW: false,
  KG: false,
  LA: false,
  LV: false,
  LB: false,
  LS: false,
  LR: false,
  LY: false,
  LI: false,
  LT: false,
  LU: false,
  MO: false,
  MG: false,
  MW: false,
  MY: false,
  MV: false,
  ML: false,
  MT: false,
  MH: false,
  MQ: false,
  MR: false,
  MU: false,
  YT: false,
  MX: false,
  FM: false,
  MD: false,
  MC: false,
  MN: false,
  MS: false,
  MA: false,
  MZ: false,
  MM: false,
  NA: false,
  NR: false,
  NP: false,
  NL: false,
  NC: false,
  NZ: false,
  NI: false,
  NE: false,
  NG: false,
  NU: false,
  NF: false,
  MK: false,
  MP: false,
  NO: false,
  OM: false,
  PK: false,
  PW: false,
  PS: false,
  PA: false,
  PG: false,
  PY: false,
  PE: false,
  PH: false,
  PN: false,
  PL: false,
  PT: false,
  PR: false,
  QA: false,
  RE: false,
  RO: false,
  RU: false,
  RW: false,
  SH: false,
  KN: false,
  LC: false,
  PM: false,
  VC: false,
  WS: false,
  SM: false,
  ST: false,
  SA: false,
  SN: false,
  SC: false,
  SL: false,
  SG: false,
  SK: false,
  SI: false,
  SB: false,
  SO: false,
  ZA: false,
  GS: false,
  ES: false,
  LK: false,
  SD: false,
  SR: false,
  SJ: false,
  SZ: false,
  SE: false,
  CH: false,
  SY: false,
  TW: false,
  TJ: false,
  TZ: false,
  TH: false,
  TL: false,
  TG: false,
  TK: false,
  TO: false,
  TT: false,
  TN: false,
  TR: false,
  TM: false,
  TC: false,
  TV: false,
  UG: false,
  UA: false,
  AE: false,
  GB: false,
  US: false,
  UM: false,
  UY: false,
  UZ: false,
  VU: false,
  VE: false,
  VN: false,
  VG: false,
  VI: false,
  WF: false,
  EH: false,
  YE: false,
  ZM: false,
  ZW: false,
  AX: false,
  BQ: false,
  CW: false,
  GG: false,
  IM: false,
  JE: false,
  ME: false,
  BL: false,
  MF: false,
  RS: false,
  SX: false,
  SS: false,
  XK: false,
})
