import * as React from 'react';
const DEFAULT_IMAGE_STYLE = {
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
    fill: '#e7eaf5',
};
export const Other = ({ style }) => {
    const styleWithDefault = Object.assign(Object.assign({}, DEFAULT_IMAGE_STYLE), style);
    return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "prefix__Other_Icon", width: "25", height: "25", "data-name": "Other Icon", viewBox: "0 0 25 25" },
        React.createElement("path", { id: "prefix__BG", d: "M18.809 0H6.191A6.256 6.256 0 0 0 0 6.191v12.618A6.2 6.2 0 0 0 6.191 25h12.618A6.2 6.2 0 0 0 25 18.809V6.191A6.256 6.256 0 0 0 18.809 0z", fill: "#f3f3f4" }),
        React.createElement("g", { id: "prefix__folder", transform: "translate(2.767 -24.373)" },
            React.createElement("g", { id: "prefix__Group_11108", "data-name": "Group 11108", transform: "translate(0 28.293)" },
                React.createElement("path", { id: "prefix__Path_9826", d: "M19.626 33.137a1.714 1.714 0 0 0-1.35-.638h-.785a1.824 1.824 0 0 0-1.682-1.4H7.36a1.053 1.053 0 0 1-1.051-1.051 1.754 1.754 0 0 0-1.752-1.752h-2.8A1.754 1.754 0 0 0 0 30.045v14.02a1.754 1.754 0 0 0 1.752 1.752h.118a1.818 1.818 0 0 0 .227 0h13.98a1.714 1.714 0 0 0 1.69-1.348l2.2-9.883a1.7 1.7 0 0 0-.341-1.449zM.7 30.045a1.053 1.053 0 0 1 1.051-1.051h2.8a1.053 1.053 0 0 1 1.051 1.051A1.754 1.754 0 0 0 7.36 31.8h8.448a1.1 1.1 0 0 1 .959.7H4.25a1.714 1.714 0 0 0-1.69 1.348L.7 42.2zm18.55 4.382l-2.2 9.9a1 1 0 0 1-.975.792H2.1a1.047 1.047 0 0 1-1.011-1.272l2.187-9.852a1 1 0 0 1 .975-.792h14.024a1.01 1.01 0 0 1 .976 1.228z", "data-name": "Path 9826", transform: "translate(0 -28.293)", fill: "#8e8e93", stroke: "#8e8e93", strokeWidth: ".1px" })))));
};
