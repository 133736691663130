/* eslint-disable @typescript-eslint/no-deprecated */
import * as React from 'react';
import styled from 'styled-components';
import { DescriptionOutlined } from '@mui/icons-material';
import { Text } from '../../styles';
export const ResourceLink = ({ resourceURL, linkText = 'Further Information', additionalText, }) => (React.createElement(Link, { href: resourceURL, target: "_blank", rel: "noopener" },
    React.createElement(Icon, null),
    React.createElement(Description, { as: "p" }, linkText),
    additionalText || React.createElement(Text, null, additionalText)));
const Link = styled.a `
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: ${({ theme }) => theme.colors.primary.medium};
  margin-bottom: 3rem;
`;
const Icon = styled(DescriptionOutlined) `
  width: 2.4rem !important;
  height: 2.4rem !important;
  margin-right: 1rem;
`;
const Description = styled(Text) `
  color: ${({ theme }) => theme.colors.primary.medium};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.medium};
  margin-bottom: 0;
`;
