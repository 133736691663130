/* eslint-disable @typescript-eslint/no-deprecated */

import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Breakpoint } from '@tumelo/shared'

interface Props {
  header?: ReactNode
}

export const CenterLayout: React.FC<React.PropsWithChildren<Props>> = ({ children, header }) => {
  return (
    <Container>
      <Column tabIndex={0}>
        {header}
        <Main>{children}</Main>
      </Column>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  // 66 at end is hex code alpha transparency (40%)
  background: ${({ theme }) => theme.colors.light}66;
  @media (max-width: ${Breakpoint.tablet}) {
    flex-direction: column;
  }
`

const Column = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-wrap: anywhere;
  flex: 0 0 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  background: ${(p) => p.theme.colors.white};
  @media (min-width: ${Breakpoint.desktop}) {
    flex: 0 0 80rem;
  }
`

const Main = styled.main`
  padding: 3rem 1rem 1rem;
  @media (min-width: ${Breakpoint.tablet}) {
    padding: 1rem;
  }
`
