import React from 'react'
import { Amplify, ResourcesConfig } from 'aws-amplify'
import { I18n } from 'aws-amplify/utils'
import { Loader } from '@tumelo/shared'
import { useForceNewSession, useInitAuthState } from '../application/features/auth/hooks'
import { useAppSelector } from '../application/store'
import { getQueryVariable } from '../utils/getQueryVariable'
import { ConfigAws } from '../config/ConfigAws'
import { GenericError } from '../components/Error'
import { SignUp } from './CustomSignUp'
import { ConfirmSignUp } from './CustomConfirmSignUp'
import { SignIn } from './CustomSignIn'
import { ForgotPassword } from './CustomForgotPassword'
import { ConfirmSignIn } from './CustomConfirmSignIn'

const START_SIGN_IN_QUERY_KEY = 'startSignIn'
const startNewSession = getQueryVariable(START_SIGN_IN_QUERY_KEY) === '1'

export const configure = (configuration: ConfigAws) => {
  const config: ResourcesConfig = {
    Auth: {
      Cognito: {
        userPoolId: configuration.aws_user_pools_id,
        userPoolClientId: configuration.aws_user_pools_web_client_id,
        loginWith:
          configuration.oauth !== undefined && configuration.oauth.aws_cognito_domain
            ? {
                oauth: {
                  domain: configuration.oauth.aws_cognito_domain,
                  redirectSignIn: [window.location.origin], // 'http://localhost:3000',
                  redirectSignOut: [`${window.location.origin}${startNewSession ? '?startSignIn=1' : ''}`], // 'http://localhost:3000',
                  scopes: ['profile', 'openid', 'aws.cognito.signin.user.admin'],
                  responseType: 'code',
                },
              }
            : undefined,
      },
    },
  }
  Amplify.configure(config)
}

const authLabels = {
  en: {
    'Confirm Sign Up': 'We have sent an email containing a confirmation code to the following address',
    'Lost your code? ':
      "It can take a couple of minutes for your code to arrive. If you have problems, please check we have the correct email address. Don't forget to check your spam/junk box. Still no joy? ",
    Username: 'Email',
    'Back to Sign In': 'Go to Sign In',
    'Create account': 'Sign Up',
    'Enter your username': 'Enter your email address',
    'User does not exist': 'We do not recognise this email address. Please sign up below.',
    'Custom auth lambda trigger is not configured for the user pool.': 'You need to put in your password.',
    'Incorrect username or password.': 'Incorrect email or password. Please check your details again.',
    'An account with the given email already exists.':
      'An account with that email address already exists. Please check your email address is correct or sign in below.',
    'Invalid verification code provided, please try again.':
      'Your verification code is not correct, please try again or ask for another code below.',
    'PreSignUp failed with error email_address_not_supported.':
      'Sorry, this email address is not allowed. Please use another address.',
    'A failure occurred when returning to the Cognito Hosted UI':
      'Something went wrong. Please try again in a few minutes, if the problem persists contact support@tumelo.com',
    'Invalid code or auth state for the user.':
      'Your verification code is not correct. If you have requested a new code, please check this is the most recent and try again.',
  },
}

I18n.setLanguage('en')
I18n.putVocabularies(authLabels)

export const AuthWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const authState = useAppSelector((state) => state.auth.authState)
  useInitAuthState()
  const { status } = useForceNewSession()

  // Handle force new session logic first
  if (status === 'pending') {
    return <Loader />
  }
  if (status === 'error') {
    return <GenericError onClick={() => window.location.reload()} />
  }

  // Handle each possible auth state
  if (authState === 'authenticated') {
    return <>{children}</>
  }
  if (authState === 'not-initialized' || authState === 'federatedSignIn' || authState === 'signOut') {
    return <Loader />
  }
  if (authState === 'confirmSignIn') {
    return <ConfirmSignIn />
  }
  if (authState === 'confirmSignUp') {
    return <ConfirmSignUp />
  }
  if (authState === 'forgotPassword') {
    return <ForgotPassword />
  }
  if (authState === 'signUp') {
    return <SignUp />
  }
  if (authState === 'error') {
    return <GenericError onClick={() => window.location.reload()} />
  }

  return <SignIn />
}
