import * as React from 'react';
import { useTheme } from 'styled-components';
export const EmptyNotificationsIcon = () => {
    const { colors } = useTheme();
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "181.175", height: "127.771" },
        React.createElement("defs", null),
        React.createElement("g", { id: "No-Notifications_image", "data-name": "No-Notifications image", transform: "translate(-899.57 -200)" },
            React.createElement("g", { id: "Group_21090", "data-name": "Group 21090", transform: "translate(899.57 241.428)", style: { opacity: '.6' } },
                React.createElement("path", { id: "Polygon", style: { fill: colors.light }, d: "m12.71 0 12.71 12.71-12.71 12.709L0 12.71z", transform: "translate(145.501)" }),
                React.createElement("circle", { id: "Oval", style: { fill: colors.light }, cx: "9", cy: "9", r: "9", transform: "translate(0 68.344)" }),
                React.createElement("path", { id: "Polygon-2", "data-name": "Polygon", style: { fill: colors.light }, d: "m5.366 0 5.366 5.366-5.366 5.365L0 5.366z", transform: "translate(170.444 27.579)" })),
            React.createElement("g", { id: "Group_21092", "data-name": "Group 21092", transform: "translate(927.976 200)" },
                React.createElement("g", { id: "Notification_icon", transform: "translate(0 5.5)" },
                    React.createElement("path", { id: "Path_12671", style: { fill: colors.light }, d: "M112.994 85.113S99.6 74.9 99.6 41.445a41.418 41.418 0 1 0-82.837 0C16.766 74.9 3.534 85.06 3.587 85.06a7.887 7.887 0 0 0 4.393 14.5h100.568a7.834 7.834 0 0 0 7.463-5.293 8.045 8.045 0 0 0-3.017-9.157z", transform: "translate(.009)" }),
                    React.createElement("path", { id: "Path_12672", style: { fill: colors.light }, d: "M7.991 30.357c2.33 4.092 6.7 9.521 10.791 11.851a26.963 26.963 0 0 0 20.5 2.763c6.929-1.841 11.86-8.405 15.446-14.614-1.986-.287-43.774-.409-46.737 0z", transform: "translate(27.009 74.662)" })),
                React.createElement("g", { id: "Group_21091", "data-name": "Group 21091", transform: "translate(65)" },
                    React.createElement("circle", { id: "Ellipse_1086", "data-name": "Ellipse 1086", cx: "25.5", cy: "25.5", r: "25.5", style: { fill: colors.grey.dark } }),
                    React.createElement("text", { id: "_0", "data-name": "0", transform: "translate(17 34)", style: {
                            fill: colors.background.light,
                            fontSize: '25px',
                            fontFamily: 'Montserrat-Bold,Montserrat',
                            fontWeight: 700,
                        } },
                        React.createElement("tspan", { x: "0", y: "0" }, "0")))))));
};
