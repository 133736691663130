import React from 'react';
import styled from 'styled-components';
import { Column, Grid } from '../Grid';
import { Stack } from '../Stack';
import { Body1 } from '../Typography';
import { screen } from '../_utils';
import { ModalAriaDescribedBy } from './BlankModal';
export const ModalBody = ({ fullScreen = false, media, body, children, }) => {
    const content = () => (React.createElement(Stack, { flexDirection: "column" },
        !children && media && media.type === 'image' && (React.createElement(ModalImage, { src: media.src, alt: media.altText, crossOrigin: "" })),
        !children && media && media.type === 'video' && (React.createElement(StyledIframeContainer, null,
            React.createElement("iframe", { title: media.title, src: `https://player.vimeo.com/video/${media.vimeoID}`, frameBorder: "0", allow: "autoplay; fullscreen", allowFullScreen: true }))),
        !children && body && React.createElement(Body1, null, body),
        children));
    return (React.createElement(StyledScrolling, { id: ModalAriaDescribedBy, fullScreen: fullScreen }, fullScreen ? (React.createElement(StyledGrid, null,
        React.createElement(StyledColumn, { lg: 3, md: 2, sm: 0, xs: 0 }),
        React.createElement(StyledColumn, { lg: 6, md: 8, sm: 12, xs: 12 }, content()),
        React.createElement(StyledColumn, { lg: 3, md: 2, sm: 0, xs: 0 }))) : (content())));
};
const StyledScrolling = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% + 1.2rem);
  overflow-y: scroll;
  padding-bottom: 1.6rem;
  justify-content: flex-start;
  scrollbar-gutter: stable;
  margin-left: 1.2rem;
  padding-right: 0.4rem;
  &::-webkit-scrollbar {
    width: 0.8rem;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey.medium};
    border-radius: 0.8rem;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.grey.dark};
  }
  @media ${({ fullScreen }) => (fullScreen ? screen.mediumUp : screen.smallUp)} {
    width: calc(100% + 1.6rem);
  }
  @media ${screen.smallUp} {
    margin-left: 1.6rem;
    padding-right: 0.8rem;
  }
`;
// The functional syntax below fixes the error 'Cannot create styled-component for component: undefined.'
const StyledGrid = styled((props) => React.createElement(Grid, Object.assign({}, props))) `
  height: 100%;
  align-self: center;
  width: 100%;
  max-width: 140rem;
  @media ${screen.mediumUp} {
    padding: 0 5.6rem 0 5.6rem;
  }
`;
// The functional syntax below fixes the error 'Cannot create styled-component for component: undefined.'
const StyledColumn = styled((props) => React.createElement(Column, Object.assign({}, props))) `
  flex-direction: column;
  justify-content: center;
  @media ${screen.xsmallOnly} {
    justify-content: flex-start;
  }
`;
export const ModalImage = styled.img `
  width: 100%;
  margin-bottom: 0.8rem;
  @media ${screen.smallUp} {
    margin-bottom: 1.6rem;
  }
`;
const StyledIframeContainer = styled.div `
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
