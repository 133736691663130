/* eslint-disable @typescript-eslint/no-deprecated */
import styled, { css } from 'styled-components';
import { color, typography, margin } from 'styled-system';
import { Breakpoint } from '../../styles';
/**
 * @deprecated use theme instead (same way as colors)
 */
export var FontWeight;
(function (FontWeight) {
    FontWeight["normal"] = "normal";
    FontWeight["bold"] = "bold";
    FontWeight[FontWeight["semiBold"] = 600] = "semiBold";
})(FontWeight || (FontWeight = {}));
export const bodyFontSize = {
    Body: css `
    font-size: 1.6rem;
    @media (min-width: ${Breakpoint.mobile}) {
      font-size: 2rem;
    }
  `,
    SmallBody: css `
    font-size: 1.4rem;
  `,
    Button: css `
    font-size: 1.4rem;
  `,
    Caption: css `
    font-size: 1.6rem;
  `,
};
/**
 * @deprecated use designsystem/Typography instead
 */
export const BaseTypography = styled.p `
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.colors.dark};
  ${color};
  ${typography};
  ${margin};
`;
/**
 * @deprecated use designsystem/Typography instead
 */
export const Body = styled(BaseTypography) `
  font-size: 1.6rem;
  line-height: 2.4rem;
  @media (min-width: ${Breakpoint.mobile}) {
    font-size: 2rem;
    line-height: 2.8rem;
  }
`;
/**
 * @deprecated use designsystem/Typography instead
 */
export const SmallBody = styled(BaseTypography) `
  font-size: 1.4rem;
`;
/**
 * @deprecated use designsystem/Typography instead
 */
export const ButtonText = styled(BaseTypography) `
  font-size: 1.4rem;
  line-height: 2.4rem;
  font-weight: ${FontWeight.bold};
`;
// TODO refactor so that there is only one Caption in the shared and rename this back to Caption
/**
 * @deprecated use designsystem/Typography instead
 */
export const TypographyCaption = styled(BaseTypography) `
  font-size: 1.6rem;
  line-height: 2.4rem;
`;
/**
 * @deprecated use designsystem/Typography instead
 */
export const Span = styled(BaseTypography).attrs({
    as: 'span',
}) ``;
