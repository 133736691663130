export var PollTag;
(function (PollTag) {
    PollTag["Other"] = "1";
    PollTag["Equality"] = "2";
    PollTag["AnimalWelfare"] = "3";
    PollTag["Audit"] = "4";
    PollTag["DefenceSecurity"] = "5";
    PollTag["Environment"] = "6";
    PollTag["HumanRights"] = "7";
    PollTag["Lobbying"] = "8";
    PollTag["Management"] = "9";
})(PollTag || (PollTag = {}));
export const PollTagMap = new Map([
    [PollTag.Other, 'Other'],
    [PollTag.Equality, 'Equality'],
    [PollTag.AnimalWelfare, 'AnimalWelfare'],
    [PollTag.Audit, 'Audit'],
    [PollTag.DefenceSecurity, 'DefenceSecurity'],
    [PollTag.Environment, 'Environment'],
    [PollTag.HumanRights, 'HumanRights'],
    [PollTag.Lobbying, 'Lobbying'],
    [PollTag.Management, 'Management'],
]);
