/* eslint-disable @typescript-eslint/no-deprecated */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { SwipeableMenuContainer } from '../../../../buildingBlocks/SwipeableMenuContainer';
import { CloseIcon } from '../../../../buildingBlocks/SwipeableMenuContainer/CloseIcon';
import { SmallHeading } from '../../../../buildingBlocks/Typography';
export const MobileSearchMenu = ({ heading, isOpen, toggleOpen, children, }) => {
    const theme = useTheme();
    return (React.createElement(SwipeableMenuContainer, { backgroundColor: theme.colors.background.light, isOpen: isOpen, toggleOpen: toggleOpen, showCloseIcon: false },
        React.createElement(Container, null,
            React.createElement(Header, null,
                React.createElement(SmallHeading, null, heading),
                React.createElement(CloseIcon, { color: theme.colors.dark, onClick: toggleOpen })),
            children)));
};
const Container = styled.div `
  padding: 3rem 4rem;
`;
const Header = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
