import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgWinning = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { clipPath: "url(#clip0_317_849)" },
            React.createElement("path", { d: "M20.95 2H16.22C16.0844 1.99983 15.9501 2.02726 15.8254 2.08061C15.7007 2.13396 15.5882 2.21213 15.4946 2.31035C15.4011 2.40857 15.3285 2.52479 15.2813 2.65195C15.2341 2.7791 15.2132 2.91454 15.22 3.05V3.38H9.34C9.14087 3.37901 8.94598 3.43748 8.78029 3.54794C8.6146 3.6584 8.48567 3.81581 8.41 4C8.33714 4.19405 8.32007 4.40461 8.36072 4.60786C8.40137 4.8111 8.49812 4.99891 8.64 5.15L10.01 6.57L8.61 8.16C8.47446 8.31097 8.38463 8.49735 8.35099 8.69743C8.31735 8.8975 8.34128 9.10301 8.42 9.29C8.49189 9.47636 8.61795 9.63692 8.78192 9.75099C8.94589 9.86506 9.14027 9.92741 9.34 9.93H16.22C16.3565 9.93025 16.4917 9.90254 16.6171 9.84857C16.7425 9.79461 16.8555 9.71553 16.9492 9.61622C17.0429 9.51692 17.1152 9.39947 17.1618 9.27114C17.2084 9.1428 17.2282 9.00628 17.22 8.87V8.24H19.95V12.48H14.95C14.6863 12.4663 14.428 12.5575 14.2313 12.7336C14.0346 12.9097 13.9155 13.1564 13.9 13.42V14.17H8.95C8.69648 14.1694 8.45218 14.2651 8.26653 14.4377C8.08089 14.6104 7.96775 14.8471 7.95 15.1V16.7H3C2.74486 16.6995 2.49919 16.7966 2.31329 16.9714C2.12739 17.1461 2.01531 17.3853 2 17.64V22.07C2.01775 22.3229 2.13089 22.5596 2.31653 22.7323C2.50218 22.9049 2.74648 23.0006 3 23H20.95C21.2035 23.0006 21.4478 22.9049 21.6335 22.7323C21.8191 22.5596 21.9323 22.3229 21.95 22.07V3.07C21.9596 2.93291 21.9409 2.79531 21.895 2.66579C21.849 2.53627 21.7769 2.4176 21.6831 2.3172C21.5892 2.21679 21.4757 2.13681 21.3496 2.08223C21.2234 2.02766 21.0874 1.99967 20.95 2ZM15.22 6.89V7.82H11.64L12.14 7.25C12.3234 7.04915 12.4251 6.78699 12.4251 6.515C12.4251 6.24301 12.3234 5.98085 12.14 5.78L11.86 5.48H15.26L15.22 6.89ZM7.95 21.13H4.06V18.57H7.95V21.13ZM13.95 21.13H10.02V16H13.88L13.95 21.13ZM17.29 6.13V4.13H19.95V6.13H17.29ZM19.91 21.13H15.95V14.35H19.81L19.91 21.13Z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_317_849" },
                React.createElement("rect", { width: 24, height: 24, fill: "white" })))));
};
