import React from 'react';
import { Collapse } from '@mui/material';
import styled, { useTheme } from 'styled-components';
export const CardHeader = ({ children, helpText, className }) => {
    const { colors } = useTheme();
    const [isOpen, setOpen] = React.useState(false);
    return (React.createElement(HeaderContainer, { isOpen: isOpen, className: className },
        React.createElement(SubHeader, null,
            React.createElement("div", null, children),
            helpText ? (React.createElement(Icon, { onClick: () => setOpen(!isOpen) }, isOpen ? (React.createElement("svg", { fill: colors.primary.medium, xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", viewBox: "0 0 20 20" },
                React.createElement("g", { id: "Group_13185", "data-name": "Group 13185", transform: "translate(-400 -40)" },
                    React.createElement("path", { id: "_", "data-name": "(?)", d: "M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,10,18Z", transform: "translate(400 40)" }),
                    React.createElement("path", { id: "Path_9357", "data-name": "Path 9357", d: "M14.164,5.613a.676.676,0,0,0-.958,0L9.885,8.927,6.564,5.606a.677.677,0,0,0-.958.958L8.927,9.885,5.606,13.206a.677.677,0,1,0,.958.958l3.321-3.321,3.321,3.321a.677.677,0,1,0,.958-.958L10.843,9.885l3.321-3.321A.681.681,0,0,0,14.164,5.613Z", transform: "translate(400.092 40)", stroke: colors.primary.medium, strokeWidth: "0.5" })))) : (React.createElement("svg", { fill: colors.primary.medium, xmlns: "http://www.w3.org/2000/svg", id: "helpIcon", width: "20", height: "20", "data-name": "Layer 2", "data-test": "Help Button", viewBox: "0 0 20 20" },
                React.createElement("g", { id: "Layer_1", "data-name": "Layer 1" },
                    React.createElement("path", { id: "Path_8428", d: "M9 16h2v-2H9zm1-16a10 10 0 1 0 10 10A10 10 0 0 0 10 0zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm0-14a4 4 0 0 0-4 4h2a2 2 0 0 1 4 0c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5a4 4 0 0 0-4-4z", className: "cls-1", "data-name": "Path 8428" })))))) : null),
        helpText ? (React.createElement(Collapse, { in: isOpen },
            React.createElement(HelperText, null, helpText))) : null));
};
const HelperText = styled.p `
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.grey.dark};
  white-space: pre-wrap;
`;
const HeaderContainer = styled.div `
  &:first-child {
    border-radius: 2rem 2rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 2rem 2rem;
  }
  box-shadow: ${({ isOpen }) => (isOpen ? '0 0 1rem 0 rgba(0, 0, 0, 0.16)' : 'none')};
  padding: 1rem 3rem 0;
`;
const SubHeader = styled.div `
  align-items: flex-start;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0;
`;
const Icon = styled.div `
  margin-top: 2rem;
  justify-content: flex-end;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.medium};
`;
