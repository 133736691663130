import * as React from 'react'
import md5 from 'crypto-js/md5'
import styled from 'styled-components'
import { glowing } from '../../styles/animation'

interface Props {
  email?: string
  size?: number
  loading?: boolean
}

export const ProfileImage: React.FC<Props> = ({ email, size = 400, loading = false }) => {
  if (loading) return <Loading size={size} />

  return <Profile src={getGravatarImage(email)} alt="Your profile" size={size} />
}

const getGravatarImage = (email?: string): string => {
  if (!email) {
    return `https://www.gravatar.com/avatar?s=400&d=mp`
  }

  return `https://www.gravatar.com/avatar/${convertEmailToHash(email)}?s=400&d=mp`
}

const convertEmailToHash = (email: string): string => {
  return md5(email.toLowerCase()).toString()
}

interface ImageProps {
  size: number
}

const Loading = styled.div<ImageProps>`
  border-radius: 50%;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  background-color: ${({ theme }) => theme.colors.light};

  animation: ${glowing} 2s infinite;
`

const Profile = styled.img<ImageProps>`
  border-radius: 50%;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
`
