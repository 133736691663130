import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Stack } from '../Stack';
import { Button } from '../Button';
import { ArrowLeftIcon, CrossIcon } from '../Icons';
import { H2 } from '../Typography';
import { ModalAriaLabelledBy } from './BlankModal';
export const ModalHeader = ({ onCloseCallback, onBackCallback: onBack, title }) => {
    const colors = useTheme();
    return (React.createElement(StyledHeaderStack, { flexDirection: "row", justifyContent: "flex-start", alignItems: "center" },
        onBack && (React.createElement(StyledBackButton, { iconOnly: React.createElement(ArrowLeftIcon, { fill: colors.colors.primary.medium }), iconOnlyAltText: "Back to previous page", variant: "tertiary", onClick: onBack })),
        title && React.createElement(StyledTitle, { id: ModalAriaLabelledBy }, title),
        React.createElement(StyledSpacer, null),
        React.createElement(StyledCloseButton, { iconOnly: React.createElement(CrossIcon, { fill: colors.colors.primary.medium }), iconOnlyAltText: `Close ${title ? `${title} ` : null}modal`, variant: "tertiary", onClick: () => onCloseCallback('backdropClick') })));
};
const StyledHeaderStack = styled(Stack) `
  max-width: 108.8rem;
  margin-top: -0.8rem;
  width: 100%;
`;
// const StyledBackButton = styled((props) => <Button {...props} />)`
const StyledBackButton = styled(Button) `
  justify-self: flex-start;
  align-self: flex-start;
  margin-left: -0.8rem;
  svg {
    fill: ${({ theme }) => theme.colors.primary.medium};
  }
`;
const StyledTitle = styled(H2) `
  margin-top: 0.4rem;
`;
// const StyledCloseButton = styled((props) => <Button {...props} />)`
const StyledCloseButton = styled(Button) `
  justify-self: flex-end;
  align-self: flex-start;
  margin-right: -0.8rem;
  svg {
    fill: ${({ theme }) => theme.colors.primary.medium};
  }
`;
const StyledSpacer = styled.span `
  flex-grow: 1;
`;
