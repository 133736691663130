import { PollTag } from '../../domain';
const colorMap = new Map([
    [PollTag.Other, '#8e8e93'],
    [PollTag.Equality, '#ffd54f'],
    [PollTag.AnimalWelfare, '#e06fd3'],
    [PollTag.Audit, '#894a24'],
    [PollTag.DefenceSecurity, '#1731a0'],
    [PollTag.Environment, '#3fb962'],
    [PollTag.HumanRights, '#6853bd'],
    [PollTag.Lobbying, '#d30245'],
    [PollTag.Management, '#2e5e22'],
]);
export const getTagColor = (tagId) => {
    return colorMap.get(tagId);
};
