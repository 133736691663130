import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgCalendar = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M19.49 2.52H16.91V2C16.91 1.73478 16.8047 1.48043 16.6171 1.29289C16.4296 1.10536 16.1752 1 15.91 1C15.6448 1 15.3904 1.10536 15.2029 1.29289C15.0154 1.48043 14.91 1.73478 14.91 2V2.54H9.10001V2C9.10001 1.73478 8.99465 1.48043 8.80712 1.29289C8.61958 1.10536 8.36523 1 8.10001 1C7.83479 1 7.58044 1.10536 7.3929 1.29289C7.20537 1.48043 7.10001 1.73478 7.10001 2V2.54H4.51001C3.72465 2.53984 2.97054 2.84766 2.40962 3.39736C1.84871 3.94706 1.52572 4.69479 1.51001 5.48V20.08C1.52572 20.8652 1.84871 21.6129 2.40962 22.1626C2.97054 22.7123 3.72465 23.0202 4.51001 23.02H19.51C20.2954 23.0202 21.0495 22.7123 21.6104 22.1626C22.1713 21.6129 22.4943 20.8652 22.51 20.08V5.46C22.4943 4.67132 22.1685 3.92058 21.6033 3.37033C21.038 2.82007 20.2788 2.51458 19.49 2.52ZM4.49001 4.52H7.09001V5C7.09001 5.26522 7.19537 5.51957 7.3829 5.70711C7.57044 5.89464 7.82479 6 8.09001 6C8.35523 6 8.60958 5.89464 8.79712 5.70711C8.98465 5.51957 9.09001 5.26522 9.09001 5V4.48H14.89V5C14.89 5.26522 14.9954 5.51957 15.1829 5.70711C15.3704 5.89464 15.6248 6 15.89 6C16.1552 6 16.4096 5.89464 16.5971 5.70711C16.7847 5.51957 16.89 5.26522 16.89 5V4.48H19.47C19.7352 4.48 19.9896 4.58536 20.1771 4.77289C20.3647 4.96043 20.47 5.21478 20.47 5.48V8.33H3.47001V5.46C3.47256 5.32694 3.50165 5.19572 3.55558 5.07404C3.6095 4.95237 3.68716 4.84268 3.78402 4.7514C3.88089 4.66013 3.99499 4.58911 4.11965 4.54251C4.24432 4.4959 4.37703 4.47465 4.51001 4.48L4.49001 4.52ZM19.49 21H4.49001C4.22479 21 3.97044 20.8946 3.7829 20.7071C3.59537 20.5196 3.49001 20.2652 3.49001 20V10.29H20.49V20.06C20.4747 20.3147 20.3626 20.5539 20.1767 20.7286C19.9908 20.9034 19.7451 21.0005 19.49 21Z" }),
        React.createElement("path", { d: "M10.37 12.07H9.61001C9.34479 12.07 9.09044 12.1754 8.9029 12.3629C8.71537 12.5504 8.61001 12.8048 8.61001 13.07C8.61001 13.3352 8.71537 13.5896 8.9029 13.7771C9.09044 13.9646 9.34479 14.07 9.61001 14.07H10.37C10.6352 14.07 10.8896 13.9646 11.0771 13.7771C11.2647 13.5896 11.37 13.3352 11.37 13.07C11.37 12.8048 11.2647 12.5504 11.0771 12.3629C10.8896 12.1754 10.6352 12.07 10.37 12.07Z" }),
        React.createElement("path", { d: "M14.39 12.07H13.63C13.3648 12.07 13.1104 12.1754 12.9229 12.3629C12.7354 12.5504 12.63 12.8048 12.63 13.07C12.63 13.3352 12.7354 13.5896 12.9229 13.7771C13.1104 13.9646 13.3648 14.07 13.63 14.07H14.39C14.6552 14.07 14.9096 13.9646 15.0971 13.7771C15.2847 13.5896 15.39 13.3352 15.39 13.07C15.39 12.8048 15.2847 12.5504 15.0971 12.3629C14.9096 12.1754 14.6552 12.07 14.39 12.07Z" }),
        React.createElement("path", { d: "M18.41 12.07H17.65C17.3848 12.07 17.1304 12.1754 16.9429 12.3629C16.7554 12.5504 16.65 12.8048 16.65 13.07C16.65 13.3352 16.7554 13.5896 16.9429 13.7771C17.1304 13.9646 17.3848 14.07 17.65 14.07H18.41C18.6752 14.07 18.9296 13.9646 19.1171 13.7771C19.3047 13.5896 19.41 13.3352 19.41 13.07C19.41 12.8048 19.3047 12.5504 19.1171 12.3629C18.9296 12.1754 18.6752 12.07 18.41 12.07Z" }),
        React.createElement("path", { d: "M6.35001 15H5.59001C5.32479 15 5.07044 15.1054 4.8829 15.2929C4.69537 15.4804 4.59001 15.7348 4.59001 16C4.59001 16.2652 4.69537 16.5196 4.8829 16.7071C5.07044 16.8946 5.32479 17 5.59001 17H6.35001C6.61523 17 6.86958 16.8946 7.05712 16.7071C7.24465 16.5196 7.35001 16.2652 7.35001 16C7.35001 15.7348 7.24465 15.4804 7.05712 15.2929C6.86958 15.1054 6.61523 15 6.35001 15Z" }),
        React.createElement("path", { d: "M10.37 15H9.61001C9.34479 15 9.09044 15.1054 8.9029 15.2929C8.71537 15.4804 8.61001 15.7348 8.61001 16C8.61001 16.2652 8.71537 16.5196 8.9029 16.7071C9.09044 16.8946 9.34479 17 9.61001 17H10.37C10.6352 17 10.8896 16.8946 11.0771 16.7071C11.2647 16.5196 11.37 16.2652 11.37 16C11.37 15.7348 11.2647 15.4804 11.0771 15.2929C10.8896 15.1054 10.6352 15 10.37 15Z" }),
        React.createElement("path", { d: "M14.39 15H13.63C13.3648 15 13.1104 15.1054 12.9229 15.2929C12.7354 15.4804 12.63 15.7348 12.63 16C12.63 16.2652 12.7354 16.5196 12.9229 16.7071C13.1104 16.8946 13.3648 17 13.63 17H14.39C14.6552 17 14.9096 16.8946 15.0971 16.7071C15.2847 16.5196 15.39 16.2652 15.39 16C15.39 15.7348 15.2847 15.4804 15.0971 15.2929C14.9096 15.1054 14.6552 15 14.39 15Z" }),
        React.createElement("path", { d: "M18.41 15H17.65C17.3848 15 17.1304 15.1054 16.9429 15.2929C16.7554 15.4804 16.65 15.7348 16.65 16C16.65 16.2652 16.7554 16.5196 16.9429 16.7071C17.1304 16.8946 17.3848 17 17.65 17H18.41C18.6752 17 18.9296 16.8946 19.1171 16.7071C19.3047 16.5196 19.41 16.2652 19.41 16C19.41 15.7348 19.3047 15.4804 19.1171 15.2929C18.9296 15.1054 18.6752 15 18.41 15Z" }),
        React.createElement("path", { d: "M6.35001 18H5.59001C5.32479 18 5.07044 18.1054 4.8829 18.2929C4.69537 18.4804 4.59001 18.7348 4.59001 19C4.59001 19.2652 4.69537 19.5196 4.8829 19.7071C5.07044 19.8946 5.32479 20 5.59001 20H6.35001C6.61523 20 6.86958 19.8946 7.05712 19.7071C7.24465 19.5196 7.35001 19.2652 7.35001 19C7.35001 18.7348 7.24465 18.4804 7.05712 18.2929C6.86958 18.1054 6.61523 18 6.35001 18Z" }),
        React.createElement("path", { d: "M10.37 18H9.61001C9.34479 18 9.09044 18.1054 8.9029 18.2929C8.71537 18.4804 8.61001 18.7348 8.61001 19C8.61001 19.2652 8.71537 19.5196 8.9029 19.7071C9.09044 19.8946 9.34479 20 9.61001 20H10.37C10.6352 20 10.8896 19.8946 11.0771 19.7071C11.2647 19.5196 11.37 19.2652 11.37 19C11.37 18.7348 11.2647 18.4804 11.0771 18.2929C10.8896 18.1054 10.6352 18 10.37 18Z" }),
        React.createElement("path", { d: "M14.39 18H13.63C13.3648 18 13.1104 18.1054 12.9229 18.2929C12.7354 18.4804 12.63 18.7348 12.63 19C12.63 19.2652 12.7354 19.5196 12.9229 19.7071C13.1104 19.8946 13.3648 20 13.63 20H14.39C14.6552 20 14.9096 19.8946 15.0971 19.7071C15.2847 19.5196 15.39 19.2652 15.39 19C15.39 18.7348 15.2847 18.4804 15.0971 18.2929C14.9096 18.1054 14.6552 18 14.39 18Z" })));
};
