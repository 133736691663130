import * as React from 'react';
export const SvgPiechartsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#EEF6FF" }),
        React.createElement("path", { d: "M21.69 12.33C21.5 12.12 21.23 12 20.94 12H12V3.04998C12 2.76998 11.88 2.48998 11.67 2.29998C11.46 2.10998 11.17 2.01998 10.89 2.04998C9.01 2.25998 7.24 2.98998 5.77 4.16998C4.3 5.33998 3.19 6.90998 2.56 8.68998C1.94 10.47 1.83 12.38 2.25 14.22C2.67 16.06 3.6 17.73 4.93 19.07C6.26 20.4 7.94 21.33 9.78 21.75C10.51 21.92 11.26 22 12 22C13.12 22 14.24 21.81 15.31 21.44C17.09 20.82 18.65 19.71 19.83 18.23C21 16.75 21.74 14.98 21.95 13.11C21.98 12.83 21.89 12.54 21.7 12.33H21.69ZM18.26 16.98C17.32 18.16 16.07 19.05 14.65 19.55C13.23 20.05 11.7 20.14 10.23 19.8C8.76 19.47 7.42 18.72 6.35 17.66C5.28 16.59 4.54 15.25 4.21 13.78C3.88 12.31 3.96 10.78 4.46 9.35998C4.96 7.93998 5.85 6.68998 7.03 5.74998C7.91 5.04998 8.93 4.53998 10.01 4.25998V13C10.01 13.55 10.46 14 11.01 14H19.76C19.48 15.08 18.98 16.1 18.27 16.98H18.26ZM15 9.99998H20.49C20.82 9.99998 21.12 9.83998 21.31 9.57998C21.5 9.30998 21.54 8.97998 21.44 8.66998C20.94 7.26998 20.13 5.97998 19.08 4.92998C18.03 3.87998 16.74 3.06998 15.34 2.56998C15.04 2.45998 14.69 2.50998 14.43 2.69998C14.17 2.88998 14.01 3.18998 14.01 3.51998V9.00998C14.01 9.55998 14.46 10.01 15.01 10.01L15 9.99998ZM16 5.06998C16.6 5.41998 17.17 5.84998 17.66 6.33998C18.15 6.82998 18.58 7.38998 18.93 7.99998H16V5.06998Z", fill: "#406794" })));
};
