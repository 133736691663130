import React from 'react'
import { SwipeableMenuContainer } from '@tumelo/shared'
import { MenuConnected } from './MenuConnected'

interface Props {
  isOpen: boolean
  toggleOpen: () => void
}

export const BurgerMenu: React.FC<Props> = ({ isOpen, toggleOpen }) => {
  return (
    <SwipeableMenuContainer isOpen={isOpen} toggleOpen={toggleOpen}>
      <MenuConnected isOpen={isOpen} onClickMenuItem={toggleOpen} />
    </SwipeableMenuContainer>
  )
}
