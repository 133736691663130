/* eslint-disable @typescript-eslint/no-deprecated */

import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { BreakpointAsMaxValue, HamburgerIcon } from '@tumelo/shared'
import { useComponentVisible, useBreakpoints } from '@tumelo/shared/utils'
import { ContentMaxWidth } from '@tumelo/designsystem'
import { useAppSelector } from '../../../application/store'
import { useRouter } from '../../../application/router/useRouter'
import { BurgerMenu } from '../BurgerMenu'
import { getMenuItems } from '../../../config/MenuItems'
import { Link } from '../../../application/router/Link'
import { SubNav } from '../../../buildingBlocks/SubNav'
import { RootState } from '../../../application/rootReducer'
import { NotificationsConnected } from '../../Notifications'
import { useAnalytics } from '../../../application/analytics'
import { buildEvent } from '../../../application/analytics/eventBuilders'
import { Branding } from './Branding'
import { Item } from './MenuItem'
import { ProfileTabContainer } from './ProfileTab/ProfileTabContainer'
import { BellConnected } from './BellConnected'
import { PlatformFooter } from './PlatformFooter'

export const TopBar: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { enableCompaniesArea, companiesAreaPageType } = useAppSelector((state: RootState) => {
    return {
      enableCompaniesArea: state.config.config.softConfig.featureSwitches.companiesArea.type !== 'off',
      companiesAreaPageType: state.config.config.softConfig.featureSwitches.companiesArea.type,
    }
  })

  const { trackEvent } = useAnalytics()

  const { isTabletOrSmaller } = useBreakpoints()
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }
  const { colors } = useTheme()

  // At the moment the industry grouping enabling is based off of the companies area page type.
  // At a later stage we may want to move this into its own config setting.
  const enableIndustryGrouping = companiesAreaPageType === 'comprehensive'

  const menuItems = getMenuItems({
    enableCompaniesArea,
    enableIndustryGrouping,
  })
  const { location } = useRouter()
  const areaIndex = menuItems.findIndex((item) => item.areas.some((area) => location.pathname.startsWith(area)))
  const subNavItems = menuItems[areaIndex]?.subLinks

  const selectedTabIndex = subNavItems?.findIndex((tab) => location.pathname.startsWith(tab.to))
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  return (
    <StyledDiv>
      <Page>
        <BurgerMenu isOpen={isOpen} toggleOpen={toggleIsOpen} />
        <Sticky>
          <StyledInner>
            <Nav>
              {isTabletOrSmaller ? (
                <HamburgerIcon onClick={toggleIsOpen} />
              ) : (
                <FlexItem>
                  <Link to="/dashboard">
                    <Branding showBranding />
                  </Link>
                  {menuItems.map((x, index) => {
                    return (
                      <Item
                        key={x.label}
                        label={x.label}
                        to={x.to}
                        icon={x.icon}
                        isActive={index === areaIndex}
                        isExternal={x.isExternal}
                      />
                    )
                  })}
                </FlexItem>
              )}
              <NotificationsIcon
                onClick={() => {
                  if (!isComponentVisible) {
                    setIsComponentVisible(true)
                    trackEvent(buildEvent.clickNotificationBell())
                  }
                }}
              >
                <BellConnected fillColor={isComponentVisible ? colors.info.light : colors.info.medium} />
                {isComponentVisible && <NotificationsTriangle />}
              </NotificationsIcon>
              <ProfileTabContainer path="/profile" />
              {isComponentVisible && (
                <NotificationsWall tabIndex={0} ref={ref}>
                  <NotificationsConnected flipOpenClose={() => setIsComponentVisible(!isComponentVisible)} />
                </NotificationsWall>
              )}
            </Nav>
          </StyledInner>
          {subNavItems !== undefined && selectedTabIndex !== undefined && (
            <SubNav selectedTabIndex={selectedTabIndex} navItems={subNavItems} />
          )}
        </Sticky>
        {children}
      </Page>
      <PlatformFooter />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const Sticky = styled.div`
  @media (max-width: ${BreakpointAsMaxValue.tablet}) {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  grid-area: nav;
`

const Nav = styled.nav`
  grid-area: header;
  color: ${(p) => p.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  grid-area: nav;
  max-width: ${ContentMaxWidth}px;
  position: relative;
  margin: 0 auto;
  height: 10rem;
  @media (max-width: ${BreakpointAsMaxValue.tablet}) {
    height: 7rem;
  }
`

const StyledInner = styled.div`
  width: 100%;
  padding: 0 1.6rem;
  background-color: ${(p) => p.theme.colors.background.dark};
`

const Page = styled.div`
  display: grid;
  margin: 0 auto;
  width: 100%;
  gap: 0 1.6rem;
  grid:
    'nav nav nav nav' auto
    '. sidebar main .' 1fr/
    1fr auto minmax(0, 100rem) 1fr;
  > main {
    grid-area: main;
  }
  > aside {
    grid-area: sidebar;
  }

  @media (max-width: ${BreakpointAsMaxValue.tablet}) {
    display: initial;
    grid:
      'nav' auto
      'stack' 1fr/
      1fr;
    > :is(main, aside) {
      grid-area: stack;
      overflow-y: auto;
    }
  }
`

const FlexItem = styled.div`
  display: flex;
  align-items: center;
`

const NotificationsIcon = styled.div`
  margin-right: 4rem;
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  @media (max-width: ${BreakpointAsMaxValue.tablet}) {
    margin-right: 1.2rem;
  }
`

const NotificationsWall = styled.div`
  position: absolute;
  z-index: 40;
  max-width: 48rem;
  width: 90vw;
  top: 9.3rem;
  right: 2rem;
  max-height: 50rem;
  @media (max-width: ${BreakpointAsMaxValue.tablet}) {
    top: 8rem;
  }
`

const NotificationsTriangle = styled.div`
  z-index: 41;
  position: absolute;
  top: 4rem;
  width: 0;
  height: 0;
  border-left: 2rem solid transparent;
  border-right: 2rem solid transparent;
  border-bottom: 3rem solid ${(p) => p.theme.colors.white};
`
