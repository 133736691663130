import styled from 'styled-components';
export const CardImage = styled.img `
  width: 100%;
  object-fit: cover;
  object-position: center;
  &:first-child {
    border-radius: 2rem 2rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 2rem 2rem;
  }
`;
