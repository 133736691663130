/* eslint-disable @typescript-eslint/no-deprecated */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Collapse, IconButton } from '@mui/material';
import { CancelOutlined, HelpOutlineOutlined } from '@mui/icons-material';
import { Button, ArrowLeftIcon } from '@tumelo/designsystem';
import { Heading, Body } from '../../buildingBlocks/Typography';
import { Breakpoint } from '../../styles';
/**
 * PageHeader - Should only be 1 per page as it contains a h1
 */
export const PageHeader = ({ title, helpText, callToAction, showBack = false, onClickGoBack, iconOnlyAltText, className, }) => {
    const [isOpen, setOpen] = React.useState(false);
    const { colors } = useContext(ThemeContext);
    return (React.createElement(Row, { className: className },
        showBack && (React.createElement(Column, { style: { flex: 0 } },
            React.createElement(Button, { iconOnly: React.createElement(ArrowLeftIcon, null), variant: "tertiary", iconOnlyAltText: iconOnlyAltText, onClick: onClickGoBack, className: className }))),
        title && (React.createElement(Column, { style: { flex: 2 } },
            React.createElement(SubTitleContainer, null,
                React.createElement(StyledHeading, { mt: "0", as: "h1" },
                    title,
                    helpText && (React.createElement(StyledIconButton, { title: "Toggle help", style: inheritFontSize, disableRipple: true, onClick: () => setOpen(!isOpen) }, isOpen ? React.createElement(CancelOutlined, { fontSize: "inherit" }) : React.createElement(HelpOutlineOutlined, { fontSize: "inherit" }))))))),
        callToAction && React.createElement(Column, { style: { flex: 1, order: 1 } }, callToAction),
        helpText && (React.createElement(HelpColumn, null,
            React.createElement(Collapse, { in: isOpen },
                React.createElement(Body, { as: "p", color: colors.grey.dark }, helpText))))));
};
const inheritFontSize = { fontSize: 'inherit' };
const StyledIconButton = styled(IconButton) `
  color: ${({ theme }) => theme.colors.primary.medium};
`;
const StyledHeading = styled(Heading) `
  display: flex;
  align-items: center;
  margin-bottom: 0;
`;
const HelpColumn = styled.div `
  order: 1;
  flex-basis: 100%;
  flex-grow: 1;
  @media (max-width: ${Breakpoint.tablet}) {
    order: 0;
  }
`;
const Row = styled.div `
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  @media (max-width: ${Breakpoint.tablet}) {
    margin-bottom: 1rem;
  }
`;
const SubTitleContainer = styled.div `
  display: flex;
  align-items: baseline;
`;
const Column = styled.div `
  &:first-child {
    margin-right: 2rem;
  }
`;
