import * as React from 'react';
export const SvgClimatechangesmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#EBF8EF" }),
        React.createElement("path", { d: "M8 5.49998H6.5V3.99998C6.5 3.44998 6.05 2.99998 5.5 2.99998C4.95 2.99998 4.5 3.44998 4.5 3.99998V5.49998H3C2.45 5.49998 2 5.94998 2 6.49998C2 7.04998 2.45 7.49998 3 7.49998H4.5V8.99998C4.5 9.54998 4.95 9.99998 5.5 9.99998C6.05 9.99998 6.5 9.54998 6.5 8.99998V7.49998H8C8.55 7.49998 9 7.04998 9 6.49998C9 5.94998 8.55 5.49998 8 5.49998ZM19.68 14.65C19.35 13.72 18.76 12.89 18 12.26V5.49998C18 4.56998 17.64 3.68998 16.97 3.02998C15.65 1.70998 13.34 1.70998 12.02 3.02998C11.36 3.68998 10.99 4.56998 10.99 5.49998V12.26C10.23 12.89 9.64 13.72 9.31 14.65C8.92 15.75 8.89 16.98 9.23 18.1C9.57 19.22 10.28 20.22 11.22 20.92C12.16 21.62 13.32 22 14.5 22C15.68 22 16.83 21.62 17.78 20.92C18.73 20.22 19.43 19.22 19.77 18.1C20.11 16.98 20.08 15.75 19.69 14.65H19.68ZM17.85 17.52C17.63 18.23 17.18 18.87 16.59 19.31C15.38 20.21 13.63 20.21 12.42 19.31C11.82 18.86 11.37 18.23 11.16 17.52C10.94 16.81 10.96 16.03 11.21 15.32C11.46 14.61 11.94 14 12.56 13.59C12.84 13.4 13 13.09 13 12.76V5.49998C13 5.09998 13.16 4.71998 13.44 4.43998C13.72 4.15998 14.1 3.99998 14.5 3.99998C14.9 3.99998 15.28 4.15998 15.56 4.43998C15.84 4.71998 16 5.09998 16 5.49998V12.76C16 13.09 16.17 13.41 16.44 13.59C17.06 14.01 17.54 14.62 17.79 15.32C18.04 16.02 18.06 16.8 17.84 17.52H17.85Z", fill: "#37A155" })));
};
