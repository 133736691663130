import * as React from 'react';
export const SvgOther = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 40, height: 40, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 40, height: 40, rx: 4, fill: "#F3F3F4" }),
        React.createElement("path", { d: "M35.8 14.4C35.61 14.15 35.31 14 35 14H31V12C31 11.45 30.55 11 30 11H18V9C18 8.45 17.55 8 17 8H7C6.45 8 6 8.45 6 9V32C6 32 6.02 32.07 6.02 32.1C6.02 32.16 6.04 32.21 6.05 32.26C6.08 32.35 6.11 32.43 6.16 32.51C6.18 32.54 6.18 32.57 6.2 32.59C6.21 32.61 6.23 32.61 6.25 32.63C6.32 32.71 6.39 32.77 6.48 32.82C6.52 32.84 6.54 32.87 6.58 32.89C6.71 32.95 6.85 32.99 7 32.99H30C30.44 32.99 30.83 32.7 30.96 32.27L35.96 15.27C36.05 14.97 35.99 14.64 35.8 14.39V14.4ZM8 10H16V11C16 11.53 16.21 12.04 16.59 12.41C16.97 12.78 17.47 13 18 13H29V14H12C11.56 14 11.17 14.29 11.04 14.72L8 25.06V10ZM29.25 31H8.34L12.75 16H33.67L29.26 31H29.25Z", fill: "#8B8B8F" })));
};
