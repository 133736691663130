import * as React from 'react';
import { useTheme } from 'styled-components';
import { ButtonText } from '../Typography';
export const ProgressLabel = ({ currentStep = 0, totalSteps = 0 }) => {
    const { colors } = useTheme();
    return (React.createElement(ButtonText, { mb: "0", color: colors.grey.medium },
        "Step ",
        currentStep,
        " of ",
        totalSteps));
};
