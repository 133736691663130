/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.platform.v1";

export interface UserPreferences {
  /** GENERATED at the time of creation */
  createTime:
    | Date
    | undefined;
  /** GENERATED each time the user's prefences are updated */
  updateTime:
    | Date
    | undefined;
  /** REQUIRED bool indicating if the user is subscribed to receive emils */
  subscribedEmail: boolean;
  /** REQUIRED bool indicating if the user is subscribed to push notifications */
  subscribedToPushNotificationsState: UserPreferences_SubscribedToPushNotifications;
}

export enum UserPreferences_SubscribedToPushNotifications {
  USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_INVALID = 0,
  USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_TRUE = 1,
  USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_FALSE = 2,
  UNRECOGNIZED = -1,
}

export function userPreferences_SubscribedToPushNotificationsFromJSON(
  object: any,
): UserPreferences_SubscribedToPushNotifications {
  switch (object) {
    case 0:
    case "USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_INVALID":
      return UserPreferences_SubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_INVALID;
    case 1:
    case "USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_TRUE":
      return UserPreferences_SubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_TRUE;
    case 2:
    case "USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_FALSE":
      return UserPreferences_SubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_FALSE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return UserPreferences_SubscribedToPushNotifications.UNRECOGNIZED;
  }
}

export function userPreferences_SubscribedToPushNotificationsToJSON(
  object: UserPreferences_SubscribedToPushNotifications,
): string {
  switch (object) {
    case UserPreferences_SubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_INVALID:
      return "USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_INVALID";
    case UserPreferences_SubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_TRUE:
      return "USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_TRUE";
    case UserPreferences_SubscribedToPushNotifications.USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_FALSE:
      return "USER_PREFERENCES_SUBSCRIBED_TO_PUSH_NOTIFICATIONS_FALSE";
    case UserPreferences_SubscribedToPushNotifications.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

function createBaseUserPreferences(): UserPreferences {
  return {
    createTime: undefined,
    updateTime: undefined,
    subscribedEmail: false,
    subscribedToPushNotificationsState: 0,
  };
}

export const UserPreferences = {
  encode(message: UserPreferences, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.subscribedEmail === true) {
      writer.uint32(24).bool(message.subscribedEmail);
    }
    if (message.subscribedToPushNotificationsState !== 0) {
      writer.uint32(40).int32(message.subscribedToPushNotificationsState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserPreferences {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserPreferences();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.subscribedEmail = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.subscribedToPushNotificationsState = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserPreferences {
    return {
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      updateTime: isSet(object.updateTime) ? fromJsonTimestamp(object.updateTime) : undefined,
      subscribedEmail: isSet(object.subscribedEmail) ? Boolean(object.subscribedEmail) : false,
      subscribedToPushNotificationsState: isSet(object.subscribedToPushNotificationsState)
        ? userPreferences_SubscribedToPushNotificationsFromJSON(object.subscribedToPushNotificationsState)
        : 0,
    };
  },

  toJSON(message: UserPreferences): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime.toISOString();
    }
    if (message.subscribedEmail === true) {
      obj.subscribedEmail = message.subscribedEmail;
    }
    if (message.subscribedToPushNotificationsState !== 0) {
      obj.subscribedToPushNotificationsState = userPreferences_SubscribedToPushNotificationsToJSON(
        message.subscribedToPushNotificationsState,
      );
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserPreferences>, I>>(base?: I): UserPreferences {
    return UserPreferences.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserPreferences>, I>>(object: I): UserPreferences {
    const message = createBaseUserPreferences();
    message.createTime = object.createTime ?? undefined;
    message.updateTime = object.updateTime ?? undefined;
    message.subscribedEmail = object.subscribedEmail ?? false;
    message.subscribedToPushNotificationsState = object.subscribedToPushNotificationsState ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
