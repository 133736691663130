import React, { useState } from 'react'
import {
  PollTag,
  TagIcon,
  ToggleSwitch,
  FilterList,
  FilterMenu,
  FilterMenuItem,
  industryCategoryToIndustryCategoryTitle,
} from '@tumelo/shared'
import { useRouter } from '../../../../application/router/useRouter'
import { useAppDispatch, useAppSelector } from '../../../../application/store'
import { RootState } from '../../../../application/rootReducer'
import { clearFilters } from '../../../../application/features/searchFilters'
import { toggleFilterWithAnalytics } from '../../../../application/features/searchFilters/asyncActions'
import { IndustriesFilterMenu } from './IndustriesFilterMenu'

const VotingFilterMenu: React.FC = () => {
  const dispatch = useAppDispatch()

  const { location } = useRouter()
  const isOpenVotes = location.pathname === '/votes/open'
  const isVoteResults = location.pathname === '/votes/results'

  const [voteOutcomeToggle, setVoteOutcomeToggle] = useState(false)
  const [pollTagToggle, setPollTagToggle] = useState(true)

  const { awaitingResults, acceptedVoteOutcomes, rejectedVoteOutcomes, industries, pollTags, withdrawnVoteOutcomes } =
    useAppSelector((state: RootState) => {
      return {
        awaitingResults: state.searchFilters.binaryFilters.awaitingResults,
        acceptedVoteOutcomes: state.searchFilters.pollResponse.for,
        rejectedVoteOutcomes: state.searchFilters.pollResponse.against,
        withdrawnVoteOutcomes: state.searchFilters.pollResponse.withdrawn,
        industries: state.searchFilters.votingOrganizationIndustries,
        pollTags: state.searchFilters.pollTags,
      }
    })

  return (
    <>
      {isOpenVotes && (
        <ToggleSwitch
          active={awaitingResults}
          caption="See votes awaiting results"
          onClick={() => dispatch(toggleFilterWithAnalytics({ filter: 'binaryFilters', entry: 'awaitingResults' }))}
        />
      )}
      <FilterList caption="Filter votes by: " clearAll={() => dispatch(clearFilters())} />
      {isVoteResults && (
        <FilterMenu
          active={voteOutcomeToggle}
          toggleActive={() => setVoteOutcomeToggle(!voteOutcomeToggle)}
          title="Vote outcome"
        >
          <FilterMenuItem
            active={acceptedVoteOutcomes}
            toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollResponse', entry: 'for' }))}
          >
            Passed votes
          </FilterMenuItem>
          <FilterMenuItem
            active={rejectedVoteOutcomes}
            toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollResponse', entry: 'against' }))}
          >
            Not passed votes
          </FilterMenuItem>
          <FilterMenuItem
            active={withdrawnVoteOutcomes}
            toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollResponse', entry: 'withdrawn' }))}
          >
            Withdrawn votes
          </FilterMenuItem>
        </FilterMenu>
      )}
      <IndustriesFilterMenu
        industries={industries}
        callback={(industry) => {
          dispatch(
            toggleFilterWithAnalytics({
              filter: 'votingOrganizationIndustries',
              filterTrackingName: industryCategoryToIndustryCategoryTitle(industry),
              entry: industry,
            })
          )
        }}
      />
      <FilterMenu active={pollTagToggle} toggleActive={() => setPollTagToggle(!pollTagToggle)} title="Vote category">
        <FilterMenuItem
          active={pollTags.AnimalWelfare}
          toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollTags', entry: 'AnimalWelfare' }))}
          icon={<TagIcon tagId={PollTag.AnimalWelfare} />}
        >
          Animal welfare
        </FilterMenuItem>
        <FilterMenuItem
          active={pollTags.Audit}
          toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollTags', entry: 'Audit' }))}
          icon={<TagIcon tagId={PollTag.Audit} />}
        >
          Audit
        </FilterMenuItem>
        <FilterMenuItem
          active={pollTags.DefenceSecurity}
          toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollTags', entry: 'DefenceSecurity' }))}
          icon={<TagIcon tagId={PollTag.DefenceSecurity} />}
        >
          Defence & Security
        </FilterMenuItem>
        <FilterMenuItem
          active={pollTags.Environment}
          toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollTags', entry: 'Environment' }))}
          icon={<TagIcon tagId={PollTag.Environment} />}
        >
          Environment
        </FilterMenuItem>
        <FilterMenuItem
          active={pollTags.Equality}
          toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollTags', entry: 'Equality' }))}
          icon={<TagIcon tagId={PollTag.Equality} />}
        >
          Equality
        </FilterMenuItem>
        <FilterMenuItem
          active={pollTags.HumanRights}
          toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollTags', entry: 'HumanRights' }))}
          icon={<TagIcon tagId={PollTag.HumanRights} />}
        >
          Human Rights
        </FilterMenuItem>
        <FilterMenuItem
          active={pollTags.Lobbying}
          toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollTags', entry: 'Lobbying' }))}
          icon={<TagIcon tagId={PollTag.Lobbying} />}
        >
          Lobbying
        </FilterMenuItem>
        <FilterMenuItem
          active={pollTags.Management}
          toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollTags', entry: 'Management' }))}
          icon={<TagIcon tagId={PollTag.Management} />}
        >
          Management
        </FilterMenuItem>
        <FilterMenuItem
          active={pollTags.Other}
          toggleActive={() => dispatch(toggleFilterWithAnalytics({ filter: 'pollTags', entry: 'Other' }))}
          icon={<TagIcon tagId={PollTag.Other} />}
        >
          Uncategorised
        </FilterMenuItem>
      </FilterMenu>
    </>
  )
}

export default VotingFilterMenu
