/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchAuthSession } from 'aws-amplify/auth'
import { BrowserHeaders } from 'browser-headers'
import {
  DashboardBffService,
  DashboardBffServiceClientImpl,
  GrpcWebImpl,
} from '../proto/tumelo/dashboardbff/v1/service'
import { ApiError } from './ApiError'
import {
  AccountsApi,
  Configuration,
  HabitatsApi,
  InstrumentsApi,
  InvestorsApi,
  ModelPortfoliosApi,
  OrganizationsApi,
  VotingApi,
} from './gen'
import * as runtime from './gen/runtime'
import { isResponseError } from './isResponseError'

export class APIServiceBaseClass {
  private readonly baseUrl: string

  protected readonly habitatId: string = 'mine'

  protected readonly investorId: string = 'mine'

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  private async getAPIConfiguration(): Promise<Configuration> {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString()
    if (!token) throw new Error('No token found')
    return new Configuration({
      basePath: this.baseUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  protected async getDashboardBffService(): Promise<DashboardBffService> {
    const session = await fetchAuthSession()
    const token = session.tokens?.idToken?.toString()
    if (!token) throw new Error('No token found')
    const metadata = new BrowserHeaders({ Authorization: `Bearer ${token}` })
    return new DashboardBffServiceClientImpl(new GrpcWebImpl(this.baseUrl, { metadata }))
  }

  protected async getVotingApi(): Promise<VotingApi> {
    const config = await this.getAPIConfiguration()
    return catchResponseErrorAndTurnToString(new VotingApi(config))
  }

  protected async getOrganizationsApi(): Promise<OrganizationsApi> {
    const config = await this.getAPIConfiguration()
    return catchResponseErrorAndTurnToString(new OrganizationsApi(config))
  }

  protected async getModelPortfoliosApi(): Promise<ModelPortfoliosApi> {
    const config = await this.getAPIConfiguration()
    return catchResponseErrorAndTurnToString(new ModelPortfoliosApi(config))
  }

  protected async getInstrumentsApi(): Promise<InstrumentsApi> {
    const config = await this.getAPIConfiguration()
    return catchResponseErrorAndTurnToString(new InstrumentsApi(config))
  }

  protected async getAccountsApi(): Promise<AccountsApi> {
    const config = await this.getAPIConfiguration()
    return catchResponseErrorAndTurnToString(new AccountsApi(config))
  }

  protected async getHabitatsApi(): Promise<HabitatsApi> {
    const config = await this.getAPIConfiguration()
    return catchResponseErrorAndTurnToString(new HabitatsApi(config))
  }

  protected async getInvestorsApi(): Promise<InvestorsApi> {
    const config = await this.getAPIConfiguration()
    return catchResponseErrorAndTurnToString(new InvestorsApi(config))
  }
}

/**
 * catchResponseErrorAndTurnToString takes in a class and wraps each call with a try/catch block. In the case that the
 * error thrown is an `instanceof Response`, the function wrapper/decorator takes the most useful parts of out of the
 * Response and makes a parse-able Error.
 *
 * @param target is a API Class such as VotingApi as generated from the OpenAPI spec
 */
function catchResponseErrorAndTurnToString<T extends runtime.BaseAPI>(target: T): T {
  Object.keys(target).forEach((propertyName) => {
    const descriptor = Object.getOwnPropertyDescriptor(target, propertyName)
    if (!descriptor) {
      throw new Error('could not find descriptor with own property name')
    }
    const isMethod = descriptor.value instanceof Function
    if (isMethod) {
      const originalMethod = descriptor.value
      descriptor.value = function method(...args: any[]) {
        return originalMethod.apply(this, args).then((res: any) => {
          if (isResponseError(res)) {
            const { url, status } = res
            throw new ApiError(`request failed requesting ${url}, returning ${status}`, status)
          }
          return res
        })
      }
    }
    Object.defineProperty(target, propertyName, descriptor)
  })
  return target
}
