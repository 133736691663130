/* eslint-disable @typescript-eslint/no-deprecated */
import styled from 'styled-components';
import { BreakpointAsMaxValue } from '../../styles';
export const Content = styled.main `
  max-width: 100rem;
  box-sizing: border-box;
  padding: 3rem 0;
  @media (max-width: ${BreakpointAsMaxValue.tablet}) {
    padding: 1.6rem;
  }
`;
