import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const RocketIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 25 25", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "data-name": "Layer 2" },
            React.createElement("g", { "data-name": "Layer 1" },
                React.createElement("path", { d: "M25 1.85A2 2 0 0023.12 0a19.21 19.21 0 00-9.58 2 18.81 18.81 0 00-5.49 4.51L8 6.58l-3.62.29a1.9 1.9 0 00-1.46.85L.43 11.47a1.33 1.33 0 00.37 1.84 1.42 1.42 0 00.53.2l3.06.49v.18a1.93 1.93 0 00.56 1.66L9.2 20.1a1.9 1.9 0 001.67.56h.18l.47 3.06A1.33 1.33 0 0013 24.78a1.28 1.28 0 00.52-.2l3.74-2.47a1.91 1.91 0 00.86-1.47l.29-3.64.07-.06A18.57 18.57 0 0023 11.45a19.3 19.3 0 002-9.6zM4.73 12.57l-3-.47 2.39-3.57a.44.44 0 01.36-.19l2.36-.18a16.29 16.29 0 00-2.11 4.41zm5.86 6.64a.42.42 0 01-.4-.13l-4.26-4.27a.42.42 0 01-.13-.4 12.22 12.22 0 01.55-2.16l6.42 6.39a11.86 11.86 0 01-2.18.57zm6.1 1.31a.45.45 0 01-.22.36l-3.57 2.37-.47-3a16.2 16.2 0 004.44-2.12zm5-9.74a17.43 17.43 0 01-4.19 5.05 16.72 16.72 0 01-3.36 2.22L7 10.83a16.4 16.4 0 012.2-3.36 17.18 17.18 0 015.05-4.2A17.58 17.58 0 0123 1.48a.48.48 0 01.45.45 17.7 17.7 0 01-1.72 8.85z" }),
                React.createElement("path", { d: "M17.1 3.51A4.34 4.34 0 0014 4.8a4.28 4.28 0 00-1.26 3.08 4.34 4.34 0 004.35 4.34 4.36 4.36 0 000-8.71zM19.2 10a2.94 2.94 0 01-2.09.87 3 3 0 01-3-2.94A2.94 2.94 0 0115 5.78a3 3 0 014.18 0A3 3 0 0119.2 10zM.74 20.6a.7.7 0 00.53-.21L3.66 18a.74.74 0 00.08-1 .73.73 0 00-1-.09l-.12.09-2.39 2.35a.73.73 0 000 1 .66.66 0 00.51.25zM5.85 19.16a.73.73 0 00-1 0l-4.63 4.6a.72.72 0 000 1 .72.72 0 001 0l4.6-4.6a.72.72 0 00.03-1zM7 21.35l-2.4 2.39a.74.74 0 001 1L8 22.38a.73.73 0 00-.08-1 .74.74 0 00-.92-.03z" })))));
};
