import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgSpeech = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M3.00001 22C2.8684 22.0008 2.73794 21.9755 2.6161 21.9258C2.49427 21.876 2.38345 21.8027 2.29001 21.71C2.16319 21.5807 2.07388 21.4193 2.03161 21.2432C1.98934 21.0671 1.9957 20.8828 2.05001 20.71L3.82001 15.4C3.27602 14.1719 2.99663 12.8431 3.00001 11.5C3.00527 8.98552 4.00511 6.57524 5.78124 4.79536C7.55738 3.01547 9.96555 2.01056 12.48 2H13C15.3403 2.11276 17.5558 3.08913 19.2181 4.74037C20.8804 6.39161 21.8716 8.60047 22 10.94V11.5C22.0015 12.9751 21.6591 14.4303 21 15.75C20.2106 17.3282 18.9972 18.6553 17.4959 19.5826C15.9945 20.5099 14.2646 21.0007 12.5 21C11.1734 20.9991 9.86183 20.7197 8.65001 20.18L3.32001 22C3.21383 22.0144 3.10619 22.0144 3.00001 22ZM12.48 4C11.3236 4.00025 10.1833 4.27078 9.15001 4.79C7.37045 5.67852 6.01672 7.23755 5.3866 9.12415C4.75649 11.0107 4.9016 13.0704 5.79001 14.85C5.85056 14.968 5.88704 15.0968 5.89735 15.229C5.90765 15.3612 5.89156 15.4941 5.85001 15.62L4.58001 19.42L8.38001 18.15C8.50592 18.1085 8.63884 18.0924 8.77104 18.1027C8.90323 18.113 9.03205 18.1495 9.15001 18.21C10.1833 18.7292 11.3236 18.9998 12.48 19C13.4666 19.0026 14.444 18.8106 15.3563 18.4348C16.2685 18.0591 17.0977 17.5071 17.7962 16.8104C18.4948 16.1137 19.049 15.286 19.4272 14.3748C19.8054 13.4635 20 12.4866 20 11.5V11C19.883 9.17168 19.0999 7.44965 17.7989 6.15973C16.4979 4.8698 14.7693 4.10144 12.94 4H12.48Z" })));
};
