import { createSelector } from '@reduxjs/toolkit'
import { Ballot } from '@tumelo/shared'
import { Selector } from '../../../utils/redux'
import { Payload } from '../../payload'
import { RootState } from '../../rootReducer'
import { ballotsAdapter } from '.'

const selectBallotsSlice = (state: RootState) => state.ballots

const { selectAll } = ballotsAdapter.getSelectors()

export const selectBallots: Selector<Payload<Ballot[]>> = createSelector(
  selectBallotsSlice,
  Payload.map((s) => selectAll(s.ballots))
)
