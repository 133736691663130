/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { Breakpoint } from '@tumelo/shared'
import ReadMoreButton from './ReadMoreButton'
import { ReadMoreButtonTextVariant } from './ReadMoreButtonTextVariant'

interface Props {
  isReadMoreOpen: boolean
  setIsReadMoreOpen: () => void
  variant?: ReadMoreVariant
}

export type ReadMoreVariant = 'arrow' | 'add' | 'text'

const CallToAction: React.FC<React.PropsWithChildren<Props>> = ({
  isReadMoreOpen,
  setIsReadMoreOpen,
  children,
  variant = 'arrow',
}) => {
  // TODO Figure out how to do shared analytics
  // const analytics = useAnalytics()
  const toggleOpen = () => {
    setIsReadMoreOpen()
    // analytics.trackEvent(readMoreButtonClickedEvent({ isOpen: isReadMoreOpen }))
  }

  return (
    <>
      {variant === 'text' ? (
        <ReadMoreButtonTextVariant isOpen={isReadMoreOpen} setIsOpen={toggleOpen} />
      ) : (
        <ReadMoreButton isOpen={isReadMoreOpen} setIsOpen={toggleOpen} />
      )}
      <AlignContent>{children}</AlignContent>
    </>
  )
}

const SingleQuestionStyles = {
  MARGIN: 2,
  MAX_WIDTH: 900,
  CONTENT_MAX_WIDTH: 700,
  ICON_SIZE: 5,
}

const { ICON_SIZE, MARGIN } = SingleQuestionStyles

const AlignContent = styled.div`
  margin: 5rem 0 0rem ${MARGIN + ICON_SIZE}rem;
  @media (max-width: ${Breakpoint.mobile}) {
    margin: 0;
  }
`

export default CallToAction
