/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.platform.v1";

export interface TermsAndConditionsConsent {
  /** GENERATED at the time of creation */
  createTime:
    | Date
    | undefined;
  /** REQUIRED has the user accepted the terms? */
  accepted: boolean;
}

function createBaseTermsAndConditionsConsent(): TermsAndConditionsConsent {
  return { createTime: undefined, accepted: false };
}

export const TermsAndConditionsConsent = {
  encode(message: TermsAndConditionsConsent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.accepted === true) {
      writer.uint32(16).bool(message.accepted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TermsAndConditionsConsent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTermsAndConditionsConsent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.accepted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TermsAndConditionsConsent {
    return {
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      accepted: isSet(object.accepted) ? Boolean(object.accepted) : false,
    };
  },

  toJSON(message: TermsAndConditionsConsent): unknown {
    const obj: any = {};
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.accepted === true) {
      obj.accepted = message.accepted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TermsAndConditionsConsent>, I>>(base?: I): TermsAndConditionsConsent {
    return TermsAndConditionsConsent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TermsAndConditionsConsent>, I>>(object: I): TermsAndConditionsConsent {
    const message = createBaseTermsAndConditionsConsent();
    message.createTime = object.createTime ?? undefined;
    message.accepted = object.accepted ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
