import React from 'react';
import styled, { css } from 'styled-components';
import { useBreakpointValues } from '..';
export const Stack = ({ children, flexDirection = 'column', gap = '1.6rem', rowGap, columnGap, flexWrap = false, justifyContent = 'flex-start', alignItems = 'stretch', alignContent = 'normal', mt = '0', mb = '0', as, className, }) => {
    return (React.createElement(StyledFlexContainer, { flexDirection: useBreakpointValues(flexDirection), gap: useBreakpointValues(gap), flexWrap: flexWrap, justifyContent: useBreakpointValues(justifyContent), alignItems: useBreakpointValues(alignItems), alignContent: alignContent, mb: useBreakpointValues(mb), mt: useBreakpointValues(mt), as: as, className: className, rowGap: rowGap, columnGap: columnGap }, children));
};
export const StyledFlexContainer = styled.div `
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: ${(props) => (props.flexWrap ? 'wrap' : 'nowrap')};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  gap: ${(props) => props.gap};
  ${({ rowGap }) => rowGap &&
    css `
      row-gap: ${rowGap};
    `}
  ${({ columnGap }) => columnGap &&
    css `
      column-gap: ${columnGap};
    `}
`;
