import React from 'react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { StylesProvider } from '@mui/styles'
// eslint-disable-next-line no-restricted-imports
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { useAsync } from 'react-use'
import { useCookies } from 'react-cookie'
import { cookieConsent, CookieConsentValue } from '@tumelo/shared'
import { createAnalyticsMixpanel, AnalyticsConsoleLogger, combineAnalytics } from '@tumelo/analytics'
import { GlobalStyle } from '@tumelo/designsystem'
import { LoggerService } from '../application/services/Logger/LoggerService'
import { LoggerServiceConsole } from '../application/services/Logger/LoggerServiceConsole'
import { LoggerServiceSentry } from '../application/services/Logger/LoggerServiceSentry'
import { LoggerServiceCompound } from '../application/services/Logger/LoggerServiceCompound'
import { getNewConfig } from '../config'
import { SoftConfigThemeSchema } from '../config/SoftConfigTheme'
import { GenericError } from '../components/Error'
import { configureStore } from '../application/store'
import { LoggerContext } from '../utils/loggerContext'
import { DebugWrapper } from '../utils/config/DebugUtils'
import { SentrySetUser } from '../utils/sentry'
import { ScrollToTop } from '../utils/ScrollToTop'
import { IdleTimeoutWrapper } from '../components/IdleTimeout/IdleTimeoutWrapper'
import { BrowserWarning } from '../components/BrowserWarning/BrowserWarning'
import { CookieBanner } from '../components/CookieBanner'
import { SSOTermsAcceptanceModalConnected } from '../containers/SSOTermsAcceptanceModalConnected'
import { DashboardRoutes } from '../routes'
import { returnServices } from '../application/services'
import * as Analytics from '../application/analytics'
import { configure as AuthConfigure, AuthWrapper } from '../auth'
import { getQueryVariable } from '../utils/getQueryVariable'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { PlannedMaintenance } from '../components/PlannedMaintenance'

export const App: React.FC = () => {
  const isProduction = process.env.NODE_ENV === 'production'
  const release = process.env.NEXT_PUBLIC_APP_RELEASE // in CI will contain the COMMIT_SHA
  // TODO Probably needs rethinking in future with configuration.
  const isProductionPlatformUrl = window.location.hostname.endsWith('platform.tumelo.com')

  const [cookies] = useCookies([cookieConsent])
  const cookiesAccepted = cookies[cookieConsent] === CookieConsentValue.Accepted

  // configure logger service
  let logger: LoggerService = LoggerServiceConsole
  // Any platform which is not suffixed with .platform.tumelo.com is considered part of the development environment in sentry
  const sentryEnvironment = window.location.hostname.endsWith('.platform.tumelo.com') ? 'production' : 'development'
  const dsn = 'https://93602412781842aebcbea429efba42ef@sentry.io/5127805'
  const init = release ? { dsn, release, environment: sentryEnvironment } : { dsn, environment: sentryEnvironment }
  const sentryLogger = new LoggerServiceSentry(init)
  if (isProduction && isProductionPlatformUrl && cookiesAccepted) {
    logger = new LoggerServiceCompound(LoggerServiceConsole, sentryLogger)
  }

  // Allows switching of config/data files on previews / in dev
  const pathPrefix = isProductionPlatformUrl ? '' : (getQueryVariable('config') ?? '')
  const state = useAsync(getNewConfig(pathPrefix))

  if (state.loading) {
    return null
  }

  if (state.error || !state.value) {
    logger.logError(state.error)
    return (
      <ThemeProvider theme={SoftConfigThemeSchema.parse()}>
        <GenericError onClick={() => window.location.reload()} />
        <GlobalStyle />
      </ThemeProvider>
    )
  }

  const config = state.value
  // configure auth
  AuthConfigure(config.aws)

  // configure Analytics
  const analyticsEngines = []
  if (cookiesAccepted) {
    if (config.mixpanel.token && config.mixpanel.token !== '')
      analyticsEngines.push(createAnalyticsMixpanel(config.mixpanel.token, { api_host: config.mixpanel.apiHost }))
  }

  if (!isProduction) analyticsEngines.push(AnalyticsConsoleLogger)
  const analytics = combineAnalytics(...analyticsEngines)

  const services = returnServices(config.services, logger, analytics, { fetchPathPrefix: pathPrefix })
  const store = configureStore({ services }, config, isProduction)

  const queryParameters = new URLSearchParams(window.location.search)
  const bypass = queryParameters.get('bypass')

  if (config.plannedMaintenance === true && bypass === null) {
    return <PlannedMaintenance />
  }
  return (
    <LoggerContext.Provider value={logger}>
      <Analytics.AnalyticsContext.Provider value={analytics}>
        <Provider store={store}>
          <StylesProvider injectFirst>
            <DebugWrapper enable={config.enableConfigWidget ?? false}>
              {(theme) => (
                <ThemeProvider theme={theme}>
                  <Router>
                    <ErrorBoundary>
                      <Analytics.PageViewFromLocationPathname>
                        <Analytics.SetUserProperties>
                          <Analytics.TrackLoadPWA>
                            <SentrySetUser logger={sentryLogger}>
                              <ScrollToTop />
                              <Routes>
                                <Route
                                  path="/*"
                                  element={
                                    <>
                                      <BrowserWarning />
                                      <CookieBanner>
                                        <AuthWrapper>
                                          <IdleTimeoutWrapper>
                                            <SSOTermsAcceptanceModalConnected>
                                              <DashboardRoutes />
                                            </SSOTermsAcceptanceModalConnected>
                                          </IdleTimeoutWrapper>
                                        </AuthWrapper>
                                      </CookieBanner>
                                    </>
                                  }
                                />
                              </Routes>
                            </SentrySetUser>
                          </Analytics.TrackLoadPWA>
                        </Analytics.SetUserProperties>
                      </Analytics.PageViewFromLocationPathname>
                    </ErrorBoundary>
                  </Router>
                  <GlobalStyle />
                </ThemeProvider>
              )}
            </DebugWrapper>
          </StylesProvider>
        </Provider>
      </Analytics.AnalyticsContext.Provider>
    </LoggerContext.Provider>
  )
}

const NextApp: React.FC = () => {
  const [isMounted, setIsMounted] = React.useState(false)

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Tumelo Platform</title>
      </Head>
      <App />
    </>
  )
}

export default dynamic(() => Promise.resolve(NextApp), {
  ssr: false,
})
