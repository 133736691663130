import React from 'react'
import { Body1, Body2, RadioButtonGroup, RadioButtonItem, Button, Stack } from '@tumelo/designsystem'
import { InvestorVoteResponse, Poll, VotingOption } from '@tumelo/shared'
import { selectSoftConfig } from '../../../application/features/config/selectors'
import { VotingRecommendationConnected } from '../../VotingRecommendations/VotingRecommendationConnected'
import { useAppSelector } from '../../../application/store'

interface Props {
  onClickAnswer: (position: InvestorVoteResponse) => void
  poll: Poll
}

export const PollOpen: React.FC<Props> = ({ onClickAnswer, poll }) => {
  const { votingPoliciesEnabled } = useAppSelector((state) => ({
    votingPoliciesEnabled: selectSoftConfig(state).featureSwitches.enableVotingPolicies,
  }))

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as typeof e.target & {
      position: { value: InvestorVoteResponse }
    }
    onClickAnswer(target.position.value)
  }

  const convertVotingOptionToText = (votingOption: VotingOption) => {
    return {
      [VotingOption.For]: 'In favour',
      [VotingOption.Against]: 'Against',
      [VotingOption.Abstain]: 'Abstain',
      [VotingOption.Withhold]: 'Withhold',
      [VotingOption.NoAction]: 'No action',
    }[votingOption]
  }
  return (
    <Stack flexDirection="column">
      <form onSubmit={onSubmit}>
        <Body1>I&apos;d like my fund managers to vote:</Body1>
        <RadioButtonGroup groupName="position">
          {/* polls are filtered when fetched to remove any without votingOptions */}
          {poll.relationships.proposal.votingOptions?.map((voteOption, index) => {
            const isFirstItem = index === 0
            return (
              <RadioButtonItem value={voteOption} key={voteOption} checked={isFirstItem} textHidden={isFirstItem}>
                <Body1 mb="0">{convertVotingOptionToText(voteOption)}</Body1>
              </RadioButtonItem>
            )
          })}
        </RadioButtonGroup>
        {votingPoliciesEnabled && <VotingRecommendationConnected poll={poll} />}
        <Body2 mb="2.4rem">
          Heads up: we send votes to your fund manager as soon as they&apos;re placed, so you won&apos;t be able to
          change your mind!
        </Body2>
        <Button fullWidth type="submit" variant="primary">
          Confirm your vote
        </Button>
      </form>
    </Stack>
  )
}
