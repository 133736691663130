import * as z from 'myzod'

export const SoftConfigBrandingSchema = z
  .object(
    {
      logoUrl: z.string(),
      logoUsesCircle: z.boolean(),
      signUpLogoUrl: z.string(),
      signupTagline: z.string().default(''),
    },

    { allowUnknown: true }
  )
  .default({
    logoUrl: 'https://res.cloudinary.com/tumelo/image/upload/v1582212613/bVPg3-a-_gahtwg.jpg',
    logoUsesCircle: true,
    signUpLogoUrl: 'https://res.cloudinary.com/tumelo/image/upload/v1582212613/bVPg3-a-_gahtwg.jpg',
    signupTagline: '',
  })

export type SoftConfigBranding = z.Infer<typeof SoftConfigBrandingSchema>
