export function getAndExpandPagedItemsViaPageToken<TResult extends { nextPageToken?: string }, TArr>(
  getPage: (token: string | undefined) => Promise<TResult>,
  extractArrayPartFromResult: (x: TResult) => TArr[]
) {
  // yeah there is no tail call optimization, so this would blow up with tons of pages - should probably not be using it anyway if this is the case!
  // On top of this, while loops do not appear to support async, so the dirty while-mutate mechanism is out too.
  const recurse = async (token: string | undefined): Promise<TArr[]> => {
    const result = await getPage(token)
    if (result.nextPageToken) return extractArrayPartFromResult(result).concat(await recurse(result.nextPageToken))
    return extractArrayPartFromResult(result).concat()
  }
  return recurse(undefined)
}
