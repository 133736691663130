import * as React from 'react';
const DEFAULT_IMAGE_STYLE = {
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
};
export const AnimalRights = ({ style }) => {
    const styleWithDefault = Object.assign(Object.assign({}, DEFAULT_IMAGE_STYLE), style);
    return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "prefix__Animal_Rights_Icon", width: "25", height: "25", "data-name": "Animal Rights Icon", viewBox: "0 0 25 25" },
        React.createElement("path", { id: "prefix__BG", d: "M18.809 0H6.191A6.256 6.256 0 0 0 0 6.191v12.618A6.2 6.2 0 0 0 6.191 25h12.618A6.2 6.2 0 0 0 25 18.809V6.191A6.256 6.256 0 0 0 18.809 0z", fill: "#fcf0fb" }),
        React.createElement("g", { id: "prefix__track", transform: "translate(2.001 -19.383)" },
            React.createElement("g", { id: "prefix__Group_11105", "data-name": "Group 11105", transform: "translate(-.001 21.383)" },
                React.createElement("g", { id: "prefix__Group_11104", "data-name": "Group 11104" },
                    React.createElement("path", { id: "prefix__Path_9812", d: "M286.425 281.376a.426.426 0 1 0 .426.426.426.426 0 0 0-.426-.426z", fill: "#e06fd3", "data-name": "Path 9812", transform: "translate(-273.809 -270.294)" }),
                    React.createElement("path", { id: "prefix__Path_9813", d: "M107.336 212.176a10.685 10.685 0 0 1-2.133-3.233 3.649 3.649 0 0 0-6.736 0 10.309 10.309 0 0 1-2.282 3.375 3.655 3.655 0 0 0-1.216 2.719 3.617 3.617 0 0 0 1.65 3.066 4.044 4.044 0 0 0 3.315.535 7.694 7.694 0 0 1 3.825.008 4.018 4.018 0 0 0 3.3-.519 3.671 3.671 0 0 0 .281-5.956zm-.751 5.245a3.166 3.166 0 0 1-2.6.409 8.542 8.542 0 0 0-4.28-.009 3.192 3.192 0 0 1-2.617-.422 2.772 2.772 0 0 1-1.27-2.357 2.8 2.8 0 0 1 .93-2.083 10.975 10.975 0 0 0 2.5-3.678v-.007a2.8 2.8 0 0 1 5.165 0v.007a11.537 11.537 0 0 0 2.333 3.518.437.437 0 0 0 .039.034 2.818 2.818 0 0 1-.207 4.588z", fill: "#e06fd3", "data-name": "Path 9813", transform: "translate(-90.917 -198.799)" }),
                    React.createElement("path", { id: "prefix__Path_9814", d: "M305.642 316.835a.426.426 0 1 0-.714.465 12.178 12.178 0 0 0 1.572 1.966.426.426 0 1 0 .6-.6 11.3 11.3 0 0 1-1.458-1.831z", fill: "#e06fd3", "data-name": "Path 9814", transform: "translate(-291.865 -304.057)" }),
                    React.createElement("path", { id: "prefix__Path_9815", d: "M121.973 27.2a4.33 4.33 0 0 0 .468-2.608c-.216-1.833-1.481-3.369-2.893-3.2a2.177 2.177 0 0 0-1.6 1.179 4.333 4.333 0 0 0-.468 2.608c.356 2.998 3.143 4.383 4.493 2.021zm-3.282-4.2a1.309 1.309 0 0 1 1.066-.762c.839 0 1.68 1.126 1.838 2.459a3.465 3.465 0 0 1-.362 2.085c-.982 1.716-2.672.269-2.9-1.7a3.468 3.468 0 0 1 .358-2.082z", fill: "#e06fd3", "data-name": "Path 9815", transform: "translate(-112.443 -21.383)" }),
                    React.createElement("path", { id: "prefix__Path_9816", d: "M5.447 167.491a3.868 3.868 0 0 0-.422-2.366 3.868 3.868 0 0 0-1.685-1.714 2.311 2.311 0 0 0-2.116-.052 2.311 2.311 0 0 0-1.181 1.756 3.868 3.868 0 0 0 .422 2.366 3.868 3.868 0 0 0 1.685 1.713 2.317 2.317 0 0 0 2.116.053 2.311 2.311 0 0 0 1.181-1.756zm-1.573 1a1.483 1.483 0 0 1-1.35-.061 3.013 3.013 0 0 1-1.3-1.339 3.013 3.013 0 0 1-.338-1.838 1.483 1.483 0 0 1 .731-1.136 1.493 1.493 0 0 1 1.349.06 3.013 3.013 0 0 1 1.3 1.339 3.013 3.013 0 0 1 .338 1.838 1.483 1.483 0 0 1-.73 1.136z", fill: "#e06fd3", "data-name": "Path 9816", transform: "translate(.001 -157.086)" }),
                    React.createElement("path", { id: "prefix__Path_9817", d: "M279.163 28.6c1.292 0 2.472-1.411 2.685-3.212.227-1.919-.74-3.8-2.275-3.8-1.292 0-2.472 1.411-2.685 3.212-.227 1.927.743 3.8 2.275 3.8zm-1.428-3.7c.158-1.333 1-2.459 1.838-2.459a.807.807 0 0 1 .1.006 1.438 1.438 0 0 1 1.013.853 3.559 3.559 0 0 1 .314 1.992c-.164 1.381-1.079 2.566-1.943 2.454a1.417 1.417 0 0 1-1.009-.852 3.561 3.561 0 0 1-.313-1.994z", fill: "#e06fd3", "data-name": "Path 9817", transform: "translate(-265.055 -21.584)" }),
                    React.createElement("path", { id: "prefix__Path_9818", d: "M388.659 165.168a2.2 2.2 0 0 0-3.3-1.7 4.061 4.061 0 0 0-2.107 4.079 2.2 2.2 0 0 0 3.3 1.7 4.06 4.06 0 0 0 2.107-4.079zm-2.483 3.314a1.484 1.484 0 0 1-1.35.061 1.484 1.484 0 0 1-.731-1.136 3.213 3.213 0 0 1 1.642-3.177 1.355 1.355 0 0 1 2.081 1.075 3.212 3.212 0 0 1-1.642 3.177z", fill: "#e06fd3", "data-name": "Path 9818", transform: "translate(-366.879 -157.137)" }))))));
};
