var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { IndustryIcon } from '..';
import { ListItem } from '../ListItem';
export const ListItemWithIndustryIcon = (_a) => {
    var { industryCategory, text, truncatedTextLength } = _a, rest = __rest(_a, ["industryCategory", "text", "truncatedTextLength"]);
    const truncatedText = truncatedTextLength && text.length >= truncatedTextLength
        ? `${text.substring(0, truncatedTextLength - 3)}...`
        : text;
    return (React.createElement(ListItem, Object.assign({}, rest, { text: truncatedText, adornment: React.createElement(IconContainer, null,
            React.createElement(IndustryIcon, Object.assign({ industryCategory: industryCategory, imageStyle: { maxWidth: '100%', maxHeight: '100%' } }, rest))) })));
};
const IconContainer = styled.div `
  width: 4rem;
  min-width: 4rem;
  text-align: center;
  margin: 0.8rem;
  height: 2.8rem;
`;
