import { IndustryCategory } from '@tumelo/shared'

export type OrganizationIndustries = {
  [IndustryCategory.AGRICULTURE_AND_FOOD]: boolean
  [IndustryCategory.ENERGY_AND_UTILITIES]: boolean
  [IndustryCategory.FINANCE]: boolean
  [IndustryCategory.HEALTHCARE]: boolean
  [IndustryCategory.INDUSTRIAL]: boolean
  [IndustryCategory.LIFESTYLE]: boolean
  [IndustryCategory.REAL_ESTATE]: boolean
  [IndustryCategory.RETAIL]: boolean
  [IndustryCategory.TECH]: boolean
  [IndustryCategory.TRANSPORT_AND_VEHICLES]: boolean
  [IndustryCategory.SPECIALIST_SERVICES]: boolean
  [IndustryCategory.UNKNOWN]: boolean
}

export const getInitialOrganizationIndustries = (): OrganizationIndustries => ({
  [IndustryCategory.AGRICULTURE_AND_FOOD]: false,
  [IndustryCategory.ENERGY_AND_UTILITIES]: false,
  [IndustryCategory.FINANCE]: false,
  [IndustryCategory.HEALTHCARE]: false,
  [IndustryCategory.INDUSTRIAL]: false,
  [IndustryCategory.LIFESTYLE]: false,
  [IndustryCategory.REAL_ESTATE]: false,
  [IndustryCategory.RETAIL]: false,
  [IndustryCategory.TECH]: false,
  [IndustryCategory.TRANSPORT_AND_VEHICLES]: false,
  [IndustryCategory.SPECIALIST_SERVICES]: false,
  [IndustryCategory.UNKNOWN]: false,
})
