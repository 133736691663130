import React from 'react'
import { BasePageProps, Content } from '@tumelo/shared'
import { useRouter } from '../application/router/useRouter'
import { useAppSelector } from '../application/store'
import { Dashboard } from '../components/views/Dashboard'
import { Payload } from '../application/payload'
import { compose } from '../utils/functional/common'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { selectInvestorDisplayName } from '../application/features/investor/investorSelector'
import { changeTitle } from './changeTitle'

type Props = BasePageProps

const DashboardPage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)

  const { navigate } = useRouter()

  const handleFundClick = () => navigate('/companies')
  const handleCompanyClick = (id: string) => navigate(`/companies/${id}`)
  const handleOpenVotes = () => navigate('/votes/open')
  const displayName = useAppSelector(compose(selectInvestorDisplayName, Payload.toMaybe))

  return (
    <ErrorBoundary>
      <Content>
        <Dashboard
          name={displayName}
          handleOpenVotes={handleOpenVotes}
          handleCompanyClick={handleCompanyClick}
          handleFundClick={handleFundClick}
        />
      </Content>
    </ErrorBoundary>
  )
}

export default DashboardPage
