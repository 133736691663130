import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const VoteNotPassedIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 20 20", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { id: "Not_passed_vote_Cross_icon", transform: "translate(-553.534 -577.298)" },
            React.createElement("circle", { id: "Ellipse_1406", cx: "10", cy: "10", r: "10", fill: "rgba(214,75,119,0.1)", transform: "translate(553.534 577.298)" }),
            React.createElement("g", { id: "Group_21041" },
                React.createElement("path", { id: "Path_13652", d: "M563.534 577.3a10 10 0 1 0 10 10 10.012 10.012 0 0 0-10-10zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z" }),
                React.createElement("path", { id: "Path_13653", d: "M566.8 582.529l-3.355 3.354-3.355-3.355-1.414 1.414 3.355 3.355-3.356 3.355 1.414 1.414 3.356-3.355 3.355 3.355 1.414-1.414-3.355-3.355 3.355-3.354z" })))));
};
