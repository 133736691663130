import React from 'react'
import styled from 'styled-components'
import { OpenInNew } from '@mui/icons-material'
import { MiniGridCard } from '@tumelo/shared'
import { Markdown } from '../Markdown'
import { CustomContentCard as CustomContentCardDM } from '../../config/SoftConfigSettingsReturningCards'

export type CustomContentCardProps = Omit<CustomContentCardDM, 'type' | 'userConfig'> & { onCtaClick?: () => void }

export const CustomContentCard: React.FC<CustomContentCardProps> = ({
  title,
  body,
  backgroundColor,
  callToAction,
  onCtaClick,
}) => {
  const onClick = () => {
    if (onCtaClick) onCtaClick()
    if (callToAction) {
      const windowName = callToAction.openNewTab ? '_newtab' : '_self'
      const windowFeatures = 'noopener,noreferrer'
      window.open(callToAction.targetUrl, windowName, windowFeatures)
    }
  }

  return (
    <MiniGridCard title={title} backgroundColor={backgroundColor}>
      <Markdown markdown={body} />
      {callToAction ? (
        <div>
          <OpenInNew /> <CTA onClick={onClick}>{callToAction.text}</CTA>{' '}
        </div>
      ) : null}
    </MiniGridCard>
  )
}

const CTA = styled.a`
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.primary.medium};
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.medium};
`
