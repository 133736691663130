import React from 'react'
import styled from 'styled-components'
import { TickIcon } from '@tumelo/designsystem'

export interface CheckBoxProps {
  checked: boolean
  onChange: () => void
  type?: 'checkbox' | 'radio'
}

// Must be wrapped in a label to be interactive
// Has a hidden native checkbox to maintain accessibility
export const Checkbox: React.FC<CheckBoxProps> = ({ checked, onChange, type = 'checkbox' }) => (
  <CheckboxContainer>
    <HiddenCheckbox type={type} checked={checked} onChange={onChange} />
    <StyledCheckbox type={type} checked={checked}>
      <StyledTickIcon viewBox="0 0 20 20">
        <Tick height={20} width={20} />
      </StyledTickIcon>
    </StyledCheckbox>
  </CheckboxContainer>
)

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  height: 2rem;
  width: 2rem;
`

const StyledTickIcon = styled.svg`
  fill: none;
  stroke: ${({ theme }) => theme.colors.dark};
  stroke-width: 1px;
  padding-bottom: 1px;
`
const Tick = styled(TickIcon)`
  fill: ${({ theme }) => theme.colors.dark};
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

interface StyledCheckboxProps {
  checked: boolean
  type: 'checkbox' | 'radio'
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  box-sizing: border-box;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background: ${({ theme }) => theme.colors.white};
  border: solid 0.8px ${({ checked, theme }) => (checked ? theme.colors.dark : theme.colors.grey.medium)};
  border-radius: ${(p) => (p.type === 'checkbox' ? '4px' : '50%')};
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    outline: 1px ${({ theme }) => theme.colors.black} dotted;
  }

  ${StyledTickIcon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`
