/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { FontWeight, TypographyCaption, hexAlpha } from '@tumelo/shared'

export const UserTestingAd: React.FC = () => {
  return (
    <Container>
      <Image src="/images/tumelo-champion.png" alt="Dancing man" />
      <TextContainer>
        <Title as="span">Be first to try new features</Title>
        <Link href="https://tumelo-hq.typeform.com/to/AbbvB2oI" target="_blank" rel="noreferrer noopener">
          Get involved
        </Link>
      </TextContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 500px;
  max-width: 100%;
  display: flex;
  background-color: ${({ theme }) => hexAlpha(theme.colors.white, 0.15)};
  border-radius: 2rem;
  align-self: flex-start;
`

const TextContainer = styled.div`
  display: flex;
  width: 150px;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.7rem 0.5rem;
`

const Image = styled.img`
  width: 9.5rem;
  height: 9.5rem;
  justify-self: flex-end;
  align-self: flex-end;
  position: relative;
  margin-top: 0.5rem;
`

const Title = styled(TypographyCaption)`
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.white};
  font-weight: ${FontWeight.bold};
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  text-decoration: underline;
  font-weight: 600;
  margin-top: 0.5rem;
`
