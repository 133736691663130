import React from 'react'
import { BlankModal, ModalHeaderWithProgress } from '@tumelo/designsystem'
import { VotingPolicy, VotingPolicyId } from '@tumelo/shared'
import { useRouter } from '../../application/router/useRouter'
import { useAnalytics } from '../../application/analytics'
import { buildEvent } from '../../application/analytics/eventBuilders'
import {
  setVotingPolicyModalClosed,
  setVotingPolicySelectionNotUpdating,
} from '../../application/features/votingPolicies'
import { selectVotingPolicy } from '../../application/features/votingPolicies/asyncActions'
import { useAppDispatch, useAppSelector } from '../../application/store'
import { selectUpdateVotingPolicySelection } from '../../application/features/votingPolicies/selectors'
import { Payload } from '../../application/payload'
import { useStep } from '../../utils/useStep'
import { PoliciesIntroductionOne } from './PoliciesIntroductionOne'
import { PoliciesIntroductionTwo } from './PoliciesIntroductionTwo'
import { PoliciesIntroductionThree } from './PoliciesIntroductionThree'
import { PickAPolicy } from './PickAPolicy'
import { ConfirmedPolicy } from './ConfirmedPolicy'
import { VotingPolicyModalStep } from './types'

export interface VotePoliciesModalProps {
  votingPolicies: VotingPolicy[]
}

export const VotePoliciesModal = ({ votingPolicies }: VotePoliciesModalProps) => {
  const dispatch = useAppDispatch()
  const isVotingPolicyModalOpen = useAppSelector((state) => state.votingPolicies.isVotingPolicyModalOpen)

  const totalSteps = 5
  const [step, helpers] = useStep(totalSteps)

  const { trackEvent } = useAnalytics()

  const onModalClose = () => {
    dispatch(setVotingPolicyModalClosed())
    trackEvent(buildEvent.closeVotingPoliciesModal(step))
    helpers.reset()
  }

  const onPolicySelection = (votingPolicyID: VotingPolicyId) => {
    dispatch(selectVotingPolicy(votingPolicyID))
  }

  const onPolicyDocumentClick = (votingPolicyId: VotingPolicyId) => {
    trackEvent(buildEvent.clickViewVotePolicy(votingPolicyId))
  }

  const updateVotingPolicySelection = useAppSelector(selectUpdateVotingPolicySelection)

  const { navigate } = useRouter()
  const navigateToOpenVotes = () => {
    dispatch(setVotingPolicyModalClosed())
    navigate('/votes/open')
  }

  return (
    <VotePoliciesModalUI
      currentStep={step}
      setStep={helpers.setStep}
      totalSteps={totalSteps}
      onBack={helpers.goToPrevStep}
      onClose={onModalClose}
      isOpen={isVotingPolicyModalOpen}
      votingPolicies={votingPolicies}
      onPolicySelection={onPolicySelection}
      onPolicyDocumentClick={onPolicyDocumentClick}
      updateVotingPolicySelection={updateVotingPolicySelection}
      onTryAgain={() => dispatch(setVotingPolicySelectionNotUpdating())}
      onNavigateToOpenVotes={navigateToOpenVotes}
    />
  )
}

export interface VotePoliciesModalUIProps {
  currentStep: number
  totalSteps: number
  onBack: () => void
  onClose: () => void
  isOpen: boolean
  setStep: (step: number) => void
  votingPolicies: VotingPolicy[]
  onPolicySelection: (id: VotingPolicyId) => void
  onPolicyDocumentClick: (votingPolicyId: VotingPolicyId) => void
  updateVotingPolicySelection: Payload<'not-updating'>
  onTryAgain: () => void
  onNavigateToOpenVotes: () => void
}

export const VotePoliciesModalUI: React.FC<VotePoliciesModalUIProps> = ({
  currentStep,
  totalSteps,
  onBack,
  onClose,
  isOpen,
  setStep,
  votingPolicies,
  onPolicySelection,
  onPolicyDocumentClick,
  updateVotingPolicySelection,
  onTryAgain,
  onNavigateToOpenVotes,
}) => {
  const goForward = () => setStep(currentStep + 1)
  return (
    <BlankModal open={isOpen} onCloseCallback={onClose} fullScreen>
      <ModalHeaderWithProgress
        onCloseCallback={onClose}
        progress={{ type: 'step', currentStep, totalSteps }}
        onBackCallback={onBack}
      />
      {currentStep === VotingPolicyModalStep.PoliciesIntroductionOne && (
        <PoliciesIntroductionOne goForward={goForward} />
      )}
      {currentStep === VotingPolicyModalStep.PoliciesIntroductionTwo && (
        <PoliciesIntroductionTwo goForward={goForward} />
      )}
      {currentStep === VotingPolicyModalStep.PoliciesIntroductionThree && (
        <PoliciesIntroductionThree goForward={goForward} />
      )}
      {currentStep === VotingPolicyModalStep.PickAPolicy && (
        <PickAPolicy
          policies={votingPolicies}
          onPolicySelection={onPolicySelection}
          onPolicyDocumentClick={onPolicyDocumentClick}
          updateVotingPolicySelection={updateVotingPolicySelection}
          goForward={goForward}
          onTryAgain={onTryAgain}
        />
      )}
      {currentStep === VotingPolicyModalStep.ConfirmedPolicy && (
        <ConfirmedPolicy onNavigateToOpenVotes={onNavigateToOpenVotes} />
      )}
    </BlankModal>
  )
}
