import React from 'react';
import { AgricultureIcon, UtilitiesIcon, FinanceIcon, HealthcareIcon, IndustrialsIcon, LifestyleIcon, RealEstateIcon, RetailIcon, SpecialistServicesIcon, TechnologyIcon, TransportIcon, } from '@tumelo/designsystem';
import { IndustryCategory } from '../../domain/NAICS';
export const IndustryIcon = ({ industryCategory, imageStyle }) => {
    switch (industryCategory) {
        case IndustryCategory.AGRICULTURE_AND_FOOD:
            return React.createElement(AgricultureIcon, { style: imageStyle });
        case IndustryCategory.ENERGY_AND_UTILITIES:
            return React.createElement(UtilitiesIcon, { style: imageStyle });
        case IndustryCategory.FINANCE:
            return React.createElement(FinanceIcon, { style: imageStyle });
        case IndustryCategory.HEALTHCARE:
            return React.createElement(HealthcareIcon, { style: imageStyle });
        case IndustryCategory.INDUSTRIAL:
            return React.createElement(IndustrialsIcon, { style: imageStyle });
        case IndustryCategory.LIFESTYLE:
            return React.createElement(LifestyleIcon, { style: imageStyle });
        case IndustryCategory.REAL_ESTATE:
            return React.createElement(RealEstateIcon, { style: imageStyle });
        case IndustryCategory.RETAIL:
            return React.createElement(RetailIcon, { style: imageStyle });
        case IndustryCategory.TECH:
            return React.createElement(TechnologyIcon, { style: imageStyle });
        case IndustryCategory.TRANSPORT_AND_VEHICLES:
            return React.createElement(TransportIcon, { style: imageStyle });
        case IndustryCategory.SPECIALIST_SERVICES:
            return React.createElement(SpecialistServicesIcon, { style: imageStyle });
        default:
            return React.createElement(IndustrialsIcon, { style: imageStyle });
    }
};
