import React from 'react'
import { format } from 'date-fns'
import { NoResultsMessage, timestampToDate, Timestamp } from '@tumelo/shared'
import { Stack, Body1, Button } from '@tumelo/designsystem'
import { VoteResult } from '../../../application/types/VoteResult'
import { PollGrid } from '../../PollsList/ListContainer'
import { VoteResultCardlet } from '../../Cardlet/VoteResultCardlet'
import { VoteResultsPageState } from '../../../application/features/voteResults'
import { LoadingSkeleton } from '../../Cardlet/LoadingSkeleton'
import { GenericError } from '../../Error'

interface Props {
  voteResults: VoteResult[]
  hasActiveFilters: boolean
  pageState: VoteResultsPageState
  minCloseTime: Timestamp
  resetAllFilters: () => void
  loadNextPage: () => void
}

export const ListVoteResults: React.FC<Props> = ({
  voteResults,
  hasActiveFilters,
  resetAllFilters,
  pageState,
  minCloseTime,
  loadNextPage,
}) => {
  // page error
  if (pageState === 'error') {
    return <GenericError onClick={window.location.reload} />
  }

  // No results when filters applied
  if (hasActiveFilters && voteResults.length === 0 && pageState === 'loaded') {
    return (
      <NoResultsMessage
        heading="No result"
        body="Sorry, there aren't any vote results that match the criteria you've selected from the filters. To see all
  votes, clear all the filters."
        button={{ text: 'Clear all filters', onClick: resetAllFilters }}
      />
    )
  }

  // No results - even when no filters applied
  if (voteResults.length === 0 && pageState === 'loaded') {
    return <Body1>Change is coming, but there are no vote results just yet.</Body1>
  }

  return (
    <>
      <PollGrid>
        {voteResults.map((result) => (
          <VoteResultCardlet key={result.pollId} voteResult={result} />
        ))}
      </PollGrid>
      {pageState === 'pending' && (
        <Stack mt="2.4rem">
          <LoadingSkeleton />
        </Stack>
      )}
      <Stack mt="2.4rem" flexDirection="column" alignItems="center">
        {pageState === 'loaded' && (
          <Body1 mb="0">
            Showing results from between {format(new Date(), 'dd/MM/yyyy')} and{' '}
            {format(timestampToDate(minCloseTime), 'dd/MM/yyyy')}.
          </Body1>
        )}
        <Button onClick={() => loadNextPage()}>See next 3 months</Button>
      </Stack>
    </>
  )
}
