import { createSelector } from '@reduxjs/toolkit'
import { Investor } from '@tumelo/shared'
import { Selector } from '../../../utils/redux'
import { Payload } from '../../payload'
import { RootState } from '../../rootReducer'

export const selectInvestor: Selector<Payload<Investor>> = (state: RootState) => state.investor.investor

export const selectInvestorSub: Selector<Payload<string>> = createSelector(
  selectInvestor,
  Payload.map((i) => i.sub)
)

export const selectInvestorFirstName: Selector<Payload<string>> = createSelector(
  selectInvestor,
  Payload.map((i) => i.firstName)
)

export const selectInvestorDisplayName: Selector<Payload<string>> = createSelector(
  selectInvestor,
  Payload.map((i) => i.nickname ?? i.firstName)
)

export const selectTermsAndConditionsAccepted: Selector<Payload<boolean>> = (state: RootState) =>
  state.investor.termsAndConditionsAccepted
