import * as React from 'react'
import { useRequireDashboardAccountSetup } from '../../application/features/dashboardUserAccount'
import { useLogout } from '../../application/hooks'
import { GenericError } from '../Error'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'

interface Props {
  children: React.ReactNode
}

export const RequireDashboardAccountSetup: React.FC<Props> = ({ children }) => {
  const dashboardAccount = useRequireDashboardAccountSetup()

  const { logout } = useLogout()

  return (
    <PayloadUnwrapper
      item={dashboardAccount}
      customError={<GenericError onClick={() => window.location.reload()} onLogoutClick={logout} />}
    >
      {({ account }) => {
        // useRequireDashboardAccountSetup hook redirects in this case
        if (account === 'not-configured') return null
        return children
      }}
    </PayloadUnwrapper>
  )
}
