import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgArrowup = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.7 4.08C12.578 4.02911 12.4472 4.00291 12.315 4.00291C12.1828 4.00291 12.052 4.02911 11.93 4.08C11.8108 4.12874 11.7022 4.20005 11.61 4.29L4.61001 11.29C4.51628 11.383 4.44188 11.4936 4.39112 11.6154C4.34035 11.7373 4.31421 11.868 4.31421 12C4.31421 12.132 4.34035 12.2627 4.39112 12.3846C4.44188 12.5064 4.51628 12.617 4.61001 12.71C4.79737 12.8962 5.05082 13.0008 5.31501 13.0008C5.57919 13.0008 5.83264 12.8962 6.02001 12.71L11.32 7.41V19C11.32 19.2652 11.4254 19.5196 11.6129 19.7071C11.8004 19.8946 12.0548 20 12.32 20C12.5852 20 12.8396 19.8946 13.0271 19.7071C13.2147 19.5196 13.32 19.2652 13.32 19V7.41L18.61 12.71C18.7974 12.8962 19.0508 13.0008 19.315 13.0008C19.5792 13.0008 19.8326 12.8962 20.02 12.71C20.1137 12.617 20.1881 12.5064 20.2389 12.3846C20.2897 12.2627 20.3158 12.132 20.3158 12C20.3158 11.868 20.2897 11.7373 20.2389 11.6154C20.1881 11.4936 20.1137 11.383 20.02 11.29L13.02 4.29C12.9279 4.20005 12.8192 4.12874 12.7 4.08Z" })));
};
