import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgRealestatesmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M9.84 11.66L9.8 12.42V12.66H9.45C9.02 12.66 8.68 13 8.67 13.42C8.67 13.42 8.67 13.43 8.67 13.44C8.67 13.86 9 14.2 9.42 14.21C9.42 14.21 9.43 14.21 9.44 14.21H9.77L9.78 14.53C9.67 14.81 9.49 15.06 9.26 15.25C8.98 15.45 8.87 15.8 8.96 16.13C9 16.24 9.07 16.35 9.15 16.43C9.3 16.58 9.49 16.66 9.7 16.66L14.53 16.64C14.96 16.64 15.31 16.29 15.31 15.86C15.32 15.43 14.99 15.07 14.56 15.05L11.24 15.07C11.32 14.87 11.35 14.66 11.35 14.45C11.34 14.35 11.33 14.25 11.34 14.15H12.92C13.35 14.13 13.7 13.79 13.7 13.36C13.7 12.93 13.36 12.59 12.93 12.59H11.35V12.45L11.39 11.75C11.44 11.39 11.68 11.09 12.01 10.96C12.38 10.84 12.77 10.84 13.13 10.96C13.43 11.08 13.62 11.38 13.62 11.7C13.62 12.12 13.95 12.46 14.37 12.47C14.37 12.47 14.38 12.47 14.39 12.47C14.82 12.47 15.17 12.12 15.17 11.69C15.16 10.71 14.54 9.85 13.62 9.52C12.95 9.3 12.22 9.29 11.54 9.51C10.59 9.83 9.95 10.67 9.84 11.66ZM22.6 9.7L20.6 8.2L12.6 2.2C12.24 1.93 11.76 1.93 11.4 2.2L3.4 8.2L1.4 9.7C0.96 10.03 0.87 10.66 1.2 11.1C1.53 11.54 2.16 11.63 2.6 11.3L3 11V21C3 21.55 3.45 22 4 22H20C20.55 22 21 21.55 21 21V11L21.4 11.3C21.58 11.43 21.79 11.5 22 11.5C22.3 11.5 22.6 11.36 22.8 11.1C23.13 10.66 23.04 10.03 22.6 9.7ZM19 20H5V9.5L12 4.25L19 9.5V20Z" })));
};
