import React from 'react'
import { isPollEndingSoon, Poll } from '@tumelo/shared'
import { VoteNow } from './VoteNow'
import { DaysRemaining } from './DaysRemaining'

interface Props {
  poll: Poll
}

export const OpenPoll: React.FC<Props> = ({ poll }) => {
  const isEndingSoon = isPollEndingSoon(poll)

  if (isEndingSoon) {
    return <DaysRemaining poll={poll} showVoteCount />
  }

  return <VoteNow poll={poll} showVoteCount />
}
