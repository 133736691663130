import { PollTag } from '@tumelo/shared'

export type PollTags = { [key in keyof typeof PollTag]: boolean }

export const getInitialPollTags = (): PollTags => ({
  AnimalWelfare: false,
  Audit: false,
  DefenceSecurity: false,
  Environment: false,
  Equality: false,
  HumanRights: false,
  Lobbying: false,
  Management: false,
  Other: false,
})
