import { MifirCode } from './Instrument';
export const sampleCompositeInstrument = () => {
    return {
        isin: 'BMG428941162',
        instrumentReferences: [{ idType: 'isin', idValue: 'BMG5361W1047' }],
        title: 'HANSA INVESTMENT CO LTD-ORD',
        mifirCode: MifirCode.SHRS,
        isComposite: true,
    };
};
export const sampleNonCompositeInstrument = () => {
    return {
        isin: 'BMG5361W1047',
        instrumentReferences: [{ idType: 'isin', idValue: 'BMG5361W1047' }],
        title: 'LANCASHIRE HOLDINGS',
        mifirCode: MifirCode.ETFS,
        isComposite: false,
    };
};
