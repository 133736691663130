import styled from 'styled-components'
import { hexAlpha } from '@tumelo/shared'

export const IconCircle = styled.div`
    width: 6rem;     
    height: 6rem;     
    border-radius: 50%;
    background: ${({ theme }) => hexAlpha(theme.colors.info.dark, 0.2)};
    margin-bottom: 3.6rem;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const Flex = styled.div`
  display: flex;
`
