export { SvgAnimalwelfaresmall as AnimalWelfareSmallIcon } from './AnimalWelfareSmall';
export { SvgAnimalwelfare as AnimalWelfareIcon } from './AnimalWelfare';
export { SvgAuditsmall as AuditSmallIcon } from './AuditSmall';
export { SvgAudit as AuditIcon } from './Audit';
export { SvgBuildingssmall as BuildingsSmallIcon } from './BuildingsSmall';
export { SvgCapitalstructuresmall as CapitalStructureSmallIcon } from './CapitalStructureSmall';
export { SvgClimatechangesmall as ClimateChangeSmallIcon } from './ClimateChangeSmall';
export { SvgCorporatesmall as CorporateSmallIcon } from './CorporateSmall';
export { SvgDefencesmall as DefenceSmallIcon } from './DefenceSmall';
export { SvgElectionsmall as ElectionSmallIcon } from './ElectionSmall';
export { SvgEnvironmentsmall as EnvironmentSmallIcon } from './EnvironmentSmall';
export { SvgEnvironment as EnvironmentIcon } from './Environment';
export { SvgEqualitysmall as EqualitySmallIcon } from './EqualitySmall';
export { SvgEquality as EqualityIcon } from './Equality';
export { SvgGavelsmall as GavelSmallIcon } from './GavelSmall';
export { SvgGovernancesmall as GovernanceSmallIcon } from './GovernanceSmall';
export { SvgHealthsmall as HealthSmallIcon } from './HealthSmall';
export { SvgHumanrightssmall as HumanRightsSmallIcon } from './HumanRightsSmall';
export { SvgHumanrights as HumanRightsIcon } from './HumanRights';
export { SvgLobbyingsmall as LobbyingSmallIcon } from './LobbyingSmall';
export { SvgLobbying as LobbyingIcon } from './Lobbying';
export { SvgManagementsmall as ManagementSmallIcon } from './ManagementSmall';
export { SvgManagement as ManagementIcon } from './Management';
export { SvgMegaphonesmall as MegaphoneSmallIcon } from './MegaphoneSmall';
export { SvgMiscellaneoussmall as MiscellaneousSmallIcon } from './MiscellaneousSmall';
export { SvgMoneycoinsmall as MoneyCoinSmallIcon } from './MoneyCoinSmall';
export { SvgMoneyfilesmall as MoneyFileSmallIcon } from './MoneyFileSmall';
export { SvgNewdealssmall as NewdealsSmallIcon } from './NewdealsSmall';
export { SvgOnepersonsmall as OnePersonSmallIcon } from './OnePersonSmall';
export { SvgOthersmall as OtherSmallIcon } from './OtherSmall';
export { SvgOther as OtherIcon } from './Other';
export { SvgPiechartsmall as PieChartSmallIcon } from './PieChartSmall';
export { SvgRemunerationreportsmall as RemunerationReportSmallIcon } from './RemunerationReportSmall';
export { SvgRemunerationsmall as RemunerationSmallIcon } from './RemunerationSmall';
export { SvgShareholdercandidatesmall as ShareholderCandidateSmallIcon } from './ShareholderCandidateSmall';
export { SvgSocialsmall as SocialSmallIcon } from './SocialSmall';
export { SvgSparklesmall as SparkleSmallIcon } from './SparkleSmall';
export { SvgThermometersmall as ThermometerSmallIcon } from './ThermometerSmall';
export { SvgTwopeoplesmall as TwoPeopleSmallIcon } from './TwoPeopleSmall';
export { SvgUncategorisedsmall as UncategorisedSmallIcon } from './UncategorisedSmall';
