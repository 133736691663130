/* eslint-disable @typescript-eslint/no-deprecated */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { useFormikContext, useField } from 'formik';
import styled from 'styled-components';
import { ArrowDownward } from '@mui/icons-material';
import { FontWeight, SmallBody } from '../../buildingBlocks/Typography/Text';
import { Select } from '../../styles';
import { WarningIcon } from './WarningIcon';
import { HelperText } from './Field';
import { Required } from './Required';
export const SelectField = (_a) => {
    var _b;
    var { name, label, helperText, showValidationErrorMessages, required = false, autoComplete, options } = _a, rest = __rest(_a, ["name", "label", "helperText", "showValidationErrorMessages", "required", "autoComplete", "options"]);
    const { setFieldValue, getFieldMeta, submitCount, submitForm } = useFormikContext();
    const [field] = useField(name);
    const { touched, error } = getFieldMeta(name);
    const hasError = touched && error != null && submitCount > 0;
    return (React.createElement(Container, null,
        React.createElement(Label, { as: "label", htmlFor: name },
            label,
            required && React.createElement(Required, null)),
        React.createElement(StyledInput, Object.assign({ id: name, autoComplete: autoComplete, 
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange: (e) => setFieldValue(field.name, e.target.value), value: field.value }, rest, { hasError: hasError, 
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onKeyDown: (e) => (e.keyCode === 13 ? submitForm() : {}) }), options.map((o) => (React.createElement("option", { key: o.value, value: o.value }, o.label)))),
        (showValidationErrorMessages && hasError) || helperText != null ? (React.createElement(HelperText, { isError: (_b = (hasError && showValidationErrorMessages)) !== null && _b !== void 0 ? _b : false }, hasError && error ? error : helperText)) : null,
        showValidationErrorMessages && hasError ? React.createElement(WarningIcon, { indent: 4.5 }) : null,
        React.createElement(StyledDownArrow, null)));
};
const Container = styled.div `
  position: relative;
  margin: 1rem 0;
`;
const StyledDownArrow = styled(ArrowDownward) `
  position: absolute;
  transform: scale(1.5);
  pointer-events: none;
  right: 2rem;
  top: 3.2rem;
`;
const StyledInput = styled(Select) `
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.8rem;
  border: 1px solid ${({ hasError, theme }) => (hasError ? theme.colors.danger.dark : theme.colors.grey.light)};
  padding: 1.5rem 1rem;
  margin: 1.2rem 0 0 0;
  color: ${({ theme }) => theme.colors.primary.medium};
  &:active,
  &:focus {
    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.danger.dark : theme.colors.grey.light)};
    outline: none;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey.light};
  }
`;
const Label = styled(SmallBody) `
  margin: 0;
  line-height: 2.4rem;
  font-weight: ${FontWeight.semiBold};
  position: absolute;
  top: 0;
  left: 2.9rem;
  background: linear-gradient(${({ theme }) => theme.colors.white} 70%, transparent 70%);
  pointer-events: none;
  padding: 0 0.2rem;
`;
