/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.dashboardbff.v1";

/** VoteResult is an object that contains all vote result information needed for vote results page */
export interface VoteResult {
  /** REQUIRED the ID of the poll */
  pollId: string;
  /** REQUIRED the title of the poll */
  pollTitle: string;
  /** REQUIRED the tags associated with the poll */
  pollTagIds: string[];
  /** REQUIRED the time the poll closed */
  pollCloseTime:
    | Date
    | undefined;
  /** REQUIRED proposal outcome */
  proposalOutcome: VoteResult_ProposalOutcome;
  /** REQUIRED the number of votes placed */
  numberOfVotesPlaced: number;
  /** REQUIRED bool to indicate if the investor voted on this poll or not */
  investorVoted: boolean;
  /** REQUIRED the organization of the proposal */
  organization: VoteResultOrganization | undefined;
}

export enum VoteResult_ProposalOutcome {
  PROPOSAL_OUTCOME_INVALID = 0,
  PROPOSAL_OUTCOME_FOR = 1,
  PROPOSAL_OUTCOME_AGAINST = 2,
  PROPOSAL_OUTCOME_WITHDRAWN = 3,
  UNRECOGNIZED = -1,
}

export function voteResult_ProposalOutcomeFromJSON(object: any): VoteResult_ProposalOutcome {
  switch (object) {
    case 0:
    case "PROPOSAL_OUTCOME_INVALID":
      return VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_INVALID;
    case 1:
    case "PROPOSAL_OUTCOME_FOR":
      return VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_FOR;
    case 2:
    case "PROPOSAL_OUTCOME_AGAINST":
      return VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_AGAINST;
    case 3:
    case "PROPOSAL_OUTCOME_WITHDRAWN":
      return VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_WITHDRAWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VoteResult_ProposalOutcome.UNRECOGNIZED;
  }
}

export function voteResult_ProposalOutcomeToJSON(object: VoteResult_ProposalOutcome): string {
  switch (object) {
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_INVALID:
      return "PROPOSAL_OUTCOME_INVALID";
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_FOR:
      return "PROPOSAL_OUTCOME_FOR";
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_AGAINST:
      return "PROPOSAL_OUTCOME_AGAINST";
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_WITHDRAWN:
      return "PROPOSAL_OUTCOME_WITHDRAWN";
    case VoteResult_ProposalOutcome.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface VoteResultOrganization {
  /** OPTIONAL the logo url of the organization */
  logoUrl: string;
  /** REQUIRED the display name of the organization */
  displayName: string;
  /** REQUIRED the naics2017 code used to categorise the organization */
  naics2017Code: string;
}

function createBaseVoteResult(): VoteResult {
  return {
    pollId: "",
    pollTitle: "",
    pollTagIds: [],
    pollCloseTime: undefined,
    proposalOutcome: 0,
    numberOfVotesPlaced: 0,
    investorVoted: false,
    organization: undefined,
  };
}

export const VoteResult = {
  encode(message: VoteResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId !== "") {
      writer.uint32(10).string(message.pollId);
    }
    if (message.pollTitle !== "") {
      writer.uint32(18).string(message.pollTitle);
    }
    for (const v of message.pollTagIds) {
      writer.uint32(26).string(v!);
    }
    if (message.pollCloseTime !== undefined) {
      Timestamp.encode(toTimestamp(message.pollCloseTime), writer.uint32(34).fork()).ldelim();
    }
    if (message.proposalOutcome !== 0) {
      writer.uint32(40).int32(message.proposalOutcome);
    }
    if (message.numberOfVotesPlaced !== 0) {
      writer.uint32(48).int64(message.numberOfVotesPlaced);
    }
    if (message.investorVoted === true) {
      writer.uint32(56).bool(message.investorVoted);
    }
    if (message.organization !== undefined) {
      VoteResultOrganization.encode(message.organization, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VoteResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVoteResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pollTitle = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pollTagIds.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pollCloseTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.proposalOutcome = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.numberOfVotesPlaced = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.investorVoted = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.organization = VoteResultOrganization.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VoteResult {
    return {
      pollId: isSet(object.pollId) ? String(object.pollId) : "",
      pollTitle: isSet(object.pollTitle) ? String(object.pollTitle) : "",
      pollTagIds: globalThis.Array.isArray(object?.pollTagIds) ? object.pollTagIds.map((e: any) => String(e)) : [],
      pollCloseTime: isSet(object.pollCloseTime) ? fromJsonTimestamp(object.pollCloseTime) : undefined,
      proposalOutcome: isSet(object.proposalOutcome) ? voteResult_ProposalOutcomeFromJSON(object.proposalOutcome) : 0,
      numberOfVotesPlaced: isSet(object.numberOfVotesPlaced) ? Number(object.numberOfVotesPlaced) : 0,
      investorVoted: isSet(object.investorVoted) ? Boolean(object.investorVoted) : false,
      organization: isSet(object.organization) ? VoteResultOrganization.fromJSON(object.organization) : undefined,
    };
  },

  toJSON(message: VoteResult): unknown {
    const obj: any = {};
    if (message.pollId !== "") {
      obj.pollId = message.pollId;
    }
    if (message.pollTitle !== "") {
      obj.pollTitle = message.pollTitle;
    }
    if (message.pollTagIds?.length) {
      obj.pollTagIds = message.pollTagIds;
    }
    if (message.pollCloseTime !== undefined) {
      obj.pollCloseTime = message.pollCloseTime.toISOString();
    }
    if (message.proposalOutcome !== 0) {
      obj.proposalOutcome = voteResult_ProposalOutcomeToJSON(message.proposalOutcome);
    }
    if (message.numberOfVotesPlaced !== 0) {
      obj.numberOfVotesPlaced = Math.round(message.numberOfVotesPlaced);
    }
    if (message.investorVoted === true) {
      obj.investorVoted = message.investorVoted;
    }
    if (message.organization !== undefined) {
      obj.organization = VoteResultOrganization.toJSON(message.organization);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VoteResult>, I>>(base?: I): VoteResult {
    return VoteResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VoteResult>, I>>(object: I): VoteResult {
    const message = createBaseVoteResult();
    message.pollId = object.pollId ?? "";
    message.pollTitle = object.pollTitle ?? "";
    message.pollTagIds = object.pollTagIds?.map((e) => e) || [];
    message.pollCloseTime = object.pollCloseTime ?? undefined;
    message.proposalOutcome = object.proposalOutcome ?? 0;
    message.numberOfVotesPlaced = object.numberOfVotesPlaced ?? 0;
    message.investorVoted = object.investorVoted ?? false;
    message.organization = (object.organization !== undefined && object.organization !== null)
      ? VoteResultOrganization.fromPartial(object.organization)
      : undefined;
    return message;
  },
};

function createBaseVoteResultOrganization(): VoteResultOrganization {
  return { logoUrl: "", displayName: "", naics2017Code: "" };
}

export const VoteResultOrganization = {
  encode(message: VoteResultOrganization, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.logoUrl !== "") {
      writer.uint32(10).string(message.logoUrl);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.naics2017Code !== "") {
      writer.uint32(26).string(message.naics2017Code);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VoteResultOrganization {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVoteResultOrganization();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.naics2017Code = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VoteResultOrganization {
    return {
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      displayName: isSet(object.displayName) ? String(object.displayName) : "",
      naics2017Code: isSet(object.naics2017Code) ? String(object.naics2017Code) : "",
    };
  },

  toJSON(message: VoteResultOrganization): unknown {
    const obj: any = {};
    if (message.logoUrl !== "") {
      obj.logoUrl = message.logoUrl;
    }
    if (message.displayName !== "") {
      obj.displayName = message.displayName;
    }
    if (message.naics2017Code !== "") {
      obj.naics2017Code = message.naics2017Code;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VoteResultOrganization>, I>>(base?: I): VoteResultOrganization {
    return VoteResultOrganization.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VoteResultOrganization>, I>>(object: I): VoteResultOrganization {
    const message = createBaseVoteResultOrganization();
    message.logoUrl = object.logoUrl ?? "";
    message.displayName = object.displayName ?? "";
    message.naics2017Code = object.naics2017Code ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
