import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgCount4Fill = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 1C5.92 1 1 5.92 1 12C1 18.08 5.92 23 12 23C18.08 23 23 18.08 23 12C23 5.92 18.08 1 12 1ZM14.92 14.94H16.52V13.09H14.92V11.26H12.78V13.09H10.15L14.43 7.2H12.05L7.43 13.42V14.95H12.71V17.01H14.92V14.95V14.94Z" })));
};
