import { keyframes } from 'styled-components'

export const glowing = keyframes`
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.8;
  }
`
