/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.platform.v1";

/** NotificationType describes the type of the notification */
export enum NotificationType {
  TYPE_INVALID = 0,
  TYPE_OPEN_VOTE = 1,
  TYPE_VOTE_RESULT = 2,
  TYPE_NEW_FEATURE = 3,
  TYPE_USER_RESEARCH = 4,
  TYPE_NEWS = 5,
  TYPE_CASE_STUDIES = 6,
  TYPE_IMPACT = 7,
  UNRECOGNIZED = -1,
}

export function notificationTypeFromJSON(object: any): NotificationType {
  switch (object) {
    case 0:
    case "TYPE_INVALID":
      return NotificationType.TYPE_INVALID;
    case 1:
    case "TYPE_OPEN_VOTE":
      return NotificationType.TYPE_OPEN_VOTE;
    case 2:
    case "TYPE_VOTE_RESULT":
      return NotificationType.TYPE_VOTE_RESULT;
    case 3:
    case "TYPE_NEW_FEATURE":
      return NotificationType.TYPE_NEW_FEATURE;
    case 4:
    case "TYPE_USER_RESEARCH":
      return NotificationType.TYPE_USER_RESEARCH;
    case 5:
    case "TYPE_NEWS":
      return NotificationType.TYPE_NEWS;
    case 6:
    case "TYPE_CASE_STUDIES":
      return NotificationType.TYPE_CASE_STUDIES;
    case 7:
    case "TYPE_IMPACT":
      return NotificationType.TYPE_IMPACT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NotificationType.UNRECOGNIZED;
  }
}

export function notificationTypeToJSON(object: NotificationType): string {
  switch (object) {
    case NotificationType.TYPE_INVALID:
      return "TYPE_INVALID";
    case NotificationType.TYPE_OPEN_VOTE:
      return "TYPE_OPEN_VOTE";
    case NotificationType.TYPE_VOTE_RESULT:
      return "TYPE_VOTE_RESULT";
    case NotificationType.TYPE_NEW_FEATURE:
      return "TYPE_NEW_FEATURE";
    case NotificationType.TYPE_USER_RESEARCH:
      return "TYPE_USER_RESEARCH";
    case NotificationType.TYPE_NEWS:
      return "TYPE_NEWS";
    case NotificationType.TYPE_CASE_STUDIES:
      return "TYPE_CASE_STUDIES";
    case NotificationType.TYPE_IMPACT:
      return "TYPE_IMPACT";
    case NotificationType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** NotificationResolution describes how the notification was resolved */
export enum NotificationResolution {
  RESOLUTION_INVALID = 0,
  RESOLUTION_UNRESOLVED = 1,
  RESOLUTION_DISMISSED = 2,
  RESOLUTION_OPENED = 3,
  UNRECOGNIZED = -1,
}

export function notificationResolutionFromJSON(object: any): NotificationResolution {
  switch (object) {
    case 0:
    case "RESOLUTION_INVALID":
      return NotificationResolution.RESOLUTION_INVALID;
    case 1:
    case "RESOLUTION_UNRESOLVED":
      return NotificationResolution.RESOLUTION_UNRESOLVED;
    case 2:
    case "RESOLUTION_DISMISSED":
      return NotificationResolution.RESOLUTION_DISMISSED;
    case 3:
    case "RESOLUTION_OPENED":
      return NotificationResolution.RESOLUTION_OPENED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NotificationResolution.UNRECOGNIZED;
  }
}

export function notificationResolutionToJSON(object: NotificationResolution): string {
  switch (object) {
    case NotificationResolution.RESOLUTION_INVALID:
      return "RESOLUTION_INVALID";
    case NotificationResolution.RESOLUTION_UNRESOLVED:
      return "RESOLUTION_UNRESOLVED";
    case NotificationResolution.RESOLUTION_DISMISSED:
      return "RESOLUTION_DISMISSED";
    case NotificationResolution.RESOLUTION_OPENED:
      return "RESOLUTION_OPENED";
    case NotificationResolution.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface Notification {
  /**
   * GENERATED the identifier of the notification.
   * format `userpools/{userpoolID}/users/{sub}/notifications/{uuid}`
   */
  name: string;
  /** GENERATED at the time of creation. */
  createTime:
    | Date
    | undefined;
  /** REQUIRED the title of the notification. */
  title: string;
  /** REQUIRED the url for which the notifcation signposts to. */
  url: string;
  /** REQUIRED the type of the notification. */
  type: NotificationType;
  /** OPTIONAL is a https link to a logo of the notification. It must be hosted by cloudinary. */
  logoUrl: string;
  /** REQUIRED the resolution of the notification. */
  resolution: NotificationResolution;
  /**
   * OPTIONAL expiration date of notification.
   * This is the time after which the notification is no longer considered interesting or useful
   */
  expiryTime:
    | Date
    | undefined;
  /**
   * REQUIRED a unique id
   * This is a unique string to be set by the consumer to ensure the same notification is not created twice
   */
  uniqueIdempotencyId: string;
}

function createBaseNotification(): Notification {
  return {
    name: "",
    createTime: undefined,
    title: "",
    url: "",
    type: 0,
    logoUrl: "",
    resolution: 0,
    expiryTime: undefined,
    uniqueIdempotencyId: "",
  };
}

export const Notification = {
  encode(message: Notification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.url !== "") {
      writer.uint32(34).string(message.url);
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.logoUrl !== "") {
      writer.uint32(50).string(message.logoUrl);
    }
    if (message.resolution !== 0) {
      writer.uint32(56).int32(message.resolution);
    }
    if (message.expiryTime !== undefined) {
      Timestamp.encode(toTimestamp(message.expiryTime), writer.uint32(66).fork()).ldelim();
    }
    if (message.uniqueIdempotencyId !== "") {
      writer.uint32(74).string(message.uniqueIdempotencyId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Notification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.url = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.logoUrl = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.resolution = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.expiryTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.uniqueIdempotencyId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Notification {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      title: isSet(object.title) ? String(object.title) : "",
      url: isSet(object.url) ? String(object.url) : "",
      type: isSet(object.type) ? notificationTypeFromJSON(object.type) : 0,
      logoUrl: isSet(object.logoUrl) ? String(object.logoUrl) : "",
      resolution: isSet(object.resolution) ? notificationResolutionFromJSON(object.resolution) : 0,
      expiryTime: isSet(object.expiryTime) ? fromJsonTimestamp(object.expiryTime) : undefined,
      uniqueIdempotencyId: isSet(object.uniqueIdempotencyId) ? String(object.uniqueIdempotencyId) : "",
    };
  },

  toJSON(message: Notification): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.type !== 0) {
      obj.type = notificationTypeToJSON(message.type);
    }
    if (message.logoUrl !== "") {
      obj.logoUrl = message.logoUrl;
    }
    if (message.resolution !== 0) {
      obj.resolution = notificationResolutionToJSON(message.resolution);
    }
    if (message.expiryTime !== undefined) {
      obj.expiryTime = message.expiryTime.toISOString();
    }
    if (message.uniqueIdempotencyId !== "") {
      obj.uniqueIdempotencyId = message.uniqueIdempotencyId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Notification>, I>>(base?: I): Notification {
    return Notification.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Notification>, I>>(object: I): Notification {
    const message = createBaseNotification();
    message.name = object.name ?? "";
    message.createTime = object.createTime ?? undefined;
    message.title = object.title ?? "";
    message.url = object.url ?? "";
    message.type = object.type ?? 0;
    message.logoUrl = object.logoUrl ?? "";
    message.resolution = object.resolution ?? 0;
    message.expiryTime = object.expiryTime ?? undefined;
    message.uniqueIdempotencyId = object.uniqueIdempotencyId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
