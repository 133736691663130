import { InvestorVoteResponse } from '@tumelo/shared'
import * as yup from 'yup'

export const schema = yup
  .object({
    ballots: yup
      .array(
        yup
          .object({
            id: yup.string().required(),
            pollId: yup.string().required(),
            createTime: yup.date().required(),
            expirationTime: yup.date().required(),
            investorVote: yup
              .object({
                response: yup.mixed<InvestorVoteResponse>().oneOf(['for', 'against', 'abstain']).required(),
                responseTime: yup.date().required(),
              })
              .notRequired()
              .default(undefined),
          })
          .required()
      )
      .required(),
    votingRecommendations: yup.array(
      yup.object({
        id: yup.string().required(),
        votingPolicyId: yup.string().required(),
        organizationId: yup.string().required(),
        generalMeetingId: yup.string().required(),
        proposalId: yup.string().required(),
        recommendation: yup.object({
          instruction: yup.string().required(),
          rationale: yup.string().notRequired(),
        }),
        createTime: yup.date().required(),
        updateTime: yup.date().required(),
      })
    ),
  })
  .required()
