import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgRocket = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M15.25 11.52H15.5C15.9799 11.5142 16.4515 11.3934 16.875 11.1676C17.2986 10.9419 17.6618 10.6178 17.9341 10.2226C18.2065 9.82737 18.38 9.37259 18.4402 8.89642C18.5004 8.42025 18.4454 7.93659 18.28 7.48605C18.1145 7.03551 17.8434 6.63125 17.4894 6.3072C17.1353 5.98315 16.7087 5.74878 16.2453 5.62377C15.7819 5.49876 15.2953 5.48675 14.8263 5.58876C14.3573 5.69076 13.9196 5.90381 13.55 6.21C13.2474 6.46684 12.9988 6.78117 12.8186 7.13478C12.6383 7.48839 12.5301 7.87425 12.5 8.27C12.4658 8.66424 12.5099 9.06135 12.6298 9.43847C12.7497 9.81559 12.943 10.1653 13.1986 10.4673C13.4542 10.7694 13.7671 11.018 14.1191 11.1986C14.4712 11.3793 14.8556 11.4885 15.25 11.52ZM14.78 7.67C14.9802 7.49709 15.2355 7.40134 15.5 7.4H15.59C15.8793 7.42795 16.1461 7.56824 16.3331 7.79075C16.5201 8.01325 16.6123 8.30022 16.59 8.59C16.5573 8.87729 16.4156 9.14109 16.1943 9.32711C15.9729 9.51313 15.6886 9.60721 15.4 9.59C15.2533 9.58067 15.1102 9.54123 14.9794 9.47413C14.8487 9.40703 14.7331 9.31372 14.64 9.2C14.5453 9.08943 14.4734 8.96115 14.4286 8.82259C14.3838 8.68403 14.367 8.53795 14.379 8.39284C14.3911 8.24772 14.4319 8.10645 14.499 7.97721C14.5662 7.84798 14.6583 7.73336 14.77 7.64L14.78 7.67Z" }),
        React.createElement("path", { d: "M7.83002 16.19C7.64627 16.0242 7.40754 15.9324 7.16002 15.9324C6.9125 15.9324 6.67377 16.0242 6.49002 16.19L2.56002 20.13C2.39137 20.3143 2.29784 20.5551 2.29784 20.805C2.29784 21.0548 2.39137 21.2957 2.56002 21.48C2.64837 21.567 2.75306 21.6356 2.86805 21.6819C2.98304 21.7283 3.10605 21.7514 3.23002 21.75C3.47919 21.7466 3.71813 21.6503 3.90002 21.48L7.83002 17.54C7.99867 17.3557 8.0922 17.1148 8.0922 16.865C8.0922 16.6151 7.99867 16.3743 7.83002 16.19Z" }),
        React.createElement("path", { d: "M8.33002 18L5.59002 20.79C5.45786 20.9228 5.36794 21.0918 5.33157 21.2756C5.2952 21.4595 5.31401 21.6499 5.38563 21.8231C5.45724 21.9962 5.57847 22.1444 5.73405 22.2488C5.88964 22.3532 6.07263 22.4093 6.26002 22.41C6.50919 22.4066 6.74812 22.3103 6.93002 22.14L9.67002 19.39C9.78296 19.2015 9.83065 18.981 9.80572 18.7627C9.78079 18.5443 9.68464 18.3403 9.53211 18.182C9.37959 18.0238 9.17919 17.9203 8.96191 17.8874C8.74462 17.8545 8.52255 17.894 8.33002 18Z" }),
        React.createElement("path", { d: "M3.24002 18.44L6.00002 15.69C6.09015 15.6032 6.16185 15.4992 6.21082 15.3841C6.2598 15.2689 6.28504 15.1451 6.28504 15.02C6.28504 14.8949 6.2598 14.7711 6.21082 14.6559C6.16185 14.5408 6.09015 14.4368 6.00002 14.35C5.81627 14.1842 5.57754 14.0924 5.33002 14.0924C5.0825 14.0924 4.84377 14.1842 4.66002 14.35L1.90002 17.1C1.73418 17.2838 1.64238 17.5225 1.64238 17.77C1.64238 18.0175 1.73418 18.2563 1.90002 18.44C1.9865 18.5302 2.09061 18.6016 2.20588 18.6497C2.32115 18.6979 2.4451 18.7218 2.57002 18.72C2.69468 18.7201 2.81812 18.6954 2.93315 18.6473C3.04817 18.5992 3.15249 18.5288 3.24002 18.44Z" }),
        React.createElement("path", { d: "M17.76 15.13C22.2 9.94 22.49 2.83 22.5 2.48C22.5036 2.43674 22.5036 2.39326 22.5 2.35C22.5057 2.28011 22.5057 2.20988 22.5 2.14L22.36 2C22.3156 1.9236 22.2619 1.85306 22.2 1.79C22.1197 1.71925 22.0285 1.66185 21.93 1.62H21.86C21.781 1.58495 21.6963 1.56462 21.61 1.56H21.55C21.2 1.56 14.12 1.83 8.93001 6.28C8.83586 6.24727 8.73873 6.22383 8.64001 6.21L5.78001 6C5.6492 5.9912 5.51803 6.01077 5.39549 6.05737C5.27295 6.10397 5.16192 6.1765 5.07001 6.27L1.78001 9.57C1.67814 9.68538 1.60429 9.82272 1.56419 9.97132C1.5241 10.1199 1.51884 10.2758 1.54884 10.4267C1.57884 10.5777 1.64328 10.7197 1.73715 10.8417C1.83101 10.9637 1.95177 11.0623 2.09001 11.13L5.19001 12.39H5.40001C5.39509 12.4499 5.39509 12.5101 5.40001 12.57C5.3858 12.7088 5.40266 12.849 5.44936 12.9804C5.49606 13.1119 5.57143 13.2313 5.67001 13.33L10.67 18.33C10.7584 18.417 10.8631 18.4856 10.978 18.5319C11.093 18.5783 11.216 18.6014 11.34 18.6H11.59C11.5852 18.6566 11.5852 18.7134 11.59 18.77L12.85 21.88C12.9078 22.0254 13.0013 22.1538 13.1219 22.2535C13.2425 22.3531 13.3863 22.4207 13.54 22.45H13.73C13.9805 22.4439 14.2197 22.344 14.4 22.17L17.65 18.87C17.7437 18.7778 17.8171 18.667 17.8653 18.5447C17.9135 18.4224 17.9356 18.2913 17.93 18.16L17.81 15.33C17.8032 15.2613 17.7863 15.1939 17.76 15.13ZM20.46 3.84C20.12 6.35 18.96 11.67 15.09 15.16L8.85001 8.92C12.35 5.03 17.66 3.92 20.16 3.54C20.2006 3.53517 20.2417 3.53961 20.2803 3.55298C20.3189 3.56636 20.3539 3.58831 20.3828 3.61719C20.4117 3.64607 20.4337 3.68113 20.447 3.71972C20.4604 3.75832 20.4648 3.79944 20.46 3.84ZM4.14001 9.91L6.11001 8H7.11001C6.85001 8.31 6.60001 8.62 6.36001 9C6.04377 9.48343 5.8137 10.018 5.68001 10.58L4.14001 9.91ZM7.31001 12.35C7.36283 11.7112 7.50071 11.0823 7.72001 10.48L13.52 16.27C12.927 16.5027 12.3043 16.6508 11.67 16.71L7.31001 12.35ZM14.07 19.85L13.44 18.3C14.0881 18.1438 14.7017 17.8692 15.25 17.49L15.6 17.2L15.72 17.13L15.96 16.93V17.93L14.07 19.85Z" })));
};
