import { AccountId, Ballot, InvestorVoteResponse, PollId, ProposalId } from '@tumelo/shared'
import { AppThunk } from '../../store'
import { selectBreakdown } from '../breakdown/selectors'
import * as featuredVoteActions from '../featuredVotes'
import { fetchPollsAndBallots } from '../../asyncActions'
import { isValueState } from '../../payload'
import * as singleBallotActions from '.'

export const fetchBallotByPollId =
  (pollId: PollId): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { votingService, loggerService } = services
    try {
      dispatch(singleBallotActions.setBallotPending(pollId))
      const ballot = await votingService.getInvestorBallotByPollId(pollId)
      if (!ballot) {
        dispatch(singleBallotActions.setBallotNotFound(pollId))
      } else {
        dispatch(singleBallotActions.setBallot({ pollId, ballot }))
      }
    } catch (e) {
      dispatch(singleBallotActions.setBallotError(pollId))
      loggerService.logError(e)
    }
  }

export const fetchPollByPollId =
  (pollId: PollId): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { pollService, loggerService } = services
    try {
      dispatch(singleBallotActions.setPollAndOrganizationPending(pollId))
      const pollAndOrganization = await pollService.getPoll(pollId)

      if (!pollAndOrganization) {
        dispatch(singleBallotActions.setPollAndOrganizationNotFound(pollId))
      } else {
        dispatch(singleBallotActions.setPollAndOrganization({ pollId, pollAndOrganization }))
      }
    } catch (e) {
      dispatch(singleBallotActions.setPollAndOrganizationError(pollId))
      loggerService.logError(e)
    }
  }

export const castVote =
  (b: Ballot, option: InvestorVoteResponse): AppThunk =>
  async (dispatch, getState, { services }) => {
    const breakdown = selectBreakdown(getState())
    const { votingService, loggerService } = services
    try {
      const ballot = await votingService.castVote(b.id, option)
      dispatch(singleBallotActions.setBallot({ pollId: ballot.pollId, ballot }))
      dispatch(featuredVoteActions.setInvestorOpenVotesCloseToExpirationNotInitialised())
      if (isValueState(breakdown)) {
        // Update polls and ballots if breakdown has previously been set
        dispatch(fetchPollsAndBallots())
      }
    } catch (e) {
      loggerService.logError(e)
    }
  }

export const sendComment =
  (b: Ballot, comment: string): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { votingService, loggerService } = services
    try {
      const ballot = await votingService.sendComment(b.id, comment)
      dispatch(singleBallotActions.setBallot({ pollId: ballot.pollId, ballot }))
    } catch (e) {
      loggerService.logError(e)
    }
  }

export const fetchFundManagerVotesByBallot =
  (ballot: Ballot): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { fundManagerVotesService, loggerService } = services
    const { pollId } = ballot
    try {
      dispatch(singleBallotActions.setFundManagerVotesWithFundManagerPending(pollId))
      const fundManagersAndVotes = await fundManagerVotesService.fetchFundManagerVotesForBallot(ballot.id)
      if (!fundManagersAndVotes) {
        dispatch(singleBallotActions.setFundManagerVotesWithFundManagerNotFound(pollId))
      } else {
        dispatch(singleBallotActions.setFundManagerVotesWithFundManager({ pollId, fundManagersAndVotes }))
      }
    } catch (e) {
      dispatch(singleBallotActions.setFundManagerVotesWithFundManagerError(pollId))
      loggerService.logError(e)
    }
  }

export const fetchFundManagerVotesByProposalId =
  (accountId: AccountId, proposalIds: ProposalId[], pollId: PollId): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { fundManagerVotesService, loggerService } = services
    try {
      dispatch(singleBallotActions.setFundManagerVotesWithFundManagerPending(pollId))
      const fundManagersAndVotes = await fundManagerVotesService.fetchFundManagerVotesForProposal(
        proposalIds,
        accountId
      )
      if (!fundManagersAndVotes) {
        dispatch(singleBallotActions.setFundManagerVotesWithFundManagerNotFound(pollId))
      } else {
        dispatch(singleBallotActions.setFundManagerVotesWithFundManager({ pollId, fundManagersAndVotes }))
      }
    } catch (e) {
      dispatch(singleBallotActions.setFundManagerVotesWithFundManagerError(pollId))
      loggerService.logError(e)
    }
  }
