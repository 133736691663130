import React from 'react';
import styled from 'styled-components';
import { ToggleSwitch } from '../../../ToggleSwitch';
import { Search } from '../components/Search';
import { FilterList } from '../components/FilterList';
import { IndustriesFilterMenu } from './IndustriesFilterMenu';
export const CompaniesFilterMenu = ({ showWithVotesFilter, withVotesFilter, industriesFilter, companyNameFilter, organizationIndustriesToggle, handleSetSearchValue, handleToggleWithVotesFilter, handleClearFilters, handleIndustriesFilter, handleOrganizationIndustriesToggle, handleSearchKeyDown, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Search, { value: companyNameFilter, setSearchValue: handleSetSearchValue, placeholder: "Search for company", name: "search for company", handleSearchKeyDown: handleSearchKeyDown }),
        React.createElement(Separator, null),
        showWithVotesFilter && (React.createElement(ToggleSwitch, { active: withVotesFilter, caption: "Companies with votes", onClick: handleToggleWithVotesFilter })),
        React.createElement(FilterListPadding, null,
            React.createElement(FilterList, { caption: "Filter companies by: ", clearAll: handleClearFilters }),
            React.createElement(IndustriesFilterMenu, { industries: industriesFilter, callback: handleIndustriesFilter, organizationIndustriesToggle: organizationIndustriesToggle, handleOrganizationIndustriesToggle: handleOrganizationIndustriesToggle }))));
};
const FilterListPadding = styled.div `
  padding-bottom: 2rem;
`;
const Separator = styled.div `
  margin: 2rem 0;
`;
