import React from 'react'
import { ConfirmSmsOtpCodeValues } from '../components/Auth/Forms/ConfirmSmsOtpCodeForm'
import { ConfirmSmsOtpCodePage } from '../components/Auth/Pages/ConfirmSmsOtpCodePage'
import { useAppDispatch, useAppSelector } from '../application/store'
import { confirmSignIn } from '../application/features/auth/asyncActions'
import { setAuthState } from '../application/features/auth'
import { selectAuthUser } from '../application/features/auth/selectors'

export const ConfirmSignIn: React.FC = () => {
  const dispatch = useAppDispatch()
  const { phonenumber } = useAppSelector(selectAuthUser)
  const submitCode = async (values: ConfirmSmsOtpCodeValues) => {
    const { code } = values
    dispatch(confirmSignIn(code))
  }

  return (
    <ConfirmSmsOtpCodePage
      submit={submitCode}
      goToSignIn={() => dispatch(setAuthState('signIn'))}
      phoneNumber={phonenumber ?? ''}
    />
  )
}
