import { PollId } from '@tumelo/shared'
import { AppThunk } from '../../store'
import * as featuredVotesActions from '.'

export const getInvestorOpenVotesCloseToExpiration =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { pollService, loggerService } = services
    dispatch(featuredVotesActions.setInvestorOpenVotesCloseToExpirationPending())
    try {
      const openVotes = await pollService.getInvestorOpenVotesCloseToExpiration()
      dispatch(featuredVotesActions.setInvestorOpenVotesCloseToExpiration(openVotes))
    } catch (e) {
      loggerService.logError(e)
      dispatch(featuredVotesActions.setInvestorOpenVotesCloseToExpirationError())
    }
  }

export const getMostVotedOnOpenPoll =
  (pollId: PollId): AppThunk =>
  async (dispatch, _, { services }) => {
    const { pollService, loggerService } = services
    dispatch(featuredVotesActions.mostVotedOnInvestorOpenVotePending())
    try {
      const resp = await pollService.getPoll(pollId)
      if (resp) {
        dispatch(featuredVotesActions.mostVotedOnInvestorOpenVote({ ...resp }))
      } else {
        dispatch(featuredVotesActions.mostVotedOnInvestorOpenVote())
      }
    } catch (e) {
      loggerService.logError(e)
      dispatch(featuredVotesActions.mostVotedOnInvestorOpenVoteError())
    }
  }
