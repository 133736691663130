/* eslint-disable @typescript-eslint/no-deprecated */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore as toolkitConfigureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import { createMiddleware } from 'redux-beacon'
import GoogleAnalytics, { trackEvent } from '@redux-beacon/google-analytics'
import { EventsMap } from 'redux-beacon/src/types'
// eslint-disable-next-line no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Config } from '../config'
import { Services } from './services'
import rootReducer, { RootState } from './rootReducer'

const eventsMap: EventsMap = {
  '*': trackEvent(({ type }) => ({ category: 'redux', action: type })),
}

const configureStore = (extraArgument: { services: Services }, config: Config, enableAnalyticsRedux = false) => {
  const middleware = getDefaultMiddleware({ thunk: { extraArgument } })

  if (enableAnalyticsRedux) {
    const ga = GoogleAnalytics()
    const gaMiddleware = createMiddleware(eventsMap, ga)
    middleware.push(gaMiddleware)
  }

  return toolkitConfigureStore({
    reducer: rootReducer,
    // TODO fix this typescript has gotten better and stricter and this needs fixing
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    preloadedState: { config: { config } },
    middleware,
  })
}

export type AppThunk = ThunkAction<void, RootState, { services: Services }, Action<string>>

type AppDispatch = ReturnType<typeof configureStore>['dispatch']
type ThinkDispatch = (action: AppThunk) => void

export const useAppDispatch: () => (arg: Parameters<ThinkDispatch>[0] | Parameters<AppDispatch>[0]) => void =
  useDispatch as any
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export { configureStore }
