/* eslint-disable @typescript-eslint/no-deprecated */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { LinkRounded } from '@mui/icons-material';
import { Card, CardHeader, CardContent } from '../../buildingBlocks';
import { Body, SmallHeading, SubHeading } from '../../buildingBlocks/Typography';
import { industryCategoryToIndustryCategoryTitle } from '../../domain';
import { IndustryIcon } from '../IndustryIcon/IndustryIcon';
import { getCountryNameFromISO } from '../../utils/formatting/countryCode';
export const OrganizationOverview = ({ organization, flagComponent }) => {
    const { colors } = useContext(ThemeContext);
    const { bio, industryCategory, websiteUrl, headquarters } = organization;
    return (React.createElement(Card, null,
        React.createElement(CardHeader, { helpText: HELP_TEXT },
            React.createElement(SubHeading, { mb: 0 }, TITLE)),
        React.createElement(CardContent, null,
            bio ? (React.createElement(React.Fragment, null,
                React.createElement(Body, { color: colors.dark }, bio.description || BIO_NOT_AVAILABLE),
                React.createElement(Item, null,
                    React.createElement(StyledSmallHeading, { as: "h3" }, "Source"),
                    bio.sourceUrl ? React.createElement(LinkItem, { url: bio.sourceUrl, text: bio.source }) : React.createElement(Body, { m: 0 }, bio.source)))) : (React.createElement(Item, null,
                React.createElement(StyledSmallHeading, { as: "h3" }, "Bio"),
                React.createElement(Body, { m: 0 }, NOT_AVAILABLE))),
            React.createElement(Item, null,
                React.createElement(StyledSmallHeading, { as: "h3" }, "Industry"),
                industryCategory ? (React.createElement(Row, null,
                    React.createElement(IndustryIcon, { industryCategory: industryCategory, imageStyle: { height: '3rem', width: '3rem', marginRight: '1rem' } }),
                    React.createElement(Body, { m: 0 }, industryCategoryToIndustryCategoryTitle(industryCategory)))) : (React.createElement(Body, { m: 0 }, NOT_AVAILABLE))),
            React.createElement(Item, null,
                React.createElement(StyledSmallHeading, { as: "h3" }, "Head office"),
                headquarters && headquarters.isoCountryCode2 && getCountryNameFromISO(headquarters.isoCountryCode2) ? (React.createElement(Row, null,
                    flagComponent,
                    React.createElement(Body, { m: 0 }, getCountryNameFromISO(headquarters.isoCountryCode2)))) : (React.createElement(Body, { m: 0 }, NOT_AVAILABLE))),
            React.createElement(Item, null,
                React.createElement(StyledSmallHeading, { as: "h3" }, "Website"),
                websiteUrl ? React.createElement(LinkItem, { url: websiteUrl, text: VISIT_COMPANY }) : React.createElement(Body, { m: 0 }, NOT_AVAILABLE)))));
};
const LinkItem = ({ url, text }) => {
    return (React.createElement(Container, null,
        React.createElement(LinkIcon, { className: "icon" }),
        React.createElement(LinkItemText, { as: "a", href: url, target: "_blank", rel: "noopener noreferrer" }, text)));
};
const Row = styled.div `
  display: flex;
  align-items: center;
`;
const Container = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LinkIcon = styled(LinkRounded) `
  color: ${({ theme }) => theme.colors.secondary.medium};
  margin-right: 1rem;
`;
const LinkItemText = styled(Body) `
  color: ${({ theme }) => theme.colors.link.dark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.link.dark};
  margin: 0;
`;
const Item = styled.div `
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: left;
`;
const StyledSmallHeading = styled(SmallHeading) `
  margin: 0 0 1rem;
`;
const BIO_NOT_AVAILABLE = 'Bio not available';
const NOT_AVAILABLE = 'Not available';
const TITLE = 'About this company';
const HELP_TEXT = `This tells you more about a specific company you have in your portfolio.

It includes a short bio of the company and a link to their website.`;
const VISIT_COMPANY = 'Visit Company Website';
