import React, { useEffect } from 'react'
import styled from 'styled-components'
import { isPollOpen, Poll, NoResultsMessage, timestampToDate } from '@tumelo/shared'
import { isBefore } from 'date-fns'
import { BallotWithPollAndOrg } from '../../../application/types/PollWithOrganization/PollWithOrganization'
import { useRouter } from '../../../application/router/useRouter'
import { useAnalytics } from '../../../application/analytics'
import { MyVotesInfographic } from '../../ProgressWheelInfographic/MyVotesInfographic'
import { ListContainer } from '../../PollsList/ListContainer'
import { buildEvent } from '../../../application/analytics/eventBuilders'
import { PollCardlet } from '../../Cardlet/PollCardlet'
import { ListTitle } from './ListTitle'

interface Props {
  votes: BallotWithPollAndOrg[]
}

export const MyVotesList: React.FC<Props> = ({ votes }) => {
  const isAGMInPast = (poll: Poll): boolean => {
    const today = new Date()
    today.setHours(12, 0, 0, 0)
    const AGMDate = timestampToDate(poll.relationships.generalMeeting.date)
    AGMDate.setHours(12, 0, 0, 0)
    return isBefore(AGMDate, today)
  }
  const hasOutcome = (poll: Poll): boolean => poll.relationships.proposal.outcome != null
  const { navigate } = useRouter()
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    if (votes.length === 0) {
      trackEvent(buildEvent.myVotesEmptyStateRendered())
    }
  }, [trackEvent, votes])

  if (votes.length === 0) {
    return (
      <FlexContainer>
        <NoResultsMessage
          heading="Welcome to your personal voting area!"
          body="Here you can keep track of everything you've voted on and the final outcome. Remember, your voice, joined with others on the platform, is a powerful force. You can use it to push companies to do better. Get started by placing your first vote preference."
          button={{ text: 'Go to Open votes', onClick: () => navigate('/votes/open') }}
        />
      </FlexContainer>
    )
  }
  const openVotes = votes.filter((vote) => isPollOpen(vote.poll))
  const votesAwaitingAGM = votes.filter(
    (vote) => !isPollOpen(vote.poll) && !isAGMInPast(vote.poll) && !hasOutcome(vote.poll)
  )
  const results = votes.filter((vote) => hasOutcome(vote.poll))

  return (
    <FlexContainer>
      {openVotes.length > 0 && (
        <div data-testid="stillOpen">
          <ListContainer title={<ListTitle polls={openVotes} suffix="still open for your fellow user’s vote" />}>
            <MyVotesInfographic voteStatus="open" />
            {openVotes.map((poll) => (
              <PollCardlet key={poll.poll.id} poll={poll} navigateTo="openVote" />
            ))}
          </ListContainer>
        </div>
      )}
      {votesAwaitingAGM.length > 0 && (
        <div data-testid="awaitingAGM">
          <ListContainer title={<ListTitle polls={votesAwaitingAGM} suffix="awaiting the AGM" />}>
            <MyVotesInfographic voteStatus="awaitingOutcome" />
            {votesAwaitingAGM.map((poll) => (
              <PollCardlet key={poll.poll.id} poll={poll} navigateTo="openVote" />
            ))}
          </ListContainer>
        </div>
      )}
      {results.length > 0 && (
        <div data-testid="haveResults">
          <ListContainer title={<ListTitle polls={results} suffix="have results" />}>
            <MyVotesInfographic voteStatus="results" />
            {results.map((poll) => (
              <PollCardlet key={poll.poll.id} poll={poll} navigateTo="voteResult" />
            ))}
          </ListContainer>
        </div>
      )}
    </FlexContainer>
  )
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100rem;
  max-width: 100%;
`
