export const selectBreakdownByAz = (breakdown) => {
    if (breakdown.organizationEntries.length === 0) {
        return [];
    }
    const other = 'Other';
    const sortedEntries = breakdown.organizationEntries.sort(alphabetizeOrgsByTitle);
    const azMap = new Map();
    sortedEntries.forEach((entry) => {
        if (firstLetterIsLatinAlphabet(entry.organization.title)) {
            const firstLetter = entry.organization.title[0].toLocaleUpperCase();
            upsertOrganizationBreakdownAzMap(firstLetter, entry, azMap);
        }
        else {
            upsertOrganizationBreakdownAzMap(other, entry, azMap);
        }
    });
    const organizationBreakdownAz = Array.from(azMap, ([category, orgs]) => ({ category, orgs }));
    if (organizationBreakdownAz[0].category === other) {
        const otherOrgs = organizationBreakdownAz.shift();
        if (otherOrgs) {
            organizationBreakdownAz.push(otherOrgs);
        }
    }
    return organizationBreakdownAz;
};
const upsertOrganizationBreakdownAzMap = (category, entry, map) => {
    const breakdown = map.get(category);
    if (breakdown !== undefined) {
        map.set(category, [...breakdown, entry]);
    }
    else {
        map.set(category, [entry]);
    }
};
const alphabetizeOrgsByTitle = (a, b) => a.organization.title.localeCompare(b.organization.title);
const firstLetterIsLatinAlphabet = (str) => /[a-z]/gi.test(str[0]);
