import React from 'react'
import { ProgressWheelInfographic } from './ProgressWheelInfographic'

interface Props {
  voteStatus: myVotesStatus
}

type myVotesStatus = 'open' | 'awaitingOutcome' | 'results'

export const MyVotesInfographic: React.FC<Props> = ({ voteStatus }) => {
  const steps: [string, string, string] = ['You', 'Fellow users', 'Fund manager(s) & the final result']
  switch (voteStatus) {
    case 'open':
      return (
        <ProgressWheelInfographic
          stepIndex={0}
          steps={steps}
          body="You’ve voted on these votes, but there's still some time left for your fellow users to have their say."
        />
      )
    case 'awaitingOutcome':
      return (
        <ProgressWheelInfographic
          stepIndex={1}
          steps={steps}
          body="You and your fellow users have had a say on these votes. They’ve been sent to your fund manager(s) awaiting the AGM to take place."
        />
      )
    case 'results':
      return (
        <ProgressWheelInfographic
          stepIndex={2}
          steps={steps}
          body="The AGM has taken place — check out 
          the results available now. Some fund managers will have published their votes too by now."
        />
      )
    default:
      return null
  }
}
