import { dateToTimestamp } from '@tumelo/shared'
import { tryExecute } from '../../asyncActions'
import { AppThunk } from '../../store'
import { isValueState } from '../../payload'
import { setAccount, setAvailableModelPortfolios } from '.'
import * as accountActions from './index'

export const fetchAccount =
  (): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { accountService, loggerService } = services
    const tryExecuteL = tryExecute(loggerService)

    const { investor } = getState().investor
    if (isValueState(investor) && !investor.investorId) {
      loggerService.logError('Account fetched before investorId has been set')
    }

    dispatch(setAccount('pending'))

    const account = (await tryExecuteL(() => accountService.getAccount())) ?? 'not-configured'

    dispatch(setAccount(account))
  }

export const fetchAvailableModelPortfolios =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { modelPortfolioService, loggerService } = services
    const tryExecuteL = tryExecute(loggerService)
    dispatch(setAvailableModelPortfolios('pending'))
    const modelPortfolios = await tryExecuteL(() => modelPortfolioService.listModelPortfolios())
    dispatch(setAvailableModelPortfolios(modelPortfolios))
  }

/**
 * triggerFetchOfCompositions will trigger if fetch undefined or more than a day old, don't refresh
 * TODO: (Optimisaiton) The trigger fetch could return the account and the composition
 */
export const triggerFetchOfCompositions =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    try {
      dispatch(accountActions.setLatestTriggerFetchLoading())
      await services.accountTriggerFetchCompositionService.triggerFetch()
      dispatch(accountActions.setLatestTriggerFetchComplete(dateToTimestamp(new Date())))
    } catch (e) {
      dispatch(accountActions.setLatestTriggerFetchError())
      services.loggerService.logError(e)
    }
  }
