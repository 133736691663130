import React from 'react'
import { BasePageProps, Content } from '@tumelo/shared'
import { useRouter } from '../application/router/useRouter'
import { NotFoundError } from '../components/Error'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { changeTitle } from './changeTitle'

type Props = BasePageProps

const NotFoundPage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)

  const { navigate } = useRouter()

  return (
    <ErrorBoundary>
      <Content>
        <NotFoundError goBack={() => navigate('/dashboard')} />
      </Content>
    </ErrorBoundary>
  )
}

export default NotFoundPage
