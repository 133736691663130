/**
 * hexAlpha takes a hexColor and an opacity and returns a color in the format rrggbbaa with the opacity applied
 * @param hexColor - a hex color in the format #rrggbb
 * @param opacity - a number between 0 and 1
 */
export const hexAlpha = (hexColor, opacity) => {
    if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hexColor)) {
        throw new Error(`${hexColor} is not a valid hex color.`);
    }
    if (opacity < 0 || opacity > 1) {
        throw new Error(`${opacity} is not a valid opacity, must be between 0 and 1.`);
    }
    const aa = Math.round(opacity * 255).toString(16);
    return `${hexColor}${aa}`;
};
