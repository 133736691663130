import React, { Suspense } from 'react'
import { Loader, BasePageProps } from '@tumelo/shared'
import { useAppSelector } from '../application/store'
import { selectSoftConfig } from '../application/features/config/selectors'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { changeTitle } from './changeTitle'

const CompaniesPageLazy = React.lazy(() => import('./CompaniesPageLazy'))

type Props = BasePageProps

const CompaniesPage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)
  const pageType = useAppSelector((state) => selectSoftConfig(state).featureSwitches.companiesArea.type)

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <PageContent pageType={pageType} />
      </Suspense>
    </ErrorBoundary>
  )
}

const PageContent: React.FC<{ pageType: 'comprehensive' | 'minimal' | 'off' }> = ({ pageType }) => {
  switch (pageType) {
    case 'comprehensive':
      return (
        <CompaniesPageLazy
          helpText="Your pension money has been used to buy bits of these companies. These are called shares.
        The percentage shows how much of your pension money is invested in a specific company."
          showPercentageWeights
        />
      )
    case 'minimal':
      return (
        <CompaniesPageLazy
          helpText="Your pension money has been used to buy bits of these companies. These are called shares."
          showPercentageWeights={false}
        />
      )
    default:
      throw new Error(`Invalid page type ${pageType}`)
  }
}

export default CompaniesPage
