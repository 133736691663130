import { AppThunk } from '../../store'
import * as statisticsActions from '.'

export const getInvestorStatistics =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { pollService, loggerService } = services
    dispatch(statisticsActions.setInvestorStatisticsPending())
    try {
      const statistics = await pollService.getInvestorStatistics()
      dispatch(statisticsActions.setInvestorStatistics(statistics))
    } catch (e) {
      loggerService.logError(e)
      dispatch(statisticsActions.setInvestorStatisticsError())
    }
  }

export const getHabitatStatistics =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { pollService, loggerService } = services
    dispatch(statisticsActions.setHabitatStatisticsPending())
    try {
      const statistics = await pollService.getHabitatStatistics()
      dispatch(statisticsActions.setHabitatStatistics(statistics))
    } catch (e) {
      loggerService.logError(e)
      dispatch(statisticsActions.setHabitatStatisticsError())
    }
  }
