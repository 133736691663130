/* eslint-disable @typescript-eslint/no-deprecated */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import styled from 'styled-components';
import { Field, useFormikContext, useField } from 'formik';
import { Body, SmallBody } from '../..';
import { WarningIcon } from './WarningIcon';
export const TextAreaWithSuggestions = (_a) => {
    var { suggestions, showValidationErrorMessages = true, charLimit, name } = _a, rest = __rest(_a, ["suggestions", "showValidationErrorMessages", "charLimit", "name"]);
    const { setFieldValue, getFieldMeta } = useFormikContext();
    const [field] = useField(name);
    const [focus, setFocus] = useState(false);
    const { error } = getFieldMeta(name);
    const hasError = showValidationErrorMessages && error !== undefined;
    const textAreaState = {
        focus,
        hasError,
    };
    const charLimitText = charLimit && formatCharLimitText(field.value.length, charLimit);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, Object.assign({}, textAreaState),
            React.createElement(TextArea, Object.assign({}, rest, { name: name, id: name, showValidationErrorMessages: showValidationErrorMessages, value: field.value, as: "textarea", onChange: (e) => setFieldValue(field.name, e.target.value), onFocus: () => setFocus(true), onBlur: () => setFocus(false), rows: 5 })),
            React.createElement(Suggestions, null, suggestions === null || suggestions === void 0 ? void 0 : suggestions.map((suggestion) => (React.createElement(SuggestionButton, Object.assign({}, textAreaState, { type: "button", onClick: () => setFieldValue(field.name, suggestion), key: suggestion }),
                React.createElement(SmallBody, { m: 0 }, suggestion))))),
            hasError && React.createElement(WarningIcon, { indent: 1, top: 1 }),
            React.createElement(CharacterCount, { isError: hasError },
                React.createElement("span", null, charLimitText))),
        React.createElement(HelperText, { isError: hasError },
            React.createElement("span", null, error))));
};
const formatCharLimitText = (currentLength, limit) => `${currentLength} / ${limit}`;
const Container = styled.div `
  border: solid 1px;
  border-color: ${({ theme, focus, hasError }) => hasError ? theme.colors.danger.dark : focus ? theme.colors.success.dark : theme.colors.grey.medium};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 1rem 5rem 1rem 1rem;
  position: relative;
`;
const Suggestions = styled.div `
  display: flex;
  flex-wrap: wrap;
`;
const TextArea = styled(Field) `
  border: none;
  resize: none;
  outline: none;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: italic;
`;
const CharacterCount = styled(Body) `
  font-size: 1.3rem;
  position: absolute;
  color: ${(p) => (p.isError ? p.theme.colors.danger.dark : p.theme.colors.dark)};
  right: 2.9rem;
  bottom: -0.7rem;
  line-height: 1.3rem;
  background-color: white;
  margin: 0;
  padding: 0;
`;
const HelperText = styled(Body) `
  font-size: 1.3rem;
  color: ${(p) => (p.isError ? p.theme.colors.danger.dark : p.theme.colors.dark)};
  left: 2.9rem;
  line-height: 1.3rem;
  display: flex;
  padding: 1rem 0 0;
  margin: 0;
`;
const SuggestionButton = styled.button `
  margin: 8px 8px 0 0;
  border: solid 1px;
  border-color: ${({ theme, focus, hasError }) => hasError ? theme.colors.danger.dark : focus ? theme.colors.success.dark : theme.colors.grey.medium};
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.background.light};
  cursor: pointer;
  border-radius: 5px;
  p {
    font-weight: normal;
    color: ${({ theme }) => theme.colors.grey.dark};
  }
`;
