import * as React from 'react';
import styled from 'styled-components';
export const BackButton = ({ onClick, className }) => (React.createElement(Container, { className: className, onClick: onClick, tabIndex: 0 },
    React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "40", height: "40", viewBox: "15 15 40 40" },
        React.createElement("g", { id: "prefix__Buttons_Solid_Icon_Medium", "data-name": "Buttons / Solid / Icon / Medium", transform: "translate(15 15)" },
            React.createElement("path", { id: "prefix__icon", d: "M278.44 428.06l5.88 5.88a.76.76 0 0 1 .37.65.79.79 0 0 1-.1.37.76.76 0 0 1-.13.17l-.08.07-5.93 5.93a.75.75 0 1 1-1.06-1.06l4.73-4.73h-12.68a.75.75 0 0 1-.75-.75.75.75 0 0 1 .75-.75h12.66l-4.71-4.71a.746.746 0 1 1 1.05-1.06z", transform: "rotate(180 148.589 227.426)", style: {
                    strokeWidth: '.5px',
                } })))));
const Container = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  height: 4rem;
  width: 4rem;
  align-self: center;
  fill: ${({ theme }) => theme.colors.secondary.medium};
  stroke: ${({ theme }) => theme.colors.secondary.medium};
  border-radius: 0.5rem;
  box-shadow: 0 3px 6px 0 ${({ theme }) => theme.colors.boxShadow};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    box-shadow: 0 3px 12px 0 ${({ theme }) => theme.colors.boxShadow};
  }
`;
