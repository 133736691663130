import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { Poll } from '@tumelo/shared'

export const pollsAdapter = createEntityAdapter<Poll>()

export type PollsStatusType = 'partial' | 'all' | 'not-initialised' | 'error' | 'pending'

type PollsState = {
  polls: EntityState<Poll>
  pollsStatus: PollsStatusType
}

export const getInitialState = (): PollsState => {
  return { polls: pollsAdapter.getInitialState(), pollsStatus: 'not-initialised' }
}

const pollsSlice = createSlice({
  name: 'polls',
  initialState: getInitialState(),
  reducers: {
    setPolls: (state, action: PayloadAction<Poll[]>) => {
      const { payload } = action
      state.polls = pollsAdapter.setAll(state.polls, payload)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setStatusAll: (state, __: PayloadAction<void>) => {
      state.pollsStatus = 'all'
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setPartial: (state, __: PayloadAction<void>) => {
      state.pollsStatus = 'partial'
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setPending: (state, __: PayloadAction<void>) => {
      state.pollsStatus = 'pending'
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setError: (state, __: PayloadAction<void>) => {
      state.pollsStatus = 'error'
    },
  },
})

export default pollsSlice.reducer

export const { setPolls, setPending, setError, setPartial, setStatusAll } = pollsSlice.actions
