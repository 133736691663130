import React, { ComponentProps } from 'react'
import { Markdown as MarkdownShared } from '@tumelo/shared'
import { useRouter } from '../../application/router/useRouter'

type Props = Omit<ComponentProps<typeof MarkdownShared>, 'internalLinkHandler'>

export const Markdown: React.FC<Props> = (props) => {
  const { navigate } = useRouter()

  const internalLinkHandler = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const target = e.target as Element
    const targetLink = target.closest('a')
    if (!targetLink) return
    e.preventDefault()

    const href = targetLink.getAttribute('href')
    if (href) {
      navigate(href)
    }
  }

  return <MarkdownShared internalLinkHandler={internalLinkHandler} {...props} />
}
