import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgHeart = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M12 22C11.8728 22.0013 11.7466 21.9762 11.6296 21.9263C11.5125 21.8764 11.4071 21.8027 11.32 21.71L2.82999 13C1.65603 11.7781 1.00038 10.1494 1.00038 8.455C1.00038 6.76056 1.65603 5.13185 2.82999 3.91C3.40113 3.31503 4.08664 2.84164 4.84533 2.51826C5.60403 2.19488 6.42026 2.02818 7.24499 2.02818C8.06973 2.02818 8.88596 2.19488 9.64465 2.51826C10.4033 2.84164 11.0889 3.31503 11.66 3.91L12 4.26L12.34 3.91C12.9046 3.31481 13.5853 2.84173 14.34 2.52C15.4809 2.03592 16.7419 1.90963 17.9561 2.15783C19.1703 2.40603 20.2806 3.01704 21.14 3.91C21.7348 4.50763 22.204 5.21825 22.52 6C23.1604 7.57748 23.1604 9.34252 22.52 10.92C22.2104 11.695 21.7517 12.4016 21.17 13L12.68 21.74C12.5903 21.8273 12.4837 21.8954 12.3668 21.9401C12.2499 21.9848 12.1251 22.0052 12 22ZM7.23999 4C6.67852 4.00568 6.12385 4.12362 5.60864 4.34688C5.09343 4.57013 4.62808 4.8942 4.23999 5.3C3.42549 6.14437 2.97034 7.27181 2.97034 8.445C2.97034 9.61819 3.42549 10.7456 4.23999 11.59L12.05 19.59L19.86 11.59C20.2664 11.1777 20.5861 10.688 20.8 10.15C21.2404 9.05945 21.2404 7.84055 20.8 6.75C20.5863 6.20892 20.2667 5.71595 19.86 5.3C19.4699 4.87071 18.9926 4.52976 18.46 4.3C17.9366 4.07749 17.3737 3.9628 16.805 3.9628C16.2363 3.9628 15.6734 4.07749 15.15 4.3C14.6174 4.52976 14.1401 4.87071 13.75 5.3L12.75 6.3C12.5651 6.4715 12.3222 6.56679 12.07 6.56679C11.8178 6.56679 11.5749 6.4715 11.39 6.3L10.39 5.3C9.98417 4.87623 9.49443 4.54174 8.95205 4.31791C8.40967 4.09407 7.82657 3.98579 7.23999 4Z" })));
};
