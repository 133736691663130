import React from 'react'
import styled from 'styled-components'
import { Button } from '@tumelo/shared'
import { BackButton } from '../../buildingBlocks/BackButton'
import { Markdown } from '../Markdown'

interface Props {
  logOut: () => void
  goBack: () => void
  /**
   * Title text, not markdown
   */
  titleText: string
  /**
   * Markdown copy for the body
   */
  unsupportedFundText: string
}

export const UnsupportedFund: React.FC<Props> = ({ logOut, goBack, unsupportedFundText, titleText }) => (
  <>
    <StyledBackButton onClick={goBack} />
    <Title>{titleText}</Title>
    <Markdown markdown={unsupportedFundText} />
    <ButtonContainer>
      <Button onClick={logOut}>Sign Out</Button>
    </ButtonContainer>
  </>
)

const StyledBackButton = styled(BackButton)`
  margin-bottom: 2rem;
`

const Title = styled.h2`
  margin-bottom: 2rem;
`

const ButtonContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.link.medium};
`
