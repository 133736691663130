import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgDefence = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M12 22H11.5C11.41 22 11.33 21.99 11.25 21.97C5.51 20.46 1.5 15.18 1.5 9.13C1.5 8.01 1.64 6.89 1.91 5.8C1.98 5.54 2.14 5.32 2.37 5.18C2.6 5.04 2.88 5.01 3.13 5.07C6.41 5.93 8.95 5.05 10.66 2.44C10.7515 2.30103 10.8763 2.18709 11.023 2.10851C11.1696 2.02993 11.3336 1.9892 11.5 1.99H12C12.34 1.99 12.65 2.16 12.84 2.44C14.55 5.05 17.08 5.93 20.37 5.07C20.62 5 20.9 5.04 21.13 5.18C21.36 5.32 21.53 5.54 21.59 5.8C21.86 6.88 22 8 22 9.13C22 15.18 17.99 20.46 12.25 21.97C12.17 21.99 12.08 22 12 22ZM11.63 20H11.87C16.66 18.68 20 14.23 20 9.13C20 8.5 19.95 7.86 19.85 7.24C16.52 7.83 13.74 6.84 11.75 4.36C9.76 6.85 6.99 7.83 3.65 7.24C3.55 7.86 3.5 8.49 3.5 9.13C3.5 14.23 6.84 18.68 11.63 20Z" })));
};
