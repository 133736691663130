import { useEffect } from 'react'
import { TumeloAccount } from '@tumelo/shared'
import { isErrorStates, isIntermediaryState, isValueState, Payload } from '../../payload'
import { useInvestor } from '../investor/useInvestor'
import { useRouter } from '../../router/useRouter'
import { useAccount } from '../account/hooks'
import { CompositionWithInstruments, isCompositionWithInstrumentsEmpty } from '../../types/CompositionWithInstruments'
import { useAccountComposition } from './useAccountComposition'

export const useRequireAccountComposition = (): Payload<{
  account: TumeloAccount | 'not-configured'
  accountComposition: CompositionWithInstruments
}> => {
  const { investor } = useInvestor()
  const account = useAccount(investor)
  const { accountComposition } = useAccountComposition(account)
  const redirectUrl = '/compositionpending'
  const { navigate, pathname } = useRouter()

  const isAccountCompositionEmpty =
    isValueState(accountComposition) && isCompositionWithInstrumentsEmpty(accountComposition)

  useEffect(() => {
    if (isAccountCompositionEmpty && pathname !== redirectUrl) {
      navigate(redirectUrl)
    }
  }, [isAccountCompositionEmpty, navigate, pathname])

  if (isErrorStates(accountComposition) || isErrorStates(account)) {
    return 'error'
  }
  if (isIntermediaryState(accountComposition) || isIntermediaryState(account)) {
    return 'pending'
  }

  return { account, accountComposition }
}
