import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const NestEggsIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 26 20", xmlns: "http://www.w3.org/2000/svg", style: styleWithDefault },
        React.createElement("g", { "data-name": "Group 20738" },
            React.createElement("path", { "data-name": "Path 13481", d: "M2.557 11.29c.055.235.111.471.164.707a.521.521 0 0 0-.641.236.539.539 0 0 0 .122.731 3.619 3.619 0 0 0 .513.4 7.921 7.921 0 0 0 2.332.931 33.494 33.494 0 0 0 7.881.8h.335c1.193 0 2.4-.082 3.571-.161h.03a18.291 18.291 0 0 0 5.126-.955 8.682 8.682 0 0 0 1.642-.884l.073-.046a.549.549 0 0 0 .225-.786.542.542 0 0 0-.682-.244l.094-.351c.086-.32.168-.622.24-.93a12.741 12.741 0 0 0 .084-6.214 5.61 5.61 0 0 0-1.671-2.958 2.152 2.152 0 0 0-1.683-.551 4.891 4.891 0 0 0-2.579 1.14 12.8 12.8 0 0 0-.773.673c-.1.09-.2.18-.295.269l-.124-.21c-.116-.2-.236-.4-.367-.594a5.686 5.686 0 0 0-2.17-2.037 2.111 2.111 0 0 0-2.009 0 5.119 5.119 0 0 0-1.784 1.514c-.23.3-.437.615-.657.949l-.262.4a.572.572 0 0 0-.087-.124 6.847 6.847 0 0 0-2.654-1.8 2.394 2.394 0 0 0-2.959.8 6.012 6.012 0 0 0-.846 1.372 10.61 10.61 0 0 0-.561 6.168c.097.587.237 1.181.372 1.755Zm.877-6.57a5.038 5.038 0 0 1 .99-2 1.371 1.371 0 0 1 1.071-.608 2.057 2.057 0 0 1 .65.126 5.872 5.872 0 0 1 2.612 1.933.349.349 0 0 1 0 .259 15.3 15.3 0 0 0-.79 5.128 5.5 5.5 0 0 0 1.721 4.29 22.173 22.173 0 0 1-4.976-.817.728.728 0 0 1-.457-.383 9.758 9.758 0 0 1-.91-2.921 10.972 10.972 0 0 1 .089-5.007ZM9.06 9.258a14.159 14.159 0 0 1 .783-4.566 6.594 6.594 0 0 1 2.05-3.092 3.483 3.483 0 0 1 .506-.324 1.1 1.1 0 0 1 1.187 0 6.618 6.618 0 0 1 1.252 1.049 7.971 7.971 0 0 1 1.666 3.531 12.83 12.83 0 0 1 .338 4.673 3.78 3.78 0 0 1-4.285 3.4 3.712 3.712 0 0 1-3.4-3.393 9.809 9.809 0 0 1-.072-.87c-.008-.14-.015-.274-.025-.41Zm8.926-1.073a13.815 13.815 0 0 0-.766-3.808.264.264 0 0 1 .019-.206 5.957 5.957 0 0 1 2.835-2 1.552 1.552 0 0 1 .413-.06 1.254 1.254 0 0 1 .935.431 5.11 5.11 0 0 1 1.253 2.62 12.765 12.765 0 0 1-.643 6.886 1.6 1.6 0 0 1-1.294 1.154 25.678 25.678 0 0 1-4.365.614 5.21 5.21 0 0 0 1.347-2.142 9.033 9.033 0 0 0 .266-3.489Z" }),
            React.createElement("path", { "data-name": "Path 13482", d: "M24.844 10.127a.554.554 0 0 0-.829.059.56.56 0 0 0 .15.819 1.631 1.631 0 0 1 .687 1.214 6.757 6.757 0 0 1-.071 1.176 1.438 1.438 0 0 1-.709 1.079 10.253 10.253 0 0 1-3.549 1.511.755.755 0 0 0-.5.295.5.5 0 0 0-.062.382.54.54 0 0 0 .241.359.718.718 0 0 0 .565.046c.393-.1.789-.224 1.172-.339l.483-.144a3.671 3.671 0 0 1-.486.346 14.923 14.923 0 0 1-6.6 1.917 25.844 25.844 0 0 1-5.63-.106 15.163 15.163 0 0 1-4.862-1.377 7.114 7.114 0 0 1-1.676-1.1h.018l.784.2c.795.206 1.616.419 2.429.612a.659.659 0 0 0 .53-.07.55.55 0 0 0 .232-.378.512.512 0 0 0-.087-.393.731.731 0 0 0-.5-.261 15.054 15.054 0 0 1-4.827-1.58.64.64 0 0 1-.27-.235 2.545 2.545 0 0 1 .463-3.243.565.565 0 0 0 .067-.717.54.54 0 0 0-.722-.165 1.606 1.606 0 0 0-.291.215A2.888 2.888 0 0 0 0 12.521a5.6 5.6 0 0 0 1.513 3.772 10.034 10.034 0 0 0 4.574 2.791 20.9 20.9 0 0 0 6.067.956q.492.014.964.014a22.018 22.018 0 0 0 6.49-.869 10.468 10.468 0 0 0 4.578-2.569A5.745 5.745 0 0 0 26 12.582a2.879 2.879 0 0 0-1.156-2.455Z" }),
            React.createElement("path", { "data-name": "Path 13483", d: "M16.285 16.988a10.8 10.8 0 0 1-4.567-.047.567.567 0 0 0-.727.731.578.578 0 0 0 .5.361c.864.134 2.567.286 2.606.286h.11c1.053 0 2.341-.1 2.745-.537a.467.467 0 0 0-.071-.707.744.744 0 0 0-.596-.087Z" }),
            React.createElement("path", { "data-name": "Path 13484", d: "M14.72 8.564h-1.993a1.508 1.508 0 0 0 .084-.523V7.69h1.228v-.954h-1.228V6.17a.567.567 0 0 1 .1-.38.469.469 0 0 1 .334-.1 1.651 1.651 0 0 1 .734.193l.27.135.422-.92-.255-.126a2.635 2.635 0 0 0-1.177-.275 1.538 1.538 0 0 0-1.076.375 1.336 1.336 0 0 0-.421 1.032v.627h-.576v.954h.576v.357c0 .421-.187.52-.37.562l-.227.052v.935h3.579Z" }))));
};
