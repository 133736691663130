import {
  BinaryQuestion as BinaryQuestionType,
  ListQuestion as ListQuestionType,
  ListQuestionAll as ListQuestionAllType,
} from '../../config/SoftConfigPortfolioSelection'

export type Question = BinaryQuestionType | ListQuestionType | ListQuestionAllType

/**
 * QuestionBlock
 * question - question to explore
 * condition - function that describes the state that `values` should be in for `question` to render
 */
interface QuestionBlock {
  question: Question
  condition: (values: { [key: string]: string }) => boolean
}

/**
 * exploreQuestion completes a recursive DFS of the question tree starting at `question`
 * and returns an ordered array of QuestionBlocks to render
 * @param question question to start DFS
 * @param condition condition function used to decide whether to render the current question block
 * @param questions all questions
 * @returns
 */
export const exploreQuestion = (
  question: Question,
  condition: (values: { [key: string]: string }) => boolean,
  questions: Question[]
): QuestionBlock[] => {
  let questionBlocks: QuestionBlock[] = []
  const currentQuestion: QuestionBlock = {
    question,
    condition,
  }
  questionBlocks.push(currentQuestion)

  if (question.type === 'binary') {
    question.options.forEach((o) => {
      if (o.type === 'next') {
        const nextQuestion = questions.find((q) => q.id === o.nextQuestionId)
        if (!nextQuestion) {
          throw new Error(`configuration does not define question with id ${o.nextQuestionId}`)
        }
        questionBlocks = questionBlocks.concat(
          exploreQuestion(
            nextQuestion,
            (values) => condition(values) && values[currentQuestion.question.id] === o.nextQuestionId,
            questions
          )
        )
      }
    })
  }

  return questionBlocks
}

export const questionsToQuestionBlocks = (questions: Question[]): QuestionBlock[] => {
  return exploreQuestion(questions[0], () => true, questions)
}
