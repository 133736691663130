import * as yup from 'yup'

// Type of General Meeting as exposed by the public API
export interface GeneralMeeting {
  date: Date
  generalMeetingType: GeneralMeetingType
  proxyNoticeUrl?: string
}

enum GeneralMeetingType {
  annual = 'annual',
  extraordinary = 'extraordinary',
}

export const generalMeetingSchema = yup
  .object({
    date: yup.date().required(),
    generalMeetingType: yup
      .mixed<keyof typeof GeneralMeetingType>()
      .oneOf(Object.values(GeneralMeetingType))
      .required(),
    proxyNoticeUrl: yup.string(),
    organizationId: yup.string().required(),
  })
  .required()

type GeneralMeetingYup = yup.InferType<typeof generalMeetingSchema>

export const mapYupGeneralMeetingToAPI = (gm: GeneralMeetingYup): GeneralMeeting => {
  const { date, generalMeetingType, proxyNoticeUrl } = gm
  return {
    // TODO Hopefully we can remove
    date: date as Date,
    proxyNoticeUrl,
    generalMeetingType: GeneralMeetingType[generalMeetingType],
  }
}
