import React from 'react';
import styled from 'styled-components';
import { VisuallyHidden } from '../VisuallyHidden';
import { RadioGroupNameContext } from './RadioButtonGroup';
export const RadioButtonItem = ({ value, textHidden = false, checked, disabled, children, onChange, }) => {
    return (React.createElement(RadioGroupNameContext.Consumer, null, (groupName) => (React.createElement(StyledLabel, { disabled: disabled },
        React.createElement(StyledHTMLRadioButton, { type: "radio", name: groupName, value: value, defaultChecked: checked, disabled: disabled, onChange: onChange }),
        React.createElement(StyledRadioButton, null),
        textHidden && React.createElement(VisuallyHidden, null, value),
        children))));
};
const StyledHTMLRadioButton = styled.input `
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;
  z-index: 1;
  &:checked ~ span:after {
    display: block;
  }
  &:checked ~ span {
    border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  }
  &:disabled:hover {
    cursor: default;
  }
  &:disabled ~ span {
    border: 1px solid ${({ theme }) => theme.colors.grey.medium};
    background: ${({ theme }) => theme.colors.grey.light};
  }
  &:disabled:hover ~ span {
    border: 1px solid ${({ theme }) => theme.colors.grey.medium};
    background: ${({ theme }) => theme.colors.grey.light};
  }
  &:focus-visible ~ span {
    outline: 2px solid ${({ theme }) => theme.colors.primary.medium};
    outline-offset: 3px;
  }
  &:disabled ~ * {
    color: ${({ theme }) => theme.colors.grey.light};
    cursor: default;
  }
`;
const StyledRadioButton = styled.span `
  margin-right: ${({ withoutLabel }) => {
    return withoutLabel ? '0' : '1.6rem';
}};
  height: 2.4rem;
  width: 2.4rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.medium};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    display: none;
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary.dark};
  }
`;
const StyledLabel = styled.label `
  position: relative;
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  user-select: none;
  padding: 0.2rem;
  border-radius: 0.8rem;
  margin-block-end: 4px;
  &:hover > span {
    border: 1px solid ${({ theme }) => theme.colors.grey.dark};
    &:after {
      background: ${({ theme }) => theme.colors.primary.light};
    }
  }
`;
