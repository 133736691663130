import React, { useState } from 'react'
import { IndustriesFilterMenu as SharedIndustriesFilterMenu } from '@tumelo/shared'
import { OrganizationIndustries } from '../../../../application/types/SearchFilters'

interface IndustriesFilterMenuProps {
  industries: OrganizationIndustries
  callback: (industry: keyof OrganizationIndustries) => void
}

export const IndustriesFilterMenu: React.FC<IndustriesFilterMenuProps> = ({ callback, industries }) => {
  const [organizationIndustriesToggle, setOrganizationIndustriesToggle] = useState(false)

  return (
    <SharedIndustriesFilterMenu
      callback={callback}
      industries={industries}
      organizationIndustriesToggle={organizationIndustriesToggle}
      handleOrganizationIndustriesToggle={() => setOrganizationIndustriesToggle(!organizationIndustriesToggle)}
    />
  )
}
