import * as commonmark from 'commonmark';
import DOMPurify from 'dompurify';
// Provides an HTML renderer for markdown conforming to the [CommonMark](https://spec.commonmark.org) specification
// This implementation uses the reference JavaScript implementation of the CommonMark parser
// For full details see the [CommonMark GitHub](https://github.com/commonmark/commonmark.js) pages.
export class CommonMarkRenderer {
    constructor(window = undefined, // this allows us to inject a window to test the sanitiser
    parserOptions = {}, rendererOptions = {}) {
        this.parser = new commonmark.Parser(parserOptions);
        this.renderer = new commonmark.HtmlRenderer(rendererOptions);
        this.sanitiser = DOMPurify(window);
    }
    // Returns an HTML string representation of the markdown.
    // The HTML returned is sanitized against XSS attacks using [DOMPurify](https://github.com/cure53/DOMPurify)
    // and is safe to use as the HTML content of `dangerouslySetInnerHTML` properties.
    renderHtml(markdown) {
        const ast = this.parser.parse(markdown);
        const html = this.renderer.render(ast);
        // full list of tags allowed https://github.com/cure53/DOMPurify/blob/main/src/tags.js
        // we remove some extra ones we don't want here
        return this.sanitiser.sanitize(html, {
            FORBID_TAGS: [
                'style',
                'img',
                'form',
                'field',
                'input',
                'button',
                'select',
                'textarea',
                'canvas',
                'audio',
                'video',
                'source',
                'picture',
            ],
            USE_PROFILES: { html: true },
        });
    }
}
