import {
  signIn,
  signInWithRedirect,
  signUp,
  signOut,
  confirmSignIn,
  confirmSignUp,
  resetPassword,
  confirmResetPassword,
  resendSignUpCode,
  fetchUserAttributes,
} from 'aws-amplify/auth'
import { Auth as AuthService, AuthAttributes, Provider } from './Auth'

export const AuthCognito: AuthService = {
  signIn: async (username: string, password: string) => {
    const resp = await signIn({ username, password })

    if (resp.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
      return { challenge: 'CONFIRM_SIGN_UP' }
    }
    const { phone_number: phoneNumber } = await fetchUserAttributes()
    return {
      challenge: resp.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE' ? 'SMS_MFA' : undefined,
      challengePhoneNumber: phoneNumber,
    }
  },

  signUp: async (attributes: AuthAttributes) => {
    const { userId } = await signUp({
      username: attributes.username,
      password: attributes.password,
      options: {
        userAttributes: attributes.attributes,
      },
    })
    if (!userId) {
      throw new Error('User ID not returned from amplify signUp')
    }
    return { userSub: userId }
  },

  signOut: async (global: boolean) => {
    await signOut({ global })
  },

  confirmSignIn: async (code: string) => {
    await confirmSignIn({ challengeResponse: code })
  },

  confirmSignUp: async (email: string, code: string) => {
    await confirmSignUp({ username: email, confirmationCode: code })
  },

  resendSignUpCode: async (email: string) => {
    await resendSignUpCode({ username: email })
  },

  forgotPassword: async (email: string) => {
    await resetPassword({ username: email })
  },

  forgotPasswordSubmit: async (email: string, code: string, password: string) => {
    await confirmResetPassword({ username: email, confirmationCode: code, newPassword: password })
  },

  federatedSignIn: async (provider: Provider) => {
    await signInWithRedirect({ provider: { custom: provider } })
  },
}
