import { dateToTimestamp } from '@tumelo/shared'
import { AppThunk } from '../../store'
import * as voteResultsActions from './index'

export const fetchVoteResultsPage =
  (nextPageToken?: string): AppThunk =>
  async (dispatch, getState, { services }) => {
    // Check we're not waiting for a previous request to complete - avoids fetching same page twice
    if (getState().voteResults.pageState === 'pending') {
      return
    }
    dispatch(voteResultsActions.setVoteResultsPending())
    try {
      const { voteResults, nextPageToken: pageToken } = await services.dashboardBffService.FetchVoteResults(
        nextPageToken ?? ''
      )
      const minCloseTime = voteResults
        .map(({ pollCloseTime }) => pollCloseTime)
        .filter((pollCloseTime): pollCloseTime is Date => pollCloseTime !== undefined)
        .reduce((prev, curr) => (prev > curr ? curr : prev), new Date())
      dispatch(
        voteResultsActions.addVoteResultsPage({
          nextPageToken: pageToken,
          minCloseTime: dateToTimestamp(minCloseTime),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          voteResults: voteResults.map(({ pollCloseTime, ...rest }) => ({ ...rest })),
        })
      )
    } catch (err) {
      services.loggerService.logError(err)
      dispatch(voteResultsActions.setVoteResultsError())
    }
  }
