import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgRetailsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M17.16 8C17.71 8 18.16 7.55 18.16 7C18.16 6.45 17.71 6 17.16 6C16.61 6 16.16 6.45 16.16 7C16.16 7.55 16.61 8 17.16 8ZM21.17 2H11.69C11.42 2 11.17 2.11 10.98 2.29L2.84 10.44C1.72 11.57 1.72 13.4 2.84 14.52L9.63 21.32C10.19 21.89 10.94 22.17 11.68 22.17C12.42 22.17 13.16 21.89 13.73 21.32L21.87 13.19C22.06 13 22.16 12.75 22.16 12.48V3C22.16 2.45 21.71 2 21.16 2H21.17ZM20.17 12.06L12.32 19.9C11.97 20.25 11.4 20.25 11.05 19.9L4.26 13.11C3.91 12.76 3.91 12.2 4.26 11.85L12.11 4H20.17V12.06ZM10.28 8.83C9.65 9.15 9.13 9.66 8.8 10.29C8.36 11.19 8.47 12.27 9.1 13.05L9.61 13.61L9.77 13.79L9.53 14.03C9.23 14.33 9.22 14.82 9.52 15.12C9.52 15.12 9.52 15.12 9.53 15.13C9.82 15.43 10.3 15.44 10.6 15.15C10.6 15.15 10.61 15.14 10.62 15.13L10.86 14.9L11.09 15.11C11.21 15.39 11.26 15.69 11.23 15.99C11.18 16.33 11.34 16.66 11.64 16.82C11.75 16.87 11.87 16.9 11.99 16.9C12.2 16.9 12.39 16.82 12.54 16.67L15.94 13.24C16.24 12.93 16.24 12.44 15.94 12.13C15.65 11.82 15.16 11.8 14.84 12.09L12.51 14.45C12.42 14.26 12.3 14.08 12.15 13.93C12.07 13.87 12 13.8 11.93 13.73L13.04 12.61C13.34 12.3 13.34 11.81 13.04 11.5C12.74 11.2 12.25 11.2 11.95 11.5L10.84 12.62L10.74 12.51L10.27 11.99C10.05 11.7 10.01 11.32 10.15 10.99C10.33 10.65 10.61 10.37 10.95 10.2C11.25 10.08 11.59 10.15 11.82 10.38C12.11 10.68 12.59 10.69 12.89 10.4C12.89 10.4 12.9 10.39 12.91 10.38C13.21 10.07 13.21 9.58 12.91 9.27C12.21 8.59 11.16 8.41 10.28 8.83Z" })));
};
