import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VotingPolicy, VotingPolicySelection } from '@tumelo/shared'
import { Payload } from '../../payload'
import { VotingRecommendation } from './types'

interface State {
  habitatVotingPolicies: Payload<VotingPolicy[]>
  votingPolicySelection: Payload<VotingPolicySelection | undefined>
  updateVotingPolicySelection: Payload<'not-updating'>
  isVotingPolicyModalOpen: boolean
  votingPolicyRecommendation: Payload<VotingRecommendation | 'not-configured'>
}

const initialState: State = {
  habitatVotingPolicies: 'not-initialised',
  votingPolicySelection: 'not-initialised',
  updateVotingPolicySelection: 'not-updating',
  isVotingPolicyModalOpen: false,
  votingPolicyRecommendation: 'not-initialised',
}

const votingPolicySlice = createSlice({
  name: 'votingPolicySlice',
  initialState,
  reducers: {
    setVotingPolicySelection: (state, action: PayloadAction<VotingPolicySelection | undefined>) => {
      state.votingPolicySelection = action.payload
    },
    deleteVotingPolicySelection: (state) => {
      state.votingPolicySelection = undefined
    },
    setVotingPolicySelectionError: (state) => {
      state.votingPolicySelection = 'error'
    },
    setVotingPolicySelectionPending: (state) => {
      state.votingPolicySelection = 'pending'
    },
    setHabitatVotingPoliciesPending: (state) => {
      state.habitatVotingPolicies = 'pending'
    },
    setHabitatVotingPoliciesError: (state) => {
      state.habitatVotingPolicies = 'error'
    },
    setHabitatVotingPolicies: (state, action: PayloadAction<{ votingPolicies: VotingPolicy[] }>) => {
      state.habitatVotingPolicies = action.payload.votingPolicies
    },
    setVotingPolicyModalOpen: (state) => {
      state.isVotingPolicyModalOpen = true
    },
    setVotingPolicyModalClosed: (state) => {
      state.isVotingPolicyModalOpen = false
    },
    setVotingPolicyRecommendation: (state, action: PayloadAction<Payload<VotingRecommendation | 'not-configured'>>) => {
      state.votingPolicyRecommendation = action.payload
    },
    setVotingPolicyRecommendationPending: (state) => {
      state.votingPolicyRecommendation = 'pending'
    },
    setVotingPolicyRecommendationError: (state) => {
      state.votingPolicyRecommendation = 'error'
    },
    setVotingPolicySelectionNotUpdating: (state) => {
      state.updateVotingPolicySelection = 'not-updating'
    },
    setUpdateVotingPolicySelectionPending: (state) => {
      state.updateVotingPolicySelection = 'pending'
    },
    setUpdateVotingPolicySelectionError: (state) => {
      state.updateVotingPolicySelection = 'error'
    },
  },
})

export const {
  setVotingPolicySelection,
  deleteVotingPolicySelection,
  setVotingPolicySelectionError,
  setVotingPolicySelectionPending,
  setHabitatVotingPoliciesPending,
  setHabitatVotingPolicies,
  setHabitatVotingPoliciesError,
  setVotingPolicyModalOpen,
  setVotingPolicyModalClosed,
  setVotingPolicyRecommendation,
  setVotingPolicyRecommendationPending,
  setVotingPolicyRecommendationError,
  setVotingPolicySelectionNotUpdating,
  setUpdateVotingPolicySelectionPending,
  setUpdateVotingPolicySelectionError,
} = votingPolicySlice.actions

export default votingPolicySlice.reducer
