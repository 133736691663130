import React from 'react';
import { DashboardOutlined, HowToVoteOutlined, PieChartOutlined, HelpOutline } from '@mui/icons-material';
import { IdeaIcon } from '@tumelo/designsystem';
export const MaterialIconNames = ['dashboard', 'vote', 'pie-chart', 'help', 'idea'];
export const MenuItemIconSwitch = ({ icon, style }) => {
    switch (icon) {
        case 'dashboard':
            return React.createElement(DashboardOutlined, { style: style });
        case 'vote':
            return React.createElement(HowToVoteOutlined, { style: style });
        case 'pie-chart':
            return React.createElement(PieChartOutlined, { style: style });
        case 'help':
            return React.createElement(HelpOutline, { style: style });
        case 'idea':
            return React.createElement(IdeaIcon, { style: style });
        default:
            return null;
    }
};
