import React, { Suspense } from 'react'
import { Loader, BasePageProps, Content } from '@tumelo/shared'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { changeTitle } from './changeTitle'

const IndustryGroupingPageLazy = React.lazy(() => import('./IndustryGroupingPageLazy'))

type Props = BasePageProps

const IndustryGroupingPage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)
  return (
    <ErrorBoundary>
      <Content>
        <Suspense fallback={<Loader />}>
          <IndustryGroupingPageLazy />
        </Suspense>
      </Content>
    </ErrorBoundary>
  )
}

export default IndustryGroupingPage
