import {
  dateToTimestamp,
  GeneralMeetingType,
  GM,
  ISOCountryCode2,
  Organization,
  OrganizationBio,
  OrganizationBioSource,
  OrganizationBioSourceOther,
  OrganizationHeadquarters,
  stringToNAICSCode,
  naicsCodeToIndustryCategory,
} from '@tumelo/shared'
import { APIServiceBaseClass } from '../../../utils/api'
import {
  Organization as OrganizationApi,
  OrganizationAddress,
  OrganizationBio as OrganizationBioApi,
  OrganizationBioSourceEnum as OrganizationBioSourceEnumApi,
} from '../../../utils/api/gen'
import { OrganizationService } from './OrganizationService'

export class OrganizationServiceAPI extends APIServiceBaseClass implements OrganizationService {
  readonly chunkSize = 30

  async batchGetOrganizations(ids: string[]): Promise<Organization[]> {
    if (ids.length < 1) {
      return []
    }
    const api = await this.getOrganizationsApi()
    const promises = chunks(ids, this.chunkSize).map((chunk) => api.listOrganizations({ ids: chunk }))
    const results = await Promise.all(promises)
    const organizations = results
      .map((result) => result.organizations)
      .reduce((acc, val) => acc.concat(val), [])
      .map(OrganizationServiceAPI.organizationApiToOrganization)
    const organizationsMap = organizations.reduce((m, o) => m.set(o.id, o), new Map<string, Organization>())
    return ids.map((identifier) => {
      const organization = organizationsMap.get(identifier)
      if (!organization) {
        throw new Error(`could not find organization with identifier ${identifier}`)
      }
      return organization
    })
  }

  async listGeneralMeetings(organizationId: string): Promise<GM[]> {
    const api = await this.getOrganizationsApi()
    const result = await api.listGeneralMeetings({ organizationId })

    return result.generalMeetings.map((gm) => ({
      date: dateToTimestamp(gm.date),
      type: GeneralMeetingType[gm.generalMeetingType],
      organizationId,
    }))
  }

  static organizationApiToOrganization({
    id,
    bio,
    legalName,
    displayName,
    logoUrl,
    websiteUrl,
    headquarters,
    industry,
  }: OrganizationApi): Organization {
    const naicsCode = stringToNAICSCode(industry?.naics2017?.code)
    const industryCategory = naicsCodeToIndustryCategory(naicsCode)
    return {
      id,
      title: displayName !== undefined ? displayName : legalName,
      bio: OrganizationServiceAPI.organizationBioApiToOrganizationBio(bio),
      websiteUrl,
      logoUrl,
      headquarters: OrganizationServiceAPI.organizationAddressApiToOrganizationHQ(headquarters),
      naicsCode,
      industryCategory,
    }
  }

  static organizationBioApiToOrganizationBio(bio: OrganizationBioApi | undefined): OrganizationBio | undefined {
    if (bio === undefined) {
      return undefined
    }
    const { source, sourceUrl, description } = bio
    return {
      source: OrganizationServiceAPI.organizationBioSourceApiToOrganizationBioSource(source),
      sourceUrl,
      description,
    }
  }

  static organizationAddressApiToOrganizationHQ(
    address: OrganizationAddress | undefined
  ): OrganizationHeadquarters | undefined {
    if (address === undefined) {
      return undefined
    }

    return {
      isoCountryCode2: address.isoCountryCode2 as ISOCountryCode2,
    }
  }

  static organizationBioSourceApiToOrganizationBioSource(source: OrganizationBioSourceEnumApi): OrganizationBioSource {
    const s = source as OrganizationBioSourceEnumApi | string
    switch (s) {
      case OrganizationBioSourceEnumApi.Tumelo:
        return 'Tumelo'
      case OrganizationBioSourceEnumApi.Wikipedia:
        return 'Wikipedia'
      default:
        return s as OrganizationBioSourceOther
    }
  }
}

function chunks<T>(array: T[], chunkLength: number): T[][] {
  const outs: T[][] = []
  let i = 0
  const n = array.length
  while (i < n) {
    outs.push(array.slice(i, (i += chunkLength)))
  }
  return outs
}
