import React from 'react';
import styled from 'styled-components';
import { Stack } from '../Stack';
import { screen } from '../_utils';
import { ProgressLabel } from '../ProgressBar/ProgressLabel';
import { StepProgressBar } from '../ProgressBar/StepProgessBar';
export const PageHeader = ({ logoPath = 'https://res.cloudinary.com/tumelo/image/upload/v1700485254/o8z03g1iloivgctvkqbu.png', logoTitle = 'Tumelo', variant = 'default', progressLabel = false, currentStep, totalSteps, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledHeaderStack, { flexDirection: "column", justifyContent: "flex-start" },
            React.createElement(Stack, { flexDirection: "row", justifyContent: "flex-start", alignItems: "center", columnGap: "4.0rem" },
                React.createElement("img", { src: logoPath, crossOrigin: "", height: 22, alt: logoTitle }),
                variant === 'progress' && currentStep && totalSteps && progressLabel && (React.createElement(ProgressLabel, { currentStep: currentStep, totalSteps: totalSteps })))),
        variant === 'progress' && currentStep && totalSteps && (React.createElement(StepProgressBar, { currentStep: currentStep, totalSteps: totalSteps }))));
};
const StyledHeaderStack = styled(Stack) `
  background-color: ${({ theme }) => theme.colors.white};
  padding: 1.6rem;
  width: 100%;
  @media ${screen.smallUp} {
    padding: 3.2rem 2.4rem 1.6rem;
  }
  @media ${screen.mediumUp} {
    padding: 3.2rem 3.2rem 1.6rem;
  }
  @media ${screen.largeUp} {
    padding: 3.2rem 5.6rem 1.6rem;
  }
`;
