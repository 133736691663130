import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgCount2Fill = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 1C5.92 1 1 5.92 1 12C1 18.08 5.92 23 12 23C18.08 23 23 18.08 23 12C23 5.92 18.08 1 12 1ZM15.87 17V15.15H11.66L13.91 13.02C14.55 12.42 14.99 11.89 15.21 11.42C15.43 10.95 15.55 10.45 15.55 9.92C15.55 9.33 15.4 8.82 15.09 8.39C14.79 7.95 14.37 7.62 13.82 7.38C13.28 7.14 12.64 7.02 11.92 7.02C11.05 7.02 10.28 7.18 9.61 7.51C8.94 7.83 8.41 8.27 8.03 8.84L9.68 9.9C9.91 9.57 10.2 9.33 10.55 9.17C10.9 9 11.28 8.92 11.71 8.92C12.22 8.92 12.61 9.03 12.87 9.24C13.14 9.45 13.28 9.74 13.28 10.14C13.28 10.41 13.21 10.69 13.07 10.97C12.93 11.24 12.66 11.57 12.26 11.95L8.48 15.52V16.99H15.89L15.87 17Z" })));
};
