import { ModelPortfolioId } from '@tumelo/shared'
import { createAccount, updateAccountModelPortfolio } from '../../application/asyncActions'
import { useDashboardAccount } from '../../application/features/dashboardUserAccount'
import { isValueState, Payload } from '../../application/payload'
import { useAppDispatch } from '../../application/store'
import { useNavigateWithQuery } from '../../utils/useNavigateWithQuery'

export const useOnSelfSelect = (): Payload<(id: ModelPortfolioId) => void> => {
  const dispatch = useAppDispatch()
  const navigate = useNavigateWithQuery()
  const dashboardAccount = useDashboardAccount()

  if (isValueState(dashboardAccount)) {
    const { account } = dashboardAccount

    if (account === 'not-configured') {
      return (id: ModelPortfolioId) => {
        dispatch(createAccount(id))
        navigate('/')
      }
    }
    return (id: ModelPortfolioId) => {
      dispatch(updateAccountModelPortfolio(id))
      navigate('/profile')
    }
  }

  return dashboardAccount
}
