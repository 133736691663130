import React, { FC } from 'react'
import { BackButton as BackButtonUI } from '@tumelo/shared'
import { useRouter } from '../../application/router/useRouter'

interface Props {
  /**
   * Optional onclick defaults to calling useHistory().goBack()
   */
  onClick?: () => void
  className?: string
}

export const BackButton: FC<Props> = ({ onClick, className }) => {
  const { back } = useRouter()
  const clickHandler = onClick || back

  return <BackButtonUI onClick={clickHandler} className={className} />
}
