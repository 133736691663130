import { Breakpoints } from '../_constants';
import { useWindowWidth } from './useWindowWidth';
export const useBreakpoints = () => {
    const currentWindowSize = useWindowWidth();
    /*
      usage:
      const { isSmallUp } = useBreakpoints()
    */
    const breakpoints = {
        isXsmallOnly: currentWindowSize < Breakpoints.Small,
        isSmallUp: Breakpoints.Small <= currentWindowSize && currentWindowSize < Breakpoints.Medium,
        isMediumUp: Breakpoints.Medium <= currentWindowSize && currentWindowSize < Breakpoints.Large,
        isLargeUp: Breakpoints.Large <= currentWindowSize,
        active: 'sm',
    };
    /*
      sets active breakpoint to be used in styled components responsive props
    */
    if (breakpoints.isXsmallOnly)
        breakpoints.active = 'xs';
    if (breakpoints.isSmallUp)
        breakpoints.active = 'sm';
    if (breakpoints.isMediumUp)
        breakpoints.active = 'md';
    if (breakpoints.isLargeUp)
        breakpoints.active = 'lg';
    return breakpoints;
};
