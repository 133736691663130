/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { Breakpoint, SubHeading, Body, Poll, ResourceLink, Markdown } from '@tumelo/shared'
import Placeholder from './Placeholder'

interface Props {
  poll: Poll
  isReadMoreOpen: boolean
}

export const Question: React.FC<Props> = ({ poll, isReadMoreOpen }) => {
  return (
    <AlignContent>
      <Title>{poll.title}</Title>
      {!isReadMoreOpen && <Placeholder />}
      <HiddenDescription isOpen={isReadMoreOpen}>
        <Body>
          <Markdown markdown={poll.formattedDescription} />
        </Body>
        {poll.relationships.generalMeeting.proxyNoticeUrl && (
          <ResourceLink
            linkText="Click here"
            resourceURL={poll.relationships.generalMeeting.proxyNoticeUrl}
            additionalText=" to see the formal company AGM document"
          />
        )}
      </HiddenDescription>
    </AlignContent>
  )
}

const SingleQuestionStyles = {
  MARGIN: 2,
  MAX_WIDTH: 900,
  CONTENT_MAX_WIDTH: 700,
  ICON_SIZE: 5,
}

const { MARGIN, ICON_SIZE } = SingleQuestionStyles

const AlignContent = styled.div`
  margin-left: ${MARGIN + ICON_SIZE}rem;
  width: 80%;

  @media (max-width: ${Breakpoint.mobile}) {
    margin: 0;
    width: 100%;
  }
`

const Title = styled(SubHeading)`
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 3rem;

  @media (min-width: ${Breakpoint.mobile}) {
    font-size: 3rem;
    line-height: 4.2rem;
  }
`

interface DisappearingTextProps {
  isOpen: boolean
}

const HiddenDescription = styled.div<DisappearingTextProps>`
  white-space: pre-wrap;
  position: relative;
  overflow: hidden;
  padding-bottom: ${(props) => (props.isOpen ? '2rem' : '0')};
  margin-bottom: ${(props) => (props.isOpen ? '4rem' : '0')};
  max-height: ${(props) => (props.isOpen ? '100%' : '0')};
`
