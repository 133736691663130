import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { timestampToDate, hexAlpha } from '@tumelo/shared'
import { Button, H3 } from '@tumelo/designsystem'
import { useBreakpoints } from '@tumelo/shared/utils'
import { useNotifications } from '../../application/features/notifications/hooks'
import { AppNotification } from '../../application/types/AppNotification/AppNotification'
import { NotificationCardlet } from './NotificationCardlet'

interface Props {
  notifications: AppNotification[]
  onClick: (notification: AppNotification) => void
}

export const NotificationsList: React.FC<Props> = ({ notifications, onClick }) => {
  const scrollContainer = useRef<HTMLDivElement>(null)
  const [isFadeVisible, setIsFadeVisible] = useState(false)

  const { fetchNextPage } = useNotifications()

  useEffect(() => {
    const isFadeVisible = scrollContainer.current
      ? scrollContainer.current.scrollHeight > scrollContainer.current.clientHeight
      : false
    setIsFadeVisible(isFadeVisible)
  }, [])
  const { isTabletOrSmaller } = useBreakpoints()

  const currentDatetime = new Date()

  const notificationsToday = notifications.filter((notification) => {
    const timeDifference = currentDatetime.getTime() - timestampToDate(notification.createTime).getTime()
    const seconds = Math.floor(timeDifference) / 1000
    const days = seconds / 86400
    return days < 1
  })
  const notificationsThisWeek = notifications.filter((notification) => {
    const timeDifference = currentDatetime.getTime() - timestampToDate(notification.createTime).getTime()
    const seconds = Math.floor(timeDifference) / 1000
    const weeks = seconds / 604800
    const days = seconds / 86400
    return weeks < 1 && days >= 1
  })
  const notificationsThisMonth = notifications.filter((notification) => {
    const timeDifference = currentDatetime.getTime() - timestampToDate(notification.createTime).getTime()
    const seconds = Math.floor(timeDifference) / 1000
    const weeks = seconds / 604800
    const currentMonth = currentDatetime.getMonth()
    const currentYear = currentDatetime.getFullYear()
    const notificationMonth = timestampToDate(notification.createTime).getMonth()
    const notificationYear = timestampToDate(notification.createTime).getFullYear()
    return notificationMonth === currentMonth && notificationYear === currentYear && weeks >= 1
  })
  const notificationsOlder = notifications.filter((notification) => {
    const currentMonth = currentDatetime.getMonth()
    const currentYear = currentDatetime.getFullYear()
    const notificationMonth = timestampToDate(notification.createTime).getMonth()
    const notificationYear = timestampToDate(notification.createTime).getFullYear()
    return !(notificationMonth === currentMonth && notificationYear === currentYear)
  })

  return (
    <ListContainer>
      {isFadeVisible && <TopOverlay />}
      <ScrollContainer ref={scrollContainer}>
        {notificationsToday.length > 0 && (
          <>
            <DateHeading isTabletOrSmaller={isTabletOrSmaller}>Today</DateHeading>
            <>
              {notificationsToday.map((notification) => (
                <NotificationCardlet key={notification.id} notification={notification} onClick={onClick} />
              ))}
            </>
          </>
        )}
        {notificationsThisWeek.length > 0 && (
          <>
            <DateHeading isTabletOrSmaller={isTabletOrSmaller}>This Week</DateHeading>
            <>
              {notificationsThisWeek.map((notification) => (
                <NotificationCardlet key={notification.id} notification={notification} onClick={onClick} />
              ))}
            </>
          </>
        )}
        {notificationsThisMonth.length > 0 && (
          <>
            <DateHeading isTabletOrSmaller={isTabletOrSmaller}>This Month</DateHeading>
            <>
              {notificationsThisMonth.map((notification) => (
                <NotificationCardlet key={notification.id} notification={notification} onClick={onClick} />
              ))}
            </>
          </>
        )}
        {notificationsOlder.length > 0 && (
          <>
            <DateHeading isTabletOrSmaller={isTabletOrSmaller}>Older</DateHeading>
            <>
              {notificationsOlder.map((notification) => (
                <NotificationCardlet key={notification.id} notification={notification} onClick={onClick} />
              ))}
            </>
          </>
        )}
        <ButtonSection isFadeVisible={isFadeVisible}>
          <Button onClick={fetchNextPage} isDisabled={!fetchNextPage} size="small" variant="tertiary">
            Load more
          </Button>
        </ButtonSection>
      </ScrollContainer>
      {isFadeVisible && <BottomOverlay />}
    </ListContainer>
  )
}

interface DateHeadingProps {
  isTabletOrSmaller: boolean
}

const DateHeading = styled(H3)<DateHeadingProps>`
  ${({ isTabletOrSmaller }) => (isTabletOrSmaller ? `margin-top: 1.6rem` : `margin-top: 2.4rem`)};
  ${({ isTabletOrSmaller }) => (isTabletOrSmaller ? `margin-right: 2.2rem` : `margin-right: 4rem`)};
  ${({ isTabletOrSmaller }) => (isTabletOrSmaller ? `margin-left: 2.5rem` : `margin-left: 4rem`)};
`
const ScrollContainer = styled.div`
  max-height: 34rem;
  overflow-y: auto;
  margin-right: 1.1rem;

  ::-webkit-scrollbar {
    width: 0.8rem;
    height: 1.3rem;
  }
  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.grey.medium};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.grey.dark};
  }
  // styling for firefox:
  scrollbar-color: ${({ theme }) => theme.colors.grey.dark} ${({ theme }) => theme.colors.grey.medium};
  scrollbar-width: thin;
`
const ListContainer = styled.div`
  position: relative;
`
const BottomOverlay = styled.div`
  position: absolute;
  height: 3rem;
  width: calc(100% - 2.2rem);
  bottom: 0;
  margin-bottom: -0.2rem;
  margin-left: -0.01rem;
  background: linear-gradient(
    ${({ theme }) => hexAlpha(theme.colors.white, 0)},
    ${({ theme }) => hexAlpha(theme.colors.white, 1)}
  );
`
const TopOverlay = styled.div`
  position: absolute;
  height: 3rem;
  width: calc(100% - 2.2rem);
  top: 0;
  margin-bottom: -0.2rem;
  margin-left: -0.01rem;
  background: linear-gradient(
    ${({ theme }) => hexAlpha(theme.colors.white, 1)},
    ${({ theme }) => hexAlpha(theme.colors.white, 0)}
  );
`

interface ButtonSectionProps {
  isFadeVisible: boolean
}
const ButtonSection = styled.div<ButtonSectionProps>`
  ${({ isFadeVisible }) => (isFadeVisible ? `margin-bottom: 2rem` : `margin-bottom: 0.5rem`)};
  display: flex;
  margin-top: 1rem;
  justify-content: center;
`
