import * as z from 'myzod'

const hasFundChangedText = 'Have you ever changed your pension fund*?'
const hasFundChangedAsterixText =
  '*If you have, you’ll know. You will have actively done it online, in person or over the phone. If you haven’t, please click no.'
const hasFundChangedQuestionMarkMarkdownText =
  'Tumelo’s platform lets you see the companies your pension is invested in and have your say on issues being discussed at those companies.' +
  '\n\n' +
  'It’s important that we show you the right companies that your pension money is invested in so that you can have your say. Otherwise, your voice won’t be heard' +
  '\n\n' +
  'To make sure you have this opportunity we need you to verify which pension fund you’re invested in.'
const hasFundChangedTopMarkdownText =
  'When you start saving towards your pension, your money is usually invested automatically into a ‘default’ fund.' +
  '\n\n' +
  'But sometimes people decide to change the fund their pension is invested in for ethical or financial reasons.'

const selfSelectFundHelpText = `## Ok, so you’ve changed where your pension is invested. No worries, tell us about the new fund.

If you’re not sure which fund(s) you are invested in through this pension, don’t worry. 

Find out by logging into your online pension account or by checking your last pension statement.`
const selfSelectFundHeaderMarkdown =
  'You may have chosen to invest your pension in multiple funds. If so, please only select one of those funds for now.' +
  '\n\n' +
  'Tumelo is currently being rolled out in stages and we can’t support multiple funds just yet.'
const selfSelectFundBoxHeaderText = 'Which of the following funds is your pension invested in?'
const confirmFundText =
  'Remember: if you’re not sure take a look at your online account or your last pension statement. Tip: If you need to change your fund later, head to your profile page.'

const confirmationModalTitle = 'Please confirm this is the correct fund.'
const confirmationModalText =
  'I confirm that my pension is invested in this fund. I will make sure that I inform Tumelo if this changes or is no longer correct.'
const confirmationSubheadingText = 'Your fund:'

const unsupportedFundTitleText = 'We’re sorry, but we can’t show you how your pension is invested.'
const unsupportedFundText = `We need you to verify your fund before showing you any of the details.\n\n

If you don’t know which fund your pension is invested in or can’t see it on the list, contact [support@tumelo.com](mailto:support@tumelo.com). We’ll do what we can to help.\n\n

We only show funds that are invested in companies. So if your pension is invested in other types of assets, like property or cash, it may not have appeared on the list.`

export const SelfSelectSchema = z
  .object(
    {
      type: z.literal('self-select'),

      hasFundChangedText: z.string().default(hasFundChangedText),
      hasFundChangedAsterixText: z.string().default(hasFundChangedAsterixText),
      hasFundChangedQuestionMarkMarkdownText: z.string().default(hasFundChangedQuestionMarkMarkdownText),
      hasFundChangedTopMarkdownText: z.string().default(hasFundChangedTopMarkdownText),

      selfSelectFundHelpText: z.string().default(selfSelectFundHelpText),
      selfSelectFundHeaderMarkdown: z.string().default(selfSelectFundHeaderMarkdown),
      selfSelectFundBoxHeaderText: z.string().default(selfSelectFundBoxHeaderText),

      confirmFundText: z.string().default(confirmFundText),
      confirmationModalTitle: z.string().default(confirmationModalTitle),
      confirmationModalText: z.string().default(confirmationModalText),
      confirmationSubheadingText: z.string().default(confirmationSubheadingText),

      unsupportedFundTitleText: z.string().default(unsupportedFundTitleText),
      unsupportedFundText: z.string().default(unsupportedFundText),
    },
    { allowUnknown: true }
  )
  .default({
    type: 'self-select',

    hasFundChangedText,
    hasFundChangedAsterixText,
    hasFundChangedQuestionMarkMarkdownText,
    hasFundChangedTopMarkdownText,

    selfSelectFundHelpText,
    selfSelectFundHeaderMarkdown,
    selfSelectFundBoxHeaderText,

    confirmFundText,
    confirmationModalTitle,
    confirmationModalText,
    confirmationSubheadingText,

    unsupportedFundTitleText,
    unsupportedFundText,
  })

export type SelfSelect = z.Infer<typeof SelfSelectSchema>

const OptionWithNext = z.object({
  type: z.literal('next'),
  optionText: z.string(),
  nextQuestionId: z.string(),
})

const OptionWithModelPortfolioId = z.object({
  type: z.literal('modelPortfolio'),
  optionText: z.string(),
  modelPortfolioId: z.string(),
})

const BinaryQuestionSchema = z.object({
  id: z.string(),
  type: z.literal('binary'),
  questionText: z.string(),
  options: z.array(z.union([OptionWithModelPortfolioId, OptionWithNext]), { length: 2 }),
})

export type BinaryQuestion = z.Infer<typeof BinaryQuestionSchema>

const ListQuestionSchema = z.object({
  id: z.string(),
  type: z.literal('list'),
  questionText: z.string(),
  modelPortfolioIds: z.array(z.string(), { min: 1 }),
})

export type ListQuestion = z.Infer<typeof ListQuestionSchema>

const ListQuestionAllSchema = z.object({
  id: z.string(),
  type: z.literal('list-all'),
  questionText: z.string(),
})

export type ListQuestionAll = z.Infer<typeof ListQuestionAllSchema>

const SelfSelectFormSchema = z.object({
  type: z.literal('self-select-form'),
  title: z.string(),
  subheading: z.string().optional(),
  unsupportedFundTitleText: z.string().default(unsupportedFundTitleText),
  unsupportedFundText: z.string().default(unsupportedFundText),
  questions: z.array(z.union([ListQuestionSchema, BinaryQuestionSchema, ListQuestionAllSchema]), { min: 1 }),
})

export type SelfSelectForm = z.Infer<typeof SelfSelectFormSchema>

export const PortfolioSelectionFlowSchema = z
  .union([SelfSelectSchema, z.object({ type: z.literal('buck') }), SelfSelectFormSchema])
  .default(SelfSelectSchema.parse())

export type PortfolioSelectionFlow = z.Infer<typeof PortfolioSelectionFlowSchema>
