/* eslint-disable @typescript-eslint/no-deprecated */
import React from 'react';
import styled from 'styled-components';
import { Toggle } from '../../buildingBlocks/Toggle';
import { Caption } from '../../styles';
export const ToggleSwitch = ({ caption, active, onClick, disabled, toggleName }) => (React.createElement(React.Fragment, null,
    React.createElement(ToggleSwitchContainer, null,
        React.createElement(Toggle, { disabled: disabled, active: active, onClick: () => onClick(), toggleName: toggleName }),
        React.createElement(ToggleCaption, { onClick: () => onClick() }, caption)),
    React.createElement(Border, null)));
const ToggleSwitchContainer = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  user-select: none;
`;
const ToggleCaption = styled(Caption) `
  cursor: pointer;
  margin-left: 2rem;
`;
const Border = styled.div `
  border-bottom: 0.2rem solid ${({ theme }) => theme.colors.grey.light};
  margin: 2rem 0;
`;
