import {
  Composition,
  Instrument,
  InstrumentReference,
  isCompositionEmpty,
  MifirCode,
  notEmpty,
  CompositionComponentInstrument,
  instrumentReferenceToString,
  InstrumentReferenceString,
} from '@tumelo/shared'
import { Instrument2 as APIInstrument } from '../../../utils/api/gen/models'
import { APIServiceBaseClass } from '../../../utils/api'
import { InstrumentService } from './InstrumentService'

export class InstrumentServiceAPI extends APIServiceBaseClass implements InstrumentService {
  async batchFetchFilteredSubscribedInstruments(
    instrumentReferences: Array<InstrumentReference>
  ): Promise<Map<InstrumentReferenceString, Instrument>> {
    if (instrumentReferences.length === 0) {
      return new Map<InstrumentReferenceString, Instrument>()
    }
    const habitatApi = await this.getHabitatsApi()
    const { habitatId } = this
    const maxBatchSize = 100
    const nInstrumentReferences = instrumentReferences.length
    const nBatches = Math.ceil(nInstrumentReferences / maxBatchSize)
    const batchedResponses = await Promise.all(
      Array.from(Array(nBatches).keys()).map((b) =>
        habitatApi.getFilteredSubscribedInstruments({
          habitatId,
          listHabitatInstrumentsRequest: {
            instruments: instrumentReferences.slice(b, Math.min(nInstrumentReferences - b, maxBatchSize)),
          },
        })
      )
    )
    const instrumentMap = new Map<InstrumentReferenceString, Instrument>(
      batchedResponses.flatMap((res) =>
        res.instruments.flatMap((instrument: APIInstrument) =>
          instrument.instrumentReferences.map((instrumentReference) => [
            instrumentReferenceToString(instrumentReference),
            InstrumentServiceAPI.mapAPIInstrumentToInstrument(instrument),
          ])
        )
      )
    )
    return instrumentMap
  }

  static mapAPIInstrumentToInstrument({
    instrumentReferences,
    isComposite,
    title,
    mifirCode,
  }: APIInstrument): Instrument {
    const isinReference = instrumentReferences.find((iR) => iR.idType === 'isin')
    const isin = isinReference && isinReference.idValue
    return {
      instrumentReferences,
      isComposite,
      title,
      isin,
      mifirCode: MifirCode[mifirCode.toUpperCase() as keyof typeof MifirCode],
    }
  }

  async createCompositionInstruments(composition: Composition): Promise<{
    composition: Composition
    instrumentMap: Map<InstrumentReferenceString, Instrument>
    missingInstrumentReferences: Array<InstrumentReferenceString>
  }> {
    if (isCompositionEmpty(composition)) {
      return {
        composition,
        instrumentMap: new Map<InstrumentReferenceString, Instrument>(),
        missingInstrumentReferences: new Array<InstrumentReferenceString>(),
      }
    }
    const instruments = composition.components.instruments.map((instrument: CompositionComponentInstrument) => {
      return {
        ...instrument,
        instrumentReference: instrument.instrumentReference,
      }
    })
    const instrumentReferences = instruments
      .map((instrument: CompositionComponentInstrument) => instrument?.instrumentReference)
      .filter(notEmpty)
    // fetch all instruments in the subscribed instrument set that intersect the set composition.instruments
    const filteredSubscribedInstrumentsMap = await this.batchFetchFilteredSubscribedInstruments(instrumentReferences)
    // retrieve the symmetric difference of the sets subscribedInstrument and composition.instruments
    const missingInstrumentReferences = await InstrumentServiceAPI.symmetricDifferenceSubscribedComposition(
      filteredSubscribedInstrumentsMap,
      instrumentReferences
    )

    const instrumentMap = new Map<InstrumentReferenceString, Instrument>(
      InstrumentServiceAPI.intersectSubscribedComposition(filteredSubscribedInstrumentsMap, instrumentReferences)
    )

    const updatedComposition = InstrumentServiceAPI.updateCompositionWithMissingReferences(
      composition,
      missingInstrumentReferences
    )
    return { composition: updatedComposition, instrumentMap, missingInstrumentReferences }
  }

  static symmetricDifferenceSubscribedComposition(
    instrumentMap: Map<InstrumentReferenceString, Instrument>,
    instrumentReferences: Array<InstrumentReference>
  ): Array<InstrumentReferenceString> {
    const instrumentMapKeys = Array.from(instrumentMap.keys())
    const missingInstrumentReferences = instrumentReferences.reduce(
      (acc: Array<InstrumentReferenceString>, instrumentReference) => {
        const iRMap = instrumentMapKeys.some((iR: InstrumentReferenceString) => {
          return iR === instrumentReferenceToString(instrumentReference)
        })
        if (!iRMap) {
          acc.push(instrumentReferenceToString(instrumentReference))
        }
        return acc
      },
      new Array<InstrumentReferenceString>()
    )

    return missingInstrumentReferences
  }

  static intersectSubscribedComposition(
    filteredSubscribedInstrumentsMap: Map<InstrumentReferenceString, Instrument>,
    instrumentReferences: Array<InstrumentReference>
  ): Map<InstrumentReferenceString, Instrument> {
    const intersection = new Map<InstrumentReferenceString, Instrument>(
      Array.from(filteredSubscribedInstrumentsMap).reduce(
        (acc: Map<InstrumentReferenceString, Instrument>, [instrumentReference, instrument]) => {
          const iRMap = instrumentReferences.some((iR: InstrumentReference) => {
            return instrumentReferenceToString(iR) === instrumentReference
          })
          if (iRMap) {
            return acc.set(instrumentReference, instrument)
          }
          return acc
        },
        new Map<InstrumentReferenceString, Instrument>()
      )
    )
    return intersection
  }

  static updateCompositionWithMissingReferences(
    composition: Composition,
    missingInstrumentReferences: Array<InstrumentReferenceString>
  ): Composition {
    const updatedComposition: Composition = composition
    let otherAddition = 0
    const updatedInstruments = composition.components.instruments.filter(
      (instrument: CompositionComponentInstrument) => {
        if (instrument.instrumentReference !== undefined) {
          const irMap = missingInstrumentReferences.some((iR: InstrumentReferenceString) => {
            if (instrument.instrumentReference !== undefined) {
              if (iR === instrumentReferenceToString(instrument.instrumentReference)) {
                otherAddition += instrument.weight
                return true
              }
            }
            return false
          })
          return !irMap
        }
        otherAddition += instrument.weight
        return false
      }
    )
    updatedComposition.components.instruments = updatedInstruments
    updatedComposition.components.others += otherAddition
    return updatedComposition
  }
}
