import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isValueState, Payload } from '../../payload'
import { AppNotification, ResolutionType } from '../../types/AppNotification/AppNotification'

interface State {
  notificationsAndNextPageToken: Payload<{ notifications: AppNotification[]; nextPageToken: string }>
}

const initialState: State = {
  notificationsAndNextPageToken: 'not-initialised',
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotifications: (state, action: PayloadAction<{ notifications: AppNotification[]; nextPageToken: string }>) => {
      if (isValueState(state.notificationsAndNextPageToken)) {
        const { notifications, nextPageToken } = action.payload
        state.notificationsAndNextPageToken.notifications.push(...notifications)
        state.notificationsAndNextPageToken.nextPageToken = nextPageToken
      } else {
        state.notificationsAndNextPageToken = action.payload
      }
    },
    setNotificationsAndNextPageTokenError: (state) => {
      state.notificationsAndNextPageToken = 'error'
    },
    resolveNotification: (state, action: PayloadAction<{ id: string; resolution: ResolutionType }>) => {
      if (isValueState(state.notificationsAndNextPageToken)) {
        const indexOf = state.notificationsAndNextPageToken.notifications.findIndex(
          (obj) => obj.id === action.payload.id
        )
        if (indexOf >= 0) {
          state.notificationsAndNextPageToken.notifications[indexOf].resolution = action.payload.resolution
        }
      }
    },
  },
})

export default notificationsSlice.reducer

export const { addNotifications, setNotificationsAndNextPageTokenError, resolveNotification } =
  notificationsSlice.actions
