import { createSelector } from '@reduxjs/toolkit'
import { ModelPortfolio, Timestamp, TumeloAccount } from '@tumelo/shared'
import { Selector } from '../../../utils/redux'
import { Payload } from '../../payload'
import { RootState } from '../../rootReducer'

const selectAccountSlice = (state: RootState) => state.account

export const selectAvailableModelPortfolios: Selector<Payload<ModelPortfolio[]>> = createSelector(
  selectAccountSlice,
  (s) => s.availableModelPortfolios
)

export const selectAccount: Selector<Payload<TumeloAccount | 'not-configured'>> = createSelector(
  selectAccountSlice,
  (s) => s.account
)

export const selectLatestTriggerFetch: Selector<Payload<Timestamp>> = createSelector(
  selectAccountSlice,
  (s) => s.latestTriggerFetch
)
