export const choose =
  <T, U>(f: (x: T) => U | null | undefined) =>
  (arr: T[]) =>
    arr.map((x) => f(x)).filter((x) => x != null) as U[]

export const collect =
  <T, U>(f: (x: T) => U[]) =>
  (arr: T[]) =>
    arr.map((x) => f(x)).reduce((acc, item) => acc.concat(item), [])

export const head = <T>(arr: T[]): T | undefined => arr[0]

export const replaceAt =
  <T>(next: T, idx: number) =>
  (v: T[]) =>
    v.map((x, i) => (i === idx ? next : x))

// partition splits an array in two, the first array passses the condition, the second fails
export const partition = <T>(array: T[], condition: (p: T) => boolean): [pass: T[], fail: T[]] => {
  return array.reduce<[T[], T[]]>(
    ([pass, fail], elem) => (condition(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]),
    [[], []] as [T[], T[]]
  )
}
