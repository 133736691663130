import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SoftConfig } from '../../../config/SoftConfig'
import { Config } from '../../../config'

interface ConfigState {
  config: Config
}

/**
 * getInitialState returns an invalid state but is overriden by the store creator
 */
const getInitialState = (): ConfigState => ({ config: {} as Config })

const configSlice = createSlice({
  name: 'config',
  initialState: getInitialState(),
  reducers: {
    setConfig: (state, { payload }: PayloadAction<Config>) => {
      return { config: payload }
    },
    setSoftConfig: (state, { payload }: PayloadAction<SoftConfig>) => {
      return { config: { ...state.config, softConfig: payload } }
    },
  },
})

export default configSlice.reducer

export const { setSoftConfig } = configSlice.actions
