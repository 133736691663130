import * as React from 'react';
const DEFAULT_IMAGE_STYLE = {
    width: '30px',
    height: '30px',
    borderRadius: '20%',
    objectFit: 'contain',
};
export class OrganizationLogoWithFallback extends React.Component {
    constructor(props) {
        super(props);
        if (props.src) {
            this.hasFailed = this.hasFailed.bind(this);
            this.state = { failed: false };
            return;
        }
        this.state = { failed: true };
    }
    hasFailed() {
        this.setState({ failed: true });
    }
    render() {
        const { state, props: { alt, style, src }, } = this;
        const styleWithDefault = Object.assign(Object.assign({}, DEFAULT_IMAGE_STYLE), style);
        if (state.failed) {
            return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 50 50" },
                React.createElement("path", { fill: "#eee", d: "M0 0h50v50H0z" }),
                React.createElement("path", { stroke: "#b3b3b3", strokeMiterlimit: "10", fill: "none", d: "M19.5 19.5h23v23h-23z" }),
                React.createElement("circle", { cx: "19.5", cy: "19.5", r: "12", stroke: "#b3b3b3", strokeMiterlimit: "10", fill: "#eee" })));
        }
        return React.createElement("img", { src: src, onError: this.hasFailed, alt: alt, style: styleWithDefault });
    }
}
