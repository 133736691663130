/* eslint-disable @typescript-eslint/no-deprecated */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Display, Text } from '../../styles/fonts';
export const StaticPageContainer = ({ title, handleClickBack, children }) => {
    const { colors } = useContext(ThemeContext);
    return (React.createElement(Container, null,
        React.createElement(GoBack, { onClick: handleClickBack, onKeyPress: handleClickBack, tabIndex: 0, role: "button" },
            React.createElement("svg", { height: "14.17", viewBox: "0 0 16.5 14.17", width: "16.5", xmlns: "http://www.w3.org/2000/svg" },
                React.createElement("path", { d: "m278.44 428.06 5.88 5.88a.76.76 0 0 1 .37.65.79.79 0 0 1 -.1.37.76.76 0 0 1 -.13.17l-.08.07-5.93 5.93a.75.75 0 1 1 -1.06-1.06l4.73-4.73h-12.68a.75.75 0 0 1 -.75-.75.75.75 0 0 1 .75-.75h12.66l-4.71-4.71a.746.746 0 1 1 1.05-1.06z", fill: "#e83b77", stroke: "#e83b77", strokeWidth: ".5", transform: "matrix(-1 0 0 -1 284.94 441.626)" })),
            ' ',
            "Go Back"),
        React.createElement(Display, { color: colors.primary.medium }, title),
        children));
};
const GoBack = styled(Text) `
  font-weight: bold;
  margin-bottom: 4rem;
  display: block;
`;
const Container = styled.div `
  max-width: 80rem;
  padding: 2rem;
  margin-left: auto;
  margin-right: auto;
`;
