import * as React from 'react'
import styled from 'styled-components'
import { Link } from '../../../../application/router/Link'
import { useRouter } from '../../../../application/router/useRouter'
import { useAppSelector } from '../../../../application/store'
import { RootState } from '../../../../application/rootReducer'
import { UserTestingAd } from '../../../UserTestingAd'
import { getMenuItems } from '../../../../config/MenuItems'
import { Branding } from '../../TopBarLayout/Branding'
import { BurgerMenuItem } from '../../../../buildingBlocks/BurgerMenuItem'

interface Props {
  isOpen: boolean
  onClickMenuItem: () => void
}

export const MenuConnected: React.FC<Props> = ({ isOpen, onClickMenuItem }) => {
  const { enableCompaniesArea, companiesAreaPageType } = useAppSelector((state: RootState) => {
    return {
      enableCompaniesArea: state.config.config.softConfig.featureSwitches.companiesArea.type !== 'off',
      companiesAreaPageType: state.config.config.softConfig.featureSwitches.companiesArea.type,
    }
  })

  // At the moment the industry grouping enabling is based off of the companies area page type.
  // At a later stage we may want to move this into its own config setting.
  const enableIndustryGrouping = companiesAreaPageType === 'comprehensive'

  const { location } = useRouter()
  const menuItems = getMenuItems({
    enableCompaniesArea,
    enableIndustryGrouping,
  })
  const areaIndex = menuItems.findIndex((item) => item.areas.some((area) => location.pathname.startsWith(area)))

  return (
    <>
      <BrandingContainer>
        <Link onClick={onClickMenuItem} to="/dashboard">
          <Branding showBranding />
        </Link>
      </BrandingContainer>
      <nav>
        <ul>
          {menuItems.map((x, index) => (
            <StyledLi key={x.label}>
              <BurgerMenuItem
                key={x.label}
                name={x.label}
                path={x.to}
                isActive={index === areaIndex}
                onClick={onClickMenuItem}
                icon={x.icon}
                isExternal={x.isExternal}
              />
            </StyledLi>
          ))}
        </ul>
      </nav>
      <Bottom>
        <AdContainer isVisible={isOpen}>
          <UserTestingAd />
        </AdContainer>
      </Bottom>
    </>
  )
}

const StyledLi = styled.li`
  margin-bottom: 1.6rem;
`

const Bottom = styled.div`
  margin: auto 0 0 0;
  padding-bottom: 4rem;
`

const BrandingContainer = styled.div`
  padding: 3rem 3rem;
`

const AdContainer = styled.div<{ isVisible: boolean }>`
  margin: 0 2rem;
  transition: visibility 1000ms;
  height: 10rem;
  visibility: ${(p) => (p.isVisible ? 'visible' : 'hidden')};
  opacity: ${(p) => (p.isVisible ? 1 : 0)};
  transition: opacity 200ms;
  transition-delay: 200ms;
`
