import * as React from 'react';
export const SvgManagementsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#E9EEE8" }),
        React.createElement("path", { d: "M21 6H17V5C17 4.2 16.69 3.45 16.12 2.88C15.55 2.31 14.8 2 14 2H10C9.2 2 8.45 2.31 7.88 2.88C7.31 3.45 7 4.2 7 5V6H3C2.45 6 2 6.45 2 7V20C2 20.55 2.45 21 3 21H21C21.55 21 22 20.55 22 20V7C22 6.45 21.55 6 21 6ZM9 5C9 4.74 9.11 4.48 9.29 4.29C9.48 4.1 9.73 4 10 4H14C14.26 4 14.52 4.11 14.71 4.29C14.9 4.48 15 4.73 15 5V6H9V5ZM8 8H20V11.55C17.74 12.39 15.39 12.86 12.99 12.96C12.96 12.43 12.54 12.01 12 12.01C11.46 12.01 11.04 12.43 11.01 12.96C8.6 12.86 6.25 12.39 4 11.55V8H8ZM4 19V13.69C6.26 14.46 8.6 14.91 11 14.99V15.02C11 15.57 11.45 16.02 12 16.02C12.55 16.02 13 15.57 13 15.02V14.98C15.39 14.89 17.74 14.46 20 13.69V19H4Z", fill: "#2E5E22" })));
};
