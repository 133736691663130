import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Poll, VotePassedIcon, VoteNotPassedIcon } from '@tumelo/shared'
import { TotalVotesContainer } from './TotalVotes'

interface Props {
  poll: Poll
}

export const SeeResults: React.FC<Props> = ({ poll }) => {
  const outcome = getOutcomeText(poll.relationships.proposal.outcome?.decision)

  return (
    <Row>
      <TotalVotesContainer poll={poll} />
      <Row>
        <IconContainer>
          <OutcomeIcon decision={poll.relationships.proposal.outcome?.decision} />
        </IconContainer>
        <Column>
          <SmallText>{outcome}</SmallText>
          <div>
            <CustomCallToAction>See result</CustomCallToAction>
          </div>
        </Column>
      </Row>
    </Row>
  )
}

const getOutcomeText = (decision?: string): string => {
  switch (decision) {
    case 'for': {
      return 'Passed'
    }
    case 'against': {
      return 'Not passed'
    }
    case 'withdrawn': {
      return 'Withdrawn'
    }
    default: {
      return ''
    }
  }
}

const OutcomeIcon: React.FC<{ decision?: string }> = ({ decision }) => {
  const { colors } = useTheme()

  switch (decision) {
    case 'for': {
      return <VotePassedIcon width={20} fill={colors.success.dark} />
    }
    case 'against': {
      return <VoteNotPassedIcon width={20} fill={colors.danger.dark} />
    }
    default: {
      return null
    }
  }
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 5.5rem;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const SmallText = styled.div`
  font-size: 1.4rem;
`

export const CustomCallToAction = styled.span`
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid ${(p) => p.color};
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`
