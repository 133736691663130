import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Stack } from '../Stack';
import { Button } from '../Button';
import { ArrowLeftIcon, CrossIcon } from '../Icons';
import { ProgressBar } from '../ProgressBar';
import { StepProgressBar } from '../ProgressBar/StepProgessBar';
import { VisuallyHidden } from '..';
export const ModalHeaderWithProgress = ({ onCloseCallback, onBackCallback: onBack, progress, }) => {
    const colors = useTheme();
    if (progress.type === 'step') {
        return (React.createElement(StyledHeaderStack, { flexDirection: "row", justifyContent: "flex-start", alignItems: "center" },
            onBack && (React.createElement(StyledBackButton, { iconOnly: React.createElement(ArrowLeftIcon, { fill: colors.colors.primary.medium }), variant: "tertiary", onClick: progress.currentStep <= 1 ? () => onCloseCallback('backdropClick') : onBack },
                React.createElement(VisuallyHidden, null, "Go back"))),
            React.createElement(StepProgressBar, { currentStep: progress.currentStep, totalSteps: progress.totalSteps }),
            React.createElement(StyledCloseButton, { iconOnly: React.createElement(CrossIcon, { fill: colors.colors.primary.medium }), variant: "tertiary", onClick: () => onCloseCallback('backdropClick') },
                React.createElement(VisuallyHidden, null, "Close modal"))));
    }
    return (React.createElement(StyledHeaderStack, { flexDirection: "row", justifyContent: "flex-start", alignItems: "center" },
        onBack && (React.createElement(StyledBackButton, { iconOnly: React.createElement(ArrowLeftIcon, { fill: colors.colors.primary.medium }), variant: "tertiary", onClick: progress.percentageProgress <= 0 ? () => onCloseCallback('backdropClick') : onBack },
            React.createElement(VisuallyHidden, null, "Go back"))),
        React.createElement(StyledProgressBar, { percentageProgress: progress.percentageProgress, screenReaderText: progress.screenReaderText }),
        React.createElement(StyledCloseButton, { iconOnly: React.createElement(CrossIcon, { fill: colors.colors.primary.medium }), variant: "tertiary", onClick: () => onCloseCallback('backdropClick') },
            React.createElement(VisuallyHidden, null, "Close modal"))));
};
const StyledHeaderStack = styled(Stack) `
  max-width: 108.8rem;
  margin-top: -0.8rem;
  width: 100%;
`;
const StyledBackButton = styled(Button) `
  justify-self: flex-start;
  align-self: flex-start;
  margin-left: -0.8rem;
  svg {
    fill: ${({ theme }) => theme.colors.primary.medium};
  }
`;
const StyledProgressBar = styled(ProgressBar) `
  margin-top: 0.4rem;
`;
const StyledCloseButton = styled(Button) `
  justify-self: flex-end;
  align-self: flex-start;
  margin-right: -0.8rem;
  svg {
    fill: ${({ theme }) => theme.colors.primary.medium};
  }
`;
