import { ModelPortfolio, sampleModelPortfolio } from '@tumelo/shared'
import { ModelPortfolioService } from './ModelPortfolioService'

export const ModelPortfolioServiceMock: ModelPortfolioService = {
  listModelPortfolios: async () => Array.from(map.values()),
  getModelPortfolioById: async (id) => map.get(id),
}

const modelPortfolio = sampleModelPortfolio()
const map = new Map<string, ModelPortfolio>([[modelPortfolio.id, modelPortfolio]])
