import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { TopBar } from '../components/Layout/TopBarLayout'
import Vote from '../containers/Vote'
import CompaniesPage from '../containers/CompaniesPage'
import Portfolio from '../containers/PortfolioPage'
import CompanyDetailPage from '../containers/CompanyDetailPage'
import OpenVotes from '../containers/OpenVotes'
import VoteResultPage from '../containers/VoteResultPage'
import VoteResults from '../containers/VoteResults'
import Dashboard from '../containers/Dashboard'
import Profile from '../containers/Profile'
import NotFoundPage from '../containers/NotFound'
import DeleteAccountPage from '../containers/DeleteAccount'
import IndustryGroupingPage from '../containers/IndustryGroupingPage'
import MyVotes from '../containers/MyVotes'
import ChangeEmailPage from '../components/ChangeEmail/ChangeEmailPage'
import { ChangeEmailVerifyCodePage } from '../components/ChangeEmail/ChangeEmailVerifyCodePage'
import { EmptyAccountModalConnected } from '../components/EmptyAccountModal/EmptyAccountModalConnected'
import { NavigateWithQueryParams } from '../utils/useNavigateWithQuery'
import HasFundChangedPage from '../containers/selfSelect/HasFundChangedPage'
import SelfSelectFormPage from '../containers/selfSelect/SelfSelectFormPageLazy'
import UnsupportedFundPage from '../containers/selfSelect/UnsupportedFundPageLazy'
import { RequireDashboardAccountSetup } from '../components/RequireDashboardAccountSetup'
import { RequireIsEmailVerified } from '../components/RequireIsEmailVerified'
import { RequireAccountComposition } from '../components/RequireAccountComposition'
import { BuckWarningMessage } from '../components/BuckWarningMessage'

// Router for user with configured account
export const DashboardRoutes: React.FC = () => {
  useEffect(() => {
    document.title = `Tumelo Platform`
  })

  return (
    <RequireIsEmailVerified>
      <Routes>
        {/* To retain qs on account setup */}
        <Route path="/" element={<NavigateWithQueryParams to="/dashboard" />} />
        <Route path="/compositionpending" element={<EmptyAccountModalConnected />} />
        <Route path="/profile/change-email" element={<ChangeEmailPage pageTitle="Update your email address" />} />
        <Route path="/profile/confirm-email" element={<ChangeEmailVerifyCodePage />} />
        <Route path="/portfolio-selection/self-select/has-fund-changed" element={<HasFundChangedPage />} />
        <Route path="/portfolio-selection/self-select/unsupported-fund" element={<UnsupportedFundPage />} />
        <Route path="/portfolio-selection/self-select/self-select-fund" element={<SelfSelectFormPage />} />
        <Route
          path="/*"
          element={
            <BuckWarningMessage>
              <RequireDashboardAccountSetup>
                <RequireAccountComposition>
                  <TopBar>
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard pageTitle="Home" />} />
                      <Route path="/account" element={<Portfolio pageTitle="Account" />} />
                      <Route path="/companies" element={<CompaniesPage pageTitle="Companies in my Pension" />} />
                      <Route path="fund" element={<NavigateWithQueryParams to="/companies" />} />
                      <Route path="/companies/:id" element={<CompanyDetailPage pageTitle="About this company" />} />
                      <Route
                        path="/industry-grouping"
                        element={<IndustryGroupingPage pageTitle="Industry Grouping" />}
                      />
                      <Route
                        path="/industry-grouping/:id"
                        element={<CompanyDetailPage pageTitle="About this company" />}
                      />
                      <Route path="/votes/open" element={<OpenVotes pageTitle="Open votes" />} />
                      <Route path="/votes/open/:id" element={<Vote pageTitle="About this vote" />} />
                      <Route path="/votes/my-votes" element={<MyVotes pageTitle="My votes" />} />
                      <Route
                        path="/votes/results/:id"
                        element={<VoteResultPage pageTitle="About this vote result" />}
                      />
                      <Route path="/votes/results" element={<VoteResults pageTitle="Vote results" />} />
                      <Route path="/profile" element={<Profile pageTitle="Your profile" />} />
                      <Route
                        path="/profile/delete-account"
                        element={<DeleteAccountPage pageTitle="Delete my account" />}
                      />
                      <Route path="/*" element={<NotFoundPage pageTitle="Not Found" />} />
                    </Routes>
                  </TopBar>
                </RequireAccountComposition>
              </RequireDashboardAccountSetup>
            </BuckWarningMessage>
          }
        />
      </Routes>
    </RequireIsEmailVerified>
  )
}
