import { subMonths } from 'date-fns'
import { AppThunk } from '../../store'
import { BallotWithPollAndOrg, PollAndOrg } from '../../types/PollWithOrganization/PollWithOrganization'
import * as InvestorVotesActions from './index'

const isPollAndOrg = (item: PollAndOrg | undefined): item is PollAndOrg => !!item

export const fetchInvestorVotes =
  (): AppThunk =>
  async (dispatch, __, { services }) => {
    dispatch(InvestorVotesActions.setPending())
    const { loggerService, pollService, votingService } = services
    try {
      const currentDate = new Date()
      const threeMonthsAgo = subMonths(currentDate, 3)

      const investorBallots = await votingService.listInvestorBallots(true, threeMonthsAgo)
      const investorBallotsWithPollAndOrg: BallotWithPollAndOrg[] = []
      await Promise.all(
        investorBallots.map(async (ballot) => {
          const pollAndOrg = await pollService.getPoll(ballot.pollId)
          if (isPollAndOrg(pollAndOrg)) {
            investorBallotsWithPollAndOrg.push({ ballot, ...pollAndOrg })
          }
        })
      )

      dispatch(InvestorVotesActions.setInvestorVotes(investorBallotsWithPollAndOrg))
    } catch (e) {
      loggerService.logError(e)
      dispatch(InvestorVotesActions.setError())
    }
  }
