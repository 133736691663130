import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgPaperplane = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M22 2.67V2.59C21.9697 2.51796 21.9293 2.45061 21.88 2.39C21.88 2.39 21.88 2.39 21.88 2.34V2.29C21.83 2.24126 21.7727 2.20076 21.71 2.17C21.6156 2.12185 21.5144 2.08815 21.41 2.07H20.94L4.93 7.61C4.7516 7.68156 4.5976 7.80301 4.48645 7.95983C4.37529 8.11665 4.31168 8.30218 4.30324 8.49421C4.2948 8.68624 4.34188 8.87664 4.43885 9.0426C4.53582 9.20856 4.67857 9.34307 4.85 9.43L8.6 11.15L9.2 13.74C9.24916 13.9426 9.3604 14.1248 9.51819 14.261C9.67597 14.3973 9.8724 14.4809 10.08 14.5L13.18 14.67L16.36 18.18C16.4498 18.2769 16.5578 18.3551 16.678 18.41C16.7981 18.465 16.928 18.4955 17.06 18.5C17.1364 18.5097 17.2136 18.5097 17.29 18.5C17.4562 18.455 17.608 18.3678 17.7306 18.2469C17.8533 18.1261 17.9426 17.9755 17.99 17.81L22 3.23C22.0053 3.15343 22.0053 3.07657 22 3C22.0172 2.89067 22.0172 2.77933 22 2.67ZM9.35 9.35L7.84 8.66L13.54 6.66L9.35 9.35ZM11.93 11.89C11.8426 11.9911 11.7763 12.1086 11.7351 12.2357C11.6939 12.3628 11.6785 12.4969 11.69 12.63H10.91L10.52 10.95L15.74 7.58L11.93 11.89ZM16.6 15.57L14.33 13.06C14.1731 12.8959 13.965 12.7901 13.74 12.76L19.01 6.76L16.6 15.57Z" }),
        React.createElement("path", { d: "M8.73 15.22C8.54508 15.0485 8.3022 14.9532 8.05 14.9532C7.7978 14.9532 7.55492 15.0485 7.37 15.22L3.78 18.87C3.60271 19.056 3.50381 19.3031 3.50381 19.56C3.50381 19.8169 3.60271 20.064 3.78 20.25C3.8669 20.3417 3.97157 20.4147 4.08762 20.4646C4.20367 20.5145 4.32867 20.5403 4.455 20.5403C4.58133 20.5403 4.70633 20.5145 4.82238 20.4646C4.93843 20.4147 5.0431 20.3417 5.13 20.25L8.73 16.6C8.90729 16.414 9.00619 16.1669 9.00619 15.91C9.00619 15.6531 8.90729 15.406 8.73 15.22Z" }),
        React.createElement("path", { d: "M6.83 13.52C6.72453 13.2853 6.53264 13.1003 6.29424 13.0034C6.05584 12.9066 5.78928 12.9053 5.55 13L2.55 14.42C2.32113 14.5362 2.14571 14.736 2.06009 14.978C1.97446 15.22 1.98517 15.4857 2.09 15.72C2.16878 15.8857 2.29162 16.0265 2.44506 16.127C2.59851 16.2275 2.77665 16.2839 2.96 16.29C3.10285 16.2912 3.24376 16.2569 3.37 16.19L6.37 14.78C6.58918 14.6643 6.75759 14.4714 6.84259 14.2386C6.92758 14.0058 6.92309 13.7497 6.83 13.52Z" }),
        React.createElement("path", { d: "M10.64 17.28C10.4178 17.1592 10.158 17.1272 9.9131 17.1903C9.66817 17.2533 9.45624 17.4069 9.32 17.62L7.62 20.52C7.4967 20.7489 7.46741 21.0169 7.53835 21.267C7.60929 21.5172 7.77488 21.7299 8 21.86C8.14689 21.9519 8.31674 22.0004 8.49 22C8.65748 21.9959 8.82125 21.9498 8.96628 21.866C9.1113 21.7821 9.23293 21.6631 9.32 21.52L11.02 18.62C11.1433 18.3911 11.1726 18.1231 11.1017 17.873C11.0307 17.6228 10.8651 17.4101 10.64 17.28Z" })));
};
