/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import styled from 'styled-components'
import { Breakpoint, SubHeading, Body, SubmitButton, Field } from '@tumelo/shared'
import { PasswordField } from '../Form/PasswordField'
import { PageHeader } from '../PageHeader'
import { AuthSplitContainer } from '../Auth/Components/AuthSplitContainer'
import { ErrorContainer } from '../Auth/Components/ErrorContainer'

interface Props {
  submit: (details: ChangeEmailFormValues) => Promise<void>
  error: string | undefined
}

const ChangeEmailSchema = yup.object({
  email: yup.string().required('Email is required').email('Invalid email'),
  password: yup.string().required('Password is required'),
})

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface ChangeEmailFormValues extends yup.Asserts<typeof ChangeEmailSchema> {}

export const ChangeEmailForm: React.FC<Props> = ({ submit, error }) => {
  const initialValues: ChangeEmailFormValues = {
    email: '',
    password: '',
  }

  return (
    <AuthSplitContainer>
      <PageHeader title="Profile" showBack />
      <ErrorContainer error={error} />
      <Header>
        <SubHeading as="h1">Update your email address</SubHeading>
        <Body m={0}>
          Please enter your new email address and current password. We will send you an email to your new address to
          verify this change
        </Body>
      </Header>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => submit(values)}
        validationSchema={ChangeEmailSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <Form>
          <Field
            autoComplete="email"
            name="email"
            type="email"
            label="New email address"
            required
            showValidationErrorMessages
          />
          <PasswordField
            name="password"
            type="password"
            label="Current Password"
            autoComplete="new-password"
            required
            showValidationErrorMessages
          />
          <BottomContainer>
            <SignInButtonContainer>
              <SubmitButton label="Submit" />
            </SignInButtonContainer>
          </BottomContainer>
        </Form>
      </Formik>
    </AuthSplitContainer>
  )
}

const SignInButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2rem;
  @media (max-width: ${Breakpoint.mobile}) {
    flex-direction: column;
  }
`

const Header = styled.div`
  margin-bottom: 2rem;
`

const BottomContainer = styled.div`
  margin-top: 4rem;
`
