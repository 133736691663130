/* eslint-disable @typescript-eslint/no-deprecated */
import * as React from 'react';
import styled from 'styled-components';
import { IndustryIcon } from '../IndustryIcon/IndustryIcon';
import { industryCategoryToIndustryCategoryTitle } from '../../domain';
import { Small } from '../../styles';
const ColItem = ({ industryCategory, count }) => {
    return (React.createElement(Container, null,
        React.createElement(Image, null,
            React.createElement(IndustryIcon, { industryCategory: industryCategory, imageStyle: { width: '5rem', height: '5rem' } })),
        React.createElement(Count, null, count),
        React.createElement(Name, null, industryCategoryToIndustryCategoryTitle(industryCategory))));
};
export const OrganizationsIndustryCategoryKey = ({ industriesWithCount }) => {
    return (React.createElement(ContainerRow, null, industriesWithCount.map((industryWithCount) => (React.createElement(ColItem, { key: industryWithCount.industryCategory, industryCategory: industryWithCount.industryCategory, count: industryWithCount.count })))));
};
const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 11rem;
`;
const Image = styled.div ``;
const Count = styled(Small) `
  font-weight: bold;
  text-align: center;
`;
const Name = styled(Small) `
  text-align: center;
  line-height: 1.7rem;
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
`;
const ContainerRow = styled.div `
  display: flex;
  flex-flow: wrap;
`;
