import { BallotId, dateToTimestamp, InvestorVoteResponse, PollId, sampleBallot, sleep } from '@tumelo/shared'
import { sampleVotingRecommendation } from '../../features/votingPolicies/types'
import { Ballot as ApiBallot, VoteOptions } from '../../../utils/api/gen'
import { convertToVoteOptions } from './utils'
import { VotingService } from './VotingService'
import { fromDto } from './VotingServiceApi'

const sampleApiBallot = (override?: Partial<ApiBallot>): ApiBallot => {
  return {
    id: '438ccddc-1892-4589-941f-7b1235ab6191' as BallotId,
    investorId: '895438fd-cf94-fa39-9308-3e2fd54f9d6c',
    createTime: new Date(),
    expirationTime: new Date(),
    earliestVotingDeadline: new Date(),
    latestVotingDeadline: new Date(),
    pollId: '77738a21-be2b-48d4-9308-3e2fd54f9d6c' as PollId,
    investorVote: {
      response: VoteOptions.For,
      responseTime: new Date(),
      voteMethod: 'automatic',
    },
    ...override,
  }
}

export const VotingServiceMock: VotingService = {
  castVote: async (ballotId: BallotId, response: InvestorVoteResponse) => {
    const voteOption = convertToVoteOptions(response)
    if (voteOption === undefined) {
      throw new Error(`invalid vote response on ballot ${ballotId}`)
    }
    const ballot = sampleApiBallot()
    ballot.investorVote = {
      response: voteOption,
      responseTime: new Date(),
      voteMethod: 'automatic',
    }
    return fromDto(ballot)
  },
  listInvestorBallots: async () => {
    await sleep()
    return [sampleBallot()]
  },
  getInvestorBallotByBallotId: async (ballotId: BallotId) => {
    await sleep()
    const ballot = sampleBallot()
    ballot.id = ballotId
    return ballot
  },
  getInvestorBallotByPollId: async (pollId: PollId) => {
    await sleep()
    const ballot = sampleBallot()
    ballot.pollId = pollId
    return ballot
  },
  sendComment: async (content) => {
    await sleep()
    return sampleBallot({
      comment: {
        content,
        createTime: dateToTimestamp(new Date()),
      },
    })
  },
  listVotingRecommendationForProposalId: async () => {
    await sleep()
    return sampleVotingRecommendation()
  },
}
