import {
  InvestorExtendedProfileSchema,
  InvestorExtendedProfileService,
  mapSchemaToType,
  mapTypeToSchema,
} from './InvestorExtendedProfileService'

const LOCAL_STORAGE_KEY = 'INVESTOR_EXTENDED_PROFILE'

export const InvestorExtendedProfileServiceLocalStorage: InvestorExtendedProfileService = {
  getProfile: async () => {
    const item = localStorage.getItem(LOCAL_STORAGE_KEY)
    if (!item) {
      return { tracking: {} }
    }
    try {
      return mapSchemaToType(InvestorExtendedProfileSchema.parse(JSON.parse(item)))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return { tracking: {} }
    }
  },
  saveProfile: async (profile) => localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(mapTypeToSchema(profile))),
  deleteProfile: async () => localStorage.removeItem(LOCAL_STORAGE_KEY),
}
