import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgEyeopen = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M23.89 10.72C23.72 10.37 19.53 2.17 12 2.17C4.47002 2.17 0.280023 10.37 0.110023 10.72C0.0396889 10.8596 0.00305176 11.0137 0.00305176 11.17C0.00305176 11.3263 0.0396889 11.4804 0.110023 11.62C0.280023 12 4.47002 20.17 12 20.17C19.53 20.17 23.72 12 23.89 11.62C23.9604 11.4804 23.997 11.3263 23.997 11.17C23.997 11.0137 23.9604 10.8596 23.89 10.72ZM12 18.17C6.62002 18.17 3.08002 12.78 2.14002 11.17C3.08002 9.56 6.61002 4.17 12 4.17C17.39 4.17 20.92 9.56 21.86 11.17C20.92 12.78 17.39 18.17 12 18.17Z" }),
        React.createElement("path", { d: "M12 7.17C11.2089 7.17 10.4355 7.4046 9.77774 7.84412C9.11995 8.28365 8.60725 8.90836 8.3045 9.63927C8.00175 10.3702 7.92254 11.1744 8.07688 11.9504C8.23122 12.7263 8.61219 13.439 9.1716 13.9984C9.73101 14.5578 10.4437 14.9388 11.2197 15.0931C11.9956 15.2475 12.7999 15.1683 13.5308 14.8655C14.2617 14.5628 14.8864 14.0501 15.3259 13.3923C15.7654 12.7345 16 11.9611 16 11.17C16 10.1091 15.5786 9.09172 14.8285 8.34157C14.0783 7.59143 13.0609 7.17 12 7.17ZM12 13.17C11.6045 13.17 11.2178 13.0527 10.8889 12.8329C10.56 12.6132 10.3036 12.3008 10.1523 11.9354C10.0009 11.5699 9.96128 11.1678 10.0385 10.7798C10.1156 10.3919 10.3061 10.0355 10.5858 9.75579C10.8655 9.47608 11.2219 9.2856 11.6098 9.20843C11.9978 9.13126 12.3999 9.17087 12.7654 9.32224C13.1308 9.47362 13.4432 9.72996 13.663 10.0589C13.8827 10.3878 14 10.7744 14 11.17C14 11.7004 13.7893 12.2091 13.4142 12.5842C13.0392 12.9593 12.5305 13.17 12 13.17Z" })));
};
