import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const FiltersIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { style: styleWithDefault, viewBox: "0 0 26 21.98", "data-name": "filters icon", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("defs", null,
            React.createElement("mask", { id: "a" },
                React.createElement("rect", { width: "100%", height: "100%", fill: "white" }),
                React.createElement("path", { "data-name": "Path 13243", d: "M21.5 11a1 1 0 1 0-1 1 1 1 0 0 0 1-1z", fill: "black" }),
                React.createElement("circle", { "data-name": "Ellipse 1372", cx: "1", cy: "1", r: "1", transform: "translate(4.5 18)", fill: "black" }),
                React.createElement("circle", { "data-name": "Ellipse 1373", cx: "1", cy: "1", r: "1", transform: "translate(4.5 2)", fill: "black" }))),
        React.createElement("path", { "data-name": "Path 13240", d: "M1 4h1.68a3 3 0 0 0 5.64 0H25a1 1 0 0 0 0-2H8.32A3 3 0 0 0 5.8 0h-.55a3 3 0 0 0-2.57 2H1a1 1 0 0 0 0 2z", mask: "url(#a)" }),
        React.createElement("path", { "data-name": "Path 13241", d: "M25 18H8.32a3 3 0 0 0-2.52-2h-.55a3 3 0 0 0-2.57 2H1a1 1 0 0 0 0 2h1.68a3 3 0 0 0 5.64 0H25a1 1 0 0 0 0-2z", mask: "url(#a)" }),
        React.createElement("path", { "data-name": "Path 13242", d: "M25 10h-1.68a3 3 0 0 0-5.64 0H1a1 1 0 0 0 0 2h16.68a3 3 0 0 0 5.64 0H25a1 1 0 0 0 0-2z", mask: "url(#a)" })));
};
