import React from 'react'
import { RocketIcon } from '@tumelo/shared'
import { NewPollCard } from '../NewPollCard/NewPollCard'
import { BallotWithPollAndOrg } from '../../application/types/PollWithOrganization/PollWithOrganization'
import { ListContainer } from './ListContainer'

interface Props {
  newPolls: BallotWithPollAndOrg[]
}

export const NewPollsList: React.FC<Props> = ({ newPolls }) => {
  return (
    <ListContainer title="New to the platform" icon={<RocketIcon height={25} width={25} />}>
      {newPolls.map(
        ({ poll, organization }) =>
          poll.teaserImage &&
          poll.teaserText && (
            <NewPollCard
              key={poll.id}
              poll={poll}
              orgName={organization.title}
              image={poll.teaserImage.url}
              teaser={poll.teaserText}
            />
          )
      )}
    </ListContainer>
  )
}
