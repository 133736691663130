import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgFlag = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M20.4 3C20.2225 2.92898 20.0295 2.90546 19.8401 2.93177C19.6507 2.95807 19.4715 3.03329 19.32 3.15C19.32 3.15 18.44 3.82 16 3.82C14.7502 3.76934 13.5202 3.49147 12.37 3C10.9872 2.4007 9.50578 2.06169 8 2C4.75 2 3.51 3 3.29 3.19C3.20076 3.27141 3.12903 3.37014 3.07917 3.48017C3.02931 3.5902 3.00238 3.70923 3 3.83V21.08C3.02015 21.3311 3.13434 21.5654 3.31974 21.7359C3.50514 21.9065 3.74808 22.0008 4 22C4.25192 22.0008 4.49486 21.9065 4.68026 21.7359C4.86566 21.5654 4.97985 21.3311 5 21.08V15.34C5.95291 14.9613 6.97579 14.7908 8 14.84C9.25163 14.9002 10.4818 15.1882 11.63 15.69C13.0128 16.2893 14.4942 16.6283 16 16.69C19.25 16.69 20.49 15.69 20.71 15.51C20.889 15.3404 20.9933 15.1065 21 14.86V3.86C20.9995 3.6726 20.9417 3.48984 20.8345 3.33615C20.7273 3.18246 20.5757 3.06518 20.4 3ZM19 14.34C18.0461 14.7152 17.024 14.8855 16 14.84C14.7497 14.7782 13.5204 14.4938 12.37 14C10.9891 13.3951 9.50655 13.0559 8 13C6.98834 12.9841 5.97917 13.1052 5 13.36V4.36C5.95389 3.98485 6.976 3.81449 8 3.86C9.25223 3.92355 10.4824 4.21499 11.63 4.72C13.0128 5.3193 14.4942 5.65831 16 5.72C17.0117 5.73594 18.0208 5.61484 19 5.36V14.34Z" })));
};
