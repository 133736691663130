import { MaterialIcon } from '@tumelo/shared'

type menuItem = {
  label: string
  to: string
  areas: string[]
  subLinks?: { label: string; to: string }[]
  icon: MaterialIcon
  isExternal?: boolean
}

interface MenuItemsConfig {
  enableCompaniesArea?: boolean
  enableIndustryGrouping?: boolean
}

export const getMenuItems = (config: MenuItemsConfig): menuItem[] => {
  const accountItem = getAccountMenuItem(config)
  const menuItems: (menuItem | undefined)[] = [
    {
      label: 'Home',
      to: '/dashboard',
      areas: ['/dashboard'],
      icon: 'dashboard',
    },
    accountItem,
    {
      label: 'Voting',
      to: '/votes/open',
      areas: ['/votes'],
      subLinks: [
        { label: 'Open votes', to: '/votes/open' },
        { label: 'My votes', to: '/votes/my-votes' },
        { label: 'Vote results', to: '/votes/results' },
      ],
      icon: 'vote',
    },
    {
      label: 'Need help?',
      to: 'https://tumelo.zendesk.com/hc/en-us',
      areas: [],
      icon: 'help',
      isExternal: true,
    },
  ]

  return menuItems.filter((x) => x !== undefined) as menuItem[]
}

const getAccountMenuItem = ({ enableCompaniesArea, enableIndustryGrouping }: MenuItemsConfig): menuItem | undefined => {
  const companiesLink = enableCompaniesArea !== false ? { label: 'Companies', to: '/companies' } : undefined

  const industryGroupingsLink =
    enableIndustryGrouping !== false ? { label: 'Industry grouping', to: '/industry-grouping' } : undefined

  const pensionBreakdownLink = { label: 'Account breakdown', to: '/account' }

  const subLinks: { label: string; to: string }[] = []

  if (companiesLink) {
    subLinks.push(companiesLink)
  }

  if (industryGroupingsLink) {
    subLinks.push(industryGroupingsLink)
  }

  if (pensionBreakdownLink) {
    subLinks.push(pensionBreakdownLink)
  }

  if (subLinks.length === 0) {
    return undefined
  }

  const topBarLink = subLinks[0].to

  return {
    label: 'Account',
    to: topBarLink,
    areas: ['/companies', '/account', '/industry-grouping'],
    subLinks,
    icon: 'pie-chart',
  }
}
