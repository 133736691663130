import { buildEvent } from '../../analytics/eventBuilders'
import { AppThunk } from '../../store'
import { SearchFilter, FilterEntry } from '../../types/SearchFilters'
import { toggleFilter } from '.'

export const toggleFilterWithAnalytics =
  ({
    filter,
    entry,
    filterTrackingName,
  }: {
    filter: keyof SearchFilter
    entry: FilterEntry
    filterTrackingName?: string
  }): AppThunk =>
  async (dispatch, _, { services }) => {
    const { analyticsService } = services
    const { trackEvent } = analyticsService
    trackEvent(buildEvent.clickToggleFilter(filter, filterTrackingName || (entry as string)))
    dispatch(toggleFilter({ filter, entry }))
  }
