import { ModelPortfolioId } from '@tumelo/shared'
import { useEffect } from 'react'
import { getQueryVariable } from '../../../utils/getQueryVariable'
import { useNavigateWithQuery } from '../../../utils/useNavigateWithQuery'
import { createAccount, updateAccountModelPortfolio } from '../../asyncActions'
import { useAvailableModelPortfolios } from '../../features/account/hooks'
import { useDashboardAccount } from '../../features/dashboardUserAccount'
import { isValueState, Payload } from '../../payload'
import { useAppDispatch, useAppSelector } from '../../store'
import { compareModelPortfolio } from './compareModelPortfolio'
import { flowSelector, RuntimeFlow } from './portfolioSelection'

const MODEL_PORTFOLIO_ID_QUERY_KEY = 'modelPortfolioId'

export const useSelfSelectFlow = (): Payload<RuntimeFlow> => {
  const { availableModelPortfolios } = useAvailableModelPortfolios()
  const dispatch = useAppDispatch()
  const navigate = useNavigateWithQuery()
  const dashboardAccount = useDashboardAccount()

  const { portfolioSelectionFlow, defaultModelPortfolioId } = useAppSelector((state) => {
    const { portfolioSelectionFlow } = state.config.config.softConfig.settings
    const { defaultModelPortfolioId } = state.config.config
    return { portfolioSelectionFlow, defaultModelPortfolioId }
  })
  const modelPortfolioId = getQueryVariable(MODEL_PORTFOLIO_ID_QUERY_KEY) as ModelPortfolioId | undefined

  useEffect(() => {
    if (isValueState(dashboardAccount) && isValueState(availableModelPortfolios) && modelPortfolioId) {
      const { account } = dashboardAccount
      const portfolio = availableModelPortfolios.find(({ id }) => id === modelPortfolioId)

      if (portfolio != null && account === 'not-configured') {
        dispatch(createAccount(modelPortfolioId))
        navigate('/')
      }
      // this enables the provision of a URL to update an existing users account
      if (portfolio != null && account !== 'not-configured') {
        dispatch(updateAccountModelPortfolio(modelPortfolioId))
        navigate('/')
      }
    }
  }, [availableModelPortfolios, dashboardAccount, dispatch, modelPortfolioId, navigate])

  if (!isValueState(availableModelPortfolios)) {
    return availableModelPortfolios
  }

  return flowSelector(
    Array.from(availableModelPortfolios).sort(compareModelPortfolio),
    portfolioSelectionFlow,
    // casting string or undefined to string type alias | undefined
    defaultModelPortfolioId as ModelPortfolioId | undefined
  )
}
