import React from 'react';
import styled from 'styled-components';
import { CrossIcon } from '../Icons';
import { VisuallyHidden } from '../VisuallyHidden';
export const DismissButton = ({ onClick, children }) => (React.createElement(StyledButton, { onClick: onClick },
    children,
    React.createElement(CrossIcon, null),
    React.createElement(VisuallyHidden, null, "Dismiss")));
const StyledButton = styled.button `
  cursor: pointer;
  border: none;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  width: inherit;
  :before {
    position: absolute;
    width: inherit;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;
