import * as React from 'react';
const DEFAULT_IMAGE_STYLE = {
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
    fill: '#e7eaf5',
};
export const Lobbying = ({ style }) => {
    const styleWithDefault = Object.assign(Object.assign({}, DEFAULT_IMAGE_STYLE), style);
    return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "prefix__Lobbying_Icon", width: "25", height: "25", "data-name": "Lobbying Icon", viewBox: "0 0 25 25" },
        React.createElement("path", { id: "prefix__BG", d: "M18.809 0H6.191A6.256 6.256 0 0 0 0 6.191v12.618A6.2 6.2 0 0 0 6.191 25h12.618A6.2 6.2 0 0 0 25 18.809V6.191A6.256 6.256 0 0 0 18.809 0z", fill: "#fbe5ec" }),
        React.createElement("path", { id: "prefix__Path_9828", d: "M19.707 19.414h-.293V11.6h.293a.293.293 0 0 0 0-.586h-4.781v-.873l.2.067a.293.293 0 1 0 .187-.555L14.1 9.246V7.208h.293a.293.293 0 1 0 0-.586h-.3a3.965 3.965 0 0 0-3.8-3.664v-.419h2.363a.684.684 0 0 0 .684-.684V.684A.684.684 0 0 0 12.656 0H10a.293.293 0 0 0-.293.293v2.665a3.969 3.969 0 0 0-2.338.876.293.293 0 0 0 .369.456 3.381 3.381 0 0 1 2.12-.749h.284A3.378 3.378 0 0 1 13.5 6.622h-7a3.347 3.347 0 0 1 .592-1.635.293.293 0 0 0-.481-.335 3.931 3.931 0 0 0-.7 1.97h-.3a.293.293 0 1 0 0 .586H5.9v2.038l-1.211.408a.293.293 0 1 0 .187.555l.2-.067v.873H.293a.293.293 0 0 0 0 .586h.293v7.812H.293a.293.293 0 1 0 0 .586h19.414a.293.293 0 0 0 0-.586zM10.293.586h2.363a.1.1 0 0 1 .1.1v1.169a.1.1 0 0 1-.1.1h-2.363zM6.484 7.208h7.031v1.841l-2.923-.985a1.866 1.866 0 0 0-1.184 0l-2.923.985zM5.66 9.945L9.595 8.62a1.278 1.278 0 0 1 .81 0l3.935 1.325V11.6H5.66V9.945zm-1.843 9.469H2.344v-1.172h1.473zm0-1.758v.586H2.344v-1.571a.737.737 0 0 1 1.473 0zm1.172 1.758H4.4v-2.743a1.323 1.323 0 0 0-2.645 0v2.743h-.583V14.8l3.817-.586v5.2zm0-5.2l-3.817.586v-3.2h2.859v.605a.88.88 0 0 0 .879.879h.079v1.129zm1.819 5.2H5.575V12.5h1.233v6.914zm1.9 0H7.394v-6.328h1.319zm1.819 0H9.3V12.5h1.233zm2.075 0h-1.484v-6.328h1.25a.293.293 0 0 0 0-.586H4.91a.293.293 0 0 1-.293-.293V11.6h10.766v.605a.293.293 0 0 1-.293.293h-2.722a.293.293 0 0 0 0 .586h.239v6.328zm1.819 0h-1.229V12.5h1.233v6.914zm3.231 0h-1.469v-1.172h1.473zm0-1.758v.586h-1.469v-1.571a.737.737 0 0 1 1.473 0zm1.172 1.758h-.586v-2.743a1.323 1.323 0 0 0-2.645 0v2.743h-.586v-5.2l3.817.586zm0-5.2v.586l-3.817-.586v-1.128h.079a.88.88 0 0 0 .879-.879V11.6h2.859z", "data-name": "Path 9828", transform: "translate(2.5 2)", fill: "#d30245" })));
};
