import React from 'react';
import styled from 'styled-components';
import { screen } from '..';
const gridGutters = {
    xs: '1.6rem',
    md: '2.4rem',
};
export const getColumnWidth = (span) => {
    const width = (span / 12) * 100;
    return `${width}%`;
};
export const Grid = ({ mb, as = 'div', children, className }) => {
    if (mb)
        return (React.createElement(StyledMargin, { mb: mb },
            React.createElement(StyledGrid, { as: as, className: className }, children)));
    return (React.createElement(StyledGrid, { as: as, className: className }, children));
};
const Tag = ({ children, as }) => {
    const Element = as || 'div';
    return React.createElement(Element, null, children);
};
const StyledGrid = styled(Tag) `
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 0 -${gridGutters.xs} -${gridGutters.xs};
  @media ${screen.mediumUp} {
    margin: 0 0 -${gridGutters.md} -${gridGutters.md};
  }
`;
const StyledMargin = styled.div `
  margin-bottom: ${({ mb }) => mb || '0'};
`;
export const Column = styled.div `
  display: ${({ xs }) => (xs ? 'block' : 'none')};
  align-items: stretch;
  width: ${({ xs }) => (xs ? getColumnWidth(xs) : '100%')};
  @media ${screen.smallUp} {
    display: ${({ sm }) => (sm ? 'block' : 'none')};
    width: ${({ sm }) => sm && getColumnWidth(sm)};
  }
  @media ${screen.mediumUp} {
    display: ${({ md }) => (md ? 'block' : 'none')};
    width: ${({ md }) => md && getColumnWidth(md)};
  }
  @media ${screen.largeUp} {
    display: ${({ lg }) => (lg ? 'block' : 'none')};
    width: ${({ lg }) => lg && getColumnWidth(lg)};
  }

  padding: 0 0 ${gridGutters.xs} ${gridGutters.xs};
  @media ${screen.mediumUp} {
    padding: 0 0 ${gridGutters.md} ${gridGutters.md};
  }
`;
