import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const SandTimerIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 20 25", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "lastchance.icon" },
        React.createElement("g", { id: "Layer_1" },
            React.createElement("path", { id: "Path_12824", d: "M18.16 1.467h.96A.817.817 0 0 0 20 .733.817.817 0 0 0 19.12 0H.88A.817.817 0 0 0 0 .733a.817.817 0 0 0 .88.733h.807C2 6.833 5.26 9.794 8.667 12.533c-3.333 2.728-6.667 5.678-6.953 11H.88a.817.817 0 0 0-.88.733.817.817 0 0 0 .88.734h18.24a.817.817 0 0 0 .88-.733.817.817 0 0 0-.88-.733h-.96c-.313-5.322-3.593-8.272-6.953-11 3.373-2.739 6.667-5.7 6.953-11.067zM9.92 11.494a32.512 32.512 0 0 1-3.133-2.777 34.252 34.252 0 0 0 3.433-.556 31.166 31.166 0 0 1 3.827-.589 24.816 24.816 0 0 1-4.127 3.922zm6.42-10a10.466 10.466 0 0 1-1.273 4.539 31.792 31.792 0 0 0-5.287.706 30.042 30.042 0 0 1-4.147.611 10.485 10.485 0 0 1-2.126-5.883zM6.507 23.533l2.8-2.667a1.055 1.055 0 0 1 1.387 0l2.8 2.667zm9.367 0l-3.787-3.611a3.172 3.172 0 0 0-4.173 0l-3.787 3.611h-.62c.307-4.739 3.207-7.356 6.413-9.961 3.213 2.606 6.113 5.222 6.42 9.961z" }),
            React.createElement("path", { id: "Path_12825", d: "M14.42 29.18h.056a.735.735 0 0 1 .73.735.735.735 0 0 1-.73.735h-.056a.735.735 0 0 1-.73-.735.735.735 0 0 1 .73-.735z", transform: "translate(-4.414 -12.972)" }))));
};
