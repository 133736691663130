import React from 'react'
import styled from 'styled-components'
import { Poll, CallToAction } from '@tumelo/shared'
import { TotalVotesContainer } from './TotalVotes'

interface Props {
  poll: Poll
  showVoteCount?: boolean
}

export const VoteNow: React.FC<Props> = ({ poll, showVoteCount = true }) => {
  return (
    <Container>
      {showVoteCount && <TotalVotesContainer poll={poll} />}
      <Container>
        <CallToAction callToAction="Vote now" size="small" />
      </Container>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items center;
  min-height: 5.5rem;
`
