import * as z from 'myzod'

const ShadeTypeSchema = z.object({
  light: z.string(),
  medium: z.string(),
  dark: z.string(),
})

export type ShadeType = z.Infer<typeof ShadeTypeSchema>

const SentimentTypeSchema = z
  .object({
    positive: z.string(),
    negative: z.string(),
    neutral: z.string(),
    suppressed: z.string(),
    idle: z.string(),
  })
  .default({
    positive: '#48A395',
    negative: '#E44343',
    neutral: '#CF8327',
    suppressed: '#606297',
    idle: '#8F9494',
  })

export const SoftConfigThemeColorsSchema = z
  .object({
    white: z.string(),
    black: z.string(),
    light: z.string(),
    dark: z.string(),
    boxShadow: z.string(),

    grey: ShadeTypeSchema,
    success: ShadeTypeSchema,
    warning: ShadeTypeSchema,
    danger: ShadeTypeSchema,
    primary: ShadeTypeSchema,
    secondary: ShadeTypeSchema,
    link: ShadeTypeSchema,
    info: ShadeTypeSchema,
    background: ShadeTypeSchema,
    sentiment: SentimentTypeSchema,
  })
  .default({
    white: '#FFFFFF',
    black: '#3a3c62',
    light: '#c4c5d0',
    dark: '#3a3c62',
    boxShadow: 'rgba(0, 0, 0, 0.16)',
    grey: {
      light: '#d3d9de',
      medium: '#8F91A3',
      dark: '#616381',
    },
    success: {
      light: '#abe2e3',
      medium: '#82d3d5',
      dark: '#2eb6b9',
    },
    warning: {
      light: '#eb96b3',
      medium: '#eb96b3',
      dark: '#eb96b3',
    },
    danger: {
      light: '#F6CFDD',
      medium: '#dd4176',
      dark: '#aa0e43',
    },
    primary: {
      light: '#616381',
      medium: '#3a3c62',
      dark: '#23223B',
    },
    secondary: {
      light: '#DD4176',
      medium: '#D41154',
      dark: '#AA0E43',
    },
    link: {
      light: '#FFFFFF',
      medium: '#8e8e93',
      dark: '#d41154',
    },
    info: {
      light: '#A5FFFF',
      medium: '#c4c5d0',
      dark: '#c4c5d0',
    },
    background: {
      light: '#ffffff',
      medium: '#F9F9F9',
      dark: '#23223b',
    },
    sentiment: SentimentTypeSchema.parse(),
  })

const FontWeightSchema = z
  .object({
    regular: z.number(),
    semibold: z.number(),
    bold: z.number(),
  })
  .default({
    regular: 400,
    semibold: 600,
    bold: 700,
  })

const DeviceTypographySchema = z.object({
  mobile: z.string(),
  default: z.string(),
})
const FontSizeSchema = z
  .object({
    body1: DeviceTypographySchema,
    body2: DeviceTypographySchema,
    caption: DeviceTypographySchema,
    buttonText: DeviceTypographySchema,
    h1: DeviceTypographySchema,
    h2: DeviceTypographySchema,
    h3: DeviceTypographySchema,
    h4: DeviceTypographySchema,
  })
  .default({
    body1: {
      mobile: '1.6rem',
      default: '2rem',
    },
    body2: {
      mobile: '1.4rem',
      default: '1.6rem',
    },
    caption: {
      mobile: '1.4rem',
      default: '1.4rem',
    },
    buttonText: {
      mobile: '1.4rem',
      default: '1.4rem',
    },
    h1: {
      mobile: '2.4rem',
      default: '3.4rem',
    },
    h2: {
      mobile: '2rem',
      default: '2.4rem',
    },
    h3: {
      mobile: '2rem',
      default: '2.4rem',
    },
    h4: {
      mobile: '1.6rem',
      default: '2rem',
    },
  })

const BoxShadowSchema = z
  .object({
    one: z.string(),
    two: z.string(),
    three: z.string(),
  })
  .default({
    one: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
    two: '0px 2px 6px -2px rgba(0, 0, 0, 0.08), 0px 4px 12px -2px rgba(0, 0, 0, 0.08)',
    three: '0px 4px 6px -2px rgba(0, 0, 0, 0.03), 0px 12px 16px -4px rgba(35, 34, 59, 0.08)',
  })

const LineHeightSchema = z
  .object({
    body1: DeviceTypographySchema,
    body2: DeviceTypographySchema,
    caption: DeviceTypographySchema,
  })
  .default({
    body1: {
      mobile: '2.4rem',
      default: '3.2rem',
    },
    body2: {
      mobile: '2rem',
      default: '2.4rem',
    },
    caption: {
      mobile: '2rem',
      default: '2rem',
    },
  })

export const SoftConfigThemeSchema = z
  .object(
    {
      colors: SoftConfigThemeColorsSchema,
      fontWeights: FontWeightSchema,
      fontSizes: FontSizeSchema,
      lineHeights: LineHeightSchema,
      boxShadows: BoxShadowSchema,
    },
    { allowUnknown: true }
  )
  .default({
    colors: SoftConfigThemeColorsSchema.parse(),
    fontWeights: FontWeightSchema.parse(),
    fontSizes: FontSizeSchema.parse(),
    lineHeights: LineHeightSchema.parse(),
    boxShadows: BoxShadowSchema.parse(),
  })

export type SoftConfigTheme = z.Infer<typeof SoftConfigThemeSchema>
