export const seededRandomInteger = (s: string, lowerBound: number, upperBound: number): number => {
  const seed = hashCode(s)
  const x = Math.sin(seed) * 10000
  return Math.round((x - Math.floor(x)) * (upperBound - lowerBound) + lowerBound)
}

function hashCode(s: string): number {
  let h = 0
  // eslint-disable-next-line no-bitwise
  for (let i = 0; i < s.length; i += 1) h = (Math.imul(31, h) + s.charCodeAt(i)) | 0

  return h
}
