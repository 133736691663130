import * as React from 'react';
import { useTheme } from 'styled-components';
export const VoteBox = ({ style }) => {
    const { colors } = useTheme();
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", style: Object.assign({ fill: colors.secondary.medium }, style), viewBox: "0 0 17.21 21.14" },
        React.createElement("g", { "data-name": "Layer 2" },
            React.createElement("g", { "data-name": "Layer 1" },
                React.createElement("path", { d: "M0 10.82v8a.36.36 0 0 0 .37.37l16.47.07a.38.38 0 0 0 .37-.37v-8.07Z" }),
                React.createElement("path", { d: "M8.61 8.22h2.67l2.56-5-3.63-1.86L7.64 0 4.41 6.24l3.71 2Zm-.88-2.31-.62-1.3a.41.41 0 1 1 .78-.26l.45.9L11.22 4a.42.42 0 0 1 .51.22.42.42 0 0 1-.25.53L8.26 6.17a.41.41 0 0 1-.53-.26Z" }),
                React.createElement("path", { d: "M14.76 7h-2l-.59 1.17h1.31a.41.41 0 0 1 .41.41.41.41 0 0 1-.41.37H4a.43.43 0 0 1-.41-.41.41.41 0 0 1 .41-.41h2.21L4 7H3L.45 10h16.31Z" }),
                React.createElement("path", { d: "M.71 20.09v.68a.36.36 0 0 0 .37.37h15.06a.36.36 0 0 0 .36-.36v-.68Z" })))));
};
