import React, { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useAppSelector } from '../../application/store'
import { selectIdleTimeout } from '../../application/features/config/selectors'
import { useLogout } from '../../application/hooks'
import { IdleTimeoutModal } from './IdleTimeoutModal'

const CANCEL_ACTION = 'CANCEL'

export const IdleTimeoutWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { logout } = useLogout()
  const [promptOpen, setPromptOpen] = useState(false)
  const [remainingSeconds, setRemainingSeconds] = useState(0)
  const { enabled, timeoutMinutes } = useAppSelector(selectIdleTimeout)

  // Remove 1 minute to account for 1 minute prompt
  const timeout = 1000 * 60 * timeoutMinutes
  const promptTimeoutSeconds = 60
  const promptTimeout = 1000 * promptTimeoutSeconds

  const onPrompt = () => {
    setRemainingSeconds(promptTimeoutSeconds)
    setPromptOpen(true)
  }

  const onIdle = async () => {
    await logout({ withSingleLogout: false })
  }
  const onActive = () => {
    // Message other tabs that this tab is now active - ensures prompts are cancelled
    message(CANCEL_ACTION)
  }

  // Cancel if message recieved from another tab
  const onMessage = (message: string) => {
    if (message === CANCEL_ACTION) {
      setPromptOpen(false)
    }
  }

  const { getRemainingTime, activate, message, start, isPrompted } = useIdleTimer({
    timeout,
    promptBeforeIdle: promptTimeout,
    onPrompt,
    onIdle,
    onActive,
    onMessage,
    events: ['keydown', 'mousedown', 'touchstart', 'touchmove'],
    crossTab: true,
    syncTimers: 200,
    startManually: true,
  })

  const handleStillHere = () => {
    setPromptOpen(false)
    activate()
  }

  useEffect(() => {
    if (enabled) {
      start()
    }
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemainingSeconds(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, enabled, start, isPrompted])

  return (
    <>
      <IdleTimeoutModal
        open={promptOpen}
        remainingSeconds={remainingSeconds}
        onSignout={() => logout()}
        onClose={() => handleStillHere()}
      />
      {children}
    </>
  )
}
