import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgThumbsup = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M18.46 23H4.16C3.36435 23 2.60129 22.6839 2.03868 22.1213C1.47607 21.5587 1.16 20.7956 1.16 20V13C1.16 12.2044 1.47607 11.4413 2.03868 10.8787C2.60129 10.3161 3.36435 10 4.16 10H6.51L10.24 1.59C10.3196 1.41299 10.4489 1.26296 10.6123 1.1582C10.7756 1.05343 10.9659 0.998467 11.16 1C12.2209 1 13.2383 1.42143 13.9884 2.17157C14.7386 2.92172 15.16 3.93913 15.16 5V8H19.82H20.29C20.6803 8.05861 21.0551 8.19363 21.3931 8.39734C21.7311 8.60105 22.0255 8.86944 22.2596 9.18713C22.4937 9.50482 22.6628 9.86558 22.7573 10.2487C22.8517 10.6319 22.8696 11.0299 22.81 11.42L21.43 20.42C21.3288 21.1357 20.9726 21.7908 20.427 22.2648C19.8813 22.7388 19.1828 22.9999 18.46 23ZM8.16 21H18.45C18.6912 21.0027 18.9252 20.9182 19.109 20.762C19.2927 20.6058 19.4138 20.3885 19.45 20.15L20.83 11.15C20.87 10.8892 20.8053 10.6233 20.65 10.41C20.5741 10.3022 20.4775 10.2104 20.366 10.1401C20.2544 10.0697 20.13 10.0221 20 10H14.16C13.8948 10 13.6404 9.89464 13.4529 9.70711C13.2654 9.51957 13.16 9.26522 13.16 9V5C13.1611 4.5753 13.027 4.16126 12.7771 3.81786C12.5272 3.47446 12.1745 3.21953 11.77 3.09L8.16 11.21V21ZM4.16 12C3.89479 12 3.64043 12.1054 3.4529 12.2929C3.26536 12.4804 3.16 12.7348 3.16 13V20C3.16 20.2652 3.26536 20.5196 3.4529 20.7071C3.64043 20.8946 3.89479 21 4.16 21H6.16V12H4.16Z" })));
};
