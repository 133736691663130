import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgFilter = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.66 5.47H1.87V5.48C1.39 5.48 1 5.09 1 4.61C1 4.13 1.39 3.74 1.87 3.74H3.66C4.02 2.74 4.96 2 6.09 2C7.22 2 8.17 2.72 8.53 3.73H22.64C23.12 3.73 23.51 4.12 23.51 4.6C23.51 5.08 23.12 5.47 22.64 5.47H8.54C8.18 6.47 7.23 7.2 6.1 7.2C4.97 7.2 4.02 6.48 3.66 5.47ZM6.97 4.61C6.97 4.13 6.58 3.74 6.1 3.74C5.62 3.74 5.23 4.13 5.23 4.61C5.23 5.09 5.62 5.48 6.1 5.48C6.58 5.48 6.97 5.09 6.97 4.61ZM22.63 11.24H19.66C19.3 10.23 18.35 9.51001 17.22 9.51001C16.09 9.51001 15.14 10.24 14.78 11.24H1.87C1.39 11.24 1 11.63 1 12.11C1 12.59 1.39 12.98 1.87 12.98H14.79C15.15 13.99 16.1 14.71 17.23 14.71C18.36 14.71 19.31 13.98 19.67 12.98H22.64C23.12 12.98 23.51 12.59 23.51 12.11C23.51 11.63 23.12 11.24 22.64 11.24H22.63ZM17.22 12.97C16.74 12.97 16.35 12.58 16.35 12.1C16.35 11.62 16.74 11.23 17.22 11.23C17.7 11.23 18.09 11.62 18.09 12.1C18.09 12.58 17.7 12.97 17.22 12.97ZM22.63 18.73H11.99C11.63 17.72 10.68 17 9.55 17C8.42 17 7.47 17.73 7.11 18.73H1.87C1.39 18.73 1 19.12 1 19.6C1 20.08 1.39 20.47 1.87 20.47H7.12C7.48 21.48 8.43 22.2 9.56 22.2C10.69 22.2 11.64 21.47 12 20.47H22.64C23.12 20.47 23.51 20.08 23.51 19.6C23.51 19.12 23.12 18.73 22.64 18.73H22.63ZM9.55 20.46C9.07 20.46 8.68 20.07 8.68 19.59C8.68 19.11 9.07 18.72 9.55 18.72C10.03 18.72 10.42 19.11 10.42 19.59C10.42 20.07 10.03 20.46 9.55 20.46Z" })));
};
