import { isBefore, isSameDay, add } from 'date-fns';
import { timestampToDate } from '../Timestamp';
export const isPollOpen = (poll) => {
    const now = new Date();
    return isBefore(now, timestampToDate(poll.endDate));
};
export const isPollEndingSoon = (poll) => {
    const sixDaysFromNow = add(new Date(), { days: 6 });
    return isBefore(timestampToDate(poll.endDate), sixDaysFromNow);
};
export const calculatePollTallyOutcome = (poll) => {
    const now = new Date();
    const maxValue = Math.max(poll.tally.forCount, poll.tally.againstCount, poll.tally.abstainCount, poll.tally.withholdCount, poll.tally.noActionCount);
    const countsWithMaxValue = [
        poll.tally.forCount,
        poll.tally.againstCount,
        poll.tally.abstainCount,
        poll.tally.withholdCount,
        poll.tally.noActionCount,
    ].filter((count) => count === maxValue);
    if (isBefore(now, timestampToDate(poll.endDate)) || isSameDay(now, timestampToDate(poll.endDate))) {
        return 'no outcome';
    }
    if (countsWithMaxValue.length > 1) {
        return 'tied';
    }
    if (poll.tally.forCount + poll.tally.againstCount === 0) {
        return 'no votes';
    }
    if (poll.tally.forCount === maxValue) {
        return 'for';
    }
    if (poll.tally.againstCount === maxValue) {
        return 'against';
    }
    if (poll.tally.abstainCount === maxValue) {
        return 'abstain';
    }
    if (poll.tally.withholdCount === maxValue) {
        return 'withhold';
    }
    if (poll.tally.noActionCount === maxValue) {
        return 'no_action';
    }
    return 'no outcome';
};
