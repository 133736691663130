import React from 'react'
import { useAnalytics } from '../../application/analytics'
import { useRouter } from '../../application/router/useRouter'

interface Props {
  to: string
  className?: string
}

export const ExternalLinkWithTracking: React.FC<React.PropsWithChildren<Props>> = ({ to, className, children }) => {
  const { location } = useRouter()
  const analytics = useAnalytics()

  React.useEffect(() => {
    analytics.registerExternalLinkClickListener()
  }, [analytics, location])

  return (
    <a className={`${className} mixpanel-link`} href={to} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}
