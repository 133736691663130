import React from 'react'
import { ConfirmSmsOtpCodeForm, ConfirmSmsOtpCodeValues } from '../Forms/ConfirmSmsOtpCodeForm'
import { ExplicitPageView } from '../../../application/analytics'
import { changeTitle } from '../../../containers/changeTitle'
import { useAppSelector } from '../../../application/store'
import { selectAuthError, selectAuthPending } from '../../../application/features/auth/selectors'

interface Props {
  submit: (details: ConfirmSmsOtpCodeValues) => Promise<void>
  goToSignIn: () => void
  phoneNumber: string
}

export const ConfirmSmsOtpCodePage: React.FC<Props> = ({ submit, goToSignIn, phoneNumber }) => {
  changeTitle('Confirm Code')
  const error = useAppSelector(selectAuthError)
  const authActionPending = useAppSelector(selectAuthPending)

  const onSubmit = async (values: ConfirmSmsOtpCodeValues) => {
    await submit(values)
  }

  return (
    <>
      <ExplicitPageView page="/confirm-otp-code" />
      <ConfirmSmsOtpCodeForm
        submit={onSubmit}
        goToSignIn={goToSignIn}
        phoneNumber={phoneNumber}
        pending={authActionPending}
        error={error?.message}
      />
    </>
  )
}
