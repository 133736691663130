import { isValueState, Payload } from '../payload'
import { useAccount, useAvailableModelPortfolios } from '../features/account/hooks'
import { useInvestor } from '../features/investor/useInvestor'

/**
 * useDerivedAccountTitle returns an account title depending on the situation. For the logic:
 * If the user has a connection to a model portfolio id in the account, return the title of the model portfolio, else ...
 * If the user has an account title return that, else ...
 * Return 'Your pension fund'
 */
export const useDerivedAccountTitle = (): Payload<string> => {
  const { investor } = useInvestor()
  const account = useAccount(investor)
  const modelPortfolios = useAvailableModelPortfolios()

  if (!isValueState(account)) {
    return account
  }
  if (account === 'not-configured') {
    throw new Error('invalid condition, account should be configured before this can be used.')
  }
  if (account.modelPortfolioId) {
    const { availableModelPortfolios } = modelPortfolios
    if (!isValueState(availableModelPortfolios)) {
      return availableModelPortfolios
    }
    const modelPortfolio = availableModelPortfolios.find(({ id }) => id === account.modelPortfolioId)
    if (!modelPortfolio) {
      throw new Error(`expect to find model portfolio with id ${account.modelPortfolioId}`)
    }
    return modelPortfolio.title
  }
  return account.title ?? 'Your pension fund'
}
