import { createSelector } from '@reduxjs/toolkit'
import { Selector } from '../../../utils/redux'
import { Payload } from '../../payload'
import { RootState } from '../../rootReducer'
import { AppNotification } from '../../types/AppNotification/AppNotification'

const selectNotificationsSlice = (state: RootState) => state.notifications

const selectNotificationsAndNextPageToken: Selector<
  Payload<{ notifications: AppNotification[]; nextPageToken: string }>
> = createSelector(selectNotificationsSlice, (s) => s.notificationsAndNextPageToken)

export const selectNotifications: Selector<Payload<AppNotification[]>> = createSelector(
  selectNotificationsAndNextPageToken,
  Payload.map((nnpt) => nnpt.notifications)
)

export const selectNextPageToken: Selector<Payload<string>> = createSelector(
  selectNotificationsAndNextPageToken,
  Payload.map((nnpt) => nnpt.nextPageToken)
)
