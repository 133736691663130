import React from 'react'
import { BasePageProps, Content } from '@tumelo/shared'
import { Link } from '@tumelo/designsystem'
import styled from 'styled-components'
import { MyVotesList } from '../components/views/MyVotesList'
import { LoadingSkeleton } from '../components/Cardlet/LoadingSkeleton'
import { PayloadUnwrapperM } from '../components/Error/PayloadUnwrapper'
import { PageHeader } from '../components/PageHeader'
import { VotingActivityCards } from '../components/VotingActivityCards/VotingActivityCards'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { useInvestorVotes } from '../application/features/investorVotes/useInvestorVotes'
import { changeTitle } from './changeTitle'

const MyVotesPage: React.FC<BasePageProps> = ({ pageTitle }) => {
  changeTitle(pageTitle)
  const { investorVotes, favouriteCategory } = useInvestorVotes()

  return (
    <ErrorBoundary>
      <Content>
        <PageHeader title="My votes" />

        <Styled.MoreInformationContainer>
          Check in on the votes you&apos;ve cast in the last three months! For a full breakdown of the results, head to
          the <Link href="/votes/results">Results Page.</Link>
        </Styled.MoreInformationContainer>

        <PayloadUnwrapperM
          items={[investorVotes, favouriteCategory]}
          customSpinner={<LoadingSkeleton />}
          customNotInitialized={<LoadingSkeleton />}
        >
          {([investorVotes, favouriteCategory]) => (
            <>
              <VotingActivityCards overallUserVotes={investorVotes.length} favouriteCategory={favouriteCategory} />
              <MyVotesList votes={investorVotes} />
            </>
          )}
        </PayloadUnwrapperM>
      </Content>
    </ErrorBoundary>
  )
}

const Styled = {
  MoreInformationContainer: styled.div`
    padding-bottom: 2rem;
    width: 100%;
  `,
}

export default MyVotesPage
