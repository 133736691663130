import * as React from 'react'
import { useRequireAccountComposition } from '../../application/features/accountComposition/useRequireAccountComposition'
import { useLogout } from '../../application/hooks'
import { GenericError } from '../Error'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'

interface Props {
  children: React.ReactNode
}

export const RequireAccountComposition: React.FC<Props> = ({ children }) => {
  const payload = useRequireAccountComposition()

  const { logout } = useLogout()

  return (
    <PayloadUnwrapper
      customError={<GenericError onClick={() => window.location.reload()} onLogoutClick={logout} />}
      item={payload}
    >
      {() => children}
    </PayloadUnwrapper>
  )
}
