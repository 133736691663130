import * as React from 'react';
export const SvgSocialsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#EFEDF8" }),
        React.createElement("path", { d: "M20.83 7.16999C20.32 6.65999 19.69 6.29999 19 6.11999V2.99999C19 2.57999 18.74 2.20999 18.35 2.05999C17.96 1.90999 17.52 2.01999 17.25 2.33999C15.86 3.92999 12.48 4.99999 8.82998 4.99999H6.99998C5.84998 4.99999 4.72998 5.39999 3.83998 6.12999C2.94998 6.85999 2.32998 7.87999 2.09998 9.00999C1.86998 10.14 2.03998 11.32 2.56998 12.33C3.02998 13.21 3.74998 13.93 4.61998 14.4L6.62998 20.17C6.84998 20.78 7.27998 21.3 7.83998 21.62C8.25998 21.87 8.73998 22 9.22998 22C9.38998 22 9.53998 21.99 9.69998 21.96C10.34 21.85 10.92 21.52 11.34 21.02C11.76 20.52 11.99 19.89 11.99 19.24V15.27C14.32 15.69 16.27 16.54 17.24 17.65C17.51 17.96 17.96 18.07 18.34 17.93C18.73 17.78 18.99 17.41 18.99 16.99V13.87C19.68 13.69 20.31 13.34 20.82 12.82C21.57 12.07 21.99 11.06 21.99 9.98999C21.99 8.91999 21.57 7.91999 20.82 7.15999L20.83 7.16999ZM9.99998 19.24C9.99998 19.42 9.93998 19.59 9.81998 19.73C9.69998 19.87 9.53998 19.96 9.36998 19.99C9.19998 20.02 9.00998 19.99 8.85998 19.9C8.70998 19.81 8.58998 19.67 8.52998 19.51L6.95998 15.01C6.95998 15.01 6.98998 15.01 6.99998 15.01H8.82998C9.21998 15.01 9.60998 15.02 9.99998 15.05V19.25V19.24ZM9.99998 13.03C9.61998 13.01 9.22998 13 8.82998 13H6.98998C6.59998 13 6.21998 12.92 5.85998 12.78C5.83998 12.77 5.81998 12.76 5.78998 12.75C5.16998 12.48 4.65998 12 4.33998 11.4C4.01998 10.79 3.91998 10.08 4.04998 9.40999C4.18998 8.72999 4.55998 8.11999 5.08998 7.67999C5.61998 7.23999 6.29998 6.99999 6.98998 6.99999H8.81998C9.20998 6.99999 9.59998 6.98999 9.98998 6.96999V13.03H9.99998ZM17 14.92C15.66 14.12 13.94 13.54 12 13.24V6.75999C13.94 6.45999 15.66 5.87999 17 5.07999V14.92ZM19.42 11.41C19.3 11.53 19.16 11.64 19 11.73V8.26999C19.15 8.35999 19.29 8.45999 19.42 8.58999C19.8 8.96999 20 9.46999 20 9.99999C20 10.53 19.79 11.04 19.42 11.41Z", fill: "#6355B5" })));
};
