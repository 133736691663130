import { dateToTimestamp } from '../Timestamp';
import { VoteMethod } from './Ballot';
export const sampleBallot = (override) => {
    return Object.assign({ id: '438ccddc-1892-4589-941f-7b1235ab6191', pollId: '77738a21-be2b-48d4-9308-3e2fd54f9d6c', createTime: dateToTimestamp(new Date()), expirationTime: dateToTimestamp(new Date()), investorVote: { response: 'for', responseTime: dateToTimestamp(new Date()), voteMethod: VoteMethod.Manual } }, override);
};
export const sampleBallotWithMockDates = (override) => {
    return Object.assign(Object.assign({}, sampleBallot({
        createTime: dateToTimestamp(new Date('2021-06-26T14:46:36.000')),
        expirationTime: dateToTimestamp(new Date('2021-06-26T14:46:36.000')),
    })), override);
};
