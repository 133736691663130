import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgHead = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { clipPath: "url(#clip0_1260_3099)" },
            React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0 11.91C0 5.34 5.34 0 11.91 0C18.47 0 23.82 5.34 23.82 11.91C23.82 18.48 18.48 23.82 11.91 23.82C5.34 23.82 0 18.48 0 11.91ZM21.82 11.91C21.82 6.44 17.37 2 11.91 2C6.44 2 2 6.44 2 11.91C2 13.57 2.41 15.13 3.13 16.5C3.37 15.71 3.78 14.97 4.37 14.38C5.31 13.45 6.58 12.92 7.91 12.92H15.91C17.24 12.92 18.51 13.44 19.45 14.38C20.05 14.97 20.46 15.71 20.69 16.5C21.41 15.12 21.82 13.57 21.82 11.91ZM4.91 18.9C6.7 20.7 9.18 21.81 11.91 21.81C14.64 21.81 17.12 20.69 18.91 18.9V17.9C18.91 17.11 18.59 16.34 18.03 15.78C17.47 15.22 16.71 14.9 15.91 14.9H7.91C7.12 14.9 6.35 15.22 5.79 15.78C5.23 16.34 4.91 17.1 4.91 17.9V18.9ZM11.91 2.91C14.67 2.91 16.91 5.15 16.91 7.91C16.91 10.67 14.67 12.91 11.91 12.91C9.14997 12.91 6.90997 10.67 6.90997 7.91C6.90997 5.15 9.14997 2.91 11.91 2.91ZM8.90997 7.91C8.90997 9.57 10.25 10.91 11.91 10.91C13.57 10.91 14.91 9.57 14.91 7.91C14.91 6.25 13.57 4.91 11.91 4.91C10.25 4.91 8.90997 6.25 8.90997 7.91Z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_1260_3099" },
                React.createElement("rect", { width: 24, height: 24, fill: "white" })))));
};
