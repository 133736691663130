import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgCross = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18.71 6.71C18.8983 6.5217 19.0041 6.2663 19.0041 6C19.0041 5.7337 18.8983 5.4783 18.71 5.29C18.5217 5.1017 18.2663 4.99591 18 4.99591C17.7337 4.99591 17.4783 5.1017 17.29 5.29L12 10.59L6.71 5.29C6.5217 5.1017 6.2663 4.99591 6 4.99591C5.7337 4.99591 5.47831 5.1017 5.29 5.29C5.1017 5.4783 4.99591 5.7337 4.99591 6C4.99591 6.2663 5.1017 6.5217 5.29 6.71L10.59 12L5.29 17.29C5.1017 17.4783 4.99591 17.7337 4.99591 18C4.99591 18.2663 5.1017 18.5217 5.29 18.71C5.47831 18.8983 5.7337 19.0041 6 19.0041C6.2663 19.0041 6.5217 18.8983 6.71 18.71L12 13.41L17.29 18.71C17.4783 18.8983 17.7337 19.0041 18 19.0041C18.2663 19.0041 18.5217 18.8983 18.71 18.71C18.8983 18.5217 19.0041 18.2663 19.0041 18C19.0041 17.7337 18.8983 17.4783 18.71 17.29L13.41 12L18.71 6.71Z" })));
};
