/* eslint-disable @typescript-eslint/no-deprecated */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { industryCategoryToIndustryCategoryTitle } from '../../domain';
import { OrganizationLogoWithFallback } from '../OrganizationLogoWithFallback';
import { Text, Caption } from '../../styles';
export const OrganizationGroup = ({ organization: { logoUrl, industryCategory, title }, onClick }) => {
    const { colors } = useContext(ThemeContext);
    return (React.createElement(Container, { onClick: onClick },
        React.createElement(OrganizationLogoWithFallback, { src: logoUrl, alt: title, style: IMAGE_STYLE }),
        React.createElement(Column, null,
            React.createElement(Text, { weight: "800", as: "span", color: colors.dark }, title),
            React.createElement(Caption, { color: colors.grey.dark }, industryCategoryToIndustryCategoryTitle(industryCategory)))));
};
const SPACING = 1;
const IMAGE_STYLE = {
    overflow: 'hidden',
    margin: '0 2rem 0 0',
    width: '50px',
    height: '50px',
};
const Container = styled.div `
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: ${SPACING}rem 0;
  margin-bottom: ${SPACING}rem;
`;
const Column = styled.div `
  display: flex;
  flex-direction: column;
`;
