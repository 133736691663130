/* eslint-disable @typescript-eslint/no-deprecated */

import React, { FunctionComponent } from 'react'
import { Title } from '@tumelo/shared'
import { VimeoModal } from '../../buildingBlocks/VimeoModal'

interface Props {
  open: boolean
  handleModalClose: () => void
}

export const WhereYourPensionIsInvestedVimeoID = 498270720
export const WhereYourPensionIsInvestedVimeoTitle = 'Do you know where your pension is invested?'

export const WhereYourPensionIsInvestedModal: FunctionComponent<Props> = ({ open, handleModalClose }) => {
  return (
    <VimeoModal
      title={WhereYourPensionIsInvestedVimeoTitle}
      video={{ vimeoID: WhereYourPensionIsInvestedVimeoID, title: WhereYourPensionIsInvestedVimeoTitle }}
      open={open}
      handleModalClose={handleModalClose}
      page="WhereYouPensionIsInvestedModal"
      body={<Title>Watch this short video to understand</Title>}
    />
  )
}
