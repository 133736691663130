export type PollResponse = {
  for: boolean
  against: boolean
  none: boolean
  withdrawn: boolean
}

export const getInitialPollResponse = (): PollResponse => ({
  for: false,
  against: false,
  none: false,
  withdrawn: false,
})
