import { CalendarIcon, ChatIcon, LightBulbIcon, MegaphoneIcon, RocketIcon, VoteBoxIcon } from '@tumelo/shared'
import React from 'react'
import { useTheme } from 'styled-components'
import { AppNotificationType } from '../../application/types/AppNotification/AppNotification'

interface Props {
  notificationType: AppNotificationType
}

export const NotificationIcon: React.FC<Props> = ({ notificationType }) => {
  const { colors } = useTheme()
  const props = { fill: colors.secondary.light, height: 22, width: 22 }

  switch (notificationType) {
    case 'caseStudies':
      return <MegaphoneIcon {...props} />
    case 'news':
      return <CalendarIcon {...props} />
    case 'newFeatures':
      return <LightBulbIcon {...props} />
    case 'userResearch':
      return <ChatIcon {...props} />
    case 'impact':
      return <RocketIcon {...props} />
    case 'openVote':
      return <VoteBoxIcon {...props} />
    case 'openVotes':
      return <VoteBoxIcon {...props} />
    case 'voteResult':
      return <VoteBoxIcon {...props} />
    default:
      return <VoteBoxIcon {...props} />
  }
}
