/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { FontWeight } from '@tumelo/shared'
import { BallotWithPollAndOrg } from '../../../application/types/PollWithOrganization/PollWithOrganization'

interface Props {
  polls: BallotWithPollAndOrg[]
  suffix: string
}

export const ListTitle: React.FC<Props> = ({ polls, suffix }) => {
  const { length } = polls
  return (
    <>
      {length} votes:
      <Suffix> {suffix}</Suffix>
    </>
  )
}

const Suffix = styled.span`
  font-weight: ${FontWeight.normal};
`
