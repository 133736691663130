import { createSelector } from '@reduxjs/toolkit'
import { PollTagMap, PollTag, naicsCodeToIndustryCategory, stringToNAICSCode, IndustryCategory } from '@tumelo/shared'
import { PollTags as PollTagsFilter } from '../../types/SearchFilters/PollTags'
import { PollResponse } from '../../types/SearchFilters/PollResponse'
import { RootState } from '../../rootReducer'
import { VoteResult, mapVoteResultOutcome } from '../../types/VoteResult'
import { Selector } from '../../../utils/redux'
import { OrganizationIndustries } from '../../types/SearchFilters'
import { pipe } from '../../../utils/functional/common'

const selectProposalOutcomeFilter = (state: RootState) => state.searchFilters.pollResponse
const selectPollTagFilter = (state: RootState) => state.searchFilters.pollTags
const selectIndustryFilter = (state: RootState) => state.searchFilters.votingOrganizationIndustries

const selectVoteResults = (state: RootState) => state.voteResults.voteResults

const pollTagsFilter =
  (pollTags: PollTagsFilter) =>
  (voteResults: VoteResult[]): VoteResult[] => {
    // If no pollTag Filters set return all vote results
    if (Object.values(pollTags).every((value) => !value)) {
      return voteResults
    }

    // Filter vote results by selected poll tags
    return voteResults.filter((voteResult: VoteResult) => {
      return voteResult.pollTagIds.find((pollTagId) => {
        const tag = PollTagMap.get(pollTagId as PollTag)
        return tag === undefined ? false : pollTags[tag]
      })
    })
  }

const pollResponseFilter =
  (pollResponse: PollResponse) =>
  (voteResults: VoteResult[]): VoteResult[] => {
    // If no pollResponse Filters set return all vote results
    if (Object.values(pollResponse).every((value) => !value)) {
      return voteResults
    }

    // Filter vote results by selected poll response
    return voteResults.filter((voteResult: VoteResult) => {
      const outcome = mapVoteResultOutcome(voteResult.proposalOutcome)
      return outcome === undefined ? false : pollResponse[outcome]
    })
  }

const industryFilter = (industries: OrganizationIndustries) => (voteResults: VoteResult[]) => {
  // If no industry Filters set return all vote results
  if (Object.values(industries).every((value) => !value)) {
    return voteResults
  }

  // Filter vote results by selected industries
  return voteResults.filter((voteResult: VoteResult) => {
    const industry: IndustryCategory = naicsCodeToIndustryCategory(
      stringToNAICSCode(voteResult.organization?.naics2017Code)
    )
    return industry === undefined ? false : industries[industry]
  })
}

export const selectVoteResultsFiltered: Selector<VoteResult[]> = createSelector(
  [selectVoteResults, selectPollTagFilter, selectIndustryFilter, selectProposalOutcomeFilter],
  (voteResults, pollTags, industries, pollResponse) =>
    pipe(voteResults, pollTagsFilter(pollTags), industryFilter(industries), pollResponseFilter(pollResponse))
)
