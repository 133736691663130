import * as React from 'react';
const DEFAULT_IMAGE_STYLE = {
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
    fill: '#e7eaf5',
};
export const Management = ({ style }) => {
    const styleWithDefault = Object.assign(Object.assign({}, DEFAULT_IMAGE_STYLE), style);
    return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "prefix__Management_Pay_Icon", width: "25", height: "25", "data-name": "Management Pay Icon", viewBox: "0 0 25 25" },
        React.createElement("path", { id: "prefix__BG", d: "M18.809 0H6.191A6.256 6.256 0 0 0 0 6.191v12.618A6.2 6.2 0 0 0 6.191 25h12.618A6.2 6.2 0 0 0 25 18.809V6.191A6.256 6.256 0 0 0 18.809 0z", fill: "#e9eee8" }),
        React.createElement("path", { id: "prefix__suitcase", d: "M17.765 2.821h-4.052V1.567A1.565 1.565 0 0 0 12.155 0h-5.61a1.565 1.565 0 0 0-1.558 1.567v1.254H.935a.938.938 0 0 0-.935.94v6.976a.944.944 0 0 0 .623.885v4.362a.938.938 0 0 0 .935.94h15.584a.938.938 0 0 0 .935-.94v-4.362a.944.944 0 0 0 .623-.885V3.761a.938.938 0 0 0-.935-.94zM5.61 1.567a.938.938 0 0 1 .935-.94h5.61a.938.938 0 0 1 .935.94v1.254h-.623V1.567a.313.313 0 0 0-.312-.313h-5.61a.313.313 0 0 0-.312.313v1.254H5.61zm6.233 1.254H6.857V1.88h4.987zm5.61 13.163a.313.313 0 0 1-.312.313H1.558a.313.313 0 0 1-.312-.313V11.73l6.857.951v.8a.938.938 0 0 0 .935.94h.623a.938.938 0 0 0 .935-.94v-.8l6.857-.951zm-7.48-2.507a.313.313 0 0 1-.312.313h-.623a.313.313 0 0 1-.312-.313V11.6a.313.313 0 0 1 .312-.313h.623a.313.313 0 0 1 .312.313zm8.1-2.739a.313.313 0 0 1-.268.311l-.086.012-7.126.988V11.6a.938.938 0 0 0-.935-.94h-.62a.938.938 0 0 0-.935.94v.452l-7.212-1a.313.313 0 0 1-.268-.311v-6.98a.313.313 0 0 1 .312-.313h16.83a.313.313 0 0 1 .312.313zm0 0", transform: "translate(3.5 4.186)", fill: "#2d5e21" })));
};
