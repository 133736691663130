import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../../application/store'
import { useHabitatVotingPolicies, useVotingPolicySelection } from '../../application/features/votingPolicies/hooks'
import { useAccount } from '../../application/features/account/hooks'
import { setVotingPolicyModalOpen } from '../../application/features/votingPolicies'
import { useInvestor } from '../../application/features/investor/useInvestor'
import { deleteVotingPolicySelection } from '../../application/features/votingPolicies/asyncActions'
import { isValueState } from '../../application/payload'
import { selectUpdateVotingPolicySelection } from '../../application/features/votingPolicies/selectors'
import { ManageVotingPolicy } from './ManageVotingPolicy'

export const ManageVotingPolicyConnected: React.FC = () => {
  const dispatch = useAppDispatch()
  const { investor } = useInvestor()
  const account = useAccount(investor)
  const policySelection = useVotingPolicySelection(account)
  const habitatVotingPolicies = useHabitatVotingPolicies()
  const updateVotingPolicySelection = useAppSelector(selectUpdateVotingPolicySelection)

  const deleteVotingPolicy = () => {
    if (!isValueState(policySelection)) {
      throw new Error('Attempted to delete voting policy without selection')
    }

    if (policySelection === undefined) {
      throw new Error('Attempted to delete voting policy when not set')
    }

    dispatch(deleteVotingPolicySelection(policySelection.id))
  }

  return (
    <ManageVotingPolicy
      policySelection={policySelection}
      habitatVotingPolicies={habitatVotingPolicies}
      openVotingPoliciesModal={() => dispatch(setVotingPolicyModalOpen())}
      deleteVotingPolicySelection={deleteVotingPolicy}
      updateVotingPolicySelection={updateVotingPolicySelection}
    />
  )
}
