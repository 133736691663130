import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationBreakdown } from '@tumelo/shared'
import { Payload } from '../../payload'

interface State {
  topFive: Payload<OrganizationBreakdown>
}

const initialState: State = {
  topFive: 'not-initialised',
}

const topOrganizationsSlice = createSlice({
  name: 'top organizations',
  initialState,
  reducers: {
    setTopFive: (state, action: PayloadAction<OrganizationBreakdown>) => {
      state.topFive = action.payload
    },
    setTopFivePending: (state) => {
      state.topFive = 'pending'
    },
    setTopFiveError: (state) => {
      state.topFive = 'error'
    },
  },
})

export const { setTopFive, setTopFivePending, setTopFiveError } = topOrganizationsSlice.actions

export default topOrganizationsSlice.reducer
