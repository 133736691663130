/* eslint-disable @typescript-eslint/no-deprecated */

/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { RadioGroup } from '@mui/material'
import { Body, ModelPortfolio, ModelPortfolioId, RadioItem } from '@tumelo/shared'
import {
  ListQuestion as ListQuestionType,
  ListQuestionAll as ListQuestionAllType,
} from '../../../config/SoftConfigPortfolioSelection'

export const NoneOfTheAbove = 'NoneOfTheAbove'

interface ListQuestionBaseProps {
  id: string
  questionText: string
  modelPortfolios: ModelPortfolio[]
  value: string
  onChange: (e: React.ChangeEvent<any>) => void
  showNoneOfTheAbove: boolean
}

const ListQuestionBase: React.FC<ListQuestionBaseProps> = ({
  id,
  questionText,
  modelPortfolios,
  value,
  onChange,
  showNoneOfTheAbove,
}) => (
  <div style={{ marginBottom: '4rem' }}>
    <Body id={id}>{questionText}</Body>
    <RadioGroup name={id} aria-labelledby={id} style={{ gap: '3rem' }} value={value} onChange={onChange}>
      {modelPortfolios.map((m) => {
        return <RadioItem key={m.id} label={m.title} value={m.id} />
      })}
      {showNoneOfTheAbove && <RadioItem label="None of the above" value={NoneOfTheAbove} />}
    </RadioGroup>
  </div>
)

interface ListQuestionProps {
  question: ListQuestionType
  value: string
  modelPortfolioIdToName: { [key: string]: string }
  onChange: (e: React.ChangeEvent<any>) => void
  showNoneOfTheAbove: boolean
}

export const ListQuestion: React.FC<ListQuestionProps> = ({
  question,
  modelPortfolioIdToName,
  value,
  onChange,
  showNoneOfTheAbove,
}) => {
  const { modelPortfolioIds, questionText, id } = question
  const modelPortfolios: ModelPortfolio[] = modelPortfolioIds.map((mpid) => ({
    id: mpid as ModelPortfolioId,
    title: modelPortfolioIdToName[mpid],
  }))
  return (
    <ListQuestionBase
      id={id}
      questionText={questionText}
      modelPortfolios={modelPortfolios}
      value={value}
      onChange={onChange}
      showNoneOfTheAbove={showNoneOfTheAbove}
    />
  )
}

interface ListQuestionAllProps {
  question: ListQuestionAllType
  value: string
  modelPortfolios: ModelPortfolio[]
  onChange: (e: React.ChangeEvent<any>) => void
  showNoneOfTheAbove: boolean
}

export const ListQuestionAll: React.FC<ListQuestionAllProps> = ({
  question,
  value,
  modelPortfolios,
  onChange,
  showNoneOfTheAbove,
}) => (
  <ListQuestionBase
    id={question.id}
    questionText={question.questionText}
    modelPortfolios={modelPortfolios}
    value={value}
    onChange={onChange}
    showNoneOfTheAbove={showNoneOfTheAbove}
  />
)
