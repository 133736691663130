import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgAbstain = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 22, height: 22, viewBox: "0 0 22 22", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11 2C6.03 2 2 6.03 2 11C2 15.97 6.03 20 11 20C15.97 20 20 15.97 20 11C20 6.03 15.97 2 11 2ZM0 11C0 4.92 4.92 0 11 0C17.08 0 22 4.92 22 11C22 17.08 17.08 22 11 22C4.92 22 0 17.08 0 11ZM10 12H5.34C4.79 12 4.34 11.55 4.34 11C4.34 10.45 4.79 10 5.34 10H16.65C17.2 10 17.65 10.45 17.65 11C17.65 11.55 17.2 12 16.65 12H9.99H10Z" })));
};
