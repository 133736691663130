/* eslint-disable @typescript-eslint/no-deprecated */
import * as React from 'react';
import styled from 'styled-components';
import { Formatting } from '../../utils';
import { OrganizationLogoWithFallback } from '../OrganizationLogoWithFallback';
import { VoteBox } from '../VoteBox';
import { Breakpoint } from '../../styles';
const MOB_WIDTH = '350';
export const ListItem = ({ logoUrl, text, percentage, containerStyle, onClick, votesAvailable = false, adornment, }) => {
    return (React.createElement(CompanyItemContainer, { style: containerStyle, onClick: onClick ? () => onClick() : undefined, clickable: !!onClick },
        React.createElement(FlexBox, null,
            React.createElement("div", { style: {
                    alignSelf: 'stretch',
                    minWidth: '0.55rem',
                } }),
            React.createElement("div", { style: {
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                    padding: '1rem 0',
                } },
                React.createElement(OrganizationLogoWithFallback, { src: logoUrl, alt: text, style: IMAGE_STYLE }),
                React.createElement(Text, { style: votesAvailable ? { fontWeight: 'bold' } : undefined }, text),
                votesAvailable ? React.createElement(VoteBox, { style: { width: '2.8rem', height: '2.8rem', margin: '0.8rem' } }) : null,
                percentage != null ? (React.createElement(Percentage, { style: { justifySelf: 'flex-end' } }, Formatting.percentage(percentage))) : null,
                adornment != null ? adornment : null))));
};
const IMAGE_STYLE = {
    margin: '5px 5px 5px 10px',
};
const CompanyItemContainer = styled.div `
  border-bottom: ${({ theme }) => theme.colors.grey.medium} 1px solid;
  color: ${({ theme }) => theme.colors.dark};
  cursor: ${(props) => (props.clickable ? 'pointer' : 'inherit')};
  flex: 0 0 30%;
  @media (max-width: ${Breakpoint.tablet}) {
    width: ${MOB_WIDTH} !important;
    flex: 100% !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.medium};
    font-weight: bold;
  }
`;
const FlexBox = styled.div `
  display: flex;
  align-items: center;
`;
const Text = styled.div `
  margin-left: 0.5rem;
  margin-right: 5px;
  flex-grow: 1;
  font-size: 1.4rem;
`;
const Percentage = styled.div `
  font-weight: bold;
  font-size: 1.7rem;
  margin-right: 1.5rem;
`;
