import * as yup from 'yup'
import {
  MarkdownString,
  Organization,
  Tag,
  Poll as DomainPoll,
  ProposalId,
  dateToTimestamp,
  PollId,
} from '@tumelo/shared'
import { mapOrganization, organizationSchema } from '../../Organization/OrganizationServiceInjected'
import { GeneralMeeting, generalMeetingSchema, mapYupGeneralMeetingToAPI } from './GeneralMeeting'
import { Proposal, proposalSchema, mapProposalYupToAPI } from './Proposal'

interface Tally {
  forCount: number
  againstCount: number
  abstainCount: number
  withholdCount: number
  noActionCount: number
}

interface Poll {
  id: string
  title: string
  formattedDescription: MarkdownString
  closeAt: Date
  publishAt: Date
  tags: Tag[]
  tally: Tally
  relationships: PollRelationships
  teaserText?: MarkdownString
  teaserImage?: Image
  isHotTopic: boolean
  isNewToPlatform: boolean
}

interface Image {
  url: string
  altText: string
}

interface PollRelationships {
  organization: Omit<Organization, 'icb'>
  generalMeeting: GeneralMeeting
  proposal: Proposal
}

export const pollSchema = yup
  .object({
    id: yup.string().required(),
    title: yup.string().required(),
    formattedDescription: yup.string().required(),
    closeAt: yup.date().required(),
    publishAt: yup.date().required(),
    tags: yup
      .array(
        yup
          .object({
            id: yup.string().required(),
            title: yup.string().required(),
          })
          .required()
      )
      .required(),
    relationships: yup
      .object({
        organization: organizationSchema.required(),
        generalMeeting: generalMeetingSchema.required(),
        proposal: proposalSchema.required(),
      })
      .required(),
    isHotTopic: yup.boolean().required(),
    teaserText: yup.string().optional(),
    teaserImage: yup
      .object({
        url: yup.string().optional(),
        altText: yup.string().optional(),
      })
      .optional(),
    isNewToPlatform: yup.boolean().required(),
  })
  .required()

type PollYup = yup.InferType<typeof pollSchema>

export const mapYupPollToAPI = (poll: PollYup): Poll => {
  const {
    id,
    title,
    formattedDescription,
    publishAt,
    closeAt,
    tags,
    relationships,
    isHotTopic,
    teaserImage,
    teaserText,
    isNewToPlatform,
  } = poll
  return {
    id,
    title,
    // TODO at Date
    closeAt: closeAt as Date,
    publishAt: publishAt as Date,
    tags,
    formattedDescription,
    relationships: {
      ...relationships,
      generalMeeting: mapYupGeneralMeetingToAPI(relationships.generalMeeting),
      proposal: mapProposalYupToAPI(relationships.proposal),
      organization: mapOrganization(relationships.organization),
    },
    tally: { againstCount: 0, forCount: 0, abstainCount: 0, withholdCount: 0, noActionCount: 0 },
    isHotTopic,
    teaserImage:
      teaserImage.url && teaserImage.altText ? { url: teaserImage.url, altText: teaserImage.altText } : undefined,
    teaserText,
    isNewToPlatform,
  }
}

export const mapAPIPollToDomain = (poll: Poll): DomainPoll => {
  return {
    id: poll.id as PollId,
    title: poll.title,
    formattedDescription: poll.formattedDescription,
    endDate: dateToTimestamp(poll.closeAt),
    tags: poll.tags,
    tally: {
      forCount: poll.tally.forCount,
      againstCount: poll.tally.againstCount,
      abstainCount: poll.tally.abstainCount,
      withholdCount: poll.tally.withholdCount,
      noActionCount: poll.tally.noActionCount,
    },
    isHotTopic: poll.isHotTopic,
    teaserText: poll.teaserText,
    teaserImage: poll.teaserImage,
    publishAt: dateToTimestamp(poll.publishAt),
    isNewToPlatform: poll.isNewToPlatform,
    relationships: {
      proposal: {
        id: poll.relationships.proposal.id as ProposalId,
        title: poll.relationships.proposal.title,
        formattedDescription: poll.relationships.proposal.formattedDescription,
        outcome: poll.relationships.proposal.outcome,
        votingOptions: poll.relationships.proposal.votingOptions,
      },
      organization: poll.relationships.organization,
      generalMeeting: {
        date: dateToTimestamp(poll.relationships.generalMeeting.date),
        proxyNoticeUrl: poll.relationships.generalMeeting.proxyNoticeUrl ?? '',
        organizationId: poll.relationships.organization.id,
        type: poll.relationships.generalMeeting.generalMeetingType,
      },
    },
  }
}
