import { dateToTimestamp, IndustryCategory, sampleOrganization } from '../../domain';
export const sampleOrganizationBreakdown = () => {
    return {
        breakdownBasedOn: {
            instruments: [
                { isin: '123', validAt: dateToTimestamp(new Date('2013-02-08')) },
                { isin: '123', validAt: dateToTimestamp(new Date('2013-02-10')) },
            ],
        },
        organizationEntries: [
            {
                organization: sampleOrganization({ id: '1' }),
                percentageWeight: 1,
            },
            {
                organization: sampleOrganization({ id: '2' }),
                percentageWeight: 2,
            },
            {
                organization: sampleOrganization({ id: '3' }),
                percentageWeight: 3,
            },
            {
                organization: sampleOrganization({ id: '4' }),
                percentageWeight: 4,
            },
            {
                organization: sampleOrganization({ id: '5' }),
                percentageWeight: 5,
            },
            {
                organization: sampleOrganization({ id: '6' }),
                percentageWeight: 6,
            },
            {
                organization: sampleOrganization({ id: '7' }),
                percentageWeight: 7,
            },
            {
                organization: sampleOrganization({ id: '8' }),
                percentageWeight: 8,
            },
            {
                organization: sampleOrganization({ id: '9' }),
                percentageWeight: 9,
            },
            {
                organization: sampleOrganization({ id: '10' }),
                percentageWeight: 10,
            },
            {
                organization: sampleOrganization({ id: '11' }),
                percentageWeight: 11,
            },
            {
                organization: sampleOrganization({ id: '12' }),
                percentageWeight: 12,
            },
            {
                organization: sampleOrganization({ id: '13' }),
                percentageWeight: 13,
            },
            {
                organization: sampleOrganization({ id: '14' }),
                percentageWeight: 14,
            },
            {
                organization: sampleOrganization({ id: '15' }),
                percentageWeight: 15,
            },
            {
                organization: {
                    id: '16',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 1',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 16,
            },
            {
                organization: {
                    id: '17',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 2',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 17,
            },
            {
                organization: {
                    id: '18',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 3',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 18,
            },
            {
                organization: {
                    id: '19',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 4',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 19,
            },
            {
                organization: {
                    id: '20',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 5',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 20,
            },
            {
                organization: {
                    id: '21',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 6',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 21,
            },
            {
                organization: {
                    id: '22',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 7',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 22,
            },
            {
                organization: {
                    id: '23',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 8',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 23,
            },
            {
                organization: {
                    id: '24',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 9',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 24,
            },
            {
                organization: {
                    id: '25',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 10',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 25,
            },
            {
                organization: {
                    id: '26',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 11',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 26,
            },
            {
                organization: {
                    id: '27',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 12',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 27,
            },
            {
                organization: {
                    id: '28',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 13',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 28,
            },
            {
                organization: {
                    id: '29',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 14',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 29,
            },
            {
                organization: {
                    id: '30',
                    logoUrl: 'https://logo.clearbit.com/roche.com',
                    title: 'Coca Cola 15',
                    industryCategory: IndustryCategory.UNKNOWN,
                },
                percentageWeight: 30,
            },
        ],
        cash: [
            {
                currency: 'GBP',
                weight: 0.0001,
            },
            {
                currency: 'USD',
                weight: 0.00001,
            },
        ],
        others: 0.4,
    };
};
export const sampleTopFiveBreakdown = () => {
    return {
        breakdownBasedOn: {
            instruments: [
                { isin: '123', validAt: dateToTimestamp(new Date('2013-02-08')) },
                { isin: '123', validAt: dateToTimestamp(new Date('2013-02-10')) },
            ],
        },
        organizationEntries: [
            {
                organization: sampleOrganization({ id: '11' }),
                percentageWeight: 11,
            },
            {
                organization: sampleOrganization({ id: '8' }),
                percentageWeight: 8,
            },
            {
                organization: sampleOrganization({ id: '7' }),
                percentageWeight: 7,
            },
            {
                organization: sampleOrganization({ id: '6' }),
                percentageWeight: 6,
            },
            {
                organization: sampleOrganization({ id: '5' }),
                percentageWeight: 5,
            },
        ],
        others: 0.4,
    };
};
