import * as React from 'react';
const DEFAULT_IMAGE_STYLE = {
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
    fill: '#e7eaf5',
};
export const DefenceSecurity = ({ style }) => {
    const styleWithDefault = Object.assign(Object.assign({}, DEFAULT_IMAGE_STYLE), style);
    return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "prefix__Defence_Security_Icon", width: "25", height: "25", "data-name": "Defence & Security Icon", viewBox: "0 0 25 25" },
        React.createElement("path", { id: "prefix__BG", d: "M18.809 0H6.191A6.256 6.256 0 0 0 0 6.191v12.618A6.2 6.2 0 0 0 6.191 25h12.618A6.2 6.2 0 0 0 25 18.809V6.191A6.256 6.256 0 0 0 18.809 0z", fill: "#e7eaf5" }),
        React.createElement("g", { id: "prefix__Group_11110", "data-name": "Group 11110", transform: "translate(-74.445 -384.741)" },
            React.createElement("path", { id: "prefix__Path_9821", d: "M78.465 399.661c.143-.91.215-1.827.356-2.737.309-1.82.8-3.84-.519-5.4a.5.5 0 0 1 .05-.741q1.46-1.464 2.926-2.922a.5.5 0 0 1 .848.085c.693 1.094 2 2.186 3.275 1.284a5.241 5.241 0 0 0 1.29-1.194c.324-.405.659-.4.989 0a5.406 5.406 0 0 0 1.294 1.189 1.763 1.763 0 0 0 2.145-.059 4.86 4.86 0 0 0 1.1-1.193.521.521 0 0 1 .908-.083q1.443 1.433 2.878 2.875a.509.509 0 0 1 .057.759c-1.542 1.859-.652 4.244-.332 6.361a6.34 6.34 0 0 1-1.713 6.178 11.54 11.54 0 0 1-3.21 2.164c-1.1.51-2.233.945-3.36 1.392a.842.842 0 0 1-.533 0c-3.659-1.276-8.557-3.41-8.449-7.962zm.928-8.466a6.754 6.754 0 0 1 .639 4.747c-.3 2.054-1.122 4.276.058 6.206 1.524 2.438 4.4 3.5 6.976 4.469a.439.439 0 0 0 .276-.011c3.349-1.224 8.066-3.172 7.518-7.493-.27-2.576-1.48-5.5.153-7.849l-2.241-2.253c-1.821 2.082-3.71 2.082-5.573.047-1.877 1.957-3.814 2.12-5.581-.08z", fill: "#1731a0", "data-name": "Path 9821" }),
            React.createElement("path", { id: "prefix__Path_9822", d: "M130.1 454.163c.449-3.053.886-2.008-1.335-4.22a.519.519 0 0 1 .319-.922c.736-.108 1.472-.219 2.21-.315a.359.359 0 0 0 .313-.233c.319-.664.65-1.322.973-1.984a.53.53 0 0 1 .986-.006c.326.669.659 1.334.983 2a.344.344 0 0 0 .3.217c.745.1 1.488.209 2.232.319a.521.521 0 0 1 .3.932c-.521.5-1.03 1.013-1.557 1.507a.47.47 0 0 0-.153.488c.133.709.245 1.422.369 2.133a.515.515 0 0 1-.789.57c-.663-.354-1.332-.7-1.995-1.052a.325.325 0 0 0-.349 0q-1.023.549-2.056 1.08a.5.5 0 0 1-.751-.514zm.1-4.273c1.754 1.863 1.442.91 1.075 3.432 2.2-1.13 1.412-1.12 3.6 0-.409-2.489-.6-1.667 1.1-3.429-2.425-.384-1.8.066-2.9-2.115-1.128 2.145-.424 1.722-2.875 2.112z", fill: "#1731a0", "data-name": "Path 9822", transform: "translate(-45.894 -53.25)" }))));
};
