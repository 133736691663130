import { confirmUserAttribute } from 'aws-amplify/auth'
import { dateToTimestamp } from '@tumelo/shared'
import { isValueState } from '../../payload'
import { AppThunk } from '../../store'
import { tryExecute } from '../../asyncActions'
import { InvestorExtendedProfile } from '../../types/InvestorExtendedProfile/InvestorExtendedProfile'
import { AuthErrorType, logUnexpectedError, parseError } from '../auth/errors'
import * as investorActions from './index'

export const fetchInvestorAndPushTracking =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { investorService, loggerService, investorExtendedProfileService } = services
    const tryExecuteL = tryExecute(loggerService)

    dispatch(investorActions.setInvestor('pending'))
    dispatch(investorActions.setInvestorExtendedProfile('pending'))
    const [investor, profile] = await Promise.all([
      // "parallel" execution with independent error code propagation
      tryExecuteL(() => investorService.getInvestor()),
      tryExecuteL(() => investorExtendedProfileService.getProfile()),
    ])
    dispatch(investorActions.setInvestor(investor))
    if (isValueState(profile)) {
      const now = dateToTimestamp(new Date()) // important to use same ref for now because you can determine first time user from firstActive === lastActive
      const firstActive = profile.tracking.firstActive ?? now
      const profileTagged: InvestorExtendedProfile = { ...profile, tracking: { firstActive, lastActive: now } }
      dispatch(pushInvestorExtendedProfile(profileTagged))
    } else dispatch(investorActions.setInvestorExtendedProfile(profile))
  }

const pushInvestorExtendedProfile =
  (nextProfile: InvestorExtendedProfile): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { loggerService, investorExtendedProfileService } = services
    const tryExecuteL = tryExecute(loggerService)
    dispatch(investorActions.setInvestorExtendedProfile('pending'))
    await tryExecuteL(() => investorExtendedProfileService.saveProfile(nextProfile))
    dispatch(investorActions.setInvestorExtendedProfile(nextProfile))
  }

export const setTermsAndConditionsAccepted =
  (accepted: boolean): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService } = services
    try {
      dispatch(investorActions.setTermsAndConditionsAccepted('pending'))
      const resp = await dashboardBffService.UpdateTermsAndConditionsConsent(accepted)
      dispatch(investorActions.setTermsAndConditionsAccepted(resp.accepted))
    } catch (e) {
      services.loggerService.logError(e)
      dispatch(investorActions.setTermsAndConditionsAccepted('error'))
    }
  }

export const fetchTermsAndConditionsAccepted =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService } = services
    try {
      dispatch(investorActions.setTermsAndConditionsAccepted('pending'))
      const { accepted } = await dashboardBffService.GetTermsAndConditionsConsent()
      dispatch(investorActions.setTermsAndConditionsAccepted(accepted))
    } catch (e) {
      services.loggerService.logError(e)
      dispatch(investorActions.setTermsAndConditionsAccepted('error'))
    }
  }

export const updateInvestorEmail =
  (email: string): AppThunk =>
  async (dispatch, _, { services }) => {
    const { investorService, loggerService } = services
    try {
      dispatch(investorActions.setInvestor('pending'))
      await investorService.updateInvestorEmail(email)
      const investor = await investorService.getInvestor()
      dispatch(investorActions.setInvestor(investor))
    } catch (e) {
      const investor = await investorService.getInvestor()
      const authError = parseError(e)
      dispatch(
        investorActions.setInvestor({
          ...investor,
          changeEmail: { ...investor.changeEmail, error: authError.message },
        })
      )
      loggerService.logError(e)
    }
  }

export const updateInvestorNickname =
  (nickname: string): AppThunk =>
  async (dispatch, _, { services }) => {
    const { investorService, loggerService } = services
    try {
      dispatch(investorActions.setNicknameUpdateState('pending'))
      const investor = await investorService.updateInvestorNickname(nickname)
      dispatch(investorActions.setInvestor(investor))
      dispatch(investorActions.setNicknameUpdateState('not-updating'))
    } catch (err) {
      loggerService.logError(err)
      dispatch(investorActions.setNicknameUpdateState('error'))
    }
  }

export const ConfirmInvestorEmailWithCode =
  (code: string): AppThunk =>
  async (dispatch, _, { services }) => {
    const { investorService, loggerService } = services
    try {
      dispatch(investorActions.setInvestor('pending'))
      await confirmUserAttribute({ userAttributeKey: 'email', confirmationCode: code })

      const investor = await investorService.getInvestor()
      dispatch(investorActions.setInvestor(investor))
    } catch (err) {
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.CodeMismatchException,
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
      ])

      const investor = await investorService.getInvestor()
      dispatch(
        investorActions.setInvestor({ ...investor, changeEmail: { ...investor.changeEmail, error: authError.message } })
      )
    }
  }
