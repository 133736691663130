import React, { useEffect } from 'react'
import { useAnalytics } from '../../application/analytics'
import { buildEvent } from '../../application/analytics/eventBuilders'
import { CustomContentCard, CustomContentCardProps } from './CustomContentCard'

type CustomContentCardPropsConnected = Omit<CustomContentCardProps, 'onCtaClick'>

export const CustomContentCardConnected: React.FC<CustomContentCardPropsConnected> = (props) => {
  const { trackEvent } = useAnalytics()
  const { title, body, backgroundColor, callToAction } = props
  const targetUrl = callToAction?.targetUrl

  useEffect(() => {
    trackEvent(buildEvent.returningCardRendered({ card_type: 'custom-content-card', card_title: title, to: targetUrl }))
  }, [trackEvent, title, targetUrl])

  const trackCtaClick = () => {
    trackEvent(
      buildEvent.returningCardCTAClicked({ card_type: 'custom-content-card', card_title: title, to: targetUrl })
    )
  }

  return (
    <CustomContentCard
      title={title}
      body={body}
      backgroundColor={backgroundColor}
      callToAction={callToAction}
      onCtaClick={trackCtaClick}
    />
  )
}
