import React from 'react'
import styled from 'styled-components'
import { Organization, OrganizationGroup, Tag } from '@tumelo/shared'
import Tags from './Tags'

interface Props {
  organization: Organization
  onClick: () => void
  tags: Tag[]
}

const HeaderSection: React.FC<Props> = ({ organization, onClick, tags }) => (
  <Header>
    <OrganizationGroup organization={organization} onClick={onClick} />
    <Tags tags={tags} />
  </Header>
)

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

export default HeaderSection
