import { AppThunk } from '../../store'
import { AppNotification, ResolutionType } from '../../types/AppNotification/AppNotification'
import { isValueState } from '../../payload'
import { selectNotifications } from './selectors'
import * as notificationsActions from '.'

export const listNotifications =
  (pageToken: string): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService, loggerService } = services
    if (!isValueState(pageToken)) {
      throw new Error('attempted to list notifications without a value state page token')
    }
    try {
      const { notifications, nextPageToken } = await dashboardBffService.ListNotifications(pageToken)
      dispatch(notificationsActions.addNotifications({ notifications, nextPageToken }))
    } catch (e) {
      loggerService.logError(e)
      dispatch(notificationsActions.setNotificationsAndNextPageTokenError())
    }
  }

export const updateNotificationResolution =
  (notification: AppNotification, resolution: ResolutionType): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService, loggerService } = services
    if (notification.source === 'platform-internal') {
      try {
        const respResolution = await dashboardBffService.UpdateNotificationResolution(notification.id, resolution)
        dispatch(notificationsActions.resolveNotification({ id: notification.id, resolution: respResolution }))
      } catch (e) {
        loggerService.logError(e)
      }
    } else {
      dispatch(notificationsActions.resolveNotification({ id: notification.id, resolution }))
    }
  }

export const resolveAllNotifications = (): AppThunk => async (dispatch, getState) => {
  const state = getState()
  const notifications = selectNotifications(state)
  if (!isValueState(notifications)) {
    throw new Error('attempted to resolve notifications before set in the store')
  }

  await Promise.all(
    notifications.map(async (n) => {
      dispatch(updateNotificationResolution(n, 'dismissed'))
    })
  )
}
