import React from 'react';
import styled from 'styled-components';
import { Body2, Caption, DefaultBoxShadow, H3 } from '..';
// TODO: MD & LG layout
// TODO: cattToAction button
export const FeaturedMediaCard = ({ preTitle, title, titleAs = 'h3', description, LargeImgUrl, footer, }) => {
    return (React.createElement(StyledCard, null,
        LargeImgUrl && React.createElement(StyledLargeImg, { src: LargeImgUrl, alt: "" }),
        React.createElement(StyledSection, null,
            preTitle && (React.createElement(Caption, { semibold: true, mb: "1.6rem" }, preTitle)),
            title && React.createElement(H3, { as: titleAs }, title),
            description && (React.createElement(Body2, { semibold: true, mb: "1.6rem" }, description)),
            footer && React.createElement(StyledFooter, null, footer))));
};
const StyledCard = styled.div `
  width: 100%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  ${DefaultBoxShadow}
`;
const StyledSection = styled.div `
  padding: 1.6rem;
`;
const StyledLargeImg = styled.img `
  object-fit: cover;
  object-position: center;
  width: 100%;
`;
const StyledFooter = styled.div `
  padding: 0;
  margin: 0;
`;
