export const industryCategoryWithCount = (breakdown) => {
    const industriesWithCounts = [];
    breakdown.organizationEntries.forEach((orgEntry) => {
        const { industryCategory } = orgEntry.organization;
        const existingItem = industriesWithCounts.find((x) => x.industryCategory === industryCategory);
        if (!existingItem) {
            industriesWithCounts.push({ industryCategory, count: 1 });
        }
        else {
            existingItem.count += 1;
        }
    });
    return industriesWithCounts.sort((a, b) => b.count - a.count);
};
export const groupOrganizationsByIndustry = ({ organizationEntries, }) => {
    // industryBuckets are buckets of ICB industry string to arrays of entries, for no industry use code UNDEFINED_INDUSTRY
    const industryBuckets = new Map();
    organizationEntries.forEach((entry) => {
        const { industryCategory } = entry.organization;
        const array = industryBuckets.get(industryCategory);
        if (array) {
            industryBuckets.set(industryCategory, [...array, entry]);
        }
        else {
            industryBuckets.set(industryCategory, [entry]);
        }
    });
    // turn into array and calculate sums and organize by weight
    const industries = Array.from(industryBuckets.entries()).map(([industryCategory, organizations]) => {
        const totalPercentage = organizations.reduce((sum, breakdown) => {
            return sum + breakdown.percentageWeight;
        }, 0);
        organizations.sort((a, b) => b.percentageWeight - a.percentageWeight);
        return { industryCategory, organizations, totalPercentage };
    });
    // sort
    industries.sort((a, b) => {
        return b.totalPercentage - a.totalPercentage;
    });
    return { industries };
};
export const calculateIndustryOther = ({ cash, others }) => {
    const totalCash = (cash === null || cash === void 0 ? void 0 : cash.reduce((sum, breakdown) => {
        return sum + breakdown.weight;
    }, 0)) || 0;
    const othersTotal = others || 0;
    return totalCash + othersTotal;
};
