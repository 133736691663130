import React from 'react';
// SwipeableDrawer was throwing: Element type is invalid, this seemed to fix it
// eslint-disable-next-line no-restricted-imports
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import styled, { useTheme } from 'styled-components';
import { CloseIcon } from './CloseIcon';
export const SwipeableMenuContainer = ({ isOpen, toggleOpen, backgroundColor, iconColor, children, showCloseIcon = true, }) => {
    const theme = useTheme();
    const paperProps = { style: { backgroundColor: backgroundColor || theme.colors.background.dark } };
    return (React.createElement(SwipeableDrawer, { PaperProps: paperProps, open: isOpen, onClose: toggleOpen, onOpen: toggleOpen },
        React.createElement(Container, { backgroundColor: backgroundColor },
            showCloseIcon && (React.createElement(PositionAbsolute, null,
                React.createElement(CloseIcon, { onClick: toggleOpen, color: iconColor || theme.colors.white }))),
            children)));
};
const Container = styled.div `
  width: 90vw;
  height: 100%;
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.background.dark};
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  padding-top: 1.6rem;
`;
const PositionAbsolute = styled.div `
  position: absolute;
  top: 2rem;
  right: 2rem;
`;
