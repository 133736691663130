import React from 'react'
import { useAppSelector } from '../../application/store'
import { Payload } from '../../application/payload'
import { useTopFiveOrganizations } from '../../application/features/topOrganizations/hooks'
import { selectFeatureSwitches } from '../../application/features/config/selectors'
import { compose } from '../../utils/functional/common'
import { useBreakdown } from '../../application/features/breakdown/hooks'
import { useAccount } from '../../application/features/account/hooks'
import { useInvestor } from '../../application/features/investor/useInvestor'
import { TopHoldings } from './TopHoldings'

interface Props {
  onClickCta: () => void
  onCompanyClick: (id: string) => void
}

export const TopHoldingsContainer: React.FC<Props> = ({ onClickCta, onCompanyClick }) => {
  const { investor } = useInvestor()
  const account = useAccount(investor)
  const breakdown = useBreakdown(account)
  const topFive = useTopFiveOrganizations(breakdown)

  const isMinimal =
    useAppSelector(
      compose(
        selectFeatureSwitches,
        Payload.map((p) => p.dashboardArea.companiesCard.type === 'minimal'),
        Payload.toMaybe
      )
    ) ?? false

  return (
    <TopHoldings breakdown={topFive} onClickCta={onClickCta} onCompanyClick={onCompanyClick} isMinimal={isMinimal} />
  )
}
