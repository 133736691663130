import React from 'react';
import styled from 'styled-components';
import { CancelOutlined } from '@mui/icons-material';
export const CloseIcon = ({ color, onClick }) => (React.createElement(Button, { onClick: onClick },
    React.createElement(CancelOutlined, { style: { fontSize: '2.6rem', color } })));
const Button = styled.button `
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
`;
