import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, ChevronDownIcon, ChevronUpIcon } from '..';
import { TableCell, TableRow } from '.';
export const ExpandableTableRow = ({ rowData, keysToRender, expandableContent, ariaId, isDisabled = false, }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { noBorder: isExpanded },
            React.createElement(TableCell, { "aria-expanded": isExpanded },
                React.createElement(StyledButton, { variant: "tertiary", onClick: () => setIsExpanded(!isExpanded), iconOnly: isExpanded ? React.createElement(ChevronUpIcon, null) : React.createElement(ChevronDownIcon, null), iconOnlyAltText: "Toggle row", isDisabled: isDisabled })),
            keysToRender.map((key) => (React.createElement(TableCell, { key: `${key}-${rowData[key]}` }, rowData[key])))),
        isExpanded && (React.createElement(TableRow, { id: ariaId, "aria-label": "More Information" },
            React.createElement(TableCell, { colSpan: keysToRender.length + 1 }, expandableContent)))));
};
const StyledButton = styled(Button) `
  background: none !important;
  > svg {
    fill: ${({ theme }) => theme.colors.dark};
  }
  &:hover {
    box-shadow: none !important;
  }
`;
