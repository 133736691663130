/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { ReactNode, Suspense, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '../../application/store'
import { setSoftConfig } from '../../application/features/config'
import { selectSoftConfig } from '../../application/features/config/selectors'
import { compose } from '../functional/common'
import { SoftConfigTheme } from '../../config/SoftConfigTheme'
import { SoftConfig, SoftConfigSchema } from '../../config/SoftConfig'

const initialSoftConfig = SoftConfigSchema.parse({})

// It is really important that this remains lazy, as this prevents the editor itself, or any dependent plugins from being included in core bundle
const SoftConfigEditorLazy = React.lazy(() =>
  import('./SoftConfigEditor').then((t) => ({ default: t.SoftConfigEditor }))
)

export const DebugWrapper = ({
  enable,
  children,
}: {
  enable: boolean
  children: (cfg: SoftConfigTheme) => ReactNode
}) => {
  const softConfig = useAppSelector(selectSoftConfig) as SoftConfig
  const dispatch = useAppDispatch()
  const setSoftConfigS = compose(setSoftConfig, dispatch)
  const [isOpen, setIsOpen] = useState(false)

  // All the mess of code below is basically to allow "ctrl/cmd + q" to toggle the overlay open/closed state
  useEffect(() => {
    let ctrlDown = false
    const ctrlKey = 17
    const yKey = 89

    const keyDownHandler = (e: any) => {
      if (e.keyCode === ctrlKey) ctrlDown = true
      if (ctrlDown && e.keyCode === yKey) setIsOpen(!isOpen)
    }
    const keyUpHandler = (e: any) => {
      if (e.keyCode === ctrlKey) ctrlDown = false
    }

    if (window && enable) {
      window.addEventListener('keydown', keyDownHandler)
      window.addEventListener('keyup', keyUpHandler)

      return () => {
        window.removeEventListener('keydown', keyDownHandler)
        window.removeEventListener('keyup', keyUpHandler)
      }
    }
    return undefined
  }, [enable, isOpen])
  return (
    <>
      {enable && isOpen ? (
        <FloatingDebugPanel>
          <PanelHeader onClick={() => setIsOpen(false)}>Customize your experience</PanelHeader>
          <Suspense fallback={<div>loading</div>}>
            <SoftConfigEditorLazy defaultValue={initialSoftConfig} value={softConfig} onChange={setSoftConfigS} />
          </Suspense>
        </FloatingDebugPanel>
      ) : null}
      {children(softConfig.theme)}
    </>
  )
}

const PanelHeader = styled.div`
  font-size: 2.4rem;
  height: 3rem;
  background-color: lightblue;
  cursor: pointer;
`

const FloatingDebugPanel = styled.div`
  background-color: white;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 65rem;
  height: 30rem;
  overflow-y: scroll;
  z-index: 100;
  box-shadow: 0 3px 12px 0;
  font-size: 1.3rem;
`
