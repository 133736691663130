import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { Organization } from '@tumelo/shared'

export const organizationAdapter = createEntityAdapter<Organization>({
  selectId: (response) => response.id,
  sortComparer: (a, b) => Number(a.id > b.id),
})

type AreaState = {
  organizations: EntityState<Organization>
  userVisitedOrgLetters: string[]
}

const getInitialState = (): AreaState => {
  return { organizations: organizationAdapter.getInitialState(), userVisitedOrgLetters: [] }
}

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: getInitialState(),
  reducers: {
    addMany: (state, item: PayloadAction<Organization[]>) => {
      state.organizations = organizationAdapter.addMany(state.organizations, item)
    },
  },
})

export default organizationsSlice.reducer

export const { addMany } = organizationsSlice.actions
