import * as yup from 'yup'
import { ProposalOutcome, MarkdownString } from '@tumelo/shared'

export interface Proposal {
  id: string
  sequenceIdentifier: string
  title: string
  formattedDescription?: MarkdownString
  source: ProposalSource
  managementRecommendation: 'for' | 'against' | 'none'
  managementRecommendationDescription?: MarkdownString
  passThreshold: number
  outcome?: ProposalOutcome
  votingOptions?: VotingOption[]
}

enum ProposalSource {
  management = 'management',
  shareholder = 'shareholder',
}

const proposalOutcomeSchema = yup
  .object({
    decision: yup
      .mixed<'for' | 'against'>()
      .oneOf(Object.values(['for', 'against']))
      .required(),
    votes: yup
      .object({
        for: yup.number().notRequired(),
        against: yup.number().notRequired(),
        abstain: yup.number().notRequired(),
        brokerNonVote: yup.number().notRequired(),
      })
      .notRequired(),
    createTime: yup.date().notRequired(),
  })
  .notRequired()
  .default(undefined)

enum VotingOption {
  For = 'for',
  Against = 'against',
  Abstain = 'abstain',
  Withhold = 'withhold',
  NoAction = 'no_action',
}

export const proposalSchema = yup
  .object({
    id: yup.string().required(),
    sequenceIdentifier: yup.string().required(),
    title: yup.string().required(),
    formattedDescription: yup.string().notRequired(),
    source: yup.mixed<keyof typeof ProposalSource>().oneOf(Object.values(ProposalSource)).required(),
    managementRecommendation: yup
      .mixed<'for' | 'against' | 'none'>()
      .oneOf(Object.values(['for', 'against', 'none']))
      .required(),
    managementRecommendationDescription: yup.string().notRequired(),
    passThreshold: yup.number().moreThan(0).lessThan(1.0).required(),
    outcome: proposalOutcomeSchema.notRequired(),
    votingOptions: yup.array(yup.mixed<VotingOption>().oneOf(Object.values(VotingOption)).required()),
  })
  .required()

type ProposalYup = yup.InferType<typeof proposalSchema>

export const mapProposalYupToAPI = (py: ProposalYup): Proposal => {
  const outcome = py.outcome
    ? {
        ...py.outcome,
        decision: py.outcome.decision,
      }
    : undefined
  return {
    ...py,
    source: ProposalSource[py.source],
    managementRecommendation: py.managementRecommendation,
    outcome,
    votingOptions: py.votingOptions,
  }
}
