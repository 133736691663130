import styled from 'styled-components';
/**
 * Button styled like a link
 */
export const LinkButton = styled.button `
  color: ${({ theme }) => theme.colors.secondary.light};
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
