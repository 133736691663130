import { Investor } from '@tumelo/shared'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { isErrorStates, isIntermediaryState, isValueState, Payload } from '../../payload'
import { selectSkipTermsAndConditionsConsentSSO } from '../config/selectors'
import { fetchTermsAndConditionsAccepted, setTermsAndConditionsAccepted } from './asyncActions'
import { selectTermsAndConditionsAccepted } from './investorSelector'

interface TermsAndConditions {
  accepted: boolean
  showTermsAcceptanceModal: boolean
  acceptTermsAndConditions: () => void
}

export const useTermsAndConditions = (investor: Payload<Investor>): Payload<TermsAndConditions> => {
  const dispatch = useAppDispatch()
  const termsAndConditionsAccepted = useAppSelector(selectTermsAndConditionsAccepted)
  const skipTermsAndConditionsConsentSSO = useAppSelector(selectSkipTermsAndConditionsConsentSSO)

  useEffect(() => {
    if (termsAndConditionsAccepted === 'not-initialised') {
      dispatch(fetchTermsAndConditionsAccepted())
    }

    // A investor may have already accepted T&Cs in account creation process OR
    // on a providers own website.
    const termsAlreadyAccepted = (investor: Investor) => {
      return !investor.signedInWithSSO || skipTermsAndConditionsConsentSSO
    }

    if (isValueState(investor) && isValueState(termsAndConditionsAccepted) && termsAndConditionsAccepted === false) {
      if (termsAlreadyAccepted(investor)) {
        dispatch(setTermsAndConditionsAccepted(true))
      }
    }
  }, [investor, termsAndConditionsAccepted, skipTermsAndConditionsConsentSSO, dispatch])

  if (isErrorStates(investor) || isErrorStates(termsAndConditionsAccepted)) {
    return 'error'
  }

  if (isIntermediaryState(investor) || isIntermediaryState(termsAndConditionsAccepted)) {
    return 'pending'
  }

  return {
    accepted: termsAndConditionsAccepted,
    showTermsAcceptanceModal:
      !termsAndConditionsAccepted && investor.signedInWithSSO && !skipTermsAndConditionsConsentSSO,
    acceptTermsAndConditions: () => dispatch(setTermsAndConditionsAccepted(true)),
  }
}
