import React, { FC, ReactElement } from 'react'
import styled from 'styled-components'
import { VimeoVideo, Modal } from '@tumelo/shared'
import { ExplicitPageView } from '../../application/analytics'

interface Props {
  body: ReactElement
  handleModalClose: () => void
  open: boolean
  title: string
  video: {
    title: string
    vimeoID: number
  }
  page?: string
}

export const VimeoModal: FC<Props> = ({ body, handleModalClose, open, title, video, page }) => {
  return (
    <Modal title={title} open={open} onClose={handleModalClose} width="medium">
      {page && <ExplicitPageView page={page} />}
      <VideoContainer>
        <VimeoVideo title={video.title} vimeoID={video.vimeoID} />
      </VideoContainer>
      <ModalBody>{body}</ModalBody>
    </Modal>
  )
}

const VideoContainer = styled.div`
  padding: 0 4rem;
`

const ModalBody = styled.div`
  color: ${({ theme }) => theme.colors.primary.medium};
`
