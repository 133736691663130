import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { VoteBoxIcon, isPollEndingSoon, Poll, SearchMenuLayout, NoResultsMessage, SandTimerIcon } from '@tumelo/shared'
import { BallotWithPollAndOrg } from '../../../application/types/PollWithOrganization/PollWithOrganization'
import { PageHeader } from '../../PageHeader'
import { LoadingSkeleton } from '../../Cardlet/LoadingSkeleton'
import { NewPollsList } from '../../PollsList/NewPollsList'
import { PollsList } from '../../PollsList/PollsList'
import { HotTopicContainer } from '../../PollsList/HotTopicContainer'
import VotingFilterMenu from '../../Layout/SearchMenu/menus/VotingFilterMenu'
import { AwaitingResultsList } from '../../PollsList/AwaitingResultsList'
import { RouterLink } from '../../../styles/fonts'

interface Props {
  polls: BallotWithPollAndOrg[]
  awaitingResults: BallotWithPollAndOrg[]
  newPolls?: BallotWithPollAndOrg[]
  hotTopics?: BallotWithPollAndOrg[]
  resetAllFilters: () => void
}

const OpenVotes: React.FC<Props> = ({ polls, awaitingResults, newPolls, hotTopics, resetAllFilters }) => {
  return (
    <FilterContainer>
      <VotesSection
        polls={polls}
        awaitingResults={awaitingResults}
        hotTopics={hotTopics}
        newPolls={newPolls}
        resetAllFilters={resetAllFilters}
      />
    </FilterContainer>
  )
}

export const OpenVotesLoading: React.FC = () => (
  <FilterContainer>
    <LoadingSkeleton />
  </FilterContainer>
)

interface VotesSectionProps {
  polls: BallotWithPollAndOrg[]
  awaitingResults: BallotWithPollAndOrg[]
  newPolls?: BallotWithPollAndOrg[]
  hotTopics?: BallotWithPollAndOrg[]
  resetAllFilters: () => void
}

const VotesSection: React.FC<VotesSectionProps> = ({
  polls,
  awaitingResults,
  newPolls,
  hotTopics,
  resetAllFilters,
}) => {
  let visibleNewPolls: BallotWithPollAndOrg[] | undefined
  const visibleHotTopic = hotTopics && hotTopics.length > 0 ? hotTopics[0] : undefined
  if (visibleHotTopic !== undefined && newPolls) {
    const newPollsNotHotTopic = newPolls.filter((newPoll) => {
      return newPoll.poll.id !== visibleHotTopic.poll.id
    })
    visibleNewPolls = newPollsNotHotTopic && newPollsNotHotTopic.slice(0, 3)
  } else {
    visibleNewPolls = newPolls && newPolls.slice(0, 3)
  }

  const isPollVisibleNewPoll = (poll: Poll): boolean => visibleNewPolls?.some((x) => poll.id === x.poll.id) === true
  const isPollVisibleHotTopic = (poll: Poll): boolean => poll.id === visibleHotTopic?.poll.id

  const lastChanceToVotePolls = polls
    .filter((x) => isPollEndingSoon(x.poll))
    .filter((x) => !isPollVisibleNewPoll(x.poll))
    .filter((x) => !isPollVisibleHotTopic(x.poll))

  const otherPolls = polls
    .filter((x) => !isPollEndingSoon(x.poll))
    .filter((x) => !isPollVisibleNewPoll(x.poll))
    .filter((x) => !isPollVisibleHotTopic(x.poll))

  const hasFeaturedVotesSection =
    visibleHotTopic || (visibleNewPolls && visibleNewPolls.length > 0) || lastChanceToVotePolls.length > 0

  const { colors } = useTheme()

  if (polls.length === 0 && awaitingResults.length === 0) {
    return (
      <NoResultsMessage
        heading="No results"
        body="Sorry, there aren't any votes that match the criteria you've selected from the filters. To see all votes,
      clear all the filters."
        button={{ text: 'Clear all filters', onClick: resetAllFilters }}
      />
    )
  }

  return (
    <>
      {hotTopics && hotTopics.length > 0 && <HotTopicContainer title="Hot topics" pollAndHotTopic={hotTopics[0]} />}
      {visibleNewPolls && visibleNewPolls.length > 0 && <NewPollsList newPolls={visibleNewPolls} />}
      {lastChanceToVotePolls.length > 0 && (
        <PollsList
          icon={<SandTimerIcon height={25} width={25} />}
          title="Last chance to vote"
          polls={lastChanceToVotePolls}
        />
      )}
      {otherPolls.length > 0 && (
        <PollsList
          icon={<VoteBoxIcon height={25} width={25} />}
          title={hasFeaturedVotesSection && 'Other votes'}
          polls={otherPolls}
        />
      )}
      {awaitingResults.length > 0 && (
        <AwaitingResultsList
          title="Your work here is done, people"
          subheading={
            <>
              <p style={{ marginTop: 0 }}>
                If you voted in time, great work. If you didn’t, don’t fret, your fellow users did and you’ll get to see
                the results soon. These votes are still <strong>open for your fund managers</strong> to make their
                decision ahead of the AGM.
              </p>

              <p style={{ color: colors.link.medium }}>
                Keep an eye on the{' '}
                <RouterLink style={{ color: colors.link.medium }} to="/votes/results">
                  Vote results
                </RouterLink>{' '}
                page to see what happened.
              </p>
            </>
          }
          polls={awaitingResults}
        />
      )}
    </>
  )
}

const FilterContainer: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const description = 'Place your vote preference on upcoming issues at companies in your pension.'
  const [isMenuOpenOnMobile, setIsMenuOpenOnMobile] = useState(false)

  return (
    <SearchMenuLayout
      menuHeading="Filter"
      menu={<VotingFilterMenu />}
      isMenuOpenOnMobile={isMenuOpenOnMobile}
      toggleMenuCallback={() => setIsMenuOpenOnMobile(!isMenuOpenOnMobile)}
    >
      <PageHeader title="Open votes" helpText={description} />
      <MoreInformationContainer>
        Dive in and read more about upcoming votes. Click into a card to dig into the details and cast your vote!
      </MoreInformationContainer>

      {children}
    </SearchMenuLayout>
  )
}

const MoreInformationContainer = styled.div`
  padding-bottom: 2rem;
  width: 100%;
`

export default OpenVotes
