/**
 * AccountTriggerFetchCompositionService is a service dedicated primarily for the purpose of integration with initially
 * Fidelity but ideally more widely.
 *
 * The service will trigger the auth reverse proxy to go and update the composition in someone's account before it is
 * pulled from the API. It can thus be used. The fetching happens in the background by the service.
 *
 * There are three implementations:
 * - Nothing which just returns a success and is for backwards compatibility
 * - Mock which just introduces a daily for testing
 * - GRPC which will ping the server
 */
import { APIServiceBaseClass } from '../../../utils/api'

export interface AccountTriggerFetchCompositionService {
  triggerFetch(): Promise<void>
}

export const AccountTriggerFetchCompositionDoNothing: AccountTriggerFetchCompositionService = {
  triggerFetch: async () => {},
}

export class AccountTriggerFetchCompositionGRPC
  extends APIServiceBaseClass
  implements AccountTriggerFetchCompositionService
{
  async triggerFetch() {
    const grpcClient = await this.getDashboardBffService()
    await grpcClient.TriggerFetchToCompositions({})
  }
}
