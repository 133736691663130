import {
  ConstrainedString,
  dateToTimestamp,
  InvestorVoteResponse,
  ProposalId,
  Timestamp,
  VotingPolicyId,
} from '@tumelo/shared'

export type VotingRecommendationId = ConstrainedString<'votingRecommendation-id'>

export type VotingRecommendations = Array<VotingRecommendation>

export type VotingRecommendation = {
  id: VotingRecommendationId
  votingPolicyId: VotingPolicyId
  organizationId: string
  generalMeetingId: string
  proposalId: ProposalId
  recommendation: Recommendation
  createTime: Timestamp
  updateTime: Timestamp
}

type Recommendation = {
  instruction: InvestorVoteResponse
  rationale?: string
}

export const sampleVotingRecommendation = (): VotingRecommendation => ({
  id: 'sample-id' as VotingRecommendationId,
  votingPolicyId: 'votingPolicy-id' as VotingPolicyId,
  organizationId: 'organization-id',
  generalMeetingId: 'generalMeeting-id',
  proposalId: 'proposal-id' as ProposalId,
  recommendation: {
    instruction: 'for',
  },
  createTime: dateToTimestamp(new Date('2021-06-26T14:46:36.000')),
  updateTime: dateToTimestamp(new Date('2021-06-26T14:46:36.000')),
})
