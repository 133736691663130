import React from 'react'
import { useParams } from 'react-router-dom'
import { isPollOpen, PollId, BasePageProps, Content } from '@tumelo/shared'
import { useRouter } from '../application/router/useRouter'
import { useAppDispatch } from '../application/store'
import { Vote } from '../components/views/Voting'
import { Error as ErrorComponent } from '../components/Error'
import { PayloadUnwrapperM } from '../components/Error/PayloadUnwrapper'
import { useAnalytics } from '../application/analytics'
import { buildEvent } from '../application/analytics/eventBuilders'
import { useBallot, usePoll } from '../application/features/singleBallot/hooks'
import { castVote } from '../application/features/singleBallot/asyncActions'
import { useInvestor } from '../application/features/investor/useInvestor'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { useAccount } from '../application/features/account/hooks'
import { changeTitle } from './changeTitle'

type Props = BasePageProps

const VotePage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)

  const { id = '' } = useParams<'id'>()
  const pollId = id as PollId
  const { navigate, back } = useRouter()
  const dispatch = useAppDispatch()
  const { trackEvent } = useAnalytics()

  const { ballotOrUndefinedIfNotFound } = useBallot(pollId)
  const pollAndOrganization = usePoll(pollId)
  const { investor } = useInvestor()
  const account = useAccount(investor)

  const urlParams = new URLSearchParams(window.location.search)
  const readMoreParam = urlParams.get('readmore')

  return (
    <ErrorBoundary>
      <Content>
        <PayloadUnwrapperM items={[ballotOrUndefinedIfNotFound, pollAndOrganization, account]}>
          {([ballotOrUndefinedIfNotFound, pollAndOrganization, account]) => {
            if (!pollAndOrganization || !pollAndOrganization.poll || account === 'not-configured') {
              return (
                <ErrorComponent
                  title="Sorry, we can't find that vote"
                  bodyText="It looks like this vote doesn't exist or has closed, would you like to: "
                  mainButton={{ onClick: () => back(), text: 'Back' }}
                />
              )
            }
            if (!pollAndOrganization || !pollAndOrganization.organization) {
              return (
                <ErrorComponent
                  title="Sorry, we can't find the organization"
                  bodyText="It looks like we can't find the organization"
                  mainButton={{ onClick: () => back(), text: 'Back' }}
                />
              )
            }

            if (!ballotOrUndefinedIfNotFound && isPollOpen(pollAndOrganization.poll)) {
              return (
                <ErrorComponent
                  title="Sorry, you don't seem to have this vote."
                  bodyText="This company isn't in your fund right now and we should've spotted it. But please click below to see your open votes!"
                  mainButton={{ onClick: () => navigate(`/votes/open`), text: 'See my open votes' }}
                />
              )
            }

            return (
              <Vote
                poll={pollAndOrganization.poll}
                ballot={ballotOrUndefinedIfNotFound}
                onClickAnswer={(option) => {
                  if (!ballotOrUndefinedIfNotFound) {
                    throw new Error('Cannot click to vote on ballot when have no ballot.')
                  } else {
                    trackEvent(buildEvent.clickVote(option, pollAndOrganization.poll.id))
                    dispatch(castVote(ballotOrUndefinedIfNotFound, option))
                  }
                }}
                onClickOpenVotes={() => navigate(`/votes/open`)}
                onClickOrganization={() => navigate(`/companies/${pollAndOrganization.organization.id}`)}
                organization={pollAndOrganization.organization}
                readMoreVariant="text"
                initialReadMoreState={readMoreParam === '1'}
              />
            )
          }}
        </PayloadUnwrapperM>
      </Content>
    </ErrorBoundary>
  )
}

export default VotePage
