import { IndustryCategory } from './industryCategory';
import { NAICSCode } from './naicsCodes';
/**
 * Maps a NAICS code to a Tumelo defined industry category
 */
export const naicsCodeToIndustryCategory = (code) => {
    return (code && codeToCategoryMap.get(code)) || IndustryCategory.UNKNOWN;
};
const codeToCategoryMap = new Map([
    [NAICSCode.SECTOR_AGRICULTURE_FORESTRY_FISHING_AND_HUNTING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.SECTOR_MINING_QUARRYING_AND_OIL_AND_GAS_EXTRACTION, IndustryCategory.INDUSTRIAL],
    [NAICSCode.SECTOR_UTILITIES, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.SECTOR_CONSTRUCTION, IndustryCategory.INDUSTRIAL],
    [NAICSCode.SECTOR_MANUFACTURING_1, IndustryCategory.INDUSTRIAL],
    [NAICSCode.SECTOR_MANUFACTURING_2, IndustryCategory.INDUSTRIAL],
    [NAICSCode.SECTOR_MANUFACTURING_3, IndustryCategory.INDUSTRIAL],
    [NAICSCode.SECTOR_WHOLESALE_TRADE_1, IndustryCategory.RETAIL],
    [NAICSCode.SECTOR_WHOLESALE_TRADE_2, IndustryCategory.RETAIL],
    [NAICSCode.SECTOR_RETAIL_TRADE_1, IndustryCategory.RETAIL],
    [NAICSCode.SECTOR_RETAIL_TRADE_2, IndustryCategory.RETAIL],
    [NAICSCode.SECTOR_TRANSPORTATION_AND_WAREHOUSING_1, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SECTOR_TRANSPORTATION_AND_WAREHOUSING_2, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SECTOR_INFORMATION, IndustryCategory.LIFESTYLE],
    [NAICSCode.SECTOR_FINANCE_AND_INSURANCE, IndustryCategory.FINANCE],
    [NAICSCode.SECTOR_REAL_ESTATE_AND_RENTAL_AND_LEASING, IndustryCategory.REAL_ESTATE],
    [NAICSCode.SECTOR_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.SECTOR_MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES, IndustryCategory.SPECIALIST_SERVICES],
    [
        NAICSCode.SECTOR_ADMINISTRATIVE_AND_SUPPORT_AND_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES,
        IndustryCategory.SPECIALIST_SERVICES,
    ],
    [NAICSCode.SECTOR_EDUCATIONAL_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.SECTOR_HEALTH_CARE_AND_SOCIAL_ASSISTANCE, IndustryCategory.HEALTHCARE],
    [NAICSCode.SECTOR_ARTS_ENTERTAINMENT_AND_RECREATION, IndustryCategory.LIFESTYLE],
    [NAICSCode.SECTOR_ACCOMMODATION_AND_FOOD_SERVICES, IndustryCategory.LIFESTYLE],
    [NAICSCode.SECTOR_OTHER_SERVICES_EXCEPT_PUBLIC_ADMINISTRATION, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.SECTOR_PUBLIC_ADMINISTRATION_1, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.SECTOR_PUBLIC_ADMINISTRATION_2, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.OILSEED_AND_GRAIN_FARMING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.VEGETABLE_AND_MELON_FARMING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.FRUIT_AND_TREE_NUT_FARMING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.GREENHOUSE_NURSERY_AND_FLORICULTURE_PRODUCTION, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.OTHER_CROP_FARMING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.CATTLE_RANCHING_AND_FARMING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.HOG_AND_PIG_FARMING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.POULTRY_AND_EGG_PRODUCTION, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.SHEEP_AND_GOAT_FARMING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.AQUACULTURE, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.OTHER_ANIMAL_PRODUCTION, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.TIMBER_TRACT_OPERATIONS, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.FOREST_NURSERIES_AND_GATHERING_OF_FOREST_PRODUCTS, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.LOGGING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.FISHING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.HUNTING_AND_TRAPPING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_CROP_PRODUCTION, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_ANIMAL_PRODUCTION, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_FORESTRY, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.OIL_AND_GAS_EXTRACTION, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.COAL_MINING, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.METAL_ORE_MINING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.NONMETALLIC_MINERAL_MINING_AND_QUARRYING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_MINING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.ELECTRIC_POWER_GENERATION_TRANSMISSION_AND_DISTRIBUTION, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.NATURAL_GAS_DISTRIBUTION, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.WATER_SEWAGE_AND_OTHER_SYSTEMS, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.RESIDENTIAL_BUILDING_CONSTRUCTION, IndustryCategory.INDUSTRIAL],
    [NAICSCode.NONRESIDENTIAL_BUILDING_CONSTRUCTION, IndustryCategory.INDUSTRIAL],
    [NAICSCode.UTILITY_SYSTEM_CONSTRUCTION, IndustryCategory.INDUSTRIAL],
    [NAICSCode.LAND_SUBDIVISION, IndustryCategory.INDUSTRIAL],
    [NAICSCode.HIGHWAY_STREET_AND_BRIDGE_CONSTRUCTION, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_HEAVY_AND_CIVIL_ENGINEERING_CONSTRUCTION, IndustryCategory.INDUSTRIAL],
    [NAICSCode.FOUNDATION_STRUCTURE_AND_BUILDING_EXTERIOR_CONTRACTORS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.BUILDING_EQUIPMENT_CONTRACTORS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.BUILDING_FINISHING_CONTRACTORS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_SPECIALTY_TRADE_CONTRACTORS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.ANIMAL_FOOD_MANUFACTURING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.GRAIN_AND_OILSEED_MILLING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.SUGAR_AND_CONFECTIONERY_PRODUCT_MANUFACTURING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.FRUIT_AND_VEGETABLE_PRESERVING_AND_SPECIALTY_FOOD_MANUFACTURING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.DAIRY_PRODUCT_MANUFACTURING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.ANIMAL_SLAUGHTERING_AND_PROCESSING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.SEAFOOD_PRODUCT_PREPARATION_AND_PACKAGING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.BAKERIES_AND_TORTILLA_MANUFACTURING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.OTHER_FOOD_MANUFACTURING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.BEVERAGE_MANUFACTURING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.TOBACCO_MANUFACTURING, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.FIBER_YARN_AND_THREAD_MILLS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.FABRIC_MILLS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.TEXTILE_AND_FABRIC_FINISHING_AND_FABRIC_COATING_MILLS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.TEXTILE_FURNISHINGS_MILLS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_TEXTILE_PRODUCT_MILLS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.APPAREL_KNITTING_MILLS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.CUT_AND_SEW_APPAREL_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.APPAREL_ACCESSORIES_AND_OTHER_APPAREL_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.LEATHER_AND_HIDE_TANNING_AND_FINISHING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.FOOTWEAR_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_LEATHER_AND_ALLIED_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.SAWMILLS_AND_WOOD_PRESERVATION, IndustryCategory.INDUSTRIAL],
    [NAICSCode.VENEER_PLYWOOD_AND_ENGINEERED_WOOD_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_WOOD_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.PULP_PAPER_AND_PAPERBOARD_MILLS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.CONVERTED_PAPER_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.PRINTING_AND_RELATED_SUPPORT_ACTIVITIES, IndustryCategory.INDUSTRIAL],
    [NAICSCode.PETROLEUM_AND_COAL_PRODUCTS_MANUFACTURING, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.BASIC_CHEMICAL_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [
        NAICSCode.RESIN_SYNTHETIC_RUBBER_AND_ARTIFICIAL_AND_SYNTHETIC_FIBERS_AND_FILAMENTS_MANUFACTURING,
        IndustryCategory.INDUSTRIAL,
    ],
    [NAICSCode.PESTICIDE_FERTILIZER_AND_OTHER_AGRICULTURAL_CHEMICAL_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.PHARMACEUTICAL_AND_MEDICINE_MANUFACTURING, IndustryCategory.HEALTHCARE],
    [NAICSCode.PAINT_COATING_AND_ADHESIVE_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.SOAP_CLEANING_COMPOUND_AND_TOILET_PREPARATION_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_CHEMICAL_PRODUCT_AND_PREPARATION_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.PLASTICS_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.RUBBER_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.CLAY_PRODUCT_AND_REFRACTORY_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.GLASS_AND_GLASS_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.CEMENT_AND_CONCRETE_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.LIME_AND_GYPSUM_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_NONMETALLIC_MINERAL_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.IRON_AND_STEEL_MILLS_AND_FERROALLOY_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.STEEL_PRODUCT_MANUFACTURING_FROM_PURCHASED_STEEL, IndustryCategory.INDUSTRIAL],
    [NAICSCode.ALUMINA_AND_ALUMINUM_PRODUCTION_AND_PROCESSING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.NONFERROUS_METAL_EXCEPT_ALUMINUM_PRODUCTION_AND_PROCESSING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.FOUNDRIES, IndustryCategory.INDUSTRIAL],
    [NAICSCode.FORGING_AND_STAMPING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.CUTLERY_AND_HANDTOOL_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.ARCHITECTURAL_AND_STRUCTURAL_METALS_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.BOILER_TANK_AND_SHIPPING_CONTAINER_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.HARDWARE_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.SPRING_AND_WIRE_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.MACHINE_SHOPS_TURNED_PRODUCT_AND_SCREW_NUT_AND_BOLT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.COATING_ENGRAVING_HEAT_TREATING_AND_ALLIED_ACTIVITIES, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_FABRICATED_METAL_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.AGRICULTURE_CONSTRUCTION_AND_MINING_MACHINERY_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.INDUSTRIAL_MACHINERY_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.COMMERCIAL_AND_SERVICE_INDUSTRY_MACHINERY_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [
        NAICSCode.VENTILATION_HEATING_AIRCONDITIONING_AND_COMMERCIAL_REFRIGERATION_EQUIPMENT_MANUFACTURING,
        IndustryCategory.INDUSTRIAL,
    ],
    [NAICSCode.METALWORKING_MACHINERY_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.ENGINE_TURBINE_AND_POWER_TRANSMISSION_EQUIPMENT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_GENERAL_PURPOSE_MACHINERY_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.COMPUTER_AND_PERIPHERAL_EQUIPMENT_MANUFACTURING, IndustryCategory.TECH],
    [NAICSCode.COMMUNICATIONS_EQUIPMENT_MANUFACTURING, IndustryCategory.TECH],
    [NAICSCode.AUDIO_AND_VIDEO_EQUIPMENT_MANUFACTURING, IndustryCategory.TECH],
    [NAICSCode.SEMICONDUCTOR_AND_OTHER_ELECTRONIC_COMPONENT_MANUFACTURING, IndustryCategory.TECH],
    [NAICSCode.NAVIGATIONAL_MEASURING_ELECTROMEDICAL_AND_CONTROL_INSTRUMENTS_MANUFACTURING, IndustryCategory.TECH],
    [NAICSCode.MANUFACTURING_AND_REPRODUCING_MAGNETIC_AND_OPTICAL_MEDIA, IndustryCategory.TECH],
    [NAICSCode.ELECTRIC_LIGHTING_EQUIPMENT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.HOUSEHOLD_APPLIANCE_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.ELECTRICAL_EQUIPMENT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_ELECTRICAL_EQUIPMENT_AND_COMPONENT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.MOTOR_VEHICLE_MANUFACTURING, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.MOTOR_VEHICLE_BODY_AND_TRAILER_MANUFACTURING, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.MOTOR_VEHICLE_PARTS_MANUFACTURING, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.AEROSPACE_PRODUCT_AND_PARTS_MANUFACTURING, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.RAILROAD_ROLLING_STOCK_MANUFACTURING, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SHIP_AND_BOAT_BUILDING, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.OTHER_TRANSPORTATION_EQUIPMENT_MANUFACTURING, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.HOUSEHOLD_AND_INSTITUTIONAL_FURNITURE_AND_KITCHEN_CABINET_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OFFICE_FURNITURE_INCLUDING_FIXTURES_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.OTHER_FURNITURE_RELATED_PRODUCT_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.MEDICAL_EQUIPMENT_AND_SUPPLIES_MANUFACTURING, IndustryCategory.HEALTHCARE],
    [NAICSCode.OTHER_MISCELLANEOUS_MANUFACTURING, IndustryCategory.INDUSTRIAL],
    [NAICSCode.MOTOR_VEHICLE_AND_MOTOR_VEHICLE_PARTS_AND_SUPPLIES_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.FURNITURE_AND_HOME_FURNISHING_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.LUMBER_AND_OTHER_CONSTRUCTION_MATERIALS_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.PROFESSIONAL_AND_COMMERCIAL_EQUIPMENT_AND_SUPPLIES_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.METAL_AND_MINERAL_EXCEPT_PETROLEUM_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.HOUSEHOLD_APPLIANCES_AND_ELECTRICAL_AND_ELECTRONIC_GOODS_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.HARDWARE_AND_PLUMBING_AND_HEATING_EQUIPMENT_AND_SUPPLIES_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.MACHINERY_EQUIPMENT_AND_SUPPLIES_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.MISCELLANEOUS_DURABLE_GOODS_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.PAPER_AND_PAPER_PRODUCT_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.DRUGS_AND_DRUGGISTS_SUNDRIES_MERCHANT_WHOLESALERS, IndustryCategory.HEALTHCARE],
    [NAICSCode.APPAREL_PIECE_GOODS_AND_NOTIONS_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.GROCERY_AND_RELATED_PRODUCT_MERCHANT_WHOLESALERS, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.FARM_PRODUCT_RAW_MATERIAL_MERCHANT_WHOLESALERS, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.CHEMICAL_AND_ALLIED_PRODUCTS_MERCHANT_WHOLESALERS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.PETROLEUM_AND_PETROLEUM_PRODUCTS_MERCHANT_WHOLESALERS, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.BEER_WINE_AND_DISTILLED_ALCOHOLIC_BEVERAGE_MERCHANT_WHOLESALERS, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.MISCELLANEOUS_NONDURABLE_GOODS_MERCHANT_WHOLESALERS, IndustryCategory.RETAIL],
    [NAICSCode.WHOLESALE_ELECTRONIC_MARKETS_AND_AGENTS_AND_BROKERS, IndustryCategory.RETAIL],
    [NAICSCode.AUTOMOBILE_DEALERS, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.OTHER_MOTOR_VEHICLE_DEALERS, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.AUTOMOTIVE_PARTS_ACCESSORIES_AND_TIRE_STORES, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.FURNITURE_STORES, IndustryCategory.RETAIL],
    [NAICSCode.HOME_FURNISHINGS_STORES, IndustryCategory.RETAIL],
    [NAICSCode.ELECTRONICS_AND_APPLIANCE_STORES, IndustryCategory.RETAIL],
    [NAICSCode.BUILDING_MATERIAL_AND_SUPPLIES_DEALERS, IndustryCategory.INDUSTRIAL],
    [NAICSCode.LAWN_AND_GARDEN_EQUIPMENT_AND_SUPPLIES_STORES, IndustryCategory.RETAIL],
    [NAICSCode.GROCERY_STORES, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.SPECIALTY_FOOD_STORES, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.BEER_WINE_AND_LIQUOR_STORES, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.HEALTH_AND_PERSONAL_CARE_STORES, IndustryCategory.HEALTHCARE],
    [NAICSCode.GASOLINE_STATIONS, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.CLOTHING_STORES, IndustryCategory.RETAIL],
    [NAICSCode.SHOE_STORES, IndustryCategory.RETAIL],
    [NAICSCode.JEWELRY_LUGGAGE_AND_LEATHER_GOODS_STORES, IndustryCategory.RETAIL],
    [NAICSCode.SPORTING_GOODS_HOBBY_AND_MUSICAL_INSTRUMENT_STORES, IndustryCategory.RETAIL],
    [NAICSCode.BOOK_STORES_AND_NEWS_DEALERS, IndustryCategory.RETAIL],
    [NAICSCode.DEPARTMENT_STORES, IndustryCategory.RETAIL],
    [NAICSCode.GENERAL_MERCHANDISE_STORES_INCLUDING_WAREHOUSE_CLUBS_AND_SUPERCENTERS, IndustryCategory.RETAIL],
    [NAICSCode.FLORISTS, IndustryCategory.RETAIL],
    [NAICSCode.OFFICE_SUPPLIES_STATIONERY_AND_GIFT_STORES, IndustryCategory.RETAIL],
    [NAICSCode.USED_MERCHANDISE_STORES, IndustryCategory.RETAIL],
    [NAICSCode.OTHER_MISCELLANEOUS_STORE_RETAILERS, IndustryCategory.RETAIL],
    [NAICSCode.ELECTRONIC_SHOPPING_AND_MAILORDER_HOUSES, IndustryCategory.RETAIL],
    [NAICSCode.VENDING_MACHINE_OPERATORS, IndustryCategory.RETAIL],
    [NAICSCode.DIRECT_SELLING_ESTABLISHMENTS, IndustryCategory.RETAIL],
    [NAICSCode.SCHEDULED_AIR_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.NONSCHEDULED_AIR_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.RAIL_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.DEEP_SEA_COASTAL_AND_GREAT_LAKES_WATER_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.INLAND_WATER_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.GENERAL_FREIGHT_TRUCKING, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SPECIALIZED_FREIGHT_TRUCKING, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.URBAN_TRANSIT_SYSTEMS, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.INTERURBAN_AND_RURAL_BUS_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.TAXI_AND_LIMOUSINE_SERVICE, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SCHOOL_AND_EMPLOYEE_BUS_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.CHARTER_BUS_INDUSTRY, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.OTHER_TRANSIT_AND_GROUND_PASSENGER_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.PIPELINE_TRANSPORTATION_OF_CRUDE_OIL, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.PIPELINE_TRANSPORTATION_OF_NATURAL_GAS, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.OTHER_PIPELINE_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SCENIC_AND_SIGHTSEEING_TRANSPORTATION_LAND, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SCENIC_AND_SIGHTSEEING_TRANSPORTATION_WATER, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SCENIC_AND_SIGHTSEEING_TRANSPORTATION_OTHER, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_AIR_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_RAIL_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_WATER_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_ROAD_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.FREIGHT_TRANSPORTATION_ARRANGEMENT, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.OTHER_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.POSTAL_SERVICE, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.COURIERS_AND_EXPRESS_DELIVERY_SERVICES, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.LOCAL_MESSENGERS_AND_LOCAL_DELIVERY, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.WAREHOUSING_AND_STORAGE, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.NEWSPAPER_PERIODICAL_BOOK_AND_DIRECTORY_PUBLISHERS, IndustryCategory.LIFESTYLE],
    [NAICSCode.SOFTWARE_PUBLISHERS, IndustryCategory.TECH],
    [NAICSCode.MOTION_PICTURE_AND_VIDEO_INDUSTRIES, IndustryCategory.LIFESTYLE],
    [NAICSCode.SOUND_RECORDING_INDUSTRIES, IndustryCategory.TECH],
    [NAICSCode.RADIO_AND_TELEVISION_BROADCASTING, IndustryCategory.LIFESTYLE],
    [NAICSCode.CABLE_AND_OTHER_SUBSCRIPTION_PROGRAMMING, IndustryCategory.LIFESTYLE],
    [NAICSCode.WIRED_AND_WIRELESS_TELECOMMUNICATIONS_CARRIERS, IndustryCategory.TECH],
    [NAICSCode.SATELLITE_TELECOMMUNICATIONS, IndustryCategory.TECH],
    [NAICSCode.OTHER_TELECOMMUNICATIONS, IndustryCategory.TECH],
    [NAICSCode.DATA_PROCESSING_HOSTING_AND_RELATED_SERVICES, IndustryCategory.TECH],
    [NAICSCode.OTHER_INFORMATION_SERVICES, IndustryCategory.TECH],
    [NAICSCode.MONETARY_AUTHORITIESCENTRAL_BANK, IndustryCategory.FINANCE],
    [NAICSCode.DEPOSITORY_CREDIT_INTERMEDIATION, IndustryCategory.FINANCE],
    [NAICSCode.NONDEPOSITORY_CREDIT_INTERMEDIATION, IndustryCategory.FINANCE],
    [NAICSCode.ACTIVITIES_RELATED_TO_CREDIT_INTERMEDIATION, IndustryCategory.FINANCE],
    [NAICSCode.SECURITIES_AND_COMMODITY_CONTRACTS_INTERMEDIATION_AND_BROKERAGE, IndustryCategory.FINANCE],
    [NAICSCode.SECURITIES_AND_COMMODITY_EXCHANGES, IndustryCategory.FINANCE],
    [NAICSCode.OTHER_FINANCIAL_INVESTMENT_ACTIVITIES, IndustryCategory.FINANCE],
    [NAICSCode.INSURANCE_CARRIERS, IndustryCategory.FINANCE],
    [NAICSCode.AGENCIES_BROKERAGES_AND_OTHER_INSURANCE_RELATED_ACTIVITIES, IndustryCategory.FINANCE],
    [NAICSCode.INSURANCE_AND_EMPLOYEE_BENEFIT_FUNDS, IndustryCategory.FINANCE],
    [NAICSCode.OTHER_INVESTMENT_POOLS_AND_FUNDS, IndustryCategory.FINANCE],
    [NAICSCode.LESSORS_OF_REAL_ESTATE, IndustryCategory.REAL_ESTATE],
    [NAICSCode.OFFICES_OF_REAL_ESTATE_AGENTS_AND_BROKERS, IndustryCategory.REAL_ESTATE],
    [NAICSCode.ACTIVITIES_RELATED_TO_REAL_ESTATE, IndustryCategory.REAL_ESTATE],
    [NAICSCode.AUTOMOTIVE_EQUIPMENT_RENTAL_AND_LEASING, IndustryCategory.REAL_ESTATE],
    [NAICSCode.CONSUMER_GOODS_RENTAL, IndustryCategory.REAL_ESTATE],
    [NAICSCode.GENERAL_RENTAL_CENTERS, IndustryCategory.REAL_ESTATE],
    [NAICSCode.COMMERCIAL_AND_INDUSTRIAL_MACHINERY_AND_EQUIPMENT_RENTAL_AND_LEASING, IndustryCategory.REAL_ESTATE],
    [NAICSCode.LESSORS_OF_NONFINANCIAL_INTANGIBLE_ASSETS_EXCEPT_COPYRIGHTED_WORKS, IndustryCategory.REAL_ESTATE],
    [NAICSCode.LEGAL_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.ACCOUNTING_TAX_PREPARATION_BOOKKEEPING_AND_PAYROLL_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.ARCHITECTURAL_ENGINEERING_AND_RELATED_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.SPECIALIZED_DESIGN_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.COMPUTER_SYSTEMS_DESIGN_AND_RELATED_SERVICES, IndustryCategory.TECH],
    [NAICSCode.MANAGEMENT_SCIENTIFIC_AND_TECHNICAL_CONSULTING_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.SCIENTIFIC_RESEARCH_AND_DEVELOPMENT_SERVICES, IndustryCategory.TECH],
    [NAICSCode.ADVERTISING_PUBLIC_RELATIONS_AND_RELATED_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES, IndustryCategory.TECH],
    [NAICSCode.MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.OFFICE_ADMINISTRATIVE_SERVICES, IndustryCategory.REAL_ESTATE],
    [NAICSCode.FACILITIES_SUPPORT_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.EMPLOYMENT_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.BUSINESS_SUPPORT_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.TRAVEL_ARRANGEMENT_AND_RESERVATION_SERVICES, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.INVESTIGATION_AND_SECURITY_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.SERVICES_TO_BUILDINGS_AND_DWELLINGS, IndustryCategory.REAL_ESTATE],
    [NAICSCode.OTHER_SUPPORT_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.WASTE_COLLECTION, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.WASTE_TREATMENT_AND_DISPOSAL, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.REMEDIATION_AND_OTHER_WASTE_MANAGEMENT_SERVICES, IndustryCategory.ENERGY_AND_UTILITIES],
    [NAICSCode.ELEMENTARY_AND_SECONDARY_SCHOOLS, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.JUNIOR_COLLEGES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.COLLEGES_UNIVERSITIES_AND_PROFESSIONAL_SCHOOLS, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.BUSINESS_SCHOOLS_AND_COMPUTER_AND_MANAGEMENT_TRAINING, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.TECHNICAL_AND_TRADE_SCHOOLS, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.OTHER_SCHOOLS_AND_INSTRUCTION, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.EDUCATIONAL_SUPPORT_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.OFFICES_OF_PHYSICIANS, IndustryCategory.HEALTHCARE],
    [NAICSCode.OFFICES_OF_DENTISTS, IndustryCategory.HEALTHCARE],
    [NAICSCode.OFFICES_OF_OTHER_HEALTH_PRACTITIONERS, IndustryCategory.HEALTHCARE],
    [NAICSCode.OUTPATIENT_CARE_CENTERS, IndustryCategory.HEALTHCARE],
    [NAICSCode.MEDICAL_AND_DIAGNOSTIC_LABORATORIES, IndustryCategory.HEALTHCARE],
    [NAICSCode.HOME_HEALTH_CARE_SERVICES, IndustryCategory.HEALTHCARE],
    [NAICSCode.OTHER_AMBULATORY_HEALTH_CARE_SERVICES, IndustryCategory.HEALTHCARE],
    [NAICSCode.GENERAL_MEDICAL_AND_SURGICAL_HOSPITALS, IndustryCategory.HEALTHCARE],
    [NAICSCode.PSYCHIATRIC_AND_SUBSTANCE_ABUSE_HOSPITALS, IndustryCategory.HEALTHCARE],
    [NAICSCode.SPECIALTY_EXCEPT_PSYCHIATRIC_AND_SUBSTANCE_ABUSE_HOSPITALS, IndustryCategory.HEALTHCARE],
    [NAICSCode.NURSING_CARE_FACILITIES_SKILLED_NURSING_FACILITIES, IndustryCategory.HEALTHCARE],
    [
        NAICSCode.RESIDENTIAL_INTELLECTUAL_AND_DEVELOPMENTAL_DISABILITY_MENTAL_HEALTH_AND_SUBSTANCE_ABUSE_FACILITIES,
        IndustryCategory.HEALTHCARE,
    ],
    [
        NAICSCode.CONTINUING_CARE_RETIREMENT_COMMUNITIES_AND_ASSISTED_LIVING_FACILITIES_FOR_THE_ELDERLY,
        IndustryCategory.HEALTHCARE,
    ],
    [NAICSCode.OTHER_RESIDENTIAL_CARE_FACILITIES, IndustryCategory.HEALTHCARE],
    [NAICSCode.INDIVIDUAL_AND_FAMILY_SERVICES, IndustryCategory.HEALTHCARE],
    [NAICSCode.COMMUNITY_FOOD_AND_HOUSING_AND_EMERGENCY_AND_OTHER_RELIEF_SERVICES, IndustryCategory.HEALTHCARE],
    [NAICSCode.VOCATIONAL_REHABILITATION_SERVICES, IndustryCategory.HEALTHCARE],
    [NAICSCode.CHILD_DAY_CARE_SERVICES, IndustryCategory.HEALTHCARE],
    [NAICSCode.PERFORMING_ARTS_COMPANIES, IndustryCategory.LIFESTYLE],
    [NAICSCode.SPECTATOR_SPORTS, IndustryCategory.LIFESTYLE],
    [NAICSCode.PROMOTERS_OF_PERFORMING_ARTS_SPORTS_AND_SIMILAR_EVENTS, IndustryCategory.LIFESTYLE],
    [
        NAICSCode.AGENTS_AND_MANAGERS_FOR_ARTISTS_ATHLETES_ENTERTAINERS_AND_OTHER_PUBLIC_FIGURES,
        IndustryCategory.SPECIALIST_SERVICES,
    ],
    [NAICSCode.INDEPENDENT_ARTISTS_WRITERS_AND_PERFORMERS, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.MUSEUMS_HISTORICAL_SITES_AND_SIMILAR_INSTITUTIONS, IndustryCategory.LIFESTYLE],
    [NAICSCode.AMUSEMENT_PARKS_AND_ARCADES, IndustryCategory.LIFESTYLE],
    [NAICSCode.GAMBLING_INDUSTRIES, IndustryCategory.LIFESTYLE],
    [NAICSCode.OTHER_AMUSEMENT_AND_RECREATION_INDUSTRIES, IndustryCategory.LIFESTYLE],
    [NAICSCode.TRAVELER_ACCOMMODATION, IndustryCategory.LIFESTYLE],
    [NAICSCode.RV_RECREATIONAL_VEHICLE_PARKS_AND_RECREATIONAL_CAMPS, IndustryCategory.LIFESTYLE],
    [NAICSCode.ROOMING_AND_BOARDING_HOUSES_DORMITORIES_AND_WORKERS_CAMPS, IndustryCategory.LIFESTYLE],
    [NAICSCode.SPECIAL_FOOD_SERVICES, IndustryCategory.AGRICULTURE_AND_FOOD],
    [NAICSCode.DRINKING_PLACES_ALCOHOLIC_BEVERAGES, IndustryCategory.LIFESTYLE],
    [NAICSCode.RESTAURANTS_AND_OTHER_EATING_PLACES, IndustryCategory.LIFESTYLE],
    [NAICSCode.AUTOMOTIVE_REPAIR_AND_MAINTENANCE, IndustryCategory.TRANSPORT_AND_VEHICLES],
    [NAICSCode.ELECTRONIC_AND_PRECISION_EQUIPMENT_REPAIR_AND_MAINTENANCE, IndustryCategory.TECH],
    [
        NAICSCode.COMMERCIAL_AND_INDUSTRIAL_MACHINERY_AND_EQUIPMENT_EXCEPT_AUTOMOTIVE_AND_ELECTRONIC_REPAIR_AND_MAINTENANCE,
        IndustryCategory.INDUSTRIAL,
    ],
    [NAICSCode.PERSONAL_AND_HOUSEHOLD_GOODS_REPAIR_AND_MAINTENANCE, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.PERSONAL_CARE_SERVICES, IndustryCategory.HEALTHCARE],
    [NAICSCode.DEATH_CARE_SERVICES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.DRYCLEANING_AND_LAUNDRY_SERVICES, IndustryCategory.LIFESTYLE],
    [NAICSCode.OTHER_PERSONAL_SERVICES, IndustryCategory.LIFESTYLE],
    [NAICSCode.RELIGIOUS_ORGANIZATIONS, IndustryCategory.LIFESTYLE],
    [NAICSCode.GRANTMAKING_AND_GIVING_SERVICES, IndustryCategory.LIFESTYLE],
    [NAICSCode.SOCIAL_ADVOCACY_ORGANIZATIONS, IndustryCategory.LIFESTYLE],
    [NAICSCode.CIVIC_AND_SOCIAL_ORGANIZATIONS, IndustryCategory.LIFESTYLE],
    [NAICSCode.BUSINESS_PROFESSIONAL_LABOR_POLITICAL_AND_SIMILAR_ORGANIZATIONS, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.PRIVATE_HOUSEHOLDS, IndustryCategory.REAL_ESTATE],
    [NAICSCode.EXECUTIVE_LEGISLATIVE_AND_OTHER_GENERAL_GOVERNMENT_SUPPORT, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.JUSTICE_PUBLIC_ORDER_AND_SAFETY_ACTIVITIES, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.ADMINISTRATION_OF_HUMAN_RESOURCE_PROGRAMS, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.ADMINISTRATION_OF_ENVIRONMENTAL_QUALITY_PROGRAMS, IndustryCategory.SPECIALIST_SERVICES],
    [
        NAICSCode.ADMINISTRATION_OF_HOUSING_PROGRAMS_URBAN_PLANNING_AND_COMMUNITY_DEVELOPMENT,
        IndustryCategory.SPECIALIST_SERVICES,
    ],
    [NAICSCode.ADMINISTRATION_OF_ECONOMIC_PROGRAMS, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.SPACE_RESEARCH_AND_TECHNOLOGY, IndustryCategory.TECH],
    [NAICSCode.NATIONAL_SECURITY_AND_INTERNATIONAL_AFFAIRS, IndustryCategory.SPECIALIST_SERVICES],
    [NAICSCode.INTERNET_PUBLISHING_AND_BROADCASTING_AND_WEB_SEARCH_PORTALS, IndustryCategory.TECH],
    [NAICSCode.COSMETICS_BEAUTY_SUPPLIES_AND_PERFUME_STORES, IndustryCategory.LIFESTYLE],
    [NAICSCode.PORTFOLIO_MANAGEMENT, IndustryCategory.FINANCE],
    [NAICSCode.FOOTWEAR_MANUFACTURING_6D, IndustryCategory.RETAIL],
]);
