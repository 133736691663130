import React from 'react';
import { PollTag } from '../../domain';
import { AnimalRights, Audit, DefenceSecurity, Environment, Equality, HumanRights, Lobbying, Management, Other, } from './images';
export const TagIcon = ({ tagId, style }) => {
    const initialStyle = Object.assign({ width: '2rem', height: '2rem', objectFit: 'contain' }, style);
    switch (tagId) {
        case PollTag.Other:
            return React.createElement(Other, { style: initialStyle });
        case PollTag.Equality:
            return React.createElement(Equality, { style: initialStyle });
        case PollTag.AnimalWelfare:
            return React.createElement(AnimalRights, { style: initialStyle });
        case PollTag.Audit:
            return React.createElement(Audit, { style: initialStyle });
        case PollTag.DefenceSecurity:
            return React.createElement(DefenceSecurity, { style: initialStyle });
        case PollTag.Environment:
            return React.createElement(Environment, { style: initialStyle });
        case PollTag.HumanRights:
            return React.createElement(HumanRights, { style: initialStyle });
        case PollTag.Lobbying:
            return React.createElement(Lobbying, { style: initialStyle });
        case PollTag.Management:
            return React.createElement(Management, { style: initialStyle });
        default:
            return React.createElement(Other, { style: initialStyle });
    }
};
