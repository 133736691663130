import React from 'react'
import styled from 'styled-components'
import { Modal } from '@tumelo/shared'
import { Body1, Body2, Button } from '@tumelo/designsystem'

interface Props {
  open: boolean
  onClose: () => void
  onSignout: () => void
  remainingSeconds: number
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.6rem;
`

export const IdleTimeoutModal: React.FC<Props> = ({ open, remainingSeconds, onClose, onSignout }) => {
  return (
    <Modal width="small" title="Hello? Are you still there?" open={open} onClose={onClose}>
      <Body1>Your session is about to expire, would you like to stay signed in?</Body1>
      <Body2>You&apos;ll be automatically signed out in {remainingSeconds} seconds.</Body2>
      <ButtonContainer>
        <Button onClick={onSignout} variant="secondary">
          Sign out
        </Button>
        <Button onClick={onClose}>Yes, I&apos;m still here!</Button>
      </ButtonContainer>
    </Modal>
  )
}
