import * as React from 'react'
import { useRequireEmailVerified } from '../../utils/useRequireEmailVerified'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'

interface Props {
  children: React.ReactNode
}

export const RequireIsEmailVerified: React.FC<Props> = ({ children }) => {
  const investor = useRequireEmailVerified()

  return <PayloadUnwrapper item={investor}>{() => children}</PayloadUnwrapper>
}
