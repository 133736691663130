/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { ReactElement, ReactNode, useContext } from 'react'
import { Loader } from '@tumelo/shared'
import { useRouter } from '../../application/router/useRouter'
import { Payload, PayloadUnw, tryUnwrapPayloads } from '../../application/payload'
import { LoggerContext } from '../../utils/loggerContext'
import { InlineError, NotFoundInlineError } from '.'

export type RecoveryProps = {
  onRetry: () => void
}

const ignore = () => {}

export function PayloadUnwrapperM<Arrays extends Array<Payload<any>>>({
  items,
  recovery,
  customSpinner,
  customNotInitialized,
  customError,
  children,
}: {
  items: [...Arrays]
  recovery?: RecoveryProps
  customSpinner?: ReactElement<any, any>
  customNotInitialized?: ReactElement<any, any>
  customError?: ReactElement<any, any>
  children: (...t: ReadonlyArray<PayloadUnw<Arrays>>) => ReactNode
}): ReactElement<any, any> | null {
  // Log errors
  const { logError } = useContext(LoggerContext)
  const item = tryUnwrapPayloads(...items)
  const { location } = useRouter()
  const { pathname } = location
  React.useEffect(() => {
    if (item === 'error') {
      logError(new Error(`error unwrapping payload in page ${pathname}`))
    }
  }, [item, pathname, logError])

  const buttonOrDefaults = () =>
    recovery != null ? { text: 'Try again', onClick: recovery?.onRetry ?? ignore } : undefined
  if (item === 'pending') return customSpinner ? <>{customSpinner}</> : <Loader />
  if (item === 'not-initialised') return customNotInitialized ? <>{customNotInitialized}</> : <></>
  if (item === 'error') {
    if (customError) return customError
    const msg = `Sorry, something unexpected has happened. Try again by refreshing the page. If the problem persists`
    const supportNode = (
      <>
        <a href="https://tumelo.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
          get in touch
        </a>{' '}
        with our support team
      </>
    )
    return (
      <InlineError title="Something went wrong..." mainButton={buttonOrDefaults()}>
        <span>
          {msg} {supportNode}.
        </span>
      </InlineError>
    )
  }
  if (item != null && 'type' in item)
    if (item.type === 'error') {
      if (item.errorType === 'not-found') {
        if (customError) return customError
        return <NotFoundInlineError goBack={recovery?.onRetry ?? ignore} />
      }
      if (item.errorType === 'rejected') {
        if (customError) return customError
        return <InlineError title="There was a problem" bodyText={item.screenMessage} mainButton={buttonOrDefaults()} />
      }
      return <InlineError title="There was a problem" bodyText={item.screenMessage} mainButton={buttonOrDefaults()} />
    } else return null

  return <>{children(item as any)}</> // something is going wrong on destructuring - not sure entirely why hence any cast.
}

export function PayloadUnwrapper<T>({
  item,
  recovery,
  customSpinner,
  customError,
  customNotInitialized,
  children,
}: {
  item: Payload<T>
  recovery?: RecoveryProps
  customSpinner?: ReactElement<any, any>
  customNotInitialized?: ReactElement<any, any>
  customError?: ReactElement<any, any>
  children: (t: T) => ReactNode
}) {
  return PayloadUnwrapperM({
    items: [item],
    recovery,
    customSpinner,
    customError,
    customNotInitialized,
    children: ([x]) => children(x as T),
  })
}
