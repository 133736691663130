import {
  Composition,
  Instrument,
  InstrumentReference,
  sampleCompositeInstrument,
  sampleNonCompositeInstrument,
  InstrumentReferenceString,
  CompositionComponentInstrument,
  notEmpty,
  isCompositionEmpty,
  instrumentReferenceToString,
} from '@tumelo/shared'
import { InstrumentService } from './InstrumentService'
import { InstrumentServiceAPI } from './InstrumentServiceAPI'

export const InstrumentServiceMock: InstrumentService = {
  batchFetchFilteredSubscribedInstruments: async (
    instrumentReferences: Array<InstrumentReference>
  ): Promise<Map<InstrumentReferenceString, Instrument>> => {
    if (instrumentReferences.length === 0) {
      return new Map<InstrumentReferenceString, Instrument>()
    }
    return new Map<InstrumentReferenceString, Instrument>(
      instrumentReferences.map((instrumentReference) => {
        const instrument = instrumentsMap.get(instrumentReference)
        return [instrumentReferenceToString(instrumentReference), instrument] as [InstrumentReferenceString, Instrument]
      })
    )
  },

  createCompositionInstruments: async (
    composition: Composition
  ): Promise<{
    composition: Composition
    instrumentMap: Map<InstrumentReferenceString, Instrument>
    missingInstrumentReferences: Array<InstrumentReferenceString>
  }> => {
    if (isCompositionEmpty(composition)) {
      return {
        composition,
        instrumentMap: new Map<InstrumentReferenceString, Instrument>(),
        missingInstrumentReferences: new Array<InstrumentReferenceString>(),
      }
    }
    const instruments = composition.components.instruments.map((instrument: CompositionComponentInstrument) => {
      return {
        ...instrument,
        instrumentReference: instrument.instrumentReference,
      }
    })
    const instrumentReferences = instruments
      .map((instrument: CompositionComponentInstrument) => instrument?.instrumentReference)
      .filter(notEmpty)
    // fetch all instruments in the subscribed instrument set that intersect the set composition.instruments
    const filteredSubscribedInstrumentsMap =
      await InstrumentServiceMock.batchFetchFilteredSubscribedInstruments(instrumentReferences)
    // retrieve the symmetric difference of the sets subscribedInstrument and composition.instruments
    const missingInstrumentReferences = await InstrumentServiceAPI.symmetricDifferenceSubscribedComposition(
      filteredSubscribedInstrumentsMap,
      instrumentReferences
    )

    const instrumentMap = new Map<InstrumentReferenceString, Instrument>(
      InstrumentServiceAPI.intersectSubscribedComposition(filteredSubscribedInstrumentsMap, instrumentReferences)
    )

    const updatedComposition = InstrumentServiceAPI.updateCompositionWithMissingReferences(
      composition,
      missingInstrumentReferences
    )
    return { composition: updatedComposition, instrumentMap, missingInstrumentReferences }
  },
}

const instruments = [sampleCompositeInstrument(), sampleNonCompositeInstrument()]

const instrumentsMap = new Map<InstrumentReference, Instrument>(
  instruments.flatMap((instrument) =>
    instrument.instrumentReferences.map((instrumentReference: InstrumentReference) => [instrumentReference, instrument])
  )
)
