import React from 'react'
import { Skeleton } from '@mui/material'

interface SkeletonRowsProps {
  count: number
}
export const SkeletonRows: React.FC<SkeletonRowsProps> = ({ count }) => {
  const rows = []
  for (let i = 0; i < count; i += 1) {
    rows.push(<Skeleton key={i} />)
  }

  return <>{rows}</>
}
