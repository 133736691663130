import * as React from 'react';
import styled, { keyframes } from 'styled-components';
export const Loader = ({ style }) => {
    return React.createElement(Div, { style: style });
};
const Spin = keyframes `
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Div = styled.div `
  border: 16px solid ${({ theme }) => theme.colors.primary.dark};
  border-top: 16px solid ${({ theme }) => theme.colors.success.light};
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: auto;
  animation: ${Spin} 2s linear infinite;
`;
