import * as React from 'react'
import styled from 'styled-components'
import { SkeletonRows } from '../../buildingBlocks/Skeleton'
import { BaseCardlet, CardletTop, CardletBottom } from './BaseCardlet'

export const SkeletonCardlet: React.FC = () => {
  return (
    <BaseCardlet>
      <CardTop />
      <CardBottom />
    </BaseCardlet>
  )
}

const CardTop: React.FC = () => (
  <CardletTop>
    <TopContainer>
      <OrgName>
        <SkeletonRows count={1} />
      </OrgName>
      <Tag>
        <SkeletonRows count={1} />
      </Tag>
    </TopContainer>
    <ContentPlaceholder>
      <FlexItem>
        <SkeletonRows count={2} />
        <LastRow>
          <SkeletonRows count={1} />
        </LastRow>
      </FlexItem>
    </ContentPlaceholder>
  </CardletTop>
)

const CardBottom: React.FC = () => (
  <CardletBottom>
    <BottomPlaceholder>
      <BottomItem>
        <SkeletonRows count={1} />
      </BottomItem>
      <BottomItem>
        <SkeletonRows count={1} />
      </BottomItem>
    </BottomPlaceholder>
  </CardletBottom>
)

const TopContainer = styled.div`
  height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const ContentPlaceholder = styled.div`
  height: 10rem;
  display: flex;
  align-items: center;
`

const FlexItem = styled.div`
  flex: 1;
`

const OrgName = styled.div`
  width: 13rem;
`

const Tag = styled.div`
  width: 2rem;
`

const LastRow = styled.div`
  width: 15rem;
`

const BottomPlaceholder = styled.div`
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BottomItem = styled.div`
  width: 10rem;
`
