import React from 'react';
import { BlankModal } from './BlankModal';
import { ModalBody } from './ModalBody';
import { ModalFooter } from './ModalFooter';
import { ModalHeader } from './ModalHeader';
import { ModalHeaderWithProgress } from './ModalHeaderWithProgress';
export const Modal = (props) => {
    const { open, type, onCloseCallback, onBackCallback, media, body, headerType, className, children } = props;
    const fullScreen = type === 'fullscreen' || type === 'fullscreen-action';
    const footer = () => {
        if (type === 'action' || type === 'fullscreen-action') {
            const { stickyButtons } = props;
            return React.createElement(ModalFooter, { stickyButtons: stickyButtons });
        }
        return null;
    };
    const header = () => {
        if (headerType === 'progress') {
            const { progress } = props;
            return (React.createElement(ModalHeaderWithProgress, { onCloseCallback: onCloseCallback, onBackCallback: onBackCallback, progress: progress }));
        }
        const { title } = props;
        return React.createElement(ModalHeader, { onCloseCallback: onCloseCallback, onBackCallback: onBackCallback, title: title });
    };
    return (React.createElement(BlankModal, { open: open, onCloseCallback: onCloseCallback, fullScreen: fullScreen, className: className },
        header(),
        React.createElement(ModalBody, { media: media, body: body, fullScreen: fullScreen }, children),
        footer()));
};
