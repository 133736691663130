import * as React from 'react';
export const SvgTwopeoplesmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#E9EEE8" }),
        React.createElement("path", { d: "M9 12C10.1 12 11.14 11.65 12 11C12.53 11.4 13.14 11.69 13.79 11.85C14.19 11.95 14.59 12 15 12C15.6 12 16.2 11.89 16.77 11.68C17.72 11.32 18.53 10.68 19.11 9.85001C19.69 9.01001 20 8.03001 20 7.01001C20 5.99001 19.69 5.01001 19.11 4.17001C18.53 3.33001 17.72 2.70001 16.77 2.34001C15.82 1.98001 14.79 1.92001 13.8 2.16001C13.14 2.32001 12.54 2.61001 12.01 3.01001C11.15 2.36001 10.11 2.01001 9.01 2.01001C7.67 2.01001 6.42 2.53001 5.47 3.47001C4.53 4.42001 4.01 5.67001 4.01 7.01001C4.01 8.35001 4.53 9.60001 5.47 10.55C6.41 11.49 7.67 12.01 9.01 12.01L9 12ZM14.28 4.09001C14.87 3.94001 15.49 3.98001 16.06 4.19001C16.63 4.41001 17.12 4.79001 17.47 5.29001C17.82 5.79001 18 6.38001 18 7.00001C18 7.62001 17.82 8.20001 17.47 8.71001C17.12 9.21001 16.64 9.59001 16.06 9.81001C15.49 10.03 14.87 10.06 14.28 9.92001C13.94 9.84001 13.62 9.70001 13.34 9.50001C13.77 8.75001 14.01 7.89001 14.01 7.00001C14.01 6.11001 13.78 5.25001 13.34 4.50001C13.63 4.31001 13.94 4.17001 14.28 4.08001V4.09001ZM6.88 4.88001C7.44 4.32001 8.21 4.00001 9 4.00001C9.79 4.00001 10.56 4.32001 11.12 4.88001C11.69 5.45001 12 6.20001 12 7.00001C12 7.80001 11.69 8.55001 11.12 9.12001C10 10.24 8 10.24 6.88 9.12001C6.31 8.55001 6 7.80001 6 7.00001C6 6.20001 6.31 5.45001 6.88 4.88001ZM21.06 16.5C20.45 15.44 19.56 14.55 18.5 13.94C16.54 12.81 14.04 12.71 12 13.68C11.07 13.24 10.05 13.01 9 13.01C7.13 13.01 5.37 13.74 4.05 15.06C2.73 16.38 2 18.14 2 20.01V21.01C2 21.56 2.45 22.01 3 22.01H21C21.55 22.01 22 21.56 22 21.01V20.01C22 18.78 21.68 17.57 21.06 16.51V16.5ZM4 20C4 18.66 4.52 17.41 5.46 16.46C6.4 15.51 7.66 15 9 15C9.88 15 10.72 15.22 11.46 15.65C11.48 15.67 11.51 15.68 11.54 15.69C11.9 15.9 12.24 16.16 12.54 16.47C13.48 17.41 14 18.67 14 20.01H4V20ZM16 20C16 18.15 15.29 16.42 14 15.1C15.18 14.86 16.45 15.06 17.5 15.67C18.26 16.11 18.89 16.74 19.33 17.5C19.77 18.26 20 19.12 20 20H16Z", fill: "#2E5E22" })));
};
