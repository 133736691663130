import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const ChatIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 27.9 25.67" },
        React.createElement("path", { d: "M4.88 10.78h5.9a.45.45 0 0 0 0-.91h-5.9a.45.45 0 0 0 0 .9z" }),
        React.createElement("path", { d: "M16.67 12.59H4.88a.45.45 0 1 0 0 .9h11.79a.45.45 0 1 0 0-.9z" }),
        React.createElement("path", { d: "M16.67 15.31H4.88a.45.45 0 1 0 0 .9h11.79a.45.45 0 1 0 0-.9z" }),
        React.createElement("path", { d: "M24.86.39 9.4.35a2.7 2.7 0 0 0-2.7 2.69v2.32H3.05a2.7 2.7 0 0 0-2.69 2.7v9.58a2.7 2.7 0 0 0 2.69 2.69h2.75v4.53a.45.45 0 0 0 .79.3l4.4-4.83 7.54-.03a2.7 2.7 0 0 0 2.68-2.7v-.04l2.39 2.63a.45.45 0 0 0 .79-.31v-4.54h.48a2.7 2.7 0 0 0 2.69-2.69V3.07A2.7 2.7 0 0 0 24.86.39zM20.3 17.6a1.78 1.78 0 0 1-1.79 1.78l-7.73.03a.45.45 0 0 0-.34.15L6.7 23.69v-3.81a.45.45 0 0 0-.46-.46h-3.2a1.78 1.78 0 0 1-1.78-1.78V8.06a1.78 1.78 0 0 1 1.78-1.79h4.11l11.36-.03a1.78 1.78 0 0 1 1.79 1.78v9.59zm6.34-4.96a1.78 1.78 0 0 1-1.78 1.79h-.94a.45.45 0 0 0-.45.45v3.8l-2.27-2.48V8.02a2.7 2.7 0 0 0-2.69-2.68l-10.9.02V3.04a1.78 1.78 0 0 1 1.77-1.78l15.48.02a1.78 1.78 0 0 1 1.78 1.79v9.58z" })));
};
