import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { RootState } from '../../rootReducer'
import { fetchInvestorVotes } from './asyncActions'
import * as InvestorVotesSelectors from './selectors'

export function useInvestorVotes() {
  const dispatch = useAppDispatch()
  const { investorVotes, favouriteCategory } = useAppSelector((state: RootState) => {
    return {
      investorVotes: InvestorVotesSelectors.selectInvestorVotes(state),
      favouriteCategory: InvestorVotesSelectors.selectInvestorFavouriteVoteCategory(state),
    }
  })

  useEffect(() => {
    if (investorVotes === 'not-initialised') {
      dispatch(fetchInvestorVotes())
    }
  }, [investorVotes, dispatch])

  return { investorVotes, favouriteCategory }
}
