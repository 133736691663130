import { Timestamp } from '@tumelo/shared'

export interface AppNotification {
  id: string
  type: AppNotificationType
  title: string
  logo?: string
  link: string
  createTime: Timestamp
  resolution?: ResolutionType
  source: AppNotificationSource
}

export type ResolutionType = 'dismissed' | 'opened' | 'unresolved' | 'unrecognized'

export type AppNotificationType =
  | 'openVotes'
  | 'openVote'
  | 'voteResult'
  | 'caseStudies'
  | 'impact'
  | 'news'
  | 'newFeatures'
  | 'userResearch'

export type AppNotificationSource = 'platform-internal' | 'static'

export function readableAppNotification(type: AppNotificationType) {
  switch (type) {
    case 'openVote':
      return 'Open Vote'
    case 'openVotes':
      return 'Open Votes'
    case 'voteResult':
      return 'Vote Result'
    case 'caseStudies':
      return 'Case Studies'
    case 'news':
      return 'News'
    case 'newFeatures':
      return 'New Features'
    case 'userResearch':
      return 'User Research'
    case 'impact':
      return 'Impact'
    default:
      return 'Open Votes'
  }
}

export const notificationIsResolved = (notification: AppNotification): boolean =>
  notification.resolution !== 'unresolved'
