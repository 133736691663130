/* eslint-disable @typescript-eslint/no-deprecated */
import React from 'react';
import styled from 'styled-components';
import { Caption } from '../../../../styles';
export const FilterList = ({ caption, clearAll, children }) => {
    return (React.createElement(Row, null,
        React.createElement(Caption, { weight: "600" }, caption),
        React.createElement(ClearAll, { onClick: () => clearAll() }, "Clear all"),
        children));
};
const Row = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
`;
const ClearAll = styled(Caption) `
  cursor: pointer;
  color: ${({ theme }) => theme.colors.grey.dark};
  text-decoration: underline;
  font-weight: 600;
  margin-bottom: 1rem;
`;
