import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ballot, FundManager, FundManagerId, FundManagerVote, Organization, Poll, PollId } from '@tumelo/shared'
import { createErrorNotFound, Payload } from '../../payload'
import { FundManagerVoteWithFundManager } from './types'

interface State {
  ballots: BallotMap
  pollAndOrganizations: PollAndOrganizationMap
  fundManagerVotesWithFundManagers: FundManagerVotesWithFundManagerMap
}

export type BallotMap = {
  [pollId: string]: Payload<Ballot>
}

export type PollAndOrganizationMap = {
  [pollId: string]: Payload<{ poll: Poll; organization: Organization }>
}

export type FundManagerVotesWithFundManagerMap = {
  [pollId: string]: Payload<FundManagerVoteWithFundManager[]>
}

const initialState: State = {
  ballots: {},
  pollAndOrganizations: {},
  fundManagerVotesWithFundManagers: {},
}

const ballotSingleSlice = createSlice({
  name: 'singleBallot',
  initialState,
  reducers: {
    setBallot: (state, action: PayloadAction<{ pollId: PollId; ballot: Ballot }>) => {
      state.ballots[action.payload.pollId] = action.payload.ballot
    },
    setBallotError: (state, action: PayloadAction<PollId>) => {
      state.ballots[action.payload] = 'error'
    },
    setBallotNotFound: (state, action: PayloadAction<PollId>) => {
      state.ballots[action.payload] = createErrorNotFound('Ballot')
    },
    setBallotPending: (state, action: PayloadAction<PollId>) => {
      state.ballots[action.payload] = 'pending'
    },
    setPollAndOrganization: (
      state,
      action: PayloadAction<{ pollId: PollId; pollAndOrganization: { poll: Poll; organization: Organization } }>
    ) => {
      state.pollAndOrganizations[action.payload.pollId] = action.payload.pollAndOrganization
    },
    setPollAndOrganizationError: (state, action: PayloadAction<PollId>) => {
      state.pollAndOrganizations[action.payload] = 'error'
    },
    setPollAndOrganizationNotFound: (state, action: PayloadAction<PollId>) => {
      state.pollAndOrganizations[action.payload] = createErrorNotFound('Poll')
    },
    setPollAndOrganizationPending: (state, action: PayloadAction<PollId>) => {
      state.pollAndOrganizations[action.payload] = 'pending'
    },
    setFundManagerVotesWithFundManager: (
      state,
      action: PayloadAction<{
        pollId: string
        fundManagersAndVotes: {
          fundManagerVotes: FundManagerVote[]
          fundManagersMap: Map<FundManagerId, FundManager>
        }
      }>
    ) => {
      const { fundManagerVotes, fundManagersMap } = action.payload.fundManagersAndVotes
      state.fundManagerVotesWithFundManagers[action.payload.pollId] = fundManagerVotes.map((fmv) => {
        const fundManager = fundManagersMap.get(fmv.fundManagerId) as FundManager
        return {
          ...fmv,
          fundManager,
        }
      })
    },
    setFundManagerVotesWithFundManagerError: (state, action: PayloadAction<string>) => {
      state.fundManagerVotesWithFundManagers[action.payload] = 'error'
    },
    setFundManagerVotesWithFundManagerNotFound: (state, action: PayloadAction<string>) => {
      state.fundManagerVotesWithFundManagers[action.payload] = createErrorNotFound('Poll')
    },
    setFundManagerVotesWithFundManagerPending: (state, action: PayloadAction<string>) => {
      state.fundManagerVotesWithFundManagers[action.payload] = 'pending'
    },
  },
})

export const {
  setBallot,
  setBallotError,
  setBallotNotFound,
  setBallotPending,
  setPollAndOrganization,
  setPollAndOrganizationError,
  setPollAndOrganizationNotFound,
  setPollAndOrganizationPending,
  setFundManagerVotesWithFundManager,
  setFundManagerVotesWithFundManagerError,
  setFundManagerVotesWithFundManagerNotFound,
  setFundManagerVotesWithFundManagerPending,
} = ballotSingleSlice.actions
export default ballotSingleSlice.reducer
