import React from 'react'
import { sendUserAttributeVerificationCode } from 'aws-amplify/auth'
import { I18n } from 'aws-amplify/utils'
import { useAppDispatch } from '../../application/store'
import { useRouter } from '../../application/router/useRouter'
import { ConfirmInvestorEmailWithCode } from '../../application/features/investor/asyncActions'
import { useInvestor } from '../../application/features/investor/useInvestor'
import { isValueState } from '../../application/payload'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'
import { ConfirmCodeForm, ConfirmCodeValues } from './ConfirmCodeForm'

export const ChangeEmailVerifyCodePage: React.FC = () => {
  const { investor } = useInvestor()
  const { navigate } = useRouter()

  const dispatch = useAppDispatch()
  React.useEffect(() => {
    if (isValueState(investor)) {
      setError(investor.changeEmail.error)
    }
  }, [investor])

  const [error, setError] = React.useState<string | undefined>(undefined)

  const onSubmit = async (values: ConfirmCodeValues) => {
    try {
      setError(undefined)
      dispatch(ConfirmInvestorEmailWithCode(values.code))
      navigate('/profile')
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- typescript getting stricter in errors
      // @ts-ignore
      setError(e.message)
    }
  }

  const onResendCode = async () => {
    try {
      await sendUserAttributeVerificationCode({ userAttributeKey: 'email' })
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- typescript getting stricter in errors
      // @ts-ignore
      setError(I18n.get(e.message))
    }
  }

  return (
    <PayloadUnwrapper item={investor}>
      {({ email }) => <ConfirmCodeForm submit={onSubmit} resendCode={onResendCode} email={email} error={error} />}
    </PayloadUnwrapper>
  )
}
