import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgDownload = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13 2.94C13 2.67478 12.8946 2.42043 12.7071 2.23289C12.5196 2.04536 12.2652 1.94 12 1.94C11.7348 1.94 11.4804 2.04536 11.2929 2.23289C11.1054 2.42043 11 2.67478 11 2.94V12.53L7.72 9.24C7.62742 9.14676 7.51738 9.07267 7.39616 9.02196C7.27495 8.97125 7.14493 8.94491 7.01354 8.94444C6.88214 8.94398 6.75194 8.9694 6.63037 9.01925C6.5088 9.06911 6.39824 9.14242 6.305 9.235C6.1167 9.42198 6.01038 9.6761 6.00944 9.94147C6.00851 10.2068 6.11302 10.4617 6.3 10.65L11.3 15.65C11.3922 15.74 11.5008 15.8113 11.62 15.86C11.7417 15.9052 11.8702 15.9289 12 15.93C12.2609 15.9316 12.5121 15.8311 12.7 15.65L17.7 10.65C17.8534 10.4583 17.9309 10.2169 17.9177 9.97177C17.9046 9.72662 17.8018 9.49488 17.6288 9.32067C17.4558 9.14647 17.2248 9.04197 16.9798 9.0271C16.7347 9.01222 16.4928 9.088 16.3 9.24L13 12.53V2.94ZM3 13.94C3.26522 13.94 3.51957 14.0454 3.70711 14.2329C3.89464 14.4204 4 14.6748 4 14.94V18.94C4 19.2052 4.10536 19.4596 4.29289 19.6471C4.48043 19.8346 4.73478 19.94 5 19.94H19C19.1316 19.9408 19.2621 19.9155 19.3839 19.8658C19.5057 19.816 19.6166 19.7427 19.71 19.65C19.8027 19.5566 19.876 19.4457 19.9258 19.3239C19.9755 19.2021 20.0008 19.0716 20 18.94V14.94C20 14.6748 20.1054 14.4204 20.2929 14.2329C20.4804 14.0454 20.7348 13.94 21 13.94C21.2652 13.94 21.5196 14.0454 21.7071 14.2329C21.8946 14.4204 22 14.6748 22 14.94V18.94C22 19.7356 21.6839 20.4987 21.1213 21.0613C20.5587 21.6239 19.7956 21.94 19 21.94H5C4.20435 21.94 3.44129 21.6239 2.87868 21.0613C2.31607 20.4987 2 19.7356 2 18.94V14.94C2 14.6748 2.10536 14.4204 2.29289 14.2329C2.48043 14.0454 2.73478 13.94 3 13.94Z" })));
};
