import React from 'react';
import styled from 'styled-components';
import { Body2, Stack, Button, screen } from '../..';
export const CookieBanner = ({ onClickAccept, onClickReject, children, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledDiv, null,
            React.createElement(Stack, { flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' }, alignItems: "center" },
                React.createElement(Body2, null,
                    "This site uses cookies to enhance the user experience. Here's our",
                    ' ',
                    React.createElement("a", { style: { textDecoration: 'underline' }, href: "https://www.tumelo.com/cookies-policy", rel: "noopener noreferrer", target: "_blank" }, "cookie policy"),
                    "."),
                React.createElement(Stack, { flexDirection: "row", gap: "0.8rem" },
                    React.createElement(StyledButton, { variant: "tertiary", onClick: onClickAccept }, "Ok"),
                    React.createElement(StyledButton, { variant: "tertiary", onClick: onClickReject }, "Decline")))),
        children));
};
const StyledDiv = styled.div `
  box-shadow: ${({ theme }) => theme.boxShadows.one};
  display: flex;
  flex-direction: column;
  min-height: 48px;
  padding: 2.4rem;
  background-color: rgb(255, 255, 255);
  color: rgb(58, 60, 98);
  outline: none;
  pointer-events: all;
  z-index: 20;
  position: absolute;
  max-height: calc((100% - 48px) - 48px);
  max-width: calc((100% - 48px) - 48px);
  border-radius: 4px;
  bottom: 48px;
  left: 50%;
  transform: translate(-50%, 0px);
  animation: 0.2s ease-in-out 0s 1 normal forwards running cookiebanner;

  @keyframes cookiebanner {
    0% {
      transform: translate(-50%, 100%);
    }

    100% {
      transform: translate(-50%, 0px);
    }
  }
`;
const StyledButton = styled(Button) `
  width: 100%;

  @media ${screen.smallUp} {
    min-width: auto;
  }
`;
