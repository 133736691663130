/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "tumelo.dashboardbff.v1";

export interface InvestorAccount {
  id: string;
  title: string;
  modelPortfolioId: string;
}

function createBaseInvestorAccount(): InvestorAccount {
  return { id: "", title: "", modelPortfolioId: "" };
}

export const InvestorAccount = {
  encode(message: InvestorAccount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.modelPortfolioId !== "") {
      writer.uint32(26).string(message.modelPortfolioId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InvestorAccount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInvestorAccount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.modelPortfolioId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InvestorAccount {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      modelPortfolioId: isSet(object.modelPortfolioId) ? String(object.modelPortfolioId) : "",
    };
  },

  toJSON(message: InvestorAccount): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.modelPortfolioId !== "") {
      obj.modelPortfolioId = message.modelPortfolioId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InvestorAccount>, I>>(base?: I): InvestorAccount {
    return InvestorAccount.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InvestorAccount>, I>>(object: I): InvestorAccount {
    const message = createBaseInvestorAccount();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.modelPortfolioId = object.modelPortfolioId ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
