import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HabitatStatistics, InvestorStatistics } from '@tumelo/shared'
import { Payload } from '../../payload'

interface State {
  investorStatistics: Payload<InvestorStatistics>
  habitatStatistics: Payload<HabitatStatistics>
}

const initialState: State = {
  investorStatistics: 'not-initialised',
  habitatStatistics: 'not-initialised',
}

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setInvestorStatistics: (state, action: PayloadAction<InvestorStatistics>) => {
      state.investorStatistics = action.payload
    },
    setInvestorStatisticsPending: (state) => {
      state.investorStatistics = 'pending'
    },
    setInvestorStatisticsError: (state) => {
      state.investorStatistics = 'error'
    },
    setHabitatStatistics: (state, action: PayloadAction<HabitatStatistics>) => {
      state.habitatStatistics = action.payload
    },
    setHabitatStatisticsPending: (state) => {
      state.habitatStatistics = 'pending'
    },
    setHabitatStatisticsError: (state) => {
      state.habitatStatistics = 'error'
    },
  },
})

export const {
  setInvestorStatistics,
  setInvestorStatisticsPending,
  setInvestorStatisticsError,
  setHabitatStatistics,
  setHabitatStatisticsPending,
  setHabitatStatisticsError,
} = statisticsSlice.actions

export default statisticsSlice.reducer
