import * as React from 'react';
export const SvgNewdealssmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#E3F3F3" }),
        React.createElement("path", { d: "M4 6V7C4 7.55 4.45 8 5 8C5.55 8 6 7.55 6 7V6H7C7.55 6 8 5.55 8 5C8 4.45 7.55 4 7 4H6V3C6 2.45 5.55 2 5 2C4.45 2 4 2.45 4 3V4H3C2.45 4 2 4.45 2 5C2 5.55 2.45 6 3 6H4ZM8 18H7V17C7 16.45 6.55 16 6 16C5.45 16 5 16.45 5 17V18H4C3.45 18 3 18.45 3 19C3 19.55 3.45 20 4 20H5V21C5 21.55 5.45 22 6 22C6.55 22 7 21.55 7 21V20H8C8.55 20 9 19.55 9 19C9 18.45 8.55 18 8 18ZM20.41 11.09L16.07 9.11L13.93 3.63C13.78 3.25 13.41 2.99 13 2.99C12.59 2.99 12.22 3.24 12.07 3.63L9.93 9.11L5.59 11.09C5.23 11.25 5 11.61 5 12C5 12.39 5.23 12.75 5.59 12.91L9.93 14.88L12.07 20.36C12.22 20.74 12.59 21 13 21C13.41 21 13.78 20.75 13.93 20.36L16.07 14.88L20.41 12.91C20.77 12.75 21 12.39 21 12C21 11.61 20.77 11.25 20.41 11.09ZM14.87 13.23C14.63 13.34 14.45 13.53 14.35 13.78L13 17.25L11.65 13.78C11.56 13.54 11.37 13.34 11.13 13.23L8.42 12L11.13 10.77C11.37 10.66 11.55 10.47 11.65 10.22L13 6.75L14.35 10.22C14.44 10.46 14.63 10.66 14.87 10.77L17.58 12L14.87 13.23Z", fill: "#1D7A6C" })));
};
