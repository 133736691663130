import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const VotePassedIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 20 20", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { id: "Vote_passed_Tick_icon", transform: "translate(-528.529 -578.298)" },
            React.createElement("circle", { id: "Ellipse_1405", cx: "10", cy: "10", r: "10", fill: "rgba(46,182,185,0.1)", transform: "translate(528.529 578.298)" }),
            React.createElement("g", { id: "Vote_passed_Tick_icon-2" },
                React.createElement("g", { id: "Ellipse_1401" },
                    React.createElement("path", { id: "Path_13650", d: "M538.529 598.3a10 10 0 1 1 10-10 10.012 10.012 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8z" })),
                React.createElement("path", { id: "Path_13651", d: "M536.377 593.775l-4.32-4.322 1.414-1.414 2.906 2.908 7.418-7.417 1.414 1.414z" })))));
};
