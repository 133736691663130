import styled from 'styled-components';
export const CardSegment = styled.div `
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  padding: 1rem 2rem;
  box-shadow: 0 -0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1);
  &:first-child {
    border-radius: 2rem 2rem 0 0;
  }
  &:last-child {
    border-radius: 0 0 2rem 2rem;
  }
`;
