import React from 'react';
import styled, { keyframes } from 'styled-components';
export const Counter = ({ count }) => {
    return (React.createElement(Container, { visible: !!count },
        React.createElement("div", null, count)));
};
const RevealAndBounce = keyframes `
    0%   { opacity: 0; }
    19%  { opacity: 0; }
    20%  { opacity: 1 transform: scale(1,1) translateY(0); }
    30%  { transform: scale(1.1,.9) translateY(0); }
    50%  { transform: scale(.9,1.1) translateY(-10px); }
    70%  { transform: scale(1,1)    translateY(0); }
    100% { transform: scale(1,1)    translateY(0); }
`;
const Container = styled.div `
  background: ${({ theme }) => theme.colors.secondary.medium};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 2rem;
  height: 2rem;
  width: 2rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  margin-left: auto;
  display: ${(p) => (p.visible ? 'flex' : 'none')};
  animation: ${RevealAndBounce} 1s;
`;
