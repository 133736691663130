import React, { useEffect } from 'react'
import { Modal, StickyButtonsProps, Body2 } from '@tumelo/designsystem'
import { VotingPolicySelection } from '@tumelo/shared'
import { isIntermediaryState, Payload } from '../../application/payload'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'
import { InlineError } from '../Error'

interface Props {
  open: boolean
  handleModalClose: () => void
  policySelection: Payload<VotingPolicySelection | undefined>
  handleDelete: () => void
  updateVotingPolicySelection: Payload<'not-updating'>
}

export const DeleteVotingPolicyModal: React.FC<Props> = ({
  open,
  handleModalClose,
  handleDelete,
  policySelection,
  updateVotingPolicySelection,
}) => {
  const disableButtons = isIntermediaryState(updateVotingPolicySelection) || policySelection === undefined

  const primaryButtonCopy = updateVotingPolicySelection === 'error' ? 'Try again' : 'Delete my policy'

  const modalButtons: StickyButtonsProps = [
    { label: "I've changed my mind", isDisabled: disableButtons, variant: 'secondary', onClick: handleModalClose },
    {
      label: primaryButtonCopy,
      isDisabled: disableButtons,
      onClick: handleDelete,
      variant: 'primary',
    },
  ]

  useEffect(() => {
    if (policySelection === undefined) {
      handleModalClose()
    }
  }, [policySelection, handleModalClose])

  return (
    <Modal
      title="Deleting your vote policy"
      open={open}
      headerType="title"
      type="action"
      onCloseCallback={handleModalClose}
      stickyButtons={modalButtons}
    >
      <PayloadUnwrapper item={updateVotingPolicySelection} customSpinner={<ModalCopy />} customError={<CustomError />}>
        {() => <ModalCopy />}
      </PayloadUnwrapper>
    </Modal>
  )
}

const ModalCopy: React.FC = () => (
  <>
    <Body2>Did you know you can change your policy to reflect your current values?</Body2>
    <Body2>If you want to stop using vote policies entirely, then click the button below.</Body2>
  </>
)

const CustomError: React.FC = () => {
  const msg = `Sorry, something unexpected has happened. Try again or you can refresh the page, if the problem persists`
  const supportNode = (
    <>
      <a href="https://tumelo.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
        get in touch
      </a>{' '}
      with our support team
    </>
  )
  return (
    <InlineError title="Something went wrong...">
      <span>
        {msg} {supportNode}.
      </span>
    </InlineError>
  )
}
