/* eslint-disable @typescript-eslint/no-deprecated */
import React from 'react';
import styled from 'styled-components';
import { ClearRounded } from '@mui/icons-material';
import { hexAlpha } from '../../utils';
import { ButtonText } from '..';
export const TagButton = ({ title, onClick }) => (React.createElement(Wrapper, null,
    React.createElement(Tag, { onClick: onClick },
        React.createElement(ButtonText, { m: "0" }, title),
        React.createElement(ClearRounded, { style: { color: 'inherit', fontSize: '1.8rem', position: 'absolute', top: '0.5rem', right: '0.8rem' } }))));
const Tag = styled.button `
  padding: 0.8rem 3.1rem 0.8rem 0.9rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  * {
    color: ${({ theme }) => theme.colors.primary.medium};
  }
  background: ${({ theme }) => hexAlpha(theme.colors.success.dark, 0.2)};
  position: relative;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  min-width: 80px;
  &:hover {
    background: ${({ theme }) => hexAlpha(theme.colors.success.dark, 0.15)};
  }
`;
// this ensures the transparent colour of the tag is always the same
const Wrapper = styled.div `
  background: ${({ theme }) => theme.colors.white};
  display: inline-block;
  border-radius: 5px;
`;
