import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgMail = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M20.25 3H3.75C3.02767 2.99466 2.33267 3.27582 1.81723 3.7819C1.30178 4.28797 1.00792 4.97769 1 5.7V18.3C1.00792 19.0223 1.30178 19.712 1.81723 20.2181C2.33267 20.7242 3.02767 21.0053 3.75 21H20.25C20.9723 21.0053 21.6673 20.7242 22.1828 20.2181C22.6982 19.712 22.9921 19.0223 23 18.3V5.7C22.9921 4.97769 22.6982 4.28797 22.1828 3.7819C21.6673 3.27582 20.9723 2.99466 20.25 3ZM3.75 4.8H20.25C20.4755 4.79911 20.693 4.88287 20.8597 5.03472C21.0264 5.18656 21.13 5.39543 21.15 5.62L12 11L2.85 5.62C2.87004 5.39543 2.97364 5.18656 3.1403 5.03472C3.30696 4.88287 3.52454 4.79911 3.75 4.8ZM20.25 19.2H3.75C3.6305 19.2013 3.5119 19.1791 3.40099 19.1346C3.29008 19.0901 3.18902 19.0241 3.10359 18.9406C3.01816 18.857 2.95002 18.7574 2.90308 18.6475C2.85614 18.5376 2.83131 18.4195 2.83 18.3V7.7L11.53 12.77C11.6718 12.8556 11.8344 12.9008 12 12.9008C12.1656 12.9008 12.3282 12.8556 12.47 12.77L21.17 7.7V18.3C21.1687 18.4195 21.1439 18.5376 21.0969 18.6475C21.05 18.7574 20.9818 18.857 20.8964 18.9406C20.811 19.0241 20.7099 19.0901 20.599 19.1346C20.4881 19.1791 20.3695 19.2013 20.25 19.2Z" })));
};
