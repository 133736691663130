import { ModelPortfolio, ModelPortfolioId } from '@tumelo/shared'
import {
  PortfolioSelectionFlow,
  SelfSelectForm,
  SelfSelect as SelfSelectConfig,
} from '../../../../config/SoftConfigPortfolioSelection'

interface SelfSelectFlow extends SelfSelectConfig {
  portfolios: ModelPortfolio[]
  defaultPortfolioId: ModelPortfolioId
}

export type RuntimeFlow = SelfSelectFlow | SelfSelectForm

const getModelPortfolioIds = (flow: SelfSelectForm): string[] =>
  flow.questions.flatMap((q) => {
    if (q.type === 'binary') {
      return q.options.flatMap((q) => (q.type === 'modelPortfolio' ? [q.modelPortfolioId] : []))
    }
    if (q.type === 'list-all') {
      return []
    }
    return q.modelPortfolioIds
  })

// pure fn to encapsulate logic around selecting the flow from config flows and ids
export const flowSelector = (
  availableModelPortfolios: ModelPortfolio[],
  flow: PortfolioSelectionFlow,
  defaultModelPortfolioId: ModelPortfolioId | undefined
): RuntimeFlow => {
  switch (flow.type) {
    case 'buck': {
      throw new Error('account should already be configured when selection flow is buck')
    }
    case 'self-select': {
      if (availableModelPortfolios.length === 0) {
        throw new Error('no available portfolios')
      }
      if (!defaultModelPortfolioId) {
        throw new Error('default model portfolio id not configured')
      }
      if (availableModelPortfolios.find(({ id }) => id === defaultModelPortfolioId) === undefined) {
        throw new Error('configured default portfolio id does not exist in list of available model portfolios')
      }
      return {
        portfolios: availableModelPortfolios,
        defaultPortfolioId: defaultModelPortfolioId,
        ...flow,
      }
    }
    case 'self-select-form': {
      if (availableModelPortfolios.length === 0) {
        throw new Error('no available portfolios')
      }
      const modelPortfolioIds = getModelPortfolioIds(flow)
      const unknownModelPortfolios = modelPortfolioIds.filter(
        (mpid) => !availableModelPortfolios.find((m) => m.id === mpid)
      )
      if (unknownModelPortfolios.length !== 0) {
        throw new Error('one or more configured model portfolio ids is not recognised')
      }
      return flow
    }
    default:
      throw new Error('flow not implemented')
  }
}
