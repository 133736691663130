import { createSelector } from '@reduxjs/toolkit'
import { Poll } from '@tumelo/shared'
import { Selector } from '../../../utils/redux'
import { Payload } from '../../payload'
import { RootState } from '../../rootReducer'
import { pollsAdapter, PollsStatusType } from '.'

const selectPollsSlice = (state: RootState) => state.polls

const { selectAll } = pollsAdapter.getSelectors()

export const selectPolls: Selector<Payload<Poll[]>> = createSelector(
  selectPollsSlice,
  Payload.map((s) => selectAll(s.polls))
)

export const selectPollsStatus: Selector<PollsStatusType> = createSelector(selectPollsSlice, (s) => s.pollsStatus)
