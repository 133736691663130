import styled, { css } from 'styled-components';
import { screen } from '..';
export const PageWrapper = styled.div `
  height: 100%;
  max-width: 140rem;

  margin: 0 1.6rem;
  @media ${screen.smallUp} {
    margin: 0 2.4rem;
  }
  @media ${screen.mediumUp} {
    margin: 0 3.2rem;
  }
  @media ${screen.largeUp} {
    margin: 0 5.6rem;
  }

  ${({ center }) => center &&
    css `
      @media (min-width: 1512px) {
        // 1512 = max-width + margins
        margin: 0 auto;
      }
    `};
`;
