import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgArrowdown = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M13 19.08C12.878 19.1309 12.7472 19.1571 12.615 19.1571C12.4828 19.1571 12.352 19.1309 12.23 19.08C12.1108 19.0313 12.0021 18.96 11.91 18.87L4.91 11.87C4.81627 11.777 4.74187 11.6664 4.6911 11.5446C4.64034 11.4227 4.6142 11.292 4.6142 11.16C4.6142 11.028 4.64034 10.8973 4.6911 10.7754C4.74187 10.6536 4.81627 10.543 4.91 10.45C5.09736 10.2638 5.35081 10.1592 5.615 10.1592C5.87918 10.1592 6.13263 10.2638 6.32 10.45L11.61 15.74V4.16C11.61 3.89479 11.7154 3.64043 11.9029 3.4529C12.0904 3.26536 12.3448 3.16 12.61 3.16C12.8752 3.16 13.1296 3.26536 13.3171 3.4529C13.5046 3.64043 13.61 3.89479 13.61 4.16V15.74L18.91 10.45C19.0974 10.2638 19.3508 10.1592 19.615 10.1592C19.8792 10.1592 20.1326 10.2638 20.32 10.45C20.4137 10.543 20.4881 10.6536 20.5389 10.7754C20.5897 10.8973 20.6158 11.028 20.6158 11.16C20.6158 11.292 20.5897 11.4227 20.5389 11.5446C20.4881 11.6664 20.4137 11.777 20.32 11.87L13.32 18.87C13.2278 18.96 13.1192 19.0313 13 19.08Z" })));
};
