import React, { useState } from 'react'
import { Link } from '@tumelo/shared'
import { NotificationBar } from '../../buildingBlocks/NotificationBar'

export const BrowserWarning: React.FC = () => {
  // https://stackoverflow.com/questions/65259661/detecting-edge-legacy-browser-not-edge-chromium
  const isLegacyEdge = navigator.userAgent.indexOf('Edge/') > -1
  const [showWarning, setShowWarning] = useState(true)

  if (isLegacyEdge && showWarning)
    return (
      <NotificationBar title="We don't support your current version of Edge." dismiss={() => setShowWarning(false)}>
        Please update to the{' '}
        <Link href="https://www.microsoft.com/en-us/edge" rel="noopener noreferrer" target="_blank">
          latest version
        </Link>{' '}
        or use a different browser (such as{' '}
        <Link href="https://www.google.co.uk/chrome/" rel="noopener noreferrer" target="_blank">
          Chrome
        </Link>{' '}
        or{' '}
        <Link href="https://www.mozilla.org/en-GB/firefox/new/" rel="noopener noreferrer" target="_blank">
          Firefox
        </Link>
        ).
      </NotificationBar>
    )
  return null
}
