import * as React from 'react';
export const SvgAudit = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 40, height: 40, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 40, height: 40, rx: 4, fill: "#F3ECE8" }),
        React.createElement("path", { d: "M31.14 7.35999C26.65 3.27999 19.68 3.60999 15.6 8.09999C11.78 12.3 11.83 18.68 15.53 22.83L14.14 24.35L13.51 23.77C13.1 23.4 12.47 23.43 12.1 23.84L6.04 30.5C4.93 31.72 5.02 33.62 6.24 34.74C6.8 35.25 7.51 35.52 8.25 35.52C8.3 35.52 8.35 35.52 8.4 35.52C9.2 35.48 9.94 35.13 10.48 34.54L16.54 27.88C16.72 27.68 16.81 27.42 16.8 27.16C16.79 26.89 16.67 26.65 16.47 26.47L15.63 25.7L17 24.19C18.98 25.73 21.36 26.5 23.73 26.5C26.72 26.5 29.7 25.29 31.87 22.91C35.95 18.42 35.62 11.45 31.14 7.36999V7.35999ZM9 33.18C8.82 33.38 8.58 33.49 8.31 33.51C8.04 33.52 7.79 33.43 7.59 33.25C7.18 32.88 7.15 32.25 7.52 31.84L12.9 25.92L14.38 27.26L9 33.18ZM30.4 21.55C27.06 25.22 21.36 25.49 17.69 22.15C14.02 18.81 13.75 13.11 17.09 9.43999C18.86 7.48999 21.3 6.49999 23.75 6.49999C25.91 6.49999 28.08 7.26999 29.8 8.83999C33.47 12.18 33.74 17.88 30.4 21.55ZM28.45 10.31C25.6 7.71999 21.16 7.91999 18.56 10.78C17.3 12.16 16.66 13.95 16.75 15.82C16.84 17.69 17.65 19.41 19.03 20.67C20.33 21.85 21.99 22.49 23.73 22.49C23.84 22.49 23.96 22.49 24.07 22.49C25.94 22.4 27.66 21.59 28.92 20.21C31.52 17.35 31.31 12.92 28.45 10.32V10.31ZM27.44 18.85C26.54 19.84 25.31 20.42 23.98 20.48C22.64 20.54 21.37 20.08 20.38 19.18C18.34 17.33 18.19 14.16 20.05 12.12C21.04 11.04 22.39 10.49 23.75 10.49C24.95 10.49 26.16 10.92 27.11 11.79C29.15 13.65 29.3 16.81 27.44 18.85Z", fill: "#894A24" })));
};
