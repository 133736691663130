import * as React from 'react';
const DEFAULT_IMAGE_STYLE = {
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
};
export const Audit = ({ style }) => {
    const styleWithDefault = Object.assign(Object.assign({}, DEFAULT_IMAGE_STYLE), style);
    return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "prefix__Audit_Icon", width: "25", height: "25", "data-name": "Audit Icon", viewBox: "0 0 25 25" },
        React.createElement("path", { id: "prefix__BG", d: "M18.809 0H6.191A6.256 6.256 0 0 0 0 6.191v12.618A6.2 6.2 0 0 0 6.191 25h12.618A6.2 6.2 0 0 0 25 18.809V6.191A6.256 6.256 0 0 0 18.809 0z", fill: "#f3ece8" }),
        React.createElement("g", { id: "prefix__education", transform: "translate(3 3)" },
            React.createElement("path", { id: "prefix__Path_9819", d: "M187.135 57.6a5.549 5.549 0 1 0-5.549-5.549 5.555 5.555 0 0 0 5.549 5.549zm0-10.316a4.767 4.767 0 1 1-4.767 4.767 4.773 4.773 0 0 1 4.767-4.769zm0 0", fill: "#894a24", "data-name": "Path 9819", transform: "translate(-174.488 -44.682)" }),
            React.createElement("path", { id: "prefix__Path_9820", d: "M12.589 14.85a7.432 7.432 0 1 0-6.126-3.234L5.249 12.83l-.165-.165a.391.391 0 0 0-.553 0L.584 16.612A1.993 1.993 0 1 0 3.4 19.43l3.947-3.947a.391.391 0 0 0 0-.553l-.166-.165L8.4 13.55a7.382 7.382 0 0 0 4.189 1.3zm0-14.068a6.643 6.643 0 1 1-6.643 6.643A6.651 6.651 0 0 1 12.589.782zm-9.74 18.1a1.211 1.211 0 0 1-1.713-1.713l3.671-3.671.165.165 1.548 1.544zm3.782-4.666l-.831-.833 1.142-1.142a7.508 7.508 0 0 0 .829.829zm0 0", fill: "#894a24", "data-name": "Path 9820" }))));
};
