/* eslint-disable @typescript-eslint/no-deprecated */

import * as React from 'react'
import { useFormikContext, useField } from 'formik'
import styled, { css } from 'styled-components'
import { Caption, Text, Required } from '@tumelo/shared'
import { Checkbox } from '../../buildingBlocks/Checkbox'
import { StyledWarningIcon as WarningIcon } from './WarningIcon'

interface Props {
  name: string
  label: string | React.ReactNode
  required?: boolean
  type?: 'checkbox' | 'radio'
  showValidationErrorMessages?: boolean
  warningIconRightIndentRem?: number
  helperText?: string
}

export const CheckboxField: React.FC<Props> = ({
  name,
  label,
  required = false,
  type = 'checkbox',
  showValidationErrorMessages,
  warningIconRightIndentRem,
  helperText,
}) => {
  const { setFieldValue, getFieldMeta, setFieldTouched } = useFormikContext()
  const [field] = useField(name)

  const { touched, error } = getFieldMeta(name)

  const hasError = touched && !!error
  return (
    <Label as="label" hasError={hasError}>
      <Checkbox
        checked={field.value}
        onChange={async () => {
          await setFieldTouched(name, true)
          await setFieldValue(name, !field.value)
        }}
        type={type}
      />
      <LabelText>
        {required && <Required />}
        {label}
      </LabelText>
      {showValidationErrorMessages && hasError && (
        <HelperText isError={(hasError && showValidationErrorMessages) ?? false}>{helperText}</HelperText>
      )}
      {hasError && <WarningIcon top="center" indent={warningIconRightIndentRem} />}
    </Label>
  )
}

interface LabelProps {
  hasError: boolean
}

const Label = styled(Caption)<LabelProps>`
  display: flex;
  align-items: flex-start;
  user-select: none;
  white-space: nowrap;
  margin-bottom: 1rem;
  margin-top: 3rem;
  padding: 1rem 2rem 1.5rem 1rem;
  position: relative;
  ${({ hasError, theme }) =>
    hasError &&
    css`
      border: 1px solid ${theme.colors.danger.dark};
      border-radius: 0.8rem;
    `};
`

const LabelText = styled.div`
  display: inline-block;
  padding: 0 1rem;
  white-space: normal;
`

const HelperText = styled(Text)<{ isError: boolean }>`
  font-size: 1.3rem;
  position: absolute;
  color: ${(p) => (p.isError ? p.theme.colors.danger.dark : p.theme.colors.dark)};
  left: 2.9rem;
  bottom: -0.4rem;
  line-height: 1.3rem;
  background-color: white;
`
