import React from 'react';
import styled, { keyframes } from 'styled-components';
export const BellIcon = ({ fillColor = 'black', animation = false }) => {
    return (React.createElement(Icon, { viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", animation: animation, key: animation ? 'animated' : 'not_animated' },
        React.createElement("g", { fill: fillColor },
            React.createElement("path", { d: "M21.34 16.08s-2.53-1.93-2.53-8.25a7.825 7.825 0 1 0-15.65 0c0 6.32-2.5 8.24-2.49 8.24a1.49 1.49 0 0 0 .83 2.74h19a1.48 1.48 0 0 0 1.41-1 1.52 1.52 0 0 0-.57-1.73z" }),
            React.createElement("path", { d: "M13.56 20.23a1.51 1.51 0 0 0-2.05.55.6.6 0 0 1-.83.22.66.66 0 0 1-.22-.22 1.5 1.5 0 1 0-2.59 1.5 3.5 3.5 0 0 0 1.31 1.31 3.6 3.6 0 0 0 4.93-1.31 1.5 1.5 0 0 0-.55-2.05z" }))));
};
const shake = keyframes `
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }
  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
`;
const Icon = styled.svg `
  animation-name: ${shake};
  animation-duration: ${({ animation }) => (animation ? '2s' : '0')};
  animation-timing-function: ease-in-out;
  display: inline-block;

  @media (prefers-reduced-motion) {
    animation-name: dissolve;
  }
`;
