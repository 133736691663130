import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgThumbsdown = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M12.84 23C11.7791 23 10.7617 22.5786 10.0116 21.8284C9.26142 21.0783 8.83999 20.0609 8.83999 19V16H4.17999H3.70999C3.31974 15.9414 2.9449 15.8064 2.60692 15.6027C2.26894 15.3989 1.97447 15.1306 1.74038 14.8129C1.50629 14.4952 1.33718 14.1344 1.24273 13.7513C1.14829 13.3681 1.13036 12.9701 1.18999 12.58L2.56999 3.58C2.67187 2.85917 3.03239 2.19998 3.58435 1.7253C4.1363 1.25062 4.84203 0.992836 5.56999 1H19.57C20.3687 1.00287 21.1392 1.29531 21.7387 1.82308C22.3382 2.35084 22.7259 3.07811 22.83 3.87C22.8349 3.91319 22.8349 3.95681 22.83 4V11C22.8353 11.0432 22.8353 11.0868 22.83 11.13C22.714 11.9312 22.3118 12.6633 21.6978 13.1909C21.0838 13.7185 20.2995 14.0059 19.49 14H17.49L13.76 22.41C13.6804 22.587 13.5511 22.737 13.3877 22.8418C13.2244 22.9466 13.034 23.0015 12.84 23ZM9.83999 14C10.1052 14 10.3596 14.1054 10.5471 14.2929C10.7346 14.4804 10.84 14.7348 10.84 15V19C10.8389 19.4247 10.973 19.8387 11.2229 20.1821C11.4728 20.5255 11.8255 20.7805 12.23 20.91L15.84 12.79V3H5.55999C5.31882 2.99727 5.0848 3.0818 4.90104 3.238C4.71727 3.3942 4.59615 3.61155 4.55999 3.85L3.17999 12.85C3.14004 13.1108 3.20473 13.3767 3.35999 13.59C3.51104 13.8056 3.74101 13.9529 3.99999 14H9.83999ZM17.84 12H19.51C19.8234 12.0056 20.1287 11.9003 20.3721 11.7027C20.6154 11.5051 20.7811 11.2279 20.84 10.92V4.08C20.786 3.77717 20.6271 3.50301 20.3913 3.30555C20.1554 3.10809 19.8576 2.99992 19.55 3H17.84V12Z" })));
};
