import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const VoteBoxIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 25 26.5", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M2.762 19.799h19.472c-.056-.076-.089-.13-.132-.176-.731-.8-1.465-1.6-2.192-2.4a.4.4 0 0 0-.329-.145c-.783.007-1.566 0-2.391 0 .055-.064.085-.1.121-.14q1.239-1.2 2.481-2.393a.533.533 0 0 1 .266-.111 1.4 1.4 0 0 1 1.321.5c1.127 1.147 2.3 2.249 3.464 3.364a.454.454 0 0 1 .154.359c0 1.694-.025 3.388 0 5.082a2.753 2.753 0 0 1-2.857 2.76c-3.165-.012-6.33 0-9.495 0h-9.8a2.721 2.721 0 0 1-2.78-2.147 2.324 2.324 0 0 1-.055-.5q-.006-2.606 0-5.212a.445.445 0 0 1 .12-.289q1.946-1.9 3.909-3.777a.528.528 0 0 1 .316-.126 2.408 2.408 0 0 1 .933.026c.27.106.465.394.687.608q.962.926 1.922 1.855a.8.8 0 0 0 .129.084l-.027.058c-.064 0-.128.009-.192.009H5.438a.432.432 0 0 0-.284.1c-.762.814-1.515 1.637-2.27 2.458-.038.039-.064.08-.122.153zm9.735 4.039h9.532c.177 0 .237-.049.23-.22-.012-.3 0-.608 0-.912 0-.247 0-.246-.254-.246H2.963c-.161 0-.21.048-.2.2.011.335.008.67 0 1.005 0 .128.038.185.174.172.07-.007.141 0 .212 0z" }),
        React.createElement("path", { d: "M12.925 17.846a1.254 1.254 0 0 1-.894-.39l-6.582-6.58a1.294 1.294 0 0 1-.357-1.458 1.419 1.419 0 0 1 .324-.491Q9.686 4.643 13.969.37a1.3 1.3 0 0 1 1.829.015l6.567 6.568a1.326 1.326 0 0 1 .006 2.057l-8.436 8.438a1.318 1.318 0 0 1-1.01.398zm2.007-14.64L8.276 9.867l4.738 4.751c.027-.024.069-.06.108-.1q3.2-3.2 6.4-6.393c.116-.115.117-.179 0-.294q-2.144-2.132-4.278-4.275c-.116-.113-.216-.241-.312-.351z" })));
};
