/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import {
  Notification,
  NotificationResolution,
  notificationResolutionFromJSON,
  notificationResolutionToJSON,
} from "../../platform/v1/notification";
import { PushSubscription } from "../../platform/v1/push_subscription";
import { TermsAndConditionsConsent } from "../../platform/v1/terms_and_conditions_consent";
import { UserPreferences } from "../../platform/v1/user_preferences";
import { Composition } from "./composition";
import { InvestorAccount } from "./investor_account";
import { VoteResult } from "./vote_result";
import { VotingPolicy } from "./voting_policy";

export const protobufPackage = "tumelo.dashboardbff.v1";

export interface UpdateTermsAndConditionsConsentRequest {
  accepted: boolean;
}

export interface GetTermsAndConditionsConsentRequest {
}

export interface TriggerFetchToCompositionsRequest {
}

export interface TriggerFetchToCompositionsResponse {
}

/** GetUserPreferencesRequest is an empty request with user details pulled out the token */
export interface GetUserPreferencesRequest {
}

/** HelloWorldRequest is an empty request */
export interface HelloWorldRequest {
}

/** HelloWorldResponse contains the hell world */
export interface HelloWorldResponse {
  response: string;
}

/** ListNotificationsRequest */
export interface ListNotificationsRequest {
  /** The number of notifications to return */
  pageSize: number;
  /** OPTIONAL The next_page_token value returned from a previous List request, if any. If this is empty it is ignored. */
  pageToken: string;
  /** OPTIONAL filters notifications to return expired notifications or not */
  includeExpired: boolean;
}

/** ListNotificationsResponse */
export interface ListNotificationsResponse {
  /** meetings contains the meetings that matched the request. */
  notifications: Notification[];
  /**
   * next_page_token contains a string which can be used to get the next page of results.
   * it will be empty if there are no more results.
   */
  nextPageToken: string;
}

/** UpdateNotificationResolutionRequest */
export interface UpdateNotificationResolutionRequest {
  /** id of the notification to resolve */
  id: string;
  /** resolution to update notification resolution to */
  resolution: NotificationResolution;
}

/** DeleteAllPushSubscriptionsRequest is an empty request with user details pulled out the token */
export interface DeleteAllPushSubscriptionsRequest {
}

/** DeleteAllPushSubscriptionsResponse */
export interface DeleteAllPushSubscriptionsResponse {
}

/** GetWebPushVAPIDPublicKeyRequest */
export interface GetWebPushVAPIDPublicKeyRequest {
}

/** GetWebPushVAPIDPublicKeyResponse */
export interface GetWebPushVAPIDPublicKeyResponse {
  vapidPublicKey: string;
}

export interface FetchOrganizationBreakdownRequest {
  /**
   * REQUIRED instrument_references is a list of instrument references from which to traverse the graph tree
   * it has the format:
   * - isin/{isin}   e.g. isin/DE000SLA5HM2
   * - {alias}/{id}  e.g. FIDELITY_FUND_CODE_1/KAEV
   */
  instrumentReferences: string[];
}

export interface FetchOrganizationBreakdownResponse {
  organizationIds: string[];
}

export interface FetchVotingPoliciesRequest {
}

export interface FetchVotingPoliciesResponse {
  votingPolicies: VotingPolicy[];
}

export interface CreateCompositionWithModelPortfolioRequest {
  modelPortfolioId: string;
}

export interface CreateCompositionWithModelPortfolioResponse {
  investorAccount: InvestorAccount | undefined;
  latestInvestorAccountComposition: Composition | undefined;
}

export interface FetchInvestorVoteResultsRequest {
  pageToken: string;
}

export interface FetchInvestorVoteResultsResponse {
  voteResults: VoteResult[];
  nextPageToken: string;
}

function createBaseUpdateTermsAndConditionsConsentRequest(): UpdateTermsAndConditionsConsentRequest {
  return { accepted: false };
}

export const UpdateTermsAndConditionsConsentRequest = {
  encode(message: UpdateTermsAndConditionsConsentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.accepted === true) {
      writer.uint32(8).bool(message.accepted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateTermsAndConditionsConsentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateTermsAndConditionsConsentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.accepted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateTermsAndConditionsConsentRequest {
    return { accepted: isSet(object.accepted) ? Boolean(object.accepted) : false };
  },

  toJSON(message: UpdateTermsAndConditionsConsentRequest): unknown {
    const obj: any = {};
    if (message.accepted === true) {
      obj.accepted = message.accepted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateTermsAndConditionsConsentRequest>, I>>(
    base?: I,
  ): UpdateTermsAndConditionsConsentRequest {
    return UpdateTermsAndConditionsConsentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateTermsAndConditionsConsentRequest>, I>>(
    object: I,
  ): UpdateTermsAndConditionsConsentRequest {
    const message = createBaseUpdateTermsAndConditionsConsentRequest();
    message.accepted = object.accepted ?? false;
    return message;
  },
};

function createBaseGetTermsAndConditionsConsentRequest(): GetTermsAndConditionsConsentRequest {
  return {};
}

export const GetTermsAndConditionsConsentRequest = {
  encode(_: GetTermsAndConditionsConsentRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTermsAndConditionsConsentRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTermsAndConditionsConsentRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetTermsAndConditionsConsentRequest {
    return {};
  },

  toJSON(_: GetTermsAndConditionsConsentRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTermsAndConditionsConsentRequest>, I>>(
    base?: I,
  ): GetTermsAndConditionsConsentRequest {
    return GetTermsAndConditionsConsentRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTermsAndConditionsConsentRequest>, I>>(
    _: I,
  ): GetTermsAndConditionsConsentRequest {
    const message = createBaseGetTermsAndConditionsConsentRequest();
    return message;
  },
};

function createBaseTriggerFetchToCompositionsRequest(): TriggerFetchToCompositionsRequest {
  return {};
}

export const TriggerFetchToCompositionsRequest = {
  encode(_: TriggerFetchToCompositionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TriggerFetchToCompositionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTriggerFetchToCompositionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TriggerFetchToCompositionsRequest {
    return {};
  },

  toJSON(_: TriggerFetchToCompositionsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TriggerFetchToCompositionsRequest>, I>>(
    base?: I,
  ): TriggerFetchToCompositionsRequest {
    return TriggerFetchToCompositionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TriggerFetchToCompositionsRequest>, I>>(
    _: I,
  ): TriggerFetchToCompositionsRequest {
    const message = createBaseTriggerFetchToCompositionsRequest();
    return message;
  },
};

function createBaseTriggerFetchToCompositionsResponse(): TriggerFetchToCompositionsResponse {
  return {};
}

export const TriggerFetchToCompositionsResponse = {
  encode(_: TriggerFetchToCompositionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TriggerFetchToCompositionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTriggerFetchToCompositionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): TriggerFetchToCompositionsResponse {
    return {};
  },

  toJSON(_: TriggerFetchToCompositionsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<TriggerFetchToCompositionsResponse>, I>>(
    base?: I,
  ): TriggerFetchToCompositionsResponse {
    return TriggerFetchToCompositionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TriggerFetchToCompositionsResponse>, I>>(
    _: I,
  ): TriggerFetchToCompositionsResponse {
    const message = createBaseTriggerFetchToCompositionsResponse();
    return message;
  },
};

function createBaseGetUserPreferencesRequest(): GetUserPreferencesRequest {
  return {};
}

export const GetUserPreferencesRequest = {
  encode(_: GetUserPreferencesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserPreferencesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserPreferencesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetUserPreferencesRequest {
    return {};
  },

  toJSON(_: GetUserPreferencesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetUserPreferencesRequest>, I>>(base?: I): GetUserPreferencesRequest {
    return GetUserPreferencesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetUserPreferencesRequest>, I>>(_: I): GetUserPreferencesRequest {
    const message = createBaseGetUserPreferencesRequest();
    return message;
  },
};

function createBaseHelloWorldRequest(): HelloWorldRequest {
  return {};
}

export const HelloWorldRequest = {
  encode(_: HelloWorldRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HelloWorldRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHelloWorldRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): HelloWorldRequest {
    return {};
  },

  toJSON(_: HelloWorldRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<HelloWorldRequest>, I>>(base?: I): HelloWorldRequest {
    return HelloWorldRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HelloWorldRequest>, I>>(_: I): HelloWorldRequest {
    const message = createBaseHelloWorldRequest();
    return message;
  },
};

function createBaseHelloWorldResponse(): HelloWorldResponse {
  return { response: "" };
}

export const HelloWorldResponse = {
  encode(message: HelloWorldResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.response !== "") {
      writer.uint32(10).string(message.response);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HelloWorldResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHelloWorldResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.response = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HelloWorldResponse {
    return { response: isSet(object.response) ? String(object.response) : "" };
  },

  toJSON(message: HelloWorldResponse): unknown {
    const obj: any = {};
    if (message.response !== "") {
      obj.response = message.response;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HelloWorldResponse>, I>>(base?: I): HelloWorldResponse {
    return HelloWorldResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HelloWorldResponse>, I>>(object: I): HelloWorldResponse {
    const message = createBaseHelloWorldResponse();
    message.response = object.response ?? "";
    return message;
  },
};

function createBaseListNotificationsRequest(): ListNotificationsRequest {
  return { pageSize: 0, pageToken: "", includeExpired: false };
}

export const ListNotificationsRequest = {
  encode(message: ListNotificationsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== 0) {
      writer.uint32(8).int32(message.pageSize);
    }
    if (message.pageToken !== "") {
      writer.uint32(18).string(message.pageToken);
    }
    if (message.includeExpired === true) {
      writer.uint32(24).bool(message.includeExpired);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListNotificationsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListNotificationsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageToken = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.includeExpired = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListNotificationsRequest {
    return {
      pageSize: isSet(object.pageSize) ? Number(object.pageSize) : 0,
      pageToken: isSet(object.pageToken) ? String(object.pageToken) : "",
      includeExpired: isSet(object.includeExpired) ? Boolean(object.includeExpired) : false,
    };
  },

  toJSON(message: ListNotificationsRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    if (message.includeExpired === true) {
      obj.includeExpired = message.includeExpired;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListNotificationsRequest>, I>>(base?: I): ListNotificationsRequest {
    return ListNotificationsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListNotificationsRequest>, I>>(object: I): ListNotificationsRequest {
    const message = createBaseListNotificationsRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageToken = object.pageToken ?? "";
    message.includeExpired = object.includeExpired ?? false;
    return message;
  },
};

function createBaseListNotificationsResponse(): ListNotificationsResponse {
  return { notifications: [], nextPageToken: "" };
}

export const ListNotificationsResponse = {
  encode(message: ListNotificationsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.notifications) {
      Notification.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListNotificationsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListNotificationsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.notifications.push(Notification.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListNotificationsResponse {
    return {
      notifications: globalThis.Array.isArray(object?.notifications)
        ? object.notifications.map((e: any) => Notification.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
    };
  },

  toJSON(message: ListNotificationsResponse): unknown {
    const obj: any = {};
    if (message.notifications?.length) {
      obj.notifications = message.notifications.map((e) => Notification.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListNotificationsResponse>, I>>(base?: I): ListNotificationsResponse {
    return ListNotificationsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListNotificationsResponse>, I>>(object: I): ListNotificationsResponse {
    const message = createBaseListNotificationsResponse();
    message.notifications = object.notifications?.map((e) => Notification.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

function createBaseUpdateNotificationResolutionRequest(): UpdateNotificationResolutionRequest {
  return { id: "", resolution: 0 };
}

export const UpdateNotificationResolutionRequest = {
  encode(message: UpdateNotificationResolutionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.resolution !== 0) {
      writer.uint32(16).int32(message.resolution);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateNotificationResolutionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateNotificationResolutionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.resolution = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UpdateNotificationResolutionRequest {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      resolution: isSet(object.resolution) ? notificationResolutionFromJSON(object.resolution) : 0,
    };
  },

  toJSON(message: UpdateNotificationResolutionRequest): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.resolution !== 0) {
      obj.resolution = notificationResolutionToJSON(message.resolution);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UpdateNotificationResolutionRequest>, I>>(
    base?: I,
  ): UpdateNotificationResolutionRequest {
    return UpdateNotificationResolutionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UpdateNotificationResolutionRequest>, I>>(
    object: I,
  ): UpdateNotificationResolutionRequest {
    const message = createBaseUpdateNotificationResolutionRequest();
    message.id = object.id ?? "";
    message.resolution = object.resolution ?? 0;
    return message;
  },
};

function createBaseDeleteAllPushSubscriptionsRequest(): DeleteAllPushSubscriptionsRequest {
  return {};
}

export const DeleteAllPushSubscriptionsRequest = {
  encode(_: DeleteAllPushSubscriptionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAllPushSubscriptionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAllPushSubscriptionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteAllPushSubscriptionsRequest {
    return {};
  },

  toJSON(_: DeleteAllPushSubscriptionsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteAllPushSubscriptionsRequest>, I>>(
    base?: I,
  ): DeleteAllPushSubscriptionsRequest {
    return DeleteAllPushSubscriptionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteAllPushSubscriptionsRequest>, I>>(
    _: I,
  ): DeleteAllPushSubscriptionsRequest {
    const message = createBaseDeleteAllPushSubscriptionsRequest();
    return message;
  },
};

function createBaseDeleteAllPushSubscriptionsResponse(): DeleteAllPushSubscriptionsResponse {
  return {};
}

export const DeleteAllPushSubscriptionsResponse = {
  encode(_: DeleteAllPushSubscriptionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteAllPushSubscriptionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteAllPushSubscriptionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): DeleteAllPushSubscriptionsResponse {
    return {};
  },

  toJSON(_: DeleteAllPushSubscriptionsResponse): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteAllPushSubscriptionsResponse>, I>>(
    base?: I,
  ): DeleteAllPushSubscriptionsResponse {
    return DeleteAllPushSubscriptionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteAllPushSubscriptionsResponse>, I>>(
    _: I,
  ): DeleteAllPushSubscriptionsResponse {
    const message = createBaseDeleteAllPushSubscriptionsResponse();
    return message;
  },
};

function createBaseGetWebPushVAPIDPublicKeyRequest(): GetWebPushVAPIDPublicKeyRequest {
  return {};
}

export const GetWebPushVAPIDPublicKeyRequest = {
  encode(_: GetWebPushVAPIDPublicKeyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWebPushVAPIDPublicKeyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWebPushVAPIDPublicKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetWebPushVAPIDPublicKeyRequest {
    return {};
  },

  toJSON(_: GetWebPushVAPIDPublicKeyRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWebPushVAPIDPublicKeyRequest>, I>>(base?: I): GetWebPushVAPIDPublicKeyRequest {
    return GetWebPushVAPIDPublicKeyRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWebPushVAPIDPublicKeyRequest>, I>>(_: I): GetWebPushVAPIDPublicKeyRequest {
    const message = createBaseGetWebPushVAPIDPublicKeyRequest();
    return message;
  },
};

function createBaseGetWebPushVAPIDPublicKeyResponse(): GetWebPushVAPIDPublicKeyResponse {
  return { vapidPublicKey: "" };
}

export const GetWebPushVAPIDPublicKeyResponse = {
  encode(message: GetWebPushVAPIDPublicKeyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.vapidPublicKey !== "") {
      writer.uint32(10).string(message.vapidPublicKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWebPushVAPIDPublicKeyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWebPushVAPIDPublicKeyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.vapidPublicKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWebPushVAPIDPublicKeyResponse {
    return { vapidPublicKey: isSet(object.vapidPublicKey) ? String(object.vapidPublicKey) : "" };
  },

  toJSON(message: GetWebPushVAPIDPublicKeyResponse): unknown {
    const obj: any = {};
    if (message.vapidPublicKey !== "") {
      obj.vapidPublicKey = message.vapidPublicKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWebPushVAPIDPublicKeyResponse>, I>>(
    base?: I,
  ): GetWebPushVAPIDPublicKeyResponse {
    return GetWebPushVAPIDPublicKeyResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWebPushVAPIDPublicKeyResponse>, I>>(
    object: I,
  ): GetWebPushVAPIDPublicKeyResponse {
    const message = createBaseGetWebPushVAPIDPublicKeyResponse();
    message.vapidPublicKey = object.vapidPublicKey ?? "";
    return message;
  },
};

function createBaseFetchOrganizationBreakdownRequest(): FetchOrganizationBreakdownRequest {
  return { instrumentReferences: [] };
}

export const FetchOrganizationBreakdownRequest = {
  encode(message: FetchOrganizationBreakdownRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.instrumentReferences) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchOrganizationBreakdownRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchOrganizationBreakdownRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.instrumentReferences.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchOrganizationBreakdownRequest {
    return {
      instrumentReferences: globalThis.Array.isArray(object?.instrumentReferences)
        ? object.instrumentReferences.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: FetchOrganizationBreakdownRequest): unknown {
    const obj: any = {};
    if (message.instrumentReferences?.length) {
      obj.instrumentReferences = message.instrumentReferences;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchOrganizationBreakdownRequest>, I>>(
    base?: I,
  ): FetchOrganizationBreakdownRequest {
    return FetchOrganizationBreakdownRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchOrganizationBreakdownRequest>, I>>(
    object: I,
  ): FetchOrganizationBreakdownRequest {
    const message = createBaseFetchOrganizationBreakdownRequest();
    message.instrumentReferences = object.instrumentReferences?.map((e) => e) || [];
    return message;
  },
};

function createBaseFetchOrganizationBreakdownResponse(): FetchOrganizationBreakdownResponse {
  return { organizationIds: [] };
}

export const FetchOrganizationBreakdownResponse = {
  encode(message: FetchOrganizationBreakdownResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.organizationIds) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchOrganizationBreakdownResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchOrganizationBreakdownResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.organizationIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchOrganizationBreakdownResponse {
    return {
      organizationIds: globalThis.Array.isArray(object?.organizationIds)
        ? object.organizationIds.map((e: any) => String(e))
        : [],
    };
  },

  toJSON(message: FetchOrganizationBreakdownResponse): unknown {
    const obj: any = {};
    if (message.organizationIds?.length) {
      obj.organizationIds = message.organizationIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchOrganizationBreakdownResponse>, I>>(
    base?: I,
  ): FetchOrganizationBreakdownResponse {
    return FetchOrganizationBreakdownResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchOrganizationBreakdownResponse>, I>>(
    object: I,
  ): FetchOrganizationBreakdownResponse {
    const message = createBaseFetchOrganizationBreakdownResponse();
    message.organizationIds = object.organizationIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseFetchVotingPoliciesRequest(): FetchVotingPoliciesRequest {
  return {};
}

export const FetchVotingPoliciesRequest = {
  encode(_: FetchVotingPoliciesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchVotingPoliciesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchVotingPoliciesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): FetchVotingPoliciesRequest {
    return {};
  },

  toJSON(_: FetchVotingPoliciesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchVotingPoliciesRequest>, I>>(base?: I): FetchVotingPoliciesRequest {
    return FetchVotingPoliciesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchVotingPoliciesRequest>, I>>(_: I): FetchVotingPoliciesRequest {
    const message = createBaseFetchVotingPoliciesRequest();
    return message;
  },
};

function createBaseFetchVotingPoliciesResponse(): FetchVotingPoliciesResponse {
  return { votingPolicies: [] };
}

export const FetchVotingPoliciesResponse = {
  encode(message: FetchVotingPoliciesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.votingPolicies) {
      VotingPolicy.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchVotingPoliciesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchVotingPoliciesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.votingPolicies.push(VotingPolicy.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchVotingPoliciesResponse {
    return {
      votingPolicies: globalThis.Array.isArray(object?.votingPolicies)
        ? object.votingPolicies.map((e: any) => VotingPolicy.fromJSON(e))
        : [],
    };
  },

  toJSON(message: FetchVotingPoliciesResponse): unknown {
    const obj: any = {};
    if (message.votingPolicies?.length) {
      obj.votingPolicies = message.votingPolicies.map((e) => VotingPolicy.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchVotingPoliciesResponse>, I>>(base?: I): FetchVotingPoliciesResponse {
    return FetchVotingPoliciesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchVotingPoliciesResponse>, I>>(object: I): FetchVotingPoliciesResponse {
    const message = createBaseFetchVotingPoliciesResponse();
    message.votingPolicies = object.votingPolicies?.map((e) => VotingPolicy.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateCompositionWithModelPortfolioRequest(): CreateCompositionWithModelPortfolioRequest {
  return { modelPortfolioId: "" };
}

export const CreateCompositionWithModelPortfolioRequest = {
  encode(message: CreateCompositionWithModelPortfolioRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.modelPortfolioId !== "") {
      writer.uint32(10).string(message.modelPortfolioId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCompositionWithModelPortfolioRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCompositionWithModelPortfolioRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modelPortfolioId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCompositionWithModelPortfolioRequest {
    return { modelPortfolioId: isSet(object.modelPortfolioId) ? String(object.modelPortfolioId) : "" };
  },

  toJSON(message: CreateCompositionWithModelPortfolioRequest): unknown {
    const obj: any = {};
    if (message.modelPortfolioId !== "") {
      obj.modelPortfolioId = message.modelPortfolioId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateCompositionWithModelPortfolioRequest>, I>>(
    base?: I,
  ): CreateCompositionWithModelPortfolioRequest {
    return CreateCompositionWithModelPortfolioRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCompositionWithModelPortfolioRequest>, I>>(
    object: I,
  ): CreateCompositionWithModelPortfolioRequest {
    const message = createBaseCreateCompositionWithModelPortfolioRequest();
    message.modelPortfolioId = object.modelPortfolioId ?? "";
    return message;
  },
};

function createBaseCreateCompositionWithModelPortfolioResponse(): CreateCompositionWithModelPortfolioResponse {
  return { investorAccount: undefined, latestInvestorAccountComposition: undefined };
}

export const CreateCompositionWithModelPortfolioResponse = {
  encode(message: CreateCompositionWithModelPortfolioResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.investorAccount !== undefined) {
      InvestorAccount.encode(message.investorAccount, writer.uint32(10).fork()).ldelim();
    }
    if (message.latestInvestorAccountComposition !== undefined) {
      Composition.encode(message.latestInvestorAccountComposition, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCompositionWithModelPortfolioResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCompositionWithModelPortfolioResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.investorAccount = InvestorAccount.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latestInvestorAccountComposition = Composition.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateCompositionWithModelPortfolioResponse {
    return {
      investorAccount: isSet(object.investorAccount) ? InvestorAccount.fromJSON(object.investorAccount) : undefined,
      latestInvestorAccountComposition: isSet(object.latestInvestorAccountComposition)
        ? Composition.fromJSON(object.latestInvestorAccountComposition)
        : undefined,
    };
  },

  toJSON(message: CreateCompositionWithModelPortfolioResponse): unknown {
    const obj: any = {};
    if (message.investorAccount !== undefined) {
      obj.investorAccount = InvestorAccount.toJSON(message.investorAccount);
    }
    if (message.latestInvestorAccountComposition !== undefined) {
      obj.latestInvestorAccountComposition = Composition.toJSON(message.latestInvestorAccountComposition);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateCompositionWithModelPortfolioResponse>, I>>(
    base?: I,
  ): CreateCompositionWithModelPortfolioResponse {
    return CreateCompositionWithModelPortfolioResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCompositionWithModelPortfolioResponse>, I>>(
    object: I,
  ): CreateCompositionWithModelPortfolioResponse {
    const message = createBaseCreateCompositionWithModelPortfolioResponse();
    message.investorAccount = (object.investorAccount !== undefined && object.investorAccount !== null)
      ? InvestorAccount.fromPartial(object.investorAccount)
      : undefined;
    message.latestInvestorAccountComposition =
      (object.latestInvestorAccountComposition !== undefined && object.latestInvestorAccountComposition !== null)
        ? Composition.fromPartial(object.latestInvestorAccountComposition)
        : undefined;
    return message;
  },
};

function createBaseFetchInvestorVoteResultsRequest(): FetchInvestorVoteResultsRequest {
  return { pageToken: "" };
}

export const FetchInvestorVoteResultsRequest = {
  encode(message: FetchInvestorVoteResultsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageToken !== "") {
      writer.uint32(10).string(message.pageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchInvestorVoteResultsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchInvestorVoteResultsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchInvestorVoteResultsRequest {
    return { pageToken: isSet(object.pageToken) ? String(object.pageToken) : "" };
  },

  toJSON(message: FetchInvestorVoteResultsRequest): unknown {
    const obj: any = {};
    if (message.pageToken !== "") {
      obj.pageToken = message.pageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchInvestorVoteResultsRequest>, I>>(base?: I): FetchInvestorVoteResultsRequest {
    return FetchInvestorVoteResultsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchInvestorVoteResultsRequest>, I>>(
    object: I,
  ): FetchInvestorVoteResultsRequest {
    const message = createBaseFetchInvestorVoteResultsRequest();
    message.pageToken = object.pageToken ?? "";
    return message;
  },
};

function createBaseFetchInvestorVoteResultsResponse(): FetchInvestorVoteResultsResponse {
  return { voteResults: [], nextPageToken: "" };
}

export const FetchInvestorVoteResultsResponse = {
  encode(message: FetchInvestorVoteResultsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.voteResults) {
      VoteResult.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.nextPageToken !== "") {
      writer.uint32(18).string(message.nextPageToken);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FetchInvestorVoteResultsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFetchInvestorVoteResultsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.voteResults.push(VoteResult.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nextPageToken = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FetchInvestorVoteResultsResponse {
    return {
      voteResults: globalThis.Array.isArray(object?.voteResults)
        ? object.voteResults.map((e: any) => VoteResult.fromJSON(e))
        : [],
      nextPageToken: isSet(object.nextPageToken) ? String(object.nextPageToken) : "",
    };
  },

  toJSON(message: FetchInvestorVoteResultsResponse): unknown {
    const obj: any = {};
    if (message.voteResults?.length) {
      obj.voteResults = message.voteResults.map((e) => VoteResult.toJSON(e));
    }
    if (message.nextPageToken !== "") {
      obj.nextPageToken = message.nextPageToken;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FetchInvestorVoteResultsResponse>, I>>(
    base?: I,
  ): FetchInvestorVoteResultsResponse {
    return FetchInvestorVoteResultsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FetchInvestorVoteResultsResponse>, I>>(
    object: I,
  ): FetchInvestorVoteResultsResponse {
    const message = createBaseFetchInvestorVoteResultsResponse();
    message.voteResults = object.voteResults?.map((e) => VoteResult.fromPartial(e)) || [];
    message.nextPageToken = object.nextPageToken ?? "";
    return message;
  },
};

/** DashboardBffService is a backend service for the end member dashboard frontend */
export interface DashboardBffService {
  /** HelloWorld returns hello world string */
  HelloWorld(request: DeepPartial<HelloWorldRequest>, metadata?: grpc.Metadata): Promise<HelloWorldResponse>;
  /** UpsertUserPreferences creates/updates the user preferences in the platform db */
  UpsertUserPreferences(request: DeepPartial<UserPreferences>, metadata?: grpc.Metadata): Promise<UserPreferences>;
  /** GetUserPreferences returns the users' user preferences from the platform db */
  GetUserPreferences(
    request: DeepPartial<GetUserPreferencesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UserPreferences>;
  /**
   * GetIfNotSetDefaultUserPreferences returns the users' user preferences from the platform db if they are defined,
   * if not it sets them to the default and returns them
   */
  GetIfNotSetDefaultUserPreferences(
    request: DeepPartial<GetUserPreferencesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UserPreferences>;
  /** ListNotifications lists notifications for the currently authenticated user */
  ListNotifications(
    request: DeepPartial<ListNotificationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListNotificationsResponse>;
  /**
   * ResolveNotification updates the NotificationResolution of a notification
   *
   * Possible Errors:
   * - NotFound - if the notification doesn't exist
   */
  UpdateNotificationResolution(
    request: DeepPartial<UpdateNotificationResolutionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Notification>;
  /** DeleteAllPushSubscriptions deletes a push subscription */
  DeleteAllPushSubscriptions(
    request: DeepPartial<DeleteAllPushSubscriptionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAllPushSubscriptionsResponse>;
  /** CreatePushSubscription creates a push subscription */
  CreatePushSubscription(request: DeepPartial<PushSubscription>, metadata?: grpc.Metadata): Promise<PushSubscription>;
  /** GetWebPushVAPIDPublicKey */
  GetWebPushVAPIDPublicKey(
    request: DeepPartial<GetWebPushVAPIDPublicKeyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetWebPushVAPIDPublicKeyResponse>;
  /**
   * Integration Endpoints
   * TriggerFetchToCompositions will trigger a fetch to get the composition if needed.
   */
  TriggerFetchToCompositions(
    request: DeepPartial<TriggerFetchToCompositionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TriggerFetchToCompositionsResponse>;
  /** FetchOrganizationBreakdown returns a set of organization IDs given a list of instrument references from the user's latest account breakdown */
  FetchOrganizationBreakdown(
    request: DeepPartial<FetchOrganizationBreakdownRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchOrganizationBreakdownResponse>;
  /** UpdateTermsAndConditionsConsent updates the terms and conditions consent for a user */
  UpdateTermsAndConditionsConsent(
    request: DeepPartial<UpdateTermsAndConditionsConsentRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TermsAndConditionsConsent>;
  /** GetTermsAndConditionsConsent gets the latest recorded consent and the time it was recorded for a user */
  GetTermsAndConditionsConsent(
    request: DeepPartial<GetTermsAndConditionsConsentRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TermsAndConditionsConsent>;
  /** FetchVotingPolicies returns a list of vote policies available to the user */
  FetchVotingPolicies(
    request: DeepPartial<FetchVotingPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchVotingPoliciesResponse>;
  /** CreateCompositionWithModelPortfolio creates composition for the user with chosen model portfolio */
  CreateCompositionWithModelPortfolio(
    request: DeepPartial<CreateCompositionWithModelPortfolioRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateCompositionWithModelPortfolioResponse>;
  /**
   * FetchInvestor Vote Results returns a list of vote results which are relevant to the user. It only shows
   * vote results where an outcome is already available. Results are deemed relevent if one of the following
   * is true:
   * - The user was issued a ballot for the vote - meaning at some point in time they were entitled to vote
   * - The result is for an organization which the user holds through an instrument in their composition
   */
  FetchInvestorVoteResults(
    request: DeepPartial<FetchInvestorVoteResultsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchInvestorVoteResultsResponse>;
}

export class DashboardBffServiceClientImpl implements DashboardBffService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.HelloWorld = this.HelloWorld.bind(this);
    this.UpsertUserPreferences = this.UpsertUserPreferences.bind(this);
    this.GetUserPreferences = this.GetUserPreferences.bind(this);
    this.GetIfNotSetDefaultUserPreferences = this.GetIfNotSetDefaultUserPreferences.bind(this);
    this.ListNotifications = this.ListNotifications.bind(this);
    this.UpdateNotificationResolution = this.UpdateNotificationResolution.bind(this);
    this.DeleteAllPushSubscriptions = this.DeleteAllPushSubscriptions.bind(this);
    this.CreatePushSubscription = this.CreatePushSubscription.bind(this);
    this.GetWebPushVAPIDPublicKey = this.GetWebPushVAPIDPublicKey.bind(this);
    this.TriggerFetchToCompositions = this.TriggerFetchToCompositions.bind(this);
    this.FetchOrganizationBreakdown = this.FetchOrganizationBreakdown.bind(this);
    this.UpdateTermsAndConditionsConsent = this.UpdateTermsAndConditionsConsent.bind(this);
    this.GetTermsAndConditionsConsent = this.GetTermsAndConditionsConsent.bind(this);
    this.FetchVotingPolicies = this.FetchVotingPolicies.bind(this);
    this.CreateCompositionWithModelPortfolio = this.CreateCompositionWithModelPortfolio.bind(this);
    this.FetchInvestorVoteResults = this.FetchInvestorVoteResults.bind(this);
  }

  HelloWorld(request: DeepPartial<HelloWorldRequest>, metadata?: grpc.Metadata): Promise<HelloWorldResponse> {
    return this.rpc.unary(DashboardBffServiceHelloWorldDesc, HelloWorldRequest.fromPartial(request), metadata);
  }

  UpsertUserPreferences(request: DeepPartial<UserPreferences>, metadata?: grpc.Metadata): Promise<UserPreferences> {
    return this.rpc.unary(DashboardBffServiceUpsertUserPreferencesDesc, UserPreferences.fromPartial(request), metadata);
  }

  GetUserPreferences(
    request: DeepPartial<GetUserPreferencesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UserPreferences> {
    return this.rpc.unary(
      DashboardBffServiceGetUserPreferencesDesc,
      GetUserPreferencesRequest.fromPartial(request),
      metadata,
    );
  }

  GetIfNotSetDefaultUserPreferences(
    request: DeepPartial<GetUserPreferencesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UserPreferences> {
    return this.rpc.unary(
      DashboardBffServiceGetIfNotSetDefaultUserPreferencesDesc,
      GetUserPreferencesRequest.fromPartial(request),
      metadata,
    );
  }

  ListNotifications(
    request: DeepPartial<ListNotificationsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<ListNotificationsResponse> {
    return this.rpc.unary(
      DashboardBffServiceListNotificationsDesc,
      ListNotificationsRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateNotificationResolution(
    request: DeepPartial<UpdateNotificationResolutionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<Notification> {
    return this.rpc.unary(
      DashboardBffServiceUpdateNotificationResolutionDesc,
      UpdateNotificationResolutionRequest.fromPartial(request),
      metadata,
    );
  }

  DeleteAllPushSubscriptions(
    request: DeepPartial<DeleteAllPushSubscriptionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<DeleteAllPushSubscriptionsResponse> {
    return this.rpc.unary(
      DashboardBffServiceDeleteAllPushSubscriptionsDesc,
      DeleteAllPushSubscriptionsRequest.fromPartial(request),
      metadata,
    );
  }

  CreatePushSubscription(request: DeepPartial<PushSubscription>, metadata?: grpc.Metadata): Promise<PushSubscription> {
    return this.rpc.unary(
      DashboardBffServiceCreatePushSubscriptionDesc,
      PushSubscription.fromPartial(request),
      metadata,
    );
  }

  GetWebPushVAPIDPublicKey(
    request: DeepPartial<GetWebPushVAPIDPublicKeyRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetWebPushVAPIDPublicKeyResponse> {
    return this.rpc.unary(
      DashboardBffServiceGetWebPushVAPIDPublicKeyDesc,
      GetWebPushVAPIDPublicKeyRequest.fromPartial(request),
      metadata,
    );
  }

  TriggerFetchToCompositions(
    request: DeepPartial<TriggerFetchToCompositionsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TriggerFetchToCompositionsResponse> {
    return this.rpc.unary(
      DashboardBffServiceTriggerFetchToCompositionsDesc,
      TriggerFetchToCompositionsRequest.fromPartial(request),
      metadata,
    );
  }

  FetchOrganizationBreakdown(
    request: DeepPartial<FetchOrganizationBreakdownRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchOrganizationBreakdownResponse> {
    return this.rpc.unary(
      DashboardBffServiceFetchOrganizationBreakdownDesc,
      FetchOrganizationBreakdownRequest.fromPartial(request),
      metadata,
    );
  }

  UpdateTermsAndConditionsConsent(
    request: DeepPartial<UpdateTermsAndConditionsConsentRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TermsAndConditionsConsent> {
    return this.rpc.unary(
      DashboardBffServiceUpdateTermsAndConditionsConsentDesc,
      UpdateTermsAndConditionsConsentRequest.fromPartial(request),
      metadata,
    );
  }

  GetTermsAndConditionsConsent(
    request: DeepPartial<GetTermsAndConditionsConsentRequest>,
    metadata?: grpc.Metadata,
  ): Promise<TermsAndConditionsConsent> {
    return this.rpc.unary(
      DashboardBffServiceGetTermsAndConditionsConsentDesc,
      GetTermsAndConditionsConsentRequest.fromPartial(request),
      metadata,
    );
  }

  FetchVotingPolicies(
    request: DeepPartial<FetchVotingPoliciesRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchVotingPoliciesResponse> {
    return this.rpc.unary(
      DashboardBffServiceFetchVotingPoliciesDesc,
      FetchVotingPoliciesRequest.fromPartial(request),
      metadata,
    );
  }

  CreateCompositionWithModelPortfolio(
    request: DeepPartial<CreateCompositionWithModelPortfolioRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateCompositionWithModelPortfolioResponse> {
    return this.rpc.unary(
      DashboardBffServiceCreateCompositionWithModelPortfolioDesc,
      CreateCompositionWithModelPortfolioRequest.fromPartial(request),
      metadata,
    );
  }

  FetchInvestorVoteResults(
    request: DeepPartial<FetchInvestorVoteResultsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<FetchInvestorVoteResultsResponse> {
    return this.rpc.unary(
      DashboardBffServiceFetchInvestorVoteResultsDesc,
      FetchInvestorVoteResultsRequest.fromPartial(request),
      metadata,
    );
  }
}

export const DashboardBffServiceDesc = { serviceName: "tumelo.dashboardbff.v1.DashboardBffService" };

export const DashboardBffServiceHelloWorldDesc: UnaryMethodDefinitionish = {
  methodName: "HelloWorld",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return HelloWorldRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = HelloWorldResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceUpsertUserPreferencesDesc: UnaryMethodDefinitionish = {
  methodName: "UpsertUserPreferences",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UserPreferences.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserPreferences.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceGetUserPreferencesDesc: UnaryMethodDefinitionish = {
  methodName: "GetUserPreferences",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserPreferencesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserPreferences.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceGetIfNotSetDefaultUserPreferencesDesc: UnaryMethodDefinitionish = {
  methodName: "GetIfNotSetDefaultUserPreferences",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserPreferencesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UserPreferences.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceListNotificationsDesc: UnaryMethodDefinitionish = {
  methodName: "ListNotifications",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListNotificationsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListNotificationsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceUpdateNotificationResolutionDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateNotificationResolution",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateNotificationResolutionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Notification.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceDeleteAllPushSubscriptionsDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteAllPushSubscriptions",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteAllPushSubscriptionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = DeleteAllPushSubscriptionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceCreatePushSubscriptionDesc: UnaryMethodDefinitionish = {
  methodName: "CreatePushSubscription",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PushSubscription.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PushSubscription.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceGetWebPushVAPIDPublicKeyDesc: UnaryMethodDefinitionish = {
  methodName: "GetWebPushVAPIDPublicKey",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetWebPushVAPIDPublicKeyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetWebPushVAPIDPublicKeyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceTriggerFetchToCompositionsDesc: UnaryMethodDefinitionish = {
  methodName: "TriggerFetchToCompositions",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return TriggerFetchToCompositionsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TriggerFetchToCompositionsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceFetchOrganizationBreakdownDesc: UnaryMethodDefinitionish = {
  methodName: "FetchOrganizationBreakdown",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchOrganizationBreakdownRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchOrganizationBreakdownResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceUpdateTermsAndConditionsConsentDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateTermsAndConditionsConsent",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateTermsAndConditionsConsentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TermsAndConditionsConsent.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceGetTermsAndConditionsConsentDesc: UnaryMethodDefinitionish = {
  methodName: "GetTermsAndConditionsConsent",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTermsAndConditionsConsentRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = TermsAndConditionsConsent.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceFetchVotingPoliciesDesc: UnaryMethodDefinitionish = {
  methodName: "FetchVotingPolicies",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchVotingPoliciesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchVotingPoliciesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceCreateCompositionWithModelPortfolioDesc: UnaryMethodDefinitionish = {
  methodName: "CreateCompositionWithModelPortfolio",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateCompositionWithModelPortfolioRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateCompositionWithModelPortfolioResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DashboardBffServiceFetchInvestorVoteResultsDesc: UnaryMethodDefinitionish = {
  methodName: "FetchInvestorVoteResults",
  service: DashboardBffServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return FetchInvestorVoteResultsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = FetchInvestorVoteResultsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
