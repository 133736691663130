import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgMoneybag = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 40, height: 40, viewBox: "0 0 40 40", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M24.2203 11.6688C23.7831 11.3346 23.3356 11.0815 22.8983 10.8486C24.0169 10.1398 24.861 9.03616 25.1966 7.72999C25.1966 7.69961 25.2271 7.59836 25.2271 7.56798L25.2475 7.46673C25.461 6.44407 25.1254 5.43153 24.3424 4.75313C23.5186 4.02411 22.3288 3.81147 21.261 4.18611C20.478 4.46962 19.522 4.46962 18.7288 4.18611C17.661 3.80135 16.4814 4.02411 15.6475 4.75313C14.8644 5.43153 14.5288 6.45419 14.7322 7.43635C14.7322 7.44648 14.7525 7.55786 14.7627 7.57811C14.7627 7.57811 14.7932 7.72999 14.7932 7.74011C15.1288 9.04629 15.9831 10.15 17.0915 10.8587C16.6542 11.0815 16.2169 11.3447 15.7695 11.6789C11.3254 15.0101 8 22.7358 8 27.4137C8 32.8713 12.3729 36 20 36C27.6271 36 32 32.8713 32 27.4137C32 22.7358 28.6746 15.0101 24.2203 11.6688V11.6688ZM16.339 7.13259C16.2068 6.52507 16.522 6.14031 16.7254 5.96818C16.9898 5.74542 17.3254 5.62391 17.6712 5.62391C17.8441 5.62391 18.0169 5.65429 18.1898 5.71504C19.339 6.12006 20.661 6.12006 21.8102 5.71504C22.3186 5.53279 22.8881 5.63404 23.2746 5.96818C23.478 6.14031 23.7831 6.52507 23.6407 7.2136C23.6203 7.28447 23.6203 7.3351 23.6203 7.32497C23.2746 8.64127 22.1458 9.71456 20.8136 9.98794C20.2644 10.0993 19.7254 10.0993 19.1864 9.98794C17.8441 9.71456 16.7153 8.64127 16.3898 7.40598C16.3898 7.3351 16.3695 7.27435 16.339 7.12247V7.13259ZM20 34.3799C16.1254 34.3799 9.62712 33.4788 9.62712 27.4137C9.62712 23.2015 12.739 15.972 16.7559 12.9648C17.8746 12.1244 18.9627 11.6991 20 11.6991C21.0373 11.6991 22.1153 12.1244 23.2441 12.9648C27.261 15.972 30.3729 23.2015 30.3729 27.4137C30.3729 33.4788 23.8746 34.3799 20 34.3799V34.3799Z" }),
        React.createElement("path", { d: "M22.53 25.26H18.83C18.94 25.02 18.99 24.76 18.98 24.5V24.09H20.77C21.22 24.09 21.58 23.73 21.58 23.28C21.58 22.83 21.22 22.47 20.77 22.47H18.97C18.97 22.39 18.97 22.3 18.97 22.22V21.41C19 20.96 19.29 20.57 19.71 20.41C20.12 20.27 20.56 20.27 20.97 20.41C21.33 20.57 21.56 20.93 21.56 21.32C21.56 21.76 21.91 22.11 22.35 22.12C22.8 22.15 23.18 21.82 23.22 21.37V21.33C23.22 20.26 22.58 19.3 21.58 18.91C20.85 18.67 20.06 18.67 19.33 18.91C18.31 19.28 17.6 20.21 17.5 21.29V22.5H17.07C16.62 22.5 16.26 22.86 16.26 23.31C16.26 23.76 16.62 24.12 17.07 24.12L16.97 24.09H17.4V24.54C17.28 24.88 17.07 25.18 16.8 25.41C16.52 25.62 16.4 25.99 16.51 26.32C16.61 26.65 16.92 26.87 17.26 26.88H22.53C22.98 26.88 23.34 26.52 23.34 26.07C23.34 25.62 22.98 25.26 22.53 25.26V25.26Z" })));
};
