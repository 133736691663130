import { z } from 'zod'
import { Analytics, AnalyticsConsoleLogger } from '@tumelo/analytics'
import { AccountService } from './services/Account/AccountService'
import { AccountServiceApi } from './services/Account/AccountServiceApi'
import { AccountServiceMock } from './services/Account/AccountServiceMock'
import { InstrumentService } from './services/Instrument/InstrumentService'
import { InstrumentServiceAPI } from './services/Instrument/InstrumentServiceAPI'
import { InstrumentServiceMock } from './services/Instrument/InstrumentServiceMock'
import { InvestorService } from './services/Investor/InvestorService'
import { InvestorServiceCognito } from './services/Investor/InvestorServiceCognito'
import { InvestorServiceMock } from './services/Investor/InvestorServiceMock'
import { LoggerService } from './services/Logger/LoggerService'
import { ModelPortfolioService } from './services/ModelPortfolio/ModelPortfolioService'
import { ModelPortfolioServiceAPI } from './services/ModelPortfolio/ModelPortfolioServiceApi'
import { ModelPortfolioServiceMock } from './services/ModelPortfolio/ModelPortfolioServiceMock'
import { OrganizationService } from './services/Organization/OrganizationService'
import { OrganizationServiceAPI } from './services/Organization/OrganizationServiceAPI'
import { OrganizationServiceMock } from './services/Organization/OrganizationServiceMock'
import { PollService } from './services/Poll/PollService'
import { PollServiceAPI } from './services/Poll/PollServiceAPI'
import { PollServiceDemoInjectedFetch } from './services/Poll/PollServiceDemoInjectedFetch'
import { PollServiceMock } from './services/Poll/PollServiceMock'
import { VotingService } from './services/Voting/VotingService'
import { VotingServiceAPI } from './services/Voting/VotingServiceApi'
import { VotingServiceMock } from './services/Voting/VotingServiceMock'
import { InvestorExtendedProfileService } from './services/InvestorExtendedProfile/InvestorExtendedProfileService'
import { InvestorExtendedProfileServiceAPI } from './services/InvestorExtendedProfile/InvestorExtendedProfileServiceApi'
import { InvestorExtendedProfileServiceLocalStorage } from './services/InvestorExtendedProfile/InvestorExtendedProfileServiceLocalStorage'
import { VotingServiceInjectedFetch } from './services/Voting/VotingServiceInjectedFetch'
import { FundManagerVotesService } from './services/FundManagerVote/FundManagerVotesService'
import { FundManagerVotesServiceMock } from './services/FundManagerVote/FundManagerVotesServiceMock'
import { FundManagerVotesServiceAPI } from './services/FundManagerVote/FundManagerVotesServiceAPI'
import { DashboardBffServiceMock } from './services/DashboardBff/DashboardBffServiceMock'
import { DashboardBffServiceGRPC } from './services/DashboardBff/DashboardBffServiceGRPC'
import { DashboardBffService } from './services/DashboardBff/DashboardBffService'
import {
  AccountTriggerFetchCompositionDoNothing,
  AccountTriggerFetchCompositionGRPC,
  AccountTriggerFetchCompositionService,
} from './services/AccountTriggerFetchCompsition/AccountTriggerFetchCompositionService'
import { LoggerServiceConsole } from './services/Logger/LoggerServiceConsole'
import { Auth } from './services/Auth/Auth'
import { AuthCognito } from './services/Auth/AuthCognito'

export interface Services {
  accountService: AccountService
  pollService: PollService
  organizationService: OrganizationService
  investorService: InvestorService
  investorExtendedProfileService: InvestorExtendedProfileService
  instrumentService: InstrumentService
  modelPortfolioService: ModelPortfolioService
  fundManagerVotesService: FundManagerVotesService
  loggerService: LoggerService
  votingService: VotingService
  analyticsService: Analytics
  dashboardBffService: DashboardBffService
  accountTriggerFetchCompositionService: AccountTriggerFetchCompositionService
  authService: Auth
}

const ConfigSchema = z.union([
  // prod
  z.object({
    type: z.enum(['prod']),
    baseUrl: z.string(),
  }),
  // prod with fetcher
  z.object({
    type: z.enum(['prod-with-fetcher']),
    baseUrl: z.string(),
  }),
  z.object({
    type: z.enum(['demo']),
    baseUrl: z.string(),
    votingDataPath: z.string(),
    pollDataPath: z.string(),
  }),
  z.object({
    type: z.enum(['mock']),
  }),
])

/**
 * Here we set a service as mock or real service depending on what the env
 * variable for that service is set as.
 *
 * For rest of the application, it doesn't care whether it's using the
 * "real" service or the "mocked" service, and this is the only file in the repo
 * where there is a distinction.
 *
 * should be the *real* implementation of a service when they are defined.
 */
export const returnServices = (
  configuration: unknown,
  logger: LoggerService,
  analytics: Analytics,
  options: {
    fetchPathPrefix: string
  } = { fetchPathPrefix: '' }
): Services => {
  const config = ConfigSchema.parse(configuration)
  const { fetchPathPrefix } = options
  switch (config.type) {
    case 'mock':
      return getMockServices()
    case 'prod': {
      const { baseUrl } = config
      return getProdServices(logger, analytics, baseUrl)
    }
    case 'prod-with-fetcher': {
      const { baseUrl } = config
      return getProdServicesWithInjectedFetch(logger, analytics, baseUrl)
    }
    case 'demo': {
      const { baseUrl, votingDataPath, pollDataPath } = config
      return getDemoServices(logger, analytics, fetchPathPrefix, baseUrl, votingDataPath, pollDataPath)
    }
    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      throw new Error(`Unknown config type: ${config.type}`)
  }
}

export const getMockServices = (): Services => {
  return {
    accountService: AccountServiceMock,
    accountTriggerFetchCompositionService: AccountTriggerFetchCompositionDoNothing,
    pollService: PollServiceMock,
    investorService: InvestorServiceMock,
    instrumentService: InstrumentServiceMock,
    modelPortfolioService: ModelPortfolioServiceMock,
    votingService: VotingServiceMock,
    fundManagerVotesService: FundManagerVotesServiceMock,
    dashboardBffService: DashboardBffServiceMock,
    organizationService: OrganizationServiceMock,
    investorExtendedProfileService: InvestorExtendedProfileServiceLocalStorage,
    loggerService: LoggerServiceConsole,
    analyticsService: AnalyticsConsoleLogger,
    authService: AuthCognito,
  }
}

const getProdServices = (loggerService: LoggerService, analyticsService: Analytics, baseUrl: string): Services => {
  return {
    accountService: new AccountServiceApi(baseUrl),
    accountTriggerFetchCompositionService: AccountTriggerFetchCompositionDoNothing,
    dashboardBffService: new DashboardBffServiceGRPC(baseUrl),
    fundManagerVotesService: new FundManagerVotesServiceAPI(baseUrl),
    instrumentService: new InstrumentServiceAPI(baseUrl),
    investorExtendedProfileService: new InvestorExtendedProfileServiceAPI(baseUrl),
    investorService: InvestorServiceCognito,
    modelPortfolioService: new ModelPortfolioServiceAPI(baseUrl),
    organizationService: new OrganizationServiceAPI(baseUrl),
    pollService: new PollServiceAPI(baseUrl),
    votingService: new VotingServiceAPI(baseUrl),
    loggerService,
    analyticsService,
    authService: AuthCognito,
  }
}

const getProdServicesWithInjectedFetch = (
  loggerService: LoggerService,
  analyticsService: Analytics,
  baseUrl: string
): Services => {
  const services = getProdServices(loggerService, analyticsService, baseUrl)
  return {
    ...services,
    accountTriggerFetchCompositionService: new AccountTriggerFetchCompositionGRPC(baseUrl),
  }
}

const getDemoServices = (
  loggerService: LoggerService,
  analyticsService: Analytics,
  pathPrefix: string,
  baseUrl: string,
  votingDataPath: string,
  pollDataPath: string
): Services => {
  const services = getProdServices(loggerService, analyticsService, baseUrl)
  return {
    ...services,
    votingService: new VotingServiceInjectedFetch(votingDataPath, pathPrefix),
    pollService: new PollServiceDemoInjectedFetch(pollDataPath, pathPrefix),
    fundManagerVotesService: FundManagerVotesServiceMock,
  }
}
