import { Investor, sampleInvestor } from '@tumelo/shared'
import { sleep } from '@tumelo/shared/utils'
import { InvestorService } from './InvestorService'

export const InvestorServiceMock: InvestorService = {
  async getInvestor(): Promise<Investor> {
    await sleep()
    return sampleInvestor()
  },
  async deleteInvestor(): Promise<void> {
    // eslint-disable-next-line no-console
    console.log('deleteInvestor() called')
  },
  async updateInvestorEmail(): Promise<void> {
    // eslint-disable-next-line no-console
    console.log('updateInvestorEmail() called')
  },
  async updateInvestorNickname(nickname: string): Promise<Investor> {
    await sleep()
    return { ...sampleInvestor(), nickname }
  },
}
