import React, { useEffect, useState } from 'react'
import { H1, H4, ModalBody, Stack, Button } from '@tumelo/designsystem'
import { useTheme } from 'styled-components'
import { VotingPolicy, VotingPolicyId } from '@tumelo/shared'
import { useAnalytics } from '../../application/analytics'
import { buildEvent } from '../../application/analytics/eventBuilders'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'
import { Payload } from '../../application/payload'
import { InlineError } from '../Error'
import { PolicyCard } from './PolicyCard'

interface Props {
  policies: VotingPolicy[]
  onPolicySelection: (id: VotingPolicyId) => void
  onPolicyDocumentClick: (id: VotingPolicyId) => void
  updateVotingPolicySelection: Payload<'not-updating'>
  goForward: () => void
  onTryAgain: () => void
}

export const PickAPolicy: React.FC<Props> = ({
  policies,
  onPolicySelection,
  onPolicyDocumentClick,
  updateVotingPolicySelection,
  goForward,
  onTryAgain,
}) => {
  const numberOfPolicies = policies.length
  const { colors } = useTheme()
  const subtitle = `Choose from ${numberOfPolicies} expert-backed policies.`

  const { trackEvent } = useAnalytics()

  const [policyPicked, setPolicyPicked] = useState(false)

  useEffect(() => {
    if (updateVotingPolicySelection === 'not-updating' && policyPicked) {
      goForward()
    }
  }, [updateVotingPolicySelection, policyPicked, goForward])

  const handleTryAgain = () => {
    setPolicyPicked(false)
    onTryAgain()
  }
  return (
    <ModalBody fullScreen>
      <H1>Pick a policy</H1>
      <PayloadUnwrapper
        item={updateVotingPolicySelection}
        customSpinner={<PickAPolicyLoading policies={policies} />}
        customError={<CustomError onTryAgain={handleTryAgain} />}
      >
        {() => (
          <>
            {numberOfPolicies > 1 && (
              <H4 as="h2" color={colors.secondary.medium}>
                {subtitle}
              </H4>
            )}
            <Stack
              flexDirection="column"
              alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'center' }}
              gap="2.4rem"
            >
              {policies.map((policy, index) => (
                <PolicyCard
                  key={policy.votingPolicyId}
                  policy={policy}
                  onSelection={(id: VotingPolicyId) => {
                    onPolicySelection(id)
                    setPolicyPicked(true)
                    trackEvent(buildEvent.selectVotingPolicy(id, index + 1))
                  }}
                  onDocumentClick={onPolicyDocumentClick}
                />
              ))}
            </Stack>
          </>
        )}
      </PayloadUnwrapper>
    </ModalBody>
  )
}

const PickAPolicyLoading: React.FC<{ policies: VotingPolicy[] }> = ({ policies }) => {
  const numberOfPolicies = policies.length
  const { colors } = useTheme()
  const subtitle = `Choose from ${numberOfPolicies} expert-backed policies.`

  return (
    <>
      {numberOfPolicies > 1 && (
        <H4 as="h2" color={colors.secondary.medium}>
          {subtitle}
        </H4>
      )}
      <Stack
        flexDirection="column"
        alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-start', lg: 'center' }}
        gap="2.4rem"
      >
        {policies.map((policy) => (
          <PolicyCard
            key={policy.votingPolicyId}
            policy={policy}
            onSelection={() => ({})}
            disabled
            onDocumentClick={() => ({})}
          />
        ))}
      </Stack>
    </>
  )
}

const CustomError: React.FC<{ onTryAgain?: () => void; disabled?: boolean }> = ({
  onTryAgain = () => ({}),
  disabled = false,
}) => {
  const msg = `Sorry, something unexpected has happened. Try again or you can refresh the page, if the problem persists`
  const supportNode = (
    <>
      <a href="https://tumelo.zendesk.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">
        get in touch
      </a>{' '}
      with our support team
    </>
  )
  return (
    <Stack>
      <InlineError title="Something went wrong...">
        <span>
          {msg} {supportNode}.
        </span>
      </InlineError>
      <Button onClick={onTryAgain} isDisabled={disabled}>
        Try again
      </Button>
    </Stack>
  )
}
