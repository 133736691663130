/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { ModelPortfolioId, SubmitButton, Heading, SubHeading } from '@tumelo/shared'
import { Form, Formik } from 'formik'
import { SelfSelectForm as SelfSelectFormType } from '../../config/SoftConfigPortfolioSelection'
import { useAvailableModelPortfolios } from '../../application/features/account/hooks'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'
import { BinaryQuestion } from './components/BinaryQuestion'
import { ListQuestion, ListQuestionAll, NoneOfTheAbove } from './components/ListQuestion'
import { questionsToQuestionBlocks } from './questionsToQuestionBlocks'

interface SelfSelectFormProps {
  selfSelectForm: SelfSelectFormType
  onSelect: (id: ModelPortfolioId) => void
  onNoFundSelected: () => void
}

export const SelfSelectForm: React.FC<SelfSelectFormProps> = ({ selfSelectForm, onSelect, onNoFundSelected }) => {
  const { availableModelPortfolios } = useAvailableModelPortfolios()
  const questions = questionsToQuestionBlocks(selfSelectForm.questions)
  const { title, subheading } = selfSelectForm
  const isQuestionId = (id: string) => !!questions.find((q) => q.question.id === id)

  const getSelectedValue = (values: { [key: string]: string }): string | undefined => {
    let value = ''
    questions.forEach((q) => {
      // Question must currently be rendered to use value from it
      if (q.condition(values)) {
        value = values[q.question.id]
      }
    })
    // if questionID OR not set then not a model portfolio
    if (isQuestionId(value) || value === '') {
      return undefined
    }
    return value
  }

  const handleSubmit = (values: { [key: string]: string }) => {
    const modelPortfolioId = getSelectedValue(values)
    if (modelPortfolioId === NoneOfTheAbove) {
      onNoFundSelected()
      return
    }
    if (modelPortfolioId === undefined) {
      throw new Error('model portfolio not selected')
    }
    onSelect(modelPortfolioId as ModelPortfolioId)
  }

  return (
    <>
      <Heading as="h1">{title}</Heading>
      {subheading && <SubHeading>{subheading}</SubHeading>}
      <PayloadUnwrapper item={availableModelPortfolios}>
        {(availableModelPortfolios) => (
          <Formik
            initialValues={Object.fromEntries(Object.entries(questions).map(([, q]) => [q.question.id, '']))}
            onSubmit={handleSubmit}
          >
            {({ handleChange, values }) => {
              return (
                <Form>
                  {questions.map(
                    (q) =>
                      q.condition(values) &&
                      ((): React.ReactElement => {
                        switch (q.question.type) {
                          case 'binary': {
                            return (
                              <BinaryQuestion
                                key={q.question.id}
                                question={q.question}
                                onChange={handleChange}
                                value={values[q.question.id]}
                              />
                            )
                          }
                          case 'list': {
                            return (
                              <ListQuestion
                                key={q.question.id}
                                question={q.question}
                                onChange={handleChange}
                                value={values[q.question.id]}
                                modelPortfolioIdToName={Object.fromEntries(
                                  availableModelPortfolios.map((m) => [m.id, m.title])
                                )}
                                showNoneOfTheAbove
                              />
                            )
                          }
                          case 'list-all': {
                            return (
                              <ListQuestionAll
                                key={q.question.id}
                                question={q.question}
                                onChange={handleChange}
                                value={values[q.question.id]}
                                modelPortfolios={availableModelPortfolios}
                                showNoneOfTheAbove
                              />
                            )
                          }
                        }
                      })()
                  )}
                  <div style={{ justifyContent: 'center' }}>
                    {getSelectedValue(values) && <Submit label="Submit" />}
                  </div>
                </Form>
              )
            }}
          </Formik>
        )}
      </PayloadUnwrapper>
    </>
  )
}

const Submit = styled(SubmitButton)`
  margin: 0 auto;
  display: block;
`
