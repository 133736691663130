/* eslint-disable @typescript-eslint/no-deprecated */

import React, { FC } from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import styled, { useTheme } from 'styled-components'
import { Ballot, Button, ChatIcon, Body, TextAreaWithSuggestions, hexAlpha } from '@tumelo/shared'
import { useAppDispatch } from '../../application/store'
import { sendComment } from '../../application/features/singleBallot/asyncActions'
import { HeadingWithHelp } from '../HeadingWithHelp'

interface Props {
  ballot: Ballot
}

export const VoteCommentForm: FC<Props> = ({ ballot }) => {
  const dispatch = useAppDispatch()
  const { colors } = useTheme()
  const commentCharLimit = 1000

  const ValidationSchema = Yup.object().shape({
    comment: Yup.string()
      .max(commentCharLimit, 'Your comment (although insightful) has reached the character limit of 1000.')
      .required("Oops, don't forget to write your comment before hitting submit."),
  })

  if (ballot.comment) {
    return (
      <>
        <HeadingWithIcon>
          <ChatIcon width={27} fill={colors.primary.dark} />

          <StyledHeadingWithHelp
            title="Your message has been sent"
            helpText="Your comments and your vote will be sent to your fund manager. They can then consider your opinions when placing their legal vote at the company AGM (Annual General Meeting) where they will represent you."
          />
        </HeadingWithIcon>
        <Body margin="0 0 2.8rem">This message is sent to your fund manager alongside your vote preference.</Body>
        <Comment>
          <Body margin={0}>{ballot.comment.content}</Body>
        </Comment>
      </>
    )
  }

  return (
    <>
      <HeadingWithIcon>
        <ChatIcon width={27} fill={colors.primary.dark} />

        <StyledHeadingWithHelp
          title={`Why did you vote ${ballot.investorVote?.response === 'for' ? 'in favour' : 'against'}?`}
          helpText="Your comments and your vote will be sent to your fund manager. They can then consider your opinions when placing their legal vote at the company AGM (Annual General Meeting) where they will represent you."
        />
      </HeadingWithIcon>

      <Body margin="0 0 2.8rem">This message is sent to your fund manager alongside your vote preference.</Body>
      <Formik
        initialValues={{ comment: '' }}
        validationSchema={ValidationSchema}
        onSubmit={(values) => {
          dispatch(sendComment(ballot, values.comment))
        }}
      >
        <Form>
          <TextAreaWithSuggestions
            name="comment"
            showValidationErrorMessages
            charLimit={commentCharLimit}
            placeholder="Begin typing or select a reason from the examples below…"
            suggestions={[
              'To align with my personal values',
              'I believe this decision is better for the company',
              'I agree with the shareholders',
              'I believe this is the best decision for my money',
            ]}
          />
          <div style={{ marginTop: '2rem', float: 'right' }}>
            <Button color={colors.dark} inverse size="medium" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  )
}

const HeadingWithIcon = styled.div`
  display: flex;
  align-items: top;
`

const StyledHeadingWithHelp = styled(HeadingWithHelp)`
  margin-bottom: 0;
  margin-left: 1.2rem;
`

const Comment = styled.div`
  margin-top: 1.8rem;
  background: ${({ theme }) => hexAlpha(theme.colors.secondary.light, 0.2)};
  padding: 2rem 2rem 4rem;
`
