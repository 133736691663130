import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getInitialOrganizationIndustries } from '@tumelo/shared'
import { isValueState } from '../../payload'
import {
  getInitialPollResponse,
  getInitialPollTags,
  getInitialBinaryFilters,
  SearchFilter,
  FilterEntry,
  getInitialCompanyFilters,
  getInitialFilterMenu,
  getInitialCountries,
} from '../../types/SearchFilters'

type State = SearchFilter

export const getInitialState = (): State => {
  return {
    filterMenu: getInitialFilterMenu(),
    binaryFilters: getInitialBinaryFilters(),
    pollResponse: getInitialPollResponse(),
    votingOrganizationIndustries: getInitialOrganizationIndustries(),
    pollTags: getInitialPollTags(),
    companyFilters: getInitialCompanyFilters(),
    companyOrganizationIndustries: getInitialOrganizationIndustries(),
    companyHQCountries: getInitialCountries(),
  }
}

const searchFilters = createSlice({
  name: 'searchFilters',
  initialState: getInitialState(),
  reducers: {
    toggleFilter<Filter extends keyof SearchFilter>(
      state: State,
      action: PayloadAction<{ filter: Filter; entry: FilterEntry }>
    ) {
      const { filter, entry } = action.payload

      const initialFilterState = state[filter]
      if (!isValueState(initialFilterState) || typeof initialFilterState !== 'object') {
        throw new Error(`The entry ${entry.toString()} does not exist on filter ${filter}`)
      }
      const filterEntryValue = initialFilterState[entry as keyof typeof initialFilterState]

      if (filterEntryValue === undefined) {
        throw new Error(`The entry ${entry.toString()} does not exist on filter ${filter}`)
      }

      return {
        ...state,
        [filter]: {
          ...initialFilterState,
          [entry]: !filterEntryValue,
        },
      }
    },
    setFilterToTrue<Filter extends keyof SearchFilter>(
      state: State,
      action: PayloadAction<{ filter: Filter; entry: FilterEntry }>
    ) {
      const { filter, entry } = action.payload

      const initialFilterState = state[filter]
      if (!isValueState(initialFilterState) || typeof initialFilterState !== 'object') {
        throw new Error(`The entry ${entry.toString()} does not exist on filter ${filter}`)
      }
      const filterEntryValue = initialFilterState[entry as keyof typeof initialFilterState]

      if (filterEntryValue === undefined) {
        throw new Error(`The entry ${entry.toString()} does not exist on filter ${filter}`)
      }
      return {
        ...state,
        [filter]: {
          ...initialFilterState,
          [entry]: true,
        },
      }
    },
    setCompanyName<Filter extends keyof SearchFilter>(
      state: State,
      action: PayloadAction<{ filter: Filter; name: string }>
    ) {
      const { filter, name } = action.payload
      const initialFilterState = state[filter]
      return {
        ...state,
        [filter]: {
          ...initialFilterState,
          name,
        },
      }
    },
    setFilterVisibilityOnMobile<Filter extends keyof SearchFilter>(
      state: State,
      action: PayloadAction<{ filter: Filter; isMenuOpenOnMobile: boolean }>
    ) {
      const { filter, isMenuOpenOnMobile } = action.payload
      const initialFilterState = state[filter]
      return {
        ...state,
        [filter]: {
          ...initialFilterState,
          isMenuOpenOnMobile,
        },
      }
    },
    clearFilters(state: State) {
      return {
        // When clearing filters don't reset the menu state
        filterMenu: state.filterMenu,
        binaryFilters: getInitialBinaryFilters(),
        pollResponse: getInitialPollResponse(),
        votingOrganizationIndustries: getInitialOrganizationIndustries(),
        pollTags: getInitialPollTags(),
        companyFilters: getInitialCompanyFilters(),
        companyOrganizationIndustries: getInitialOrganizationIndustries(),
        companyHQCountries: getInitialCountries(),
      }
    },
  },
})

export const { toggleFilter, clearFilters, setCompanyName, setFilterToTrue } = searchFilters.actions
export default searchFilters.reducer
