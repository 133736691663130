import { Analytics } from './Analytics'

export const combineAnalytics = (...analytics: Analytics[]): Analytics => ({
  createIdentity: (userId) => analytics.forEach((a) => a.createIdentity(userId)),
  pageView: (page) => analytics.forEach((a) => a.pageView(page)),
  trackEvent: (event) => analytics.forEach((a) => a.trackEvent(event)),
  signOutUser: () => analytics.forEach((a) => a.signOutUser()),
  setUserProperties: (userId) => analytics.forEach((a) => a.setUserProperties(userId)),
  setSuperProperties: (props) => analytics.forEach((a) => a.setSuperProperties(props)),
  registerExternalLinkClickListener: () => analytics.forEach((a) => a.registerExternalLinkClickListener()),
})
