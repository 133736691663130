import React from 'react';
import styled from 'styled-components';
import { CancelIcon as Cancel } from '../images/Cancel';
import { Search as SearchIcon } from '../images/Search';
export const Search = ({ value, setSearchValue, placeholder, name, handleSearchKeyDown, onBlur }) => {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearchKeyDown();
        }
    };
    return (React.createElement(Container, null,
        React.createElement("label", { htmlFor: "search-input" },
            React.createElement(StyledSearchIcon, null)),
        React.createElement(SearchInput, { id: "search-input", type: "text", role: "search", placeholder: placeholder, name: name, value: value, onChange: (e) => setSearchValue(e.target.value), onBlur: () => onBlur && onBlur(value), onKeyDown: (event) => {
                handleKeyDown(event);
            } }),
        React.createElement(CancelButton, { "aria-label": "clear search", role: "button", onClick: () => setSearchValue('') },
            React.createElement(CancelIcon, null))));
};
const Container = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 6px 0 ${({ theme }) => theme.colors.boxShadow};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
`;
const SearchInput = styled.input `
  display: flex;
  flex-direction: row;
  width: 100%;
  border-width: 0px;
  border: none;
  padding: 1.5rem 2rem;
  color: ${({ theme }) => theme.colors.dark};
  border-radius: 1rem;
  font-size: 1.5rem;

  &&::placeholder {
    color: ${({ theme }) => theme.colors.grey.medium};
  }
`;
const CancelIcon = styled(Cancel) `
  margin: 1rem;
  width: 1.2rem;
  height: auto;
  fill: ${({ theme }) => theme.colors.grey.medium};
  stroke: ${({ theme }) => theme.colors.grey.medium};
  cursor: pointer;
`;
const StyledSearchIcon = styled(SearchIcon) `
  margin: 1rem;
  width: auto;
  height: 2rem;
  fill: ${({ theme }) => theme.colors.grey.medium};
  stroke: ${({ theme }) => theme.colors.grey.medium};
`;
const CancelButton = styled.span `
  display: flex;
`;
