import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InstrumentReferenceString } from '@tumelo/shared'
import { isErrorStates, isIntermediaryState, isValueState, Payload } from '../../payload'
import { FundFilter } from '../../types/SearchFilters'

interface State {
  fundFilters: Payload<FundFilter[]>
}

const initialState: State = {
  fundFilters: 'not-initialised',
}

const fundFilterSlice = createSlice({
  name: 'fundFilter',
  initialState,
  reducers: {
    toggleFunds: (state, action: PayloadAction<{ instrumentReferenceString: InstrumentReferenceString }>) => {
      if (isValueState(state.fundFilters)) {
        const indexOf = state.fundFilters.findIndex(
          (obj) => obj.instrumentReferenceString === action.payload.instrumentReferenceString
        )
        if (indexOf >= 0) {
          const initialToggleState = state.fundFilters[indexOf].isToggled
          state.fundFilters[indexOf].isToggled = !initialToggleState
        }
      }
    },
    setFundFilters: (state, action: PayloadAction<Payload<FundFilter[]>>) => {
      // TO DO: check is Value State? Error State?? intermediary State?
      if (isErrorStates(action.payload) || isIntermediaryState(action.payload) || isValueState(action.payload)) {
        state.fundFilters = action.payload
      }
    },
    setOneFundFilterClearRest: (state, action: PayloadAction<{ instrumentReferenceString: string }>) => {
      if (isValueState(state.fundFilters)) {
        state.fundFilters = state.fundFilters.map((fundFilter) => {
          if (fundFilter.instrumentReferenceString === action.payload.instrumentReferenceString) {
            return {
              ...fundFilter,
              isToggled: true,
            }
          }
          return {
            ...fundFilter,
            isToggled: false,
          }
        })
      }
    },
    clearFundFilters: (state) => {
      if (isValueState(state.fundFilters)) {
        state.fundFilters = state.fundFilters.map((fundFilter) => {
          if (fundFilter.isToggled === true) {
            return {
              ...fundFilter,
              isToggled: false,
            }
          }
          return fundFilter
        })
      }
    },
  },
})

export const { toggleFunds, setFundFilters, clearFundFilters, setOneFundFilterClearRest } = fundFilterSlice.actions
export default fundFilterSlice.reducer
