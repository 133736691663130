export const Maybe = {
  map:
    <T, U>(f: (t: T) => U) =>
    (x: T | null | undefined) =>
      x ? f(x) : undefined,
  defaultValue:
    <T, U extends T>(defaultItem: U) =>
    (value: T | undefined | null) =>
      value == null ? defaultItem : value,
  defaultValueI:
    <T, U>(defaultItem: U) =>
    (value: T | undefined | null) =>
      value == null ? defaultItem : value,
  asUndefinedOnly: <T>(x: T | null | undefined) => (x === null ? undefined : x),
  tee:
    <T>(f: (t: T) => void) =>
    (value: T | undefined | null) => {
      if (value != null) f(value)
    },
}
