import { AuthError as AmplifyAuthError } from 'aws-amplify/auth'
import { I18n } from 'aws-amplify/utils'
import { LoggerService } from '../../services/Logger/LoggerService'

export interface AuthError {
  type: AuthErrorType
  message: string
}

export enum AuthErrorType {
  NotAuthorizedException,
  CodeMismatchException,
  SignInWithRedirectFailure,
  UserUnAuthenticatedException,
  UserAlreadyAuthenticatedException,
  UsernameExistsException,
  InvalidPasswordException,
  Unknown,
}

export const parseError = (error: unknown): AuthError => {
  if (!(error instanceof AmplifyAuthError)) {
    return {
      type: AuthErrorType.Unknown,
      message: 'An error occured, please try again later.',
    }
  }

  switch (error.name) {
    case 'NotAuthorizedException':
      return {
        type: AuthErrorType.NotAuthorizedException,
        message: I18n.get(error.message),
      }
    case 'UserUnAuthenticatedException':
      return {
        type: AuthErrorType.UserUnAuthenticatedException,
        message: I18n.get(error.message),
      }
    case 'UserAlreadyAuthenticatedException': {
      return {
        type: AuthErrorType.UserAlreadyAuthenticatedException,
        message: I18n.get(error.message),
      }
    }
    case 'UsernameExistsException':
      return {
        type: AuthErrorType.UsernameExistsException,
        message: I18n.get(error.message),
      }
    case 'InvalidPasswordException':
      return {
        type: AuthErrorType.InvalidPasswordException,
        message: I18n.get(error.message),
      }
    case 'CodeMismatchException':
      return {
        type: AuthErrorType.CodeMismatchException,
        message: I18n.get(error.message),
      }
    default: {
      return {
        type: AuthErrorType.Unknown,
        // Message may contain useful information for the user, even if we didn't expect this error.
        message: I18n.get(error.message),
      }
    }
  }
}

// handle errors checks if the error is okay, if not it logs the error
export const logUnexpectedError = (logger: LoggerService, err: AuthError, expectedErrors: AuthErrorType[]) => {
  if (!expectedErrors.includes(err.type)) {
    logger.logError(err)
  }
}
