import React, { useState } from 'react'
import { ResetPasswordFormValues, ForgotPasswordFormValues } from '../components/Auth/Forms/ForgotPasswordForm'
import { ForgotPasswordPage } from '../components/Auth/Pages/ForgotPasswordPage'
import { forgotPassword, forgotPasswordSubmit } from '../application/features/auth/asyncActions'
import { useAppDispatch, useAppSelector } from '../application/store'
import { setAuthState } from '../application/features/auth'
import { selectAuthUser } from '../application/features/auth/selectors'

export const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch()
  const { email } = useAppSelector(selectAuthUser)
  const [codeSent, setCodeSent] = useState(false) // Todo use delivery status instead

  const doResetPassword = async ({ code, password }: ResetPasswordFormValues) => {
    dispatch(forgotPasswordSubmit(code, password))
  }

  const sendCode = async ({ email }: ForgotPasswordFormValues) => {
    dispatch(forgotPassword(email))
    setCodeSent(true)
  }

  return (
    <ForgotPasswordPage
      email={email ?? ''}
      goToSignIn={() => dispatch(setAuthState('signIn'))}
      submit={doResetPassword}
      sendCode={sendCode}
      submitView={codeSent}
    />
  )
}
