import * as React from 'react';
const DEFAULT_IMAGE_STYLE = {
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
    fill: '#e7eaf5',
};
export const Equality = ({ style }) => {
    const styleWithDefault = Object.assign(Object.assign({}, DEFAULT_IMAGE_STYLE), style);
    return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "prefix__Equality_Icon", width: "25", height: "25", "data-name": "Equality Icon", viewBox: "0 0 25 25" },
        React.createElement("path", { id: "prefix__BG", d: "M18.809 0H6.191A6.256 6.256 0 0 0 0 6.191v12.618A6.2 6.2 0 0 0 6.191 25h12.618A6.2 6.2 0 0 0 25 18.809V6.191A6.256 6.256 0 0 0 18.809 0z", fill: "#fff8dd" }),
        React.createElement("g", { id: "prefix__balance-2", transform: "translate(2.981 2.001)" },
            React.createElement("g", { id: "prefix__Group_11130", "data-name": "Group 11130", transform: "translate(.002 -.001)" },
                React.createElement("path", { id: "prefix__Path_9829", d: "M19.969 12.195l-3.281-7.166.8-.4-.3-.6-.776.387-.1-.229a.333.333 0 0 0-.606 0l-.094.2A4.288 4.288 0 0 0 13.8 4H11v-.667h.667v-.667H11.1l.24-.959a1.374 1.374 0 1 0-2.667 0l.24.959h-.578v.667H9V4H6.2a4.288 4.288 0 0 0-1.8.4l-.094-.2a.333.333 0 0 0-.606 0l-.1.229-.776-.388-.3.6.8.4-3.292 7.153a.326.326 0 0 0-.03.139 2.336 2.336 0 0 0 2.333 2.333h3.334A2.336 2.336 0 0 0 8 12.333a.326.326 0 0 0-.033-.138L4.676 5A3.626 3.626 0 0 1 6.2 4.666H9v12.2L7.864 18h-1.2A1.669 1.669 0 0 0 5 19.666a.333.333 0 0 0 .335.334h9.333a.333.333 0 0 0 .332-.334A1.669 1.669 0 0 0 13.335 18h-1.2L11 16.861V4.666h2.8A3.626 3.626 0 0 1 15.33 5l-3.3 7.191a.326.326 0 0 0-.03.139 2.336 2.336 0 0 0 2.333 2.333h3.333A2.336 2.336 0 0 0 20 12.333a.325.325 0 0 0-.031-.138zM5.669 14H2.335A1.669 1.669 0 0 1 .7 12.666h6.6A1.669 1.669 0 0 1 5.669 14zm1.481-2H.855l3.062-6.68a.329.329 0 0 0 .171 0zM9.444.938a.729.729 0 0 1 1.115 0 .7.7 0 0 1 .129.607l-.28 1.121H9.6l-.28-1.121a.7.7 0 0 1 .124-.607zM9.669 4v-.667h.667V4zm.667.667v12h-.667v-12zm3 14a1 1 0 0 1 .943.667H5.726a1 1 0 0 1 .943-.667h6.667zM11.2 18H8.807l.667-.667h1.057zm4.716-12.68a.228.228 0 0 0 .169-.007L19.149 12h-6.294zM17.669 14h-3.334a1.669 1.669 0 0 1-1.635-1.334h6.6A1.669 1.669 0 0 1 17.669 14z", "data-name": "Path 9829", transform: "translate(-.002 .001)", fill: "#e5b728", stroke: "#e5b728", strokeWidth: ".2px" })))));
};
