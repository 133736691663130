import React from 'react'
import styled from 'styled-components'
import { CardGrid } from '@tumelo/shared'
import { PageHeader } from '../PageHeader'
import { Payload } from '../../application/payload'
import { FavouriteCategoryCard } from './FavouriteCategoryCard/FavouriteCategoryCard'
import { TotalVotesCard } from './TotalVotesCard/TotalVotesCard'

interface VotingActivityCardsProps {
  overallUserVotes: Payload<number>
  favouriteCategory: Payload<string | undefined>
}

export const VotingActivityCards: React.FC<VotingActivityCardsProps> = ({ overallUserVotes, favouriteCategory }) => {
  if (favouriteCategory === undefined) return null

  return (
    <>
      <PageHeader title="My voting activity over the past 3 months" />
      <Container>
        <TotalVotesCard key="total-votes" numberOfVotes={overallUserVotes} />
        <FavouriteCategoryCard key="favourite-category" category={favouriteCategory} />
      </Container>
    </>
  )
}

const Container = styled(CardGrid)`
  margin-bottom: 2rem;
`
