/* eslint-disable @typescript-eslint/no-deprecated */

import React, { useState } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import { Form, Formik } from 'formik'
import { ModelPortfolio, ModelPortfolioId, SubHeading, Box, Modal, SubmitButton } from '@tumelo/shared'
import { RadioButtonGroup, RadioButtonItem, Button } from '@tumelo/designsystem'
import { RouterLink } from '../../styles/fonts'
import { CheckboxField } from '../Form/CheckboxField'
import { HeadingWithHelp } from '../HeadingWithHelp'
import { Markdown } from '../Markdown'
import { BackButton } from '../../buildingBlocks/BackButton'

interface Props {
  modelPortfolios: ModelPortfolio[]
  onSelect: (id: ModelPortfolioId) => void
  goBack: () => void
  confirmFundText: string
  helpText: string
  headerMarkdown: string
  fundBoxHeaderText: string
  confirmationModalTitle: string
  confirmationModalText: string
  confirmationSubheadingText: string
}

export const SelfSelectFund: React.FC<Props> = ({
  onSelect,
  goBack,
  modelPortfolios,
  confirmFundText,
  helpText,
  headerMarkdown,
  fundBoxHeaderText,
  confirmationModalTitle,
  confirmationModalText,
  confirmationSubheadingText,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedModelPortfolio, setSelectedModelPortfolio] = useState<ModelPortfolio | null>(null)
  const initialConfirmFormValues = { modelPortfolioConfirm: false }

  const handleSelectModelPortfolio = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const modelPortfolioId: ModelPortfolioId = e.currentTarget.fundList.value
    const selectedModelPortfolio = modelPortfolios.find((m) => m.id === modelPortfolioId)
    if (!selectedModelPortfolio) {
      throw new Error(`Could not find model portfolio with id ${modelPortfolioId}`)
    }
    setSelectedModelPortfolio(selectedModelPortfolio)
    setModalOpen(true)
  }

  const handleConfirmModelPortfolio = (modelPortfolio: ModelPortfolio | null) => {
    if (modelPortfolio) onSelect(modelPortfolio.id)
  }

  return (
    <>
      <StyledBackButton onClick={goBack} />
      <StyledMarkdown markdown={helpText} />
      <Box>
        <Header title={fundBoxHeaderText} helpText={<Markdown markdown={headerMarkdown} />} />
        <form onSubmit={handleSelectModelPortfolio}>
          <RadioButtonGroup groupName="fundList">
            {modelPortfolios.map((modelPortfolio, index) => (
              <React.Fragment key={`fundselect-${modelPortfolio.id}`}>
                <RadioButtonItem value={modelPortfolio.id} checked={index === 0}>
                  {modelPortfolio.title}
                </RadioButtonItem>
                {/* don't show divider below last item */}
                {modelPortfolios.length - 1 !== index && <Divider />}
              </React.Fragment>
            ))}
            <ButtonContainer>
              <RouterLink to="/portfolio-selection/self-select/unsupported-fund">None of the above</RouterLink>
              <Button type="submit">Select fund</Button>
            </ButtonContainer>
          </RadioButtonGroup>
        </form>
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => {
          setSelectedModelPortfolio(null)
          setModalOpen(false)
        }}
        title={confirmationModalTitle}
        body={
          <>
            <Markdown markdown={confirmFundText} />
            <Divider />
            <SubHeading as="h3">
              {confirmationSubheadingText} {selectedModelPortfolio?.title ?? 'No Fund'}
            </SubHeading>
            <Formik
              initialValues={initialConfirmFormValues}
              validationSchema={ConfirmSchema}
              onSubmit={() => handleConfirmModelPortfolio(selectedModelPortfolio)}
              validateOnMount
            >
              {({ isValid }) => (
                <Form>
                  <CheckboxField name="modelPortfolioConfirm" label={confirmationModalText} required />
                  <ButtonContainer>
                    <SubmitButton disabled={!isValid} label="Confirm" />
                  </ButtonContainer>
                </Form>
              )}
            </Formik>
          </>
        }
      />
    </>
  )
}

const ConfirmSchema = yup.object({
  modelPortfolioConfirm: yup.bool().oneOf([true]).required(),
})

const StyledMarkdown = styled(Markdown)`
  h2 {
    margin-bottom: 2rem;
  }
  margin-bottom: 2rem;
`

const StyledBackButton = styled(BackButton)`
  margin-bottom: 2rem;
`

const HeadingWithHelpFiltered: React.FC<Omit<React.ComponentProps<typeof HeadingWithHelp>, 'backgroundColor'>> = (
  props
) => <HeadingWithHelp backgroundColor="transparent" {...props} />

const Header = styled(HeadingWithHelpFiltered)`
  margin: 0rem;
`

const ButtonContainer = styled.div`
  margin-top: 2.4rem;
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
  align-items: center;
`

const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.grey.light};
  border: none;
  height: 1px;
`
