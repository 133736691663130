import React from 'react'
import { Poll, Organization, Ballot, InvestorVoteResponse } from '@tumelo/shared'
import { useRouter } from '../../../application/router/useRouter'
import { SingleQuestionWithAnalytics as SingleQuestion } from '../../SingleQuestionWithAnalytics'
import { PollVote } from '../../PollVote'
import { PageHeader } from '../../PageHeader'
import { ReadMoreVariant } from '../../SingleQuestion/Bottom/CallToAction'

interface Props {
  organization: Organization
  poll: Poll
  ballot: Ballot | undefined
  onClickAnswer: (option: InvestorVoteResponse) => void
  onClickOrganization: () => void
  onClickOpenVotes: () => void
  readMoreVariant?: ReadMoreVariant
  initialReadMoreState?: boolean
}

export const Vote: React.FC<Props> = ({
  organization,
  poll,
  ballot,
  onClickAnswer,
  onClickOrganization,
  onClickOpenVotes,
  readMoreVariant = 'arrow',
  initialReadMoreState,
}) => {
  const { navigate } = useRouter()
  return (
    <>
      <PageHeader
        title="Open votes"
        showBack
        onClickGoBack={() => navigate('/votes/open')}
        iconOnlyAltText="navigate to open votes"
      />
      <SingleQuestion
        poll={poll}
        organization={organization}
        onClickOrganization={onClickOrganization}
        readMoreVariant={readMoreVariant}
        initialReadMoreState={initialReadMoreState}
      >
        <PollVote poll={poll} ballot={ballot} onClickAnswer={onClickAnswer} onClickOpenVotes={onClickOpenVotes} />
      </SingleQuestion>
    </>
  )
}
