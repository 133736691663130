import React from 'react'
import { Poll } from '@tumelo/shared'
import { useInvestor } from '../../application/features/investor/useInvestor'
import { useAccount } from '../../application/features/account/hooks'
import {
  useVotingPolicyRecommendationByProposalId,
  useVotingPolicySelection,
} from '../../application/features/votingPolicies/hooks'
import { VotingRecommendationUI } from './VotingRecommendationUI'

interface VotingRecommendationConnectedProps {
  poll: Poll
}

export const VotingRecommendationConnected: React.FC<VotingRecommendationConnectedProps> = ({ poll }) => {
  const { investor } = useInvestor()
  const account = useAccount(investor)
  const votingPolicySelection = useVotingPolicySelection(account)

  const votingPolicyRecommendation = useVotingPolicyRecommendationByProposalId(
    votingPolicySelection,
    poll.relationships.proposal.id
  )
  return (
    <VotingRecommendationUI
      votingPolicySelection={votingPolicySelection}
      votingPolicyRecommendation={votingPolicyRecommendation}
    />
  )
}
