/* eslint-disable @typescript-eslint/no-deprecated */

import React, { useState } from 'react'
import { Body, FontWeight, Caption, VotingPolicySelection, VotingPolicy } from '@tumelo/shared'
import { Link, Stack, FileIcon, Button } from '@tumelo/designsystem'
import styled, { useTheme } from 'styled-components'
import { PayloadUnwrapperM } from '../Error/PayloadUnwrapper'
import { CustomCallToAction } from '../ProfileCard/ProfileCard'
import { SkeletonRows } from '../../buildingBlocks/Skeleton'
import { Payload } from '../../application/payload'
import { DeleteVotingPolicyModal } from './DeleteVotingPolicyModal'

interface Props {
  policySelection: Payload<VotingPolicySelection | undefined>
  habitatVotingPolicies: Payload<VotingPolicy[]>
  openVotingPoliciesModal: () => void
  deleteVotingPolicySelection: () => void
  updateVotingPolicySelection: Payload<'not-updating'>
}

export const ManageVotingPolicy: React.FC<Props> = ({
  policySelection,
  habitatVotingPolicies,
  openVotingPoliciesModal,
  deleteVotingPolicySelection,
  updateVotingPolicySelection,
}) => {
  const { colors } = useTheme()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const openModal = () => setDeleteModalOpen(true)
  const closeModal = () => setDeleteModalOpen(false)

  return (
    <>
      <PayloadUnwrapperM
        items={[policySelection, habitatVotingPolicies]}
        customSpinner={<ManageVotingPolicyPending />}
        customError={<ManageVotingPolicyError />}
      >
        {([policySelection, habitatVotingPolicies]) => {
          const selectedVotingPolicy =
            policySelection &&
            habitatVotingPolicies.find((policy) => policy.votingPolicyId === policySelection.votingPolicyId)

          const policyDocument = selectedVotingPolicy && selectedVotingPolicy.currentDocument
          return selectedVotingPolicy ? (
            <Stack alignItems="flex-start">
              <div>
                <Body mt={0} mb={10} fontWeight={FontWeight.bold}>
                  Automated voting
                </Body>
                <Caption>{`Your policy: ${selectedVotingPolicy.title}`}</Caption>
              </div>
              {policyDocument && (
                <StyledStack flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Stack gap="0.8rem" flexDirection="row">
                    <FileIcon fill={colors.secondary.medium} />
                    <Link size="small" href={policyDocument.pdfUrl} target="_blank">
                      Policy PDF
                    </Link>
                  </Stack>
                  <CustomCallToAction onClick={openVotingPoliciesModal}>Update</CustomCallToAction>
                </StyledStack>
              )}
              <StyledButton variant="tertiary" onClick={openModal}>
                Delete my policy
              </StyledButton>
            </Stack>
          ) : (
            <Stack alignItems="flex-start">
              <div>
                <Body mt={0} mb={10} fontWeight={FontWeight.bold}>
                  Automated voting
                </Body>
                <Caption>Your policy: has not been set up.</Caption>
              </div>
              <StyledStack flexDirection="row" justifyContent="flex-end" alignItems="center">
                <CustomCallToAction onClick={openVotingPoliciesModal}>Get set up</CustomCallToAction>
              </StyledStack>
            </Stack>
          )
        }}
      </PayloadUnwrapperM>
      <Border />
      <DeleteVotingPolicyModal
        open={deleteModalOpen}
        updateVotingPolicySelection={updateVotingPolicySelection}
        handleModalClose={closeModal}
        policySelection={policySelection}
        handleDelete={deleteVotingPolicySelection}
      />
    </>
  )
}

const ManageVotingPolicyPending: React.FC = () => (
  <>
    <Body mt={0} mb={10} fontWeight={FontWeight.bold}>
      Automated voting
    </Body>
    <SkeletonRows count={4} />
  </>
)

const ManageVotingPolicyError: React.FC = () => (
  <>
    <Body mt={0} mb={10} fontWeight={FontWeight.bold}>
      Automated voting
    </Body>
    <Caption>Oh no, there has been an error. Please come back later to update your voting policy.</Caption>
  </>
)

const Border = styled.div`
  border-bottom: 0.2rem solid ${({ theme }) => theme.colors.grey.light};
  margin: 2rem 0;
`

const StyledStack = styled(Stack)`
  width: 100%;
`

const StyledButton = styled(Button)`
  align-self: center;
`
