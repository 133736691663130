import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { isValueState } from '../../payload'
import { useHabitatStatistics } from '../statistics/hooks'
import { getInvestorOpenVotesCloseToExpiration, getMostVotedOnOpenPoll } from './asyncActions'
import { selectInvestorOpenVotesCloseToExpiration, selectMostVotedOnInvestorOpenVote } from './selectors'
import * as actions from '.'

export const useInvestorOpenVotesCloseToExpiration = () => {
  const dispatch = useAppDispatch()
  const openVote = useAppSelector(selectInvestorOpenVotesCloseToExpiration)

  useEffect(() => {
    if (openVote === 'not-initialised') {
      dispatch(getInvestorOpenVotesCloseToExpiration())
    }
  }, [openVote, dispatch])

  return openVote
}

export const useMostVotedOnOpenPoll = () => {
  const dispatch = useAppDispatch()
  const statistics = useHabitatStatistics()
  const openVote = useAppSelector(selectMostVotedOnInvestorOpenVote)

  useEffect(() => {
    if (openVote === 'not-initialised' && isValueState(statistics)) {
      if (statistics.mostVotedOnOpenPoll?.pollId) {
        dispatch(getMostVotedOnOpenPoll(statistics.mostVotedOnOpenPoll.pollId))
      } else {
        dispatch(actions.mostVotedOnInvestorOpenVote(undefined))
      }
    }
  }, [statistics, openVote, dispatch])

  return openVote
}
