/* eslint-disable @typescript-eslint/no-deprecated */
import * as React from 'react';
import styled from 'styled-components';
import { MoneyBagIcon } from '@tumelo/designsystem';
import { Formatting } from '../../utils/formatting';
import { TypographyCaption } from '../../buildingBlocks/Typography';
export const OtherGroupHeader = ({ totalPercentage }) => {
    return (React.createElement(BigContainer, null,
        React.createElement(Container, null,
            React.createElement(SmallContainer, null,
                React.createElement(MoneyBagIcon, { style: { height: '2.8rem', margin: '0.8rem' }, "data-testid": "icon" }),
                React.createElement("div", null,
                    React.createElement(Header, null, "Other"),
                    React.createElement(Percentage, null, Formatting.percentage(totalPercentage)))))));
};
const SmallContainer = styled.div `
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
const Container = styled.div `
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const BigContainer = styled.div `
  box-shadow: 0 3px 4px ${({ theme }) => theme.colors.boxShadow};
  padding: 1.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 0.5rem;
`;
const Header = styled.h2 `
  margin: 0 0 0 1rem;
  font-size: 1.8rem;
`;
const Percentage = styled(TypographyCaption) `
  font-weight: normal;
  margin: 0 0 0 1rem;
`;
