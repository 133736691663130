import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const FireIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 20 25", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M19.12 13.198c-.358-.688-.849-1.477-1.371-2.309a27.14 27.14 0 01-2.76-5.235c-.629-1.921-.541-3.392.352-4.5a.63.63 0 000-.827.983.983 0 00-.943-.311c-.163.033-4.118.849-6.03 3.5a5.168 5.168 0 00-1 2.92 5.083 5.083 0 00-1.811-.761 1.007 1.007 0 00-.773.183.68.68 0 00-.277.627c.314 1.982-.729 3.281-1.987 4.785a17.658 17.658 0 00-1.671 2.284c-1.314 2.22-1.1 4.63.629 7.128a10.932 10.932 0 008.991 4.319 12.151 12.151 0 002.632-.288l.2-.045a8.892 8.892 0 005.722-4.163 7.665 7.665 0 00.094-7.305zM7.3 22.491a2.915 2.915 0 01-.956-2.542c.132-.944 1.138-2.037 2.106-3.092a9.6 9.6 0 002.414-3.5c.943 1 1.8 2.776.629 5.612a.628.628 0 00.151.7.979.979 0 00.786.266 3.156 3.156 0 001.207-.389 3.488 3.488 0 01-.627 2.74 3.376 3.376 0 01-2.73 1.25 4.913 4.913 0 01-.855-.05 3.956 3.956 0 01-2.144-.994zm10.123-2.6a6.784 6.784 0 01-2.471 2.5 5.049 5.049 0 00-.207-4.8.923.923 0 00-.905-.411h-.063a5.637 5.637 0 00-1.088-4.213 6.532 6.532 0 00-2.446-1.965 1.03 1.03 0 00-1.012.117.65.65 0 00-.207.822c.629 1.2-.629 2.576-1.974 4.03-1.121 1.208-2.278 2.465-2.46 3.805a4 4 0 00.226 1.982 7.455 7.455 0 01-1.792-1.81c-1.446-2.076-1.635-3.964-.578-5.757a16.351 16.351 0 011.547-2.1c1.044-1.31 2.169-2.637 2.3-4.463a10.457 10.457 0 011.56 1.24 1.014 1.014 0 001.081.178.708.708 0 00.5-.838 4.209 4.209 0 01.478-3.886 7.239 7.239 0 013.1-2.22 6.692 6.692 0 00.3 3.991 28.38 28.38 0 002.898 5.512c.509.81.981 1.577 1.32 2.22a6.423 6.423 0 01-.075 6.106z" })));
};
