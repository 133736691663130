import { OrganizationBreakdown, TumeloAccount } from '@tumelo/shared'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { isValueState, Payload } from '../../payload'
import { fetchOrganizationBreakdown } from '../../asyncActions'
import { selectBreakdown } from './selectors'

export const useBreakdown = (account: Payload<TumeloAccount | 'not-configured'>): Payload<OrganizationBreakdown> => {
  const dispatch = useAppDispatch()
  const breakdown: Payload<OrganizationBreakdown> = useAppSelector(selectBreakdown)

  useEffect(() => {
    if (breakdown === 'not-initialised' && isValueState(account)) {
      dispatch(fetchOrganizationBreakdown())
    }
  }, [account, breakdown, dispatch])

  return breakdown
}
