import * as React from 'react';
export const SvgMiscellaneoussmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#F3F3F4" }),
        React.createElement("path", { d: "M20.53 7.46999C20.4816 7.34819 20.4064 7.23883 20.31 7.14999L15.46 2.28999C15.37 2.19999 15.26 2.11999 15.14 2.06999C15.02 2.01999 14.89 1.98999 14.75 1.98999H8.50999C7.95999 1.98999 7.50999 2.43999 7.50999 2.98999V5.98999H4.39999C3.84999 5.98999 3.39999 6.43999 3.39999 6.98999V20.99C3.39999 21.54 3.84999 21.99 4.39999 21.99H15.49C16.04 21.99 16.49 21.54 16.49 20.99V17.99H19.6C20.15 17.99 20.6 17.54 20.6 16.99V7.84999C20.6 7.71999 20.57 7.58999 20.52 7.46999H20.53ZM15.75 5.40999L17.19 6.84999H15.75V5.40999ZM14.5 20H5.39999V7.99999H7.50999V17C7.50999 17.55 7.95999 18 8.50999 18H14.5V20ZM18.61 16H9.50999V3.99999H13.75V7.84999C13.75 8.39999 14.2 8.84999 14.75 8.84999H18.61V16Z", fill: "#8B8B8F" })));
};
