import * as React from 'react'
import { SignInFormValues } from '../components/Auth/Forms/SignInForm'
import { SignInPage } from '../components/Auth/Pages/SignInPage'
import { goToForgotPassword, goToSignUp, signIn } from '../application/features/auth/asyncActions'
import { useAppDispatch } from '../application/store'

export const SignIn: React.FC = () => {
  const dispatch = useAppDispatch()

  const onClickResetPassword = (email: string) => {
    dispatch(goToForgotPassword({ email }))
  }

  const onClickSignUp = (email: string, password: string) => {
    dispatch(goToSignUp({ email, password }))
  }

  const onSubmit = async (values: SignInFormValues) => {
    const { email, password } = values
    dispatch(signIn(email, password))
  }

  return <SignInPage clickResetPassword={onClickResetPassword} clickSignUp={onClickSignUp} submit={onSubmit} />
}
