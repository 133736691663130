/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.dashboardbff.v1";

export interface Composition {
  id: string;
  createTime: Date | undefined;
  validTime: Date | undefined;
  components: CompositionComponents | undefined;
}

export interface CompositionComponents {
  instruments: CompositionComponentsInstrument[];
  cash: Cash[];
  other: number;
}

export interface CompositionComponentsInstrument {
  instrumentReference: InstrumentReference | undefined;
  weight: number;
}

export interface InstrumentReference {
  idType: string;
  idValue: string;
}

export interface Cash {
  currency: string;
  weight: number;
}

function createBaseComposition(): Composition {
  return { id: "", createTime: undefined, validTime: undefined, components: undefined };
}

export const Composition = {
  encode(message: Composition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(18).fork()).ldelim();
    }
    if (message.validTime !== undefined) {
      Timestamp.encode(toTimestamp(message.validTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.components !== undefined) {
      CompositionComponents.encode(message.components, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Composition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseComposition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.validTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.components = CompositionComponents.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Composition {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      validTime: isSet(object.validTime) ? fromJsonTimestamp(object.validTime) : undefined,
      components: isSet(object.components) ? CompositionComponents.fromJSON(object.components) : undefined,
    };
  },

  toJSON(message: Composition): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.validTime !== undefined) {
      obj.validTime = message.validTime.toISOString();
    }
    if (message.components !== undefined) {
      obj.components = CompositionComponents.toJSON(message.components);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Composition>, I>>(base?: I): Composition {
    return Composition.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Composition>, I>>(object: I): Composition {
    const message = createBaseComposition();
    message.id = object.id ?? "";
    message.createTime = object.createTime ?? undefined;
    message.validTime = object.validTime ?? undefined;
    message.components = (object.components !== undefined && object.components !== null)
      ? CompositionComponents.fromPartial(object.components)
      : undefined;
    return message;
  },
};

function createBaseCompositionComponents(): CompositionComponents {
  return { instruments: [], cash: [], other: 0 };
}

export const CompositionComponents = {
  encode(message: CompositionComponents, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.instruments) {
      CompositionComponentsInstrument.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.cash) {
      Cash.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.other !== 0) {
      writer.uint32(25).double(message.other);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompositionComponents {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompositionComponents();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.instruments.push(CompositionComponentsInstrument.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cash.push(Cash.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 25) {
            break;
          }

          message.other = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompositionComponents {
    return {
      instruments: globalThis.Array.isArray(object?.instruments)
        ? object.instruments.map((e: any) => CompositionComponentsInstrument.fromJSON(e))
        : [],
      cash: globalThis.Array.isArray(object?.cash) ? object.cash.map((e: any) => Cash.fromJSON(e)) : [],
      other: isSet(object.other) ? Number(object.other) : 0,
    };
  },

  toJSON(message: CompositionComponents): unknown {
    const obj: any = {};
    if (message.instruments?.length) {
      obj.instruments = message.instruments.map((e) => CompositionComponentsInstrument.toJSON(e));
    }
    if (message.cash?.length) {
      obj.cash = message.cash.map((e) => Cash.toJSON(e));
    }
    if (message.other !== 0) {
      obj.other = message.other;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompositionComponents>, I>>(base?: I): CompositionComponents {
    return CompositionComponents.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompositionComponents>, I>>(object: I): CompositionComponents {
    const message = createBaseCompositionComponents();
    message.instruments = object.instruments?.map((e) => CompositionComponentsInstrument.fromPartial(e)) || [];
    message.cash = object.cash?.map((e) => Cash.fromPartial(e)) || [];
    message.other = object.other ?? 0;
    return message;
  },
};

function createBaseCompositionComponentsInstrument(): CompositionComponentsInstrument {
  return { instrumentReference: undefined, weight: 0 };
}

export const CompositionComponentsInstrument = {
  encode(message: CompositionComponentsInstrument, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.instrumentReference !== undefined) {
      InstrumentReference.encode(message.instrumentReference, writer.uint32(10).fork()).ldelim();
    }
    if (message.weight !== 0) {
      writer.uint32(17).double(message.weight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CompositionComponentsInstrument {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompositionComponentsInstrument();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.instrumentReference = InstrumentReference.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.weight = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CompositionComponentsInstrument {
    return {
      instrumentReference: isSet(object.instrumentReference)
        ? InstrumentReference.fromJSON(object.instrumentReference)
        : undefined,
      weight: isSet(object.weight) ? Number(object.weight) : 0,
    };
  },

  toJSON(message: CompositionComponentsInstrument): unknown {
    const obj: any = {};
    if (message.instrumentReference !== undefined) {
      obj.instrumentReference = InstrumentReference.toJSON(message.instrumentReference);
    }
    if (message.weight !== 0) {
      obj.weight = message.weight;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CompositionComponentsInstrument>, I>>(base?: I): CompositionComponentsInstrument {
    return CompositionComponentsInstrument.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CompositionComponentsInstrument>, I>>(
    object: I,
  ): CompositionComponentsInstrument {
    const message = createBaseCompositionComponentsInstrument();
    message.instrumentReference = (object.instrumentReference !== undefined && object.instrumentReference !== null)
      ? InstrumentReference.fromPartial(object.instrumentReference)
      : undefined;
    message.weight = object.weight ?? 0;
    return message;
  },
};

function createBaseInstrumentReference(): InstrumentReference {
  return { idType: "", idValue: "" };
}

export const InstrumentReference = {
  encode(message: InstrumentReference, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.idType !== "") {
      writer.uint32(10).string(message.idType);
    }
    if (message.idValue !== "") {
      writer.uint32(18).string(message.idValue);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InstrumentReference {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseInstrumentReference();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.idType = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.idValue = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): InstrumentReference {
    return {
      idType: isSet(object.idType) ? String(object.idType) : "",
      idValue: isSet(object.idValue) ? String(object.idValue) : "",
    };
  },

  toJSON(message: InstrumentReference): unknown {
    const obj: any = {};
    if (message.idType !== "") {
      obj.idType = message.idType;
    }
    if (message.idValue !== "") {
      obj.idValue = message.idValue;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<InstrumentReference>, I>>(base?: I): InstrumentReference {
    return InstrumentReference.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<InstrumentReference>, I>>(object: I): InstrumentReference {
    const message = createBaseInstrumentReference();
    message.idType = object.idType ?? "";
    message.idValue = object.idValue ?? "";
    return message;
  },
};

function createBaseCash(): Cash {
  return { currency: "", weight: 0 };
}

export const Cash = {
  encode(message: Cash, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currency !== "") {
      writer.uint32(10).string(message.currency);
    }
    if (message.weight !== 0) {
      writer.uint32(17).double(message.weight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Cash {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCash();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.weight = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Cash {
    return {
      currency: isSet(object.currency) ? String(object.currency) : "",
      weight: isSet(object.weight) ? Number(object.weight) : 0,
    };
  },

  toJSON(message: Cash): unknown {
    const obj: any = {};
    if (message.currency !== "") {
      obj.currency = message.currency;
    }
    if (message.weight !== 0) {
      obj.weight = message.weight;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Cash>, I>>(base?: I): Cash {
    return Cash.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Cash>, I>>(object: I): Cash {
    const message = createBaseCash();
    message.currency = object.currency ?? "";
    message.weight = object.weight ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
