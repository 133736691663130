import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';
import { Stack } from '../Stack';
import { screen } from '../_utils';
export const ModalFooter = ({ stickyButtons }) => (React.createElement(StyledStack, { flexDirection: { xs: 'column-reverse', sm: 'row', md: 'row', lg: 'row' }, alignContent: "flex-end", justifyContent: "center", mt: "1.6rem" }, stickyButtons.map((buttonProps, i) => (React.createElement(Button, Object.assign({ key: `button${i}` }, buttonProps))))));
const StyledStack = styled(Stack) `
  width: 100%;
  button {
    width: 100%;
  }
  @media ${screen.smallUp} {
    width: auto;
    button {
      width: auto;
    }
  }
`;
