import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgCircleslash = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.6175 19.032C16.0779 20.2636 14.1249 21 12 21C7.02944 21 3 16.9706 3 12C3 9.87506 3.73642 7.92212 4.96797 6.38248L17.6175 19.032ZM19.0318 17.6178L6.38215 4.96823C7.92185 3.73652 9.87491 3 12 3C16.9706 3 21 7.02944 21 12C21 14.1251 20.2635 16.0782 19.0318 17.6178ZM23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z" })));
};
