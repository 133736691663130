import * as z from 'myzod'
import { PortfolioSelectionFlowSchema } from './SoftConfigPortfolioSelection'
import { ReturningCardsSchema } from './SoftConfigSettingsReturningCards'

const AppWarningsSchema = z
  .object(
    {
      createMyAccount: z.string(),
    },
    { allowUnknown: true }
  )
  .default({ createMyAccount: '' })

export type AppWarnings = z.Infer<typeof AppWarningsSchema>

export const SoftConfigSettingsSchema = z
  .object(
    {
      title: z.string().max(50),
      platformLink: z.string().optional(),
      portfolioSelectionFlow: PortfolioSelectionFlowSchema,
      returningCards: ReturningCardsSchema,
      warningMessages: AppWarningsSchema,
      ssoProviderName: z.string().max(10).default('OIDC'),
      collectTelephoneNumberOnSignup: z.boolean().default(false),
    },
    { allowUnknown: true }
  )
  .default({
    title: 'Platform',
    portfolioSelectionFlow: PortfolioSelectionFlowSchema.parse(),
    returningCards: ReturningCardsSchema.parse(),
    warningMessages: AppWarningsSchema.parse(),
    collectTelephoneNumberOnSignup: false,
    ssoProviderName: 'OIDC',
  })

export type SoftConfigSettings = z.Infer<typeof SoftConfigSettingsSchema>
