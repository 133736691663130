import { createSearchParams, Navigate } from 'react-router-dom'
import React from 'react'
import { useRouter } from '../../application/router/useRouter'

export const useNavigateWithQuery = () => {
  const { location, navigate } = useRouter()
  const { search } = location
  const searchParams = createSearchParams(search)
  if (searchParams.has('code')) {
    searchParams.delete('code')
  }
  if (searchParams.has('state')) {
    searchParams.delete('state')
  }
  return (path: string) => {
    navigate(`${path}?${searchParams.toString()}`)
  }
}

interface Props {
  to: string
}

export const NavigateWithQueryParams: React.FC<Props> = ({ to }) => {
  const { location } = useRouter()
  const { search } = location
  const searchParams = createSearchParams(search)
  if (searchParams.has('code')) {
    searchParams.delete('code')
  }
  if (searchParams.has('state')) {
    searchParams.delete('state')
  }
  return <Navigate to={`${to}?${searchParams.toString()}`} />
}
