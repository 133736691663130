/* eslint-disable @typescript-eslint/no-deprecated */

import React, { FC } from 'react'
import styled from 'styled-components'
import { Breakpoint, BreakpointAsMaxValue, MaterialIcon, MenuItemIconSwitch } from '@tumelo/shared'
import { Link as NavLink } from '../../../../application/router/Link'
import { ExternalLinkWithTracking } from '../../../../buildingBlocks/ExternalLinkWithTracking'

interface Props {
  label: string
  to: string
  isActive: boolean
  icon: MaterialIcon
  isExternal?: boolean
}

export const Item: FC<Props> = ({ label, to, isActive, icon, isExternal = false }) => {
  return (
    <Container>
      <Link to={to} isExternal={isExternal}>
        <Content isActive={isActive}>
          <MenuItemIconSwitch icon={icon} style={iconStyles} />
          <Selected>{label}</Selected>
        </Content>
      </Link>
    </Container>
  )
}

interface LinkProps {
  to: string
  isExternal?: boolean
}

const Link: FC<React.PropsWithChildren<LinkProps>> = ({ to, isExternal, children }) => {
  if (isExternal) {
    return <StyledAnchor to={to}>{children}</StyledAnchor>
  }

  return <StyledLink to={to}>{children}</StyledLink>
}

const iconStyles: React.CSSProperties = {
  fontSize: '2rem',
}

interface ContentProps {
  isActive: boolean
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: 2rem;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  @media (max-width: ${BreakpointAsMaxValue.tablet}) {
    height: 4rem;
    margin-left: 1.5rem;
  }
`

const StyledLink = styled(NavLink)`
  font-size: 1.6rem;
  @media (max-width: ${Breakpoint.desktop}) {
    font-size: 1.4rem;
  }
`
const StyledAnchor = styled(ExternalLinkWithTracking)`
  font-size: 1.6rem;
  @media (max-width: ${Breakpoint.desktop}) {
    font-size: 1.4rem;
  }
`

const Selected = styled.span`
  color: inherit;
  margin-left: 1rem;
  font-weight: inherit;
  @media (max-width: ${Breakpoint.widescreen}) {
    margin-left: 0.5rem;
    font-size: 1.4rem;
  }
`

const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.info.light : theme.colors.info.medium)};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  path {
    fill: ${({ isActive, theme }) => (isActive ? theme.colors.info.light : theme.colors.info.medium)};
  }
`
