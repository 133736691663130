import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { useBreakpointValues, ButtonStyling, ArrowRightIcon, ButtonFocusStyling } from '..';
// use named function to make it work in testing library tests when there are nested components and styled components
// https://github.com/styled-components/styled-components/issues/1449
export function ButtonNextLink({ children, href, target, variant = 'primary', size = 'medium', fullWidth = false, isDisabled, iconBefore, iconAfter, className, }) {
    const iconAfterDefault = !(iconAfter || iconBefore) ? React.createElement(ArrowRightIcon, null) : iconAfter;
    return (React.createElement(Link, { href: href, target: target },
        React.createElement(StyledSpan, { variant: variant, size: size, fullWidth: useBreakpointValues(fullWidth), disabled: isDisabled, iconBefore: iconBefore, iconAfter: iconAfterDefault, className: className },
            iconBefore,
            " ",
            children,
            " ",
            iconAfterDefault)));
}
const StyledSpan = styled.span `
  &:focus {
    ${ButtonFocusStyling}
  }
  ${ButtonStyling};
`;
