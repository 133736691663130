import { Ballot, Organization, Poll, PollId } from '@tumelo/shared'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { isDetailedErrorState, isValueState, Payload } from '../../payload'
import { useAccount } from '../account/hooks'
import { useInvestor } from '../investor/useInvestor'
import {
  fetchBallotByPollId,
  fetchFundManagerVotesByBallot,
  fetchFundManagerVotesByProposalId,
  fetchPollByPollId,
} from './asyncActions'
import { selectBallots, selectFundManagerVotesWithFundManagers, selectPollAndOrganizations } from './selectors'
import { FundManagerVoteWithFundManager } from './types'

export const useBallot: (poll: PollId) => {
  ballot: Payload<Ballot>
  ballotOrUndefinedIfNotFound: Payload<Ballot | undefined>
} = (pollId: PollId) => {
  const dispatch = useAppDispatch()
  const ballots = useAppSelector(selectBallots)
  const ballot = ballots[pollId] || 'not-initialised'

  const ballotOrUndefinedIfNotFound =
    isDetailedErrorState(ballot) && ballot.errorType === 'not-found' ? undefined : ballot
  useEffect(() => {
    if (ballot === 'not-initialised' || !ballot) {
      dispatch(fetchBallotByPollId(pollId))
    }
  }, [dispatch, pollId, ballot])
  return { ballot, ballotOrUndefinedIfNotFound }
}

export const usePoll: (poll: PollId) => Payload<{ poll: Poll; organization: Organization }> = (pollId: PollId) => {
  const dispatch = useAppDispatch()
  const pollAndOrganizations = useAppSelector(selectPollAndOrganizations)
  const pollAndOrganization = pollAndOrganizations[pollId] || 'not-initialised'

  useEffect(() => {
    if (pollAndOrganization === 'not-initialised' || !pollAndOrganization) {
      dispatch(fetchPollByPollId(pollId))
    }
  }, [dispatch, pollAndOrganization, pollId])
  return pollAndOrganization
}

export const useFundManagerVotes: (poll: PollId) => {
  fundManagerVotesWithFundManager: Payload<FundManagerVoteWithFundManager[]>
} = (pollId: PollId) => {
  const dispatch = useAppDispatch()
  const { ballot } = useBallot(pollId)
  const pollAndOrganization = usePoll(pollId)
  const fundManagerVotesWithFundManagers = useAppSelector(selectFundManagerVotesWithFundManagers)
  const fundManagerVotesWithFundManager = fundManagerVotesWithFundManagers[pollId as string] || 'not-initialised'
  const { investor } = useInvestor()
  const account = useAccount(investor)
  const ballotId = isValueState(ballot) && ballot?.id
  const accountId = account !== 'not-configured' && isValueState(account) && account.id
  const proposalId = isValueState(pollAndOrganization) && pollAndOrganization?.poll.relationships.proposal.id
  useEffect(() => {
    if (fundManagerVotesWithFundManager === 'not-initialised' || !fundManagerVotesWithFundManager) {
      if (ballotId) {
        dispatch(fetchFundManagerVotesByBallot(ballot))
      } else if (accountId && proposalId) {
        dispatch(fetchFundManagerVotesByProposalId(accountId, [proposalId], pollId))
      }
    }
  }, [dispatch, ballotId, accountId, proposalId, fundManagerVotesWithFundManager, pollId, ballot])
  return { fundManagerVotesWithFundManager }
}
