var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import styled from 'styled-components';
import { Formatting } from '../../utils/formatting';
import { ListItem } from '../ListItem';
export const ListItemWithPercentage = (_a) => {
    var { percentageWeight, text, truncatedTextLength } = _a, rest = __rest(_a, ["percentageWeight", "text", "truncatedTextLength"]);
    const truncatedText = truncatedTextLength && text.length >= truncatedTextLength
        ? `${text.substring(0, truncatedTextLength - 3)}...`
        : text;
    return (React.createElement(ListItem, Object.assign({}, rest, { text: truncatedText, adornment: React.createElement(Percentage, { style: { justifySelf: 'flex-end' } }, Formatting.percentage(percentageWeight)) })));
};
const Percentage = styled.div `
  font-weight: bold;
  font-size: 1.7rem;
  margin-right: 1.5rem;
`;
