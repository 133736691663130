import { VotingPolicySelection } from '@tumelo/shared'
import { Payload } from '../../payload'
import { RootState } from '../../rootReducer'

export const selectHabitatVotingPolicies = (state: RootState) => state.votingPolicies.habitatVotingPolicies

export const selectVotingPolicySelection = (state: RootState): Payload<VotingPolicySelection | undefined> =>
  state.votingPolicies.votingPolicySelection

export const selectVotingRecommendationForProposal = (state: RootState) =>
  state.votingPolicies.votingPolicyRecommendation

export const selectUpdateVotingPolicySelection = (state: RootState) => state.votingPolicies.updateVotingPolicySelection
