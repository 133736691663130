import { APIServiceBaseClass } from '../../../utils/api'
import { ApiError } from '../../../utils/api/ApiError'
import { notFoundExUnwrapper } from '../../../utils/helpers'
import { InvestorExtendedProfile } from '../../types/InvestorExtendedProfile/InvestorExtendedProfile'
import {
  InvestorExtendedProfileSchema,
  InvestorExtendedProfileService,
  mapSchemaToType,
  mapTypeToSchema,
} from './InvestorExtendedProfileService'

export class InvestorExtendedProfileServiceAPI extends APIServiceBaseClass implements InvestorExtendedProfileService {
  async getProfile() {
    try {
      const api = await this.getInvestorsApi()
      const blob = await notFoundExUnwrapper(() =>
        api.getInvestorJsonBlob({ habitatId: this.habitatId, investorId: this.investorId })
      )
      return mapSchemaToType(InvestorExtendedProfileSchema.parse(blob))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return { tracking: {} }
    }
  }

  async saveProfile(profile: InvestorExtendedProfile): Promise<void> {
    const api = await this.getInvestorsApi()
    await api.putInvestorJsonBlob({
      habitatId: this.habitatId,
      investorId: this.investorId,
      requestBody: mapTypeToSchema(profile),
    })
  }

  async deleteProfile(): Promise<void> {
    const api = await this.getInvestorsApi()
    try {
      await api.deleteInvestor({ habitatId: this.habitatId, investorId: this.investorId })
    } catch (err) {
      if (err instanceof ApiError) {
        const { status } = err
        if (status === 404) return
      }
      throw err
    }
  }
}
