import { TagIcon } from '@tumelo/shared'
import React from 'react'
import styled from 'styled-components'

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 4.5rem;
  align-items: center;
`

interface RenderTagsProps {
  tagIds: string[]
}

export const Tags: React.FC<RenderTagsProps> = ({ tagIds }) => {
  const firstTwoTags = tagIds.slice(0, 2)

  return (
    <TagsContainer data-testid="tags">
      {firstTwoTags.map((tag) => (
        <TagIcon key={tag} tagId={tag} />
      ))}
    </TagsContainer>
  )
}
