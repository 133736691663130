import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgFacebook = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M17.75 7.8C17.6563 7.69333 17.5409 7.60784 17.4116 7.54922C17.2823 7.49059 17.142 7.46018 17 7.46H14.6V6.46H16.9C17.1652 6.46 17.4196 6.35464 17.6071 6.16711C17.7946 5.97957 17.9 5.72522 17.9 5.46V2C17.9 1.73478 17.7946 1.48043 17.6071 1.29289C17.4196 1.10536 17.1652 1 16.9 1H13.6C9.6 1 8.14 3.79 8.14 6.17V7.46H7C6.73478 7.46 6.48043 7.56536 6.29289 7.75289C6.10536 7.94043 6 8.19478 6 8.46V12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H8.14V22C8.14 22.2652 8.24536 22.5196 8.43289 22.7071C8.62043 22.8946 8.87478 23 9.14 23H13.58C13.8452 23 14.0996 22.8946 14.2871 22.7071C14.4746 22.5196 14.58 22.2652 14.58 22V13H16.58C16.8257 13.0018 17.0634 12.913 17.2479 12.7507C17.4323 12.5884 17.5505 12.3639 17.58 12.12L18 8.58C18.0157 8.43971 18.0016 8.29767 17.9585 8.16323C17.9154 8.02879 17.8444 7.905 17.75 7.8ZM15.69 11H13.58C13.3148 11 13.0604 11.1054 12.8729 11.2929C12.6854 11.4804 12.58 11.7348 12.58 12V21H10.14V12C10.14 11.7348 10.0346 11.4804 9.84711 11.2929C9.65957 11.1054 9.40522 11 9.14 11H8V9.46H9.14C9.40522 9.46 9.65957 9.35464 9.84711 9.16711C10.0346 8.97957 10.14 8.72522 10.14 8.46V6.17C10.14 5.17 10.48 3 13.6 3H15.9V4.43H14.52C13.9898 4.42427 13.4791 4.62929 13.1 5C12.9275 5.18002 12.794 5.39375 12.708 5.62778C12.6219 5.86181 12.5852 6.1111 12.6 6.36V8.46C12.6 8.72522 12.7054 8.97957 12.8929 9.16711C13.0804 9.35464 13.3348 9.46 13.6 9.46H15.87L15.69 11Z" })));
};
