import { useRouter } from '../../application/router/useRouter'
import { getOrigin, setHref } from './windowHelpers'

export const useNavigateWithAbsoluteURLs = () => {
  const { navigate } = useRouter()

  return (link: string) => {
    if (isPlatformUrl(link)) {
      navigate(link.replace(getOrigin(), ''))
    } else if (isAbsoluteUrl(link)) {
      setHref(link)
    } else {
      navigate(link)
    }
  }
}

const isAbsoluteUrl = (url: string) => url.indexOf('http://') === 0 || url.indexOf('https://') === 0

const isPlatformUrl = (url: string) => url.includes(getOrigin())
