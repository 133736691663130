import { createSelector } from '@reduxjs/toolkit'
import { Selector } from '../../../utils/redux'
import { RootState } from '../../rootReducer'
import { FundManagerVotesWithFundManagerMap, PollAndOrganizationMap, BallotMap } from '.'

const selectBallotSingleSlice = (state: RootState) => state.singleBallot

export const selectBallots: Selector<BallotMap> = createSelector(selectBallotSingleSlice, (s) => s.ballots)

export const selectPollAndOrganizations: Selector<PollAndOrganizationMap> = createSelector(
  selectBallotSingleSlice,
  (s) => s.pollAndOrganizations
)

export const selectFundManagerVotesWithFundManagers: Selector<FundManagerVotesWithFundManagerMap> = createSelector(
  selectBallotSingleSlice,
  (s) => s.fundManagerVotesWithFundManagers
)
