import React from 'react';
import { FilterMenu } from '../components/FilterMenu';
import { FilterMenuItem } from '../components/FilterMenuItem';
import { IndustryIcon } from '../../../IndustryIcon/IndustryIcon';
import { IndustryCategory } from '../../../../domain/NAICS';
import { industryCategoryToIndustryCategoryTitle } from '../../../../domain/NAICS/industryCategory';
export const IndustriesFilterMenu = ({ callback, industries, organizationIndustriesToggle, handleOrganizationIndustriesToggle, }) => {
    const industriesList = [
        IndustryCategory.AGRICULTURE_AND_FOOD,
        IndustryCategory.ENERGY_AND_UTILITIES,
        IndustryCategory.FINANCE,
        IndustryCategory.HEALTHCARE,
        IndustryCategory.INDUSTRIAL,
        IndustryCategory.LIFESTYLE,
        IndustryCategory.REAL_ESTATE,
        IndustryCategory.RETAIL,
        IndustryCategory.TECH,
        IndustryCategory.TRANSPORT_AND_VEHICLES,
        IndustryCategory.SPECIALIST_SERVICES,
        IndustryCategory.UNKNOWN,
    ];
    const entry = (code) => {
        return (React.createElement(FilterMenuItem, { active: industries[code], toggleActive: () => callback(code), icon: React.createElement(IndustryIcon, { industryCategory: code, imageStyle: ICON_STYLE }), key: code }, industryCategoryToIndustryCategoryTitle(code)));
    };
    return (React.createElement(FilterMenu, { active: organizationIndustriesToggle, toggleActive: handleOrganizationIndustriesToggle, title: "Company industry" }, industriesList.map(entry)));
};
const ICON_STYLE = {
    marginLeft: '1rem',
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
};
