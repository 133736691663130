import { ValueObject } from 'immutable'

export const makeMemo = <T, U>(keyFn: (t: T) => string | number, f: (x: T) => U) => {
  let oldKey: string | number | 'not-initialized' = 'not-initialized'
  let oldValue: U | 'not-initialized' = 'not-initialized'

  return (x: T) => {
    if (oldKey !== 'not-initialized' && oldValue !== 'not-initialized' && keyFn(x) === oldKey) return oldValue

    const newValue = f(x)
    oldKey = keyFn(x)
    oldValue = newValue
    return newValue
  }
}
export const makeHashMemoOpt = <T extends ValueObject | undefined, U>(f: (x: T) => U) =>
  makeMemo<T, U>((x: T) => (x != null ? x.hashCode() : 'null'), f)
