import React from 'react'
import {
  ModalImage,
  H1,
  Body1,
  Span,
  Stack,
  VoteIcon,
  FileIcon,
  ArrowRightIcon,
  ModalFooter,
  ModalBody,
} from '@tumelo/designsystem'
import styled from 'styled-components'

interface Props {
  goForward: () => void
}

export const PoliciesIntroductionThree: React.FC<Props> = ({ goForward }) => {
  return (
    <>
      <ModalBody fullScreen>
        <ModalImage src="/images/voting_policy_step_three.png" />
        <div>
          <H1>Good to know</H1>
          <Body1 semibold>Votes will be placed automatically according to your chosen policy.</Body1>
          <Body1 mb="2.4rem">Once it&apos;s set up: </Body1>
          <StyledStack gap="0.8rem" mb="2.4rem" flexDirection="row" alignItems="center">
            <VoteIcon />

            <Body1 mb="0">
              You can still <Span semibold>vote manually.</Span>
            </Body1>
          </StyledStack>
          <StyledStack gap="0.8rem" mb="2.4rem" flexDirection="row" alignItems="center">
            <FileIcon />
            <Body1 mb="0">
              You can <Span semibold>change or delete your policy</Span> anytime on your Profile page.
            </Body1>
          </StyledStack>
        </div>
      </ModalBody>
      <ModalFooter
        stickyButtons={[
          {
            label: 'Choose my policy',
            variant: 'primary',
            iconAfter: <ArrowRightIcon />,
            onClick: goForward,
          },
        ]}
      />
    </>
  )
}

const StyledStack = styled(Stack)`
  svg {
    flex-shrink: 0;
  }
`
