import React from 'react'
import styled from 'styled-components'
import { Tag, TagIcon } from '@tumelo/shared'

interface Props {
  tags: Tag[]
}

const Tags: React.FC<Props> = ({ tags }) => {
  const maximumTwoTags = tags.slice(0, 2)

  return (
    <Container>
      {maximumTwoTags.map((tag) => (
        <TagIcon key={tag.id} tagId={tag.id} style={{ width: '4rem', height: '4rem', padding: '0.4rem' }} />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export default Tags
