import React from 'react'
import { FireIcon } from '@tumelo/shared'
import { HotTopicCard } from '../HotTopicCard'
import { BallotWithPollAndOrg } from '../../application/types/PollWithOrganization/PollWithOrganization'
import { ListContainer } from './ListContainer'

interface Props {
  pollAndHotTopic: BallotWithPollAndOrg
  title: string
}

export const HotTopicContainer: React.FC<Props> = ({ pollAndHotTopic, title }) => {
  return pollAndHotTopic.poll.teaserText && pollAndHotTopic.poll.teaserImage ? (
    <ListContainer title={title} icon={<FireIcon height={25} width={25} />} showDivider={false}>
      <HotTopicCard
        poll={pollAndHotTopic.poll}
        teaser={pollAndHotTopic.poll.teaserText}
        image={pollAndHotTopic.poll.teaserImage?.url}
        altText={pollAndHotTopic.poll.teaserImage?.altText}
      />
    </ListContainer>
  ) : null
}
