import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgChat = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: 16.5, height: 15.186, viewBox: "0 0 16.5 15.186" }, props),
        React.createElement("defs", null,
            React.createElement("style", null, '.prefix__cls-1{stroke-width:.5px}')),
        React.createElement("g", { id: "prefix__chat", transform: "translate(.25 -2.215)" },
            React.createElement("path", { id: "prefix__Path_10215", d: "M9.267 24h3.467a.267.267 0 1 0 0-.533H9.267a.267.267 0 1 0 0 .533z", className: "prefix__cls-1", "data-name": "Path 10215", transform: "translate(-6.6 -15.4)" }),
            React.createElement("path", { id: "prefix__Path_10216", d: "M16.2 29.465H9.267a.267.267 0 1 0 0 .533H16.2a.267.267 0 1 0 0-.533z", className: "prefix__cls-1", "data-name": "Path 10216", transform: "translate(-6.6 -19.8)" }),
            React.createElement("path", { id: "prefix__Path_10217", d: "M16.2 35.465H9.267a.267.267 0 1 0 0 .533H16.2a.267.267 0 0 0 0-.533z", className: "prefix__cls-1", "data-name": "Path 10217", transform: "translate(-6.6 -24.2)" }),
            React.createElement("path", { id: "prefix__Path_10218", d: "M14.419 2.484l-9.1-.019a1.583 1.583 0 0 0-1.586 1.581v1.367H1.581A1.583 1.583 0 0 0 0 7v5.638a1.583 1.583 0 0 0 1.581 1.581H3.2v2.667a.267.267 0 0 0 .464.179l2.588-2.847 4.434-.018a1.583 1.583 0 0 0 1.581-1.581v-.03l1.4 1.543a.267.267 0 0 0 .464-.179v-2.669h.285A1.583 1.583 0 0 0 16 9.7V4.065a1.583 1.583 0 0 0-1.581-1.581zm-2.686 10.133a1.049 1.049 0 0 1-1.049 1.048l-4.552.019a.267.267 0 0 0-.2.087l-2.2 2.423V13.95a.267.267 0 0 0-.267-.267H1.581a1.049 1.049 0 0 1-1.048-1.047V7a1.049 1.049 0 0 1 1.049-1.05L4 5.945l6.685-.014a1.049 1.049 0 0 1 1.048 1.048v5.638zM15.467 9.7a1.049 1.049 0 0 1-1.048 1.048h-.552a.267.267 0 0 0-.267.267v2.244l-1.333-1.467V6.98a1.583 1.583 0 0 0-1.582-1.58l-6.418.013V4.046A1.049 1.049 0 0 1 5.314 3l9.1.019a1.049 1.049 0 0 1 1.048 1.048V9.7z", className: "prefix__cls-1", "data-name": "Path 10218" }))));
};
