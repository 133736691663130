/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import { MiniGridCard, Span, FontWeight, TypographyCaption, MegaphoneIcon } from '@tumelo/shared'
import { Payload } from '../../../application/payload'
import { PayloadUnwrapper } from '../../Error/PayloadUnwrapper'
import { SkeletonRows } from '../../../buildingBlocks/Skeleton'
import { Flex, IconCircle } from '../styled'

export const FavouriteCategoryCard: React.FC<{
  category: Payload<string | undefined>
}> = ({ category }) => (
  <MiniGridCard>
    <PayloadUnwrapper
      key="voting-interest"
      item={category}
      customNotInitialized={<SkeletonRows count={8} />}
      customSpinner={<SkeletonRows count={8} />}
    >
      {(category) => (
        <>
          <Flex>
            <IconCircle>
              <MegaphoneIcon />
            </IconCircle>
            <TypographyCaption mt="0" mb="2.5rem" fontWeight={FontWeight.semiBold}>
              You&apos;re supporting <br />
              <Span fontWeight={FontWeight.bold} fontSize="2rem">
                {category}
                <br />
              </Span>{' '}
              votes the most right now
            </TypographyCaption>
          </Flex>

          <TypographyCaption m="0">
            The most popular category that most people have had a say on has been{' '}
            <Span fontWeight={FontWeight.semiBold}>Environment</Span> votes.
          </TypographyCaption>
        </>
      )}
    </PayloadUnwrapper>
  </MiniGridCard>
)
