/* eslint-disable @typescript-eslint/no-explicit-any */
import { choose, head } from '../utils/functional/arr'
import { pipe } from '../utils/functional/common'

type IntermediaryStates = 'pending' | 'not-initialised'
type ErrorStates = 'rejected' | 'not-found'

type PayloadDetailedError = {
  type: 'error'
  errorType: ErrorStates
  screenMessage: string
  debugMessage: string
}
type PayloadErrors = PayloadDetailedError | 'error'
export type Payload<T> = T | IntermediaryStates | PayloadErrors

export const createErrorNotFound = (item: string): PayloadErrors => ({
  type: 'error',
  errorType: 'not-found',
  screenMessage: `${item} not found`,
  debugMessage: `${item} not found`,
})

export const isDetailedErrorState = (item: any): item is PayloadDetailedError => {
  return item != null && typeof item === 'object' && 'type' in item && item.type === 'error'
}
export const isErrorStates = (item: any): item is PayloadErrors => {
  if (item === 'error') return true
  return !!isDetailedErrorState(item)
}

export const isIntermediaryState = (item: any): item is IntermediaryStates => {
  return item === 'pending' || item === 'not-initialised'
}

export const isValueState = <T>(item: Payload<T>): item is T => {
  return item !== 'pending' && item !== 'not-initialised' && !isErrorStates(item)
}

export const Payload = {
  map:
    <T, U>(f: (t: T) => U) =>
    (item: Payload<T>): Payload<U> =>
      isValueState(item) ? f(item) : item,
  bind:
    <U, T>(f: (t: T) => Payload<U>) =>
    (item: Payload<T>): Payload<U> =>
      isValueState(item) ? f(item) : item,
  toMaybe: <T>(item: Payload<T>) => (isValueState(item) ? item : null),
  default:
    <T, U extends T>(defaultValue: U) =>
    (x: Payload<T>) =>
      isValueState(x) ? x : defaultValue,
}

// A type-level Payload, represented in TS as a mapped type, that maps a tuple of Arrays to the
// associated zipped type.
// For example, it maps types: [Array<number>, Array<string>] -> [number, string].
export type PayloadUnw<A extends Payload<any>[]> = {
  [K in keyof A]: A[K] extends Payload<infer T> ? T : never
}

// PayloadResult<T>[] => PayloadResult<T[]>
export const consolidateStates = <T>(items: Payload<T>[]): Payload<T[]> => {
  const error = pipe(
    items,
    choose((x) => (isErrorStates(x) ? x : undefined)),
    head
  )
  if (error != null) return error
  const intermediaryState = pipe(
    items,
    choose((x) => (x === 'pending' ? x : undefined)),
    head
  )
  if (intermediaryState != null) return 'pending'

  const values = pipe(
    items,
    choose((x) => (isValueState(x) || x == null ? x : undefined))
  )
  const hasNotInitialised = items.find((q) => q === 'not-initialised')
  const hasPending = items.find((q) => q === 'pending')
  if (hasNotInitialised)
    if (hasPending || values.length > 0) return 'pending'
    // we are assuming this is an intermediary state
    else return 'not-initialised'

  return values
}

// This incomprehensible function uses variadic types to dynamically allocate generics as Payload<T>'s are unwrapped to T's
export const tryUnwrapPayloads = <Arrays extends Array<Payload<any>>>(
  ...payloads: Arrays
): PayloadUnw<Arrays> | IntermediaryStates | PayloadErrors => {
  const maybeNonValueState = pipe(consolidateStates(payloads), (q) =>
    isIntermediaryState(q) || isErrorStates(q) ? q : null
  )

  if (maybeNonValueState) return maybeNonValueState

  const allValues = payloads.filter((q) => isValueState(q) || q == null) // nulls/undefined are potentially valid values
  return allValues as any
}
