export const instrumentReferenceToString = (instrumentReference) => {
    return `${instrumentReference.idType}/${instrumentReference.idValue}`;
};
export const stringToInstrumentReference = (s) => {
    const parts = s.split('/');
    return {
        idType: parts[0],
        idValue: parts[1],
    };
};
export const isInstrumentReferenceStringIsin = (s) => stringToInstrumentReference(s).idType === 'isin';
// The rest of the system models MifirCodes as plain strings, but they are constrained in practice
// to a finite set of values. The transparency database holds the master list of codes, so this
// enum should remain aligned with the contents of the `mifir_identifier_codes` table.
export var MifirCode;
(function (MifirCode) {
    MifirCode["SHRS"] = "SHRS";
    MifirCode["ETFS"] = "ETFS";
    MifirCode["DPRS"] = "DPRS";
    MifirCode["CRFT"] = "CRFT";
    MifirCode["OTHE"] = "OTHE";
    MifirCode["SDRV"] = "SDRV";
    MifirCode["SFPS"] = "SFPS";
    MifirCode["BOND"] = "BOND";
    MifirCode["ETCS"] = "ETCS";
    MifirCode["ETNS"] = "ETNS";
    MifirCode["EMAL"] = "EMAL";
    MifirCode["DERV"] = "DERV";
})(MifirCode || (MifirCode = {}));
