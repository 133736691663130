/* eslint-disable @typescript-eslint/no-deprecated */

import * as React from 'react'
import { SubHeading, GridCard, CardHeader, CardContent, CallToAction, VotingPolicy } from '@tumelo/shared'
import styled from 'styled-components'
import { useRouter } from '../../application/router/useRouter'
import { useAppSelector } from '../../application/store'
import { selectSoftConfig } from '../../application/features/config/selectors'
import { VotePoliciesModal } from '../VotingPoliciesModal'
import { isValueState, Payload } from '../../application/payload'
import { useHabitatVotingPolicies } from '../../application/features/votingPolicies/hooks'
import { ManageEmailsConnected } from './ManageEmails'
import { ManageVotingPolicyConnected } from './ManageVotingPolicyConnected'

export const ProfileCardManageAccount: React.FC = () => {
  const { navigate } = useRouter()

  const { votingPoliciesEnabled, emailsEnabled } = useAppSelector((state) => ({
    votingPoliciesEnabled: selectSoftConfig(state).featureSwitches.enableVotingPolicies,
    emailsEnabled: selectSoftConfig(state).featureSwitches.enableEmails,
  }))
  const habitatVotingPolicies: Payload<VotingPolicy[]> = useHabitatVotingPolicies()
  const habitatVotingPoliciesFetched =
    isValueState(habitatVotingPolicies) && habitatVotingPolicies !== undefined && habitatVotingPolicies.length > 0

  return (
    <>
      {votingPoliciesEnabled && habitatVotingPoliciesFetched && (
        <VotePoliciesModal votingPolicies={habitatVotingPolicies} />
      )}
      <GridCard columnSpan={3} columnStart={1}>
        <CardHeader>
          <SubHeading mb={0}>Manage my account</SubHeading>
        </CardHeader>
        <CardContent>
          {emailsEnabled && <ManageEmailsConnected />}
          {votingPoliciesEnabled && <ManageVotingPolicyConnected />}
          <CTAContainer>
            <CallToAction onClick={() => navigate('/profile/delete-account')} callToAction="Delete my account" />
          </CTAContainer>
        </CardContent>
      </GridCard>
    </>
  )
}

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`
