/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { Breakpoint } from '@tumelo/shared'
import { CenterLayout } from '../Layout/CenterLayout'
import TopBarSimple from '../Layout/TopBarSimple/TopBarSimple'

export const SelfSelectLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <CenterLayout header={<TopBarSimple />}>
      <Container>{children}</Container>
    </CenterLayout>
  )
}

const Container = styled.div`
  margin: 0.5rem;
  @media (min-width: ${Breakpoint.tablet}) {
    margin: 4rem 11rem;
  }
`
