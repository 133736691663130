import { useEffect } from 'react'
import { Investor } from '@tumelo/shared'
import { isValueState, Payload } from '../../application/payload'
import { useInvestor } from '../../application/features/investor/useInvestor'
import { useRouter } from '../../application/router/useRouter'

export const useRequireEmailVerified = (): Payload<Investor> => {
  const { investor } = useInvestor()
  const redirectUrl = '/profile/confirm-email'
  const { navigate, pathname } = useRouter()

  useEffect(() => {
    if (isValueState(investor)) {
      const { changeEmail } = investor
      if (!changeEmail.emailVerified && pathname !== redirectUrl) {
        navigate(redirectUrl)
      }
    }
  }, [investor, navigate, pathname])

  return investor
}
