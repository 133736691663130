export var GeneralMeetingType;
(function (GeneralMeetingType) {
    GeneralMeetingType["annual"] = "annual";
    GeneralMeetingType["extraordinary"] = "extraordinary";
    GeneralMeetingType["special"] = "special";
    GeneralMeetingType["general"] = "general";
    GeneralMeetingType["court_ordered"] = "court_ordered";
    GeneralMeetingType["postal_ballot"] = "postal_ballot";
    GeneralMeetingType["bond_holder"] = "bond_holder";
})(GeneralMeetingType || (GeneralMeetingType = {}));
