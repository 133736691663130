import React from 'react'
import { VoteNotPassedIcon, VotePassedIcon } from '@tumelo/shared'
import { useTheme } from 'styled-components'
import { VoteResult } from '../../../application/types/VoteResult'
import { VoteResult_ProposalOutcome } from '../../../utils/proto/tumelo/dashboardbff/v1/vote_result'
import { TotalVotes } from './TotalVotes'
import { Column, CustomCallToAction, IconContainer, Row, SmallText } from './SeeResults'

interface SeeResultsVoteResultProps {
  voteResult: VoteResult
}

export const SeeResultsVoteResult: React.FC<SeeResultsVoteResultProps> = ({ voteResult }) => {
  const outcome = getOutcomeText(voteResult.proposalOutcome)

  return (
    <Row>
      <TotalVotes totalCount={voteResult.numberOfVotesPlaced} hasVoted={voteResult.investorVoted} />
      <Row>
        <IconContainer>
          <OutcomeIcon outcome={voteResult.proposalOutcome} />
        </IconContainer>
        <Column>
          <SmallText>{outcome}</SmallText>
          <div>
            <CustomCallToAction>See result</CustomCallToAction>
          </div>
        </Column>
      </Row>
    </Row>
  )
}

const getOutcomeText = (outcome: VoteResult_ProposalOutcome): string => {
  switch (outcome) {
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_FOR: {
      return 'Passed'
    }
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_AGAINST: {
      return 'Not passed'
    }
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_WITHDRAWN: {
      return 'Withdrawn'
    }
    default: {
      throw new Error(`invalid outcome ${outcome}`)
    }
  }
}

const OutcomeIcon: React.FC<{ outcome: VoteResult_ProposalOutcome }> = ({ outcome }) => {
  const { colors } = useTheme()

  switch (outcome) {
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_FOR: {
      return <VotePassedIcon width={20} fill={colors.success.dark} />
    }
    case VoteResult_ProposalOutcome.PROPOSAL_OUTCOME_AGAINST: {
      return <VoteNotPassedIcon width={20} fill={colors.danger.dark} />
    }
    default: {
      return null
    }
  }
}
