import * as React from 'react';
import styled from 'styled-components';
import { SearchIcon, CrossIcon } from '../Icons';
import { Input } from '../Input';
import { VisuallyHidden } from '../VisuallyHidden';
export const SearchInput = ({ handleClearInput, id, label, value, hintText, errorText, placeholder, autoComplete, mb, onChange, onFocus, onKeyDown, hideLabel = false, hasError = false, required = false, className, readOnly, }) => {
    return (React.createElement(StyledInput, { type: "search", iconBefore: React.createElement(SearchIcon, null), iconAfter: React.createElement(StyledButton, { visible: Boolean(value), onClick: handleClearInput, type: "button" },
            React.createElement(CrossIcon, null),
            " ",
            React.createElement(VisuallyHidden, null, "Clear search term")), id: id, label: label, value: value, hintText: hintText, errorText: errorText, placeholder: placeholder, autoComplete: autoComplete, mb: mb, onChange: onChange, onFocus: onFocus, onKeyDown: onKeyDown, hideLabel: hideLabel, hasError: hasError, required: required, className: className, readOnly: readOnly }));
};
const StyledButton = styled.button `
  transition:
    visibility 0.3s linear,
    opacity 0.2s linear;
  ${({ visible }) => (visible ? 'visibility:visible; opacity:1;' : 'visibility:hidden; opacity:0;')};
  padding: 0;
  background: none;
  margin: 0;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 1.6rem;
  top: 50%;
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  svg {
    position: static;
    transform: none;
    pointer-events: auto;
  }
`;
const StyledInput = styled(Input) `
  ::-webkit-search-cancel-button {
    display: none;
  }
`;
