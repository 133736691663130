import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgLifestylesmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M19.07 4.93C17.18 3.04 14.67 2 12 2C9.33 2 6.82 3.04 4.93 4.93C3.04 6.82 2 9.33 2 12C2 13.32 2.26 14.61 2.76 15.83C3.26 17.05 3.99 18.14 4.93 19.08C5.86 20.01 6.96 20.74 8.17 21.25C9.39 21.75 10.68 22.01 12 22.01C13.32 22.01 14.61 21.75 15.83 21.25C17.05 20.75 18.14 20.02 19.08 19.08C20.01 18.15 20.74 17.05 21.25 15.84C21.75 14.62 22.01 13.33 22.01 12.01C22.01 9.34 20.97 6.83 19.08 4.94L19.07 4.93ZM19.39 15.06C18.99 16.03 18.4 16.91 17.66 17.66C16.91 18.41 16.04 18.99 15.06 19.39C13.11 20.2 10.89 20.2 8.94 19.39C7.97 18.99 7.09 18.4 6.34 17.66C5.59 16.91 5.01 16.04 4.61 15.06C4.21 14.09 4 13.06 4 12C4 9.86 4.83 7.85 6.34 6.34C7.85 4.83 9.86 4 12 4C14.14 4 16.15 4.83 17.66 6.34C19.17 7.85 20 9.86 20 12C20 13.06 19.79 14.09 19.39 15.06ZM14.12 14.12C12.99 15.25 11.01 15.25 9.88 14.12C9.49 13.73 8.86 13.73 8.47 14.12C8.08 14.51 8.08 15.14 8.47 15.53C9.41 16.47 10.67 16.99 12.01 16.99C13.35 16.99 14.6 16.47 15.55 15.53C15.94 15.14 15.94 14.51 15.55 14.12C15.16 13.73 14.53 13.73 14.14 14.12H14.12ZM9.01 11C9.56 11 10.01 10.55 10.01 10C10.01 9.45 9.56 9 9.01 9C8.46 9 8.01 9.45 8.01 10C8.01 10.55 8.46 11 9.01 11ZM15.01 9C14.46 9 14.01 9.45 14.01 10C14.01 10.55 14.46 11 15.01 11C15.56 11 16.01 10.55 16.01 10C16.01 9.45 15.56 9 15.01 9Z" })));
};
