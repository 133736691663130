/* eslint-disable @typescript-eslint/no-deprecated */

import React, { FC } from 'react'
import { Tab, Tabs } from '@mui/material'
import styled from 'styled-components'
import { Breakpoint } from '@tumelo/shared'
import { ContentMaxWidth } from '@tumelo/designsystem'
import { Link } from '../../application/router/Link'

interface Props {
  navItems: { label: string; to: string }[]
  selectedTabIndex: number
}

export const SubNav: FC<Props> = ({ navItems, selectedTabIndex }) => (
  <RelativeContainer>
    <Underline>
      <Padding>
        <StyledTabs value={selectedTabIndex} variant="scrollable">
          {navItems.map((x) => (
            <Tab tabIndex={0} disableRipple key={x.label} to={x.to} component={Link} label={x.label} />
          ))}
        </StyledTabs>
      </Padding>
    </Underline>
  </RelativeContainer>
)

const Padding = styled.div`
  max-width: ${ContentMaxWidth}px;
  margin: 0 auto;
  padding: 3rem 0 0 1.6rem;
  @media (max-width: ${Breakpoint.mobile}) {
    padding: 2rem 0 0 1.6rem;
  }
`

const StyledTabs = styled(Tabs)`
  &.MuiTabs-root {
    min-width: 0;
    min-height: 3rem;
    height: 3rem;
    min-height: 3rem;
  }
  .MuiTabs-indicator {
    background: ${({ theme }) => theme.colors.black};
    height: 0.3rem;
    z-index: 1;
  }
  .Mui-focusVisible {
    background-color: #a5ffff;
  }
  .MuiTab-root {
    text-transform: none;
    font-size: 1.6rem;
    line-height: 3rem;
    display: flex;
    min-width: 0;
    min-height: 3rem;
    height: 3rem;
    padding: 0 0 0.7rem;
    font-family: 'Montserrat', sans-serif;
    margin-right: 4rem;
    color: ${({ theme }) => theme.colors.black};
    &[aria-selected='true'] {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`

const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`

const Underline = styled.div`
  &:after {
    width: 100%;
    position: absolute;
    content: '';
    height: 2px;
    background: ${({ theme }) => theme.colors.grey.medium};
    bottom: 0px;
  }
`
