import React from 'react';
import styled from 'styled-components';
export const VimeoVideo = ({ title, vimeoID }) => {
    if (!vimeoID) {
        return null;
    }
    return (React.createElement(IframeContainer, null,
        React.createElement("iframe", { title: title, src: `https://player.vimeo.com/video/${vimeoID}`, width: "100%", height: "100%", frameBorder: "0", allow: "autoplay; fullscreen", allowFullScreen: true })));
};
const IframeContainer = styled.div `
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
