import styled from 'styled-components'
import { glowing } from '../../styles/animation'

export const LoadingItem = styled.div`
  border-radius: 0.4rem;
  width: 100%;
  height: 5rem;
  background-color: ${({ theme }) => theme.colors.grey.medium};
  animation: ${glowing} 2s infinite;
  margin-top: 1.4rem;
`
