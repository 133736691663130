import React from 'react'
import { CardGrid, BasePageProps, Content } from '@tumelo/shared'
import { useAppSelector } from '../application/store'
import { ProfileCardConnected } from '../components/ProfileCard/ProfileCardConnected'
import { AccountDetailsCardConnected } from '../components/AccountDetailsCard/AccountDetailsCardConnected'
import { PageHeader } from '../components/PageHeader'
import { RootState } from '../application/rootReducer'
import { ProfileCardManageAccount } from '../components/ProfileCardManageAccount/ProfileCardManageAccount'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { changeTitle } from './changeTitle'

type Props = BasePageProps

const Profile: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)

  const enableAccountDetails = useAppSelector((state: RootState) => {
    return state.config.config.softConfig.featureSwitches.enableAccountDetails
  })

  const Cards = [
    <ProfileCardConnected key="profilecard" />,
    enableAccountDetails ? <AccountDetailsCardConnected key="accountdetailscard" /> : null,
    <ProfileCardManageAccount key="manageaccountcard" />,
  ]

  return (
    <ErrorBoundary>
      <Content>
        <PageHeader title="Your profile" />
        <CardGrid>{Cards}</CardGrid>
      </Content>
    </ErrorBoundary>
  )
}

export default Profile
