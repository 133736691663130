import { OrganizationBreakdown } from '@tumelo/shared'
import { isValueState } from '../../payload'
import { AppThunk } from '../../store'
import * as actions from '.'

export const getTopOrganizations =
  (): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { pollService, loggerService } = services
    const { breakdown } = getState()

    dispatch(actions.setTopFivePending())
    try {
      if (!isValueState(breakdown)) {
        loggerService.logError('Trying to get top organizations before getting breakdown')
        dispatch(actions.setTopFiveError())
      } else {
        const topOrganizations = breakdown
          ? [...breakdown.organizationEntries].sort((a, b) => b.percentageWeight - a.percentageWeight).slice(0, 5)
          : []

        const organizationIds = topOrganizations.map((x) => x.organization.id)

        const orgToPollsMap = await pollService.listOpenPollsForOrganizations(organizationIds)

        const payload: OrganizationBreakdown = {
          organizationEntries: topOrganizations.map((x) => {
            const orgPolls = orgToPollsMap.get(x.organization.id)
            return { ...x, hasOpenVotes: orgPolls !== undefined && orgPolls.length > 0 }
          }),
          breakdownBasedOn: {},
          others: 0,
        }

        dispatch(actions.setTopFive(payload))
      }
    } catch (e) {
      loggerService.logError(e)
      dispatch(actions.setTopFiveError())
    }
  }
