import React from 'react'
import { PollGrid } from '../PollsList/ListContainer'
import { SkeletonCardlet } from './SkeletonCardlet'

interface Props {
  numberOfCards?: number
}

export const LoadingSkeleton: React.FC<Props> = ({ numberOfCards = 20 }) => {
  const array = Array(numberOfCards).fill(0)

  return (
    <PollGrid>
      {array.map((x, i) => (
        <SkeletonCardlet key={i} />
      ))}
    </PollGrid>
  )
}
