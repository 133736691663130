import * as React from 'react';
export const SvgManagement = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 40, height: 40, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 40, height: 40, rx: 4, fill: "#E9EEE8" }),
        React.createElement("path", { d: "M34 10.5H29V5.5C29 4.95 28.55 4.5 28 4.5H12C11.45 4.5 11 4.95 11 5.5V10.5H6C5.45 10.5 5 10.95 5 11.5V24.5C5 25 5.37 25.42 5.86 25.49L6 25.51V34.5C6 35.05 6.45 35.5 7 35.5H33C33.55 35.5 34 35.05 34 34.5V25.51L34.14 25.49C34.63 25.42 35 25 35 24.5V11.5C35 10.95 34.55 10.5 34 10.5ZM13 6.5H27V10.5H26V8.5C26 7.95 25.55 7.5 25 7.5H15C14.45 7.5 14 7.95 14 8.5V10.5H13V6.5ZM24 9.5V10.5H16V9.5H24ZM32 33.5H8V25.8L17 27.08V27.5C17 29.15 18.35 30.5 20 30.5C21.65 30.5 23 29.15 23 27.5V27.08L32 25.8V33.5ZM19 27.5V25.5H21V27.5C21 28.05 20.55 28.5 20 28.5C19.45 28.5 19 28.05 19 27.5ZM33 23.5C32.77 23.5 32.57 23.59 32.4 23.72L23 25.06V24.5C23 23.95 22.55 23.5 22 23.5H18C17.45 23.5 17 23.95 17 24.5V25.06L7.6 23.72C7.43 23.59 7.23 23.5 7 23.5V12.5H33V23.5Z", fill: "#2E5E22" })));
};
