import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgEnergysmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M7 17H5C4.45 17 4 16.55 4 16V7.99999C4 7.44999 4.45 6.99999 5 6.99999H8.19C8.74 6.99999 9.19 6.54999 9.19 5.99999C9.19 5.44999 8.74 4.99999 8.19 4.99999H5C3.35 4.99999 2 6.34999 2 7.99999V16C2 17.65 3.35 19 5 19H7C7.55 19 8 18.55 8 18C8 17.45 7.55 17 7 17ZM19 4.99999H17C16.45 4.99999 16 5.44999 16 5.99999C16 6.54999 16.45 6.99999 17 6.99999H19C19.55 6.99999 20 7.44999 20 7.99999V16C20 16.55 19.55 17 19 17H15.81C15.26 17 14.81 17.45 14.81 18C14.81 18.55 15.26 19 15.81 19H19C20.65 19 22 17.65 22 16V7.99999C22 6.34999 20.65 4.99999 19 4.99999ZM15.88 11.53C15.71 11.2 15.37 11 15 11H10.87L13.83 6.54999C14.14 6.08999 14.01 5.46999 13.55 5.15999C13.09 4.84999 12.47 4.97999 12.16 5.43999L8.16 11.44C7.96 11.75 7.94 12.14 8.11 12.47C8.28 12.8 8.62 13 8.99 13H13.12L10.16 17.45C9.85 17.91 9.98 18.53 10.44 18.84C10.61 18.95 10.8 19.01 10.99 19.01C11.31 19.01 11.63 18.85 11.82 18.56L15.82 12.56C16.02 12.25 16.04 11.86 15.87 11.53H15.88Z" })));
};
