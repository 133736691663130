/* eslint-disable @typescript-eslint/no-deprecated */
import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { Breakpoint, Small } from '../../styles';
import { shadeColor } from '../../utils';
import { Icon } from './Icon';
export const Button = ({ children, onClick, size = 'large', color, inverse = false, icon, iconAligned = 'left', disabled = false, type = 'button', className = '', fullWidth = false, form, }) => {
    const theme = useContext(ThemeContext);
    const { colors: themeColors } = theme;
    const colorIn = color !== null && color !== void 0 ? color : themeColors.secondary.medium;
    const colors = inverse ? [colorIn, themeColors.white] : [themeColors.white, colorIn];
    const [fontColor, backgroundColor] = disabled ? [themeColors.grey.dark, themeColors.grey.light] : colors;
    // TODO: fix this element - it's just a button but incredibly complex
    return type === 'pseudo' ? (React.createElement(StyledButton, { size: size, onClick: () => !disabled && onClick && onClick(), iconAligned: iconAligned, backgroundColor: backgroundColor, disabled: disabled, inverse: inverse, as: "div", fullWidth: fullWidth, className: className },
        React.createElement(ButtonContent, { icon: icon, iconAligned: iconAligned, fontColor: fontColor }, children))) : (React.createElement(StyledButton, { size: size, type: type, form: form, fullWidth: fullWidth, onClick: () => !disabled && onClick && onClick(), iconAligned: iconAligned, backgroundColor: backgroundColor, disabled: disabled, inverse: inverse, className: className },
        React.createElement(ButtonContent, { icon: icon, iconAligned: iconAligned, fontColor: fontColor }, children)));
};
export const ButtonContent = ({ children, icon, iconAligned, fontColor, }) => (React.createElement(React.Fragment, null,
    icon && iconAligned === 'left' && React.createElement(Icon, { color: fontColor, icon: icon, iconAligned: iconAligned }),
    React.createElement(Small, { weight: "bold", color: fontColor }, children),
    icon && iconAligned !== 'left' && React.createElement(Icon, { color: fontColor, icon: icon, iconAligned: iconAligned })));
const rippleStyles = css `
  position: relative;
  overflow: hidden;
  transition: all ease-in-out 0.2s;

  &::after {
    ${(p) => !p.disabled &&
    css `
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 25%;
        height: 100%;
        width: 50%;
        background-color: ${({ theme }) => theme.colors.black};
        border-radius: 50%;
        opacity: 0;
        pointer-events: none;
        transition: all ease-in-out 500ms;
        transform: scale(5, 5);
      `}
  }

  &:active::after {
    padding: 0;
    margin: 0;
    opacity: 0.2;
    transition: 0s;
    transform: scale(0, 0);
  }
`;
const padding = ({ size }) => ({
    small: css `
      padding: 0.8rem 1.6rem;
    `,
    medium: css `
      padding: 1.2rem 1.6rem;
    `,
    large: css `
      padding: 1.6rem;
    `,
    narrow: css `
      padding: 0.8rem 1.2rem;
    `,
})[size];
export const buttonStyles = css `
  display: inline-block;
  box-sizing: border-box;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'max-content')};
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 0px;
  border-radius: 5px;
  cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};

  min-width: ${(p) => (p.iconAligned === 'center' || p.size === 'narrow' ? '0' : '12.8rem')};
  @media (max-width: ${Breakpoint.mobile}) {
    min-width: ${(p) => (p.iconAligned === 'center' || p.size === 'narrow' ? '0' : '10rem')};
  }

  background-color: ${(p) => p.backgroundColor};
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: ${(p) => p.backgroundColor && !p.disabled && !p.inverse && shadeColor(p.backgroundColor, -10)};
  }

  box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.boxShadow};

  ${padding}
  ${rippleStyles}
`;
const StyledButton = styled.button `
  ${buttonStyles}
`;
