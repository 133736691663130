import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgTechnologysmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M21 2H3C2.45 2 2 2.45 2 3V17C2 17.55 2.45 18 3 18H8.72L8.22 20H7C6.45 20 6 20.45 6 21C6 21.55 6.45 22 7 22H17C17.55 22 18 21.55 18 21C18 20.45 17.55 20 17 20H15.78L15.28 18H21C21.55 18 22 17.55 22 17V3C22 2.45 21.55 2 21 2ZM10.28 20L10.78 18H13.22L13.72 20H10.28ZM20 16H4V14H20V16ZM20 12H4V4H20V12Z" })));
};
