import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InstrumentReferenceString } from '@tumelo/shared'
import { Payload } from '../../payload'

interface State {
  fundOrganizationIds: fundOrganizationIdsMap
}

export type fundOrganizationIdsMap = {
  [instrumentReferenceString: string]: Payload<string[]>
}

const initialState: State = {
  fundOrganizationIds: {},
}

const fundBreakdownsSlice = createSlice({
  name: 'fundBreakdowns',
  initialState,
  reducers: {
    setFundOrganizationIds: (
      state,
      action: PayloadAction<{
        instrumentReferenceString: InstrumentReferenceString
        organizationIds: string[]
      }>
    ) => {
      state.fundOrganizationIds[action.payload.instrumentReferenceString] = action.payload.organizationIds
    },
    setFundOrganizationIdsError: (
      state,
      action: PayloadAction<{
        instrumentReferenceString: InstrumentReferenceString
      }>
    ) => {
      state.fundOrganizationIds[action.payload.instrumentReferenceString] = 'error'
    },
    setFundOrganizationIdsPending: (
      state,
      action: PayloadAction<{
        instrumentReferenceString: InstrumentReferenceString
      }>
    ) => {
      state.fundOrganizationIds[action.payload.instrumentReferenceString] = 'pending'
    },
  },
})

export const { setFundOrganizationIds, setFundOrganizationIdsError, setFundOrganizationIdsPending } =
  fundBreakdownsSlice.actions
export default fundBreakdownsSlice.reducer
