import React, { useState } from 'react'
import styled from 'styled-components'
import { SearchMenuLayout, BasePageProps } from '@tumelo/shared'
import { useAppDispatch, useAppSelector } from '../application/store'
import { ListVoteResults } from '../components/views/ListVoteResults'
import * as SearchSelectors from '../application/selectors/searchFilters'
import { clearFilters } from '../application/features/searchFilters'
import { useVoteResults } from '../application/features/voteResults/hooks'
import { PageHeader } from '../components/PageHeader'
import VotingFilterMenu from '../components/Layout/SearchMenu/menus/VotingFilterMenu'
import { buildEvent } from '../application/analytics/eventBuilders'
import {
  WhereYourPensionIsInvestedModal,
  WhereYourPensionIsInvestedVimeoID,
  WhereYourPensionIsInvestedVimeoTitle,
} from '../components/WhereYourPensionIsInvestedModal'
import { useAnalytics } from '../application/analytics'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { changeTitle } from './changeTitle'

type Props = BasePageProps

const ResolvedVotesPage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)
  const dispatch = useAppDispatch()
  const { voteResults, pageState, loadNextPage, minCloseTime } = useVoteResults()
  const hasActiveFilters = useAppSelector(SearchSelectors.hasActiveFilters)

  const description = `These questions have been asked at company AGMs (Annual General Meetings). 
  Click on a card to explore the vote result in more detail.`

  return (
    <ErrorBoundary>
      <FilterContainer title="Vote results" description={description}>
        <ListVoteResults
          hasActiveFilters={hasActiveFilters}
          resetAllFilters={() => {
            dispatch(clearFilters())
          }}
          voteResults={voteResults}
          pageState={pageState}
          minCloseTime={minCloseTime}
          loadNextPage={loadNextPage}
        />
      </FilterContainer>
    </ErrorBoundary>
  )
}
interface FilterContainerProps {
  description: string
  title: string
}

const FilterContainer: React.FC<React.PropsWithChildren<FilterContainerProps>> = ({ description, title, children }) => {
  const [isMenuOpenOnMobile, setIsMenuOpenOnMobile] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { trackEvent } = useAnalytics()

  const handleModalOpen = () => {
    trackEvent(
      buildEvent.OpenVimeoModalVideo(
        WhereYourPensionIsInvestedVimeoID,
        WhereYourPensionIsInvestedVimeoTitle,
        'Vote Results Page'
      )
    )
    setModalIsOpen(true)
  }

  return (
    <SearchMenuLayout
      menuHeading="Filter"
      menu={<VotingFilterMenu />}
      isMenuOpenOnMobile={isMenuOpenOnMobile}
      toggleMenuCallback={() => setIsMenuOpenOnMobile(!isMenuOpenOnMobile)}
    >
      <PageHeader title={title} helpText={description} />
      <MoreInformationContainer>
        We aim to give you complete transparency over meeting results - whether you voted on these issues or not. Find
        out more about how this process works by watching{' '}
        <VimeoLink
          // This should probably be an actual button - don't copy this!
          tabIndex={0}
          onClick={handleModalOpen}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              handleModalOpen()
            }
          }}
          role="button"
        >
          this video.
        </VimeoLink>
      </MoreInformationContainer>
      {children}
      <WhereYourPensionIsInvestedModal open={modalIsOpen} handleModalClose={() => setModalIsOpen(false)} />
    </SearchMenuLayout>
  )
}

const MoreInformationContainer = styled.div`
  padding-bottom: 4rem;
  width: 100%;
  overflow: hidden;
`

const VimeoLink = styled.span`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

export default ResolvedVotesPage
