import { Config, ConfigSchema } from './Config'

export { ConfigSchema }
export type { Config }

export const getNewConfig = (pathPrefix: string) => async (): Promise<Config> => {
  const resp = await fetch(`${pathPrefix}/runtime-config.json`)
  const config = await resp.json()
  return { ...ConfigSchema.parse(config), services: config.services_v2 }
}
