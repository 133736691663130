import { dateToTimestamp } from '../Timestamp';
export const sampleStructuredDescriptionSection = [
    {
        title: 'Board governance and equality',
        content: [
            'Directors must have a wide range of expertise.',
            'Directors come from diverse backgrounds.',
            'Leave day-to-day decisions to the Board.',
        ],
    },
    {
        title: 'Shareholder value and returns',
        content: [
            'Encourage link between exec pay and company performance.',
            'Review environmental and social issues on a case-by-case basis.',
        ],
    },
    {
        title: 'Country-specific analysis',
        content: ['Compliance with relevant laws.'],
    },
];
const samplePolicyId = 'votingPolicyId';
export const sampleVotingPolicy = (override) => (Object.assign({ votingPolicyId: samplePolicyId, title: 'Example Voting Policy', policyProvider: { title: 'Glass Lewis' }, currentDocument: {
        policyDocumentId: 'documentId',
        pdfUrl: 'www.google.co.uk',
    }, structuredDescription: sampleStructuredDescriptionSection, imageUrl: '/images/policy_card.png' }, override));
export const sampleVotingPolicySelection = {
    id: 'sampleId',
    votingPolicyId: samplePolicyId,
    createTime: dateToTimestamp(new Date('2021-06-26T14:46:36.000')),
    updateTime: dateToTimestamp(new Date('2021-06-26T14:46:36.000')),
};
