import { sampleCompositeInstrument, sampleNonCompositeInstrument } from '../Instrument/SampleInstrument';
import { dateToTimestamp } from '../Timestamp';
export const sampleComposition = () => {
    return {
        id: 'd0096383-dfa9-40c8-a92f-e61fc2777f4a',
        components: {
            cash: [
                { currency: 'GBP', weight: 0.2 },
                { currency: 'USD', weight: 0.2 },
            ],
            instruments: [
                {
                    instrumentReference: sampleCompositeInstrument().instrumentReferences[0],
                    weight: 0.3,
                },
                {
                    instrumentReference: sampleNonCompositeInstrument().instrumentReferences[0],
                    weight: 0.2,
                },
            ],
            others: 0.1,
        },
        validAt: dateToTimestamp(new Date('2020-04-20T23:00:00Z')),
        createdAt: dateToTimestamp(new Date('2020-04-28T11:48:16.497851Z')),
    };
};
