import { AuthAttributes } from '../../services/Auth/Auth'
import { AppThunk } from '../../store'
import { logOutAction } from '../../rootReducer'
import { parseError, AuthErrorType, logUnexpectedError } from './errors'
import { beginAuthAction, completeAuthAction, setAuthState, setAuthUser } from '.'

export const signIn =
  (username: string, password: string): AppThunk =>
  async (dispatch, _, { services }) => {
    const { authService, loggerService } = services
    try {
      dispatch(beginAuthAction())
      const { challenge, challengePhoneNumber } = await authService.signIn(username, password)
      switch (challenge) {
        case 'CONFIRM_SIGN_UP':
          dispatch(setAuthUser({ email: username, password }))
          dispatch(setAuthState('confirmSignUp'))
          break
        case 'SMS_MFA':
          dispatch(setAuthUser({ phonenumber: challengePhoneNumber }))
          dispatch(setAuthState('confirmSignIn'))
          break
        default:
          dispatch(setAuthState('authenticated'))
          break
      }
      dispatch(completeAuthAction())
    } catch (err) {
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
      ])

      dispatch(completeAuthAction(authError))
    }
  }

export const signUp =
  (attributes: AuthAttributes): AppThunk =>
  async (dispatch, _, { services }) => {
    const { authService, loggerService, analyticsService } = services
    try {
      dispatch(beginAuthAction())
      const { userSub } = await authService.signUp(attributes)
      dispatch(
        setAuthUser({
          sub: userSub,
          email: attributes.username,
          password: attributes.password,
        })
      )
      dispatch(setAuthState('confirmSignUp'))
      dispatch(completeAuthAction())
      analyticsService.createIdentity(userSub)
    } catch (err) {
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.UsernameExistsException,
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
        AuthErrorType.InvalidPasswordException,
      ])

      dispatch(completeAuthAction(authError))
    }
  }

export const confirmSignIn =
  (code: string): AppThunk =>
  async (dispatch, _, { services }) => {
    const { authService, loggerService } = services
    try {
      dispatch(beginAuthAction())
      await authService.confirmSignIn(code)
      dispatch(setAuthState('authenticated'))
      dispatch(completeAuthAction())
    } catch (err) {
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.CodeMismatchException,
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
      ])

      dispatch(completeAuthAction(authError))
    }
  }

export const resendSignupCode =
  (): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { authService, loggerService } = services
    const { authUser } = getState().auth
    try {
      dispatch(beginAuthAction())
      if (!authUser.email) {
        throw new Error('No email provided before resend sign up code')
      }
      await authService.resendSignUpCode(authUser.email)
      dispatch(completeAuthAction())
    } catch (err) {
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
      ])

      dispatch(completeAuthAction(authError))
    }
  }

export const confirmSignUp =
  (code: string): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { authService, loggerService } = services
    const { authUser } = getState().auth
    try {
      dispatch(beginAuthAction())
      if (!authUser.email) {
        throw new Error('No email provided before confirm sign up')
      }
      if (!authUser.password) {
        throw new Error('No email provided before confirm sign up')
      }
      await authService.confirmSignUp(authUser.email, code)
      dispatch(completeAuthAction())
      dispatch(signIn(authUser.email, authUser.password))
    } catch (err) {
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.CodeMismatchException,
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
      ])

      dispatch(completeAuthAction(authError))
    }
  }

export const forgotPassword =
  (email: string): AppThunk =>
  async (dispatch, _, { services }) => {
    const { authService, loggerService } = services
    try {
      dispatch(beginAuthAction())
      await authService.forgotPassword(email)
      dispatch(setAuthUser({ email }))
      dispatch(setAuthState('forgotPassword'))
      dispatch(completeAuthAction())
    } catch (err) {
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
      ])

      dispatch(completeAuthAction(authError))
    }
  }

export const forgotPasswordSubmit =
  (code: string, password: string): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { authService, loggerService } = services
    const { email } = getState().auth.authUser
    try {
      if (!email) {
        throw new Error('No email provided before forgot password submit')
      }
      dispatch(beginAuthAction())
      await authService.forgotPasswordSubmit(email, code, password)
      dispatch(setAuthState('signIn'))
      dispatch(completeAuthAction())
    } catch (err) {
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.CodeMismatchException,
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
      ])

      dispatch(completeAuthAction(authError))
    }
  }

export const federatedSignIn =
  (): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { authService, loggerService } = services
    const federatedSignInProvider = getState().config.config.aws.aws_cognito_federated_sign_in_provider
    try {
      dispatch(beginAuthAction())
      await authService.federatedSignIn(federatedSignInProvider)
      dispatch(setAuthState('federatedSignIn')) // set auth state so loading state is shown until user is redirected to federated provider
      dispatch(completeAuthAction())
    } catch (err) {
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
      ])

      dispatch(completeAuthAction(authError))
    }
  }

export const goToSignUp =
  ({ email, password }: { email?: string; password?: string }): AppThunk =>
  async (dispatch) => {
    dispatch(setAuthUser({ email, password }))
    dispatch(setAuthState('signUp'))
  }

export const goToForgotPassword =
  ({ email }: { email?: string }): AppThunk =>
  async (dispatch) => {
    dispatch(setAuthUser({ email }))
    dispatch(setAuthState('forgotPassword'))
  }

export const logOut =
  (callback: () => void, { global } = { global: false }): AppThunk =>
  async (dispatch, _, { services }) => {
    const { analyticsService, authService, loggerService } = services
    try {
      dispatch(beginAuthAction())
      analyticsService.signOutUser()
      dispatch(setAuthState('signOut'))
      await authService.signOut(global)
      dispatch(completeAuthAction())
      dispatch(logOutAction())
      localStorage.clear()
      callback()
    } catch (err) {
      dispatch(setAuthState('error'))
      const authError = parseError(err)

      logUnexpectedError(loggerService, authError, [
        AuthErrorType.UserUnAuthenticatedException,
        AuthErrorType.NotAuthorizedException,
      ])

      dispatch(completeAuthAction(authError))
    }
  }
