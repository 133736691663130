import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgNewwindow = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18 12C17.7348 12 17.4804 12.1054 17.2929 12.2929C17.1054 12.4804 17 12.7348 17 13V19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V8C4 7.73478 4.10536 7.48043 4.29289 7.29289C4.48043 7.10536 4.73478 7 5 7H11C11.2652 7 11.5196 6.89464 11.7071 6.70711C11.8946 6.51957 12 6.26522 12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5H5C4.20435 5 3.44129 5.31607 2.87868 5.87868C2.31607 6.44129 2 7.20435 2 8V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V13C19 12.7348 18.8946 12.4804 18.7071 12.2929C18.5196 12.1054 18.2652 12 18 12Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M21.9199 2.62C21.8712 2.50081 21.7999 2.39215 21.7099 2.3C21.6173 2.20551 21.5069 2.13034 21.385 2.07885C21.2631 2.02735 21.1322 2.00055 20.9999 2H14.9999C14.7347 2 14.4804 2.10536 14.2928 2.29289C14.1053 2.48043 13.9999 2.73478 13.9999 3C13.9999 3.26522 14.1053 3.51957 14.2928 3.70711C14.4804 3.89464 14.7347 4 14.9999 4H18.5899L9.28994 13.29C9.10164 13.4783 8.99585 13.7337 8.99585 14C8.99585 14.2663 9.10164 14.5217 9.28994 14.71C9.47824 14.8983 9.73364 15.0041 9.99994 15.0041C10.2662 15.0041 10.5216 14.8983 10.7099 14.71L19.9999 5.41V9C19.9999 9.26522 20.1053 9.51957 20.2928 9.70711C20.4804 9.89464 20.7347 10 20.9999 10C21.2652 10 21.5195 9.89464 21.707 9.70711C21.8946 9.51957 21.9999 9.26522 21.9999 9V3C21.9984 2.86932 21.9712 2.74022 21.9199 2.62Z" })));
};
