import React from 'react';
import styled from 'styled-components';
export const Toggle = ({ active, onClick, toggleName, disabled }) => {
    const fieldID = toggleName !== null && toggleName !== void 0 ? toggleName : 'toggle';
    return (React.createElement(React.Fragment, null,
        React.createElement(Checkbox, { disabled: disabled, defaultChecked: active, type: "checkbox", id: fieldID, onClick: onClick }),
        React.createElement(Border, { isDisabled: disabled, active: active, htmlFor: fieldID }, toggleName || 'toggle button')));
};
const Border = styled.label `
  position: relative;
  background: ${({ active, isDisabled, theme }) => {
    if (isDisabled) {
        return theme.colors.white;
    }
    if (active) {
        return theme.colors.secondary.medium;
    }
    return theme.colors.white;
}};
  // TODO remove next line  after  css update complete
  box-sizing: border-box;
  width: 54px;
  min-width: 54px;
  height: 30px;
  display: block;
  border-radius: 25px;
  cursor: pointer;
  text-indent: -9999px;
  border: 2px solid;
  border-color: ${({ active, isDisabled, theme }) => {
    if (isDisabled) {
        return theme.colors.grey.light;
    }
    if (active) {
        return theme.colors.secondary.medium;
    }
    return theme.colors.secondary.medium;
}};

  ::after {
    position: absolute;
    content: '';
    width: 2rem;
    height: 2rem;
    background: ${({ active, isDisabled, theme }) => {
    if (isDisabled) {
        return theme.colors.grey.light;
    }
    if (active) {
        return theme.colors.white;
    }
    return theme.colors.secondary.medium;
}};
    top: 3px;
    left: 3px;
    border-radius: 50%;
    transition-duration: 0.2s;

    left: ${(p) => p.active && 'calc(60% - 3px)'};
    animation: ${(p) => p.active && 'squidge 0.2s'};

    @keyframes squidge {
      0% {
        transform: scale(1, 1);
      }
      10% {
        transform: scale(1, 0.9);
      }
      30% {
        transform: scale(1, 0.85);
      }
      50% {
        transform: scale(1, 0.85);
      }
      75% {
        transform: scale(1, 0.9);
      }
      100% {
        transform: scale(1, 1);
      }
    }
  }
`;
const Checkbox = styled.input `
  display: none;
`;
