import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgExport = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M20.71 10.29C20.52 10.1 20.27 10 20 10C19.73 10 19.48 10.11 19.29 10.29C19.1 10.47 19 10.73 19 11V19C19 19.26 18.89 19.52 18.71 19.71C18.62 19.8 18.51 19.88 18.38 19.93C18.26 19.98 18.13 20.01 18 20H6C5.87 20 5.74 19.98 5.62 19.93C5.5 19.88 5.39 19.81 5.29 19.71C5.11 19.52 5 19.26 5 19V11C5 10.73 4.89 10.48 4.71 10.29C4.52 10.1 4.27 10 4 10C3.73 10 3.48 10.11 3.29 10.29C3.1 10.48 3 10.73 3 11V19C3 19.8 3.32 20.56 3.88 21.12C4.44 21.68 5.21 22 6 22H18C18.8 22 19.56 21.68 20.12 21.12C20.68 20.56 21 19.79 21 19V11C21 10.73 20.89 10.48 20.71 10.29ZM7.29 7.71C7.38 7.8 7.49 7.88 7.62 7.93C7.74 7.98 7.87 8.01 8 8.01C8.13 8.01 8.26 7.98 8.38 7.93C8.5 7.88 8.61 7.81 8.71 7.71L11 5.41V16C11 16.27 11.11 16.52 11.29 16.71C11.47 16.9 11.73 17 12 17C12.27 17 12.52 16.89 12.71 16.71C12.9 16.53 13 16.27 13 16V5.4L15.29 7.7C15.48 7.89 15.73 7.99 16 7.99C16.27 7.99 16.52 7.88 16.71 7.7C16.9 7.52 17 7.26 17 6.99C17 6.72 16.89 6.47 16.71 6.28L12.71 2.28C12.61 2.19 12.5 2.12 12.38 2.07C12.14 1.97 11.86 1.97 11.62 2.07C11.5 2.12 11.39 2.19 11.29 2.28L7.29 6.28C7.2 6.37 7.12 6.48 7.07 6.61C7.02 6.73 6.99 6.86 6.99 6.99C6.99 7.12 7.02 7.25 7.07 7.37C7.12 7.49 7.19 7.6 7.29 7.7V7.71Z" })));
};
