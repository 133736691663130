import React from 'react'
import { SignUpFormValues } from '../components/Auth/Forms/SignUpForm'
import { SignUpPage } from '../components/Auth/Pages/SignUpPage'
import { AuthAttributes } from '../application/services/Auth/Auth'
import { useAppDispatch, useAppSelector } from '../application/store'
import { signUp } from '../application/features/auth/asyncActions'
import { setAuthState } from '../application/features/auth'
import { selectAuthUser } from '../application/features/auth/selectors'

export const SignUp: React.FC = () => {
  const dispatch = useAppDispatch()
  const { email, password } = useAppSelector(selectAuthUser)
  const doSignUp = async (values: SignUpFormValues) => {
    const attributes: AuthAttributes = {
      username: values.email,
      password: values.password,
      attributes: {
        family_name: values.lastName,
        given_name: values.firstName,
      },
    }

    if (values.phoneNumber) {
      attributes.attributes.phone_number = values.phoneNumber
    }
    dispatch(signUp(attributes))
  }
  return (
    <SignUpPage
      clickSignUp={doSignUp}
      clickSignIn={() => dispatch(setAuthState('signIn'))}
      email={email ?? ''}
      password={password ?? ''}
      phoneNumber=""
    />
  )
}
