/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import { MiniGridCard, Span, FontWeight, TypographyCaption, VotingIcon } from '@tumelo/shared'
import { Payload } from '../../../application/payload'
import { PayloadUnwrapper } from '../../Error/PayloadUnwrapper'
import { SkeletonRows } from '../../../buildingBlocks/Skeleton'
import { Flex, IconCircle } from '../styled'

export const TotalVotesCard: React.FC<{
  numberOfVotes: Payload<number | undefined>
}> = ({ numberOfVotes }) => (
  <MiniGridCard>
    <PayloadUnwrapper
      key="voting-interest"
      item={numberOfVotes}
      customNotInitialized={<SkeletonRows count={8} />}
      customSpinner={<SkeletonRows count={8} />}
    >
      {(numberOfVotes) => (
        <>
          <Flex>
            <IconCircle>
              <VotingIcon width={35} height={35} />
            </IconCircle>
            <TypographyCaption mt="0" mb="2.5rem" fontWeight={FontWeight.semiBold}>
              You&apos;ve voted on
              <br />
              <Span fontWeight={FontWeight.bold} fontSize="2rem">
                {numberOfVotes} votes
              </Span>{' '}
              so far
            </TypographyCaption>
          </Flex>

          <TypographyCaption m="0">
            In total, over <Span fontWeight={FontWeight.semiBold}>35,000</Span> votes have been cast by other people -
            like you - using Tumelo&apos;s platform.
          </TypographyCaption>
        </>
      )}
    </PayloadUnwrapper>
  </MiniGridCard>
)
