import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { isValueState, Payload } from '../../payload'
import { AppNotification } from '../../types/AppNotification/AppNotification'
import { listNotifications } from './asyncActions'
import { selectNextPageToken, selectNotifications } from './selectors'

export const useNotifications: () => {
  notifications: Payload<AppNotification[]>
  fetchNextPage?: () => void
} = () => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector(selectNotifications)
  const nextPageToken = useAppSelector(selectNextPageToken)
  useEffect(() => {
    if (notifications === 'not-initialised') {
      dispatch(listNotifications(''))
    }
  }, [dispatch, notifications])
  const fetchNextPage =
    isValueState(nextPageToken) && nextPageToken !== ''
      ? () => {
          dispatch(listNotifications(nextPageToken))
        }
      : undefined
  return { notifications, fetchNextPage }
}
