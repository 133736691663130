import { InvestorVoteResponse, PollId, VotingPolicyId } from '@tumelo/shared'
import { ReadMoreVariant } from '../../components/SingleQuestion/Bottom/CallToAction'
import { VotingPolicyModalStep } from '../../components/VotingPoliciesModal/types'
import { AppNotificationType } from '../types/AppNotification/AppNotification'
import { PollCard, PollStatus, Event, CardType } from '../types/Event'

export const buildEvent = {
  openPoll: (pollId: PollId, referringCard: PollCard, status: PollStatus): Event => ({
    name: 'open_poll',
    props: {
      poll_id: pollId,
      referring_card: referringCard,
      poll_status: status,
    },
  }),
  clickNotification: (type: AppNotificationType, title: string): Event => ({
    name: 'click_notification',
    props: {
      notification_type: type,
      notification_title: title,
    },
  }),
  clickVote: (direction: InvestorVoteResponse, pollID: PollId): Event => ({
    name: 'click_to_vote',
    props: {
      poll_id: pollID,
      direction,
    },
  }),
  clickReasonToVote: (): Event => ({
    name: 'click_reason_to_vote',
    props: undefined,
  }),
  clickNotificationBell: (): Event => ({
    name: 'click_notification_bell',
    props: undefined,
  }),
  myVotesEmptyStateRendered: (): Event => ({
    name: 'my_votes_empty_rendered',
    props: undefined,
  }),
  returningCardRendered: (props: {
    card_type: CardType
    card_title?: string
    /**
     * where does the card CTA navigate to? (if anywhere)
     */
    to?: string
  }): Event => ({
    name: 'render_returning_card',
    props,
  }),
  returningCardCTAClicked: (props: {
    card_type: CardType
    card_title?: string
    /**
     * where does the card CTA navigate to? (if anywhere)
     */
    to?: string
  }): Event => ({
    name: 'click_returning_card_cta',
    props,
  }),
  clickReadMoreButton: (isSectionOpen: boolean, variant?: ReadMoreVariant): Event => ({
    name: 'click_read_more',
    props: {
      isOpen: isSectionOpen,
      variant,
    },
  }),
  OpenVimeoModalVideo: (vimeoID: number, vimeoTitle: string, openedFrom: string): Event => ({
    name: 'open_vimeo_modal_video',
    props: {
      vimeo_id: vimeoID,
      vimeo_title: vimeoTitle,
      opened_from: openedFrom,
    },
  }),
  respondToVoteResultNotificationsModal: (response: 'accept' | 'reject' | 'close'): Event => ({
    name: 'vote_result_notifications_modal_response',
    props: {
      response,
    },
  }),
  clickToggleFilter: (filter: string, entry: string): Event => ({
    name: 'use_filter',
    props: {
      type: 'toggle',
      filter,
      entry,
    },
  }),
  useSearchFilter: (filter: string, term: string): Event => ({
    name: 'use_filter',
    props: {
      type: 'search',
      filter,
      term,
    },
  }),
  loadPWA: (from: 'browser' | 'installed'): Event => ({
    name: 'load_pwa',
    props: {
      from,
    },
  }),
  clickFundFromAccountBreakdown: (isin: string, view: 'pie' | 'table'): Event => ({
    name: 'click_fund_from_account_breakdown',
    props: {
      isin,
      view,
    },
  }),
  clickViewVotePolicy: (policyId: VotingPolicyId): Event => ({
    name: 'click_view_vote_policy',
    props: {
      policyId,
    },
  }),
  clickVotingPolicyViewMoreAccordion: (policyId: VotingPolicyId, sectionHeading: string): Event => ({
    name: 'click_voting_policy_view_more_accordion',
    props: {
      policyId,
      sectionHeading,
    },
  }),
  openVotingPoliciesModal: (): Event => ({
    name: 'open_voting_policies_modal',
    props: undefined,
  }),
  closeVotingPoliciesModal: (step: VotingPolicyModalStep): Event => ({
    name: 'close_voting_policies_modal',
    props: {
      step: VotingPolicyModalStep[step],
    },
  }),
  selectVotingPolicy: (policyId: VotingPolicyId, index: number): Event => ({
    name: 'select_voting_policy',
    props: {
      policyId,
      index,
    },
  }),
}
