import * as React from 'react';
const DEFAULT_IMAGE_STYLE = {
    width: '2rem',
    height: '2rem',
    objectFit: 'contain',
    fill: '#e7eaf5',
};
export const HumanRights = ({ style }) => {
    const styleWithDefault = Object.assign(Object.assign({}, DEFAULT_IMAGE_STYLE), style);
    return (React.createElement("svg", { style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "prefix__Human_Rights_Icon", width: "25", height: "25", "data-name": "Human Rights Icon", viewBox: "0 0 25 25" },
        React.createElement("path", { id: "prefix__BG", d: "M18.809 0H6.191A6.256 6.256 0 0 0 0 6.191v12.618A6.2 6.2 0 0 0 6.191 25h12.618A6.2 6.2 0 0 0 25 18.809V6.191A6.256 6.256 0 0 0 18.809 0z", fill: "#efedf8" }),
        React.createElement("g", { id: "prefix__empowerment", transform: "translate(-37.492 -28)" },
            React.createElement("path", { id: "prefix__Path_9799", d: "M102.666 114.26a1.26 1.26 0 0 0-.79.283 1.451 1.451 0 0 0-2.025-.4 1.445 1.445 0 0 0-2.417-.521 1.424 1.424 0 0 0-1.109-.537h-.075a1.434 1.434 0 0 0-1.432 1.432v.649l-1.3.8a.365.365 0 0 0-.135.148l-.931 1.887a3.05 3.05 0 0 0-.255 1.928 2.989 2.989 0 0 0 .828 1.551l2.914 3.034v2.468a.364.364 0 0 0 .364.364h6.909a.364.364 0 0 0 .364-.364v-1.48a6.459 6.459 0 0 1 .058-.866l.2-1.46a12.216 12.216 0 0 0 .109-1.621v-6.209a.354.354 0 0 0-.062-.192 1.27 1.27 0 0 0-1.21-.9zm0 .727a.546.546 0 0 1 .545.545v1.815a.545.545 0 1 1-1.091 0v-1.815a.546.546 0 0 1 .546-.545zm-2-.366a.728.728 0 0 1 .727.727v1.821a.727.727 0 1 1-1.455 0v-1.821a.728.728 0 0 1 .728-.727zm-2.182-.725a.728.728 0 0 1 .727.727v2.545a.727.727 0 0 1-.468.678l.025-.049a.364.364 0 0 0-.141-.484l-.87-.5v-2.192a.728.728 0 0 1 .727-.721zm-2.235-.083h.075a.705.705 0 0 1 .7.7v1.887l-1.485-.85v-.069a.354.354 0 0 0 0-.255v-.707a.705.705 0 0 1 .7-.7zm6.859 9.271l-.2 1.459a7.233 7.233 0 0 0-.065.963v1.117h-6.177v-2.251a.364.364 0 0 0-.1-.252l-3.022-3.146a2.272 2.272 0 0 1-.633-1.181 2.317 2.317 0 0 1 .194-1.465l.883-1.791a.349.349 0 0 0 .077-.026l.849-.487 3.048 1.746-.017.031a1.736 1.736 0 0 1-2.028.886 1.111 1.111 0 0 1-.681-.553.364.364 0 1 0-.644.337 1.827 1.827 0 0 0 1.1.908 2.256 2.256 0 0 0 .7.109 2.457 2.457 0 0 0 1.279-.368.323.323 0 0 0 .036.013 1.711 1.711 0 0 1 1.506 1.64v2.027a.364.364 0 0 0 .727 0v-2.023a2.4 2.4 0 0 0-1.431-2.153 1.445 1.445 0 0 0 1.068-.5 1.44 1.44 0 0 0 2.134.057 1.247 1.247 0 0 0 1.5.315v3.067a11.474 11.474 0 0 1-.1 1.524z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9799", transform: "translate(-47.408 -76.351)" }),
            React.createElement("path", { id: "prefix__Path_9800", d: "M46.051 108.675l-3.549-2.6a.364.364 0 0 0-.428.588l3.549 2.6a.364.364 0 1 0 .428-.588z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9800", transform: "translate(-1.757 -69.374)" }),
            React.createElement("path", { id: "prefix__Path_9801", d: "M65.328 70.648a.364.364 0 1 0 .572-.448l-3.327-4.061a.364.364 0 1 0-.576.444z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9801", transform: "translate(-19.94 -32.85)" }),
            React.createElement("path", { id: "prefix__Path_9802", d: "M105.974 39.692a.364.364 0 0 0 .7-.192l-1.464-3.994a.364.364 0 1 0-.7.192z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9802", transform: "translate(-58.641 -3.853)" }),
            React.createElement("path", { id: "prefix__Path_9803", d: "M150.266 35.544h.023a.364.364 0 0 0 .363-.341l.364-3.818a.364.364 0 1 0-.726-.045l-.364 3.818a.364.364 0 0 0 .34.386z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9803", transform: "translate(-99.94)" }),
            React.createElement("path", { id: "prefix__Path_9804", d: "M178.135 47.759a.364.364 0 0 0 .483-.177l2.313-3.994a.364.364 0 0 0-.66-.306l-2.313 3.994a.363.363 0 0 0 .177.483z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9804", transform: "translate(-125.393 -11.884)" }),
            React.createElement("path", { id: "prefix__Path_9805", d: "M208.3 71.108l-1.93 1.911a.364.364 0 1 0 .514.514l1.93-1.911a.364.364 0 0 0-.514-.514z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9805", transform: "translate(-150.849 -36.532)" }),
            React.createElement("path", { id: "prefix__Path_9806", d: "M40.869 215.261l-.771.771a.364.364 0 1 0 .514.514l.771-.771a.364.364 0 1 0-.514-.514z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9806", transform: "translate(0 -168.993)" }),
            React.createElement("path", { id: "prefix__Path_9807", d: "M60.154 196.854a.363.363 0 0 0 .257-.107l.771-.771a.364.364 0 1 0-.514-.514l-.771.771a.364.364 0 0 0 .257.621z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9807", transform: "translate(-17.999 -150.918)" }),
            React.createElement("path", { id: "prefix__Path_9808", d: "M61.351 216.2a.364.364 0 1 0-.514.514l.778.778a.364.364 0 0 0 .514-.514z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9808", transform: "translate(-18.854 -169.851)" }),
            React.createElement("path", { id: "prefix__Path_9809", d: "M40.612 195.462a.364.364 0 1 0-.514.514l.771.771a.364.364 0 0 0 .514-.514z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9809", transform: "translate(0 -150.918)" }),
            React.createElement("path", { id: "prefix__Path_9810", d: "M235.291 34.25a1.122 1.122 0 1 0-1.074-.8 1.121 1.121 0 0 0 1.074.8zm-.348-1.308a.394.394 0 1 1-.03.3.392.392 0 0 1 .029-.299z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9810", transform: "translate(-176.524 -.825)" }),
            React.createElement("path", { id: "prefix__Path_9811", d: "M241.633 162.033l.279-.279a.364.364 0 1 0-.514-.514l-.279.279-.279-.279a.364.364 0 0 0-.514.514l.279.279-.279.279a.364.364 0 1 0 .514.514l.279-.279.279.279a.364.364 0 0 0 .514-.514z", fill: "#6355b5", stroke: "#6355b5", strokeWidth: ".2px", "data-name": "Path 9811", transform: "translate(-182.026 -119.683)" }))));
};
