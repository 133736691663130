import { dateToTimestamp, timestampToDate } from '../../domain';
/**
 * getMinMax returns the minimum and maximum timestamp of a basedOn. It removes
 * any duplicates such that if there is only one data the timestamp array
 * outputted may be only 1 long. If there are no entries in the basedOn, the output may be empty.
 */
export const getMinMaxTimestamp = (basedOn) => {
    var _a, _b, _c, _d, _e, _f;
    const dates = [
        ...((_b = (_a = basedOn.instruments) === null || _a === void 0 ? void 0 : _a.map(({ validAt }) => validAt)) !== null && _b !== void 0 ? _b : []),
        ...((_d = (_c = basedOn.modelPortfolios) === null || _c === void 0 ? void 0 : _c.map(({ validAt }) => validAt)) !== null && _d !== void 0 ? _d : []),
        ...((_f = (_e = basedOn.investorAccounts) === null || _e === void 0 ? void 0 : _e.map(({ validAt }) => validAt)) !== null && _f !== void 0 ? _f : []),
    ]
        .map(timestampToDate)
        .filter((date, i, self) => self.findIndex((d) => d.getTime() === date.getTime()) === i)
        .sort((a, b) => a.getTime() - b.getTime())
        .map(dateToTimestamp);
    switch (dates.length) {
        case 0:
            return [];
        case 1:
            return dates;
        case 2:
            return dates;
        default:
            return [dates[0], dates[dates.length - 1]];
    }
};
