/* eslint-disable @typescript-eslint/no-deprecated */

import React, { FC } from 'react'
import styled from 'styled-components'
import { Breakpoint, Button, Modal } from '@tumelo/shared'
import { ExplicitPageView } from '../../application/analytics'
import { ExternalLinkWithTracking } from '../../buildingBlocks/ExternalLinkWithTracking'
import { privacyPolicyUrl, termsAndConditionsUrl } from '../../config/ExternalLinks'

interface Props {
  handleNonAcceptance: () => void
  handleAcceptance: () => void
  open: boolean
  page?: string
}

export const TermsModal: FC<Props> = ({ open, page, handleAcceptance, handleNonAcceptance }) => {
  return (
    <Modal
      width="small"
      title="Welcome to Tumelo! Before diving into your investments..."
      open={open}
      onClose={handleNonAcceptance}
    >
      {page && <ExplicitPageView page={page} />}
      <ModalBody>
        <OverviewContainer>Have a read and you&#39;ll be on your way.</OverviewContainer>

        <TermsContainer>
          {`I have read and accepted Tumelo's `}
          <StyledLink to={privacyPolicyUrl}>Privacy Policy</StyledLink>
          {' and '}
          <StyledLink to={termsAndConditionsUrl}>Terms &amp; Conditions</StyledLink>
          {' and I am happy for this platform to be personalised for my investments.'}
        </TermsContainer>

        <ButtonsContainer>
          <RejectWrapper>
            <Button type="button" inverse onClick={handleNonAcceptance}>
              I don&#39;t accept &amp; sign out
            </Button>
          </RejectWrapper>

          <AcceptWrapper>
            <Button type="button" onClick={handleAcceptance}>
              I accept
            </Button>
          </AcceptWrapper>
        </ButtonsContainer>
      </ModalBody>
    </Modal>
  )
}

const OverviewContainer = styled.div`
  padding-top: 1.7rem;
  padding-bottom: 2.3rem;
`

const TermsContainer = styled.div`
  padding-bottom: 6.1rem;
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (max-width: ${Breakpoint.mobile}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`

const RejectWrapper = styled.div`
  padding-right: 2rem;

  @media (max-width: ${Breakpoint.mobile}) {
    padding-top: 1.6rem;
  }
`

const AcceptWrapper = styled.div`
  padding-right: 1.05rem;
`

const ModalBody = styled.div`
  color: ${({ theme }) => theme.colors.primary.medium};
`

const StyledLink = styled(ExternalLinkWithTracking)`
  color: inherit;
  text-decoration: underline;
`
