import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '26.479',
    height: '28.748',
};
export const LightBulbIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 27 29", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "data-name": "Group 21195" },
            React.createElement("g", { "data-name": "Group 21194" },
                React.createElement("path", { "data-name": "Path 13810", d: "M19.237 6.564a8.691 8.691 0 0 0-14.682 6.054 8.723 8.723 0 0 0 3.406 7.135 4.572 4.572 0 0 1 1.731 3.632v.373a1.39 1.39 0 0 0-1.03 1.335v.2a1.391 1.391 0 0 0 1.393 1.396h.238a1.405 1.405 0 0 0-.085.464v.2a1.391 1.391 0 0 0 1.392 1.392h3.282a1.391 1.391 0 0 0 1.392-1.392v-.2a1.405 1.405 0 0 0-.085-.464h.238a1.391 1.391 0 0 0 1.392-1.392v-.2a1.384 1.384 0 0 0-1.03-1.335v-.373a4.788 4.788 0 0 1 1.861-3.734 8.685 8.685 0 0 0 .588-13.087Zm-3.893 20.787a.466.466 0 0 1-.464.464h-3.282a.466.466 0 0 1-.464-.464v-.2a.466.466 0 0 1 .464-.464h3.282a.462.462 0 0 1 .464.464Zm1.545-2.258v.2a.466.466 0 0 1-.464.464h-6.37a.466.466 0 0 1-.464-.464v-.2a.466.466 0 0 1 .464-.464h6.371a.466.466 0 0 1 .463.464Zm-5.143-7.559h-.951a.7.7 0 0 1-.7-.7v-.25a.7.7 0 0 1 .7-.7h.255a.7.7 0 0 1 .7.7Zm.928 6.167v-5.244h1.132v5.245Zm5.4-4.775a5.691 5.691 0 0 0-2.207 4.458v.317h-1.132v-5.244h.951a1.628 1.628 0 0 0 1.624-1.624v-.255a1.628 1.628 0 0 0-1.624-1.624h-.255a1.628 1.628 0 0 0-1.624 1.624v.951h-1.132v-.951a1.628 1.628 0 0 0-1.624-1.624h-.255a1.628 1.628 0 0 0-1.624 1.624v.255a1.628 1.628 0 0 0 1.624 1.624h.951v5.245h-1.132v-.317a5.51 5.51 0 0 0-2.1-4.368 7.758 7.758 0 0 1 4.334-13.913 7.764 7.764 0 0 1 5.222 13.822Zm-3.338-1.392v-.95a.7.7 0 0 1 .7-.7h.255a.7.7 0 0 1 .7.7v.255a.7.7 0 0 1-.7.7Z" }),
                React.createElement("path", { "data-name": "Rectangle 6717", d: "M12.776 0h.928v2.569h-.928z" }),
                React.createElement("path", { "data-name": "Rectangle 6718", d: "m4.785 2.584 1.817 1.817-.657.656L4.13 3.24z" }),
                React.createElement("path", { "data-name": "Rectangle 6719", d: "m20.535 5.059-.656-.656 1.816-1.817.657.656z" }),
                React.createElement("path", { "data-name": "Rectangle 6720", d: "M0 11.231h2.569v.928H0z" }),
                React.createElement("path", { "data-name": "Rectangle 6721", d: "M23.911 11.231h2.569v.928h-2.569z" })))));
};
