import React from 'react';
import styled from 'styled-components';
export const RadioGroupNameContext = React.createContext('individualRadioButton');
export const RadioButtonGroup = ({ groupName, children }) => {
    return (React.createElement(RadioGroupNameContext.Provider, { value: groupName },
        React.createElement(StyledList, null, children)));
};
const StyledList = styled.div `
  line-height: 3.2rem;
  margin-block-end: 1.6rem;
  margin-left: 2rem;
`;
