/**
 * NAICS codes
 */
export var NAICSCode;
(function (NAICSCode) {
    NAICSCode["SECTOR_AGRICULTURE_FORESTRY_FISHING_AND_HUNTING"] = "11";
    NAICSCode["SECTOR_MINING_QUARRYING_AND_OIL_AND_GAS_EXTRACTION"] = "21";
    NAICSCode["SECTOR_UTILITIES"] = "22";
    NAICSCode["SECTOR_CONSTRUCTION"] = "23";
    NAICSCode["SECTOR_MANUFACTURING_1"] = "31";
    NAICSCode["SECTOR_MANUFACTURING_2"] = "32";
    NAICSCode["SECTOR_MANUFACTURING_3"] = "33";
    NAICSCode["SECTOR_WHOLESALE_TRADE_1"] = "41";
    NAICSCode["SECTOR_WHOLESALE_TRADE_2"] = "42";
    NAICSCode["SECTOR_RETAIL_TRADE_1"] = "44";
    NAICSCode["SECTOR_RETAIL_TRADE_2"] = "45";
    NAICSCode["SECTOR_TRANSPORTATION_AND_WAREHOUSING_1"] = "48";
    NAICSCode["SECTOR_TRANSPORTATION_AND_WAREHOUSING_2"] = "49";
    NAICSCode["SECTOR_INFORMATION"] = "51";
    NAICSCode["SECTOR_FINANCE_AND_INSURANCE"] = "52";
    NAICSCode["SECTOR_REAL_ESTATE_AND_RENTAL_AND_LEASING"] = "53";
    NAICSCode["SECTOR_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES"] = "54";
    NAICSCode["SECTOR_MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES"] = "55";
    NAICSCode["SECTOR_ADMINISTRATIVE_AND_SUPPORT_AND_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES"] = "56";
    NAICSCode["SECTOR_EDUCATIONAL_SERVICES"] = "61";
    NAICSCode["SECTOR_HEALTH_CARE_AND_SOCIAL_ASSISTANCE"] = "62";
    NAICSCode["SECTOR_ARTS_ENTERTAINMENT_AND_RECREATION"] = "71";
    NAICSCode["SECTOR_ACCOMMODATION_AND_FOOD_SERVICES"] = "72";
    NAICSCode["SECTOR_OTHER_SERVICES_EXCEPT_PUBLIC_ADMINISTRATION"] = "81";
    NAICSCode["SECTOR_PUBLIC_ADMINISTRATION_1"] = "91";
    NAICSCode["SECTOR_PUBLIC_ADMINISTRATION_2"] = "92";
    NAICSCode["OILSEED_AND_GRAIN_FARMING"] = "1111";
    NAICSCode["VEGETABLE_AND_MELON_FARMING"] = "1112";
    NAICSCode["FRUIT_AND_TREE_NUT_FARMING"] = "1113";
    NAICSCode["GREENHOUSE_NURSERY_AND_FLORICULTURE_PRODUCTION"] = "1114";
    NAICSCode["OTHER_CROP_FARMING"] = "1119";
    NAICSCode["CATTLE_RANCHING_AND_FARMING"] = "1121";
    NAICSCode["HOG_AND_PIG_FARMING"] = "1122";
    NAICSCode["POULTRY_AND_EGG_PRODUCTION"] = "1123";
    NAICSCode["SHEEP_AND_GOAT_FARMING"] = "1124";
    NAICSCode["AQUACULTURE"] = "1125";
    NAICSCode["OTHER_ANIMAL_PRODUCTION"] = "1129";
    NAICSCode["TIMBER_TRACT_OPERATIONS"] = "1131";
    NAICSCode["FOREST_NURSERIES_AND_GATHERING_OF_FOREST_PRODUCTS"] = "1132";
    NAICSCode["LOGGING"] = "1133";
    NAICSCode["FISHING"] = "1141";
    NAICSCode["HUNTING_AND_TRAPPING"] = "1142";
    NAICSCode["SUPPORT_ACTIVITIES_FOR_CROP_PRODUCTION"] = "1151";
    NAICSCode["SUPPORT_ACTIVITIES_FOR_ANIMAL_PRODUCTION"] = "1152";
    NAICSCode["SUPPORT_ACTIVITIES_FOR_FORESTRY"] = "1153";
    NAICSCode["OIL_AND_GAS_EXTRACTION"] = "2111";
    NAICSCode["COAL_MINING"] = "2121";
    NAICSCode["METAL_ORE_MINING"] = "2122";
    NAICSCode["NONMETALLIC_MINERAL_MINING_AND_QUARRYING"] = "2123";
    NAICSCode["SUPPORT_ACTIVITIES_FOR_MINING"] = "2131";
    NAICSCode["ELECTRIC_POWER_GENERATION_TRANSMISSION_AND_DISTRIBUTION"] = "2211";
    NAICSCode["NATURAL_GAS_DISTRIBUTION"] = "2212";
    NAICSCode["WATER_SEWAGE_AND_OTHER_SYSTEMS"] = "2213";
    NAICSCode["RESIDENTIAL_BUILDING_CONSTRUCTION"] = "2361";
    NAICSCode["NONRESIDENTIAL_BUILDING_CONSTRUCTION"] = "2362";
    NAICSCode["UTILITY_SYSTEM_CONSTRUCTION"] = "2371";
    NAICSCode["LAND_SUBDIVISION"] = "2372";
    NAICSCode["HIGHWAY_STREET_AND_BRIDGE_CONSTRUCTION"] = "2373";
    NAICSCode["OTHER_HEAVY_AND_CIVIL_ENGINEERING_CONSTRUCTION"] = "2379";
    NAICSCode["FOUNDATION_STRUCTURE_AND_BUILDING_EXTERIOR_CONTRACTORS"] = "2381";
    NAICSCode["BUILDING_EQUIPMENT_CONTRACTORS"] = "2382";
    NAICSCode["BUILDING_FINISHING_CONTRACTORS"] = "2383";
    NAICSCode["OTHER_SPECIALTY_TRADE_CONTRACTORS"] = "2389";
    NAICSCode["ANIMAL_FOOD_MANUFACTURING"] = "3111";
    NAICSCode["GRAIN_AND_OILSEED_MILLING"] = "3112";
    NAICSCode["SUGAR_AND_CONFECTIONERY_PRODUCT_MANUFACTURING"] = "3113";
    NAICSCode["FRUIT_AND_VEGETABLE_PRESERVING_AND_SPECIALTY_FOOD_MANUFACTURING"] = "3114";
    NAICSCode["DAIRY_PRODUCT_MANUFACTURING"] = "3115";
    NAICSCode["ANIMAL_SLAUGHTERING_AND_PROCESSING"] = "3116";
    NAICSCode["SEAFOOD_PRODUCT_PREPARATION_AND_PACKAGING"] = "3117";
    NAICSCode["BAKERIES_AND_TORTILLA_MANUFACTURING"] = "3118";
    NAICSCode["OTHER_FOOD_MANUFACTURING"] = "3119";
    NAICSCode["BEVERAGE_MANUFACTURING"] = "3121";
    NAICSCode["TOBACCO_MANUFACTURING"] = "3122";
    NAICSCode["FIBER_YARN_AND_THREAD_MILLS"] = "3131";
    NAICSCode["FABRIC_MILLS"] = "3132";
    NAICSCode["TEXTILE_AND_FABRIC_FINISHING_AND_FABRIC_COATING_MILLS"] = "3133";
    NAICSCode["TEXTILE_FURNISHINGS_MILLS"] = "3141";
    NAICSCode["OTHER_TEXTILE_PRODUCT_MILLS"] = "3149";
    NAICSCode["APPAREL_KNITTING_MILLS"] = "3151";
    NAICSCode["CUT_AND_SEW_APPAREL_MANUFACTURING"] = "3152";
    NAICSCode["APPAREL_ACCESSORIES_AND_OTHER_APPAREL_MANUFACTURING"] = "3159";
    NAICSCode["LEATHER_AND_HIDE_TANNING_AND_FINISHING"] = "3161";
    NAICSCode["FOOTWEAR_MANUFACTURING"] = "3162";
    NAICSCode["OTHER_LEATHER_AND_ALLIED_PRODUCT_MANUFACTURING"] = "3169";
    NAICSCode["SAWMILLS_AND_WOOD_PRESERVATION"] = "3211";
    NAICSCode["VENEER_PLYWOOD_AND_ENGINEERED_WOOD_PRODUCT_MANUFACTURING"] = "3212";
    NAICSCode["OTHER_WOOD_PRODUCT_MANUFACTURING"] = "3219";
    NAICSCode["PULP_PAPER_AND_PAPERBOARD_MILLS"] = "3221";
    NAICSCode["CONVERTED_PAPER_PRODUCT_MANUFACTURING"] = "3222";
    NAICSCode["PRINTING_AND_RELATED_SUPPORT_ACTIVITIES"] = "3231";
    NAICSCode["PETROLEUM_AND_COAL_PRODUCTS_MANUFACTURING"] = "3241";
    NAICSCode["BASIC_CHEMICAL_MANUFACTURING"] = "3251";
    NAICSCode["RESIN_SYNTHETIC_RUBBER_AND_ARTIFICIAL_AND_SYNTHETIC_FIBERS_AND_FILAMENTS_MANUFACTURING"] = "3252";
    NAICSCode["PESTICIDE_FERTILIZER_AND_OTHER_AGRICULTURAL_CHEMICAL_MANUFACTURING"] = "3253";
    NAICSCode["PHARMACEUTICAL_AND_MEDICINE_MANUFACTURING"] = "3254";
    NAICSCode["PAINT_COATING_AND_ADHESIVE_MANUFACTURING"] = "3255";
    NAICSCode["SOAP_CLEANING_COMPOUND_AND_TOILET_PREPARATION_MANUFACTURING"] = "3256";
    NAICSCode["OTHER_CHEMICAL_PRODUCT_AND_PREPARATION_MANUFACTURING"] = "3259";
    NAICSCode["PLASTICS_PRODUCT_MANUFACTURING"] = "3261";
    NAICSCode["RUBBER_PRODUCT_MANUFACTURING"] = "3262";
    NAICSCode["CLAY_PRODUCT_AND_REFRACTORY_MANUFACTURING"] = "3271";
    NAICSCode["GLASS_AND_GLASS_PRODUCT_MANUFACTURING"] = "3272";
    NAICSCode["CEMENT_AND_CONCRETE_PRODUCT_MANUFACTURING"] = "3273";
    NAICSCode["LIME_AND_GYPSUM_PRODUCT_MANUFACTURING"] = "3274";
    NAICSCode["OTHER_NONMETALLIC_MINERAL_PRODUCT_MANUFACTURING"] = "3279";
    NAICSCode["IRON_AND_STEEL_MILLS_AND_FERROALLOY_MANUFACTURING"] = "3311";
    NAICSCode["STEEL_PRODUCT_MANUFACTURING_FROM_PURCHASED_STEEL"] = "3312";
    NAICSCode["ALUMINA_AND_ALUMINUM_PRODUCTION_AND_PROCESSING"] = "3313";
    NAICSCode["NONFERROUS_METAL_EXCEPT_ALUMINUM_PRODUCTION_AND_PROCESSING"] = "3314";
    NAICSCode["FOUNDRIES"] = "3315";
    NAICSCode["FORGING_AND_STAMPING"] = "3321";
    NAICSCode["CUTLERY_AND_HANDTOOL_MANUFACTURING"] = "3322";
    NAICSCode["ARCHITECTURAL_AND_STRUCTURAL_METALS_MANUFACTURING"] = "3323";
    NAICSCode["BOILER_TANK_AND_SHIPPING_CONTAINER_MANUFACTURING"] = "3324";
    NAICSCode["HARDWARE_MANUFACTURING"] = "3325";
    NAICSCode["SPRING_AND_WIRE_PRODUCT_MANUFACTURING"] = "3326";
    NAICSCode["MACHINE_SHOPS_TURNED_PRODUCT_AND_SCREW_NUT_AND_BOLT_MANUFACTURING"] = "3327";
    NAICSCode["COATING_ENGRAVING_HEAT_TREATING_AND_ALLIED_ACTIVITIES"] = "3328";
    NAICSCode["OTHER_FABRICATED_METAL_PRODUCT_MANUFACTURING"] = "3329";
    NAICSCode["AGRICULTURE_CONSTRUCTION_AND_MINING_MACHINERY_MANUFACTURING"] = "3331";
    NAICSCode["INDUSTRIAL_MACHINERY_MANUFACTURING"] = "3332";
    NAICSCode["COMMERCIAL_AND_SERVICE_INDUSTRY_MACHINERY_MANUFACTURING"] = "3333";
    NAICSCode["VENTILATION_HEATING_AIRCONDITIONING_AND_COMMERCIAL_REFRIGERATION_EQUIPMENT_MANUFACTURING"] = "3334";
    NAICSCode["METALWORKING_MACHINERY_MANUFACTURING"] = "3335";
    NAICSCode["ENGINE_TURBINE_AND_POWER_TRANSMISSION_EQUIPMENT_MANUFACTURING"] = "3336";
    NAICSCode["OTHER_GENERAL_PURPOSE_MACHINERY_MANUFACTURING"] = "3339";
    NAICSCode["COMPUTER_AND_PERIPHERAL_EQUIPMENT_MANUFACTURING"] = "3341";
    NAICSCode["COMMUNICATIONS_EQUIPMENT_MANUFACTURING"] = "3342";
    NAICSCode["AUDIO_AND_VIDEO_EQUIPMENT_MANUFACTURING"] = "3343";
    NAICSCode["SEMICONDUCTOR_AND_OTHER_ELECTRONIC_COMPONENT_MANUFACTURING"] = "3344";
    NAICSCode["NAVIGATIONAL_MEASURING_ELECTROMEDICAL_AND_CONTROL_INSTRUMENTS_MANUFACTURING"] = "3345";
    NAICSCode["MANUFACTURING_AND_REPRODUCING_MAGNETIC_AND_OPTICAL_MEDIA"] = "3346";
    NAICSCode["ELECTRIC_LIGHTING_EQUIPMENT_MANUFACTURING"] = "3351";
    NAICSCode["HOUSEHOLD_APPLIANCE_MANUFACTURING"] = "3352";
    NAICSCode["ELECTRICAL_EQUIPMENT_MANUFACTURING"] = "3353";
    NAICSCode["OTHER_ELECTRICAL_EQUIPMENT_AND_COMPONENT_MANUFACTURING"] = "3359";
    NAICSCode["MOTOR_VEHICLE_MANUFACTURING"] = "3361";
    NAICSCode["MOTOR_VEHICLE_BODY_AND_TRAILER_MANUFACTURING"] = "3362";
    NAICSCode["MOTOR_VEHICLE_PARTS_MANUFACTURING"] = "3363";
    NAICSCode["AEROSPACE_PRODUCT_AND_PARTS_MANUFACTURING"] = "3364";
    NAICSCode["RAILROAD_ROLLING_STOCK_MANUFACTURING"] = "3365";
    NAICSCode["SHIP_AND_BOAT_BUILDING"] = "3366";
    NAICSCode["OTHER_TRANSPORTATION_EQUIPMENT_MANUFACTURING"] = "3369";
    NAICSCode["HOUSEHOLD_AND_INSTITUTIONAL_FURNITURE_AND_KITCHEN_CABINET_MANUFACTURING"] = "3371";
    NAICSCode["OFFICE_FURNITURE_INCLUDING_FIXTURES_MANUFACTURING"] = "3372";
    NAICSCode["OTHER_FURNITURE_RELATED_PRODUCT_MANUFACTURING"] = "3379";
    NAICSCode["MEDICAL_EQUIPMENT_AND_SUPPLIES_MANUFACTURING"] = "3391";
    NAICSCode["OTHER_MISCELLANEOUS_MANUFACTURING"] = "3399";
    NAICSCode["MOTOR_VEHICLE_AND_MOTOR_VEHICLE_PARTS_AND_SUPPLIES_MERCHANT_WHOLESALERS"] = "4231";
    NAICSCode["FURNITURE_AND_HOME_FURNISHING_MERCHANT_WHOLESALERS"] = "4232";
    NAICSCode["LUMBER_AND_OTHER_CONSTRUCTION_MATERIALS_MERCHANT_WHOLESALERS"] = "4233";
    NAICSCode["PROFESSIONAL_AND_COMMERCIAL_EQUIPMENT_AND_SUPPLIES_MERCHANT_WHOLESALERS"] = "4234";
    NAICSCode["METAL_AND_MINERAL_EXCEPT_PETROLEUM_MERCHANT_WHOLESALERS"] = "4235";
    NAICSCode["HOUSEHOLD_APPLIANCES_AND_ELECTRICAL_AND_ELECTRONIC_GOODS_MERCHANT_WHOLESALERS"] = "4236";
    NAICSCode["HARDWARE_AND_PLUMBING_AND_HEATING_EQUIPMENT_AND_SUPPLIES_MERCHANT_WHOLESALERS"] = "4237";
    NAICSCode["MACHINERY_EQUIPMENT_AND_SUPPLIES_MERCHANT_WHOLESALERS"] = "4238";
    NAICSCode["MISCELLANEOUS_DURABLE_GOODS_MERCHANT_WHOLESALERS"] = "4239";
    NAICSCode["PAPER_AND_PAPER_PRODUCT_MERCHANT_WHOLESALERS"] = "4241";
    NAICSCode["DRUGS_AND_DRUGGISTS_SUNDRIES_MERCHANT_WHOLESALERS"] = "4242";
    NAICSCode["APPAREL_PIECE_GOODS_AND_NOTIONS_MERCHANT_WHOLESALERS"] = "4243";
    NAICSCode["GROCERY_AND_RELATED_PRODUCT_MERCHANT_WHOLESALERS"] = "4244";
    NAICSCode["FARM_PRODUCT_RAW_MATERIAL_MERCHANT_WHOLESALERS"] = "4245";
    NAICSCode["CHEMICAL_AND_ALLIED_PRODUCTS_MERCHANT_WHOLESALERS"] = "4246";
    NAICSCode["PETROLEUM_AND_PETROLEUM_PRODUCTS_MERCHANT_WHOLESALERS"] = "4247";
    NAICSCode["BEER_WINE_AND_DISTILLED_ALCOHOLIC_BEVERAGE_MERCHANT_WHOLESALERS"] = "4248";
    NAICSCode["MISCELLANEOUS_NONDURABLE_GOODS_MERCHANT_WHOLESALERS"] = "4249";
    NAICSCode["WHOLESALE_ELECTRONIC_MARKETS_AND_AGENTS_AND_BROKERS"] = "4251";
    NAICSCode["AUTOMOBILE_DEALERS"] = "4411";
    NAICSCode["OTHER_MOTOR_VEHICLE_DEALERS"] = "4412";
    NAICSCode["AUTOMOTIVE_PARTS_ACCESSORIES_AND_TIRE_STORES"] = "4413";
    NAICSCode["FURNITURE_STORES"] = "4421";
    NAICSCode["HOME_FURNISHINGS_STORES"] = "4422";
    NAICSCode["ELECTRONICS_AND_APPLIANCE_STORES"] = "4431";
    NAICSCode["BUILDING_MATERIAL_AND_SUPPLIES_DEALERS"] = "4441";
    NAICSCode["LAWN_AND_GARDEN_EQUIPMENT_AND_SUPPLIES_STORES"] = "4442";
    NAICSCode["GROCERY_STORES"] = "4451";
    NAICSCode["SPECIALTY_FOOD_STORES"] = "4452";
    NAICSCode["BEER_WINE_AND_LIQUOR_STORES"] = "4453";
    NAICSCode["HEALTH_AND_PERSONAL_CARE_STORES"] = "4461";
    NAICSCode["GASOLINE_STATIONS"] = "4471";
    NAICSCode["CLOTHING_STORES"] = "4481";
    NAICSCode["SHOE_STORES"] = "4482";
    NAICSCode["JEWELRY_LUGGAGE_AND_LEATHER_GOODS_STORES"] = "4483";
    NAICSCode["SPORTING_GOODS_HOBBY_AND_MUSICAL_INSTRUMENT_STORES"] = "4511";
    NAICSCode["BOOK_STORES_AND_NEWS_DEALERS"] = "4512";
    NAICSCode["DEPARTMENT_STORES"] = "4522";
    NAICSCode["GENERAL_MERCHANDISE_STORES_INCLUDING_WAREHOUSE_CLUBS_AND_SUPERCENTERS"] = "4523";
    NAICSCode["FLORISTS"] = "4531";
    NAICSCode["OFFICE_SUPPLIES_STATIONERY_AND_GIFT_STORES"] = "4532";
    NAICSCode["USED_MERCHANDISE_STORES"] = "4533";
    NAICSCode["OTHER_MISCELLANEOUS_STORE_RETAILERS"] = "4539";
    NAICSCode["ELECTRONIC_SHOPPING_AND_MAILORDER_HOUSES"] = "4541";
    NAICSCode["VENDING_MACHINE_OPERATORS"] = "4542";
    NAICSCode["DIRECT_SELLING_ESTABLISHMENTS"] = "4543";
    NAICSCode["SCHEDULED_AIR_TRANSPORTATION"] = "4811";
    NAICSCode["NONSCHEDULED_AIR_TRANSPORTATION"] = "4812";
    NAICSCode["RAIL_TRANSPORTATION"] = "4821";
    NAICSCode["DEEP_SEA_COASTAL_AND_GREAT_LAKES_WATER_TRANSPORTATION"] = "4831";
    NAICSCode["INLAND_WATER_TRANSPORTATION"] = "4832";
    NAICSCode["GENERAL_FREIGHT_TRUCKING"] = "4841";
    NAICSCode["SPECIALIZED_FREIGHT_TRUCKING"] = "4842";
    NAICSCode["URBAN_TRANSIT_SYSTEMS"] = "4851";
    NAICSCode["INTERURBAN_AND_RURAL_BUS_TRANSPORTATION"] = "4852";
    NAICSCode["TAXI_AND_LIMOUSINE_SERVICE"] = "4853";
    NAICSCode["SCHOOL_AND_EMPLOYEE_BUS_TRANSPORTATION"] = "4854";
    NAICSCode["CHARTER_BUS_INDUSTRY"] = "4855";
    NAICSCode["OTHER_TRANSIT_AND_GROUND_PASSENGER_TRANSPORTATION"] = "4859";
    NAICSCode["PIPELINE_TRANSPORTATION_OF_CRUDE_OIL"] = "4861";
    NAICSCode["PIPELINE_TRANSPORTATION_OF_NATURAL_GAS"] = "4862";
    NAICSCode["OTHER_PIPELINE_TRANSPORTATION"] = "4869";
    NAICSCode["SCENIC_AND_SIGHTSEEING_TRANSPORTATION_LAND"] = "4871";
    NAICSCode["SCENIC_AND_SIGHTSEEING_TRANSPORTATION_WATER"] = "4872";
    NAICSCode["SCENIC_AND_SIGHTSEEING_TRANSPORTATION_OTHER"] = "4879";
    NAICSCode["SUPPORT_ACTIVITIES_FOR_AIR_TRANSPORTATION"] = "4881";
    NAICSCode["SUPPORT_ACTIVITIES_FOR_RAIL_TRANSPORTATION"] = "4882";
    NAICSCode["SUPPORT_ACTIVITIES_FOR_WATER_TRANSPORTATION"] = "4883";
    NAICSCode["SUPPORT_ACTIVITIES_FOR_ROAD_TRANSPORTATION"] = "4884";
    NAICSCode["FREIGHT_TRANSPORTATION_ARRANGEMENT"] = "4885";
    NAICSCode["OTHER_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION"] = "4889";
    NAICSCode["POSTAL_SERVICE"] = "4911";
    NAICSCode["COURIERS_AND_EXPRESS_DELIVERY_SERVICES"] = "4921";
    NAICSCode["LOCAL_MESSENGERS_AND_LOCAL_DELIVERY"] = "4922";
    NAICSCode["WAREHOUSING_AND_STORAGE"] = "4931";
    NAICSCode["NEWSPAPER_PERIODICAL_BOOK_AND_DIRECTORY_PUBLISHERS"] = "5111";
    NAICSCode["SOFTWARE_PUBLISHERS"] = "5112";
    NAICSCode["MOTION_PICTURE_AND_VIDEO_INDUSTRIES"] = "5121";
    NAICSCode["SOUND_RECORDING_INDUSTRIES"] = "5122";
    NAICSCode["RADIO_AND_TELEVISION_BROADCASTING"] = "5151";
    NAICSCode["CABLE_AND_OTHER_SUBSCRIPTION_PROGRAMMING"] = "5152";
    NAICSCode["WIRED_AND_WIRELESS_TELECOMMUNICATIONS_CARRIERS"] = "5173";
    NAICSCode["SATELLITE_TELECOMMUNICATIONS"] = "5174";
    NAICSCode["OTHER_TELECOMMUNICATIONS"] = "5179";
    NAICSCode["DATA_PROCESSING_HOSTING_AND_RELATED_SERVICES"] = "5182";
    NAICSCode["OTHER_INFORMATION_SERVICES"] = "5191";
    NAICSCode["MONETARY_AUTHORITIESCENTRAL_BANK"] = "5211";
    NAICSCode["DEPOSITORY_CREDIT_INTERMEDIATION"] = "5221";
    NAICSCode["NONDEPOSITORY_CREDIT_INTERMEDIATION"] = "5222";
    NAICSCode["ACTIVITIES_RELATED_TO_CREDIT_INTERMEDIATION"] = "5223";
    NAICSCode["SECURITIES_AND_COMMODITY_CONTRACTS_INTERMEDIATION_AND_BROKERAGE"] = "5231";
    NAICSCode["SECURITIES_AND_COMMODITY_EXCHANGES"] = "5232";
    NAICSCode["OTHER_FINANCIAL_INVESTMENT_ACTIVITIES"] = "5239";
    NAICSCode["INSURANCE_CARRIERS"] = "5241";
    NAICSCode["AGENCIES_BROKERAGES_AND_OTHER_INSURANCE_RELATED_ACTIVITIES"] = "5242";
    NAICSCode["INSURANCE_AND_EMPLOYEE_BENEFIT_FUNDS"] = "5251";
    NAICSCode["OTHER_INVESTMENT_POOLS_AND_FUNDS"] = "5259";
    NAICSCode["LESSORS_OF_REAL_ESTATE"] = "5311";
    NAICSCode["OFFICES_OF_REAL_ESTATE_AGENTS_AND_BROKERS"] = "5312";
    NAICSCode["ACTIVITIES_RELATED_TO_REAL_ESTATE"] = "5313";
    NAICSCode["AUTOMOTIVE_EQUIPMENT_RENTAL_AND_LEASING"] = "5321";
    NAICSCode["CONSUMER_GOODS_RENTAL"] = "5322";
    NAICSCode["GENERAL_RENTAL_CENTERS"] = "5323";
    NAICSCode["COMMERCIAL_AND_INDUSTRIAL_MACHINERY_AND_EQUIPMENT_RENTAL_AND_LEASING"] = "5324";
    NAICSCode["LESSORS_OF_NONFINANCIAL_INTANGIBLE_ASSETS_EXCEPT_COPYRIGHTED_WORKS"] = "5331";
    NAICSCode["LEGAL_SERVICES"] = "5411";
    NAICSCode["ACCOUNTING_TAX_PREPARATION_BOOKKEEPING_AND_PAYROLL_SERVICES"] = "5412";
    NAICSCode["ARCHITECTURAL_ENGINEERING_AND_RELATED_SERVICES"] = "5413";
    NAICSCode["SPECIALIZED_DESIGN_SERVICES"] = "5414";
    NAICSCode["COMPUTER_SYSTEMS_DESIGN_AND_RELATED_SERVICES"] = "5415";
    NAICSCode["MANAGEMENT_SCIENTIFIC_AND_TECHNICAL_CONSULTING_SERVICES"] = "5416";
    NAICSCode["SCIENTIFIC_RESEARCH_AND_DEVELOPMENT_SERVICES"] = "5417";
    NAICSCode["ADVERTISING_PUBLIC_RELATIONS_AND_RELATED_SERVICES"] = "5418";
    NAICSCode["OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES"] = "5419";
    NAICSCode["MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES"] = "5511";
    NAICSCode["OFFICE_ADMINISTRATIVE_SERVICES"] = "5611";
    NAICSCode["FACILITIES_SUPPORT_SERVICES"] = "5612";
    NAICSCode["EMPLOYMENT_SERVICES"] = "5613";
    NAICSCode["BUSINESS_SUPPORT_SERVICES"] = "5614";
    NAICSCode["TRAVEL_ARRANGEMENT_AND_RESERVATION_SERVICES"] = "5615";
    NAICSCode["INVESTIGATION_AND_SECURITY_SERVICES"] = "5616";
    NAICSCode["SERVICES_TO_BUILDINGS_AND_DWELLINGS"] = "5617";
    NAICSCode["OTHER_SUPPORT_SERVICES"] = "5619";
    NAICSCode["WASTE_COLLECTION"] = "5621";
    NAICSCode["WASTE_TREATMENT_AND_DISPOSAL"] = "5622";
    NAICSCode["REMEDIATION_AND_OTHER_WASTE_MANAGEMENT_SERVICES"] = "5629";
    NAICSCode["ELEMENTARY_AND_SECONDARY_SCHOOLS"] = "6111";
    NAICSCode["JUNIOR_COLLEGES"] = "6112";
    NAICSCode["COLLEGES_UNIVERSITIES_AND_PROFESSIONAL_SCHOOLS"] = "6113";
    NAICSCode["BUSINESS_SCHOOLS_AND_COMPUTER_AND_MANAGEMENT_TRAINING"] = "6114";
    NAICSCode["TECHNICAL_AND_TRADE_SCHOOLS"] = "6115";
    NAICSCode["OTHER_SCHOOLS_AND_INSTRUCTION"] = "6116";
    NAICSCode["EDUCATIONAL_SUPPORT_SERVICES"] = "6117";
    NAICSCode["OFFICES_OF_PHYSICIANS"] = "6211";
    NAICSCode["OFFICES_OF_DENTISTS"] = "6212";
    NAICSCode["OFFICES_OF_OTHER_HEALTH_PRACTITIONERS"] = "6213";
    NAICSCode["OUTPATIENT_CARE_CENTERS"] = "6214";
    NAICSCode["MEDICAL_AND_DIAGNOSTIC_LABORATORIES"] = "6215";
    NAICSCode["HOME_HEALTH_CARE_SERVICES"] = "6216";
    NAICSCode["OTHER_AMBULATORY_HEALTH_CARE_SERVICES"] = "6219";
    NAICSCode["GENERAL_MEDICAL_AND_SURGICAL_HOSPITALS"] = "6221";
    NAICSCode["PSYCHIATRIC_AND_SUBSTANCE_ABUSE_HOSPITALS"] = "6222";
    NAICSCode["SPECIALTY_EXCEPT_PSYCHIATRIC_AND_SUBSTANCE_ABUSE_HOSPITALS"] = "6223";
    NAICSCode["NURSING_CARE_FACILITIES_SKILLED_NURSING_FACILITIES"] = "6231";
    NAICSCode["RESIDENTIAL_INTELLECTUAL_AND_DEVELOPMENTAL_DISABILITY_MENTAL_HEALTH_AND_SUBSTANCE_ABUSE_FACILITIES"] = "6232";
    NAICSCode["CONTINUING_CARE_RETIREMENT_COMMUNITIES_AND_ASSISTED_LIVING_FACILITIES_FOR_THE_ELDERLY"] = "6233";
    NAICSCode["OTHER_RESIDENTIAL_CARE_FACILITIES"] = "6239";
    NAICSCode["INDIVIDUAL_AND_FAMILY_SERVICES"] = "6241";
    NAICSCode["COMMUNITY_FOOD_AND_HOUSING_AND_EMERGENCY_AND_OTHER_RELIEF_SERVICES"] = "6242";
    NAICSCode["VOCATIONAL_REHABILITATION_SERVICES"] = "6243";
    NAICSCode["CHILD_DAY_CARE_SERVICES"] = "6244";
    NAICSCode["PERFORMING_ARTS_COMPANIES"] = "7111";
    NAICSCode["SPECTATOR_SPORTS"] = "7112";
    NAICSCode["PROMOTERS_OF_PERFORMING_ARTS_SPORTS_AND_SIMILAR_EVENTS"] = "7113";
    NAICSCode["AGENTS_AND_MANAGERS_FOR_ARTISTS_ATHLETES_ENTERTAINERS_AND_OTHER_PUBLIC_FIGURES"] = "7114";
    NAICSCode["INDEPENDENT_ARTISTS_WRITERS_AND_PERFORMERS"] = "7115";
    NAICSCode["MUSEUMS_HISTORICAL_SITES_AND_SIMILAR_INSTITUTIONS"] = "7121";
    NAICSCode["AMUSEMENT_PARKS_AND_ARCADES"] = "7131";
    NAICSCode["GAMBLING_INDUSTRIES"] = "7132";
    NAICSCode["OTHER_AMUSEMENT_AND_RECREATION_INDUSTRIES"] = "7139";
    NAICSCode["TRAVELER_ACCOMMODATION"] = "7211";
    NAICSCode["RV_RECREATIONAL_VEHICLE_PARKS_AND_RECREATIONAL_CAMPS"] = "7212";
    NAICSCode["ROOMING_AND_BOARDING_HOUSES_DORMITORIES_AND_WORKERS_CAMPS"] = "7213";
    NAICSCode["SPECIAL_FOOD_SERVICES"] = "7223";
    NAICSCode["DRINKING_PLACES_ALCOHOLIC_BEVERAGES"] = "7224";
    NAICSCode["RESTAURANTS_AND_OTHER_EATING_PLACES"] = "7225";
    NAICSCode["AUTOMOTIVE_REPAIR_AND_MAINTENANCE"] = "8111";
    NAICSCode["ELECTRONIC_AND_PRECISION_EQUIPMENT_REPAIR_AND_MAINTENANCE"] = "8112";
    NAICSCode["COMMERCIAL_AND_INDUSTRIAL_MACHINERY_AND_EQUIPMENT_EXCEPT_AUTOMOTIVE_AND_ELECTRONIC_REPAIR_AND_MAINTENANCE"] = "8113";
    NAICSCode["PERSONAL_AND_HOUSEHOLD_GOODS_REPAIR_AND_MAINTENANCE"] = "8114";
    NAICSCode["PERSONAL_CARE_SERVICES"] = "8121";
    NAICSCode["DEATH_CARE_SERVICES"] = "8122";
    NAICSCode["DRYCLEANING_AND_LAUNDRY_SERVICES"] = "8123";
    NAICSCode["OTHER_PERSONAL_SERVICES"] = "8129";
    NAICSCode["RELIGIOUS_ORGANIZATIONS"] = "8131";
    NAICSCode["GRANTMAKING_AND_GIVING_SERVICES"] = "8132";
    NAICSCode["SOCIAL_ADVOCACY_ORGANIZATIONS"] = "8133";
    NAICSCode["CIVIC_AND_SOCIAL_ORGANIZATIONS"] = "8134";
    NAICSCode["BUSINESS_PROFESSIONAL_LABOR_POLITICAL_AND_SIMILAR_ORGANIZATIONS"] = "8139";
    NAICSCode["PRIVATE_HOUSEHOLDS"] = "8141";
    NAICSCode["EXECUTIVE_LEGISLATIVE_AND_OTHER_GENERAL_GOVERNMENT_SUPPORT"] = "9211";
    NAICSCode["JUSTICE_PUBLIC_ORDER_AND_SAFETY_ACTIVITIES"] = "9221";
    NAICSCode["ADMINISTRATION_OF_HUMAN_RESOURCE_PROGRAMS"] = "9231";
    NAICSCode["ADMINISTRATION_OF_ENVIRONMENTAL_QUALITY_PROGRAMS"] = "9241";
    NAICSCode["ADMINISTRATION_OF_HOUSING_PROGRAMS_URBAN_PLANNING_AND_COMMUNITY_DEVELOPMENT"] = "9251";
    NAICSCode["ADMINISTRATION_OF_ECONOMIC_PROGRAMS"] = "9261";
    NAICSCode["SPACE_RESEARCH_AND_TECHNOLOGY"] = "9271";
    NAICSCode["NATIONAL_SECURITY_AND_INTERNATIONAL_AFFAIRS"] = "9281";
    NAICSCode["INTERNET_PUBLISHING_AND_BROADCASTING_AND_WEB_SEARCH_PORTALS"] = "519130";
    NAICSCode["COSMETICS_BEAUTY_SUPPLIES_AND_PERFUME_STORES"] = "446120";
    NAICSCode["PORTFOLIO_MANAGEMENT"] = "523920";
    NAICSCode["FOOTWEAR_MANUFACTURING_6D"] = "316210";
})(NAICSCode || (NAICSCode = {}));
const codeToNAICSTitleMap = new Map([
    [NAICSCode.SECTOR_AGRICULTURE_FORESTRY_FISHING_AND_HUNTING, 'Agriculture, Forestry, Fishing and Hunting'],
    [NAICSCode.SECTOR_MINING_QUARRYING_AND_OIL_AND_GAS_EXTRACTION, 'Mining, Quarrying, and Oil and Gas Extraction'],
    [NAICSCode.SECTOR_UTILITIES, 'Utilities'],
    [NAICSCode.SECTOR_CONSTRUCTION, 'Construction'],
    [NAICSCode.SECTOR_MANUFACTURING_1, 'Manufacturing'],
    [NAICSCode.SECTOR_MANUFACTURING_2, 'Manufacturing'],
    [NAICSCode.SECTOR_MANUFACTURING_3, 'Manufacturing'],
    [NAICSCode.SECTOR_WHOLESALE_TRADE_1, 'Wholesale Trade'],
    [NAICSCode.SECTOR_WHOLESALE_TRADE_2, 'Wholesale Trade'],
    [NAICSCode.SECTOR_RETAIL_TRADE_1, 'Retail Trade'],
    [NAICSCode.SECTOR_RETAIL_TRADE_2, 'Retail Trade'],
    [NAICSCode.SECTOR_TRANSPORTATION_AND_WAREHOUSING_1, 'Transportation and Warehousing'],
    [NAICSCode.SECTOR_TRANSPORTATION_AND_WAREHOUSING_2, 'Transportation and Warehousing'],
    [NAICSCode.SECTOR_INFORMATION, 'Information'],
    [NAICSCode.SECTOR_FINANCE_AND_INSURANCE, 'Finance and Insurance'],
    [NAICSCode.SECTOR_REAL_ESTATE_AND_RENTAL_AND_LEASING, 'Real Estate and Rental and Leasing'],
    [NAICSCode.SECTOR_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES, 'Professional, Scientific, and Technical Services'],
    [NAICSCode.SECTOR_MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES, 'Management of Companies and Enterprises'],
    [
        NAICSCode.SECTOR_ADMINISTRATIVE_AND_SUPPORT_AND_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES,
        'Administrative and Support and Waste Management and Remediation Services',
    ],
    [NAICSCode.SECTOR_EDUCATIONAL_SERVICES, 'Educational Services'],
    [NAICSCode.SECTOR_HEALTH_CARE_AND_SOCIAL_ASSISTANCE, 'Health Care and Social Assistance'],
    [NAICSCode.SECTOR_ARTS_ENTERTAINMENT_AND_RECREATION, 'Arts, Entertainment, and Recreation'],
    [NAICSCode.SECTOR_ACCOMMODATION_AND_FOOD_SERVICES, 'Accommodation and Food Services'],
    [NAICSCode.SECTOR_OTHER_SERVICES_EXCEPT_PUBLIC_ADMINISTRATION, 'Other Services (except Public Administration)'],
    [NAICSCode.SECTOR_PUBLIC_ADMINISTRATION_1, 'Public Administration'],
    [NAICSCode.SECTOR_PUBLIC_ADMINISTRATION_2, 'Public Administration'],
    [NAICSCode.OILSEED_AND_GRAIN_FARMING, 'Oilseed and Grain Farming'],
    [NAICSCode.VEGETABLE_AND_MELON_FARMING, 'Vegetable and Melon Farming'],
    [NAICSCode.FRUIT_AND_TREE_NUT_FARMING, 'Fruit and Tree Nut Farming'],
    [NAICSCode.GREENHOUSE_NURSERY_AND_FLORICULTURE_PRODUCTION, 'Greenhouse, Nursery, and Floriculture Production'],
    [NAICSCode.OTHER_CROP_FARMING, 'Other Crop Farming'],
    [NAICSCode.CATTLE_RANCHING_AND_FARMING, 'Cattle Ranching and Farming'],
    [NAICSCode.HOG_AND_PIG_FARMING, 'Hog and Pig Farming'],
    [NAICSCode.POULTRY_AND_EGG_PRODUCTION, 'Poultry and Egg Production'],
    [NAICSCode.SHEEP_AND_GOAT_FARMING, 'Sheep and Goat Farming'],
    [NAICSCode.AQUACULTURE, 'Aquaculture'],
    [NAICSCode.OTHER_ANIMAL_PRODUCTION, 'Other Animal Production'],
    [NAICSCode.TIMBER_TRACT_OPERATIONS, 'Timber Tract Operations'],
    [NAICSCode.FOREST_NURSERIES_AND_GATHERING_OF_FOREST_PRODUCTS, 'Forest Nurseries and Gathering of Forest Products'],
    [NAICSCode.LOGGING, 'Logging'],
    [NAICSCode.FISHING, 'Fishing'],
    [NAICSCode.HUNTING_AND_TRAPPING, 'Hunting and Trapping'],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_CROP_PRODUCTION, 'Support Activities for Crop Production'],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_ANIMAL_PRODUCTION, 'Support Activities for Animal Production'],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_FORESTRY, 'Support Activities for Forestry'],
    [NAICSCode.OIL_AND_GAS_EXTRACTION, 'Oil and Gas Extraction'],
    [NAICSCode.COAL_MINING, 'Coal Mining'],
    [NAICSCode.METAL_ORE_MINING, 'Metal Ore Mining'],
    [NAICSCode.NONMETALLIC_MINERAL_MINING_AND_QUARRYING, 'Nonmetallic Mineral Mining and Quarrying'],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_MINING, 'Support Activities for Mining'],
    [
        NAICSCode.ELECTRIC_POWER_GENERATION_TRANSMISSION_AND_DISTRIBUTION,
        'Electric Power Generation, Transmission and Distribution',
    ],
    [NAICSCode.NATURAL_GAS_DISTRIBUTION, 'Natural Gas Distribution'],
    [NAICSCode.WATER_SEWAGE_AND_OTHER_SYSTEMS, 'Water, Sewage and Other Systems'],
    [NAICSCode.RESIDENTIAL_BUILDING_CONSTRUCTION, 'Residential Building Construction'],
    [NAICSCode.NONRESIDENTIAL_BUILDING_CONSTRUCTION, 'Nonresidential Building Construction'],
    [NAICSCode.UTILITY_SYSTEM_CONSTRUCTION, 'Utility System Construction'],
    [NAICSCode.LAND_SUBDIVISION, 'Land Subdivision'],
    [NAICSCode.HIGHWAY_STREET_AND_BRIDGE_CONSTRUCTION, 'Highway, Street, and Bridge Construction'],
    [NAICSCode.OTHER_HEAVY_AND_CIVIL_ENGINEERING_CONSTRUCTION, 'Other Heavy and Civil Engineering Construction'],
    [
        NAICSCode.FOUNDATION_STRUCTURE_AND_BUILDING_EXTERIOR_CONTRACTORS,
        'Foundation, Structure, and Building Exterior Contractors',
    ],
    [NAICSCode.BUILDING_EQUIPMENT_CONTRACTORS, 'Building Equipment Contractors'],
    [NAICSCode.BUILDING_FINISHING_CONTRACTORS, 'Building Finishing Contractors'],
    [NAICSCode.OTHER_SPECIALTY_TRADE_CONTRACTORS, 'Other Specialty Trade Contractors'],
    [NAICSCode.ANIMAL_FOOD_MANUFACTURING, 'Animal Food Manufacturing'],
    [NAICSCode.GRAIN_AND_OILSEED_MILLING, 'Grain and Oilseed Milling'],
    [NAICSCode.SUGAR_AND_CONFECTIONERY_PRODUCT_MANUFACTURING, 'Sugar and Confectionery Product Manufacturing'],
    [
        NAICSCode.FRUIT_AND_VEGETABLE_PRESERVING_AND_SPECIALTY_FOOD_MANUFACTURING,
        'Fruit and Vegetable Preserving and Specialty Food Manufacturing',
    ],
    [NAICSCode.DAIRY_PRODUCT_MANUFACTURING, 'Dairy Product Manufacturing'],
    [NAICSCode.ANIMAL_SLAUGHTERING_AND_PROCESSING, 'Animal Slaughtering and Processing'],
    [NAICSCode.SEAFOOD_PRODUCT_PREPARATION_AND_PACKAGING, 'Seafood Product Preparation and Packaging'],
    [NAICSCode.BAKERIES_AND_TORTILLA_MANUFACTURING, 'Bakeries and Tortilla Manufacturing'],
    [NAICSCode.OTHER_FOOD_MANUFACTURING, 'Other Food Manufacturing'],
    [NAICSCode.BEVERAGE_MANUFACTURING, 'Beverage Manufacturing'],
    [NAICSCode.TOBACCO_MANUFACTURING, 'Tobacco Manufacturing'],
    [NAICSCode.FIBER_YARN_AND_THREAD_MILLS, 'Fiber, Yarn, and Thread Mills'],
    [NAICSCode.FABRIC_MILLS, 'Fabric Mills'],
    [
        NAICSCode.TEXTILE_AND_FABRIC_FINISHING_AND_FABRIC_COATING_MILLS,
        'Textile and Fabric Finishing and Fabric Coating Mills',
    ],
    [NAICSCode.TEXTILE_FURNISHINGS_MILLS, 'Textile Furnishings Mills'],
    [NAICSCode.OTHER_TEXTILE_PRODUCT_MILLS, 'Other Textile Product Mills'],
    [NAICSCode.APPAREL_KNITTING_MILLS, 'Apparel Knitting Mills'],
    [NAICSCode.CUT_AND_SEW_APPAREL_MANUFACTURING, 'Cut and Sew Apparel Manufacturing'],
    [
        NAICSCode.APPAREL_ACCESSORIES_AND_OTHER_APPAREL_MANUFACTURING,
        'Apparel Accessories and Other Apparel Manufacturing',
    ],
    [NAICSCode.LEATHER_AND_HIDE_TANNING_AND_FINISHING, 'Leather and Hide Tanning and Finishing'],
    [NAICSCode.FOOTWEAR_MANUFACTURING, 'Footwear Manufacturing'],
    [NAICSCode.OTHER_LEATHER_AND_ALLIED_PRODUCT_MANUFACTURING, 'Other Leather and Allied Product Manufacturing'],
    [NAICSCode.SAWMILLS_AND_WOOD_PRESERVATION, 'Sawmills and Wood Preservation'],
    [
        NAICSCode.VENEER_PLYWOOD_AND_ENGINEERED_WOOD_PRODUCT_MANUFACTURING,
        'Veneer, Plywood, and Engineered Wood Product Manufacturing',
    ],
    [NAICSCode.OTHER_WOOD_PRODUCT_MANUFACTURING, 'Other Wood Product Manufacturing'],
    [NAICSCode.PULP_PAPER_AND_PAPERBOARD_MILLS, 'Pulp, Paper, and Paperboard Mills'],
    [NAICSCode.CONVERTED_PAPER_PRODUCT_MANUFACTURING, 'Converted Paper Product Manufacturing'],
    [NAICSCode.PRINTING_AND_RELATED_SUPPORT_ACTIVITIES, 'Printing and Related Support Activities'],
    [NAICSCode.PETROLEUM_AND_COAL_PRODUCTS_MANUFACTURING, 'Petroleum and Coal Products Manufacturing'],
    [NAICSCode.BASIC_CHEMICAL_MANUFACTURING, 'Basic Chemical Manufacturing'],
    [
        NAICSCode.RESIN_SYNTHETIC_RUBBER_AND_ARTIFICIAL_AND_SYNTHETIC_FIBERS_AND_FILAMENTS_MANUFACTURING,
        'Resin, Synthetic Rubber, and Artificial and Synthetic Fibers and Filaments Manufacturing',
    ],
    [
        NAICSCode.PESTICIDE_FERTILIZER_AND_OTHER_AGRICULTURAL_CHEMICAL_MANUFACTURING,
        'Pesticide, Fertilizer, and Other Agricultural Chemical Manufacturing',
    ],
    [NAICSCode.PHARMACEUTICAL_AND_MEDICINE_MANUFACTURING, 'Pharmaceutical and Medicine Manufacturing'],
    [NAICSCode.PAINT_COATING_AND_ADHESIVE_MANUFACTURING, 'Paint, Coating, and Adhesive Manufacturing'],
    [
        NAICSCode.SOAP_CLEANING_COMPOUND_AND_TOILET_PREPARATION_MANUFACTURING,
        'Soap, Cleaning Compound, and Toilet Preparation Manufacturing',
    ],
    [
        NAICSCode.OTHER_CHEMICAL_PRODUCT_AND_PREPARATION_MANUFACTURING,
        'Other Chemical Product and Preparation Manufacturing',
    ],
    [NAICSCode.PLASTICS_PRODUCT_MANUFACTURING, 'Plastics Product Manufacturing'],
    [NAICSCode.RUBBER_PRODUCT_MANUFACTURING, 'Rubber Product Manufacturing'],
    [NAICSCode.CLAY_PRODUCT_AND_REFRACTORY_MANUFACTURING, 'Clay Product and Refractory Manufacturing'],
    [NAICSCode.GLASS_AND_GLASS_PRODUCT_MANUFACTURING, 'Glass and Glass Product Manufacturing'],
    [NAICSCode.CEMENT_AND_CONCRETE_PRODUCT_MANUFACTURING, 'Cement and Concrete Product Manufacturing'],
    [NAICSCode.LIME_AND_GYPSUM_PRODUCT_MANUFACTURING, 'Lime and Gypsum Product Manufacturing'],
    [NAICSCode.OTHER_NONMETALLIC_MINERAL_PRODUCT_MANUFACTURING, 'Other Nonmetallic Mineral Product Manufacturing'],
    [NAICSCode.IRON_AND_STEEL_MILLS_AND_FERROALLOY_MANUFACTURING, 'Iron and Steel Mills and Ferroalloy Manufacturing'],
    [NAICSCode.STEEL_PRODUCT_MANUFACTURING_FROM_PURCHASED_STEEL, 'Steel Product Manufacturing from Purchased Steel'],
    [NAICSCode.ALUMINA_AND_ALUMINUM_PRODUCTION_AND_PROCESSING, 'Alumina and Aluminum Production and Processing'],
    [
        NAICSCode.NONFERROUS_METAL_EXCEPT_ALUMINUM_PRODUCTION_AND_PROCESSING,
        'Nonferrous Metal (except Aluminum) Production and Processing',
    ],
    [NAICSCode.FOUNDRIES, 'Foundries'],
    [NAICSCode.FORGING_AND_STAMPING, 'Forging and Stamping'],
    [NAICSCode.CUTLERY_AND_HANDTOOL_MANUFACTURING, 'Cutlery and Handtool Manufacturing'],
    [NAICSCode.ARCHITECTURAL_AND_STRUCTURAL_METALS_MANUFACTURING, 'Architectural and Structural Metals Manufacturing'],
    [NAICSCode.BOILER_TANK_AND_SHIPPING_CONTAINER_MANUFACTURING, 'Boiler, Tank, and Shipping Container Manufacturing'],
    [NAICSCode.HARDWARE_MANUFACTURING, 'Hardware Manufacturing'],
    [NAICSCode.SPRING_AND_WIRE_PRODUCT_MANUFACTURING, 'Spring and Wire Product Manufacturing'],
    [
        NAICSCode.MACHINE_SHOPS_TURNED_PRODUCT_AND_SCREW_NUT_AND_BOLT_MANUFACTURING,
        'Machine Shops; Turned Product; and Screw, Nut, and Bolt Manufacturing',
    ],
    [
        NAICSCode.COATING_ENGRAVING_HEAT_TREATING_AND_ALLIED_ACTIVITIES,
        'Coating, Engraving, Heat Treating, and Allied Activities',
    ],
    [NAICSCode.OTHER_FABRICATED_METAL_PRODUCT_MANUFACTURING, 'Other Fabricated Metal Product Manufacturing'],
    [
        NAICSCode.AGRICULTURE_CONSTRUCTION_AND_MINING_MACHINERY_MANUFACTURING,
        'Agriculture, Construction, and Mining Machinery Manufacturing',
    ],
    [NAICSCode.INDUSTRIAL_MACHINERY_MANUFACTURING, 'Industrial Machinery Manufacturing'],
    [
        NAICSCode.COMMERCIAL_AND_SERVICE_INDUSTRY_MACHINERY_MANUFACTURING,
        'Commercial and Service Industry Machinery Manufacturing',
    ],
    [
        NAICSCode.VENTILATION_HEATING_AIRCONDITIONING_AND_COMMERCIAL_REFRIGERATION_EQUIPMENT_MANUFACTURING,
        'Ventilation, Heating, Air-Conditioning, and Commercial Refrigeration Equipment Manufacturing',
    ],
    [NAICSCode.METALWORKING_MACHINERY_MANUFACTURING, 'Metalworking Machinery Manufacturing'],
    [
        NAICSCode.ENGINE_TURBINE_AND_POWER_TRANSMISSION_EQUIPMENT_MANUFACTURING,
        'Engine, Turbine, and Power Transmission Equipment Manufacturing',
    ],
    [NAICSCode.OTHER_GENERAL_PURPOSE_MACHINERY_MANUFACTURING, 'Other General Purpose Machinery Manufacturing'],
    [NAICSCode.COMPUTER_AND_PERIPHERAL_EQUIPMENT_MANUFACTURING, 'Computer and Peripheral Equipment Manufacturing'],
    [NAICSCode.COMMUNICATIONS_EQUIPMENT_MANUFACTURING, 'Communications Equipment Manufacturing'],
    [NAICSCode.AUDIO_AND_VIDEO_EQUIPMENT_MANUFACTURING, 'Audio and Video Equipment Manufacturing'],
    [
        NAICSCode.SEMICONDUCTOR_AND_OTHER_ELECTRONIC_COMPONENT_MANUFACTURING,
        'Semiconductor and Other Electronic Component Manufacturing',
    ],
    [
        NAICSCode.NAVIGATIONAL_MEASURING_ELECTROMEDICAL_AND_CONTROL_INSTRUMENTS_MANUFACTURING,
        'Navigational, Measuring, Electromedical, and Control Instruments Manufacturing',
    ],
    [
        NAICSCode.MANUFACTURING_AND_REPRODUCING_MAGNETIC_AND_OPTICAL_MEDIA,
        'Manufacturing and Reproducing Magnetic and Optical Media',
    ],
    [NAICSCode.ELECTRIC_LIGHTING_EQUIPMENT_MANUFACTURING, 'Electric Lighting Equipment Manufacturing'],
    [NAICSCode.HOUSEHOLD_APPLIANCE_MANUFACTURING, 'Household Appliance Manufacturing'],
    [NAICSCode.ELECTRICAL_EQUIPMENT_MANUFACTURING, 'Electrical Equipment Manufacturing'],
    [
        NAICSCode.OTHER_ELECTRICAL_EQUIPMENT_AND_COMPONENT_MANUFACTURING,
        'Other Electrical Equipment and Component Manufacturing',
    ],
    [NAICSCode.MOTOR_VEHICLE_MANUFACTURING, 'Motor Vehicle Manufacturing'],
    [NAICSCode.MOTOR_VEHICLE_BODY_AND_TRAILER_MANUFACTURING, 'Motor Vehicle Body and Trailer Manufacturing'],
    [NAICSCode.MOTOR_VEHICLE_PARTS_MANUFACTURING, 'Motor Vehicle Parts Manufacturing'],
    [NAICSCode.AEROSPACE_PRODUCT_AND_PARTS_MANUFACTURING, 'Aerospace Product and Parts Manufacturing'],
    [NAICSCode.RAILROAD_ROLLING_STOCK_MANUFACTURING, 'Railroad Rolling Stock Manufacturing'],
    [NAICSCode.SHIP_AND_BOAT_BUILDING, 'Ship and Boat Building'],
    [NAICSCode.OTHER_TRANSPORTATION_EQUIPMENT_MANUFACTURING, 'Other Transportation Equipment Manufacturing'],
    [
        NAICSCode.HOUSEHOLD_AND_INSTITUTIONAL_FURNITURE_AND_KITCHEN_CABINET_MANUFACTURING,
        'Household and Institutional Furniture and Kitchen Cabinet Manufacturing',
    ],
    [NAICSCode.OFFICE_FURNITURE_INCLUDING_FIXTURES_MANUFACTURING, 'Office Furniture (including Fixtures) Manufacturing'],
    [NAICSCode.OTHER_FURNITURE_RELATED_PRODUCT_MANUFACTURING, 'Other Furniture Related Product Manufacturing'],
    [NAICSCode.MEDICAL_EQUIPMENT_AND_SUPPLIES_MANUFACTURING, 'Medical Equipment and Supplies Manufacturing'],
    [NAICSCode.OTHER_MISCELLANEOUS_MANUFACTURING, 'Other Miscellaneous Manufacturing'],
    [
        NAICSCode.MOTOR_VEHICLE_AND_MOTOR_VEHICLE_PARTS_AND_SUPPLIES_MERCHANT_WHOLESALERS,
        'Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers',
    ],
    [NAICSCode.FURNITURE_AND_HOME_FURNISHING_MERCHANT_WHOLESALERS, 'Furniture and Home Furnishing Merchant Wholesalers'],
    [
        NAICSCode.LUMBER_AND_OTHER_CONSTRUCTION_MATERIALS_MERCHANT_WHOLESALERS,
        'Lumber and Other Construction Materials Merchant Wholesalers',
    ],
    [
        NAICSCode.PROFESSIONAL_AND_COMMERCIAL_EQUIPMENT_AND_SUPPLIES_MERCHANT_WHOLESALERS,
        'Professional and Commercial Equipment and Supplies Merchant Wholesalers',
    ],
    [
        NAICSCode.METAL_AND_MINERAL_EXCEPT_PETROLEUM_MERCHANT_WHOLESALERS,
        'Metal and Mineral (except Petroleum) Merchant Wholesalers',
    ],
    [
        NAICSCode.HOUSEHOLD_APPLIANCES_AND_ELECTRICAL_AND_ELECTRONIC_GOODS_MERCHANT_WHOLESALERS,
        'Household Appliances and Electrical and Electronic Goods Merchant Wholesalers',
    ],
    [
        NAICSCode.HARDWARE_AND_PLUMBING_AND_HEATING_EQUIPMENT_AND_SUPPLIES_MERCHANT_WHOLESALERS,
        'Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers',
    ],
    [
        NAICSCode.MACHINERY_EQUIPMENT_AND_SUPPLIES_MERCHANT_WHOLESALERS,
        'Machinery, Equipment, and Supplies Merchant Wholesalers',
    ],
    [NAICSCode.MISCELLANEOUS_DURABLE_GOODS_MERCHANT_WHOLESALERS, 'Miscellaneous Durable Goods Merchant Wholesalers'],
    [NAICSCode.PAPER_AND_PAPER_PRODUCT_MERCHANT_WHOLESALERS, 'Paper and Paper Product Merchant Wholesalers'],
    [NAICSCode.DRUGS_AND_DRUGGISTS_SUNDRIES_MERCHANT_WHOLESALERS, "Drugs and Druggists' Sundries Merchant Wholesalers"],
    [
        NAICSCode.APPAREL_PIECE_GOODS_AND_NOTIONS_MERCHANT_WHOLESALERS,
        'Apparel, Piece Goods, and Notions Merchant Wholesalers',
    ],
    [NAICSCode.GROCERY_AND_RELATED_PRODUCT_MERCHANT_WHOLESALERS, 'Grocery and Related Product Merchant Wholesalers'],
    [NAICSCode.FARM_PRODUCT_RAW_MATERIAL_MERCHANT_WHOLESALERS, 'Farm Product Raw Material Merchant Wholesalers'],
    [NAICSCode.CHEMICAL_AND_ALLIED_PRODUCTS_MERCHANT_WHOLESALERS, 'Chemical and Allied Products Merchant Wholesalers'],
    [
        NAICSCode.PETROLEUM_AND_PETROLEUM_PRODUCTS_MERCHANT_WHOLESALERS,
        'Petroleum and Petroleum Products Merchant Wholesalers',
    ],
    [
        NAICSCode.BEER_WINE_AND_DISTILLED_ALCOHOLIC_BEVERAGE_MERCHANT_WHOLESALERS,
        'Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers',
    ],
    [
        NAICSCode.MISCELLANEOUS_NONDURABLE_GOODS_MERCHANT_WHOLESALERS,
        'Miscellaneous Nondurable Goods Merchant Wholesalers',
    ],
    [
        NAICSCode.WHOLESALE_ELECTRONIC_MARKETS_AND_AGENTS_AND_BROKERS,
        'Wholesale Electronic Markets and Agents and Brokers',
    ],
    [NAICSCode.AUTOMOBILE_DEALERS, 'Automobile Dealers'],
    [NAICSCode.OTHER_MOTOR_VEHICLE_DEALERS, 'Other Motor Vehicle Dealers'],
    [NAICSCode.AUTOMOTIVE_PARTS_ACCESSORIES_AND_TIRE_STORES, 'Automotive Parts, Accessories, and Tire Stores'],
    [NAICSCode.FURNITURE_STORES, 'Furniture Stores'],
    [NAICSCode.HOME_FURNISHINGS_STORES, 'Home Furnishings Stores'],
    [NAICSCode.ELECTRONICS_AND_APPLIANCE_STORES, 'Electronics and Appliance Stores'],
    [NAICSCode.BUILDING_MATERIAL_AND_SUPPLIES_DEALERS, 'Building Material and Supplies Dealers'],
    [NAICSCode.LAWN_AND_GARDEN_EQUIPMENT_AND_SUPPLIES_STORES, 'Lawn and Garden Equipment and Supplies Stores'],
    [NAICSCode.GROCERY_STORES, 'Grocery Stores'],
    [NAICSCode.SPECIALTY_FOOD_STORES, 'Specialty Food Stores'],
    [NAICSCode.BEER_WINE_AND_LIQUOR_STORES, 'Beer, Wine, and Liquor Stores'],
    [NAICSCode.HEALTH_AND_PERSONAL_CARE_STORES, 'Health and Personal Care Stores'],
    [NAICSCode.GASOLINE_STATIONS, 'Gasoline Stations'],
    [NAICSCode.CLOTHING_STORES, 'Clothing Stores'],
    [NAICSCode.SHOE_STORES, 'Shoe Stores'],
    [NAICSCode.JEWELRY_LUGGAGE_AND_LEATHER_GOODS_STORES, 'Jewelry, Luggage, and Leather Goods Stores'],
    [
        NAICSCode.SPORTING_GOODS_HOBBY_AND_MUSICAL_INSTRUMENT_STORES,
        'Sporting Goods, Hobby, and Musical Instrument Stores',
    ],
    [NAICSCode.BOOK_STORES_AND_NEWS_DEALERS, 'Book Stores and News Dealers'],
    [NAICSCode.DEPARTMENT_STORES, 'Department Stores'],
    [
        NAICSCode.GENERAL_MERCHANDISE_STORES_INCLUDING_WAREHOUSE_CLUBS_AND_SUPERCENTERS,
        'General Merchandise Stores, including Warehouse Clubs and Supercenters',
    ],
    [NAICSCode.FLORISTS, 'Florists'],
    [NAICSCode.OFFICE_SUPPLIES_STATIONERY_AND_GIFT_STORES, 'Office Supplies, Stationery, and Gift Stores'],
    [NAICSCode.USED_MERCHANDISE_STORES, 'Used Merchandise Stores'],
    [NAICSCode.OTHER_MISCELLANEOUS_STORE_RETAILERS, 'Other Miscellaneous Store Retailers'],
    [NAICSCode.ELECTRONIC_SHOPPING_AND_MAILORDER_HOUSES, 'Electronic Shopping and Mail-Order Houses'],
    [NAICSCode.VENDING_MACHINE_OPERATORS, 'Vending Machine Operators'],
    [NAICSCode.DIRECT_SELLING_ESTABLISHMENTS, 'Direct Selling Establishments'],
    [NAICSCode.SCHEDULED_AIR_TRANSPORTATION, 'Scheduled Air Transportation'],
    [NAICSCode.NONSCHEDULED_AIR_TRANSPORTATION, 'Nonscheduled Air Transportation'],
    [NAICSCode.RAIL_TRANSPORTATION, 'Rail Transportation'],
    [
        NAICSCode.DEEP_SEA_COASTAL_AND_GREAT_LAKES_WATER_TRANSPORTATION,
        'Deep Sea, Coastal, and Great Lakes Water Transportation',
    ],
    [NAICSCode.INLAND_WATER_TRANSPORTATION, 'Inland Water Transportation'],
    [NAICSCode.GENERAL_FREIGHT_TRUCKING, 'General Freight Trucking'],
    [NAICSCode.SPECIALIZED_FREIGHT_TRUCKING, 'Specialized Freight Trucking'],
    [NAICSCode.URBAN_TRANSIT_SYSTEMS, 'Urban Transit Systems'],
    [NAICSCode.INTERURBAN_AND_RURAL_BUS_TRANSPORTATION, 'Interurban and Rural Bus Transportation'],
    [NAICSCode.TAXI_AND_LIMOUSINE_SERVICE, 'Taxi and Limousine Service'],
    [NAICSCode.SCHOOL_AND_EMPLOYEE_BUS_TRANSPORTATION, 'School and Employee Bus Transportation'],
    [NAICSCode.CHARTER_BUS_INDUSTRY, 'Charter Bus Industry'],
    [NAICSCode.OTHER_TRANSIT_AND_GROUND_PASSENGER_TRANSPORTATION, 'Other Transit and Ground Passenger Transportation'],
    [NAICSCode.PIPELINE_TRANSPORTATION_OF_CRUDE_OIL, 'Pipeline Transportation of Crude Oil'],
    [NAICSCode.PIPELINE_TRANSPORTATION_OF_NATURAL_GAS, 'Pipeline Transportation of Natural Gas'],
    [NAICSCode.OTHER_PIPELINE_TRANSPORTATION, 'Other Pipeline Transportation'],
    [NAICSCode.SCENIC_AND_SIGHTSEEING_TRANSPORTATION_LAND, 'Scenic and Sightseeing Transportation, Land'],
    [NAICSCode.SCENIC_AND_SIGHTSEEING_TRANSPORTATION_WATER, 'Scenic and Sightseeing Transportation, Water'],
    [NAICSCode.SCENIC_AND_SIGHTSEEING_TRANSPORTATION_OTHER, 'Scenic and Sightseeing Transportation, Other'],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_AIR_TRANSPORTATION, 'Support Activities for Air Transportation'],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_RAIL_TRANSPORTATION, 'Support Activities for Rail Transportation'],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_WATER_TRANSPORTATION, 'Support Activities for Water Transportation'],
    [NAICSCode.SUPPORT_ACTIVITIES_FOR_ROAD_TRANSPORTATION, 'Support Activities for Road Transportation'],
    [NAICSCode.FREIGHT_TRANSPORTATION_ARRANGEMENT, 'Freight Transportation Arrangement'],
    [NAICSCode.OTHER_SUPPORT_ACTIVITIES_FOR_TRANSPORTATION, 'Other Support Activities for Transportation'],
    [NAICSCode.POSTAL_SERVICE, 'Postal Service'],
    [NAICSCode.COURIERS_AND_EXPRESS_DELIVERY_SERVICES, 'Couriers and Express Delivery Services'],
    [NAICSCode.LOCAL_MESSENGERS_AND_LOCAL_DELIVERY, 'Local Messengers and Local Delivery'],
    [NAICSCode.WAREHOUSING_AND_STORAGE, 'Warehousing and Storage'],
    [
        NAICSCode.NEWSPAPER_PERIODICAL_BOOK_AND_DIRECTORY_PUBLISHERS,
        'Newspaper, Periodical, Book, and Directory Publishers',
    ],
    [NAICSCode.SOFTWARE_PUBLISHERS, 'Software Publishers'],
    [NAICSCode.MOTION_PICTURE_AND_VIDEO_INDUSTRIES, 'Motion Picture and Video Industries'],
    [NAICSCode.SOUND_RECORDING_INDUSTRIES, 'Sound Recording Industries'],
    [NAICSCode.RADIO_AND_TELEVISION_BROADCASTING, 'Radio and Television Broadcasting'],
    [NAICSCode.CABLE_AND_OTHER_SUBSCRIPTION_PROGRAMMING, 'Cable and Other Subscription Programming'],
    [NAICSCode.WIRED_AND_WIRELESS_TELECOMMUNICATIONS_CARRIERS, 'Wired and Wireless Telecommunications Carriers'],
    [NAICSCode.SATELLITE_TELECOMMUNICATIONS, 'Satellite Telecommunications'],
    [NAICSCode.OTHER_TELECOMMUNICATIONS, 'Other Telecommunications'],
    [NAICSCode.DATA_PROCESSING_HOSTING_AND_RELATED_SERVICES, 'Data Processing, Hosting, and Related Services'],
    [NAICSCode.OTHER_INFORMATION_SERVICES, 'Other Information Services'],
    [NAICSCode.MONETARY_AUTHORITIESCENTRAL_BANK, 'Monetary Authorities-Central Bank'],
    [NAICSCode.DEPOSITORY_CREDIT_INTERMEDIATION, 'Depository Credit Intermediation'],
    [NAICSCode.NONDEPOSITORY_CREDIT_INTERMEDIATION, 'Nondepository Credit Intermediation'],
    [NAICSCode.ACTIVITIES_RELATED_TO_CREDIT_INTERMEDIATION, 'Activities Related to Credit Intermediation'],
    [
        NAICSCode.SECURITIES_AND_COMMODITY_CONTRACTS_INTERMEDIATION_AND_BROKERAGE,
        'Securities and Commodity Contracts Intermediation and Brokerage',
    ],
    [NAICSCode.SECURITIES_AND_COMMODITY_EXCHANGES, 'Securities and Commodity Exchanges'],
    [NAICSCode.OTHER_FINANCIAL_INVESTMENT_ACTIVITIES, 'Other Financial Investment Activities'],
    [NAICSCode.INSURANCE_CARRIERS, 'Insurance Carriers'],
    [
        NAICSCode.AGENCIES_BROKERAGES_AND_OTHER_INSURANCE_RELATED_ACTIVITIES,
        'Agencies, Brokerages, and Other Insurance Related Activities',
    ],
    [NAICSCode.INSURANCE_AND_EMPLOYEE_BENEFIT_FUNDS, 'Insurance and Employee Benefit Funds'],
    [NAICSCode.OTHER_INVESTMENT_POOLS_AND_FUNDS, 'Other Investment Pools and Funds'],
    [NAICSCode.LESSORS_OF_REAL_ESTATE, 'Lessors of Real Estate'],
    [NAICSCode.OFFICES_OF_REAL_ESTATE_AGENTS_AND_BROKERS, 'Offices of Real Estate Agents and Brokers'],
    [NAICSCode.ACTIVITIES_RELATED_TO_REAL_ESTATE, 'Activities Related to Real Estate'],
    [NAICSCode.AUTOMOTIVE_EQUIPMENT_RENTAL_AND_LEASING, 'Automotive Equipment Rental and Leasing'],
    [NAICSCode.CONSUMER_GOODS_RENTAL, 'Consumer Goods Rental'],
    [NAICSCode.GENERAL_RENTAL_CENTERS, 'General Rental Centers'],
    [
        NAICSCode.COMMERCIAL_AND_INDUSTRIAL_MACHINERY_AND_EQUIPMENT_RENTAL_AND_LEASING,
        'Commercial and Industrial Machinery and Equipment Rental and Leasing',
    ],
    [
        NAICSCode.LESSORS_OF_NONFINANCIAL_INTANGIBLE_ASSETS_EXCEPT_COPYRIGHTED_WORKS,
        'Lessors of Nonfinancial Intangible Assets (except Copyrighted Works)',
    ],
    [NAICSCode.LEGAL_SERVICES, 'Legal Services'],
    [
        NAICSCode.ACCOUNTING_TAX_PREPARATION_BOOKKEEPING_AND_PAYROLL_SERVICES,
        'Accounting, Tax Preparation, Bookkeeping, and Payroll Services',
    ],
    [NAICSCode.ARCHITECTURAL_ENGINEERING_AND_RELATED_SERVICES, 'Architectural, Engineering, and Related Services'],
    [NAICSCode.SPECIALIZED_DESIGN_SERVICES, 'Specialized Design Services'],
    [NAICSCode.COMPUTER_SYSTEMS_DESIGN_AND_RELATED_SERVICES, 'Computer Systems Design and Related Services'],
    [
        NAICSCode.MANAGEMENT_SCIENTIFIC_AND_TECHNICAL_CONSULTING_SERVICES,
        'Management, Scientific, and Technical Consulting Services',
    ],
    [NAICSCode.SCIENTIFIC_RESEARCH_AND_DEVELOPMENT_SERVICES, 'Scientific Research and Development Services'],
    [NAICSCode.ADVERTISING_PUBLIC_RELATIONS_AND_RELATED_SERVICES, 'Advertising, Public Relations, and Related Services'],
    [
        NAICSCode.OTHER_PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES,
        'Other Professional, Scientific, and Technical Services',
    ],
    [NAICSCode.MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES, 'Management of Companies and Enterprises'],
    [NAICSCode.OFFICE_ADMINISTRATIVE_SERVICES, 'Office Administrative Services'],
    [NAICSCode.FACILITIES_SUPPORT_SERVICES, 'Facilities Support Services'],
    [NAICSCode.EMPLOYMENT_SERVICES, 'Employment Services'],
    [NAICSCode.BUSINESS_SUPPORT_SERVICES, 'Business Support Services'],
    [NAICSCode.TRAVEL_ARRANGEMENT_AND_RESERVATION_SERVICES, 'Travel Arrangement and Reservation Services'],
    [NAICSCode.INVESTIGATION_AND_SECURITY_SERVICES, 'Investigation and Security Services'],
    [NAICSCode.SERVICES_TO_BUILDINGS_AND_DWELLINGS, 'Services to Buildings and Dwellings'],
    [NAICSCode.OTHER_SUPPORT_SERVICES, 'Other Support Services'],
    [NAICSCode.WASTE_COLLECTION, 'Waste Collection'],
    [NAICSCode.WASTE_TREATMENT_AND_DISPOSAL, 'Waste Treatment and Disposal'],
    [NAICSCode.REMEDIATION_AND_OTHER_WASTE_MANAGEMENT_SERVICES, 'Remediation and Other Waste Management Services'],
    [NAICSCode.ELEMENTARY_AND_SECONDARY_SCHOOLS, 'Elementary and Secondary Schools'],
    [NAICSCode.JUNIOR_COLLEGES, 'Junior Colleges'],
    [NAICSCode.COLLEGES_UNIVERSITIES_AND_PROFESSIONAL_SCHOOLS, 'Colleges, Universities, and Professional Schools'],
    [
        NAICSCode.BUSINESS_SCHOOLS_AND_COMPUTER_AND_MANAGEMENT_TRAINING,
        'Business Schools and Computer and Management Training',
    ],
    [NAICSCode.TECHNICAL_AND_TRADE_SCHOOLS, 'Technical and Trade Schools'],
    [NAICSCode.OTHER_SCHOOLS_AND_INSTRUCTION, 'Other Schools and Instruction'],
    [NAICSCode.EDUCATIONAL_SUPPORT_SERVICES, 'Educational Support Services'],
    [NAICSCode.OFFICES_OF_PHYSICIANS, 'Offices of Physicians'],
    [NAICSCode.OFFICES_OF_DENTISTS, 'Offices of Dentists'],
    [NAICSCode.OFFICES_OF_OTHER_HEALTH_PRACTITIONERS, 'Offices of Other Health Practitioners'],
    [NAICSCode.OUTPATIENT_CARE_CENTERS, 'Outpatient Care Centers'],
    [NAICSCode.MEDICAL_AND_DIAGNOSTIC_LABORATORIES, 'Medical and Diagnostic Laboratories'],
    [NAICSCode.HOME_HEALTH_CARE_SERVICES, 'Home Health Care Services'],
    [NAICSCode.OTHER_AMBULATORY_HEALTH_CARE_SERVICES, 'Other Ambulatory Health Care Services'],
    [NAICSCode.GENERAL_MEDICAL_AND_SURGICAL_HOSPITALS, 'General Medical and Surgical Hospitals'],
    [NAICSCode.PSYCHIATRIC_AND_SUBSTANCE_ABUSE_HOSPITALS, 'Psychiatric and Substance Abuse Hospitals'],
    [
        NAICSCode.SPECIALTY_EXCEPT_PSYCHIATRIC_AND_SUBSTANCE_ABUSE_HOSPITALS,
        'Specialty (except Psychiatric and Substance Abuse) Hospitals',
    ],
    [
        NAICSCode.NURSING_CARE_FACILITIES_SKILLED_NURSING_FACILITIES,
        'Nursing Care Facilities (Skilled Nursing Facilities)',
    ],
    [
        NAICSCode.RESIDENTIAL_INTELLECTUAL_AND_DEVELOPMENTAL_DISABILITY_MENTAL_HEALTH_AND_SUBSTANCE_ABUSE_FACILITIES,
        'Residential Intellectual and Developmental Disability, Mental Health, and Substance Abuse Facilities',
    ],
    [
        NAICSCode.CONTINUING_CARE_RETIREMENT_COMMUNITIES_AND_ASSISTED_LIVING_FACILITIES_FOR_THE_ELDERLY,
        'Continuing Care Retirement Communities and Assisted Living Facilities for the Elderly',
    ],
    [NAICSCode.OTHER_RESIDENTIAL_CARE_FACILITIES, 'Other Residential Care Facilities'],
    [NAICSCode.INDIVIDUAL_AND_FAMILY_SERVICES, 'Individual and Family Services'],
    [
        NAICSCode.COMMUNITY_FOOD_AND_HOUSING_AND_EMERGENCY_AND_OTHER_RELIEF_SERVICES,
        'Community Food and Housing, and Emergency and Other Relief Services',
    ],
    [NAICSCode.VOCATIONAL_REHABILITATION_SERVICES, 'Vocational Rehabilitation Services'],
    [NAICSCode.CHILD_DAY_CARE_SERVICES, 'Child Day Care Services'],
    [NAICSCode.PERFORMING_ARTS_COMPANIES, 'Performing Arts Companies'],
    [NAICSCode.SPECTATOR_SPORTS, 'Spectator Sports'],
    [
        NAICSCode.PROMOTERS_OF_PERFORMING_ARTS_SPORTS_AND_SIMILAR_EVENTS,
        'Promoters of Performing Arts, Sports, and Similar Events',
    ],
    [
        NAICSCode.AGENTS_AND_MANAGERS_FOR_ARTISTS_ATHLETES_ENTERTAINERS_AND_OTHER_PUBLIC_FIGURES,
        'Agents and Managers for Artists, Athletes, Entertainers, and Other Public Figures',
    ],
    [NAICSCode.INDEPENDENT_ARTISTS_WRITERS_AND_PERFORMERS, 'Independent Artists, Writers, and Performers'],
    [NAICSCode.MUSEUMS_HISTORICAL_SITES_AND_SIMILAR_INSTITUTIONS, 'Museums, Historical Sites, and Similar Institutions'],
    [NAICSCode.AMUSEMENT_PARKS_AND_ARCADES, 'Amusement Parks and Arcades'],
    [NAICSCode.GAMBLING_INDUSTRIES, 'Gambling Industries'],
    [NAICSCode.OTHER_AMUSEMENT_AND_RECREATION_INDUSTRIES, 'Other Amusement and Recreation Industries'],
    [NAICSCode.TRAVELER_ACCOMMODATION, 'Traveler Accommodation'],
    [
        NAICSCode.RV_RECREATIONAL_VEHICLE_PARKS_AND_RECREATIONAL_CAMPS,
        'RV (Recreational Vehicle) Parks and Recreational Camps',
    ],
    [
        NAICSCode.ROOMING_AND_BOARDING_HOUSES_DORMITORIES_AND_WORKERS_CAMPS,
        "Rooming and Boarding Houses, Dormitories, and Workers' Camps",
    ],
    [NAICSCode.SPECIAL_FOOD_SERVICES, 'Special Food Services'],
    [NAICSCode.DRINKING_PLACES_ALCOHOLIC_BEVERAGES, 'Drinking Places (Alcoholic Beverages)'],
    [NAICSCode.RESTAURANTS_AND_OTHER_EATING_PLACES, 'Restaurants and Other Eating Places'],
    [NAICSCode.AUTOMOTIVE_REPAIR_AND_MAINTENANCE, 'Automotive Repair and Maintenance'],
    [
        NAICSCode.ELECTRONIC_AND_PRECISION_EQUIPMENT_REPAIR_AND_MAINTENANCE,
        'Electronic and Precision Equipment Repair and Maintenance',
    ],
    [
        NAICSCode.COMMERCIAL_AND_INDUSTRIAL_MACHINERY_AND_EQUIPMENT_EXCEPT_AUTOMOTIVE_AND_ELECTRONIC_REPAIR_AND_MAINTENANCE,
        'Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance',
    ],
    [
        NAICSCode.PERSONAL_AND_HOUSEHOLD_GOODS_REPAIR_AND_MAINTENANCE,
        'Personal and Household Goods Repair and Maintenance',
    ],
    [NAICSCode.PERSONAL_CARE_SERVICES, 'Personal Care Services'],
    [NAICSCode.DEATH_CARE_SERVICES, 'Death Care Services'],
    [NAICSCode.DRYCLEANING_AND_LAUNDRY_SERVICES, 'Drycleaning and Laundry Services'],
    [NAICSCode.OTHER_PERSONAL_SERVICES, 'Other Personal Services'],
    [NAICSCode.RELIGIOUS_ORGANIZATIONS, 'Religious Organizations'],
    [NAICSCode.GRANTMAKING_AND_GIVING_SERVICES, 'Grantmaking and Giving Services'],
    [NAICSCode.SOCIAL_ADVOCACY_ORGANIZATIONS, 'Social Advocacy Organizations'],
    [NAICSCode.CIVIC_AND_SOCIAL_ORGANIZATIONS, 'Civic and Social Organizations'],
    [
        NAICSCode.BUSINESS_PROFESSIONAL_LABOR_POLITICAL_AND_SIMILAR_ORGANIZATIONS,
        'Business, Professional, Labor, Political, and Similar Organizations',
    ],
    [NAICSCode.PRIVATE_HOUSEHOLDS, 'Private Households'],
    [
        NAICSCode.EXECUTIVE_LEGISLATIVE_AND_OTHER_GENERAL_GOVERNMENT_SUPPORT,
        'Executive, Legislative, and Other General Government Support',
    ],
    [NAICSCode.JUSTICE_PUBLIC_ORDER_AND_SAFETY_ACTIVITIES, 'Justice, Public Order, and Safety Activities'],
    [NAICSCode.ADMINISTRATION_OF_HUMAN_RESOURCE_PROGRAMS, 'Administration of Human Resource Programs'],
    [NAICSCode.ADMINISTRATION_OF_ENVIRONMENTAL_QUALITY_PROGRAMS, 'Administration of Environmental Quality Programs'],
    [
        NAICSCode.ADMINISTRATION_OF_HOUSING_PROGRAMS_URBAN_PLANNING_AND_COMMUNITY_DEVELOPMENT,
        'Administration of Housing Programs, Urban Planning, and Community Development',
    ],
    [NAICSCode.ADMINISTRATION_OF_ECONOMIC_PROGRAMS, 'Administration of Economic Programs'],
    [NAICSCode.SPACE_RESEARCH_AND_TECHNOLOGY, 'Space Research and Technology'],
    [NAICSCode.NATIONAL_SECURITY_AND_INTERNATIONAL_AFFAIRS, 'National Security and International Affairs'],
    [
        NAICSCode.INTERNET_PUBLISHING_AND_BROADCASTING_AND_WEB_SEARCH_PORTALS,
        'Internet Publishing and Broadcasting and Web Search Portals',
    ],
    [NAICSCode.COSMETICS_BEAUTY_SUPPLIES_AND_PERFUME_STORES, 'Cosmetics, Beauty Supplies, and Perfume Stores'],
    [NAICSCode.PORTFOLIO_MANAGEMENT, 'Portfolio Management'],
    [NAICSCode.FOOTWEAR_MANUFACTURING_6D, 'Footwear Manufacturing'],
]);
const NAICSCodeMap = new Map(Object.values(NAICSCode).map((v) => [v, v]));
/**
 * Maps a string to a NAICS code
 * It will throw if the string does not contain 2-6 digits
 * If it can't find a match it attempts the next hierarchy level up (e.g. 1234 -> 123 -> 12)
 * If it still can't find a match it will return undefined
 */
export const stringToNAICSCode = (s) => {
    if (!s) {
        return undefined;
    }
    const regexp = /^\d{2,6}$/;
    if (!regexp.test(s)) {
        throw new Error(`NAICS code '${s}' must have 2-6 digits`);
    }
    let v = s;
    while (v.length >= 2) {
        const naicsCode = NAICSCodeMap.get(v);
        if (naicsCode) {
            return naicsCode;
        }
        v = v.slice(0, -1);
    }
    return undefined;
};
/**
 * Maps a NAICS code to its title
 */
export const naicsCodeToNAICSTitle = (code) => {
    return (code && codeToNAICSTitleMap.get(code)) || 'Uncategorised';
};
