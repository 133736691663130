import { sampleOrganization } from '../Organization/SampleOrganization';
import { dateToTimestamp } from '../Timestamp';
import { GeneralMeetingType } from './GM';
export const sampleGM = (withoutURL) => {
    const date = new Date();
    const { id, websiteUrl } = sampleOrganization();
    const sampleGM = { date: dateToTimestamp(date), organizationId: id, type: GeneralMeetingType.annual };
    return withoutURL ? sampleGM : Object.assign(Object.assign({}, sampleGM), { proxyNoticeUrl: `${websiteUrl}/agm` });
};
export const sampleGMWithMockDates = Object.assign(Object.assign({}, sampleGM()), { date: dateToTimestamp(new Date('2021-06-26T14:46:36.000')) });
