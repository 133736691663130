/* eslint-disable @typescript-eslint/no-deprecated */
import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { Breakpoint } from '../../styles';
export const buildColSpans = (columnSpan) => typeof columnSpan === 'number' ? { columnSpan, columnSpanTablet: undefined, columnSpanMobile: undefined } : columnSpan;
export const GridCard = ({ children, backgroundColor, columnSpan = 3, stretchY, columnStart, customStyles, }) => {
    const { colors } = useTheme();
    return (React.createElement(GridCardContainer, { columnStart: columnStart, columnSpans: buildColSpans(columnSpan), backgroundColor: backgroundColor !== null && backgroundColor !== void 0 ? backgroundColor : colors.white, stretchY: stretchY, customStyles: customStyles }, children));
};
const ifGtThanReplaceMax = (max, n) => (n > max ? max : n);
export const GridContainer = styled.div `
  grid-column: ${(p) => (p.columnStart ? `${p.columnStart} / ` : '')} span ${(p) => p.columnSpans.columnSpan};
  align-self: ${(p) => (p.stretchY ? 'stretch' : 'start')};

  @media (max-width: ${Breakpoint.tablet}) {
    grid-column: span ${(p) => { var _a; return (_a = p.columnSpans.columnSpanTablet) !== null && _a !== void 0 ? _a : ifGtThanReplaceMax(4, p.columnSpans.columnSpan); }};
  }
  @media (max-width: ${Breakpoint.mobile}) {
    grid-column: span
      ${(p) => {
    var _a, _b;
    return (_b = (_a = p.columnSpans.columnSpanMobile) !== null && _a !== void 0 ? _a : p.columnSpans.columnSpanTablet) !== null && _b !== void 0 ? _b : ifGtThanReplaceMax(3, p.columnSpans.columnSpan);
}};
  }
  @media (max-width: ${Breakpoint.small}) {
    grid-column: span
      ${(p) => {
    var _a, _b, _c;
    return (_c = (_b = (_a = p.columnSpans.columnSpanSmall) !== null && _a !== void 0 ? _a : p.columnSpans.columnSpanMobile) !== null && _b !== void 0 ? _b : p.columnSpans.columnSpanTablet) !== null && _c !== void 0 ? _c : ifGtThanReplaceMax(3, p.columnSpans.columnSpan);
}};
  }
  ${(p) => (p.backgroundColor ? `background-color: ${p.backgroundColor}` : '')};
`;
export const GridCardContainer = styled(GridContainer) `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.16);
  border-radius: 2rem;
  outline: none;
  ${(p) => p.customStyles}
  &:hover {
    ${({ clickable }) => (clickable ? 'box-shadow: 0 0 1rem 0 rgb(0 0 0 / 32%);' : '')}
  }
`;
export const CardGrid = styled.div `
  display: grid;
  grid-row-gap: 2rem;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(auto, 15rem)); //15.2rem = 460/3
  ${({ dense }) => (dense ? 'grid-auto-flow: dense;' : '')}
  justify-items: stretch;
  @media (max-width: ${Breakpoint.mobile}) {
    grid-template-columns: 1fr 1fr 0;
    grid-row-gap: 2rem;
    grid-column-gap: 0;
  }
  @media (max-width: ${Breakpoint.small}) {
    grid-template-columns: 1fr 0 0;
    grid-row-gap: 2rem;
    grid-column-gap: 0;
  }
`;
