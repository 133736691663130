/* eslint-disable @typescript-eslint/no-deprecated */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Modal as MatModal } from '@mui/material';
import { CancelOutlined } from '@mui/icons-material';
import { SubHeading } from '../Typography';
import { Breakpoint } from '../../styles';
export const Modal = ({ title, body, onClose, open, background, width, backdropColor, children, closeIcon, heroImageSrc, }) => {
    const { colors } = useTheme();
    const backgroundIn = background !== null && background !== void 0 ? background : colors.white;
    const handleClose = (reason) => {
        if (onClose)
            onClose(reason);
    };
    return (React.createElement(CenteredModal, { "aria-labelledby": "modal-title", "aria-describedby": "modal-body", open: open, onClose: (event, reason) => handleClose(reason), BackdropProps: backdropColor ? { style: { backgroundColor: backdropColor } } : {} },
        React.createElement(Container, { background: backgroundIn, width: width !== null && width !== void 0 ? width : 'small' },
            heroImageSrc && React.createElement(HeroImage, { imageSrc: heroImageSrc }),
            title && (React.createElement(ModalHeader, null,
                title && React.createElement(ModalTitle, null, title),
                closeIcon && React.createElement(CloseIcon, { onClose: onClose }))),
            React.createElement("div", { id: "modal-body" }, body !== null && body !== void 0 ? body : children))));
};
export const ModalTitle = ({ children }) => (React.createElement(SubHeading, { mt: 0, id: "modal-title" }, children));
export const CloseIcon = ({ onClose }) => {
    const { colors } = useTheme();
    const handleClose = (reason) => {
        if (onClose)
            onClose(reason);
    };
    return (React.createElement(Icon, { onClick: () => handleClose('backdropClick') },
        React.createElement(CancelOutlined, { style: { fontSize: '2.4rem', color: colors.primary.medium } })));
};
const modalPaddingInRem = 4;
const modalMobilePaddingInRem = 2;
const CenteredModal = styled(MatModal) `
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
`;
const HeroImage = styled.div `
  height: 25rem;
  background-position: center;
  background-image: ${({ imageSrc }) => `url(${imageSrc})`};
  background-size: cover;
  margin: ${-modalPaddingInRem}rem ${-modalPaddingInRem}rem ${modalPaddingInRem}rem;
  @media (max-width: ${Breakpoint.mobile}) {
    height: 20rem;
    margin: ${-modalMobilePaddingInRem}rem ${-modalMobilePaddingInRem}rem ${modalMobilePaddingInRem}rem;
  }
  @media (max-width: ${Breakpoint.small}) {
    height: 15rem;
  }
`;
const toRealWidth = (namedWidth) => {
    switch (namedWidth) {
        case 'small':
            return '58rem';
        case 'medium':
            return '80rem';
        case 'full-width':
            return '100%';
        default:
            return '58rem';
    }
};
export const ModalHeader = styled.div `
  display: flex;
  justify-content: space-between;
`;
const Container = styled.div `
  background: ${(p) => p.background};
  width: ${(p) => { var _a; return toRealWidth((_a = p.width) !== null && _a !== void 0 ? _a : 'small'); }};
  padding: ${modalPaddingInRem}rem;
  border-radius: 2rem;
  outline: none;
  max-height: 100vh;
  overflow-y: auto;

  @media (max-device-width: ${Breakpoint.mobile}) {
    padding: ${modalMobilePaddingInRem}rem;
  }
`;
const Icon = styled.div `
  cursor: pointer;
  margin-left: 2rem;
`;
