import React from 'react'
import { ExplicitPageView } from '../../../application/analytics'
import { SignUpForm, SignUpFormValues } from '../Forms/SignUpForm'
import { changeTitle } from '../../../containers/changeTitle'
import { privacyPolicyUrl, termsAndConditionsUrl } from '../../../config/ExternalLinks'
import { useAppSelector } from '../../../application/store'
import { selectAuthError, selectAuthPending } from '../../../application/features/auth/selectors'

interface Props {
  clickSignIn: () => void
  clickSignUp: (values: SignUpFormValues) => Promise<void>
  email: string
  password: string
  phoneNumber: string
}

export const SignUpPage: React.FC<Props> = ({ clickSignIn, clickSignUp, email, password, phoneNumber }) => {
  changeTitle('Sign Up')
  const error = useAppSelector(selectAuthError)
  const authActionPending = useAppSelector(selectAuthPending)
  const onSignUp = async (values: SignUpFormValues) => {
    await clickSignUp(values)
  }

  return (
    <>
      <ExplicitPageView page="/sign-up" />
      <SignUpForm
        clickSignIn={clickSignIn}
        submit={onSignUp}
        privacyUrl={privacyPolicyUrl}
        termsUrl={termsAndConditionsUrl}
        email={email}
        password={password}
        phoneNumber={phoneNumber}
        error={error?.message}
        pending={authActionPending}
      />
    </>
  )
}
