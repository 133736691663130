import { css } from 'styled-components';
export const DefaultBoxShadow = css `
box-shadow: ${({ theme }) => theme.boxShadows.one};
a & {
    transition: 300ms box-shadow ease-out;
    box-shadow: ${({ theme }) => theme.boxShadows.two};
  }
  a:hover & {
    box-shadow: ${({ theme }) => theme.boxShadows.three};

  }
}
`;
