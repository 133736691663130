export { SvgAbstain as AbstainIcon } from './Abstain';
export { SvgAgriculturesmall as AgricultureSmallIcon } from './AgricultureSmall';
export { SvgAgriculture as AgricultureIcon } from './Agriculture';
export { SvgAlert as AlertIcon } from './Alert';
export { SvgArrowdown as ArrowDownIcon } from './ArrowDown';
export { SvgArrowleft as ArrowLeftIcon } from './ArrowLeft';
export { SvgArrowright as ArrowRightIcon } from './ArrowRight';
export { SvgArrowup as ArrowUpIcon } from './ArrowUp';
export { SvgBell as BellIcon } from './Bell';
export { SvgCalendar as CalendarIcon } from './Calendar';
export { SvgChat as ChatIcon } from './Chat';
export { SvgChevrondown as ChevronDownIcon } from './ChevronDown';
export { SvgChevronleft as ChevronLeftIcon } from './ChevronLeft';
export { SvgChevronright as ChevronRightIcon } from './ChevronRight';
export { SvgChevronup as ChevronUpIcon } from './ChevronUp';
export { SvgCircleminus as CircleMinusIcon } from './CircleMinus';
export { SvgCircleslash as CircleSlashIcon } from './CircleSlash';
export { SvgCircle as CircleIcon } from './Circle';
export { SvgClock as ClockIcon } from './Clock';
export { SvgCog as CogIcon } from './Cog';
export { SvgCompanies as CompaniesIcon } from './Companies';
export { SvgCopy as CopyIcon } from './Copy';
export { SvgCopyright as CopyrightIcon } from './Copyright';
export { SvgCount1Fill as Count1FillIcon } from './Count1Fill';
export { SvgCount1 as Count1Icon } from './Count1';
export { SvgCount2Fill as Count2FillIcon } from './Count2Fill';
export { SvgCount2 as Count2Icon } from './Count2';
export { SvgCount3Fill as Count3FillIcon } from './Count3Fill';
export { SvgCount3 as Count3Icon } from './Count3';
export { SvgCount4Fill as Count4FillIcon } from './Count4Fill';
export { SvgCount4 as Count4Icon } from './Count4';
export { SvgCross as CrossIcon } from './Cross';
export { SvgDashboard as DashboardIcon } from './Dashboard';
export { SvgDefence as DefenceIcon } from './Defence';
export { SvgDownload as DownloadIcon } from './Download';
export { SvgEdit as EditIcon } from './Edit';
export { SvgEnergysmall as EnergySmallIcon } from './EnergySmall';
export { SvgEnergy as EnergyIcon } from './Energy';
export { SvgExport as ExportIcon } from './Export';
export { SvgEyeclosed as EyeClosedIcon } from './EyeClosed';
export { SvgEyeopen as EyeOpenIcon } from './EyeOpen';
export { SvgEye as EyeIcon } from './Eye';
export { SvgFacebook as FacebookIcon } from './Facebook';
export { SvgFail as FailIcon } from './Fail';
export { SvgFile as FileIcon } from './File';
export { SvgFilter as FilterIcon } from './Filter';
export { SvgFinancesmall as FinanceSmallIcon } from './FinanceSmall';
export { SvgFinance as FinanceIcon } from './Finance';
export { SvgFlag as FlagIcon } from './Flag';
export { SvgFlame as FlameIcon } from './Flame';
export { SvgHead as HeadIcon } from './Head';
export { SvgHealthcaresmall as HealthcareSmallIcon } from './HealthcareSmall';
export { SvgHealthcare as HealthcareIcon } from './Healthcare';
export { SvgHeart as HeartIcon } from './Heart';
export { SvgHelp as HelpIcon } from './Help';
export { SvgHome as HomeIcon } from './Home';
export { SvgHourglass as HourglassIcon } from './Hourglass';
export { SvgIdea as IdeaIcon } from './Idea';
export { SvgIndustrialssmall as IndustrialsSmallIcon } from './IndustrialsSmall';
export { SvgIndustrials as IndustrialsIcon } from './Industrials';
export { SvgInformation as InformationIcon } from './Information';
export { SvgInstagram as InstagramIcon } from './Instagram';
export { SvgLifestylesmall as LifestyleSmallIcon } from './LifestyleSmall';
export { SvgLifestyle as LifestyleIcon } from './Lifestyle';
export { SvgLink as LinkIcon } from './Link';
export { SvgLinkedinfill as LinkedinFillIcon } from './LinkedinFill';
export { SvgLinkedin as LinkedinIcon } from './Linkedin';
export { SvgLoad as LoadIcon } from './Load';
export { SvgMail as MailIcon } from './Mail';
export { SvgMegaphone as MegaphoneIcon } from './Megaphone';
export { SvgMenu as MenuIcon } from './Menu';
export { SvgMessage as MessageIcon } from './Message';
export { SvgMoneybagsmall as MoneyBagSmallIcon } from './MoneyBagSmall';
export { SvgMoneybag as MoneyBagIcon } from './MoneyBag';
export { SvgMoney as MoneyIcon } from './Money';
export { SvgNavigation as NavigationIcon } from './Navigation';
export { SvgNestegg as NesteggIcon } from './Nestegg';
export { SvgNewwindow as NewWindowIcon } from './NewWindow';
export { SvgNologo as NologoIcon } from './Nologo';
export { SvgPaperplane as PaperplaneIcon } from './Paperplane';
export { SvgPerson as PersonIcon } from './Person';
export { SvgPie as PieIcon } from './Pie';
export { SvgPiechart as PiechartIcon } from './Piechart';
export { SvgPlay as PlayIcon } from './Play';
export { SvgPlus as PlusIcon } from './Plus';
export { SvgRealestatesmall as RealEstateSmallIcon } from './RealEstateSmall';
export { SvgRealestate as RealEstateIcon } from './RealEstate';
export { SvgRetailsmall as RetailSmallIcon } from './RetailSmall';
export { SvgRetail as RetailIcon } from './Retail';
export { SvgRocket as RocketIcon } from './Rocket';
export { SvgSave as SaveIcon } from './Save';
export { SvgSearch as SearchIcon } from './Search';
export { SvgSeedling as SeedlingIcon } from './Seedling';
export { SvgSkip as SkipIcon } from './Skip';
export { SvgSpeacialistservicessmall as SpeacialistServicesSmallIcon } from './SpeacialistServicesSmall';
export { SvgSpecialistservices as SpecialistServicesIcon } from './SpecialistServices';
export { SvgSpeech as SpeechIcon } from './Speech';
export { SvgStar as StarIcon } from './Star';
export { SvgSuccess as SuccessIcon } from './Success';
export { SvgTarget as TargetIcon } from './Target';
export { SvgTechnologysmall as TechnologySmallIcon } from './TechnologySmall';
export { SvgTechnology as TechnologyIcon } from './Technology';
export { SvgThumbsdown as ThumbsDownIcon } from './ThumbsDown';
export { SvgThumbsup as ThumbsUpIcon } from './ThumbsUp';
export { SvgTick as TickIcon } from './Tick';
export { SvgTransparency as TransparencyIcon } from './Transparency';
export { SvgTransportsmall as TransportSmallIcon } from './TransportSmall';
export { SvgTransport as TransportIcon } from './Transport';
export { SvgTwitterfill as TwitterFillIcon } from './TwitterFill';
export { SvgTwitter as TwitterIcon } from './Twitter';
export { SvgUtilitiessmall as UtilitiesSmallIcon } from './UtilitiesSmall';
export { SvgUtilities as UtilitiesIcon } from './Utilities';
export { SvgVote as VoteIcon } from './Vote';
export { SvgWarning as WarningIcon } from './Warning';
export { SvgWinning as WinningIcon } from './Winning';
