import React from 'react';
import styled from 'styled-components';
import { TickSvg } from './images/tick';
// Must be wrapped in a label to be interactive
// Has a hidden native checkbox to maintain accessibility
export const Checkbox = ({ checked, onChange, type = 'checkbox' }) => (React.createElement(CheckboxContainer, null,
    React.createElement(HiddenCheckbox, { type: type, checked: checked, onChange: onChange }),
    React.createElement(StyledCheckbox, { type: type, checked: checked },
        React.createElement(TickIcon, { viewBox: "0 0 20 20" },
            React.createElement(Tick, null)))));
const CheckboxContainer = styled.div `
  display: inline-block;
  vertical-align: middle;
  height: 2rem;
  width: 2rem;
`;
const TickIcon = styled.svg `
  fill: none;
  stroke: ${({ theme }) => theme.colors.secondary.medium};
  stroke-width: 1px;
  padding-bottom: 1px;
`;
const Tick = styled(TickSvg) `
  fill: ${({ theme }) => theme.colors.secondary.medium};
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input `
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const StyledCheckbox = styled.div `
  box-sizing: border-box;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background: ${({ theme }) => theme.colors.white};
  border: solid 0.8px ${({ checked, theme }) => (checked ? theme.colors.secondary.medium : theme.colors.grey.medium)};
  border-radius: ${(p) => (p.type === 'checkbox' ? '4px' : '50%')};
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    outline: 1px ${({ theme }) => theme.colors.black} dotted;
  }

  ${TickIcon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
