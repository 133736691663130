import React from 'react'
import { SelfSelectFund, SelfSelectLayout } from '../../components/SelfSelect'
import { useRouter } from '../../application/router/useRouter'
import { useSelfSelectFlow } from '../../application/hooks/useSelfSelectFlow'
import { SelfSelectForm } from '../../components/SelfSelectForm/SelfSelectForm'
import { PayloadUnwrapperM } from '../../components/Error/PayloadUnwrapper'
import { useOnSelfSelect } from './useOnSelfSelect'

const SelfSelectFormPage: React.FC = () => {
  const flow = useSelfSelectFlow()
  const { location, navigate, back } = useRouter()
  const onSelect = useOnSelfSelect()

  return (
    <PayloadUnwrapperM items={[flow, onSelect]}>
      {([flow, onSelect]) => {
        if (flow.type === 'self-select-form') {
          return (
            <SelfSelectLayout>
              <SelfSelectForm
                selfSelectForm={flow}
                onSelect={onSelect}
                onNoFundSelected={() =>
                  navigate(`${location.pathname.replace('/self-select-fund', '')}/unsupported-fund`)
                }
              />
            </SelfSelectLayout>
          )
        }

        const {
          portfolios,
          selfSelectFundHelpText,
          confirmFundText,
          confirmationModalTitle,
          confirmationModalText,
          confirmationSubheadingText,
          selfSelectFundBoxHeaderText,
          selfSelectFundHeaderMarkdown,
        } = flow

        return (
          <SelfSelectLayout>
            <SelfSelectFund
              modelPortfolios={portfolios}
              onSelect={onSelect}
              goBack={back}
              helpText={selfSelectFundHelpText}
              confirmFundText={confirmFundText}
              fundBoxHeaderText={selfSelectFundBoxHeaderText}
              headerMarkdown={selfSelectFundHeaderMarkdown}
              confirmationModalTitle={confirmationModalTitle}
              confirmationModalText={confirmationModalText}
              confirmationSubheadingText={confirmationSubheadingText}
            />
          </SelfSelectLayout>
        )
      }}
    </PayloadUnwrapperM>
  )
}

export default SelfSelectFormPage
