import * as React from 'react';
export const SvgBuildingssmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#EEF6FF" }),
        React.createElement("path", { d: "M14 9C14.55 9 15 8.55 15 8V7C15 6.45 14.55 6 14 6C13.45 6 13 6.45 13 7V8C13 8.55 13.45 9 14 9ZM14 19C14.55 19 15 18.55 15 18V17C15 16.45 14.55 16 14 16C13.45 16 13 16.45 13 17V18C13 18.55 13.45 19 14 19ZM14 14C14.55 14 15 13.55 15 13V12C15 11.45 14.55 11 14 11C13.45 11 13 11.45 13 12V13C13 13.55 13.45 14 14 14ZM10 19C10.55 19 11 18.55 11 18V17C11 16.45 10.55 16 10 16C9.45 16 9 16.45 9 17V18C9 18.55 9.45 19 10 19ZM20 7H18V4C18 3.45 17.55 3 17 3H7C6.45 3 6 3.45 6 4V7H4C3.45 7 3 7.45 3 8V21C3 21.55 3.45 22 4 22H20C20.55 22 21 21.55 21 21V8C21 7.45 20.55 7 20 7ZM6 20H5V9H6V20ZM16 8V20H8V5H16V8ZM19 20H18V9H19V20ZM10 9C10.55 9 11 8.55 11 8V7C11 6.45 10.55 6 10 6C9.45 6 9 6.45 9 7V8C9 8.55 9.45 9 10 9ZM10 14C10.55 14 11 13.55 11 13V12C11 11.45 10.55 11 10 11C9.45 11 9 11.45 9 12V13C9 13.55 9.45 14 10 14Z", fill: "#406794" })));
};
