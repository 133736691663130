export const asPercentages = ({ forCount, againstCount, abstainCount, withholdCount, noActionCount, }) => {
    const total = forCount + againstCount + abstainCount + withholdCount + noActionCount;
    return {
        forPercentage: (forCount / total) * 100,
        againstPercentage: (againstCount / total) * 100,
        abstainPercentage: (abstainCount / total) * 100,
        withholdPercentage: (withholdCount / total) * 100,
        noActionPercentage: (noActionCount / total) * 100,
    };
};
export const asRoundedPercentages = (tally) => {
    const { forPercentage, againstPercentage, abstainPercentage, withholdPercentage, noActionPercentage } = asPercentages(tally);
    return {
        forPercentage: Math.round(forPercentage),
        againstPercentage: Math.round(againstPercentage),
        abstainPercentage: Math.round(abstainPercentage),
        withholdPercentage: Math.round(withholdPercentage),
        noActionPercentage: Math.round(noActionPercentage),
    };
};
