import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationGeneralMeetings } from '@tumelo/shared'
import { Payload } from '../../payload'

const getInitialState: () => Payload<OrganizationGeneralMeetings> = () => 'not-initialised'

const generalMeetingsSlice = createSlice({
  name: 'generalMeetings',
  initialState: getInitialState(),
  reducers: {
    set(state, action: PayloadAction<Payload<OrganizationGeneralMeetings>>) {
      return action.payload
    },
  },
})

export default generalMeetingsSlice.reducer

export const { set } = generalMeetingsSlice.actions
