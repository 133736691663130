/*eslint-disable*/
// @ts-nocheck
/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";

export const protobufPackage = "tumelo.platform.v1";

export interface PushSubscription {
  /**
   * GENERATED the identifier of the push subscription.
   * format `userpools/{userpoolID}/users/{sub}/pushSubscriptions/{uuid}`
   */
  name: string;
  /** GENERATED at the time of creation. */
  createTime:
    | Date
    | undefined;
  /** REQUIRED a string containing the endpoint associated with the push subscription. */
  endpoint: string;
  /** OPTIONAL a timestamp of the subscription expiry time associated with the push subscription. */
  expiryTime:
    | Date
    | undefined;
  /** REQUIRED the subscription encryption data generated by the external web push server */
  keys: PushSubscriptionKeys | undefined;
}

export interface PushSubscriptionKeys {
  /** REQUIRED An Elliptic curve Diffie–Hellman public key on the P-256 curve (that is, the NIST secp256r1 elliptic curve). The resulting key is an uncompressed point in ANSI X9.62 format. */
  p256dh: string;
  /** REQUIRED An authentication secret, as described in Message Encryption for Web Push (https://datatracker.ietf.org/doc/html/draft-ietf-webpush-encryption-08) */
  auth: string;
}

function createBasePushSubscription(): PushSubscription {
  return { name: "", createTime: undefined, endpoint: "", expiryTime: undefined, keys: undefined };
}

export const PushSubscription = {
  encode(message: PushSubscription, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.createTime !== undefined) {
      Timestamp.encode(toTimestamp(message.createTime), writer.uint32(42).fork()).ldelim();
    }
    if (message.endpoint !== "") {
      writer.uint32(18).string(message.endpoint);
    }
    if (message.expiryTime !== undefined) {
      Timestamp.encode(toTimestamp(message.expiryTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.keys !== undefined) {
      PushSubscriptionKeys.encode(message.keys, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PushSubscription {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePushSubscription();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endpoint = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expiryTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.keys = PushSubscriptionKeys.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PushSubscription {
    return {
      name: isSet(object.name) ? String(object.name) : "",
      createTime: isSet(object.createTime) ? fromJsonTimestamp(object.createTime) : undefined,
      endpoint: isSet(object.endpoint) ? String(object.endpoint) : "",
      expiryTime: isSet(object.expiryTime) ? fromJsonTimestamp(object.expiryTime) : undefined,
      keys: isSet(object.keys) ? PushSubscriptionKeys.fromJSON(object.keys) : undefined,
    };
  },

  toJSON(message: PushSubscription): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.createTime !== undefined) {
      obj.createTime = message.createTime.toISOString();
    }
    if (message.endpoint !== "") {
      obj.endpoint = message.endpoint;
    }
    if (message.expiryTime !== undefined) {
      obj.expiryTime = message.expiryTime.toISOString();
    }
    if (message.keys !== undefined) {
      obj.keys = PushSubscriptionKeys.toJSON(message.keys);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PushSubscription>, I>>(base?: I): PushSubscription {
    return PushSubscription.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PushSubscription>, I>>(object: I): PushSubscription {
    const message = createBasePushSubscription();
    message.name = object.name ?? "";
    message.createTime = object.createTime ?? undefined;
    message.endpoint = object.endpoint ?? "";
    message.expiryTime = object.expiryTime ?? undefined;
    message.keys = (object.keys !== undefined && object.keys !== null)
      ? PushSubscriptionKeys.fromPartial(object.keys)
      : undefined;
    return message;
  },
};

function createBasePushSubscriptionKeys(): PushSubscriptionKeys {
  return { p256dh: "", auth: "" };
}

export const PushSubscriptionKeys = {
  encode(message: PushSubscriptionKeys, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.p256dh !== "") {
      writer.uint32(10).string(message.p256dh);
    }
    if (message.auth !== "") {
      writer.uint32(18).string(message.auth);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PushSubscriptionKeys {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePushSubscriptionKeys();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.p256dh = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.auth = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PushSubscriptionKeys {
    return {
      p256dh: isSet(object.p256dh) ? String(object.p256dh) : "",
      auth: isSet(object.auth) ? String(object.auth) : "",
    };
  },

  toJSON(message: PushSubscriptionKeys): unknown {
    const obj: any = {};
    if (message.p256dh !== "") {
      obj.p256dh = message.p256dh;
    }
    if (message.auth !== "") {
      obj.auth = message.auth;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PushSubscriptionKeys>, I>>(base?: I): PushSubscriptionKeys {
    return PushSubscriptionKeys.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PushSubscriptionKeys>, I>>(object: I): PushSubscriptionKeys {
    const message = createBasePushSubscriptionKeys();
    message.p256dh = object.p256dh ?? "";
    message.auth = object.auth ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
