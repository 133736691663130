import * as React from 'react'
import { ConfirmCodeValues } from '../components/Auth/Forms/ConfirmCodeForm'
import { ConfirmCodePage } from '../components/Auth/Pages/ConfirmCodePage'
import { useAppDispatch, useAppSelector } from '../application/store'
import { confirmSignUp, resendSignupCode } from '../application/features/auth/asyncActions'
import { setAuthState } from '../application/features/auth'
import { selectAuthUser } from '../application/features/auth/selectors'

export const ConfirmSignUp: React.FC = () => {
  const dispatch = useAppDispatch()
  const { email } = useAppSelector(selectAuthUser)
  const submitCode = async (values: ConfirmCodeValues) => {
    const { code } = values
    dispatch(confirmSignUp(code))
  }

  const resendCode = async () => {
    dispatch(resendSignupCode())
  }

  return (
    <ConfirmCodePage
      submit={submitCode}
      goToSignIn={() => dispatch(setAuthState('signIn'))}
      resendCode={resendCode}
      email={email ?? ''}
    />
  )
}
