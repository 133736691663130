import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { fetchInvestorAndPushTracking } from './asyncActions'
import { selectInvestor } from './investorSelector'

export const useInvestor = () => {
  const dispatch = useAppDispatch()
  const investor = useAppSelector(selectInvestor)

  useEffect(() => {
    if (investor === 'not-initialised') {
      dispatch(fetchInvestorAndPushTracking())
    }
  }, [investor, dispatch])

  return { investor }
}
