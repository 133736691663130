import React, { ReactNode } from 'react'
import { BallotWithPollAndOrg } from '../../application/types/PollWithOrganization/PollWithOrganization'
import { PollCardlet } from '../Cardlet/PollCardlet'
import { ListContainer } from './ListContainer'

interface Props {
  polls: BallotWithPollAndOrg[]
  title: string | ReactNode
  icon?: ReactNode
}

export const PollsList: React.FC<Props> = ({ polls, title, icon }) => (
  <ListContainer title={title} icon={icon}>
    {polls.map((poll) => (
      <PollCardlet key={poll.poll.id} poll={poll} navigateTo="openVote" />
    ))}
  </ListContainer>
)
