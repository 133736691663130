import React, { useEffect } from 'react'
import { useRouter } from '../../application/router/useRouter'
import { isValueState } from '../../application/payload'
import { buildEvent } from '../../application/analytics/eventBuilders'
import { useAnalytics } from '../../application/analytics'
import { useMostVotedOnOpenPoll } from '../../application/features/featuredVotes/hooks'
import { GenericPollGridCard } from './GenericPollGridCard'

export const YourColleaguesVotedCardContainer: React.FC = () => {
  const mostPopularOpenVote = useMostVotedOnOpenPoll()

  const { navigate } = useRouter()
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    trackEvent(buildEvent.returningCardRendered({ card_type: 'your-colleagues-voted-card' }))
  }, [trackEvent])

  const onClick = () => {
    trackEvent(buildEvent.returningCardCTAClicked({ card_type: 'your-colleagues-voted-card' }))
    if (isValueState(mostPopularOpenVote)) {
      navigate({ pathname: `/votes/open/${mostPopularOpenVote?.poll.id}` })
    }
  }

  return (
    <GenericPollGridCard title="Your fellow users voted" showPollTally vote={mostPopularOpenVote} onClick={onClick} />
  )
}
