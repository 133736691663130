import React from 'react'
import { ExplicitPageView } from '../../../application/analytics'
import { ForgotPasswordFormValues, ForgotPasswordForm, ResetPasswordFormValues } from '../Forms/ForgotPasswordForm'
import { changeTitle } from '../../../containers/changeTitle'
import { useAppSelector } from '../../../application/store'
import { selectAuthError, selectAuthPending } from '../../../application/features/auth/selectors'

interface Props {
  goToSignIn: () => void
  submit: (values: ResetPasswordFormValues) => Promise<void>
  email: string
  sendCode: (values: ForgotPasswordFormValues) => Promise<void>
  submitView: boolean
}

export const ForgotPasswordPage: React.FC<Props> = ({ submit, email, sendCode, goToSignIn, submitView }) => {
  changeTitle('Forgot Password')
  const error = useAppSelector(selectAuthError)
  const authActionPending = useAppSelector(selectAuthPending)

  const onForgotPassword = async (values: ForgotPasswordFormValues) => {
    await sendCode(values)
  }

  const onResetPassword = async (values: ResetPasswordFormValues) => {
    await submit(values)
  }

  return (
    <>
      <ExplicitPageView page="/forgot-password" />
      <ForgotPasswordForm
        submit={onResetPassword}
        email={email}
        sendCode={onForgotPassword}
        submitView={submitView}
        goToSignIn={goToSignIn}
        error={error?.message}
        pending={authActionPending}
      />
    </>
  )
}
