import { useEffect } from 'react'
import { TumeloAccount } from '@tumelo/shared'
import { useAppDispatch, useAppSelector } from '../../store'
import { isValueState, Payload } from '../../payload'
import { RootState } from '../../rootReducer'
import { fetchAccountComposition } from './asyncActions'

export const useAccountComposition = (account: Payload<TumeloAccount | 'not-configured'>) => {
  const dispatch = useAppDispatch()
  const { accountComposition } = useAppSelector((state: RootState) => state.accountComposition)

  useEffect(() => {
    if (isValueState(account) && account !== 'not-configured' && accountComposition === 'not-initialised') {
      dispatch(fetchAccountComposition())
    }
  }, [account, dispatch, accountComposition])

  return { accountComposition }
}
