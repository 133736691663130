import { IndustryCategory } from '../../domain/NAICS';
export const getInitialOrganizationIndustries = () => ({
    [IndustryCategory.AGRICULTURE_AND_FOOD]: false,
    [IndustryCategory.ENERGY_AND_UTILITIES]: false,
    [IndustryCategory.FINANCE]: false,
    [IndustryCategory.HEALTHCARE]: false,
    [IndustryCategory.INDUSTRIAL]: false,
    [IndustryCategory.LIFESTYLE]: false,
    [IndustryCategory.REAL_ESTATE]: false,
    [IndustryCategory.RETAIL]: false,
    [IndustryCategory.TECH]: false,
    [IndustryCategory.TRANSPORT_AND_VEHICLES]: false,
    [IndustryCategory.SPECIALIST_SERVICES]: false,
    [IndustryCategory.UNKNOWN]: false,
});
