import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgEyeclosed = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("g", { clipPath: "url(#clip0_317_806)" },
            React.createElement("path", { d: "M23.93 22.62C23.88 22.5 23.81 22.39 23.72 22.29L1.71 0.29C1.52 0.1 1.27 0 1 0C0.73 0 0.48 0.11 0.29 0.29C0.1 0.48 0 0.73 0 1C0 1.27 0.11 1.52 0.29 1.71L4.59 6C2.77 7.55 1.26 9.43 0.12 11.53C0.05 11.67 0.01 11.83 0.01 11.99C0.01 12.15 0.05 12.31 0.12 12.45C0.28 12.8 4.47 21 12 21C14.06 20.97 16.08 20.36 17.81 19.24L22.27 23.71C22.36 23.8 22.47 23.88 22.6 23.93C22.72 23.98 22.85 24.01 22.98 24.01C23.11 24.01 23.24 23.98 23.36 23.93C23.48 23.88 23.59 23.8 23.69 23.71C23.79 23.62 23.86 23.51 23.91 23.39C23.96 23.27 23.99 23.14 23.99 23.01C23.99 22.88 23.97 22.75 23.92 22.62H23.93ZM10.01 11.44L12.57 14C12.38 14.07 12.17 14.1 11.97 14.1C11.7 14.11 11.44 14.07 11.19 13.96C10.94 13.86 10.71 13.71 10.52 13.52C10.33 13.33 10.18 13.11 10.08 12.86C9.97 12.59 9.92 12.29 9.94 12C9.94 11.81 9.96 11.62 10.01 11.44ZM12.01 19C6.64 19 3.09 13.61 2.15 12C3.16 10.25 4.46 8.7 6.01 7.4L8.55 10C8.45 10.16 8.36 10.33 8.28 10.5C8.07 10.97 7.95 11.48 7.94 12C7.94 12.54 8.03 13.07 8.23 13.57C8.43 14.07 8.73 14.52 9.11 14.9C9.49 15.28 9.94 15.58 10.44 15.78C10.94 15.99 11.47 16.09 12.01 16.07C12.54 16.07 13.07 15.95 13.56 15.73C13.73 15.65 13.9 15.56 14.06 15.46L16.38 17.79C15.05 18.56 13.55 18.98 12.01 19ZM9.22 4.97L20.14 15.9C20.33 16.08 20.59 16.19 20.85 16.19C20.99 16.19 21.12 16.15 21.25 16.09C21.37 16.03 21.48 15.94 21.57 15.83C22.45 14.8 23.22 13.67 23.85 12.47C23.92 12.33 23.96 12.17 23.96 12.01C23.96 11.85 23.92 11.69 23.85 11.55C23.73 11.2 19.54 3 12.01 3C11.23 3 10.46 3.09 9.7 3.27C9.53 3.31 9.37 3.4 9.24 3.52C9.11 3.64 9.02 3.8 8.97 3.97C8.92 4.14 8.91 4.33 8.96 4.5C9 4.68 9.1 4.84 9.22 4.97ZM21.87 12C21.53 12.58 21.16 13.15 20.76 13.69L12.07 5C17.41 5.05 20.92 10.4 21.86 12H21.87Z" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_317_806" },
                React.createElement("rect", { width: 24, height: 24, fill: "white" })))));
};
