import React from 'react';
import styled from 'styled-components';
import { linkStyles } from '../_styles/linkStyles';
export const LinkButton = ({ children, className, onClick, size = 'default', colour = 'default', }) => {
    return (React.createElement(StyledButton, { size: size, colour: colour, className: className, onClick: onClick }, children));
};
const StyledButton = styled.button `
  ${linkStyles}
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;
