import { IndustryCategory, NAICSCode, naicsCodeToIndustryCategory } from '../NAICS';
export const sampleOrganization = (override) => (Object.assign({ id: 'd0837a77-eb77-44d1-894b-20e8762a3f8d', title: 'Royal Dutch Shell', logoUrl: 'https://res.cloudinary.com/tumelo/image/upload/w_128,h_128,c_fit/v1580299678/jfjmrhjlsaxwbh0klnxx.png', websiteUrl: 'https://shell.com', bio: {
        description: 'Royal Dutch Shell PLC, commonly known as Shell, is a British-Dutch oil and gas company headquartered in the Netherlands and incorporated in the United Kingdom. It is one of the oil and gas supermajors and the third-largest company in the world measured by 2018 revenues (and the largest based in Europe).',
        sourceUrl: 'https://en.wikipedia.org/wiki/Royal_Dutch_Shell',
        source: 'Wikipedia',
    }, naicsCode: NAICSCode.OIL_AND_GAS_EXTRACTION, industryCategory: naicsCodeToIndustryCategory(NAICSCode.OIL_AND_GAS_EXTRACTION), headquarters: {
        isoCountryCode2: 'BT',
    } }, override));
export const sampleOrganizationWithTumeloBio = () => sampleOrganization({
    bio: {
        description: 'Royal Dutch Shell PLC, commonly known as Shell, is a British-Dutch oil and gas company headquartered in the Netherlands and incorporated in the United Kingdom. It is one of the oil and gas supermajors and the third-largest company in the world measured by 2018 revenues (and the largest based in Europe).',
        source: 'Tumelo',
    },
});
export const sampleOrganizationWithMinimum = () => ({
    title: 'Royal Dutch Shell',
    id: '19b01336-3874-4911-b7dd-dc2c6d57bfe5',
    industryCategory: IndustryCategory.ENERGY_AND_UTILITIES,
});
