import { add, isAfter } from 'date-fns'
import { dateToTimestamp, timestampToDate, HabitatStatistics, InvestorStatistics, Poll, PollId } from '@tumelo/shared'
import { seededRandomInteger } from '../../../utils/seededShuffle'
import { partition } from '../../../utils/functional/arr'
import { PollService } from './PollService'
import { PollServiceInjectedFetch } from './PollServiceInjectedFetch'
import { findInvestorOpenVotesCloseToExpiration, findInvestorOpenVoteWithMostVotes } from './utils'

const getPollTally = (pollId: string) => {
  const forCount = seededRandomInteger(`for${pollId}`, 10000, 20000)
  const againstCount = seededRandomInteger(`against${pollId}`, 10000, 20000)
  const abstainCount = seededRandomInteger(`abstain${pollId}`, 10000, 20000)
  const withholdCount = seededRandomInteger(`withhold${pollId}`, 10000, 20000)
  const noActionCount = seededRandomInteger(`noAction${pollId}`, 10000, 20000)
  return { forCount, againstCount, abstainCount, withholdCount, noActionCount }
}

/**
 * PollServiceDemoInjectedFetch is a class for injecting demodata into a fetch.
 *
 * The demo injection usees demo data defined in a JSON file set in the config, which is injected
 * in the inherited classes
 *
 * This class then partitions the data between open and closed poll states using a const list of
 * IDs to specify which polls should be set to open, this ensures consistent data.
 *
 * The polls are partitioned between open and closed to allow for the addition of a constant
 * endDate to the polls that corresponds to the expected state and current date.
 * We also ensure the data format is as expected for polls that are either open
 * or closed.
 *
 */
export class PollServiceDemoInjectedFetch extends PollServiceInjectedFetch implements PollService {
  private demoPolls?: Map<string, Poll>

  async listAllPolls(): Promise<{ polls: Poll[] }> {
    if (!this.demoPolls) {
      this.demoPolls = await this.partitionOpenClosedPolls()
    }
    const polls = Array.from(this.demoPolls.values()).map((poll) => {
      if (
        [
          '77ce0a02-dee7-4264-84e3-7c9fb9bb9ce0' as PollId,
          '9001ecad-7701-468e-956e-1c185a06472e' as PollId,
          'f06ede97-fd4c-4c18-b8c6-824b05439539' as PollId,
        ].includes(poll.id)
      ) {
        return { ...poll, publishAt: dateToTimestamp(new Date()) }
      }
      return poll
    })
    return { polls }
  }

  async fetchRecentPolls(): Promise<{ polls: Poll[] }> {
    if (!this.demoPolls) {
      this.demoPolls = await this.partitionOpenClosedPolls()
    }
    // Filter to only get the polls that are open
    const polls = Array.from(this.demoPolls.values())
      .filter((poll) => isAfter(timestampToDate(poll.endDate), add(new Date(), { days: -10 })))
      .map((poll) => {
        if (
          [
            '77ce0a02-dee7-4264-84e3-7c9fb9bb9ce0' as PollId,
            '9001ecad-7701-468e-956e-1c185a06472e' as PollId,
            'f06ede97-fd4c-4c18-b8c6-824b05439539' as PollId,
          ].includes(poll.id)
        ) {
          return { ...poll, publishAt: dateToTimestamp(new Date()) }
        }
        return poll
      })
    return { polls }
  }

  async listOpenPollsForOrganizations(organizationIds: string[]): Promise<Map<string, Poll[]>> {
    if (!this.demoPolls) {
      this.demoPolls = await this.partitionOpenClosedPolls()
    }
    const orgToPollsMap = organizationIds.reduce((map, id) => {
      map.set(
        id,
        Array.from(this.demoPolls?.values() ?? []).filter((p) => p.relationships.generalMeeting.organizationId === id)
      )
      return map
    }, new Map<string, Poll[]>())

    return orgToPollsMap
  }

  async getPoll(pollId: PollId) {
    if (!this.demoPolls) {
      this.demoPolls = await this.partitionOpenClosedPolls()
    }
    const poll = this.demoPolls.get(pollId)
    if (!poll) {
      return undefined
    }
    const res = await super.getPoll(pollId)
    if (!res) {
      return undefined
    }
    const { organization } = res
    return { poll, organization }
  }

  async getInvestorOpenVotesCloseToExpiration() {
    if (!this.demoPolls) {
      this.demoPolls = await this.partitionOpenClosedPolls()
    }

    const polls = findInvestorOpenVotesCloseToExpiration(Array.from(this.demoPolls.values())) as Poll[]

    const res1 = await super.getPoll(polls[0].id)
    const res2 = await super.getPoll(polls[0].id)

    if (!res1 || !res2) {
      return undefined
    }
    const { organization: org1 } = res1
    const { organization: org2 } = res2

    return [
      { poll: polls[0], organization: org1 },
      { poll: polls[1], organization: org2 },
    ]
  }

  async getInvestorStatistics(): Promise<InvestorStatistics> {
    return { pollTagsVoteCount: [] }
  }

  async getHabitatStatistics(): Promise<HabitatStatistics> {
    if (!this.demoPolls) {
      this.demoPolls = await this.partitionOpenClosedPolls()
    }
    return {
      mostVotedOnOpenPoll: {
        pollId: findInvestorOpenVoteWithMostVotes(Array.from(this.demoPolls.values()))?.id as PollId,
        voteCount: 23370,
      },
    }
  }

  private async partitionOpenClosedPolls(): Promise<Map<string, Poll>> {
    const { polls } = await super.listAllPolls()
    const [open, closed] = partition(polls, (p) => !!demoOpenPollIds.find((op) => op === p.id))
    const demoPolls = new Map<string, Poll>()
    // TODO why do we use a forEach for open and a map for closed
    open.forEach((p, i) =>
      demoPolls.set(p.id, {
        ...p,
        tally: getPollTally(p.id),
        relationships: {
          ...p.relationships,
          proposal: {
            ...p.relationships.proposal,
            outcome: undefined,
          },
        },
        endDate: dateToTimestamp(getConstantDistanceDate(i + 2)),
      })
    )
    closed.map((p, i) =>
      demoPolls.set(p.id, {
        ...p,
        tally: getPollTally(p.id),
        endDate: dateToTimestamp(getConstantDistanceDate(-2 - i)),
      })
    )
    return demoPolls
  }
}

const getConstantDistanceDate = (days: number): Date => {
  return add(new Date(), { days })
}

const demoOpenPollIds = [
  '658161d5-c093-4ced-91d8-80578230ec2e',
  'f4e448d3-a5c4-4de7-9c08-f68089667f64',
  'a5bce1c7-09ab-40d1-80af-e64bea407227',
  'b6307fee-baa0-4581-b223-53dadbc2968d',
  '1bcdcb4e-a5d5-483d-85ef-836355e1b0b6',
  '9328fe30-f932-4433-ad62-60c0a3d88939',
  'adcf1cab-a3f0-4668-ab8d-5ef7eee0ebbc',
  '484ab195-b2a2-4de0-9168-566289024274',
  '929dbdbe-3933-4661-adc8-62c55ca82b9f',
  'ad183250-0445-4ebc-8967-b0e7e8e95b06',
  '6e2e6fb8-7a04-4fe0-90d1-1d4a0e6a1916',
  '248eaee8-bd23-4c68-b9dd-f4e74e8021e0',
  'a1f858b3-0ae9-48b9-b1a8-6c69aedc2ee1',
  'e92a7875-3a8f-4365-8390-7a870ab52dde',
  '26b28c27-bcbc-40c9-966f-d76f9039e184',
  '09f3b8f2-4297-4215-83d7-a71d9b7350a4',
  'e651651f-b5f1-4a72-b752-fc7193339d37',
  '3cceb10b-59a9-42bb-9f26-a3500f7b223e',
  '4319925e-0ac1-47f3-a702-ed8d2f7e24ac',
  'd7689f93-17bf-4962-ae0d-23e18fc6c5a5',
  '77ce0a02-dee7-4264-84e3-7c9fb9bb9ce0',
  '9001ecad-7701-468e-956e-1c185a06472e',
  '4d561618-c80a-477e-a262-d22d3ad00df3',
  'f06ede97-fd4c-4c18-b8c6-824b05439539',
  // act-demo
  '84748d27-1236-4348-8dfb-e95ee11ce6f8',
  'b1971d3f-4e01-460d-a43b-0699877f1e20',
  'b2715c95-7d71-4e67-b1c5-c8f300de0ad8',
  '02dde7c1-bac7-48ae-bb88-0ebc1813d1ab',
]
