import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Body2, Caption, DefaultBoxShadow, Stack } from '..';
export const MediaCard = ({ preTitle, subTitle, preTitleImgUrl, description, LargeImgUrl, footer, imgHeight, preTitleImgSize, children, }) => {
    const { colors } = useTheme();
    const alignItems = subTitle ? undefined : 'center';
    const stackGap = subTitle ? undefined : '1.6rem';
    return (React.createElement(StyledCard, null,
        LargeImgUrl && React.createElement(StyledLargeImg, { src: LargeImgUrl, alt: "", crossOrigin: "", height: imgHeight !== null && imgHeight !== void 0 ? imgHeight : 'auto' }),
        React.createElement(StyledSection, null,
            preTitle && (React.createElement(Stack, { flexDirection: "row", alignItems: alignItems, mb: stackGap },
                preTitleImgUrl && !LargeImgUrl && (React.createElement(StyledPreTitleImg, { src: preTitleImgUrl, alt: "", width: preTitleImgSize !== null && preTitleImgSize !== void 0 ? preTitleImgSize : '32px', height: preTitleImgSize !== null && preTitleImgSize !== void 0 ? preTitleImgSize : '32px', crossOrigin: "" })),
                subTitle ? (React.createElement(Stack, { flexDirection: "column", alignItems: "flex-start", rowGap: "0" },
                    React.createElement(Caption, { semibold: true, mb: "0" }, preTitle),
                    React.createElement(Body2, { color: colors.grey.dark }, subTitle))) : (React.createElement(Caption, { semibold: true, mb: "0" }, preTitle)))),
            description && (React.createElement(Body2, { semibold: true, mb: "1.6rem" }, description)),
            children),
        footer && React.createElement(StyledFooter, null, footer)));
};
const StyledCard = styled.div `
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  ${DefaultBoxShadow}
`;
const StyledSection = styled.div `
  padding: 1.6rem;
  flex-grow: 1;
`;
const StyledLargeImg = styled.img `
  object-fit: cover;
  object-position: center;
  width: 100%;
`;
const StyledFooter = styled.div `
  justify-self: flex-end;
  padding: 0 1.6rem 1.6rem 1.6rem;
  margin: 0;
`;
const StyledPreTitleImg = styled.img `
  object-fit: contain;
`;
