import React, { useState } from 'react'
import { updatePassword } from 'aws-amplify/auth'
import { BasePageProps } from '@tumelo/shared'
import { useAppDispatch } from '../../application/store'
import { updateInvestorEmail } from '../../application/features/investor/asyncActions'
import { useInvestor } from '../../application/features/investor/useInvestor'
import { isValueState } from '../../application/payload'
import { changeTitle } from '../../containers/changeTitle'
import { ChangeEmailForm, ChangeEmailFormValues } from './ChangeEmailForm'

type Props = BasePageProps

const ChangeEmailPage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)

  const dispatch = useAppDispatch()
  const { investor } = useInvestor()
  const [error, setError] = useState<string | undefined>()

  React.useEffect(() => {
    if (isValueState(investor)) {
      setError(investor.changeEmail.error)
    }
  }, [investor])

  const onChangeEmail = async (values: ChangeEmailFormValues) => {
    try {
      setError(undefined)

      // aws amplify currently does not allow you to verify a users password
      // https://github.com/aws-amplify/amplify-js/issues/3661
      // There is a hack that I am going to implement where we try set a new password
      // as the old password and check the status of that operation
      await updatePassword({ oldPassword: values.password, newPassword: values.password })
        .then(() => {
          dispatch(updateInvestorEmail(values.email))
        })
        .catch((error) => {
          if (error.code === 'NotAuthorizedException') {
            setError('The password you have entered is incorrect')
          } else {
            setError(error.message)
          }
        })
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- typescript getting stricter in errors
      // @ts-ignore
      setError(e.message)
    }
  }

  return <ChangeEmailForm submit={onChangeEmail} error={error} />
}

export default ChangeEmailPage
