export var VoteMethod;
(function (VoteMethod) {
    VoteMethod["Automatic"] = "automatic";
    VoteMethod["Manual"] = "manual";
})(VoteMethod || (VoteMethod = {}));
export const mapInvestorVoteResponseText = (response) => {
    return {
        ['for']: 'In favour',
        ['against']: 'Against',
        ['abstain']: 'To abstain',
        ['withhold']: 'Withhold',
        ['no_action']: 'No action',
    }[response];
};
