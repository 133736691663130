import React from 'react';
import styled from 'styled-components';
export const Required = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledRequired, { "aria-hidden": "true" }, " *\u00A0"),
        React.createElement("span", { className: "sr-only" }, " required")));
};
const StyledRequired = styled.span `
  display: inline-block;
  margin-top: -0.9rem;
  margin-left: 0.3rem;
`;
