import { Instrument, MifirCode } from '@tumelo/shared'

export enum InstrumentCategory {
  Fund = 'Fund',
  Stock = 'Stock',
  Bond = 'Bond',
}

export const getInstrumentCategory = (instrument: Instrument): InstrumentCategory => {
  switch (instrument.mifirCode) {
    case MifirCode.BOND:
      return InstrumentCategory.Bond
    case MifirCode.SHRS:
      if (instrument.isComposite) {
        return InstrumentCategory.Fund
      }
      return InstrumentCategory.Stock
    case MifirCode.ETFS:
    case MifirCode.ETCS:
      return InstrumentCategory.Fund
    default:
      if (instrument.isComposite) {
        return InstrumentCategory.Fund
      }
      throw new Error(`unable to categorise ${JSON.stringify(instrument)}`)
  }
}
