import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgFile = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.92 7.61C20.8713 7.49081 20.7999 7.38215 20.71 7.29L14.71 1.29C14.6178 1.20005 14.5092 1.12874 14.39 1.08C14.266 1.02962 14.1338 1.0025 14 1H6C5.20435 1 4.44129 1.31607 3.87868 1.87868C3.31607 2.44129 3 3.20435 3 4V20C3 20.7956 3.31607 21.5587 3.87868 22.1213C4.44129 22.6839 5.20435 23 6 23H18C18.7956 23 19.5587 22.6839 20.1213 22.1213C20.6839 21.5587 21 20.7956 21 20V8C20.9975 7.86619 20.9704 7.73399 20.92 7.61ZM15 4.41L17.59 7H15V4.41ZM19 20C19 20.2652 18.8946 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21H6C5.73478 21 5.48043 20.8946 5.29289 20.7071C5.10536 20.5196 5 20.2652 5 20V4C5 3.73478 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 3 6 3H13V8C13 8.26522 13.1054 8.51957 13.2929 8.70711C13.4804 8.89464 13.7348 9 14 9H19V20Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13H16C16.2652 13 16.5196 12.8946 16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12C17 11.7348 16.8946 11.4804 16.7071 11.2929C16.5196 11.1054 16.2652 11 16 11H8C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 14.62H8C7.73478 14.62 7.48043 14.7254 7.29289 14.9129C7.10536 15.1004 7 15.3548 7 15.62C7 15.8852 7.10536 16.1396 7.29289 16.3271C7.48043 16.5146 7.73478 16.62 8 16.62H16C16.2652 16.62 16.5196 16.5146 16.7071 16.3271C16.8946 16.1396 17 15.8852 17 15.62C17 15.3548 16.8946 15.1004 16.7071 14.9129C16.5196 14.7254 16.2652 14.62 16 14.62Z" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 9.38H10C10.2652 9.38 10.5196 9.27464 10.7071 9.08711C10.8946 8.89957 11 8.64522 11 8.38C11 8.11478 10.8946 7.86043 10.7071 7.67289C10.5196 7.48536 10.2652 7.38 10 7.38H8C7.73478 7.38 7.48043 7.48536 7.29289 7.67289C7.10536 7.86043 7 8.11478 7 8.38C7 8.64522 7.10536 8.89957 7.29289 9.08711C7.48043 9.27464 7.73478 9.38 8 9.38Z" })));
};
