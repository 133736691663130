import React from 'react'
import { ModalImage, H1, Body1, ModalBody, ModalFooter, ArrowRightIcon } from '@tumelo/designsystem'

interface Props {
  goForward: () => void
}

export const PoliciesIntroductionTwo: React.FC<Props> = ({ goForward }) => {
  return (
    <>
      <ModalBody fullScreen>
        <ModalImage src="/images/voting_policy_step_two.png" />
        <div>
          <H1>What&apos;s a voting policy?</H1>
          <Body1>
            Voting policies are crafted by industry experts. Each policy will champion a different set of priorities or
            ethos, and will recommend voting decisions to support those aims.
          </Body1>
          <Body1>
            All you need to do is decide which policy best represents your values - and let the tech do the rest.
          </Body1>
        </div>
      </ModalBody>
      <ModalFooter
        stickyButtons={[
          { label: "Let's do it", variant: 'primary', iconAfter: <ArrowRightIcon />, onClick: goForward },
        ]}
      />
    </>
  )
}
