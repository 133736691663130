import React from 'react';
import styled from 'styled-components';
export const IconBackground = ({ color, children }) => {
    return React.createElement(StyledDiv, { color: color }, children);
};
const StyledDiv = styled.div `
  background: ${({ color }) => color};
  border-radius: 5px;
  padding: 4px;
  display: inline-flex;
  align-self: flex-start;
`;
