import React, { ComponentProps, FC } from 'react'
import { PageHeader as PageHeaderUI } from '@tumelo/shared'
import { useRouter } from '../../application/router/useRouter'

type Props = ComponentProps<typeof PageHeaderUI>

interface PageHeaderProps extends Props {
  iconOnlyAltText?: string
}

export const PageHeader: FC<PageHeaderProps> = ({ iconOnlyAltText, onClickGoBack, ...rest }) => {
  const { back } = useRouter()
  const clickHandler = onClickGoBack || back
  const iconAltText = () => {
    if (iconOnlyAltText) {
      return iconOnlyAltText
    }
    if (!onClickGoBack) {
      return 'Back Button'
    }
    return undefined
  }

  return <PageHeaderUI iconOnlyAltText={iconAltText()} onClickGoBack={clickHandler} {...rest} />
}
