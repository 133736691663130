import * as z from 'myzod'
import { SoftConfigSchema } from './SoftConfig'
import { ConfigAwsSchema } from './ConfigAws'

export const ConfigSchema = z
  .object(
    {
      habitat: z.string(),
      mixpanel: z.object({
        apiHost: z.string(),
        token: z.string(),
      }),
      defaultModelPortfolioId: z.string().optional(),
      softConfig: SoftConfigSchema,
      enableConfigWidget: z.boolean().optional(),
      aws: ConfigAwsSchema,
      plannedMaintenance: z.boolean().optional(),
    },
    {
      allowUnknown: true,
    }
  )
  .default({
    habitat: 'undefined',
    mixpanel: { apiHost: '', token: '' },
    softConfig: SoftConfigSchema.parse(),
    aws: ConfigAwsSchema.parse(),
  })

export interface Config extends z.Infer<typeof ConfigSchema> {
  services?: unknown
}
