import React, { Suspense } from 'react'
import { Loader } from '@tumelo/shared'
import { ErrorBoundary } from '../../components/ErrorBoundary'

const HasFundChangedPageLazy = React.lazy(() => import('./HasFundChangedPageLazy'))

const HasFundChangedPage: React.FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <HasFundChangedPageLazy />
      </Suspense>
    </ErrorBoundary>
  )
}

export default HasFundChangedPage
