import {
  Composition,
  Instrument,
  sampleCompositeInstrument,
  sampleNonCompositeInstrument,
  sampleComposition,
  instrumentReferenceToString,
  InstrumentReferenceString,
} from '@tumelo/shared'
import { getInstrumentCategory, InstrumentCategory } from '../Instrument/Instrument'

export type CompositionWithInstruments = {
  instruments: { instrument: Instrument; percentageWeight: number }[]
  cash: { currency: string; percentageWeight: number }[]
  otherPercentageWeight: number
}

export function create(
  instrumentsMap: Map<InstrumentReferenceString, Instrument>,
  { components: { cash, others, instruments: instrumentCompositions } }: Composition
): CompositionWithInstruments {
  return {
    instruments: instrumentCompositions
      .map(({ instrumentReference, weight }) => {
        if (instrumentReference === undefined) {
          throw new Error(`instrumentReference undefined`)
        }
        const instrument = instrumentsMap.get(instrumentReferenceToString(instrumentReference))
        if (!instrument) {
          throw new Error(
            `could not find instrument with instrumentReference ${instrumentReferenceToString(instrumentReference)}`
          )
        }
        return { percentageWeight: weight * 100, instrument }
      })
      .sort((a, b) => a.percentageWeight - b.percentageWeight),
    cash: cash
      .map(({ currency, weight }) => ({ currency, percentageWeight: weight * 100 }))
      .sort((a, b) => a.percentageWeight - b.percentageWeight),
    otherPercentageWeight: others * 100,
  }
}

export const categoryCounts = ({ instruments }: CompositionWithInstruments) => {
  return instruments
    .map(({ instrument }) => getInstrumentCategory(instrument))
    .reduce((map, category) => map.set(category, (map.get(category) || 0) + 1), new Map<InstrumentCategory, number>())
}

export const sampleCompositionWithInstruments = (): CompositionWithInstruments => {
  return create(
    new Map<InstrumentReferenceString, Instrument>(
      [sampleCompositeInstrument(), sampleNonCompositeInstrument()].map((i) => [
        instrumentReferenceToString(i.instrumentReferences[0]),
        i,
      ])
    ),
    sampleComposition()
  )
}

export const isCompositionWithInstrumentsEmpty = (compositionWithInstruments: CompositionWithInstruments) => {
  return (
    compositionWithInstruments.instruments.length === 0 &&
    compositionWithInstruments.cash.length === 0 &&
    compositionWithInstruments.otherPercentageWeight === 0
  )
}
