import React from 'react'
import { Card, H4, Button, ArrowRightIcon, Body1, Stack, Tag, screen, Span } from '@tumelo/designsystem'
import styled from 'styled-components'
import { useAppDispatch } from '../../application/store'
import { setVotingPolicyModalOpen } from '../../application/features/votingPolicies'
import { useAnalytics } from '../../application/analytics'
import { buildEvent } from '../../application/analytics/eventBuilders'

export const VotingPoliciesBanner: React.FC = () => {
  const dispatch = useAppDispatch()
  const { trackEvent } = useAnalytics()

  return (
    <Card variant="shadow">
      <Stack
        alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'flex-end', lg: 'flex-end' }}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
        justifyContent="space-between"
      >
        <StyledDiv>
          <Stack
            flexDirection={{ xs: 'row', sm: 'row', md: 'column-reverse', lg: 'column-reverse' }}
            alignItems={{ xs: 'stretch', sm: 'stretch', md: 'flex-start', lg: 'flex-start' }}
            justifyContent="space-between"
          >
            <H4 mb="0.8rem">
              Want to vote at scale? <StyledBr /> Automate it.
            </H4>
            <Tag title="New" variant="color" />
          </Stack>
          <Body1 mb="0">
            Set up automatic voting in just <Span semibold>5 minutes.</Span>
          </Body1>
        </StyledDiv>
        <Button
          fullWidth={{ xs: true, sm: true, md: false, lg: false }}
          onClick={() => {
            dispatch(setVotingPolicyModalOpen())
            trackEvent(buildEvent.openVotingPoliciesModal())
          }}
          iconAfter={<ArrowRightIcon />}
        >
          Let&apos;s get started
        </Button>
      </Stack>
    </Card>
  )
}

const StyledDiv = styled.div`
  width: 100%;
  @media ${screen.mediumUp} {
    width: auto;
  }
`

const StyledBr = styled.br`
  @media ${screen.mediumUp} {
    display: none;
  }
`
