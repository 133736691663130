/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { Text, Breakpoint, Button } from '@tumelo/shared'

interface Props {
  title: string
  dismiss: () => void
}

export const NotificationBar: React.FC<React.PropsWithChildren<Props>> = ({ title, children, dismiss }) => (
  <Outer>
    <Inner>
      <Text>
        <Bold>{title}</Bold>
        {children}
      </Text>
      <ButtonContainer>
        <Button onClick={dismiss}>Dismiss</Button>
      </ButtonContainer>
    </Inner>
  </Outer>
)

const Outer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 1rem solid ${({ theme }) => theme.colors.secondary.medium};
  box-sizing: border-box;
`

const Bold = styled.span`
  color: ${({ theme }) => theme.colors.secondary.medium};
  font-weight: 600;
  display: block;
`

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Breakpoint.mobile}) {
    flex-direction: column;
  }
  align-items: center;
  margin: 1rem 3rem;
`

const ButtonContainer = styled.div`
  margin: 1rem;
`
