import React from 'react';
import styled from 'styled-components';
import { VisuallyHidden } from '../../VisuallyHidden';
import { CheckboxGroupNameContext } from './CheckboxGroup';
export const CheckboxItem = ({ value, textHidden = false, defaultChecked, checked, disabled, children, onChange, }) => {
    return (React.createElement(CheckboxGroupNameContext.Consumer, null, (groupName) => (React.createElement(StyledLabel, null,
        React.createElement(StyledHTMLCheckbox, { type: "checkbox", name: groupName, value: value, defaultChecked: defaultChecked, checked: checked, disabled: disabled, onChange: onChange }),
        React.createElement(StyledCheckbox, null),
        textHidden && React.createElement(VisuallyHidden, null, value),
        children))));
};
const StyledHTMLCheckbox = styled.input `
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;
  z-index: 1;
  &:checked ~ span:after {
    display: block;
  }
  &:checked ~ span {
    border: 2px solid ${({ theme }) => theme.colors.primary.medium};
  }
  &:disabled ~ span {
    border: 2px solid ${({ theme }) => theme.colors.grey.light};
    background: ${({ theme }) => theme.colors.grey.light};
    cursor: default;
  }
  &:focus-visible ~ span {
    outline: 2px solid ${({ theme }) => theme.colors.primary.dark};
    outline-offset: 3px;
  }
  &:disabled {
    cursor: default;
  }
  &:disabled ~ * {
    color: ${({ theme }) => theme.colors.grey.light};
    cursor: default;
  }
`;
const StyledCheckbox = styled.span `
  margin-right: ${({ withoutLabel }) => {
    return withoutLabel ? '0' : '1.6rem';
}};
  height: 2.4rem;
  width: 2.4rem;
  border: 2px solid ${({ theme }) => theme.colors.grey.light};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    display: none;
    width: 2rem;
    height: 2rem;
    background-color: ${({ theme }) => theme.colors.primary.medium};
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='14' viewBox='0 0 20 14' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.7102 0.79C18.8039 0.882963 18.8783 0.993564 18.9291 1.11542C18.9798 1.23728 19.006 1.36799 19.006 1.5C19.006 1.63201 18.9798 1.76272 18.9291 1.88458C18.8783 2.00644 18.8039 2.11704 18.7102 2.21L7.71019 13.21C7.61722 13.3037 7.50662 13.3781 7.38476 13.4289C7.2629 13.4797 7.1322 13.5058 7.00019 13.5058C6.86817 13.5058 6.73747 13.4797 6.61561 13.4289C6.49375 13.3781 6.38315 13.3037 6.29019 13.21L1.29019 8.21C1.10188 8.0217 0.996094 7.7663 0.996094 7.5C0.996094 7.2337 1.10188 6.9783 1.29019 6.79C1.47849 6.6017 1.73388 6.49591 2.00019 6.49591C2.26649 6.49591 2.52188 6.6017 2.71019 6.79L7.00019 11.09L17.2902 0.79C17.3831 0.696272 17.4937 0.621877 17.6156 0.571109C17.7375 0.52034 17.8682 0.494202 18.0002 0.494202C18.1322 0.494202 18.2629 0.52034 18.3848 0.571109C18.5066 0.621877 18.6172 0.696272 18.7102 0.79Z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 2rem 1.4rem;
    background-position: center;
  }
`;
const StyledLabel = styled.label `
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0.2rem;
  border-radius: 0.8rem;
  margin-block-end: 4px;
  &:hover span {
    border: 2px solid ${({ theme }) => theme.colors.primary.light};
    :after {
      background-color: ${({ theme }) => theme.colors.primary.light};
    }
  }
  &:has(input:disabled) {
    cursor: default;
  }
  &:hover input:disabled ~ span {
    border: 2px solid ${({ theme }) => theme.colors.grey.light};
    cursor: default;
  }
`;
