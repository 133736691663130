import React from 'react'
import { VoteResult } from '../../application/types/VoteResult'
import { useRouter } from '../../application/router/useRouter'
import { SeeResultsVoteResult } from './CardletBottoms/SeeResultsVoteResult'
import { BaseCardlet, CardletBottom, CardletColumn, CardletRow, CardletTop, PollTitle } from './BaseCardlet'
import { OrganizationGroup } from './OrganizationGroup'
import { Tags } from './Tags'

interface Props {
  voteResult: VoteResult
}

export const VoteResultCardlet: React.FC<Props> = ({ voteResult }) => {
  const href = `/votes/results/${voteResult.pollId}`
  const { navigate } = useRouter()
  const handleCardletClick = () => {
    navigate(href)
  }

  const Bottom = (): React.ReactNode => {
    return (
      <CardletBottom>
        <SeeResultsVoteResult voteResult={voteResult} />
      </CardletBottom>
    )
  }

  const Top = (): React.ReactNode => {
    return (
      <CardletTop>
        <CardletRow>
          <OrganizationGroup
            title={voteResult.organization?.displayName ?? ''}
            logoUrl={voteResult.organization?.logoUrl}
          />
          <Tags tagIds={voteResult.pollTagIds} />
        </CardletRow>
        <CardletColumn>
          <PollTitle to={href}>{voteResult.pollTitle}</PollTitle>
        </CardletColumn>
      </CardletTop>
    )
  }

  return (
    <BaseCardlet onClick={handleCardletClick}>
      {Top()}
      {Bottom()}
    </BaseCardlet>
  )
}
