import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const CalendarIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 25 25", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { id: "Calendar-icon", transform: "translate(-669.94 -370.441)" },
            React.createElement("path", { id: "Path_13425", d: "M692.164 373.219h-3.7v-1.852a.926.926 0 0 0-1.852 0v1.852h-8.334v-1.852a.926.926 0 0 0-1.852 0v1.852h-3.7A2.781 2.781 0 0 0 669.94 376v16.668a2.781 2.781 0 0 0 2.778 2.778h19.446a2.78 2.78 0 0 0 2.778-2.778V376a2.781 2.781 0 0 0-2.778-2.781zm-19.446 1.852h3.7v1.852a.926.926 0 1 0 1.852 0v-1.852h8.334v1.852a.926.926 0 1 0 1.852 0v-1.852h3.7a.927.927 0 0 1 .926.926v3.7h-21.3V376a.928.928 0 0 1 .936-.929zm19.446 18.52h-19.446a.927.927 0 0 1-.926-.926v-11.112h21.3v11.112a.926.926 0 0 1-.928.927z" }),
            React.createElement("path", { id: "Path_13426", d: "M676.409 387.94h-1.173a.926.926 0 0 0 0 1.852h1.173a.926.926 0 0 0 0-1.852z", transform: "translate(-.323 -1.295)" }),
            React.createElement("path", { id: "Path_13427", d: "M681.426 387.94h-1.173a.926.926 0 0 0 0 1.852h1.173a.926.926 0 0 0 0-1.852z", transform: "translate(-.694 -1.295)" }),
            React.createElement("path", { id: "Path_13428", d: "M686.442 387.94h-1.173a.926.926 0 0 0 0 1.852h1.173a.926.926 0 0 0 0-1.852z", transform: "translate(-1.066 -1.295)" }),
            React.createElement("path", { id: "Path_13429", d: "M691.458 387.94h-1.173a.926.926 0 0 0 0 1.852h1.173a.926.926 0 0 0 0-1.852z", transform: "translate(-1.437 -1.295)" }),
            React.createElement("path", { id: "Path_13430", d: "M676.409 384.389h-1.173a.926.926 0 1 0 0 1.852h1.173a.926.926 0 1 0 0-1.852z", transform: "translate(-.323 -1.032)" }),
            React.createElement("path", { id: "Path_13431", d: "M681.426 384.389h-1.173a.926.926 0 1 0 0 1.852h1.173a.926.926 0 1 0 0-1.852z", transform: "translate(-.694 -1.032)" }),
            React.createElement("path", { id: "Path_13432", d: "M686.442 384.389h-1.173a.926.926 0 1 0 0 1.852h1.173a.926.926 0 1 0 0-1.852z", transform: "translate(-1.066 -1.032)" }),
            React.createElement("path", { id: "Path_13433", d: "M691.458 384.389h-1.173a.926.926 0 1 0 0 1.852h1.173a.926.926 0 1 0 0-1.852z", transform: "translate(-1.437 -1.032)" }),
            React.createElement("path", { id: "Path_13434", d: "M676.409 391.493h-1.173a.926.926 0 0 0 0 1.852h1.173a.926.926 0 0 0 0-1.852z", transform: "translate(-.323 -1.557)" }),
            React.createElement("path", { id: "Path_13435", d: "M681.426 391.493h-1.173a.926.926 0 0 0 0 1.852h1.173a.926.926 0 0 0 0-1.852z", transform: "translate(-.694 -1.557)" }),
            React.createElement("path", { id: "Path_13436", d: "M686.442 391.493h-1.173a.926.926 0 0 0 0 1.852h1.173a.926.926 0 0 0 0-1.852z", transform: "translate(-1.066 -1.557)" }),
            React.createElement("path", { id: "Path_13437", d: "M691.458 391.493h-1.173a.926.926 0 0 0 0 1.852h1.173a.926.926 0 0 0 0-1.852z", transform: "translate(-1.437 -1.557)" }))));
};
