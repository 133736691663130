import { grpc } from '@improbable-eng/grpc-web'

interface GrpcError extends Error {
  code: grpc.Code
  metadata: string | undefined
}

export const isGRPCError = (e: unknown): e is GrpcError => {
  if (e && typeof e === 'object' && 'code' in e && 'metadata' in e) {
    return true
  }
  return false
}
