import { Poll, timestampToDate } from '@tumelo/shared'
import { add, isAfter, isBefore } from 'date-fns'

export const findInvestorOpenVotesCloseToExpiration = (polls: Poll[]): Poll[] | undefined =>
  polls
    .filter((p) => {
      const today = new Date()
      const fiveDaysLater = add(today, {
        days: 5,
      })
      return isAfter(timestampToDate(p.endDate), today) && isBefore(timestampToDate(p.endDate), fiveDaysLater)
    })
    .slice(0, 2)

export const findInvestorOpenVoteWithMostVotes = (polls: Poll[]): Poll | undefined =>
  polls.reduce((prev, current) =>
    prev.tally.againstCount + prev.tally.forCount > current.tally.againstCount + current.tally.forCount ? prev : current
  )
