/**
 * @deprecated use screen from designsystem/utils/mediaQueries instead
 */
export const Breakpoint = {
    small: '320px',
    mobile: '600px',
    tablet: '850px',
    desktop: '1200px',
    widescreen: '1200px',
    fullhd: '1408px',
};
/**
 * @deprecated use designsystem/_contstans/breakpoints instead
 */
export const BreakpointAsNumbers = {
    small: 320,
    mobile: 600,
    tablet: 850,
    desktop: 1200,
    widescreen: 1200,
    fullhd: 1408,
};
/**
 * @deprecated use screen from designsystem/utils/mediaQueries instead
 */
export const BreakpointAsMaxValue = {
    small: '319px',
    mobile: '599px',
    tablet: '849px',
    desktop: '1199px',
    widescreen: '1199px',
    fullhd: '1407px',
};
