/* eslint-disable @typescript-eslint/no-deprecated */
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Checkbox } from '../../../../buildingBlocks';
import { Caption } from '../../../../styles';
export const FilterMenuItem = ({ active, toggleActive, icon, children }) => {
    const theme = useTheme();
    const formattedIcon = icon && React.cloneElement(icon, { style: ICON_STYLE(theme.colors.dark) });
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { onClick: () => {
                toggleActive();
            } },
            React.createElement(Checkbox, { checked: active, onChange: () => toggleActive() }),
            formattedIcon && formattedIcon,
            React.createElement(Title, null, children)),
        React.createElement(Border, null)));
};
const MARGIN = 1;
const ICON_STYLE = (fillColor) => ({
    fontSize: '16px',
    fill: fillColor,
    marginLeft: `${MARGIN}rem`,
});
const Container = styled.div `
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 1rem;
  user-select: none;
`;
const Border = styled.div `
  border-bottom: 0.2rem solid ${({ theme }) => theme.colors.grey.light};
`;
const Title = styled(Caption) `
  margin-left: ${MARGIN}rem;
  max-height: 4.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;
