/* eslint-disable @typescript-eslint/no-deprecated */
import styled from 'styled-components';
import { Breakpoint } from './media-breakpoints';
/**
 * @deprecated use designsystem/Typography instead, font sizes are baked into the components already. Use theme if needed (same as colors)
 */
const fontSizes = {
    h1: '2.2rem',
    h2: '1.6rem',
    h3: '1.3rem',
    p: '1rem',
};
const fontFamily = 'Montserrat';
/**
 * For SEO there should only ever be one h1 tag per page,
 * so for those cases, pass the prop (as="h1") in component like so:
 * <Display as="h1">Lovely Title</Display>
 *
 * More info:
 * https://www.styled-components.com/docs/basics#extending-styles
 */
/**
 * @deprecated use designsystem/Typography instead
 */
const Display = styled.h2 `
  font-size: 6rem;
  font-family: ${fontFamily}, sans-serif;
  line-height: 7rem;
  margin-block-end: 1.5rem;
  color: ${(p) => (p.color ? p.color : p.theme.colors.dark)};
  font-weight: ${(p) => (p.weight ? p.weight : '700')};
  @media (max-width: ${Breakpoint.mobile}) {
    font-size: 3rem;
    line-height: 3.8rem;
  }

  @media only screen and (max-device-width: ${Breakpoint.small}) {
    font-size: 8vw;
  }
`;
/**
 * @deprecated use designsystem/Typography instead
 */
const Subtitle = styled(Display) `
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: ${(p) => (p.weight ? p.weight : '600')};
  @media (max-width: ${Breakpoint.mobile}) {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }

  @media only screen and (max-device-width: ${Breakpoint.small}) {
    font-size: 6vw;
  }
`;
/**
 * @deprecated use designsystem/Typography instead
 */
const Title = styled(Subtitle) `
  font-weight: bold;
`;
/**
 * @deprecated use designsystem/Typography instead
 */
const Text = styled.span `
  font-size: 2rem;
  line-height: 2.8rem;
  font-family: ${fontFamily};
  font-weight: ${(p) => (p.weight ? p.weight : '400')};
  color: ${(p) => (p.color ? p.color : p.theme.colors.dark)};
  @media (max-width: ${Breakpoint.mobile}) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  @media only screen and (max-device-width: ${Breakpoint.small}) {
    font-size: 5vw;
  }
`;
const Select = styled.select `
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
`;
/**
 * @deprecated use designsystem/Typography instead
 */
const Caption = styled(Text) `
  font-size: 1.6rem;
  line-height: 2.4rem;

  @media only screen and (max-device-width: ${Breakpoint.small}) {
    font-size: 4vw;
  }
`;
/**
 * @deprecated use designsystem/Typography instead
 */
const Small = styled(Text) `
  font-size: 1.4rem;
  line-height: 2.4rem;

  @media only screen and (max-device-width: ${Breakpoint.small}) {
    font-size: 4vw;
  }
`;
/**
 * @deprecated use designsystem/Typography instead
 */
const TextToSmallMobile = styled(Text) `
  line-height: 2.4rem;
  @media only screen and (max-width: ${Breakpoint.tablet}) {
    line-height: 2rem;
  }
  @media only screen and (max-width: ${Breakpoint.mobile}) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  @media only screen and (max-width: ${Breakpoint.small}) {
    font-size: 4vw;
  }
`;
const Input = styled.input `
  font-weight: ${(p) => (p.weight ? p.weight : '400')};
  color: ${(p) => (p.color ? p.color : p.theme.colors.dark)};
  font-size: 1.6rem;
  margin: 10px 10px;
  border: none;
  flex: 1;
  min-width: 0;
`;
/**
 * @deprecated use designsystem/Typography instead
 */
const H1Dark = styled.div `
  font-family: ${fontFamily};
  font-size: ${fontSizes.h1};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.dark};
`;
/**
 * @deprecated use designsystem/Typography instead
 */
const H3Dark = styled.div `
  font-family: ${fontFamily};
  font-size: ${fontSizes.h3};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.dark};
`;
const StyledLink = styled.a `
  color: ${({ theme }) => theme.colors.secondary.medium};
  text-decoration: underline;
`;
export { Display, Subtitle, Title, Text, StyledLink as Link, Input, Small, Caption, TextToSmallMobile, H1Dark, H3Dark, fontSizes, fontFamily, Select, };
