import { css } from 'styled-components';
import { screen } from '../_utils/mediaQueries';
export const linkStyles = css `
  text-decoration: underline;
  color: ${({ colour, theme }) => {
    switch (colour) {
        case 'dark':
            return theme.colors.dark;
        case 'white':
            return theme.colors.white;
        case 'disabled':
            return theme.colors.danger.light;
        default:
            return theme.colors.secondary.medium;
    }
}};
  ${({ theme, size }) => {
    switch (size) {
        case 'small':
            return css `
          font-size: ${theme.fontSizes.body2.mobile};
          line-height: ${theme.lineHeights.body2.mobile};
          @media ${screen.smallUp} {
            font-size: ${theme.fontSizes.body2.default};
            line-height: ${theme.lineHeights.body2.default};
          }
        `;
        case 'medium':
            return css `
          font-size: ${theme.fontSizes.body1.mobile};
          line-height: ${theme.lineHeights.body1.mobile};
          @media ${screen.smallUp} {
            font-size: ${theme.fontSizes.body1.default};
            line-height: ${theme.lineHeights.body1.default};
          }
        `;
        default:
            return css `
          font-size: inherit;
          line-height: inherit;
        `;
    }
}};

  &:hover {
    text-decoration: ${({ colour }) => (colour === 'disabled' ? 'underline' : 'none')};
  }
`;
