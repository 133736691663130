import { ProposalId, VotingPolicy, VotingPolicyId, VotingPolicySelection } from '@tumelo/shared'
import { AppThunk } from '../../store'
import { isValueState } from '../../payload'
import * as votingPolicyActions from '.'

export const selectVotingPolicy =
  (votingPolicyId: VotingPolicyId): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { accountService, loggerService } = services

    const { account } = getState().account
    if (!isValueState(account) || account === 'not-configured') {
      throw new Error('user account not found and not configured when setting a voting policy')
    }

    const { votingPolicySelection: existingVotingPolicySelection } = getState().votingPolicies
    const existingVotingPolicyId =
      isValueState(existingVotingPolicySelection) && existingVotingPolicySelection !== undefined
        ? existingVotingPolicySelection.id
        : undefined

    try {
      dispatch(votingPolicyActions.setUpdateVotingPolicySelectionPending())
      const votingPolicySelection = await accountService.createVotingPolicySelection(
        account,
        votingPolicyId,
        existingVotingPolicyId
      )
      dispatch(votingPolicyActions.setVotingPolicySelection(votingPolicySelection))
      dispatch(votingPolicyActions.setVotingPolicySelectionNotUpdating())
    } catch (e) {
      dispatch(votingPolicyActions.setUpdateVotingPolicySelectionError())
      loggerService.logError(e)
    }
  }

export const deleteVotingPolicySelection =
  (votingPolicySelectionId: string): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { accountService, loggerService } = services

    const { account } = getState().account
    if (!isValueState(account) || account === 'not-configured') {
      throw new Error('user account not found and not configured when setting a voting policy')
    }

    try {
      dispatch(votingPolicyActions.setUpdateVotingPolicySelectionPending())
      await accountService.deleteVotingPolicySelection(account, votingPolicySelectionId)
      dispatch(votingPolicyActions.setVotingPolicySelectionNotUpdating())
      dispatch(votingPolicyActions.deleteVotingPolicySelection())
    } catch (e) {
      dispatch(votingPolicyActions.setUpdateVotingPolicySelectionError())
      loggerService.logError(e)
    }
  }

export const getVotingPolicySelection =
  (): AppThunk =>
  async (dispatch, getState, { services }) => {
    const { accountService, loggerService } = services
    const { account } = getState().account
    if (!isValueState(account) || account === 'not-configured') {
      throw new Error('user account not found and not configured when setting a voting policy')
    }

    try {
      dispatch(votingPolicyActions.setVotingPolicySelectionPending())
      const votingPolicySelection = await accountService.getVotingPolicySelection(account)
      dispatch(votingPolicyActions.setVotingPolicySelection(votingPolicySelection))
    } catch (e) {
      dispatch(votingPolicyActions.setVotingPolicySelectionError())
      loggerService.logError(e)
    }
  }

export const fetchHabitatVotingPolicies =
  (): AppThunk =>
  async (dispatch, _, { services }) => {
    const { dashboardBffService, loggerService } = services
    try {
      dispatch(votingPolicyActions.setHabitatVotingPoliciesPending())
      const habitatVotingPolicies: VotingPolicy[] = await dashboardBffService.FetchHabitatVotingPolicies()
      dispatch(votingPolicyActions.setHabitatVotingPolicies({ votingPolicies: habitatVotingPolicies }))
    } catch (e) {
      dispatch(votingPolicyActions.setHabitatVotingPoliciesError())
      loggerService.logError(e)
    }
  }

export const fetchVotingPolicyRecommendationsForProposal =
  (votingPolicySelection: VotingPolicySelection, proposalId: ProposalId): AppThunk =>
  async (dispatch, _, { services }) => {
    dispatch(votingPolicyActions.setVotingPolicyRecommendationPending())
    const { votingService, loggerService } = services
    try {
      const votingPolicyRecommendation = await votingService.listVotingRecommendationForProposalId(
        votingPolicySelection.votingPolicyId,
        proposalId
      )
      dispatch(votingPolicyActions.setVotingPolicyRecommendation(votingPolicyRecommendation))
    } catch (e) {
      loggerService.logError(e)
      dispatch(votingPolicyActions.setVotingPolicyRecommendationError())
    }
  }
