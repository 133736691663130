import React from 'react'
import { ConfirmCodeForm, ConfirmCodeValues } from '../Forms/ConfirmCodeForm'
import { ExplicitPageView } from '../../../application/analytics'
import { changeTitle } from '../../../containers/changeTitle'
import { useAppSelector } from '../../../application/store'
import { selectAuthError, selectAuthPending } from '../../../application/features/auth/selectors'

interface Props {
  submit: (details: ConfirmCodeValues) => Promise<void>
  resendCode: () => Promise<void>
  goToSignIn: () => void
  email: string
}

export const ConfirmCodePage: React.FC<Props> = ({ submit, resendCode, goToSignIn, email }) => {
  changeTitle('Confirm Code')
  const error = useAppSelector(selectAuthError)
  const authActionPending = useAppSelector(selectAuthPending)

  const onSubmit = async (values: ConfirmCodeValues) => {
    await submit(values)
  }

  const onResendCode = async () => {
    await resendCode()
  }

  return (
    <>
      <ExplicitPageView page="/confirm-code" />
      <ConfirmCodeForm
        submit={onSubmit}
        resendCode={onResendCode}
        goToSignIn={goToSignIn}
        email={email}
        error={error?.message}
        pending={authActionPending}
      />
    </>
  )
}
