import { useEffect } from 'react'
import { Ballot, OrganizationBreakdown, Poll } from '@tumelo/shared'
import { useAppDispatch, useAppSelector } from '../store'
import { isValueState, Payload } from '../payload'
import { selectBallots } from '../features/ballots/selectors'
import { selectPolls, selectPollsStatus } from '../features/polls/selectors'
import { PollsStatusType } from '../features/polls'
import { fetchPollsAndBallots } from '../asyncActions'

export const useFetchPollsAndBallots = (breakdown: Payload<OrganizationBreakdown>) => {
  const dispatch = useAppDispatch()
  const ballots: Payload<Ballot[]> = useAppSelector(selectBallots)
  const polls: Payload<Poll[]> = useAppSelector(selectPolls)
  const pollsStatus: Payload<PollsStatusType> = useAppSelector(selectPollsStatus)

  useEffect(() => {
    const pollsAndBallotsNotYetFetched =
      (ballots === 'not-initialised' && pollsStatus === 'not-initialised') || pollsStatus === 'partial'
    if (isValueState(breakdown) && pollsAndBallotsNotYetFetched) {
      dispatch(fetchPollsAndBallots())
    }
  }, [ballots, breakdown, polls, pollsStatus, dispatch])
}

export const useFetchRecentPolls = (breakdown: Payload<OrganizationBreakdown>) => {
  const dispatch = useAppDispatch()
  const polls: Payload<Poll[]> = useAppSelector(selectPolls)
  const pollsStatus: Payload<PollsStatusType> = useAppSelector(selectPollsStatus)
  useEffect(() => {
    const recentPollsNotYetFetched = pollsStatus === 'not-initialised'
    if (isValueState(breakdown) && recentPollsNotYetFetched) {
      dispatch(fetchPollsAndBallots(true))
    }
  }, [breakdown, polls, pollsStatus, dispatch])
}
