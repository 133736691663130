import React, { useState } from 'react'
import styled from 'styled-components'
import { CallToAction, MiniGridCard } from '@tumelo/shared'
import { SeeWhereYourVoteGoesModal } from '../SeeWhereYourVoteGoesModal'
import { useAnalytics } from '../../application/analytics'
import { buildEvent } from '../../application/analytics/eventBuilders'

export const SeeWhereYourVoteGoesCard = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { trackEvent } = useAnalytics()

  const cardTitle = 'Fund manager who?'

  return (
    <>
      <MiniGridCard
        title={cardTitle}
        onClick={() => {
          trackEvent(
            buildEvent.returningCardCTAClicked({ card_type: 'see-where-your-vote-goes-card', card_title: cardTitle })
          )
          setModalIsOpen(true)
        }}
      >
        <Container>
          Still have questions after placing your first vote preference?
          <StyledCTA callToAction="Watch this 60 sec video" />
        </Container>
      </MiniGridCard>
      <SeeWhereYourVoteGoesModal open={modalIsOpen} handleModalClose={() => setModalIsOpen(false)} />
    </>
  )
}

const Container = styled.div`
  height: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledCTA = styled(CallToAction)`
  align-self: flex-end;
`
