import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Payload } from '../../payload'
import { UserPreferences } from '../../types/UserPreferences/UserPreferences'

interface UserPreferencesState {
  preferences: Payload<UserPreferences>
}

const getInitialState = (): UserPreferencesState => {
  return {
    preferences: 'not-initialised',
  }
}

const userPreferencesSlice = createSlice({
  name: 'user-preferences',
  initialState: getInitialState(),
  reducers: {
    setUserPreferences: (state, action: PayloadAction<Payload<UserPreferences>>) => {
      state.preferences = action.payload
    },
  },
})

export default userPreferencesSlice.reducer

export const { setUserPreferences } = userPreferencesSlice.actions
