import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const MegaphoneIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", style: styleWithDefault, viewBox: "0 0 40 40" },
        React.createElement("g", { id: "Layer_2", "data-name": "Layer 2" },
            React.createElement("g", { id: "Layer_1-2", "data-name": "Layer 1" },
                React.createElement("g", { id: "promotion" },
                    React.createElement("path", { id: "Path_13595", "data-name": "Path 13595", d: "M11.17,25.11a.81.81,0,0,0,.3-1.11.81.81,0,1,0-.3,1.11Z" }),
                    React.createElement("path", { id: "Path_13596", "data-name": "Path 13596", d: "M7.84,30.73l4.1,7.1a3.26,3.26,0,0,0,5.65-3.24v0l-2.44-4.23,2.11-1.22a.81.81,0,0,0,.3-1.11h0l-1.06-1.84,15.6-3.06a2.45,2.45,0,0,0,2.31-2.57,2.32,2.32,0,0,0-.33-1.09l-2.7-4.68,1.73-2.62a.81.81,0,0,0,0-.86L31.49,8.47a.82.82,0,0,0-.75-.41l-3.13.19-3-5.2a2.42,2.42,0,0,0-2.09-1.22h0A2.41,2.41,0,0,0,20.43,3L9.79,16.51l-6.92,4a5.7,5.7,0,0,0,5,10.23Zm8.33,4.66A1.63,1.63,0,1,1,13.35,37L9.28,30l2.82-1.63Zm-1.85-6.46-.81-1.41,1.41-.81.81,1.41Zm16-19.21,1.13,1.95-1,1.52L28.52,9.83ZM21.79,3.84a.82.82,0,0,1,1.12-.27.86.86,0,0,1,.28.29l9.47,16.41a.8.8,0,0,1-.29,1.11.79.79,0,0,1-.39.11,2.36,2.36,0,0,0-.45.08l-10-17.36A4,4,0,0,0,21.79,3.84ZM20.43,5.59,29.84,21.9,15.63,24.68,11.31,17.2ZM2.19,27.47a4.08,4.08,0,0,1,1.49-5.56h0L10,18.24l4.08,7.06L7.76,29A4.07,4.07,0,0,1,2.19,27.47Z" }),
                    React.createElement("path", { id: "Path_13597", "data-name": "Path 13597", d: "M8.65,25.63a.81.81,0,0,0-1.11-.31h0l-1.41.81A.83.83,0,0,1,5,25.84H5a.81.81,0,0,0-1.41.81,2.45,2.45,0,0,0,3.34.9l1.41-.82A.81.81,0,0,0,8.65,25.63Z" }),
                    React.createElement("path", { id: "Path_13598", "data-name": "Path 13598", d: "M40.51,3.57,36,6.09a.82.82,0,0,0,.79,1.43h0L41.3,5a.81.81,0,1,0-.79-1.42Z" }),
                    React.createElement("path", { id: "Path_13599", "data-name": "Path 13599", d: "M40,12l-3.14-.84a.81.81,0,1,0-.42,1.57h0l3.14.84A.81.81,0,1,0,40,12Z" }),
                    React.createElement("path", { id: "Path_13600", "data-name": "Path 13600", d: "M32.13.6l-.85,3.15a.82.82,0,0,0,1.58.42L33.7,1a.81.81,0,0,0-.58-1A.8.8,0,0,0,32.13.6Z" }))))));
};
