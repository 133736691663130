/* eslint-disable @typescript-eslint/no-deprecated */

import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Poll, Breakpoint, Text, timestampToDate, Button } from '@tumelo/shared'
import { SingleQuestionStyles } from '../../SingleQuestion/SingleQuestion'
import { ResultsDue } from '../../ResultsDue/ResultsDue'

interface Props {
  poll: Poll
  onClick: () => void
}

export const PollClosed: React.FC<Props> = ({ poll, onClick }) => {
  const { colors } = useContext(ThemeContext)
  return (
    <Container>
      <Title>This vote has now closed</Title>
      <Text>
        All the vote preferences submitted on this platform have been sent to the fund managers. Then, they will place
        their legal vote at the company AGM.
      </Text>
      <Text as="p">Check back in a few weeks to see the final result.</Text>
      <Border />
      <Footer>
        <ResultsDue
          endDate={timestampToDate(poll.relationships.generalMeeting.date)}
          resultsText="The final result will be available after the company AGM."
        />
        <Column>
          <Button size="medium" color={colors.dark} inverse onClick={onClick}>
            See all &apos;Open votes&apos;
          </Button>
        </Column>
      </Footer>
    </Container>
  )
}

const { MARGIN, MAX_WIDTH } = SingleQuestionStyles

const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${Breakpoint.mobile}) {
    margin: ${MARGIN}rem 0 0 0;
  }
`

const Title = styled(Text)`
  font-weight: bold;
  margin-bottom: 0.5rem;
`

const Border = styled.div`
  margin: ${MARGIN}rem 0;
  border-bottom: 0.1rem ${({ theme }) => theme.colors.grey.light} solid;
`

const Footer = styled.div`
  display: flex;
  max-width: ${MAX_WIDTH}px;
  justify-content: space-between;

  @media (max-width: ${Breakpoint.tablet}) {
    flex-direction: column;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${Breakpoint.tablet}) {
    justify-content: center;
    align-items: flex-start;
  }
`
