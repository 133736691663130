import mixpanel, { Config } from 'mixpanel-browser'
import { Analytics } from './Analytics'

const PAGE_VIEW_EVENT = 'Page View'

export const createAnalyticsMixpanel = (token: string, config: Partial<Config>): Analytics => {
  mixpanel.init(token, config)

  return {
    createIdentity: (userId) => mixpanel.alias(userId),
    pageView: (page) => mixpanel.track(PAGE_VIEW_EVENT, { page }),
    trackEvent: ({ name, props }) => mixpanel.track(name, props),
    signOutUser: () => mixpanel.reset(),
    setUserProperties: (userId) => {
      if (userId !== '') mixpanel.identify(userId)
    },
    setSuperProperties: (props) => mixpanel.register(props),
    registerExternalLinkClickListener: () =>
      mixpanel.track_links('.mixpanel-link', 'click_external_cta', (el: HTMLAnchorElement) => {
        return { title: el.textContent }
      }),
  }
}
