import { VotingPolicySelection, TumeloAccount, VotingPolicy, ProposalId } from '@tumelo/shared'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { isValueState, Payload } from '../../payload'
import {
  fetchHabitatVotingPolicies,
  fetchVotingPolicyRecommendationsForProposal,
  getVotingPolicySelection,
} from './asyncActions'
import {
  selectHabitatVotingPolicies,
  selectVotingPolicySelection,
  selectVotingRecommendationForProposal,
} from './selectors'
import { VotingRecommendation } from './types'

export const useHabitatVotingPolicies = (): Payload<VotingPolicy[]> => {
  const dispatch = useAppDispatch()
  const habitatVotingPolicies = useAppSelector(selectHabitatVotingPolicies)

  useEffect(() => {
    if (habitatVotingPolicies === 'not-initialised') {
      dispatch(fetchHabitatVotingPolicies())
    }
  }, [habitatVotingPolicies, dispatch])

  return habitatVotingPolicies
}

export const useVotingPolicySelection = (
  account: Payload<TumeloAccount | 'not-configured'>
): Payload<VotingPolicySelection | undefined> => {
  const dispatch = useAppDispatch()
  const votingPolicySelection = useAppSelector(selectVotingPolicySelection)

  useEffect(() => {
    if (votingPolicySelection === 'not-initialised' && isValueState(account)) {
      dispatch(getVotingPolicySelection())
    }
  }, [account, votingPolicySelection, dispatch])

  return votingPolicySelection
}

export const useVotingPolicyRecommendationByProposalId = (
  votingPolicySelection: Payload<VotingPolicySelection | undefined>,
  proposalId: ProposalId
): Payload<VotingRecommendation> | 'not-configured' | undefined => {
  const dispatch = useAppDispatch()
  const votingPolicyRecommendation = useAppSelector(selectVotingRecommendationForProposal)

  useEffect(() => {
    if (isValueState(votingPolicySelection) && votingPolicySelection !== undefined) {
      dispatch(fetchVotingPolicyRecommendationsForProposal(votingPolicySelection, proposalId))
    }
  }, [votingPolicySelection, proposalId, dispatch])
  if (votingPolicySelection === undefined) {
    return undefined
  }
  return votingPolicyRecommendation
}
