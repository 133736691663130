import React from 'react';
import styled from 'styled-components';
export const CheckboxGroupNameContext = React.createContext('individualCheckbox');
export const CheckboxGroup = ({ groupName, children, spacer = '0.8rem', className, }) => {
    return (React.createElement(CheckboxGroupNameContext.Provider, { value: groupName },
        React.createElement(StyledFieldset, { className: className, spacer: spacer }, children)));
};
const StyledFieldset = styled.fieldset `
  margin: 0;
  padding: 0 0 1.6rem 2rem;
  border: none;

  label {
    margin-block-end: ${({ spacer }) => spacer};
  }
`;
