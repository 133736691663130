/**
 * Tumelo defined industry categories
 */
export var IndustryCategory;
(function (IndustryCategory) {
    IndustryCategory["UNKNOWN"] = "0";
    IndustryCategory["AGRICULTURE_AND_FOOD"] = "1";
    // RESERVED: ENERGY = '2',
    IndustryCategory["FINANCE"] = "3";
    IndustryCategory["HEALTHCARE"] = "4";
    IndustryCategory["INDUSTRIAL"] = "5";
    IndustryCategory["LIFESTYLE"] = "6";
    IndustryCategory["REAL_ESTATE"] = "7";
    IndustryCategory["RETAIL"] = "8";
    IndustryCategory["TECH"] = "9";
    IndustryCategory["TRANSPORT_AND_VEHICLES"] = "10";
    // RESERVED: UTILITIES = '11',
    IndustryCategory["SPECIALIST_SERVICES"] = "12";
    IndustryCategory["ENERGY_AND_UTILITIES"] = "13";
})(IndustryCategory || (IndustryCategory = {}));
/**
 * Complete list of valid Tumelo industry categories
 */
export const industryCategories = [
    IndustryCategory.AGRICULTURE_AND_FOOD,
    IndustryCategory.ENERGY_AND_UTILITIES,
    IndustryCategory.FINANCE,
    IndustryCategory.HEALTHCARE,
    IndustryCategory.INDUSTRIAL,
    IndustryCategory.LIFESTYLE,
    IndustryCategory.REAL_ESTATE,
    IndustryCategory.RETAIL,
    IndustryCategory.TECH,
    IndustryCategory.TRANSPORT_AND_VEHICLES,
    IndustryCategory.SPECIALIST_SERVICES,
    IndustryCategory.UNKNOWN,
];
/**
 * Returns the industry category Title given an industry category
 * It handles undefined / unknown industry categories
 */
export const industryCategoryToIndustryCategoryTitle = (category) => {
    return (category && industryCategoryToIndustryCategoryTitleMap.get(category)) || 'Uncategorised';
};
const industryCategoryToIndustryCategoryTitleMap = new Map([
    [IndustryCategory.AGRICULTURE_AND_FOOD, 'Agriculture & Food'],
    [IndustryCategory.ENERGY_AND_UTILITIES, 'Energy & Utilities'],
    [IndustryCategory.FINANCE, 'Finance'],
    [IndustryCategory.HEALTHCARE, 'Healthcare'],
    [IndustryCategory.INDUSTRIAL, 'Industrial'],
    [IndustryCategory.LIFESTYLE, 'Lifestyle'],
    [IndustryCategory.REAL_ESTATE, 'Real Estate'],
    [IndustryCategory.RETAIL, 'Retail'],
    [IndustryCategory.TECH, 'Tech'],
    [IndustryCategory.TRANSPORT_AND_VEHICLES, 'Transport & Vehicles'],
    [IndustryCategory.SPECIALIST_SERVICES, 'Specialist Services'],
    [IndustryCategory.UNKNOWN, 'Uncategorised'],
]);
