/* eslint-disable @typescript-eslint/no-deprecated */

import React, { useState } from 'react'
import styled from 'styled-components'
import { Organization, Poll, Breakpoint } from '@tumelo/shared'
import Header from './Top/Header'
import { Question } from './Top/Question'
import CallToAction, { ReadMoreVariant } from './Bottom/CallToAction'

interface Props {
  poll: Poll
  organization: Organization
  onClickOrganization: () => void
  readMoreVariant?: ReadMoreVariant
  initialReadMoreState?: boolean
  onToggleReadMore?: (isReadMoreOpen: boolean) => void
}

export const SingleQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  organization,
  poll,
  onClickOrganization,
  children,
  readMoreVariant = 'arrow',
  initialReadMoreState = false,
  onToggleReadMore,
}) => {
  const [isReadMoreOpen, setIsReadMoreOpen] = useState<boolean>(initialReadMoreState)

  return (
    <Container>
      <Top>
        <Header organization={organization} onClick={onClickOrganization} tags={poll.tags} />
        <Question poll={poll} isReadMoreOpen={isReadMoreOpen} />
      </Top>
      <Bottom>
        <CallToAction
          variant={readMoreVariant}
          isReadMoreOpen={isReadMoreOpen}
          setIsReadMoreOpen={() => {
            if (typeof onToggleReadMore !== 'undefined') {
              onToggleReadMore(isReadMoreOpen)
            }
            setIsReadMoreOpen(!isReadMoreOpen)
          }}
        >
          {children}
        </CallToAction>
      </Bottom>
    </Container>
  )
}

interface Styles {
  MARGIN: number
  MAX_WIDTH: number
  ICON_SIZE: number
  CONTENT_MAX_WIDTH: number
}

export const SingleQuestionStyles: Styles = {
  MARGIN: 2,
  MAX_WIDTH: 900,
  CONTENT_MAX_WIDTH: 700,
  ICON_SIZE: 5,
}

const { ICON_SIZE, MARGIN } = SingleQuestionStyles

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.boxShadow};
  background-color: ${({ theme }) => theme.colors.background.light};
  border-radius: ${MARGIN}rem;
  max-width: 100rem;
`

const Top = styled.div`
  padding: ${MARGIN * 2}rem;
  @media (max-width: ${Breakpoint.mobile}) {
    padding: ${MARGIN}rem;
  }
`

const Bottom = styled(Top)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 ${MARGIN}rem ${MARGIN}rem;
  box-shadow: 0px -4px 6px -2px ${'rgba(0, 0, 0, 0.1)'};

  margin-top: -${ICON_SIZE}rem;
  @media (max-width: ${Breakpoint.mobile}) {
    margin-top: 0;
  }
`
