/* eslint-disable @typescript-eslint/no-deprecated */

import React, { useState } from 'react'
import styled from 'styled-components'
import { Investor, SubHeading, Body, FontWeight, GridCard, CardHeader, CardContent, CardCTA } from '@tumelo/shared'
import { useRouter } from '../../application/router/useRouter'
import { ProfileImage } from '../ProfileImage'
import { glowing } from '../../styles/animation'
import { UpdateNicknameFormModal } from './UpdateNicknameFormModal'

interface Props {
  investor?: Investor
  loading?: boolean
  useFederated: boolean
  logOut: () => void
}

export const ProfileCard: React.FC<Props> = ({
  investor: { email, firstName, lastName, nickname, signedInWithSSO } = placeholder,
  useFederated,
  loading,
  logOut,
}) => {
  const { navigate } = useRouter()
  const name = `${firstName} ${lastName}`
  const [modalOpen, setModalOpen] = useState(false)

  const showNicknameField = (signedInWithSSO && nickname) || signedInWithSSO === false
  const showUpdateNicknameCTA = signedInWithSSO === false

  return (
    <GridCard columnSpan={3} stretchY>
      <CardHeader>
        <SubHeading>Overview</SubHeading>
      </CardHeader>
      <CardContent>
        <Row>
          <ProfileImage email={email} size={100} loading={loading} />
          {loading ? (
            <LoadingName />
          ) : (
            <Body data-hj-suppress m={0} ml="2rem" fontWeight={FontWeight.bold}>
              {name}
            </Body>
          )}
        </Row>
        <Seperator />
        {showNicknameField && (
          <>
            <Column>
              <Body m={0} fontWeight={FontWeight.bold}>
                Preferred name or nickname
              </Body>
              <ValueContainer data-hj-suppress>
                <Body>{nickname ?? 'No name given'}</Body>
                {showUpdateNicknameCTA && (
                  <>
                    <div>
                      <CustomCallToAction data-test-id="update-nickname" onClick={() => setModalOpen(!modalOpen)}>
                        Update
                      </CustomCallToAction>
                    </div>
                    <UpdateNicknameFormModal open={modalOpen} handleModalClose={() => setModalOpen(false)} />
                  </>
                )}
              </ValueContainer>
            </Column>
            <Seperator />
          </>
        )}
        <Column>
          <Body m={0} fontWeight={FontWeight.bold}>
            Email
          </Body>
          {loading ? (
            <LoadingEmail />
          ) : (
            <ValueContainer data-hj-suppress>
              <Body>{email}</Body>
              {useFederated ? null : (
                <div>
                  <CustomCallToAction data-test-id="update-email" onClick={() => navigate('/profile/change-email')}>
                    Update
                  </CustomCallToAction>
                </div>
              )}
            </ValueContainer>
          )}
        </Column>
        <Seperator />
      </CardContent>
      <CardCTA onClick={() => logOut()} callToAction="Log out" />
    </GridCard>
  )
}

const placeholder: Investor = {
  sub: '',
  email: '',
  firstName: '',
  lastName: '',
  signedInWithSSO: false,
  changeEmail: {
    emailVerified: false,
  },
}

export const CustomCallToAction = styled.span`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.grey.dark};
  cursor: pointer;
  border-bottom: 1px solid ${(p) => p.color};
`

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const LoadingText = styled.div`
  height: 3rem;
  width: 20rem;
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 1rem;
  animation: ${glowing} 2s infinite;
`

const LoadingName = styled(LoadingText)`
  margin-left: 2rem;
`

const LoadingEmail = styled(LoadingText)`
  margin-top: 1rem;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Seperator = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.grey.light};
  margin: 3rem 0 3rem 0;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`
