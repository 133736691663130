import * as React from 'react';
export const SvgEqualitysmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#FFF8DD" }),
        React.createElement("path", { d: "M16 22H8C7.45 22 7 21.55 7 21C7 20.45 7.45 20 8 20H11V6.38999L7.26 7.63999L9.94 15.69C10.08 16.1 9.94 16.55 9.59 16.81C7.53 18.36 4.45 18.36 2.39 16.81C2.05 16.55 1.91 16.1 2.04 15.69L4.74 7.62999L2.69 6.94999C2.27 6.80999 2.01 6.41999 2.01 5.99999C2.01 5.89999 2.03 5.78999 2.06 5.67999C2.23 5.15999 2.8 4.86999 3.32 5.04999L6 5.93999L11 4.26999V2.98999C11 2.43999 11.45 1.98999 12 1.98999C12.55 1.98999 13 2.43999 13 2.98999V4.26999L18 5.93999L20.68 5.04999C21.21 4.86999 21.77 5.15999 21.94 5.67999C21.98 5.77999 21.99 5.88999 21.99 5.99999C21.99 6.41999 21.73 6.80999 21.31 6.94999L19.26 7.62999L21.94 15.68C22.08 16.09 21.94 16.54 21.59 16.8C19.53 18.35 16.45 18.35 14.39 16.8C14.05 16.54 13.91 16.09 14.04 15.68L16.72 7.62999L12.98 6.37999V19.99H15.98C16.53 19.99 16.98 20.44 16.98 20.99C16.98 21.54 16.53 21.99 15.98 21.99L16 22ZM16.2 15.57C17.31 16.13 18.69 16.13 19.81 15.57L18.01 10.16L16.21 15.57H16.2ZM4.2 15.57C5.31 16.13 6.69 16.13 7.81 15.57L6.01 10.16L4.21 15.57H4.2Z", fill: "#B08B1E" })));
};
