import { useCallback } from 'react';
export function useInfiniteScroll(callbackParam) {
    const callback = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (entries) => {
        if (entries[0].isIntersecting) {
            callbackParam();
        }
    }, [callbackParam]);
    const infiniteScrollRef = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (node) => {
        if (!node) {
            return;
        }
        const intersectionObserver = new IntersectionObserver(callback);
        intersectionObserver.observe(node);
    }, [callback]);
    return infiniteScrollRef;
}
