import { compose } from '../../utils/functional/common'
import { RootState } from '../rootReducer'

// TODO should have tests but would need to define a SampleRootState
const getNumberOfActiveFilters = (state: RootState): number => {
  let count = 0
  const { searchFilters } = state

  count += countAllTrueValues(Object.values(searchFilters.binaryFilters))
  count += countAllTrueValues(Object.values(searchFilters.pollResponse))
  count += countAllTrueValues(Object.values(searchFilters.votingOrganizationIndustries))
  count += countAllTrueValues(Object.values(searchFilters.pollTags))

  return count
}

export const countAllTrueValues = (values: boolean[]): number => {
  return values.filter((value) => {
    return value
  }).length
}

export const hasActiveFilters = compose(getNumberOfActiveFilters, (count) => count > 0)
