/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { SubHeading, Body, GridCard, CardHeader, CardContent, CardCTA } from '@tumelo/shared'
import { BallotWithPollAndOrg } from '../../application/types/PollWithOrganization/PollWithOrganization'
import { Payload } from '../../application/payload'
import { PollCardlet } from '../Cardlet'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'
import { SkeletonCardlet } from '../Cardlet/SkeletonCardlet'

interface DashboardVotesOpenProps {
  votes: Payload<BallotWithPollAndOrg[] | undefined>
  handleCallToAction: () => void
}

const helpText = `Every year, each company holds a company AGM (Annual General Meeting). Here, shareholders (anyone who owns a piece of that company) can ask questions that matter to them. Then all the company shareholders can vote in favour or against those questions.

You can get involved by telling your fund manager (one of the company shareholders) whether you think they should vote In favour or Against those questions.

Just head over to Open votes to see what’s available and have your say.`

export const DashboardVotesOpen: React.FC<DashboardVotesOpenProps> = ({ votes, handleCallToAction }) => {
  return (
    <GridCard columnSpan={3} stretchY>
      <CardHeader helpText={helpText}>
        <SubHeading>Open votes</SubHeading>
        <Body>Place your vote preference on upcoming issues at the companies your pension is invested in.</Body>
      </CardHeader>
      <CardContent>
        <PayloadUnwrapper item={votes} customNotInitialized={<SkeletonCardlet />} customSpinner={<SkeletonCardlet />}>
          {(polls) => {
            if (polls === undefined || polls.length === 0) {
              return <Body data-test="openVotes">Sit tight, there are no open votes at the moment.</Body>
            }
            return (
              <CardletContainer key={polls[0].poll.id} data-test="openVotes">
                <PollCardlet poll={polls[0]} navigateTo="openVote" />
              </CardletContainer>
            )
          }}
        </PayloadUnwrapper>
      </CardContent>
      <CardCTA onClick={handleCallToAction} callToAction="See more votes" />
    </GridCard>
  )
}

const CardletContainer = styled.div`
  margin: 0.5rem 0.3rem 1rem 0.3rem;
`
