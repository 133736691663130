import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgStar = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M18.18 22.49C18.0159 22.4892 17.8545 22.448 17.71 22.37L12 19.37L6.29001 22.37C6.12493 22.459 5.93815 22.4998 5.75101 22.4877C5.56386 22.4756 5.38388 22.4111 5.23163 22.3016C5.07938 22.1921 4.96099 22.042 4.88997 21.8684C4.81895 21.6949 4.79818 21.5048 4.83001 21.32L5.93001 15L1.30001 10.45C1.16795 10.3192 1.07497 10.1541 1.03152 9.97334C0.988063 9.79259 0.995856 9.6033 1.05402 9.42673C1.11218 9.25016 1.21842 9.0933 1.36079 8.97376C1.50316 8.85422 1.67604 8.77673 1.86001 8.75L8.25001 7.81L11.1 2C11.1819 1.83093 11.3097 1.68834 11.4689 1.58857C11.6281 1.48881 11.8122 1.43589 12 1.43589C12.1879 1.43589 12.3719 1.48881 12.5311 1.58857C12.6903 1.68834 12.8181 1.83093 12.9 2L15.75 7.79L22.14 8.73C22.324 8.75673 22.4969 8.83422 22.6392 8.95376C22.7816 9.0733 22.8878 9.23016 22.946 9.40673C23.0042 9.5833 23.012 9.77259 22.9685 9.95334C22.9251 10.1341 22.8321 10.2992 22.7 10.43L18.07 15L19.17 21.36C19.2057 21.5475 19.187 21.7413 19.1161 21.9185C19.0453 22.0956 18.9252 22.2489 18.77 22.36C18.5901 22.4596 18.3851 22.5048 18.18 22.49ZM12 17.24C12.1633 17.2377 12.3247 17.2755 12.47 17.35L16.85 19.66L16 14.78C15.9721 14.6192 15.9839 14.4541 16.0344 14.299C16.085 14.1438 16.1727 14.0034 16.29 13.89L19.84 10.43L15 9.72C14.838 9.69747 14.684 9.63554 14.5515 9.53965C14.419 9.44376 14.3121 9.31684 14.24 9.17L12 4.73L9.81001 9.17C9.73797 9.31684 9.63101 9.44376 9.49851 9.53965C9.36601 9.63554 9.21201 9.69747 9.05001 9.72L4.15001 10.43L7.70001 13.89C7.81729 14.0034 7.90503 14.1438 7.95558 14.299C8.00612 14.4541 8.01795 14.6192 7.99001 14.78L7.15001 19.66L11.53 17.35C11.6753 17.2755 11.8367 17.2377 12 17.24Z" })));
};
