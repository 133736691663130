import * as z from 'myzod'
import { dateToTimestamp, timestampToDate } from '@tumelo/shared'
import { InvestorExtendedProfile } from '../../types/InvestorExtendedProfile/InvestorExtendedProfile'

export interface InvestorExtendedProfileService {
  getProfile(): Promise<InvestorExtendedProfile>

  saveProfile(profile: InvestorExtendedProfile): Promise<void>

  deleteProfile(): Promise<void>
}

export const InvestorExtendedProfileSchema = z
  .object(
    {
      tracking: z
        .object(
          {
            firstActive: z.date().optional(),
            lastActive: z.date().optional(),
          },
          { allowUnknown: true }
        )
        .default({}),
    },
    { allowUnknown: true }
  )
  .default({ tracking: {} })

type InvestorExtededProfileSchemaType = z.Infer<typeof InvestorExtendedProfileSchema>

export const mapSchemaToType = ({
  tracking: { firstActive, lastActive },
}: InvestorExtededProfileSchemaType): InvestorExtendedProfile => {
  return {
    tracking: {
      firstActive: firstActive ? dateToTimestamp(firstActive) : undefined,
      lastActive: lastActive ? dateToTimestamp(lastActive) : undefined,
    },
  }
}

export const mapTypeToSchema = (investor: InvestorExtendedProfile): InvestorExtededProfileSchemaType => {
  const { lastActive, firstActive } = investor.tracking
  return {
    tracking: {
      firstActive: firstActive ? timestampToDate(firstActive) : undefined,
      lastActive: lastActive ? timestampToDate(lastActive) : undefined,
    },
  }
}
