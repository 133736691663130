import React, { useEffect } from 'react'
import { useAppSelector } from '../../application/store'
import { isValueState } from '../../application/payload'
import { selectUserMostPopularTag } from '../../application/selectors/ballotComposite'
import { buildEvent } from '../../application/analytics/eventBuilders'
import { useAnalytics } from '../../application/analytics'
import { useInvestorStatistics } from '../../application/features/statistics/hooks'
import { YourVotingInterestsCard } from './YourVotingInterestsCard'

export const YourVotingInterestsCardContainer: React.FC<{ minVotesThreshold: number }> = ({
  minVotesThreshold: threshold,
}) => {
  const investorStatistics = useInvestorStatistics()

  const usersMostPopularTag = useAppSelector(selectUserMostPopularTag(threshold))
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    // Only track rendered event if user has a top category
    if (usersMostPopularTag && isValueState(usersMostPopularTag)) {
      trackEvent(buildEvent.returningCardRendered({ card_type: 'your-voting-interests-card' }))
    }
  }, [trackEvent, usersMostPopularTag, investorStatistics])

  if (isValueState(usersMostPopularTag) && usersMostPopularTag === undefined) {
    return null
  }

  return <YourVotingInterestsCard key="voting-interests-card" tagAndCount={usersMostPopularTag} />
}
