import { useEffect } from 'react'
import { VoteResult } from '../../types/VoteResult'
import { useAppDispatch, useAppSelector } from '../../store'
import { fetchVoteResultsPage } from './asyncActions'
import { selectVoteResultsFiltered } from './selectors'

export const useVoteResults = () => {
  const dispatch = useAppDispatch()
  const { nextPageToken, pageState, minCloseTime } = useAppSelector((state) => state.voteResults)
  const voteResults: VoteResult[] = useAppSelector(selectVoteResultsFiltered)

  const loadNextPage = () => {
    if (pageState === 'loaded') {
      dispatch(fetchVoteResultsPage(nextPageToken))
    }
  }

  useEffect(() => {
    if (pageState === 'not-initialized') {
      dispatch(fetchVoteResultsPage())
    }
  }, [pageState, dispatch])

  return { voteResults, minCloseTime, pageState, loadNextPage }
}
