import * as React from 'react';
export const SvgMoneycoinsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#E3F3F3" }),
        React.createElement("path", { d: "M12.29 8.29C12.66 7.92 13.33 7.92 13.7 8.29C13.89 8.48 13.99 8.73 13.99 9C13.99 9.55 14.44 10 14.99 10C15.54 10 15.99 9.55 15.99 9C15.99 8.2 15.68 7.45 15.11 6.88C13.98 5.75 12 5.75 10.87 6.88C10.3 7.45 9.99 8.2 9.99 9V11H8.99C8.44 11 7.99 11.45 7.99 12C7.99 12.55 8.44 13 8.99 13H9.99V14C9.99 14.26 9.88 14.52 9.7 14.71C9.52 14.9 9.26 15 8.99 15C8.44 15 7.99 15.45 7.99 16C7.99 16.55 8.44 17 8.99 17H14.99C15.54 17 15.99 16.55 15.99 16C15.99 15.45 15.54 15 14.99 15H11.82C11.93 14.68 11.99 14.35 11.99 14V13H12.99C13.54 13 13.99 12.55 13.99 12C13.99 11.45 13.54 11 12.99 11H11.99V9C11.99 8.74 12.1 8.48 12.28 8.29H12.29ZM19.07 4.93C17.18 3.04 14.67 2 12 2C9.33 2 6.82 3.04 4.93 4.93C3.04 6.82 2 9.33 2 12C2 13.32 2.26 14.61 2.76 15.83C3.26 17.05 3.99 18.14 4.93 19.08C5.86 20.01 6.96 20.74 8.17 21.25C9.39 21.75 10.68 22.01 12 22.01C13.32 22.01 14.61 21.75 15.83 21.25C17.05 20.75 18.14 20.02 19.08 19.08C20.01 18.15 20.74 17.05 21.25 15.84C21.75 14.62 22.01 13.33 22.01 12.01C22.01 9.34 20.97 6.83 19.08 4.94L19.07 4.93ZM19.39 15.06C18.99 16.03 18.4 16.91 17.66 17.66C16.91 18.41 16.04 18.99 15.06 19.39C13.11 20.2 10.89 20.2 8.94 19.39C7.97 18.99 7.09 18.4 6.34 17.66C5.59 16.91 5.01 16.04 4.61 15.06C4.21 14.09 4 13.06 4 12C4 9.86 4.83 7.85 6.34 6.34C7.85 4.83 9.86 4 12 4C14.14 4 16.15 4.83 17.66 6.34C19.17 7.85 20 9.86 20 12C20 13.06 19.79 14.09 19.39 15.06Z", fill: "#1D7A6C" })));
};
