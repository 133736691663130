import React from 'react'
import { useTheme } from 'styled-components'
import { IconButton } from '@mui/material'
import { BellIcon } from '@tumelo/shared'
import { PayloadUnwrapperM } from '../../../Error/PayloadUnwrapper'
import { NotificationsNumber } from '../../../Notifications/NotificationsNumber'
import { notificationIsResolved } from '../../../../application/types/AppNotification/AppNotification'
import { useNotifications } from '../../../../application/features/notifications/hooks'

interface Props {
  fillColor: string
}

export const BellConnected: React.FC<Props> = ({ fillColor }) => {
  const { notifications } = useNotifications()

  return (
    <PayloadUnwrapperM
      items={[notifications]}
      customSpinner={<LoadingBell />}
      customNotInitialized={<LoadingBell />}
      customError={<ErrorBell />}
    >
      {([notifications]) => {
        const unresolvedNotificationsCount = notifications.filter(
          (notification) => notificationIsResolved(notification) === false
        ).length

        return (
          <>
            <IconButton aria-label={`Notifications ${notifications.length}`}>
              <div style={{ width: 22, height: 24.1 }}>
                <BellIcon fillColor={fillColor} animation={notifications.length > 0} />
              </div>
            </IconButton>
            <NotificationsNumber count={unresolvedNotificationsCount} />
          </>
        )
      }}
    </PayloadUnwrapperM>
  )
}

const LoadingBell: React.FC = () => {
  const { colors } = useTheme()

  return (
    <IconButton aria-label="Notifications loading">
      <div style={{ width: 22, height: 24.1 }}>
        <BellIcon fillColor={colors.info.medium} animation={false} />
      </div>
    </IconButton>
  )
}

const ErrorBell: React.FC = () => {
  return null
}
