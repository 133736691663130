import * as React from 'react'
import { useRouter } from '../../application/router/useRouter'
import { useAppSelector } from '../../application/store'
import { selectAwsUseFederatedSignIn } from '../../application/features/config/selectors'
import { isErrorStates, isValueState } from '../../application/payload'
import { useInvestor } from '../../application/features/investor/useInvestor'
import { useLogout } from '../../application/hooks'
import { ProfileCard } from './ProfileCard'

export const ProfileCardConnected: React.FC = () => {
  const { investor } = useInvestor()
  const useFederated = useAppSelector(selectAwsUseFederatedSignIn)
  const { logout } = useLogout()

  const { navigate } = useRouter()

  if (isErrorStates(investor)) {
    // TODO Handle error state better
    navigate('/not-found')
  }

  if (!isValueState(investor)) {
    return <ProfileCard loading logOut={() => logout()} useFederated={useFederated} />
  }

  return <ProfileCard investor={investor} logOut={() => logout()} useFederated={useFederated} />
}
