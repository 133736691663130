import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { isValueState } from '../../payload'
import { useInvestor } from '../investor/useInvestor'
import { getInvestorStatistics, getHabitatStatistics } from './asyncActions'
import { selectHabitatStatistics, selectInvestorStatistics } from './selectors'

export const useInvestorStatistics = () => {
  const dispatch = useAppDispatch()
  const { investor } = useInvestor()
  const statistics = useAppSelector(selectInvestorStatistics)

  useEffect(() => {
    if (statistics === 'not-initialised' && isValueState(investor) && investor.investorId) {
      dispatch(getInvestorStatistics())
    }
  }, [statistics, investor, dispatch])

  return statistics
}

export const useHabitatStatistics = () => {
  const dispatch = useAppDispatch()
  const statistics = useAppSelector(selectHabitatStatistics)

  useEffect(() => {
    if (statistics === 'not-initialised') {
      dispatch(getHabitatStatistics())
    }
  }, [statistics, dispatch])

  return statistics
}
