import { useEffect } from 'react'
import { OrganizationBreakdown } from '@tumelo/shared'
import { useAppDispatch, useAppSelector } from '../../store'
import { isValueState, Payload } from '../../payload'
import { getTopOrganizations } from './asyncActions'
import { selectTopFiveOrganizations } from './selectors'
import { setTopFiveError } from '.'

export const useTopFiveOrganizations = (breakdown: Payload<OrganizationBreakdown>) => {
  const dispatch = useAppDispatch()
  const organizations = useAppSelector(selectTopFiveOrganizations)

  useEffect(() => {
    if (organizations === 'not-initialised' && isValueState(breakdown)) {
      dispatch(getTopOrganizations())
    }
    if (breakdown === 'error') {
      dispatch(setTopFiveError())
    }
  }, [dispatch, breakdown, organizations])

  return organizations
}
