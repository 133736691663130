import {
  AccountId,
  BallotId,
  FundManager,
  FundManagerId,
  FundManagerVote,
  FundManagerVoteId,
  ProposalId,
} from '@tumelo/shared'
import { APIServiceBaseClass } from '../../../utils/api'
import { FetchRelatedFundManagerVotesResponse } from '../../../utils/api/gen'
import { FundManagerVotesResponse, FundManagerVotesService } from './FundManagerVotesService'

const mapToDomain = ({
  fundManagers,
  fundManagerVotes,
}: FetchRelatedFundManagerVotesResponse): FundManagerVotesResponse => {
  const fms: FundManager[] = fundManagers.map((fm) => ({
    ...fm,
    id: fm.id as FundManagerId,
  }))
  const fmvs: FundManagerVote[] = fundManagerVotes.map((fmv) => ({
    ...fmv,
    id: fmv.id as FundManagerVoteId,
    fundManagerId: fmv.fundManagerId as FundManagerId,
    proposalId: fmv.proposalId as ProposalId,
  }))
  return {
    fundManagerVotes: fmvs,
    fundManagersMap: new Map<FundManagerId, FundManager>(fms.map((fm) => [fm.id, fm])),
  }
}

export class FundManagerVotesServiceAPI extends APIServiceBaseClass implements FundManagerVotesService {
  async fetchFundManagerVotesForProposal(
    proposals: ProposalId[],
    account: AccountId
  ): Promise<FundManagerVotesResponse> {
    const { habitatId, investorId } = this
    const votingApi = await this.getVotingApi()
    const fmvotes = await votingApi.investorAccountFetchRelatedFundManagerVotes({
      habitatId,
      investorId,
      accountId: account,
      fetchFundManagerVotesRelatedToAccountBody: {
        proposalIds: proposals,
      },
    })
    return mapToDomain(fmvotes)
  }

  async fetchFundManagerVotesForBallot(ballot: BallotId): Promise<FundManagerVotesResponse> {
    const { habitatId, investorId } = this
    const votingApi = await this.getVotingApi()
    const fmvotes = await votingApi.fetchRelatedFundManagerVotes({
      habitatId,
      investorId,
      ballotId: ballot,
    })
    return mapToDomain(fmvotes)
  }
}
