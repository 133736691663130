import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Payload } from '../../payload'
import { CompositionWithInstruments } from '../../types/CompositionWithInstruments'

interface AccountCompositionState {
  accountComposition: Payload<CompositionWithInstruments>
}

const getInitialState = (): AccountCompositionState => {
  return { accountComposition: 'not-initialised' }
}

const slice = createSlice({
  name: 'accountComposition',
  initialState: getInitialState(),
  reducers: {
    setAccountCompositionWithInstrumentsResult: (state, action: PayloadAction<Payload<CompositionWithInstruments>>) => {
      state.accountComposition = action.payload
    },
  },
})

export default slice.reducer

export const { setAccountCompositionWithInstrumentsResult } = slice.actions
