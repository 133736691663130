import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgCount3Fill = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12 1C5.92 1 1 5.92 1 12C1 18.08 5.92 23 12 23C18.08 23 23 18.08 23 12C23 5.92 18.08 1 12 1ZM15.1 12.16C14.64 11.66 13.98 11.33 13.13 11.19L15.34 8.67V7.2H8.48V9.02H12.65L10.63 11.3V12.8H11.67C12.89 12.8 13.5 13.2 13.5 14C13.5 14.4 13.34 14.71 13.01 14.94C12.68 15.15 12.24 15.26 11.67 15.26C11.16 15.26 10.65 15.19 10.16 15.04C9.67 14.88 9.25 14.66 8.87 14.38L7.99 16.12C8.46 16.46 9.02 16.72 9.68 16.9C10.35 17.08 11.03 17.17 11.72 17.17C12.61 17.17 13.35 17.03 13.96 16.74C14.58 16.45 15.03 16.07 15.33 15.59C15.63 15.1 15.78 14.58 15.78 14.01C15.78 13.27 15.55 12.66 15.09 12.16H15.1Z" })));
};
