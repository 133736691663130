import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrganizationBreakdown } from '@tumelo/shared'
import { Payload } from '../../payload'

const getInitialState: () => Payload<OrganizationBreakdown> = () => 'not-initialised'

const breakdownSlice = createSlice({
  name: 'breakdown',
  initialState: getInitialState(),
  reducers: {
    setOrganizationBreakdown(_, action: PayloadAction<Payload<OrganizationBreakdown>>) {
      return action.payload
    },
  },
})

export default breakdownSlice.reducer

export const { setOrganizationBreakdown } = breakdownSlice.actions
