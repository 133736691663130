import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TumeloAccount, ModelPortfolio, Timestamp } from '@tumelo/shared'
import { Payload } from '../../payload'

interface AccountState {
  availableModelPortfolios: Payload<ModelPortfolio[]>
  account: Payload<TumeloAccount | 'not-configured'>
  /**
   * latestTriggerFetch is a timestamp of the last time that the trigger fetch request was made.
   */
  latestTriggerFetch: Payload<Timestamp>
}

const getInitialState = (): AccountState => ({
  availableModelPortfolios: 'not-initialised',
  account: 'not-initialised',
  latestTriggerFetch: 'not-initialised',
})

const accountSlice = createSlice({
  name: 'account',
  initialState: getInitialState(),
  reducers: {
    setAvailableModelPortfolios: (state, action: PayloadAction<Payload<ModelPortfolio[]>>) => {
      state.availableModelPortfolios = action.payload
    },
    setAccount: (state, action: PayloadAction<Payload<TumeloAccount | 'not-configured'>>) => {
      state.account = action.payload
    },
    setLatestTriggerFetchComplete: (state, action: PayloadAction<Timestamp>) => {
      state.latestTriggerFetch = action.payload
    },
    setLatestTriggerFetchLoading: (state) => {
      state.latestTriggerFetch = 'pending'
    },
    setLatestTriggerFetchError: (state) => {
      state.latestTriggerFetch = 'error'
    },
  },
})

export default accountSlice.reducer

export const {
  setAvailableModelPortfolios,
  setAccount,
  setLatestTriggerFetchComplete,
  setLatestTriggerFetchLoading,
  setLatestTriggerFetchError,
} = accountSlice.actions
