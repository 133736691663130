import { useState, useEffect } from 'react';
const DELAY = 150;
// Takes the window size and returns it on window size change
// Includes a delay, so that this doesn't affect performance.
export function useWindowWidth() {
    const [width, setWidth] = useState(getWidth());
    useEffect(() => {
        if (typeof window !== 'undefined') {
            let timeoutId;
            const resizeListener = () => {
                // prevent execution of previous setTimeout
                clearTimeout(timeoutId);
                // change width from the state object after delay amount in milliseconds
                timeoutId = window.setTimeout(() => setWidth(getWidth()), DELAY);
            };
            window.addEventListener('resize', resizeListener);
            return () => {
                window.removeEventListener('resize', resizeListener);
            };
        }
        // window will be undefined at build time for NextJs so this is needed for build time
        return () => { };
    }, []);
    return width;
}
const getWidth = () => typeof window !== 'undefined' || typeof document !== 'undefined'
    ? window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    : -1;
