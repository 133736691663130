import { Timestamp, timestampToDate } from '@tumelo/shared'

export const createDateString = (timestamp: Timestamp) => {
  const datetimeOfEvent = timestampToDate(timestamp)
  const currentDatetime = new Date()
  const timeDifference = currentDatetime.getTime() - datetimeOfEvent.getTime()
  const seconds = Math.floor(timeDifference) / 1000
  const hours = seconds / 3600
  if (hours < 1) {
    return 'Now'
  }
  if (hours >= 1 && hours < 24) {
    return `${Math.floor(hours)}h ago`
  }
  return timestampToDate(timestamp).toLocaleString('en-GB', { day: 'numeric', month: 'short' })
}
