import { FundManager, FundManagerId } from '@tumelo/shared'
import { FundManagerVotesService } from './FundManagerVotesService'

export const FundManagerVotesServiceMock: FundManagerVotesService = {
  fetchFundManagerVotesForProposal: async () => ({
    fundManagerVotes: [],
    fundManagersMap: new Map<FundManagerId, FundManager>(),
  }),

  fetchFundManagerVotesForBallot: async () => ({
    fundManagerVotes: [],
    fundManagersMap: new Map<FundManagerId, FundManager>(),
  }),
}
