import { useEffect } from 'react'
import { Investor, TumeloAccount } from '@tumelo/shared'
import { useAppDispatch, useAppSelector } from '../../store'
import { isValueState, Payload } from '../../payload'
import { fetchAccount, fetchAvailableModelPortfolios, triggerFetchOfCompositions } from './asyncActions'
import { selectAccount, selectLatestTriggerFetch, selectAvailableModelPortfolios } from './selectors'

export const useAccount = (investor: Payload<Investor>): Payload<TumeloAccount | 'not-configured'> => {
  const dispatch = useAppDispatch()
  const account = useAppSelector(selectAccount)
  const accountLatestFetch = useAppSelector(selectLatestTriggerFetch)

  useEffect(() => {
    if (accountLatestFetch === 'not-initialised') {
      dispatch(triggerFetchOfCompositions())
    }
    if (
      account === 'not-initialised' &&
      isValueState(investor) &&
      investor.investorId &&
      isValueState(accountLatestFetch)
    ) {
      dispatch(fetchAccount())
    }
  }, [account, investor, accountLatestFetch, dispatch])

  if (accountLatestFetch === 'error') {
    return 'error'
  }
  return account
}

export const useAvailableModelPortfolios = () => {
  const dispatch = useAppDispatch()
  const availableModelPortfolios = useAppSelector(selectAvailableModelPortfolios)

  useEffect(() => {
    if (availableModelPortfolios === 'not-initialised') {
      dispatch(fetchAvailableModelPortfolios())
    }
  }, [availableModelPortfolios, dispatch])

  return { availableModelPortfolios }
}
