import styled from 'styled-components'
import { WarningIcon } from '@tumelo/designsystem'

export const StyledWarningIcon = styled(WarningIcon)<{ indent?: number; top?: number | 'center' }>`
  position: absolute;
  right: 0;
  top: ${(p) => (p.top === 'center' ? `calc(50% - 24px)` : `${p.indent ?? 1.5}rem`)};
  margin-right: ${(p) => p.indent ?? 1.5}rem;
  padding: 1rem;
  stroke: ${({ theme }) => theme.colors.warning.medium};
  fill: ${({ theme }) => theme.colors.warning.medium};
`
