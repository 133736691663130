import React from 'react'
import { Button } from '@tumelo/designsystem'
import { useAppDispatch } from '../../application/store'
import { resolveAllNotifications } from '../../application/features/notifications/asyncActions'

export const MarkAllAsReadButton: React.FC = () => {
  const dispatch = useAppDispatch()

  const markAllAsRead = () => {
    dispatch(resolveAllNotifications())
  }

  return (
    <Button onClick={markAllAsRead} size="small" variant="tertiary">
      Mark all as read
    </Button>
  )
}
