import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgTwitterfill = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 21, height: 18, viewBox: "0 0 21 18", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M6.29 17.26C13.84 17.26 17.97 10.62 17.97 4.86001C17.97 4.67001 17.97 4.48001 17.96 4.30001C18.77 3.67001 19.46 2.91001 20.01 2.04001C19.27 2.39001 18.47 2.62001 17.65 2.73001C18.52 2.17001 19.16 1.31001 19.46 0.32001C18.66 0.82001 17.78 1.18001 16.85 1.38001C16.1 0.51001 15 0.0100098 13.85 0.0100098C11.51 0.0800098 9.68 2.03001 9.74 4.37001C9.74 4.70001 9.78 5.04001 9.85 5.36001C6.5 5.15001 3.41 3.48001 1.39 0.80001C1.02 1.47001 0.83 2.23001 0.83 2.99001C0.82 4.42001 1.5 5.78001 2.66 6.62001C2 6.60001 1.36 6.41001 0.8 6.07001V6.13001C0.76 8.15001 2.13 9.93001 4.09 10.4C3.74 10.5 3.37 10.55 3.01 10.55C2.75 10.55 2.49 10.52 2.24 10.47C2.73 12.2 4.28 13.43 6.08 13.5C4.39 14.92 2.19 15.58 0 15.31C1.85 16.58 4.05 17.26 6.29 17.27" })));
};
