/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { AddRounded, RemoveRounded } from '@mui/icons-material'
import { Breakpoint } from '@tumelo/shared'

interface Props {
  isOpen: boolean
  setIsOpen: () => void
}

const ReadMoreButton: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <Circle onClick={setIsOpen}>
      {isOpen ? <RemoveIcon style={{ fontSize: '2.4rem' }} /> : <AddIcon style={{ fontSize: '2.4rem' }} />}
    </Circle>
  )
}

const AddIcon = styled(AddRounded)`
  stroke: ${({ theme }) => theme.colors.secondary.medium};
  stroke-width: 0.5;
  color: ${({ theme }) => theme.colors.secondary.medium};
`
const RemoveIcon = styled(RemoveRounded)`
  stroke: ${({ theme }) => theme.colors.secondary.medium};
  stroke-width: 0.5;
  color: ${({ theme }) => theme.colors.secondary.medium};
`

const SingleQuestionStyles = {
  MARGIN: 2,
  MAX_WIDTH: 900,
  CONTENT_MAX_WIDTH: 700,
  ICON_SIZE: 5,
}

const { ICON_SIZE } = SingleQuestionStyles

const Circle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  width: ${ICON_SIZE}rem;
  height: ${ICON_SIZE}rem;
  border-radius: 100%;

  box-shadow: 0 3px 8px 0 ${({ theme }) => theme.colors.boxShadow};

  margin: 0 auto;
  margin-top: -6.5rem;

  @media (max-width: ${Breakpoint.mobile}) {
    margin-top: -4.5rem;
  }

  animation: bounce 1s;

  @keyframes bounce {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-30px);
    }
    50% {
      transform: scale(1.05, 0.95) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-7px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }
`

export default ReadMoreButton
