import React from 'react'
import { SelfSelectLayout, UnsupportedFund } from '../../components/SelfSelect'
import { useLogout } from '../../application/hooks/useLogout'
import { useRouter } from '../../application/router/useRouter'
import { useSelfSelectFlow } from '../../application/hooks/useSelfSelectFlow'
import { PayloadUnwrapper } from '../../components/Error/PayloadUnwrapper'

const UnsupportedFundPage: React.FC = () => {
  const { logout } = useLogout()
  const { back } = useRouter()
  const flow = useSelfSelectFlow()

  return (
    <PayloadUnwrapper item={flow}>
      {(flow) => (
        <SelfSelectLayout>
          <UnsupportedFund
            logOut={logout}
            goBack={back}
            titleText={flow.unsupportedFundTitleText}
            unsupportedFundText={flow.unsupportedFundText}
          />
        </SelfSelectLayout>
      )}
    </PayloadUnwrapper>
  )
}

export default UnsupportedFundPage
