import { ApiError } from './api/ApiError'

export const notFoundExUnwrapper = async <U>(operation: () => Promise<U>): Promise<U | undefined> => {
  try {
    return await operation()
  } catch (ex) {
    if (ex instanceof ApiError) {
      const { status } = ex
      if (status === 404) {
        return undefined
      }
    }
    throw ex
  }
}
