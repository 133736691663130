import { createSelector } from '@reduxjs/toolkit'
import { Selector } from '../../../utils/redux'
import { Payload } from '../../payload'
import { RootState } from '../../rootReducer'
import { consolidateAndCountTags } from '../../selectors/ballotComposite'

export const selectInvestorVotes = (state: RootState) => state.investorVotes

export const selectInvestorFavouriteVoteCategory: Selector<Payload<string | undefined>> = createSelector(
  selectInvestorVotes,
  Payload.map((investorVotes) => {
    const unsortedTags = investorVotes.map((vote) => vote.poll.tags).reduce((acc, item) => acc.concat(item), [])
    const sortedTags = consolidateAndCountTags(unsortedTags) // tags sorted by count descending

    if (sortedTags[0] == null) return undefined

    const userVoteCountThreshold = 2
    const userVoteCount = sortedTags.map((t) => t.count).reduce((total, categoryCount) => categoryCount + total, 0)
    if (userVoteCount < userVoteCountThreshold) return undefined

    return sortedTags[0].tag.title
  })
)
