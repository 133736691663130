import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { H2 } from '@tumelo/designsystem'

interface Props {
  title?: string | ReactNode
  icon?: ReactNode
  showDivider?: boolean
}

export const ListContainer: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  icon,
  showDivider = true,
  children,
}) => {
  return (
    <Section>
      <TitleContainer>
        {title && (
          <Heading showDivider={showDivider}>
            {icon && <IconContainer>{icon}</IconContainer>}
            <Padding>{title}</Padding>
          </Heading>
        )}
      </TitleContainer>
      <PollGrid>{children}</PollGrid>
    </Section>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4rem;
`

interface TitleProps {
  showDivider: boolean
}

const Heading = styled(H2)<TitleProps>`
  display: flex;
  margin: 2rem 0;
  position: relative;
  z-index: 1;
  width: 100%;

  :after {
    content: '';
    height: 2px;
    background-color: ${({ theme, showDivider }) => (showDivider ? `${theme.colors.grey.light}` : 'transparent')};
    display: block;
    flex: 1;
    align-self: center;
  }
`

export const PollGrid = styled.div`
  --gap: 2rem;
  display: flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));
  > * {
    margin: var(--gap) 0 0 var(--gap);
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const Padding = styled.span`
  padding-right: 1.5rem;
`

const IconContainer = styled.div`
  display: inline-flex;
  padding-right: 1.5rem;
`
