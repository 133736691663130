import { RootState } from '../../rootReducer'

export const selectHabitat = (state: RootState) => state.config.config.habitat
export const selectAwsUseFederatedSignIn = (state: RootState) =>
  state.config.config.aws.aws_cognito_use_federated_sign_in
export const selectSoftConfig = (state: RootState) => state.config.config.softConfig
export const selectPortfolioSelectionFlowType = (state: RootState) =>
  state.config.config.softConfig.settings.portfolioSelectionFlow.type
export const selectFeatureSwitches = (state: RootState) => state.config.config.softConfig.featureSwitches
export const selectBranding = (state: RootState) => state.config.config.softConfig.branding
export const selectFidelityLogout = (state: RootState) => state.config.config.softConfig.featureSwitches.fidelityLogout
export const selectIdleTimeout = (state: RootState) => state.config.config.softConfig.featureSwitches.idleTimeout
export const selectSkipTermsAndConditionsConsentSSO = (state: RootState) =>
  state.config.config.softConfig.featureSwitches.skipTermsAndConditionsConsentSSO
