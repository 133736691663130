import * as React from 'react';
export const SvgOthersmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#F3F3F4" }),
        React.createElement("path", { d: "M21 8.99997H20V6.99997C20 6.44997 19.55 5.99997 19 5.99997H14.42L12.71 4.26997C12.52 4.07997 12.27 3.96997 12 3.96997H3C2.45 3.96997 2 4.41997 2 4.96997V18C2 19.1 2.9 20 4 20H21C21.55 20 22 19.55 22 19V9.99997C22 9.44997 21.55 8.99997 21 8.99997ZM4 5.96997H11.58L13.29 7.69997C13.48 7.88997 13.73 7.99997 14 7.99997H18V8.99997H8C6.9 8.99997 6 9.89997 6 11V18H4V5.96997ZM8 18V11H20V18H8Z", fill: "#8B8B8F" })));
};
