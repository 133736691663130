import { fetchUserAttributes, deleteUser, updateUserAttribute } from 'aws-amplify/auth'
import { Investor } from '@tumelo/shared'
import { InvestorService } from './InvestorService'

const INVESTOR_ID_ATTRIBUTE = 'custom:investor_id'

export const InvestorServiceCognito: InvestorService = {
  async getInvestor(): Promise<Investor> {
    const attributes = await fetchUserAttributes()
    const {
      sub,
      given_name: givenName,
      family_name: familyName,
      email,
      email_verified: emailVerified,
      nickname,
    } = attributes
    const investorId = attributes[INVESTOR_ID_ATTRIBUTE]

    return {
      sub: sub ?? '',
      investorId,
      firstName: givenName ?? '',
      lastName: familyName ?? '',
      email: email ?? '',
      // If signed in via federated sign in, the CognitoUser will have an array of identities
      signedInWithSSO: {}.hasOwnProperty.call(attributes, 'identities'),
      changeEmail: {
        emailVerified: emailVerified === 'true',
      },
      nickname,
    }
  },
  async deleteInvestor(): Promise<void> {
    await deleteUser()
  },
  async updateInvestorEmail(email: string): Promise<void> {
    await updateUserAttribute({ userAttribute: { attributeKey: 'email', value: email } })
  },
  async updateInvestorNickname(nickname: string): Promise<Investor> {
    await updateUserAttribute({ userAttribute: { attributeKey: 'nickname', value: nickname } })
    return this.getInvestor()
  },
}
