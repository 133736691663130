import React, { Suspense } from 'react'
import { Loader, BasePageProps, Content } from '@tumelo/shared'
import { ErrorBoundary } from '../components/ErrorBoundary'
import { changeTitle } from './changeTitle'

const CompanyDetailLazy = React.lazy(() => import('./CompanyDetailPageLazy'))

type Props = BasePageProps

const CompanyDetailPage: React.FC<Props> = ({ pageTitle }) => {
  changeTitle(pageTitle)
  return (
    <ErrorBoundary>
      <Content>
        <Suspense fallback={<Loader />}>
          <CompanyDetailLazy />
        </Suspense>
      </Content>
    </ErrorBoundary>
  )
}

export default CompanyDetailPage
