import { ValueObject, hash } from 'immutable'
import { Poll, Organization, Ballot } from '@tumelo/shared'

export interface PollAndOrg {
  poll: Poll
  organization: Organization
}

export interface BallotWithPollAndOrg {
  ballot?: Ballot
  poll: Poll
  organization: Organization
}

export const asValueObject = (s: BallotWithPollAndOrg) =>
  ({
    ...s,
    equals: (other: BallotWithPollAndOrg) => other.poll.id === s.poll.id,
    hashCode: () => hash(s.poll.id),
  }) as ValueObject & BallotWithPollAndOrg
