import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgChevronup = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.29 8.29C11.383 8.19627 11.4936 8.12188 11.6154 8.07111C11.7373 8.02034 11.868 7.9942 12 7.9942C12.132 7.9942 12.2627 8.02034 12.3846 8.07111C12.5064 8.12188 12.617 8.19627 12.71 8.29L18.71 14.29C18.8032 14.3832 18.8772 14.4939 18.9277 14.6158C18.9781 14.7376 19.0041 14.8681 19.0041 15C19.0041 15.1319 18.9781 15.2624 18.9277 15.3842C18.8772 15.5061 18.8032 15.6168 18.71 15.71C18.6168 15.8032 18.5061 15.8772 18.3843 15.9277C18.2624 15.9781 18.1319 16.0041 18 16.0041C17.8681 16.0041 17.7376 15.9781 17.6158 15.9277C17.4939 15.8772 17.3832 15.8032 17.29 15.71L12 10.41L6.71 15.71C6.5217 15.8983 6.2663 16.0041 6 16.0041C5.7337 16.0041 5.47831 15.8983 5.29 15.71C5.1017 15.5217 4.99591 15.2663 4.99591 15C4.99591 14.8681 5.02188 14.7376 5.07234 14.6158C5.1228 14.4939 5.19676 14.3832 5.29 14.29L11.29 8.29Z" })));
};
