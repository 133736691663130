import React from 'react'
import { PayloadUnwrapperM } from '../Error/PayloadUnwrapper'
import { useAvailableModelPortfolios } from '../../application/features/account/hooks'
import { useDerivedAccountTitle } from '../../application/hooks/useDerivedAccountTitle'
import { AccountDetailsCard } from './AccountDetailsCard'

export const AccountDetailsCardConnected: React.FC = () => {
  const accountDerivedTitle = useDerivedAccountTitle()
  const { availableModelPortfolios } = useAvailableModelPortfolios()

  return (
    <PayloadUnwrapperM
      items={[accountDerivedTitle, availableModelPortfolios]}
      customSpinner={<AccountDetailsCard derivedAccountTitle="" isLoading />}
      customNotInitialized={<AccountDetailsCard derivedAccountTitle="" isLoading />}
    >
      {([accountDerivedTitle, availableModelPortfolios]) => (
        <AccountDetailsCard
          derivedAccountTitle={accountDerivedTitle}
          otherFundsAvailable={availableModelPortfolios.length > 1}
        />
      )}
    </PayloadUnwrapperM>
  )
}
