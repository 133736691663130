import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const ClockIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 25 25", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", "data-name": "Icon / Clock" },
        React.createElement("path", { id: "prefix__Path_9522", d: "M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8z", "data-name": "Path 9522" }),
        React.createElement("path", { id: "prefix__Path_9523", d: "M0 0h24v24H0z", "data-name": "Path 9523", fill: "none" }),
        React.createElement("path", { id: "prefix__Path_9524", d: "M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z", "data-name": "Path 9524" })));
};
