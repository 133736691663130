import React, { useContext } from 'react'
import { Content } from '@tumelo/shared'
import { GenericError } from '../Error'
import { LoggerContext } from '../../utils/loggerContext'
import { LoggerService } from '../../application/services/Logger/LoggerService'

interface ErrorBoundaryProps {
  FallbackComponent?: React.ComponentType
}

type ErrorBoundaryState = { error: Error | undefined }
const initialState: ErrorBoundaryState = { error: undefined }

class ErrorBoundaryL extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps & { logger: LoggerService }>,
  ErrorBoundaryState
> {
  constructor(props: React.PropsWithChildren<ErrorBoundaryProps & { logger: LoggerService }>) {
    super(props)
    this.state = initialState
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error) {
    const { logger } = this.props
    logger.logError(error)
  }

  render() {
    const { error } = this.state

    const { FallbackComponent, children } = this.props

    if (error !== undefined) {
      return FallbackComponent ? (
        <FallbackComponent />
      ) : (
        <Content>
          <GenericError onClick={() => window.location.reload()} />
        </Content>
      )
    }

    return children
  }
}

export const ErrorBoundary: React.FC<React.PropsWithChildren<ErrorBoundaryProps>> = ({
  children,
  FallbackComponent,
}) => {
  const logger = useContext(LoggerContext)
  return (
    <ErrorBoundaryL FallbackComponent={FallbackComponent} logger={logger}>
      {children}
    </ErrorBoundaryL>
  )
}
