import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgTransportsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M22.71 10.54L19.99 7.81001C19.8 7.62001 19.55 7.52001 19.28 7.52001H16.34V3.67001C16.34 3.12001 15.89 2.67001 15.34 2.67001H2C1.45 2.67001 1 3.12001 1 3.67001V15.79C1 16.34 1.45 16.79 2 16.79H3.08C2.91 17.18 2.82 17.61 2.82 18.06C2.82 19.86 4.29 21.33 6.09 21.33C7.89 21.33 9.36 19.86 9.36 18.06C9.36 17.61 9.27 17.18 9.1 16.79H14.89C14.72 17.18 14.63 17.61 14.63 18.06C14.63 19.86 16.1 21.33 17.9 21.33C19.7 21.33 21.17 19.86 21.17 18.06C21.17 17.61 21.08 17.18 20.91 16.79H21.99C22.54 16.79 22.99 16.34 22.99 15.79V11.25C22.99 10.99 22.89 10.73 22.7 10.54H22.71ZM6.09 19.33C5.39 19.33 4.82 18.76 4.82 18.06C4.82 17.36 5.39 16.79 6.09 16.79C6.79 16.79 7.36 17.36 7.36 18.06C7.36 18.76 6.79 19.33 6.09 19.33ZM14.33 14.79H3V4.67001H14.33V14.79ZM17.91 19.33C17.21 19.33 16.64 18.76 16.64 18.06C16.64 17.36 17.21 16.79 17.91 16.79C18.61 16.79 19.18 17.36 19.18 18.06C19.18 18.76 18.61 19.33 17.91 19.33ZM21 14.79H17.92H16.35V9.51001H18.88L21.02 11.66V14.79H21Z" })));
};
