import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dateToTimestamp, Timestamp } from '@tumelo/shared'
import { VoteResult } from '../../types/VoteResult'

export type VoteResultsPageState = 'not-initialized' | 'pending' | 'error' | 'loaded'
export interface VoteResults {
  voteResults: VoteResult[]
  // Close time of oldest result, used to show date range of result
  minCloseTime: Timestamp
  // Page state is used instead of using Payload wrapper. This is so we can load a new page and keep the existing pages in state.
  pageState: VoteResultsPageState
  nextPageToken: string
}

export const getInitialState: () => VoteResults = () => ({
  voteResults: [],
  minCloseTime: dateToTimestamp(new Date()),
  pageState: 'not-initialized',
  nextPageToken: '',
})

const voteResultsSlice = createSlice({
  name: 'vote-results',
  initialState: getInitialState(),
  reducers: {
    addVoteResultsPage(
      state,
      action: PayloadAction<{ voteResults: VoteResult[]; nextPageToken: string; minCloseTime: Timestamp }>
    ) {
      state.nextPageToken = action.payload.nextPageToken
      state.minCloseTime = action.payload.minCloseTime
      state.voteResults = [...state.voteResults, ...action.payload.voteResults]
      state.pageState = 'loaded'
    },
    setVoteResultsPending(state) {
      state.pageState = 'pending'
    },
    setVoteResultsError(state) {
      state.pageState = 'error'
    },
  },
})

export default voteResultsSlice.reducer

export const { addVoteResultsPage, setVoteResultsPending, setVoteResultsError } = voteResultsSlice.actions
