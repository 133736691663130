import * as React from 'react';
import { useTheme } from 'styled-components';
export const SvgAgriculturesmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    const themeColor = useTheme().colors.primary.medium;
    props = Object.assign({ fill: themeColor, role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { d: "M21.99 15.36V11C21.99 10.45 21.54 10 20.99 10H18.99V9C18.99 8.45 18.54 8 17.99 8C17.44 8 16.99 8.45 16.99 9V10H14.99V4C15.54 4 15.99 3.55 15.99 3C15.99 2.45 15.54 2 14.99 2H4.99C4.44 2 3.99 2.45 3.99 3C3.99 3.46 4.3 3.83 4.72 3.95L3.02 10.76C3.02 10.76 3.02 10.79 3.02 10.8C1.77 11.98 1 13.65 1 15.49C1 19.07 3.92 21.99 7.5 21.99C10.2 21.99 12.52 20.33 13.5 17.99H15C15 20.2 16.79 21.99 19 21.99C21.21 21.99 23 20.2 23 17.99C23 16.98 22.62 16.06 22 15.35L21.99 15.36ZM9.99 4H12.99V10H10.94C10.64 9.81 10.32 9.64 9.99 9.5V4ZM6.77 4H7.99V9.02C7.83 9.02 7.66 9 7.49 9C6.77 9 6.08 9.12 5.44 9.34L6.78 4H6.77ZM11.8 16.8C11.24 18.65 9.52 20 7.49 20C5.01 20 2.99 17.98 2.99 15.5C2.99 14.12 3.62 12.88 4.6 12.05C4.61 12.05 4.62 12.04 4.63 12.03C4.71 11.96 4.8 11.89 4.89 11.83C5.62 11.31 6.52 11 7.49 11C8.46 11 9.36 11.31 10.09 11.83C11.24 12.65 11.99 13.99 11.99 15.5C11.99 15.95 11.92 16.39 11.8 16.8ZM15.53 16H13.97C13.98 15.84 13.99 15.67 13.99 15.5C13.99 14.21 13.61 13.01 12.96 12H19.99V14.13C19.67 14.04 19.34 14 18.99 14C17.51 14 16.22 14.8 15.53 16ZM18.99 20C17.89 20 16.99 19.1 16.99 18C16.99 16.9 17.89 16 18.99 16C20.09 16 20.99 16.9 20.99 18C20.99 19.1 20.09 20 18.99 20Z" })));
};
