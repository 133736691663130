import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Payload } from '../../payload'
import { BallotWithPollAndOrg } from '../../types/PollWithOrganization/PollWithOrganization'

// singleton votes that can be featured across the platform
interface State {
  investorOpenVotesCloseToExpiration: Payload<BallotWithPollAndOrg[] | undefined>
  mostVotedOnInvestorOpenVote: Payload<BallotWithPollAndOrg | undefined>
}

const initialState: State = {
  investorOpenVotesCloseToExpiration: 'not-initialised',
  mostVotedOnInvestorOpenVote: 'not-initialised',
}

const featuredVotesSlice = createSlice({
  name: 'featured votes',
  initialState,
  reducers: {
    setInvestorOpenVotesCloseToExpiration: (state, action: PayloadAction<BallotWithPollAndOrg[] | undefined>) => {
      state.investorOpenVotesCloseToExpiration = action.payload
    },
    setInvestorOpenVotesCloseToExpirationPending: (state) => {
      state.investorOpenVotesCloseToExpiration = 'pending'
    },
    setInvestorOpenVotesCloseToExpirationError: (state) => {
      state.investorOpenVotesCloseToExpiration = 'error'
    },
    setInvestorOpenVotesCloseToExpirationNotInitialised: (state) => {
      state.investorOpenVotesCloseToExpiration = 'not-initialised'
    },
    mostVotedOnInvestorOpenVote: (state, action: PayloadAction<BallotWithPollAndOrg | undefined>) => {
      state.mostVotedOnInvestorOpenVote = action.payload
    },
    mostVotedOnInvestorOpenVotePending: (state) => {
      state.mostVotedOnInvestorOpenVote = 'pending'
    },
    mostVotedOnInvestorOpenVoteError: (state) => {
      state.mostVotedOnInvestorOpenVote = 'error'
    },
  },
})

export const {
  setInvestorOpenVotesCloseToExpiration,
  setInvestorOpenVotesCloseToExpirationPending,
  setInvestorOpenVotesCloseToExpirationError,
  setInvestorOpenVotesCloseToExpirationNotInitialised,
  mostVotedOnInvestorOpenVote,
  mostVotedOnInvestorOpenVotePending,
  mostVotedOnInvestorOpenVoteError,
} = featuredVotesSlice.actions

export default featuredVotesSlice.reducer
