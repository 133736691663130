import * as React from 'react';
export const SvgGavelsmall = (props) => {
    /**
     * React component automatically generated from the .svg file.
     * Do not make any changes here. Use the template file or svgr settings.
     */
    props = Object.assign({ role: 'presentation' }, props);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#FBE5EC" }),
        React.createElement("path", { d: "M10 19H4C3.45 19 3 19.45 3 20C3 20.55 3.45 21 4 21H10C10.55 21 11 20.55 11 20C11 19.45 10.55 19 10 19ZM20.64 16.35L15.34 11.05L15.39 11C15.63 11.14 15.89 11.24 16.16 11.24C16.54 11.24 16.91 11.1 17.19 10.82L18.29 9.71998C18.35 9.64998 18.4 9.57998 18.43 9.52998C18.58 9.32998 18.67 9.07998 18.68 8.80998C18.71 8.17998 18.44 7.15998 16.05 4.76998C16.03 4.73998 16 4.70998 15.97 4.67998C13.5 2.22998 12.45 1.96998 11.82 1.99998C11.47 2.01998 11.15 2.16998 10.95 2.40998C10.93 2.42998 10.91 2.44998 10.89 2.45998L9.85 3.48998C9.56 3.75998 9.4 4.12998 9.4 4.52998C9.4 4.85998 9.51 5.16998 9.71 5.43998L6.36 8.77998C5.79 8.38998 4.98 8.40998 4.48 8.90998L3.45 9.94998C3.45 9.94998 3.4 9.99998 3.37 10.04C3.37 10.04 3.34 10.07 3.31 10.12C3.12 10.32 3 10.61 2.98 10.92C2.95 11.54 3.21 12.56 5.57 14.96C5.59 14.99 5.62 15.02 5.65 15.05C7.96 17.37 9.01 17.74 9.65 17.74C9.69 17.74 9.73 17.74 9.76 17.74C10.07 17.72 10.36 17.6 10.56 17.4C10.61 17.36 10.66 17.32 10.71 17.27L11.74 16.23C12.22 15.75 12.27 15.02 11.93 14.44L12.01 14.36L17.31 19.66C17.75 20.1 18.34 20.35 18.97 20.35C19.6 20.35 20.18 20.11 20.62 19.66C21.53 18.75 21.53 17.26 20.62 16.35H20.64ZM12.1 4.08998C12.43 4.24998 13.15 4.71998 14.48 6.01998C14.5 6.04998 14.53 6.07998 14.56 6.09998C15.97 7.49998 16.45 8.23998 16.61 8.56998L16.15 9.01998L16.07 8.93998C16.07 8.93998 16.07 8.91998 16.05 8.90998L11.85 4.70998C11.85 4.70998 11.77 4.65998 11.72 4.62998L11.63 4.53998L12.1 4.07998V4.08998ZM9.51 15.65C9.19 15.49 8.48 15.02 7.17 13.72C7.15 13.69 7.12 13.66 7.09 13.63C5.7 12.23 5.23 11.49 5.07 11.16L5.53 10.7L5.68 10.85C5.68 10.85 5.68 10.86 5.69 10.87L6.49 11.67L9.97 15.18L9.51 15.65ZM10.6 12.95L7.95 10.3L7.82 10.17L11.13 6.86998L13.93 9.64998L10.61 12.97L10.6 12.95ZM19.22 18.24C19.04 18.42 18.91 18.41 18.74 18.24L13.45 12.95L13.93 12.47L19.22 17.76C19.35 17.89 19.35 18.11 19.22 18.24Z", fill: "#D30245" })));
};
