import { useTheme } from 'styled-components'
import React from 'react'

export enum colorOptionType {
  forColor = '#30B3A0',
  againstColor = '#E44343',
  abstainColor = '#FFA431',
  withholdColor = '#70787C',
  defaultColor = 'defaultColor',
}

export type colorOption = colorOptionType | undefined

export interface Props {
  stageOne?: colorOption
  stageTwo?: colorOption
  stageThree?: colorOption
  className?: string
}

export const ProgressWheel: React.FC<Props> = ({ stageOne, stageTwo, stageThree, className }) => {
  const { colors } = useTheme()
  const getFinalColor = (colorOption: colorOption) => {
    if (colorOption === colorOptionType.defaultColor || colorOption === undefined) {
      return colors.grey.light
    }
    return colorOption
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.05 39.82" className={className}>
      <path
        d="M29 20.23l3.29 1.4a7.92 7.92 0 00.09-.85V19.7A12.57 12.57 0 0021 7.18V0a18.83 18.83 0 0118.82 18.83 18.18 18.18 0 01-.82 5.62l4.06 1.72-8.9 1.51z"
        fill={getFinalColor(stageOne)}
      />
      <path
        d="M10.5 37.29A18 18 0 016 33.73a1.3 1.3 0 01-.18-.23l-3.61 1.85 4.56-7.79 9 .88-3.4 1.73.12.08A12.56 12.56 0 0030.39 27l5.82 3.36a18.84 18.84 0 01-16.32 9.42 18.65 18.65 0 01-9.39-2.49z"
        fill={getFinalColor(stageTwo)}
      />
      <path
        d="M7.35 19.48A12.56 12.56 0 0118.83 7V0a18 18 0 00-9.41 2.52 18.82 18.82 0 00-6.89 25.72l6-3.46a12.46 12.46 0 01-1.18-5.3z"
        fill={getFinalColor(stageThree)}
      />
    </svg>
  )
}
