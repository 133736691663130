import * as React from 'react';
import { useTheme } from 'styled-components';
const DEFAULT_IMAGE_STYLE = {
    width: '40px',
    height: '40px',
};
export const VotingIcon = ({ fill, width, height }) => {
    const defaultFill = useTheme().colors.primary.medium;
    const styleWithDefault = {
        fill: fill !== null && fill !== void 0 ? fill : defaultFill,
        width: width !== null && width !== void 0 ? width : DEFAULT_IMAGE_STYLE.width,
        height: height !== null && height !== void 0 ? height : DEFAULT_IMAGE_STYLE.height,
    };
    return (React.createElement("svg", { viewBox: "0 0 22.787 25.024", style: styleWithDefault, xmlns: "http://www.w3.org/2000/svg", id: "prefix__archive" },
        React.createElement("path", { id: "prefix__Path_10222", d: "M22.645 10.379l-4.371-4.42a.479.479 0 0 0-.341-.143h-1.045l1.242-1.258a.493.493 0 0 0 0-.691L14.452.143a.479.479 0 0 0-.683 0L12.242 1.69 8.161 5.821a.471.471 0 0 0-.066-.005h-3.25a.479.479 0 0 0-.345.144L.141 10.379a.49.49 0 0 0-.141.346v13.811a.486.486 0 0 0 .483.489H22.3a.486.486 0 0 0 .483-.489v-4.95a.483.483 0 1 0-.966 0v4.462H.966V11.213h20.855v4.462a.483.483 0 1 0 .966 0v-4.95a.491.491 0 0 0-.142-.346zm-9.721-8l1.186-1.2 3 3.033-3.77 3.814H9.364l-1.008-1.02zM1.648 10.236l3.4-3.442h2.191a.493.493 0 0 0 .093.557L8 8.026H6.477a.489.489 0 0 0 0 .978h9.833a.489.489 0 0 0 0-.978H14.7l1.217-1.232h1.811l3.4 3.442zm0 0" }),
        React.createElement("path", { id: "prefix__Path_10223", d: "M148 276.959a4.248 4.248 0 1 0 4.248-4.248 4.253 4.253 0 0 0-4.248 4.248zm7.531 0a3.283 3.283 0 1 1-3.283-3.283 3.287 3.287 0 0 1 3.283 3.283zm0 0", transform: "translate(-140.855 -259.239)" }),
        React.createElement("path", { id: "prefix__Path_10224", d: "M195.811 328.963a.483.483 0 1 0-.683.683l1.059 1.059a.483.483 0 0 0 .683 0l1.935-1.935a.483.483 0 0 0-.683-.683l-1.594 1.594zm0 0", transform: "translate(-185.574 -311.891)" }),
        React.createElement("path", { id: "prefix__Path_10225", d: "M452.487 350.707a.483.483 0 1 0 .341.142.486.486 0 0 0-.341-.142zm0 0", transform: "translate(-430.182 -333.557)" })));
};
