import { InvestorVoteResponse } from '@tumelo/shared'
import { VoteOptions } from '../../../utils/api/gen'

export const convertToVoteOptions = (response: InvestorVoteResponse) => {
  switch (response) {
    case 'for':
      return VoteOptions.For
    case 'against':
      return VoteOptions.Against
    case 'abstain':
      return VoteOptions.Abstain
    case 'withhold':
      return VoteOptions.Withhold
    case 'no_action':
      return VoteOptions.NoAction
    default:
      return undefined
  }
}

export const convertVoteOptionToResponse = (voteOption: VoteOptions) => {
  switch (voteOption) {
    case VoteOptions.For:
      return 'for'
    case VoteOptions.Against:
      return 'against'
    case VoteOptions.Abstain:
      return 'abstain'
    case VoteOptions.Withhold:
      return 'withhold'
    case VoteOptions.NoAction:
      return 'no_action'
    default:
      return undefined
  }
}

export const convertRecommendationToTidyResponse = (response: InvestorVoteResponse) => {
  switch (response) {
    case 'for':
      return 'In favour'
    case 'against':
      return 'Against'
    case 'abstain':
      return 'Abstain'
    case 'withhold':
      return 'Withhold'
    case 'no_action':
      return 'No action'
    default:
      return ''
  }
}
