/* eslint-disable @typescript-eslint/no-deprecated */

import React from 'react'
import styled from 'styled-components'
import { Text, MiniGridCard, OrganizationLogoWithFallback } from '@tumelo/shared'
import { PayloadUnwrapper } from '../Error/PayloadUnwrapper'
import { isValueState, Payload } from '../../application/payload'
import { BallotWithPollAndOrg } from '../../application/types/PollWithOrganization/PollWithOrganization'
import { SkeletonRows } from '../../buildingBlocks/Skeleton'

export const GenericPollGridCard: React.FC<{
  vote: Payload<BallotWithPollAndOrg | undefined>
  title: string
  footerText?: string
  showPollTally: boolean
  onClick: () => void
}> = ({ title, footerText, showPollTally, vote: mostPopularOpenVote, onClick }) => {
  if (mostPopularOpenVote === undefined) {
    return null
  }

  if (
    isValueState(mostPopularOpenVote) &&
    mostPopularOpenVote.poll.tally.forCount + mostPopularOpenVote.poll.tally.againstCount < 3
  ) {
    return null
  }

  return (
    <MiniGridCard title={title} onClick={onClick}>
      <PayloadUnwrapper
        item={mostPopularOpenVote}
        customNotInitialized={<SkeletonRows count={4} />}
        customSpinner={<SkeletonRows count={4} />}
      >
        {(mostPopularOpenVote) => (
          <>
            <OrganizationLogoWithFallbackContainer>
              <OrganizationLogoWithFallback src={mostPopularOpenVote.organization.logoUrl} alt="" />
            </OrganizationLogoWithFallbackContainer>
            <Text>{mostPopularOpenVote.poll.title}</Text>
            {showPollTally ? (
              <Footer>
                <Text>
                  <strong>
                    {mostPopularOpenVote.poll.tally.forCount + mostPopularOpenVote.poll.tally.againstCount}
                  </strong>{' '}
                  votes.
                </Text>
              </Footer>
            ) : null}
            {footerText ? (
              <Footer>
                <Text>{footerText}</Text>
              </Footer>
            ) : null}
          </>
        )}
      </PayloadUnwrapper>
    </MiniGridCard>
  )
}

const OrganizationLogoWithFallbackContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
`

const Footer = styled.div`
  padding-top: 1rem;
`
