import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthUser } from '../../types/Auth/AuthUser'
import { AuthError } from './errors'

type AuthFlowState =
  | 'signIn'
  | 'signUp'
  | 'authenticated'
  | 'confirmSignIn'
  | 'forgotPassword'
  | 'confirmSignUp'
  | 'not-initialized' // special state when app first loads
  | 'federatedSignIn' // state when waiting for Auth.federatedSignIn to redirect - allows UI to remain in pending state
  | 'signOut' // state while waiting for Auth.signOut - prevents redux from firing actions that require authenticated state
  | 'error'
interface AuthState {
  authState: AuthFlowState
  authUser: AuthUser // Used to store user details between auth screens
  authAction: {
    pending: boolean
    error: AuthError | undefined
  }
}

const getInitialState = (): AuthState => {
  return {
    authState: 'not-initialized',
    authUser: {},
    authAction: { pending: false, error: undefined },
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: getInitialState(),
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthFlowState>) => {
      state.authState = action.payload
    },
    setAuthUser: (state, action: PayloadAction<AuthUser>) => {
      state.authUser = action.payload
    },
    beginAuthAction: (state) => {
      state.authAction.error = undefined
      state.authAction.pending = true
    },
    completeAuthAction: (state, action: PayloadAction<AuthError | undefined>) => {
      state.authAction.error = action.payload
      state.authAction.pending = false
    },
  },
})

export default authSlice.reducer

export const { setAuthState, setAuthUser, beginAuthAction, completeAuthAction } = authSlice.actions
